import styles from "../Table.module.scss";

type TablePlaceholder = {
  nCol: number;
  selectable?: boolean;
  textNoData: string;
};
export function TablePlaceholder({
  nCol,
  textNoData,
  selectable,
}: TablePlaceholder) {
  return (
    <div
      style={{
        gridColumn: `span ${nCol + (selectable ? 1 : 0)}`,
      }}
      className={styles.noData}
    >
      {textNoData}
    </div>
  );
}
