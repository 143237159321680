import { Cell, Row, Workbook, Worksheet } from "exceljs";
import { isDate, partition, reduce, trim } from "lodash";
import {
  ExcelPropertyType,
  MappedExcelTemplate,
  MappedExcelTemplateCategory,
  MappedType,
  TemplateCategory,
  TemplateColumn,
  ExcelTemplate,
} from "./excel.definition";
import { RowError } from "./RowError";

function convertCellData<T extends ExcelPropertyType>(
  type: T,
  cell: Cell
): MappedType | null {
  const cellValue = cell.value;
  if (cellValue === null || cellValue === undefined) return null;
  switch (type) {
    case ExcelPropertyType.date:
      if (!isDate(cellValue)) {
        throw new RowError(`Invalid cell type not date`, cell.row, cell.col);
      }
      return cellValue;
    case ExcelPropertyType.boolean:
      if (typeof cellValue !== "string") {
        throw new RowError(`Invalid cell type not boolean`, cell.row);
      }
      if (!["OUI", "NON"].includes(trim(cellValue.toUpperCase()))) {
        throw new RowError(`Invalid boolean value`, cell.row, cell.col);
      }
      return trim(cellValue.toUpperCase()) === "OUI";
    case ExcelPropertyType.number:
      if (typeof cellValue !== "number") {
        throw new RowError(`Invalid cell type not number`, cell.row, cell.col);
      }
      return cellValue;
    case ExcelPropertyType.string:
    default:
      return trim(cell.text);
  }
}

function extractPropertyData(
  propertyTemplate: TemplateColumn,
  row: Row
): MappedType | null {
  return convertCellData(
    propertyTemplate.type,
    row.getCell(propertyTemplate.col)
  );
}

function extractCategoryData(categoryTemplate: TemplateCategory, row: Row) {
  return Object.entries(categoryTemplate.columns).reduce(
    (categoryData, [propertyName, propertyTemplate]) => ({
      ...categoryData,
      [propertyName]: extractPropertyData(propertyTemplate, row),
    }),
    {} as MappedExcelTemplateCategory<TemplateCategory>
  );
}

function extractDataFromRow<TTemplate extends ExcelTemplate>(
  row: Row,
  template: TTemplate["categories"]
): MappedExcelTemplate<TTemplate> | RowError {
  try {
    const data = reduce(
      Object.entries(template) as [keyof TTemplate, TemplateCategory][],
      (rowData, [categoryName, categoryTemplate]) => ({
        ...rowData,
        [categoryName]: extractCategoryData(categoryTemplate, row),
      }),
      {} as MappedExcelTemplate<TTemplate>
    );
    return data;
  } catch (e) {
    if (!(e instanceof RowError)) throw e;
    return e;
  }
}

function extractRowsFromSheet<TTemplate extends ExcelTemplate>(
  worksheet: Worksheet,
  template: TTemplate
): (MappedExcelTemplate<TTemplate> | RowError)[] {
  const rows: (MappedExcelTemplate<TTemplate> | RowError)[] = [];
  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber <= template.firstRowIndex) return;
    rows.push(extractDataFromRow(row, template.categories));
  });
  return rows;
}

export type ExtractDataExcelSuccessResponse<TReturn> = {
  data: TReturn;
  index: number;
};

export async function extractDataExcel<
  TTemplate extends ExcelTemplate,
  TReturn
>(
  file: File,
  template: TTemplate,
  transformFunction: (data: MappedExcelTemplate<TTemplate>) => TReturn,
  filterRowsBeforeImport?: (
    data: MappedExcelTemplate<TTemplate> | RowError
  ) => boolean
): Promise<[RowError[], ExtractDataExcelSuccessResponse<TReturn>[]]> {
  const buffer = await file.arrayBuffer();
  const workbook = new Workbook();
  const workbookBuffer = await workbook.xlsx.load(buffer);
  const worksheet = workbookBuffer.getWorksheet(1);
  if (!worksheet) throw new Error("No worksheet found");
  const rows = extractRowsFromSheet(worksheet, template);
  const filteredRows = filterRowsBeforeImport
    ? rows.filter(filterRowsBeforeImport)
    : rows;

  const transformedRows = filteredRows.map((row, index) => {
    if (row instanceof RowError) return row;
    try {
      return {
        data: transformFunction(row),
        index,
      };
    } catch (err) {
      return new RowError((err as Error).message, index);
    }
  });
  return partition(transformedRows, (r) => r instanceof RowError) as [
    RowError[],
    { data: TReturn; index: number }[]
  ];
}
