import { GatewayContact, GatewayCreationContact } from "@conformite/gateway";
import { userSession } from "@src/session/UserSession";

export class GatewayCreationContactApi {
  public static async create(
    payload: GatewayCreationContact.CreationContactRequestType
  ): Promise<GatewayContact.ContactWithAssociationsResponseType> {
    const { data } =
      await userSession.axiosInstance.post<GatewayContact.ContactWithAssociationsResponseType>(
        GatewayCreationContact.creationContactControllerURL,
        payload
      );

    return data;
  }
}
