import {
  Card,
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import {
  formeJuridiqueRecord,
  trancheEffectifRecord,
  typeSouscripteurRecord,
} from "@src/societes/form/EntrepriseIdentificationForm";
import { ResumeBlockComponentProps } from "./RenouvellementResumeEntreprisePage";

export function ResumeIdentification({ data }: ResumeBlockComponentProps) {
  return (
    <Card variant="accent">
      <FormRow>
        <FormField>
          Raison sociale :{" "}
          <b>{data.resume.entreprisePrincipale.raisonSociale}</b>
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          Numéro SIREN : <b>{data.resume.entreprisePrincipale.siren}</b>
        </FormField>
        <FormField>
          Forme juridique :{" "}
          <b>
            {
              formeJuridiqueRecord[
                data.resume.entreprisePrincipale.formeJuridique
              ]
            }
          </b>
        </FormField>
      </FormRow>

      <FormRow>
        <FormField>
          Tranche effectif :{" "}
          <b>
            {
              trancheEffectifRecord[
                data.resume.entreprisePrincipale.trancheEffectif
              ]
            }
          </b>
        </FormField>
      </FormRow>

      <FormRow>
        <FormField>
          Vous souscrivez en tant que :{" "}
          <b>
            {
              typeSouscripteurRecord[
                data.resume.entreprisePrincipale.typeSouscripteur
              ]
            }
          </b>
        </FormField>
      </FormRow>
    </Card>
  );
}
