import { useCallback } from "react";
import { ParcoursAction } from "@src/parcours/NavigationButtons/ParcoursAction";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import {
  EntrepriseOrganisationProfessionnelleForm,
  EntrepriseOrganisationProfessionnelleFormValues,
} from "@src/societes/form/EntrepriseOrganisationProfessionnelleForm";
import {
  ID_FORM_RENOUVELLEMENT,
  ParcoursRenouvellementStepComponentProps,
  RenouvellementStepEnum,
} from "@src/renouvellement/ParcoursRenouvellement.definition";

export function RenouvellementOrganisationProfessionnellesPage({
  data,
  setData,
  changeStep,
}: ParcoursRenouvellementStepComponentProps) {
  const entreprise =
    data.entreprisesAControler[data.resume.entreprisePrincipale.siren];

  const handleSubmit = useCallback(
    (values: EntrepriseOrganisationProfessionnelleFormValues) => {
      setData({
        ...data,
        entreprisesAControler: {
          ...data.entreprisesAControler,
          [entreprise.siren]: {
            ...entreprise,
            organisationProfessionnelle: values.organisationsProfessionnelles,
          },
        },
      });
      changeStep(RenouvellementStepEnum.INFORMATIONS_COMPLEMENTAIRES);
    },
    [setData, data, entreprise, changeStep]
  );

  return (
    <ParcoursFullPageTemplate
      title="Organisations professionnelles"
      actions={
        <ParcoursAction type="submit" formId={ID_FORM_RENOUVELLEMENT}>
          Suivant
        </ParcoursAction>
      }
    >
      <EntrepriseOrganisationProfessionnelleForm
        formId={ID_FORM_RENOUVELLEMENT}
        defaultValues={{
          organisationsProfessionnelles: entreprise.organisationProfessionnelle,
        }}
        onSubmit={handleSubmit}
      />
    </ParcoursFullPageTemplate>
  );
}
