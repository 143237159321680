import { SliceBody, SliceByType, SliceType } from "./Slice.definition";
import { SliceEntete } from "./slices/SliceEnTete/SliceEnTete";
import { SliceGrosseImage } from "./slices/SliceGrosseImage/SliceGrosseImage";
import styles from "./SliceManager.module.scss";
import { SliceContenu } from "./slices/SliceContenu/SliceContenu";
import { SliceEmbed } from "./slices/SliceEmbed/SliceEmbed";
import { SliceTextImage } from "./slices/SliceTextImage/SliceTextImage";
import { SliceListPartenaires } from "./slices/SliceListPartenaires/SliceListPartenaires";
import { SliceFaq } from "./slices/SliceFaq/SliceFaq";
import { SliceDocuments } from "./slices/SliceDocuments/SliceDocuments";

export type SliceProps<T extends SliceType> = {
  slice: SliceByType<T>;
};
const sliceByType: {
  [key in SliceType]: (props: SliceProps<key>) => JSX.Element;
} = {
  en_tete: SliceEntete,
  gross_image: SliceGrosseImage,
  contenu: SliceContenu,
  embedded: SliceEmbed,
  text_image: SliceTextImage,
  liste_partenaires: SliceListPartenaires,
  faq: SliceFaq,
  doc_a_telecharger: SliceDocuments,
};

export type SliceFactoryProps<T extends SliceType> = {
  slice: SliceByType<T>;
};
export function SliceFactory<T extends SliceType>({
  slice,
}: SliceFactoryProps<T>) {
  const Component = sliceByType[slice.slice_type] as (
    props: SliceProps<T>
  ) => JSX.Element;
  if (!Component) return <div />;
  return <Component slice={slice} />;
}
type SliceManagerProps = {
  body: SliceBody;
};
export function SliceManager({ body }: SliceManagerProps) {
  return (
    <div className={styles.sliceFactory}>
      {body.body?.map((slice) => (
        <SliceFactory key={slice.id} slice={slice} />
      ))}
    </div>
  );
}
