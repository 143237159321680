import {
  AxeConformite as AxeConformiteService,
  AxeConformiteEntreprise as AxeConformiteEntrepriseService,
  AxeConformiteAssujetti as AxeConformiteAssujettiService,
} from '../service/conformite.definition';

export type IasIobsp = 'ias' | 'iobsp';

export const AxeConformiteAssujetti = AxeConformiteAssujettiService;
export type AxeConformiteAssujetti = AxeConformiteAssujettiService;

export const AxeConformiteEntreprise = AxeConformiteEntrepriseService;
export type AxeConformiteEntreprise = AxeConformiteEntrepriseService;

export const AxeConformite = AxeConformiteService;
export type AxeConformite = AxeConformiteService;
