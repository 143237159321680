import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Form,
  InputButtonGroup,
  InputMask,
  InputSelect,
  InputText,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayEntreprise } from "@conformite/gateway";
import { recordToSelectOptions } from "@src/adhesion/AdhesionData/adhesionData";
import { InfoCard } from "@src/components/InfoCard/InfoCard";

export const trancheEffectifRecord: Record<
  GatewayEntreprise.TrancheEffectif,
  string
> = {
  AUCUN: "Pas de salarié",
  DE_1_A_2: "1 ou 2 salariés",
  DE_3_A_5: "3 à 5 salariés",
  DE_6_A_9: "6 à 9 salariés",
  DE_10_A_19: "10 à 19 salariés",
  DE_20_A_49: "20 à 49 salariés",
  DE_50_A_99: "50 à 99 salariés",
  DE_100_A_199: "100 à 199 salariés",
  DE_200_A_249: "200 à 249 salariés",
  DE_250_A_499: "250 à 499 salariés",
  DE_500_A_999: "500 à 999 salariés",
  DE_1000_A_1999: "1000 à 1999 salariés",
  DE_2000_A_4999: "2000 à 4999 salariés",
  DE_5000_A_9999: "5000 à 9999 salariés",
  PLUS_DE_10000: "10000 salariés et plus",
};
export const trancheEffectifOptions = recordToSelectOptions(
  trancheEffectifRecord
);

export const formeJuridiqueRecord: Record<
  GatewayEntreprise.FormeJuridique,
  string
> = {
  [GatewayEntreprise.FormeJuridique
    .ORGANISME_DE_PLACEMENT_COLLECTIF_EN_VALEURS_MOBILIERES_SANS_PERSONNALITE_MORALE]:
    "Organisme de placement collectif en valeurs mobilières sans personnalité morale",
  [GatewayEntreprise.FormeJuridique.ENTREPRENEUR_INDIVIDUEL]:
    "Entrepreneur individuel",
  [GatewayEntreprise.FormeJuridique.INDIVISION]: "Indivision",
  [GatewayEntreprise.FormeJuridique.SOCIETE_CREEE_DE_FAIT]:
    "Société créée de fait",
  [GatewayEntreprise.FormeJuridique.SOCIETE_EN_PARTICIPATION]:
    "Société en participation",
  [GatewayEntreprise.FormeJuridique.FIDUCIE]: "Fiducie ",
  [GatewayEntreprise.FormeJuridique.PAROISSE_HORS_ZONE_CONCORDATAIRE]:
    "Paroisse hors zone concordataire",
  [GatewayEntreprise.FormeJuridique.ASSUJETTI_UNIQUE_A_LA_TVA]:
    "Assujetti unique à la TVA",
  [GatewayEntreprise.FormeJuridique
    .AUTRE_GROUPEMENT_DE_DROIT_PRIVE_NON_DOTE_DE_LA_PERSONNALITE_MORALE]:
    "Autre groupement de droit privé non doté de la personnalité morale",
  [GatewayEntreprise.FormeJuridique
    .PERSONNE_MORALE_DE_DROIT_ETRANGER_IMMATRICULEE_AU_RCS]:
    "Personne morale de droit étranger, immatriculée au RCS (registre du commerce et des sociétés)",
  [GatewayEntreprise.FormeJuridique
    .PERSONNE_MORALE_DE_DROIT_ETRANGERE_NON_IMMATRICULEE_AU_RCS]:
    "Personne morale de droit étranger, non immatriculée au RCS",
  [GatewayEntreprise.FormeJuridique
    .ETABLISSEMENT_PUBLIC_OU_REGIE_A_CARACTERE_INDUSTRIEL_OU_COMMERCIAL]:
    "Etablissement public ou régie à caractère industriel ou commercial",
  [GatewayEntreprise.FormeJuridique
    .SOCIETE_COOPERATIVE_COMMERCIALE_PARTICULIERE]:
    "Société coopérative commerciale particulière",
  [GatewayEntreprise.FormeJuridique.SOCIETE_EN_NOM_COLLECTIF]:
    "Société en nom collectif",
  [GatewayEntreprise.FormeJuridique.SOCIETE_EN_COMMANDITE]:
    "Société en commandite",
  [GatewayEntreprise.FormeJuridique.SOCIETE_A_RESPONSABILITE_LIMITEE]:
    "Société à responsabilité limitée (SARL)",
  [GatewayEntreprise.FormeJuridique.SOCIETE_ANONYME_A_CONSEIL_D_ADMINISTRATION]:
    "Société anonyme à conseil d'administration",
  [GatewayEntreprise.FormeJuridique.SOCIETE_ANONYME_A_DIRECTOIRE]:
    "Société anonyme à directoire",
  [GatewayEntreprise.FormeJuridique.SOCIETE_PAR_ACTIONS_SIMPLIFIEE]:
    "Société par actions simplifiée",
  [GatewayEntreprise.FormeJuridique.SOCIETE_EUROPEENNE]: "Société européenne ",
  [GatewayEntreprise.FormeJuridique.CAISSE_D_EPARGNE_ET_DE_PREVOYANCE]:
    "Caisse d'épargne et de prévoyance",
  [GatewayEntreprise.FormeJuridique.GROUPEMENT_D_INTERET_ECONOMIQUE]:
    "Groupement d'intérêt économique",
  [GatewayEntreprise.FormeJuridique.SOCIETE_COOPERATIVE_AGRICOLE]:
    "Société coopérative agricole",
  [GatewayEntreprise.FormeJuridique.SOCIETE_D_ASSURANCE_MUTUELLE]:
    "Société d'assurance mutuelle",
  [GatewayEntreprise.FormeJuridique.SOCIETE_CIVILE]: "Société civile",
  [GatewayEntreprise.FormeJuridique
    .AUTRE_PERSONNE_MORALE_DE_DROIT_PRIVE_INSCRITE_AU_REGISTRE_DU_COMMERCE_ET_DES_SOCIETES]:
    "Autre personne morale de droit privé inscrite au registre du commerce et des sociétés",
  [GatewayEntreprise.FormeJuridique.ADMINISTRATION_DE_L_ETAT]:
    "Administration de l'état",
  [GatewayEntreprise.FormeJuridique.COLLECTIVITE_TERRITORIALE]:
    "Collectivité territoriale",
  [GatewayEntreprise.FormeJuridique.ETABLISSEMENT_PUBLIC_ADMINISTRATIF]:
    "Etablissement public administratif",
  [GatewayEntreprise.FormeJuridique
    .AUTRE_PERSONNE_MORALE_DE_DROIT_PUBLIC_ADMINISTRATIF]:
    "Autre personne morale de droit public administratif",
  [GatewayEntreprise.FormeJuridique
    .ORGANISME_GERANT_UN_REGIME_DE_PROTECTION_SOCIALE_A_ADHESION_OBLIGATOIRE]:
    "Organisme gérant un régime de protection sociale à adhésion obligatoire",
  [GatewayEntreprise.FormeJuridique.ORGANISME_MUTUALISTE]:
    "Organisme mutualiste",
  [GatewayEntreprise.FormeJuridique.COMITE_D_ENTREPRISE]: "Comité d'entreprise",
  [GatewayEntreprise.FormeJuridique.ORGANISME_PROFESSIONNEL]:
    "Organisme professionnel",
  [GatewayEntreprise.FormeJuridique
    .ORGANISME_DE_RETRAITE_A_ADHESION_NON_OBLIGATOIRE]:
    "Organisme de retraite à adhésion non obligatoire",
  [GatewayEntreprise.FormeJuridique.SYNDICAT_DE_PROPRIETAIRES]:
    "Syndicat de propriétaires",
  [GatewayEntreprise.FormeJuridique.ASSOCIATION_LOI_1901_OU_ASSIMILE]:
    "Association loi 1901 ou assimilé",
  [GatewayEntreprise.FormeJuridique.FONDATION]: "Fondation",
  [GatewayEntreprise.FormeJuridique.AUTRE_PERSONNE_MORALE_DE_DROIT_PRIVE]:
    "Autre personne morale de droit privé",
};
export const formeJuridiqueOptions =
  recordToSelectOptions(formeJuridiqueRecord);

export const typeSouscripteurRecord: Record<
  GatewayEntreprise.TypeSouscripteur,
  string
> = {
  [GatewayEntreprise.TypeSouscripteur.PERSONNE_MORALE]: "Personne morale",
  [GatewayEntreprise.TypeSouscripteur.PERSONNE_PHYSIQUE]: "Personne physique",
};
export const typeSouscripteurOptions = recordToSelectOptions(
  typeSouscripteurRecord
);

export const entrepriseIdentificationSchema = zod.object({
  siren: zod.string().nonempty(),
  raisonSociale: zod
    .string()
    .nonempty({ message: "Veuillez renseigner votre raison sociale" }),
  trancheEffectif: zod.nativeEnum(GatewayEntreprise.TrancheEffectif),
  formeJuridique: zod.nativeEnum(GatewayEntreprise.FormeJuridique),
  typeSouscripteur: zod.nativeEnum(GatewayEntreprise.TypeSouscripteur),
});

export type EntrepriseIdentificationFormValues = zod.infer<
  typeof entrepriseIdentificationSchema
>;

type EntrepriseIdentificationFormProps = {
  nonDiffusable?: boolean;
  formId?: string;
  onSubmit: (repsonse: EntrepriseIdentificationFormValues) => void;
  defaultValues: Partial<EntrepriseIdentificationFormValues>;
};

export function EntrepriseIdentificationForm({
  nonDiffusable,
  onSubmit,
  defaultValues,
  formId,
}: EntrepriseIdentificationFormProps) {
  const formConfig = useFormConfig<
    typeof entrepriseIdentificationSchema,
    EntrepriseIdentificationFormValues
  >({
    schema: entrepriseIdentificationSchema,
    defaultValues,
  });

  return (
    <Form formConfig={formConfig} onSubmit={onSubmit} id={formId}>
      <FormRow>
        <FormField>
          <InputMask<EntrepriseIdentificationFormValues>
            mask="999 999 999"
            label="Siren"
            name="siren"
            required
            disabled
          />
        </FormField>
      </FormRow>
      {nonDiffusable && (
        <FormRow>
          <FormField>
            <InfoCard type="info">
              Votre SIREN étant non diffusible, nous n&apos;avons pas pu
              renseigner les champs ci-dessous.
            </InfoCard>
          </FormField>
        </FormRow>
      )}
      <FormRow>
        <FormField>
          <InputText<EntrepriseIdentificationFormValues>
            label="Raison sociale"
            name="raisonSociale"
            required
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <InputSelect<
            EntrepriseIdentificationFormValues,
            GatewayEntreprise.TrancheEffectif
          >
            name="trancheEffectif"
            label="Tranche effectif"
            placeholder="Sélectionner"
            options={trancheEffectifOptions}
            required
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <InputSelect<
            EntrepriseIdentificationFormValues,
            GatewayEntreprise.FormeJuridique
          >
            name="formeJuridique"
            label="Forme juridique"
            placeholder="Sélectionner"
            options={formeJuridiqueOptions}
            required
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <InputButtonGroup<
            EntrepriseIdentificationFormValues,
            false,
            GatewayEntreprise.TypeSouscripteur
          >
            label="Vous souscrivez en tant que"
            name="typeSouscripteur"
            variant="outline-pop"
            size="large"
            options={typeSouscripteurOptions}
            required
          />
        </FormField>
      </FormRow>
    </Form>
  );
}
