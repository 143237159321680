import { GatewayAssujetti, GatewayCapacitePro } from "@conformite/gateway";
import { AxiosResponse } from "axios";
import { nullableDateStringToDate } from "@src/helper/date.helper";
import { transformKeysInObject } from "@src/helper/object.helper";
import { userSession } from "@src/session/UserSession";

function apiResponseToIOBSP(
  response: AxiosResponse<NestedSwapDatesWithStrings<GatewayCapacitePro.CapaciteProfessionnelleIOBSP> | null>
) {
  if (!response.data) return null;
  return transformKeysInObject(
    response.data,
    ["datePriseFonction"],
    nullableDateStringToDate
  ) as GatewayCapacitePro.CapaciteProfessionnelleIOBSP;
}

function apiResponseToIAS(
  response: AxiosResponse<NestedSwapDatesWithStrings<GatewayCapacitePro.CapaciteProfessionnelleIAS> | null>
) {
  if (!response.data) return null;
  return transformKeysInObject(
    response.data,
    ["datePriseFonction"],
    nullableDateStringToDate
  ) as GatewayCapacitePro.CapaciteProfessionnelleIAS;
}

export class GatewayCapaciteProApi {
  public static async getIAS(
    idAssujetti: string,
    millesime: number
  ): Promise<GatewayCapacitePro.CapaciteProfessionnelleIAS | null> {
    return userSession.axiosInstance
      .get<NestedSwapDatesWithStrings<GatewayCapacitePro.CapaciteProfessionnelleIAS> | null>(
        `${GatewayCapacitePro.capaciteProfessionnelleUrl}/${idAssujetti}/${millesime}/ias`
      )
      .then(apiResponseToIAS);
  }

  public static async getIASAdmin(
    idAssujetti: string,
    millesime: number
  ): Promise<GatewayCapacitePro.CapaciteProfessionnelleIAS | null> {
    return userSession.axiosInstance
      .get<NestedSwapDatesWithStrings<GatewayCapacitePro.CapaciteProfessionnelleIAS> | null>(
        `${GatewayCapacitePro.capaciteProfessionnelleUrl}/${idAssujetti}/${millesime}/ias/admin`
      )
      .then(apiResponseToIAS);
  }

  public static async saveIAS(
    idAssujetti: string,
    millesime: number,
    form: GatewayCapacitePro.CapaciteProfessionnelleIAS
  ): Promise<GatewayAssujetti.Avancement> {
    const res =
      await userSession.axiosInstance.post<GatewayAssujetti.Avancement>(
        `${GatewayCapacitePro.capaciteProfessionnelleUrl}/${idAssujetti}/${millesime}/ias`,
        form
      );
    return res.data;
  }

  public static async getIOBSP(
    idAssujetti: string,
    millesime: number
  ): Promise<GatewayCapacitePro.CapaciteProfessionnelleIOBSP | null> {
    return userSession.axiosInstance
      .get<NestedSwapDatesWithStrings<GatewayCapacitePro.CapaciteProfessionnelleIOBSP> | null>(
        `${GatewayCapacitePro.capaciteProfessionnelleUrl}/${idAssujetti}/${millesime}/iobsp`
      )
      .then(apiResponseToIOBSP);
  }

  public static async getIobspAdmin(
    idAssujetti: string,
    millesime: number
  ): Promise<GatewayCapacitePro.CapaciteProfessionnelleIOBSP | null> {
    return userSession.axiosInstance
      .get<NestedSwapDatesWithStrings<GatewayCapacitePro.CapaciteProfessionnelleIOBSP> | null>(
        `${GatewayCapacitePro.capaciteProfessionnelleUrl}/${idAssujetti}/${millesime}/iobsp/admin`
      )
      .then(apiResponseToIOBSP);
  }

  public static async saveIOBSP(
    idAssujetti: string,
    millesime: number,
    form: GatewayCapacitePro.CapaciteProfessionnelleIOBSP
  ): Promise<GatewayAssujetti.Avancement> {
    const res =
      await userSession.axiosInstance.post<GatewayAssujetti.Avancement>(
        `${GatewayCapacitePro.capaciteProfessionnelleUrl}/${idAssujetti}/${millesime}/iobsp`,
        form
      );
    return res.data;
  }
}
