import { GatewayInfoSocieteOrias } from "@conformite/gateway";
import { userSession } from "@src/session/UserSession";

export class GatewayInfoSocieteOriasApi {
  public static async getBySiren(
    searchRequest: GatewayInfoSocieteOrias.RetrieveInfoSocietesOriasRequestType
  ): Promise<GatewayInfoSocieteOrias.RetrieveInfoSocietesOriasResponseType> {
    const { data } =
      await userSession.axiosInstance.post<GatewayInfoSocieteOrias.RetrieveInfoSocietesOriasResponseType>(
        GatewayInfoSocieteOrias.infoSocieteOriasUrl,
        searchRequest
      );

    return data;
  }
}
