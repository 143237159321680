import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactNode, useRef, useState } from "react";
import { ReactComponent as Close } from "@src/assets/icons/x-lg.svg";
import { ReactComponent as ChevronLeft } from "@src/assets/icons/chevron-left.svg";

import style from "./SidePane.module.scss";

type SidePaneProps = {
  isOpen: boolean;
  setIsOpen: (nextIsOpen: boolean) => void;
  children: (closePane: (unmount?: boolean) => void) => ReactNode;
  hideReopenButton?: boolean;
};

function useDismount(isOpen: boolean) {
  const wasOpenRef = useRef(isOpen);
  const willDismountRef = useRef(false);
  const unmountKeyRef = useRef(0);

  if (
    willDismountRef.current &&
    isOpen === true &&
    wasOpenRef.current === false
  ) {
    willDismountRef.current = false;
    unmountKeyRef.current += 1;
  }

  return {
    unmountKey: unmountKeyRef.current,
    markForDismount: () => {
      willDismountRef.current = true;
    },
  };
}

function usePaneState(
  isOpen: boolean,
  setIsOpen: (nextIsOpen: boolean) => void
) {
  const [isEditing, setIsEditing] = useState(isOpen);
  const { markForDismount, unmountKey } = useDismount(isOpen);
  const shouldHideShowButton = !isOpen && !isEditing;

  function closePane(unmount?: boolean) {
    if (unmount) {
      setIsEditing(false);
      markForDismount();
    } else {
      setIsEditing(true);
    }
    setIsOpen(false);
  }

  return {
    shouldHideShowButton,
    closePane,
    unmountKey,
  };
}
type ShowButtonProps = {
  onClick: () => void;
  isHidden: boolean;
  willShow: boolean;
};
function ShowButton({ onClick, isHidden, willShow }: ShowButtonProps) {
  return (
    <button
      onClick={onClick}
      className={style.sidePane__showButton}
      data-show={willShow}
      data-hidden={isHidden}
      disabled={isHidden}
    >
      <div className={style.sidePane__showButtonContent}>
        <ChevronLeft className={style.sidePane__showButtonIcon} />
      </div>
    </button>
  );
}

export function SidePane({
  isOpen,
  setIsOpen,
  children,
  hideReopenButton = false,
}: SidePaneProps) {
  const { closePane, unmountKey, shouldHideShowButton } = usePaneState(
    isOpen,
    setIsOpen
  );

  return (
    <div className={style.sidePane} data-visible={isOpen}>
      <div
        className={style.sidePane__backdrop}
        onClick={() => closePane(hideReopenButton)}
        role="presentation"
        data-visible={isOpen}
      />

      <div className={style.sidePane__contentWrapper} data-opened={isOpen}>
        <ShowButton
          isHidden={shouldHideShowButton || hideReopenButton}
          onClick={() => (isOpen ? closePane(false) : setIsOpen(true))}
          willShow={isOpen}
        />
        <div className={style.sidePane__actionsTop}>
          <Button
            variant="text"
            onClick={() => closePane(true)}
            icon={<Close />}
          >
            Annuler
          </Button>
        </div>
        <fieldset
          disabled={!isOpen}
          className={style.sidePane__content}
          key={unmountKey}
        >
          {children(closePane)}
        </fieldset>
      </div>
    </div>
  );
}
