/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Fragment, ReactElement } from "react";
import { ReactComponent as ChevronRight } from "@src/assets/icons/chevron-right.svg";
import classNames from "classnames";
import style from "./Breadcrumb.module.scss";

type BeadcrumbProps<T> = {
  items: { label: string; value: T }[];
  separator?: ReactElement;
  selected: T;
  setSelected: (nextValue: T) => void;
};

export function Breadcrumb<T>({
  items,
  separator = <ChevronRight className={style.separator} />,
  selected,
  setSelected,
}: BeadcrumbProps<T>) {
  return (
    <div className={style.breadcrumb}>
      {items.map(({ label, value }, index) => {
        return (
          <Fragment key={label}>
            <span
              onClick={() => {
                setSelected(value);
              }}
              className={classNames(style.label, {
                [style.labelSelected]: value === selected,
              })}
            >
              {label}
            </span>
            {index !== items.length - 1 && separator}
          </Fragment>
        );
      })}
    </div>
  );
}
