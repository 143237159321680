import { GatewayAssujetti, GatewayCapacitePro } from "@conformite/gateway";
import { Form } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { FieldSet } from "@src/components/FieldSet/FieldSet";
import { useCallback } from "react";
import { GatewayCapaciteProApi } from "@src/api/conformite/capacitePro.api";
import {
  ConformiteFormCapaciteProIOBSPContent,
  useCapaciteProIobspForm,
} from "@src/assujettis/AssujettiPaneContent/pages/AssujettiParcoursConformite/Steps/FormsCapacitePro/ConformiteFormCapaciteProIOBSP/ConformiteFormCapaciteProIOBSPContent";
import { useControleQuinquennalForm } from "./useControleQuinquennalForm";
import { ControleQuinquennalFormTitle } from "./ControleQuinquennalFormTitle";

export const useCapaciteProIobsp = (
  assujetti: GatewayAssujetti.AssujettiWithAvancement | undefined,
  needed: boolean
) => {
  const call = useCallback(() => {
    if (!assujetti) return Promise.resolve(null);
    return GatewayCapaciteProApi.getIobspAdmin(
      assujetti.id,
      assujetti.millesime
    );
  }, [assujetti]);
  const { content, hasLoaded } = useControleQuinquennalForm(
    assujetti,
    needed,
    call
  );
  return {
    capaciteProIobsp: content,
    hasLoadedCapaciteProIobsp: hasLoaded,
  };
};
type ControleQuinquennalAssujettiSidePaneContentCapaciteProProps = {
  assujetti: GatewayAssujetti.AssujettiWithAvancement;
  capacitePro: GatewayCapacitePro.CapaciteProfessionnelleIOBSP | null;
};

export function ControleQuinquennalAssujettiSidePaneContentCapaciteProIobsp({
  assujetti,
  capacitePro,
}: ControleQuinquennalAssujettiSidePaneContentCapaciteProProps) {
  const props = useCapaciteProIobspForm(capacitePro);
  return (
    <>
      <ControleQuinquennalFormTitle
        assujetti={assujetti}
        title="Capacité professionnelle IOBSP"
        avancement="capaciteProfessionnelleIobsp"
      />
      <Form formConfig={props.formConfig} onSubmit={() => null}>
        <FieldSet disabled>
          <ConformiteFormCapaciteProIOBSPContent
            {...props}
            data={{ assujetti }}
            hideTitle
          />
        </FieldSet>
      </Form>
    </>
  );
}
