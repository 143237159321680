function LinkDocumentationHere() {
  return <a href="https://aide.endya.fr/faq/outils-conformite">ici</a>;
}

export function ControleQuinquennalExplainAxeGarantieFinanciereIas() {
  return (
    <div>
      <p>
        Si vous percevez, même à titre occasionnel, des fonds pour le compte de
        tiers (entreprise d&apos;assurance/assuré/clients) dans le cadre de
        l&apos;activité d&apos;IAS, les documents sollicités ci-dessous
        permettront de vérifier la cohérence de la garantie financière souscrite
        avec les fonds réellement encaissés, déduction faite des mandats
        d&apos;encaissement.
      </p>
      <br />
      <p>Pièces à fournir :</p>
      <ul>
        <li>
          <b>Si vous encaissez des fonds :</b>
        </li>
        <ul>
          <li>Le(s) éventuel(s) mandat(s) d&apos;encaissement</li>
          <li>
            Attestation de votre expert-comptable ou commissaire aux comptes
            précisant le détail des fonds encaissés au sens des articles L.512-7
            et A. 512-5 du code des assurances. (cf. modèle accessible dans la
            documentation Endya disponible <LinkDocumentationHere />)
          </li>
          <li>
            Bilan comptable ou tout autre document comptable complémentaire N-1
          </li>
        </ul>
        <li>
          <b>Si vous ne percevez aucun fonds pour le compte de tiers : </b>
        </li>
        <ul>
          <li>
            Attestation de votre expert-comptable ou commissaire aux comptes
            précisant l&apos;absence d&apos;encaissement de fonds au sens des
            articles L.512-7 et A. 512-5 du code des assurances. (cf. modèle
            accessible dans la documentation Endya disponible{" "}
            <LinkDocumentationHere />)
          </li>
          <li>
            Ou, si vous n&apos;avez pas d&apos;expert-comptable ou commissaire
            aux comptes, déclaration sur l&apos;honneur attestant de
            l&apos;absence totale d&apos;encaissement de fonds accessible dans
            la documentation Endya disponible <LinkDocumentationHere />
          </li>
        </ul>
      </ul>
    </div>
  );
}
