import {
  GatewayConformiteRcPro,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";

export const blacklistedProperties = ["files", "avancement", "millesime"];
export const RcProPropertyCoaDefault: GatewayConformiteRcPro.ConformiteRcProPropertyCOA =
  {
    niveauMinimumGarantie:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    franchiseInferieur:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    declarationActivitesIntraComnmunautaire:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    declarationApporteurCommercialisation:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    declarationNombreSalarie:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
  };
export const RcProPropertyMiaDefault: GatewayConformiteRcPro.ConformiteRcProPropertyMIA =
  {
    rcpIndividuelleSouscrit:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    courvertRcMandant:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    niveauMinimumGarantie:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    franchiseInferieur:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    declarationActivitesIntraComnmunautaire:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    declarationApporteurCommercialisation:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    declarationNombreSalarie:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
  };
export const RcProPropertyCobspDefault: GatewayConformiteRcPro.ConformiteRcProPropertyCOBSP =
  {
    niveauMinimumGarantieBSP:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    franchiseInferieur:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    activiteTerritoieFrancais:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    declarationApporteurCommercialisation:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    declarationNombreSalarie:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
  };
export const RcProPropertyCobspMiobspDefault: GatewayConformiteRcPro.ConformiteRcProPropertyMIOBSP =
  {
    rcpIndividuelleSouscrit:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    courvertRcMandant:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    niveauMinimumGarantieBSP:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    franchiseInferieur:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    activiteTerritoieFrancais:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    declarationApporteurCommercialisation:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    declarationNombreSalarie:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
  };

export const RcProPropertyDefault: Record<
  GatewayInfoSocieteOrias.Categorie,
  GatewayConformiteRcPro.ConformiteRcProProperty
> = {
  COA: RcProPropertyCoaDefault,
  MIA: RcProPropertyMiaDefault,
  COBSP: RcProPropertyCobspDefault,
  MIOBSP: RcProPropertyCobspMiobspDefault,
};

export type PropertyKeyWithoutFiles = Exclude<
  GatewayConformiteRcPro.ConformiteRcProPropertyKey,
  "files"
>;
export const propertiesToText: Record<PropertyKeyWithoutFiles, string> = {
  niveauMinimumGarantie:
    "Le niveau minimum de garantie de mon contrat est-il bien fixé à 1.500.000 € par sinistre et 2.000.000 € par année ?",
  franchiseInferieur:
    "La franchise contractuelle est-elle bien inférieure ou égale à 20% du montant de l'indemnisation ?",
  declarationActivitesIntraComnmunautaire:
    "Ai-je bien déclaré à mon assureur mes activités intra-communautaires ?",
  declarationApporteurCommercialisation:
    "Ai-je bien déclaré l'existence d'éventuels sous-apporteurs auxquels je fais appel pour la commercialisation des contrats d'assurance ?",
  declarationNombreSalarie:
    "Ai-je bien indiqué le nombre de salariés travaillant au sein de mon cabinet ?",
  rcpIndividuelleSouscrit: "Avez-vous souscrit une RCP individuelle ?",
  courvertRcMandant:
    "Pour chaque activité exercée, êtes-vous couvert par la RC de votre mandant ?",
  niveauMinimumGarantieBSP:
    "Le niveau minimum de garantie de mon contrat est-il bien fixé à 500.000 € par sinistre et 800.000 € par année ?",
  activiteTerritoieFrancais:
    "Le territoire de mes activités déclarées est-il bien couvert par le contrat, à savoir le territoire français ?",
};

export const isCategorie = (
  categorie: string
): categorie is GatewayInfoSocieteOrias.Categorie => {
  return Object.values(GatewayInfoSocieteOrias.Categorie).includes(
    categorie as GatewayInfoSocieteOrias.Categorie
  );
};
