import { ReactElement, ReactNode } from "react";
import styles from "./FilterSearch.module.scss";

type FilterSearchProps = {
  search: ReactElement;
  action?: ReactNode;
  filter?: ReactNode | ReactNode[];
};
export function FilterSearch({ search, action, filter }: FilterSearchProps) {
  return (
    <div className={styles.header}>
      <div className={styles.left}>
        {search}
        {filter}
      </div>
      {action}
    </div>
  );
}
