import Fuse from "fuse.js";
import { UseFormReturn } from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import { GatewayDeclarationActivite } from "@conformite/gateway";
import { useCallback, useMemo } from "react";
import { InputNumber } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  Button,
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import {
  AutoCompleteWithList,
  Data,
  ListComponentProps,
} from "@src/components/AutocompleteWithList/AutocompleteWithList";
import { ReactComponent as Trash } from "@src/assets/icons/trash.svg";
import { invertMap } from "@src/helper/object.helper";
import styles from "./Organisations.module.scss";

export const organisationToLabel: Record<
  GatewayDeclarationActivite.OrganisationType,
  string
> = {
  VENTES_B2B2C: "Ventes B2B2C",
  VENTE_DIRECTE: "Vente directe avec des réseaux intégrés",
  VENTE_A_DISTANCE: "Vente à distance",
  DEMARCHAGE_TELEPHONIQUE: "Démarchage téléphonique (appels non sollicités)",
  RECOURS_MANDATAIRES_OU_CONVENTIONS: "Recours à des mandataires / conventions",
  VENTE_EN_LIGNE: "Vente en ligne",
  FACE_A_FACE: "Face à Face (au moins 1 contact physique)",
};
const labelToActivite: Record<
  string,
  GatewayDeclarationActivite.OrganisationType
> = invertMap(organisationToLabel);

export type OrganisationData = {
  organisation: string;
  organisations: {
    type: GatewayDeclarationActivite.OrganisationType;
    repartitionClientele: number;
  }[];
};
type OrganisationsProps = {
  form: UseFormReturn<OrganisationData>;
};
const activites = Object.keys(labelToActivite);
export function Organisations({ form }: OrganisationsProps) {
  const [value, valuesSelected] = form.watch(["organisation", "organisations"]);
  const activitesWithoutSelected = useMemo(
    () =>
      activites.filter((activite) =>
        valuesSelected.every((v) => v.type !== labelToActivite[activite])
      ),
    [valuesSelected]
  );
  const fuse = new Fuse(activitesWithoutSelected, {
    threshold: 0.4,
  });
  const ListComponent = useCallback(
    ({
      fields,
      remove,
    }: ListComponentProps<GatewayDeclarationActivite.DeclarationActiviteOrganisation>) => (
      <div className={styles.organisations}>
        {fields.map((field, index) => (
          <div className={styles.organisation} key={field.id}>
            <div className={styles.organisationHeader}>
              <h4>{organisationToLabel[field.type]}</h4>
              <Button
                icon={<Trash />}
                variant="text"
                onClick={() => remove(index)}
                className={styles.trash}
              />
            </div>
            <FormRow>
              <FormField size="half">
                <InputNumber
                  label="Répartition de la clientèle en pourcentage"
                  name={`organisations.${index}.repartitionClientele`}
                  onChange={(repartition) => {
                    form.setValue(
                      `organisations.${index}.repartitionClientele`,
                      repartition as number,
                      { shouldDirty: true }
                    );
                    form
                      .trigger("organisations.0.repartitionClientele")
                      .catch(console.error);
                  }}
                />
              </FormField>
            </FormRow>
          </div>
        ))}
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <AutoCompleteWithList<
      "organisation",
      "organisations",
      GatewayDeclarationActivite.DeclarationActiviteOrganisation
    >
      form={
        form as unknown as UseFormReturn<
          Data<
            "organisation",
            "organisations",
            GatewayDeclarationActivite.DeclarationActiviteOrganisation
          >
        >
      }
      label="Sélectionnez les modalités de distribution à ajouter"
      placeholder="Selectionner"
      options={(value
        ? fuse.search(value, { limit: 20 }).map((r) => r.item)
        : activitesWithoutSelected
      ).map((item) => ({
        label: item,
        value: labelToActivite[item],
      }))}
      nameSelected="organisations"
      nameSearch="organisation"
      valueToAppend={(item) => ({
        type: item as GatewayDeclarationActivite.OrganisationType,
        repartitionClientele: 0,
      })}
      ListComponent={ListComponent}
    />
  );
}
