import { ReactElement, ReactNode, useId, useState } from "react";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useRole,
  useClick,
  useInteractions,
  Placement,
  useDismiss,
} from "@floating-ui/react";
import {
  Button,
  ButtonVariant,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as ChevronDownIcon } from "@src/assets/icons/chevron-down.svg";

export type DropdownButtonProps = {
  content?: ReactElement | string;
  children: (props: { isOpen: boolean; close: () => void }) => ReactNode;
  placement?: Placement;
  icon?: ReactElement;
  variant?: ButtonVariant;
  className?: string;
  withIcon?: boolean;
  dismissOnClick?: boolean;
};
export function DropdownButton({
  content,
  children,
  placement,
  icon = <ChevronDownIcon />,
  variant = "outline",
  className,
  withIcon,
  dismissOnClick = false,
}: DropdownButtonProps) {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    placement,
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(10),
      flip({ fallbackAxisSideDirection: "end" }),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const role = useRole(context);
  const dismiss = useDismiss(context, {
    enabled: dismissOnClick,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    role,
    dismiss,
  ]);
  const close = () => setIsOpen(false);

  const headingId = useId();

  return (
    <>
      <Button
        ref={refs.setReference}
        {...getReferenceProps()}
        variant={variant}
        icon={withIcon ? icon : undefined}
        className={className}
      >
        {content}
      </Button>
      {isOpen && (
        <div
          className="Popover"
          ref={refs.setFloating}
          style={{ ...floatingStyles, zIndex: 1 }}
          aria-labelledby={headingId}
          {...getFloatingProps()}
        >
          {children({ isOpen, close })}
        </div>
      )}
    </>
  );
}
