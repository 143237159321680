import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Form,
  InputCheckbox,
  InputPassword,
  InputText,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  FormBloc,
  FormField,
  FormRow,
  FormSubTitle,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { PasswordCriterias } from "@src/components/PasswordCriterias/PasswordCriterias";
import { DeepPartial } from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import style from "./SignupForm.module.scss";
import { passwordWithConfirmationSchema } from "./password.definition";

const errorMessage = {
  email: "Veuillez renseigner une adresse mail valide",
  confirmEmail:
    "La confirmation de votre adresse email ne correspond pas à la valeur du champ Adresse email",
};

const schema = zod.object({
  consentCGV: zod.literal(true, {
    errorMap: () => ({
      message:
        "Vous devez accepter les conditions générales de vente, la politique de confidentialité et la gestion des cookies pour créer un compte",
    }),
  }),
  consentNewsletter: zod.boolean().optional(),
  email: zod
    .object({
      email: zod
        .string()
        .nonempty(errorMessage.email)
        .email(errorMessage.email),
      confirm: zod.string().nonempty(errorMessage.confirmEmail),
    })
    .refine((data) => data.email === data.confirm, {
      message: errorMessage.confirmEmail,
      path: ["confirm"],
    }),
  password: passwordWithConfirmationSchema,
});

export type SignupFormValues = zod.infer<typeof schema>;

type SignupFormProps = {
  formId: string;
  onSubmit: (values: SignupFormValues) => unknown;
  defaultValues?: DeepPartial<SignupFormValues>;
};

export function SignupForm({
  formId,
  onSubmit,
  defaultValues,
}: SignupFormProps) {
  const formConfig = useFormConfig<typeof schema, SignupFormValues>({
    schema,
    defaultValues,
  });

  const passwordValue = formConfig.watch("password.password");

  return (
    <Form formConfig={formConfig} onSubmit={onSubmit} id={formId}>
      <FormRow>
        <FormField>
          <InputText<SignupFormValues>
            name="email.email"
            label="Adresse email"
            required
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <InputText<SignupFormValues>
            name="email.confirm"
            label="Confirmer l'adresse email"
            required
          />
        </FormField>
      </FormRow>
      <FormSubTitle>
        <h2>Choisissez votre mot de passe</h2>
      </FormSubTitle>
      <FormRow>
        <FormField className={style.passwordContainer}>
          <InputPassword<SignupFormValues>
            name="password.password"
            label="Mot de passe"
            required
          />
        </FormField>
      </FormRow>
      <FormBloc>
        <PasswordCriterias password={passwordValue} />
      </FormBloc>
      <FormRow>
        <FormField>
          <InputPassword<SignupFormValues>
            name="password.confirm"
            label="Confirmez votre mot de passe"
            required
          />
        </FormField>
      </FormRow>
      <FormSubTitle>
        <h2>Consentement</h2>
      </FormSubTitle>
      <FormRow>
        <FormField>
          <InputCheckbox<SignupFormValues>
            size="medium"
            name="consentCGV"
            label="J'accepte les conditions générales de vente, la politique de confidentialité et la gestion des cookies."
            required
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <InputCheckbox<SignupFormValues>
            name="consentNewsletter"
            label="Je souhaite recevoir la newsletter d'Endya."
          />
        </FormField>
      </FormRow>
    </Form>
  );
}
