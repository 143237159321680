import { GatewayControleQuinquennal } from "@conformite/gateway";
import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as ChevronDown } from "@src/assets/icons/chevron-down.svg";
import { ReactComponent as ChevronUp } from "@src/assets/icons/chevron-up.svg";
import { filterSearchByKeys } from "@src/components/Filter/filter.helper";
import { useFilterPagination } from "@src/components/Filter/useFilterPagination";
import { FilterSearch } from "@src/components/FilterSearch/FilterSearch";
import { InputSearch } from "@src/components/InputSearch/InputSearch";
import { MainContentPageTemplate } from "@src/components/MainPageTemplate/MainContentPageTemplate";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { SkeletonCard } from "@src/components/Skeletons/card/SkeletonCard";
import { useEntreprises } from "@src/store/store.entreprise";
import { groupBy, keyBy, map, partition } from "lodash";
import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { ControlQuinquennalCard } from "./ControleQuinquennalCard";
import styles from "./ControleQuinquennalPage.module.scss";
import { useControlesQuinquennaux } from "./useControlesQuinquennaux";

export function Collapse({
  isOpen,
  title,
  onToggle,
  children,
}: PropsWithChildren & {
  isOpen: boolean;
  title: string;
  onToggle: () => void;
}) {
  return (
    <>
      <div className={styles.collapseTitle}>
        <h2>{title}</h2>

        <Button
          variant="text"
          icon={isOpen ? <ChevronUp /> : <ChevronDown />}
          onClick={onToggle}
        >
          {isOpen ? "Voir moins" : "Voir plus"}
        </Button>
      </div>
      {isOpen ? children : null}
    </>
  );
}

export function ControleQuinquennalPage() {
  const { controlesQuinquennaux } = useControlesQuinquennaux();
  const { entreprisesBySiren, status: entreprisesStatus } = useEntreprises();
  const [isOpenBySection, setIsOpenBySection] = useState({
    enRemediation: true,
    enCours: true,
    enCoursVerification: true,
    historique: true,
    annule: true,
  });

  const entreprisesById = useMemo(
    () => keyBy(Object.values(entreprisesBySiren ?? {}), "hubspotId"),
    [entreprisesBySiren]
  );

  const controlesQuinquennauxWithEntreprise = useMemo(
    () =>
      controlesQuinquennaux.map((controle) => ({
        ...controle,
        raisonSociale: entreprisesById[controle.idEntreprise]?.raisonSociale,
      })),
    [entreprisesById, controlesQuinquennaux]
  );

  const { content, filtersSelected, setFilterSelected } = useFilterPagination({
    content: controlesQuinquennauxWithEntreprise,
    filters: [
      { name: "raisonSociale", filter: filterSearchByKeys(["raisonSociale"]) },
    ],
  });

  const controlesQuinquennauxByStatus = useMemo(
    () => groupBy(content, "status"),
    [content]
  );

  const [controlesEnCoursAvecRemediation, controlesEnCoursSansRemediations] =
    useMemo(
      () =>
        partition(
          controlesQuinquennauxByStatus[
            GatewayControleQuinquennal.ControleQuinquennalStatus.EN_COURS
          ],
          (controle) => controle.hasRemediations
        ),
      [controlesQuinquennauxByStatus]
    );

  const handleToggleIsOpenBySection = useCallback(
    (section: keyof typeof isOpenBySection) => () =>
      setIsOpenBySection((prev) => ({ ...prev, [section]: !prev[section] })),
    []
  );

  return (
    <MainContentPageTemplate
      title="Contrôle quinquennal"
      contentSelection={
        <FilterSearch
          search={
            <InputSearch
              placeholder="Raison sociale, SIREN"
              name="search"
              value={filtersSelected?.raisonSociale[0] || ""}
              onChange={(nextValue) => {
                setFilterSelected("raisonSociale", [nextValue.target.value]);
              }}
            />
          }
        />
      }
    >
      <MaxWidthContent size="large">
        {controlesQuinquennaux.length === 0 &&
          entreprisesStatus === "loaded" && (
            <div className={styles.controleQuinquennal}>
              <h2>Collecte en cours</h2>
              <div className={styles.list}>
                {new Array(2).fill(0).map((_, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <SkeletonCard key={index} height={0} />
                ))}
              </div>
            </div>
          )}

        {controlesEnCoursSansRemediations.length > 0 && (
          <div className={styles.controleQuinquennal}>
            <Collapse
              isOpen={isOpenBySection.enCours}
              title="Collecte en cours"
              onToggle={handleToggleIsOpenBySection("enCours")}
            >
              <div className={styles.list}>
                {map(
                  controlesEnCoursSansRemediations,
                  (controleQuinquennal) => (
                    <ControlQuinquennalCard
                      key={controleQuinquennal.id}
                      controleQuinquennal={controleQuinquennal}
                      entreprise={
                        entreprisesById[controleQuinquennal.idEntreprise]
                      }
                    />
                  )
                )}
              </div>
            </Collapse>
          </div>
        )}
        {controlesEnCoursAvecRemediation.length > 0 && (
          <div className={styles.controleQuinquennal}>
            <Collapse
              isOpen={isOpenBySection.enRemediation}
              title="En remédiation"
              onToggle={handleToggleIsOpenBySection("enRemediation")}
            >
              <div className={styles.list}>
                {map(controlesEnCoursAvecRemediation, (controleQuinquennal) => (
                  <ControlQuinquennalCard
                    key={controleQuinquennal.id}
                    controleQuinquennal={controleQuinquennal}
                    entreprise={
                      entreprisesById[controleQuinquennal.idEntreprise]
                    }
                  />
                ))}
              </div>
            </Collapse>
          </div>
        )}
        {controlesQuinquennauxByStatus[
          GatewayControleQuinquennal.ControleQuinquennalStatus.DEPOSE
        ]?.length > 0 && (
          <div className={styles.controleQuinquennal}>
            <Collapse
              isOpen={isOpenBySection.enCoursVerification}
              title="Vérification en cours"
              onToggle={handleToggleIsOpenBySection("enCoursVerification")}
            >
              <div className={styles.list}>
                {map(
                  controlesQuinquennauxByStatus[
                    GatewayControleQuinquennal.ControleQuinquennalStatus.DEPOSE
                  ],
                  (controleQuinquennal) => (
                    <ControlQuinquennalCard
                      key={controleQuinquennal.id}
                      controleQuinquennal={controleQuinquennal}
                      entreprise={
                        entreprisesById[controleQuinquennal.idEntreprise]
                      }
                    />
                  )
                )}
              </div>
            </Collapse>
          </div>
        )}
        {controlesQuinquennauxByStatus[
          GatewayControleQuinquennal.ControleQuinquennalStatus.TERMINE
        ]?.length > 0 &&
          map(
            controlesQuinquennauxByStatus[
              GatewayControleQuinquennal.ControleQuinquennalStatus.TERMINE
            ],
            (controleQuinquennal) => (
              <div
                className={styles.controleQuinquennal}
                key={controleQuinquennal.id}
              >
                <Collapse
                  isOpen={isOpenBySection.historique}
                  title="Historique"
                  onToggle={handleToggleIsOpenBySection("historique")}
                >
                  <div className={styles.list}>
                    <ControlQuinquennalCard
                      controleQuinquennal={controleQuinquennal}
                      entreprise={
                        entreprisesById[controleQuinquennal.idEntreprise]
                      }
                    />
                  </div>
                </Collapse>
              </div>
            )
          )}

        {controlesQuinquennauxByStatus[
          GatewayControleQuinquennal.ControleQuinquennalStatus.ANNULE
        ]?.length > 0 &&
          map(
            controlesQuinquennauxByStatus[
              GatewayControleQuinquennal.ControleQuinquennalStatus.ANNULE
            ],
            (controleQuinquennal) => (
              <div
                className={styles.controleQuinquennal}
                key={controleQuinquennal.id}
              >
                <Collapse
                  isOpen={isOpenBySection.annule}
                  title="Contrôle annulé"
                  onToggle={handleToggleIsOpenBySection("annule")}
                >
                  <div className={styles.list}>
                    <ControlQuinquennalCard
                      controleQuinquennal={controleQuinquennal}
                      entreprise={
                        entreprisesById[controleQuinquennal.idEntreprise]
                      }
                    />
                  </div>
                </Collapse>
              </div>
            )
          )}
      </MaxWidthContent>
    </MainContentPageTemplate>
  );
}
