import { cloneDeep, get, set } from "lodash";

export function transformKeysInObject<T extends Record<string, unknown>>(
  obj: T,
  keys: NestedKeyOf<T>[],
  fn: (value: any) => unknown // eslint-disable-line @typescript-eslint/no-explicit-any
): Record<string, unknown> {
  const tranformed = cloneDeep(obj);

  function transformKey(key: NestedKeyOf<T>): void {
    set(tranformed, key, fn(get(obj, key)));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
  keys.forEach(transformKey as any);

  return {
    ...obj,
    ...tranformed,
  };
}

export function recordKeysToUppercase<T>(
  record: Record<string, T>
): Record<string, T> {
  return Object.entries(record).reduce(
    (acc, [key, value]) => ({ ...acc, [key.toUpperCase()]: value }),
    {}
  );
}

type InvertResult<T extends Record<PropertyKey, PropertyKey>> = {
  [P in keyof T as T[P]]: P;
};

export function invertMap<T extends Record<string, string>>(
  obj: T
): InvertResult<T> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return Object.fromEntries([
    ...Object.entries(obj).map((e) => [...e].reverse()),
  ]);
}

export function isStringifiedJSON(str: string): boolean {
  try {
    JSON.parse(str);
    return true;
  } catch (_) {
    return false;
  }
}

export function withLastEntryNotDisabled<T extends string>(
  entries: T[] | null,
  disabled: T[]
) {
  if (entries === null) return null;
  for (let i = entries.length - 1; i >= 0; i -= 1) {
    if (!disabled.includes(entries[i])) return entries.slice(0, i + 1);
  }
  return [];
}
