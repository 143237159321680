import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import {
  AdhesionStepEnum,
  ID_FORM_ADHESION,
  ParcoursAdhesionStepComponentProps,
} from "@src/adhesion/ParcoursAdhesion.definition";
import {
  EntrepriseAdresseForm,
  EntrepriseAdresseFormValues,
} from "@src/societes/form/EntrepriseAdresseForm";
import { ParcoursAdhesionNextAction } from "@src/adhesion/AdhesionActions/ParcoursAdhesionNextAction";
import { useSession } from "@src/session/UserSessionContext";
import { useMe } from "@src/store/store.me";
import { AdhesionDataIdentifiants } from "@src/adhesion/AdhesionData/adhesionData";

export function AdhesionEntrepriseAdressePage({
  addData,
  changeStep,
  data,
}: ParcoursAdhesionStepComponentProps) {
  const session = useSession();
  const { me } = useMe();
  const handleSubmit = (submittedData: EntrepriseAdresseFormValues) => {
    const adressePayload = { entrepriseAdresse: submittedData };
    if (session.isConnected && me?.contact.telephone) {
      changeStep(AdhesionStepEnum.INIT);
      addData({
        ...adressePayload,
        informationsPersonnelles: {
          nom: me.contact.nom,
          prenom: me.contact.prenom,
          civilite: me.contact.civilite,
          fonction: me.contact.fonction,
          telephone: me.contact.telephone,
        },
        identifiants: {
          email: me.contact.email,
        } as unknown as AdhesionDataIdentifiants,
      });
      return;
    }
    addData(adressePayload);
    changeStep(AdhesionStepEnum.INFORMATIONS_PERSONNELLES);
  };

  return (
    <ParcoursFullPageTemplate
      title="Adresse de l'entreprise"
      actions={
        <ParcoursAdhesionNextAction type="submit">
          Suivant
        </ParcoursAdhesionNextAction>
      }
    >
      <EntrepriseAdresseForm
        onSubmit={handleSubmit}
        defaultValues={data.entrepriseAdresse}
        formId={ID_FORM_ADHESION}
      />
    </ParcoursFullPageTemplate>
  );
}
