import { GatewayControleQuinquennal } from "@conformite/gateway";
import {
  Button,
  displayErrorToast,
  displaySucessToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ControleQuinquennalApi } from "@src/api/controleQuinquennal.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { ReactComponent as ChevronLeft } from "@src/assets/icons/chevron-left.svg";
import { LeSaviezVousItem } from "@src/cms/LeSaviezVous/LeSaviezVousList";
import { BulletNumber } from "@src/components/BulletNumber/BulletNumber";
import { useDialog } from "@src/components/Dialog/useDialog";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { Link } from "@src/components/Link/Link";
import { MainContentPageTemplate } from "@src/components/MainPageTemplate/MainContentPageTemplate";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { SkeletonCard } from "@src/components/Skeletons/card/SkeletonCard";
import { controleTypeOrder } from "@src/controleQuinquennal/controleQuinquennal.helper";
import { useControleQuinquennal } from "@src/store/store.controleQuinquennal";
import classNames from "classnames";
import { format } from "date-fns";
import { partition } from "lodash";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ControleQuinquennalItem.module.scss";
import { ControleQuinquennalItemActions } from "./ControleQuinquennalItemActions";
import { ControleQuinquennalItemCard } from "./ControleQuinquennalItemCard";
import { useControleQuinquennalItem } from "./useControleQuinquennalItem";

export function ControleQuinquennalItem() {
  const { controleQuinquennal, entreprise, areAllAxesComplet } =
    useControleQuinquennalItem();
  const { getDateFinDepot } = useControleQuinquennal();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { openConfirmDialog } = useDialog();

  const handleValidateDepot = () => {
    if (!controleQuinquennal) return;
    setIsLoading(true);
    openConfirmDialog({
      title: "Confirmation",
      message:
        "Les pièces transmises ne pourront plus être modifiées/supprimées après avoir confirmé. Une procédure de remédiation sera initiée si certaines pièces sont manquantes ou non conformes. Si le dossier est complet et conforme, une attestation de fin de vérification sera téléchargeable depuis cet espace.",
      confirmText: "Confirmer",
      cancelText: "Annuler",
    })
      .then((shouldValidate) => {
        if (!shouldValidate) {
          setIsLoading(false);
          return;
        }
        ControleQuinquennalApi.validateDepot(controleQuinquennal.id)
          .then(() => {
            displaySucessToast("Le dépôt a bien été validé");
            navigate("/controle-quinquennal");
          })
          .catch(
            handleGatewayError({
              onUnhandled: (e) => {
                console.error(e);
                displayErrorToast("Une erreur inconnue est survenue");
              },
            })
          )
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch(console.error);
  };
  const axesSorted = useMemo(
    () =>
      controleQuinquennal?.controleQuinquennalAxes
        ? [...controleQuinquennal.controleQuinquennalAxes].sort(
            (axe1, axe2) =>
              controleTypeOrder[axe1.type] - controleTypeOrder[axe2.type]
          )
        : [],
    [controleQuinquennal?.controleQuinquennalAxes]
  );

  const [axesWithRemediations, axesWithoutRemediation] = partition(
    axesSorted,
    (axe) =>
      controleQuinquennal?.status ===
        GatewayControleQuinquennal.ControleQuinquennalStatus.EN_COURS &&
      axe.status !==
        GatewayControleQuinquennal.ControleQuinquennalAxeStatus.VALIDE &&
      axe.hasRemediation
  );
  const hasRemediation =
    axesWithRemediations.length > 0 &&
    controleQuinquennal?.status ===
      GatewayControleQuinquennal.ControleQuinquennalStatus.EN_COURS;
  const labelTitle = useMemo(() => {
    if (
      controleQuinquennal?.status ===
      GatewayControleQuinquennal.ControleQuinquennalStatus.DEPOSE
    ) {
      return "Pièces déposées";
    }
    if (
      hasRemediation ||
      controleQuinquennal?.status ===
        GatewayControleQuinquennal.ControleQuinquennalStatus.TERMINE
    ) {
      return "Axes validés";
    }
    return "Pièces à fournir";
  }, [controleQuinquennal?.status, hasRemediation]);
  const hasActions =
    controleQuinquennal?.status ===
    GatewayControleQuinquennal.ControleQuinquennalStatus.EN_COURS;

  return (
    <MainContentPageTemplate
      actionNavigation={
        <Link to="/controle-quinquennal">
          <Button variant="text" icon={<ChevronLeft />}>
            Retour
          </Button>
        </Link>
      }
      title={
        <div className={styles.header}>
          <h1>Contrôle {controleQuinquennal?.millesime}</h1>
          <p>{entreprise?.raisonSociale}</p>
        </div>
      }
    >
      <MaxWidthContent size="large">
        <div
          className={classNames(styles.controleQuinquennalItem, {
            [styles.withActions]: hasActions,
          })}
        >
          {hasRemediation && (
            <InfoCard type="warning">
              Une action de remédiation est nécessaire sur certains axes. Nous
              vous invitons à prendre connaissance du commentaire laissé par le
              vérificateur et à transmettre les justificatifs complémentaires
              sollicités.
            </InfoCard>
          )}
          {controleQuinquennal?.status ===
            GatewayControleQuinquennal.ControleQuinquennalStatus.EN_COURS && (
            <InfoCard type="info">
              <p>
                Le contrôle quinquennal a débuté le{" "}
                {format(
                  new Date(controleQuinquennal.dateCreation),
                  "dd/MM/yyyy"
                )}
                .{" "}
              </p>
              <p>
                Vous avez jusqu&apos;au{" "}
                {getDateFinDepot(
                  controleQuinquennal.dateCreation,
                  controleQuinquennal.dateDerniereRemediation
                )}{" "}
                pour valider votre dépôt.
              </p>
            </InfoCard>
          )}
          {controleQuinquennal?.status ===
            GatewayControleQuinquennal.ControleQuinquennalStatus.DEPOSE &&
            controleQuinquennal.dateDepot && (
              <InfoCard type="info">
                Dossier déposé le{" "}
                {format(controleQuinquennal.dateDepot, "dd/MM/yyyy")}.
                Vérification en cours.
              </InfoCard>
            )}
          {controleQuinquennal &&
            controleQuinquennal.status ===
              GatewayControleQuinquennal.ControleQuinquennalStatus.DEPOSE && (
              <LeSaviezVousItem
                title={
                  <span>
                    L&apos;équipe vérification d&apos;Endya procède actuellement
                    à l&apos;analyse des pièces transmises et reste disponible
                    pour toute interrogation ou complément d&apos;information
                    que vous pourriez souhaiter au{" "}
                    <a href="tel:0189710939">01.89.71.09.39</a> ou par mail :{" "}
                    <a href="mail:verifications@endya.fr">
                      verifications@endya.fr
                    </a>
                    .
                  </span>
                }
                description={null}
                small
              />
            )}
          {controleQuinquennal &&
            controleQuinquennal.status !==
              GatewayControleQuinquennal.ControleQuinquennalStatus.DEPOSE && (
              <LeSaviezVousItem
                title={
                  <span>
                    A titre liminaire et, afin de faciliter vos démarches de
                    conformité, nous vous invitons à consulter les différents{" "}
                    <a href="https://aide.endya.fr/faq/guides-explicatifs-conformit%C3%A9">
                      guides pédagogiques
                    </a>{" "}
                    et{" "}
                    <a href="https://aide.endya.fr/faq/outils-conformite">
                      modèles
                    </a>{" "}
                    à votre disposition dans la{" "}
                    <a href="https://aide.endya.fr/faq/guides-explicatifs-conformit%C3%A9">
                      Foire aux questions
                    </a>
                    .
                  </span>
                }
                description={null}
                small
              />
            )}
          {hasRemediation && (
            <>
              <div className={styles.axeToCorrect}>
                <h2>Axes à corriger</h2>
                <BulletNumber
                  value={axesWithRemediations.length}
                  variant="error"
                  size="medium"
                />
              </div>
              <div className={styles.list}>
                {axesWithRemediations.map((axe) => (
                  <ControleQuinquennalItemCard
                    key={axe.idAxe}
                    axe={axe}
                    controleQuinquennal={controleQuinquennal}
                  />
                ))}
              </div>
            </>
          )}
          <h2>{labelTitle}</h2>
          <div className={styles.list}>
            {!controleQuinquennal &&
              new Array(4).fill(0).map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <SkeletonCard key={index} height={0} />
              ))}
            {axesWithoutRemediation.map((axe) => (
              <ControleQuinquennalItemCard
                key={axe.idAxe}
                axe={axe}
                controleQuinquennal={controleQuinquennal}
              />
            ))}
          </div>
        </div>
        {hasActions && (
          <ControleQuinquennalItemActions
            isLoading={isLoading}
            isDisabled={!areAllAxesComplet}
            onValidate={handleValidateDepot}
          />
        )}
      </MaxWidthContent>
    </MainContentPageTemplate>
  );
}
