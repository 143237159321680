export type AvancementAssujetti = {
  idAssujetti: string;
  millesime: number;
  honorabilite: number | null;
  capaciteProfessionnelleIas: number | null;
  capaciteProfessionnelleIobsp: number | null;
  formationContinueIas: number | null;
  formationContinueIobsp: number | null;
};

export enum AvancementStatus {
  INITIE = 0,
  EDITE = 50,
  COMPLET = 100,
}
