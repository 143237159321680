import {
  Button,
  ButtonVariant,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactElement, useCallback } from "react";
import { format } from "date-fns";
import {
  addSocieteDataValidationToSheet,
  downloadSheetFromWorkbook,
  importWorkBookFromFile,
  insertDataInSheet,
} from "@src/helper/excel/excel.export.helper";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { GatewayEntreprise } from "@conformite/gateway";
import { transformEntrepriseApiRowsToRows } from "./EntrepriseExportMapping";
import {
  entrepriseExcelExportTemplate,
  entrepriseExcelTemplate,
} from "./EntrepriseExcelTemplate";

type ExportButtonProps = {
  children: string;
  entreprises: GatewayEntreprise.EntrepriseAControllerResponseType[];
  variant?: ButtonVariant;
  className?: string;
  icon?: ReactElement;
};

export function ExportEntrepriseButton({
  children,
  entreprises,
  variant = "outline",
  className,
  icon,
}: ExportButtonProps) {
  const handleExport = useCallback(async () => {
    const workbook = await importWorkBookFromFile("/modele_societe.xlsx");
    const sheet = workbook.worksheets[0];
    const entreprisesAsArray = entreprises
      ? Object.values(entreprises)
      : undefined;
    if (!entreprisesAsArray) return;
    insertDataInSheet(
      entreprisesAsArray.map(transformEntrepriseApiRowsToRows),
      sheet,
      entrepriseExcelTemplate,
      entrepriseExcelExportTemplate
    );
    addSocieteDataValidationToSheet(sheet, entreprisesAsArray.length);

    await downloadSheetFromWorkbook(
      `export_entreprise_${format(new Date(), "dd-MM-yyyy_HH:mm")}.xlsx`,
      workbook
    );
  }, [entreprises]);

  return (
    <Button
      variant={variant}
      className={className}
      icon={icon}
      onClick={() => {
        handleExport().catch(
          handleGatewayError({
            onUnhandled: () => displayErrorToast("Une erreur est survenue"),
          })
        );
      }}
    >
      {children}
    </Button>
  );
}
