import { Table } from "@src/components/Table/Table";
import { SkeletonContent } from "../SkeletonContent";

type SkeletonTableProps = {
  size: (number | string)[];
  lines?: number;
};
export function SkeletonTable({ size, lines = 3 }: SkeletonTableProps) {
  return (
    <Table
      data={new Array(lines).fill(0).map((_, index) => index)}
      columns={size.map((cell) => ({
        key: `${cell}${Math.random()}}`,
        size: cell,
        render: () => <SkeletonContent />,
      }))}
    />
  );
}
