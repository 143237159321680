import { eachDayOfInterval, isBefore, isLeapYear } from "date-fns";
import { chain } from "lodash";

const datesToInterval = (date1: Date, date2: Date): Interval => {
  return isBefore(date1, date2)
    ? {
        start: date1,
        end: date2,
      }
    : {
        start: date2,
        end: date1,
      };
};
export const millesimeHasEveryDay = (
  millesime: number,
  files: (
    | {
        startDate?: Date;
        endDate?: Date;
      }
    | undefined
  )[]
) => {
  const numberOfDays = isLeapYear(new Date(millesime, 0, 1)) ? 366 : 365;
  const hasEveryDaysInYear =
    chain(files)
      .flatMap((file) => {
        if (!file?.startDate || !file?.endDate) return new Date(0);
        const interval = datesToInterval(file.startDate, file.endDate);
        const days = eachDayOfInterval(interval);
        return days;
      })
      .filter((day) => !!day && day.getFullYear() === millesime)
      .uniqBy((day) => day?.toISOString())
      .value().length === numberOfDays;

  return hasEveryDaysInYear;
};
