import {
  PrismicProvider,
  PrismicToolbar,
  usePrismicDocumentByID,
} from "@prismicio/react";
import { useSearchParams } from "react-router-dom";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { useMemo } from "react";
import { prismicClient, repositoryName } from "../prismic";
import { SliceManager } from "../slice/SliceManager";
import { SliceBody } from "../slice/Slice.definition";

function CmsPreviewContent() {
  const [query] = useSearchParams();
  const documentId = query.get("documentId");
  const [document] = usePrismicDocumentByID(documentId ?? "");
  const body = useMemo(() => {
    if (!document) {
      return undefined;
    }
    return document.data as unknown as SliceBody;
  }, [document]);
  if (!document || !["actualite", "generique"].includes(document.type))
    return null;

  return (
    <MaxWidthContent size="big">
      {body && <SliceManager body={body} />}
    </MaxWidthContent>
  );
}
export function CmsPreview() {
  return (
    <PrismicProvider client={prismicClient}>
      <CmsPreviewContent />
      <PrismicToolbar repositoryName={repositoryName} />
    </PrismicProvider>
  );
}
