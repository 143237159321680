import { useContext, useEffect } from "react";
import {
  StoreContext,
  StoreContextValue,
  StoreEntryFetchStatus,
} from "./store";

export function useStore() {
  return useContext(StoreContext);
}

type UseStoreEntryReturn<TData> = {
  data: TData | undefined;
  setData: (next: TData) => void;
  status: StoreEntryFetchStatus;
  setStatus: (next: StoreEntryFetchStatus) => void;
};

/**
 * useStoreEntry
 * @note could break if the key changes between calls
 */
export function useStoreEntry<
  TKey extends keyof Omit<StoreContextValue, "millesime" | "refresh">,
  TData extends StoreContextValue[TKey]["data"]
>(
  key: TKey,
  fetchFn: () => Promise<TData>,
  handleErrorFn: (error: unknown) => void
): UseStoreEntryReturn<TData> {
  const storeValue = useStore();
  const { setData, setStatus, status, data } = storeValue[key];

  useEffect(() => {
    if (status === "init") {
      setStatus("loading");
      fetchFn()
        .then((fetchedData) => {
          (setData as (next: TData) => void)(fetchedData);
        })
        .catch(handleErrorFn)
        .finally(() => setStatus("loaded"));
    }
  }, [key, data, status, fetchFn, handleErrorFn, setData, setStatus]);

  return {
    data: data as TData,
    setData: setData as (next: TData) => void,
    status,
    setStatus,
  };
}
