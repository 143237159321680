import { MeEntreprisePrincipaleType, MeType } from '../service/me.definition';
import { CreationContactRequestType } from '../../creation-contact/controller/creation-contact.definition';

export const meControllerUrl = 'me';

export type GetMeResponse = MeType;
export type MeEntreprisePrincipale = MeEntreprisePrincipaleType;

export type UpdateMeRequestType = Required<
  Pick<
    CreationContactRequestType,
    'civilite' | 'nom' | 'prenom' | 'telephone' | 'fonction'
  >
>;
