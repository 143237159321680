import { GatewayControleQuinquennal } from "@conformite/gateway";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Form,
  InputTextArea,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  Button,
  FormField,
  FormGrid,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as TrashIcon } from "@src/assets/icons/trash.svg";
import { FileInputButtonWithDrag } from "@src/components/FileInputButton/FileInputButtonWithDrag";
import { useCallback } from "react";
import style from "./ControleQuinquennalRemediationForm.module.scss";

const schema = zod.object({
  files: zod.array(
    zod.object({
      idFile: zod.string().optional(),
      file: zod.instanceof(File).optional(),
      pathname: zod.string(),
      filename: zod.string(),
    })
  ),
  description: zod.string().min(1),
});
export type RemediationFormValues = zod.infer<typeof schema>;
type RemediationFormValueFile = RemediationFormValues["files"][0];

type RemediationFormProps = {
  onSubmit: (values: RemediationFormValues) => unknown;
  formId: string;
  defaultValue?: GatewayControleQuinquennal.ControleQuinquenalRemediationResponseType;
};
export function ControleQuinquennalRemediationForm({
  onSubmit,
  formId,
  defaultValue,
}: RemediationFormProps) {
  const formConfig = useFormConfig<typeof schema, RemediationFormValues>({
    schema,
    defaultValues: { files: [], ...defaultValue },
  });

  const handleAddFile = useCallback(
    (file: File[]) => {
      if (file.length === 0) return;
      formConfig.setValue("files", [
        ...formConfig.getValues().files,
        ...file.map((f) => ({
          file: f,
          filename: f.name,
          pathname: Math.random().toString(10),
        })),
      ]);
    },
    [formConfig]
  );

  const handleRemoveFile = useCallback(
    (file: RemediationFormValueFile) => () => {
      formConfig.setValue(
        "files",
        formConfig.getValues("files").filter((fileCheck) => file !== fileCheck)
      );
    },
    [formConfig]
  );

  const files = formConfig.watch("files");

  return (
    <Form
      id={formId}
      formConfig={formConfig}
      onSubmit={(payload) => onSubmit(payload)}
    >
      <FormGrid disableHorizontalGutter>
        <FormRow>
          <FormField>
            <InputTextArea<RemediationFormValues>
              name="description"
              label="Laisser un commentaire"
              rows={10}
              required
              className={style.textArea}
            />
          </FormField>
        </FormRow>

        <FormRow>
          <FormField>
            <div className={style.files}>
              {files?.map((file) => (
                <div key={file.pathname} className={style.file}>
                  <p>{file.filename}</p>
                  <Button
                    variant="text"
                    icon={<TrashIcon />}
                    onClick={handleRemoveFile(file)}
                  />
                </div>
              ))}
            </div>
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <FileInputButtonWithDrag
              variant="input"
              onChange={handleAddFile}
              multiple
            >
              <p>Ajouter une pièce jointe</p>
            </FileInputButtonWithDrag>
          </FormField>
        </FormRow>
      </FormGrid>
    </Form>
  );
}
