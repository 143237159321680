import { Card } from "@lya-protect/lya-protect-form-library/dist/UI";
import { DropdownButton, DropdownButtonProps } from "./DropdownButton";
import styles from "./Dropdown.module.scss";

export function Dropdown({
  content,
  placement,
  children,
  icon,
  variant,
  className,
  dismissOnClick,
}: DropdownButtonProps) {
  return (
    <DropdownButton
      content={content}
      placement={placement}
      icon={icon}
      variant={variant}
      className={className}
      withIcon={!!icon}
      dismissOnClick={dismissOnClick}
    >
      {(props) => (
        <Card className={styles.headerAction}>{children(props)}</Card>
      )}
    </DropdownButton>
  );
}
