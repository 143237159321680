import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as ArrowRight } from "@src/assets/icons/arrow-right.svg";
import { Link } from "@src/components/Link/Link";
import styles from "./CmsHeader.module.scss";

type CmsHeaderProps = {
  title: string;
  to: string;
};
export function CmsHeader({ title, to }: CmsHeaderProps) {
  return (
    <div className={styles.header}>
      <hr className={styles.delimiter} />
      <div className={styles.row}>
        <h2>{title}</h2>
        <Link to={to}>
          <Button variant="text" icon={<ArrowRight />}>
            Voir tout
          </Button>
        </Link>
      </div>
    </div>
  );
}
