import { GatewaySearchExistingEntreprises } from "@conformite/gateway";
import { userSession } from "@src/session/UserSession";

export class GatewaySearchExistingEntreprisesAPI {
  public static async bySirensExcludingPrincipale(
    payload: GatewaySearchExistingEntreprises.SearchExistingEntreprisesType
  ): Promise<string[]> {
    const { data } = await userSession.axiosInstance.post<string[]>(
      "/search-existing-entreprises",
      payload
    );
    return data;
  }
}
