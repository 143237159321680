import { ReactElement } from "react";
import { Link as RouterLink } from "react-router-dom";
import classNames from "classnames";
import styles from "./Link.module.scss";

export type LinkProps = {
  className?: string;
  to: string;
  children: string | ReactElement;
  target?: React.HTMLAttributeAnchorTarget | undefined;
};
export function Link({ className, ...props }: LinkProps) {
  return (
    <RouterLink {...props} className={classNames(styles.link, className)} />
  );
}
