import { GatewayAssujetti } from "@conformite/gateway";
import { useEffect, useState } from "react";

export const useControleQuinquennalForm = <T>(
  assujetti: GatewayAssujetti.AssujettiWithAvancement | undefined,
  needed: boolean,
  call: () => Promise<T>
) => {
  const [content, setContent] = useState<T | null>();
  const [hasLoaded, setHasLoaded] = useState(false);
  useEffect(() => {
    if (!assujetti) return;
    if (!needed) {
      setHasLoaded(true);
      return;
    }
    call()
      .then((res) => {
        setContent(res);
      })
      .catch(console.error)
      .finally(() => setHasLoaded(true));
  }, [assujetti, needed, call]);
  return { content, hasLoaded };
};
