import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as ChevronLeft } from "@src/assets/icons/chevron-left.svg";

type PreviousButtonProps<TPath extends string> = {
  history?: TPath[];
  changeToPrevStep: () => void;
  handleQuit?: (data: unknown) => void;
};

export function PreviousButton<TPath extends string>({
  history,
  changeToPrevStep,
  handleQuit,
}: PreviousButtonProps<TPath>) {
  if (history?.length === 1) {
    if (!handleQuit) return <div />;

    return (
      <Button variant="text" icon={<ChevronLeft />} onClick={handleQuit}>
        Quitter
      </Button>
    );
  }
  return (
    <Button variant="text" onClick={changeToPrevStep} icon={<ChevronLeft />}>
      Précédent
    </Button>
  );
}
