import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Form,
  InputButtonGroup,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayInfoSocieteOrias } from "@conformite/gateway";
import { forwardRef, useCallback, useImperativeHandle, useMemo } from "react";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { recordToSelectOptions } from "@src/adhesion/AdhesionData/adhesionData";
import { UseFormReturn } from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import style from "./EntrepriseSousCategoriesActiviteForm.module.scss";

const ERROR_MESSAGE = "Vous devez choisir au moins une sous-catégorie";

const schema = zod
  .object({
    needMIOBSP: zod.boolean(),
    needCOBSP: zod.boolean(),
    sousCategoriesCOBSP: zod.array(
      zod.nativeEnum(GatewayInfoSocieteOrias.ActiviteBancaire)
    ),
    sousCategoriesMIOBSP: zod.array(
      zod.nativeEnum(GatewayInfoSocieteOrias.ActiviteBancaire)
    ),
  })
  .refine(
    (data) => (data.needCOBSP ? data.sousCategoriesCOBSP.length > 0 : true),
    {
      message: ERROR_MESSAGE,
      path: ["sousCategoriesCOBSP"],
    }
  )
  .refine(
    (data) => (data.needMIOBSP ? data.sousCategoriesMIOBSP.length > 0 : true),
    {
      message: ERROR_MESSAGE,
      path: ["sousCategoriesMIOBSP"],
    }
  );

export type EntrepriseSousCategoriesActiviteFormValues = zod.infer<
  typeof schema
>;

export const sousCategorieLabel: Record<
  GatewayInfoSocieteOrias.ActiviteBancaire,
  string
> = {
  [GatewayInfoSocieteOrias.ActiviteBancaire.SERVICE_PAIEMENT]:
    "Service de paiement",
  [GatewayInfoSocieteOrias.ActiviteBancaire.REGROUPEMENT_CREDITS]:
    "Regroupement de crédits",
  [GatewayInfoSocieteOrias.ActiviteBancaire.CREDIT_CONSOMMATION]:
    "Crédit à la consommation",
  [GatewayInfoSocieteOrias.ActiviteBancaire.PRET_VIAGER_HYPOTECAIRE]:
    "Prêt viager hypothécaire",
  [GatewayInfoSocieteOrias.ActiviteBancaire.CREDIT_IMMOBILIER]:
    "Crédit immobilier",
  [GatewayInfoSocieteOrias.ActiviteBancaire.AUTRES_ACTIVITES]:
    "Autres activités",
};

const sousCategoriesOptions = recordToSelectOptions(sousCategorieLabel);

type EntrepriseSousCategoriesActiviteFormProps = {
  defaultValues: EntrepriseSousCategoriesActiviteFormValues;
  onSubmit: (response: EntrepriseSousCategoriesActiviteFormValues) => void;
  formId?: string;
};
export type EntrepriseSousCategoriesActiviteFormHandle = {
  getFormConfig: () => UseFormReturn<EntrepriseSousCategoriesActiviteFormValues>;
};
export const EntrepriseSousCategoriesActiviteForm = forwardRef<
  EntrepriseSousCategoriesActiviteFormHandle,
  EntrepriseSousCategoriesActiviteFormProps
>(({ defaultValues, onSubmit, formId }, ref) => {
  const formConfig = useFormConfig<
    typeof schema,
    EntrepriseSousCategoriesActiviteFormValues
  >({
    schema,
    defaultValues,
  });

  useImperativeHandle(ref, () => ({
    getFormConfig() {
      return formConfig;
    },
  }));

  const sousCategoriesCOBSPWatcher = formConfig.watch("sousCategoriesCOBSP");
  const sousCategoriesMIOBSPWatcher = formConfig.watch("sousCategoriesMIOBSP");

  const hasCumulSousCategories = useMemo<boolean>(() => {
    const sousCategorieNonCumulable = [
      GatewayInfoSocieteOrias.ActiviteBancaire.REGROUPEMENT_CREDITS,
      GatewayInfoSocieteOrias.ActiviteBancaire.PRET_VIAGER_HYPOTECAIRE,
      GatewayInfoSocieteOrias.ActiviteBancaire.CREDIT_IMMOBILIER,
    ];
    const isSousCategorieNonCumulable = (
      sousCategorie: GatewayInfoSocieteOrias.ActiviteBancaire
    ): boolean => sousCategorieNonCumulable.includes(sousCategorie);

    const mappedSousCategoriesCOBSP = sousCategoriesCOBSPWatcher
      .filter(isSousCategorieNonCumulable)
      .reduce(
        (acc, curr) => ({ ...acc, [curr]: true }),
        {} as Record<(typeof sousCategorieNonCumulable)[number], boolean>
      );

    return !!sousCategoriesMIOBSPWatcher
      .filter(isSousCategorieNonCumulable)
      .find(
        (sousCategorieMIOBSP) => mappedSousCategoriesCOBSP[sousCategorieMIOBSP]
      );
  }, [sousCategoriesCOBSPWatcher, sousCategoriesMIOBSPWatcher]);

  const handleSubmit = useCallback(
    (response: EntrepriseSousCategoriesActiviteFormValues) => {
      onSubmit(response);
    },
    [onSubmit]
  );

  return (
    <Form formConfig={formConfig} onSubmit={handleSubmit} id={formId}>
      {defaultValues.needCOBSP && (
        <FormRow>
          <FormField>
            <InputButtonGroup<
              EntrepriseSousCategoriesActiviteFormValues,
              true,
              GatewayInfoSocieteOrias.ActiviteBancaire
            >
              options={sousCategoriesOptions}
              name="sousCategoriesCOBSP"
              label={
                <b className={style.label}>
                  Sélectionnez au moins une sous-catégorie à votre activité
                  COBSP :
                </b>
              }
              isMultiple
              variant="outline-pop"
              size="large"
            />
          </FormField>
        </FormRow>
      )}

      {defaultValues.needMIOBSP && (
        <FormRow>
          <FormField>
            <InputButtonGroup<
              EntrepriseSousCategoriesActiviteFormValues,
              true,
              GatewayInfoSocieteOrias.ActiviteBancaire
            >
              options={sousCategoriesOptions}
              name="sousCategoriesMIOBSP"
              label={
                <b className={style.label}>
                  Sélectionnez au moins une sous-catégorie à votre activité
                  MIOBSP :
                </b>
              }
              variant="outline-pop"
              size="large"
              isMultiple
            />
          </FormField>
        </FormRow>
      )}

      {hasCumulSousCategories && (
        <InfoCard type="warning" className={style.errorCard}>
          Vous avez déclaré la même opération de banque pour l&apos;activité de
          COBSP et de MIOBSP. Nous vous rappelons que l&apos;article R. 519-4 II
          du code monétaire et financier prévoit une interdiction de cumul
          d&apos;inscription en IOBSP pour une meme opération de banque. Nous
          vous invitons à mettre à jour cette information.
        </InfoCard>
      )}
    </Form>
  );
});
