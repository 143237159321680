import classNames from "classnames";
import { Toaster } from "@lya-protect/lya-protect-form-library/dist/UI";
import { Header } from "@src/components/Header/Header";
import { ToastContent } from "@src/components/ToastContent/ToastContent";
import { ProgressBar } from "@src/components/ProgressBar/ProgressBar";
import { UIParcoursComponentProps } from "@src/parcours/Parcours.types";
import style from "./UIParcoursFullPage.module.scss";
import { PreviousButton } from "./NavigationButtons/PreviousButton";

export const SUBMIT_BUTTON_PORTAL_TARGET_ID = "submit-button-target";

export function UIParcoursFullPage<TStepEnum extends string>({
  children,
  progress,
  centeredContent,
  hasPrevious,
  handleQuit,
  history,
  changeToPrevStep,
  title,
}: UIParcoursComponentProps<TStepEnum>) {
  return (
    <div className={style.parcours}>
      <Header title={title} />
      <div
        className={classNames(
          style.contentWrapper,
          centeredContent && style["contentWrapper--centered"]
        )}
      >
        {children}
      </div>
      <div
        className={classNames(style.footer, {
          [style.footerWithBorder]: progress === undefined,
        })}
      >
        <Toaster
          canal="adhesion"
          positionning="local"
          position={{
            vertical: "top",
            horizontal: "center",
          }}
          ToastContentComponent={ToastContent}
          defaultHideAfter={5000}
        />
        {progress !== undefined && <ProgressBar step={progress} total={100} />}
        <div className={style.buttons}>
          <div>
            {hasPrevious && (
              <PreviousButton
                history={history}
                handleQuit={handleQuit}
                changeToPrevStep={changeToPrevStep}
              />
            )}
          </div>
          <div
            id={SUBMIT_BUTTON_PORTAL_TARGET_ID}
            className={style.actionsGroup}
          />
        </div>
      </div>
    </div>
  );
}
