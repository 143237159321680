import { Button, Card } from "@lya-protect/lya-protect-form-library/dist/UI";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { downloadFile } from "@src/helper/file.helper";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ReactComponent as Loader } from "@src/assets/loader.svg";
import { ReactComponent as XCircle } from "@src/assets/icons/x-circle.svg";
import { GatewayDocumentApi } from "@src/api/document.api";
import styles from "./Document.module.scss";

function getValidParams(siren?: string, pathname?: string | null) {
  if (!siren || !pathname) {
    return null;
  }
  return {
    siren,
    pathname,
  };
}

export function DocumentViewer() {
  const [query] = useSearchParams();
  const pathname = query.get("pathname");
  const { siren } = useParams<{ siren: string }>();
  const [isError, setIsError] = useState(false);

  const validParams = getValidParams(siren, pathname);
  useEffect(() => {
    if (!validParams) return;
    GatewayDocumentApi.getDocument(validParams.siren, validParams.pathname)
      .then((data) => {
        downloadFile(data, `document_a_valider${new Date().toISOString()}.pdf`);
        window.close();
      })
      .catch(
        handleGatewayError({
          onUnhandled: (error) => {
            console.error(error);
            setIsError(true);
          },
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!validParams) {
    return (
      <Card className={styles.card}>
        <>
          <div className={styles.header}>
            <XCircle className={styles.icon} />
            <h1>OUPS</h1>
          </div>
          <p>
            Les paramètres de récupération du document sont invalides, vérifiez
            l&apos;url
          </p>
        </>
      </Card>
    );
  }

  if (!isError) {
    return <Loader />;
  }
  return (
    <Card className={styles.card}>
      <div className={styles.header}>
        <XCircle className={styles.icon} />
        <h1>OUPS</h1>
      </div>
      <p>une erreur est survenue lors de la récupération de votre document</p>
      <Button
        onClick={() => {
          window.close();
        }}
        className={styles.button}
      >
        Fermer la page
      </Button>
    </Card>
  );
}
