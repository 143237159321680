import { Header } from "@src/components/Header/Header";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";

type ForgotPasswordData = {
  email: string;
};

export type ForgotPasswordOutletContext = {
  data: ForgotPasswordData;
  setData: (data: ForgotPasswordData) => void;
};

export function ForgotPassword() {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState<ForgotPasswordData>({
    email: searchParams.get("email") ?? "",
  });

  return (
    <div>
      <Header />
      <MaxWidthContent>
        <Outlet context={{ data, setData }} />
      </MaxWidthContent>
    </div>
  );
}
