import { GatewayContact, GatewayCreationContact } from "@conformite/gateway";
import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayCreationContactApi } from "@src/api/creation-contact.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { ContactsWithAssociations } from "@src/store/store.definition";
import { useCallback, useEffect, useState } from "react";
import { SidePane } from "@src/components/SidePane/SidePane";
import { GatewayContactApi } from "@src/api/contact.api";
import { AjoutAdministrateurPaneContent } from "./AjoutAdministrateurPane";
import { AjoutUtilisateurPaneContent } from "./AjoutUtilisateurPane";

export enum AjoutContactPaneType {
  ADMIN = "ADMIN",
  USER = "USER",
}

type AjoutContactPaneProps = {
  contact?: GatewayContact.ContactWithAssociationsResponseType;
  type?: AjoutContactPaneType;
  reopenKey: number;
  executeAfterAdd: (contact: ContactsWithAssociations) => void;
  executeAfterUpdate: (contact: ContactsWithAssociations) => void;
};

export function AjoutContactPane({
  type,
  reopenKey,
  executeAfterAdd,
  executeAfterUpdate,
  contact,
}: AjoutContactPaneProps) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(!!type);
  }, [reopenKey, type]);

  const handleUpdate = useCallback(
    async (
      contactId: string,
      values: GatewayContact.UpdateContactRequestType,
      closePane: () => void
    ) => {
      try {
        const updatedContact =
          await GatewayContactApi.updateContactWithAssociations(
            contactId,
            values
          );
        executeAfterUpdate(updatedContact);
        closePane();
      } catch (err) {
        handleGatewayError({
          onUnhandled: (error) => {
            console.error(error);
            displayErrorToast(
              `Une erreur est survenue lors de la modification du contact`
            );
          },
        })(err);
      }
    },
    [executeAfterUpdate]
  );

  const handleCreate = useCallback(
    async (
      values: GatewayCreationContact.CreationContactRequestType,
      closePane: () => void
    ) => {
      try {
        const createdContact = await GatewayCreationContactApi.create(values);
        executeAfterAdd(createdContact);
        closePane();
      } catch (err) {
        handleGatewayError({
          onResponse: (error) => {
            if (error.response?.status === 409) {
              displayErrorToast(
                `Un contact avec l'adresse email ${values.email} existe déjà`
              );
            }
          },
          onUnhandled: (error) => {
            console.error(error);
            displayErrorToast(
              `Une erreur est survenue lors de la création du contact`
            );
          },
        })(err);
        throw err;
      }
    },
    [executeAfterAdd]
  );

  const handleSubmit = useCallback(
    (close: (unmount: true) => void) =>
      (values: GatewayCreationContact.CreationContactRequestType) => {
        if (contact) return handleUpdate(contact.id, values, () => close(true));
        return handleCreate(values, () => close(true));
      },
    [contact, handleCreate, handleUpdate]
  );

  return (
    <SidePane isOpen={isOpen} setIsOpen={setIsOpen}>
      {(closePane) => {
        if (type === AjoutContactPaneType.ADMIN) {
          return (
            <AjoutAdministrateurPaneContent
              isEdit={contact !== undefined}
              key={contact?.id}
              defaultValues={contact}
              onSubmit={handleSubmit(closePane)}
              buttonLabel={contact ? "Modifier" : "Envoyer l'invitation"}
            />
          );
        }
        if (type === AjoutContactPaneType.USER) {
          return (
            <AjoutUtilisateurPaneContent
              isEdit={contact !== undefined}
              key={contact?.id}
              defaultValues={contact}
              onSubmit={handleSubmit(closePane)}
              buttonLabel={contact ? "Modifier" : "Envoyer l'invitation"}
            />
          );
        }
        return null;
      }}
    </SidePane>
  );
}
