import { ToastContentProps } from "@lya-protect/lya-protect-form-library/dist/UI";
import classNames from "classnames";
import { InfoCard } from "../InfoCard/InfoCard";
import style from "./toastContent.module.scss";

export function ToastContent({
  hideToast,
  level,
  message,
  className,
}: ToastContentProps) {
  return (
    <InfoCard
      type={level}
      className={classNames(className, style.toastContent)}
      onCancel={hideToast}
    >
      {message}
    </InfoCard>
  );
}
