import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import { ParcoursAction } from "@src/parcours/NavigationButtons/ParcoursAction";
import {
  ParcoursRenouvellementStepComponentProps,
  RenouvellementStepEnum,
} from "@src/renouvellement/ParcoursRenouvellement.definition";
import style from "./RenouvellementIntroductionPage.module.scss";

export function RenouvellementIntroductionPage({
  changeStep,
}: ParcoursRenouvellementStepComponentProps) {
  return (
    <ParcoursFullPageTemplate
      actions={
        <ParcoursAction
          onClick={() => changeStep(RenouvellementStepEnum.RESUME_ENTREPRISE)}
        >
          Suivant
        </ParcoursAction>
      }
      title={
        <h1>
          Afin de renouveler votre adhésion, merci de
          <span className={style.textAccent}>
            {` modifier et compléter si besoin `}
          </span>
          les informations que nous possédons déjà.
        </h1>
      }
    >
      <p className={style.information}>
        Vous pouvez à tout moment sauvegarder votre saisie et y revenir plus
        tard.
      </p>
    </ParcoursFullPageTemplate>
  );
}
