import classNames from "classnames";
import { ColumnDefinition } from "../Table.definition";
import { TableSelect } from "./TableSelect";
import styles from "../Table.module.scss";
import { TableSelectionState } from "./useTableSelection";

type TableHeaderProps<T> = {
  columns: ColumnDefinition<T>[];
  renderSelectionActions?: (selectedRows: T[]) => React.ReactElement;
  selectionState: TableSelectionState<T> | null;
};

export function TableHeader<T>({
  columns,
  selectionState,
  renderSelectionActions,
}: TableHeaderProps<T>) {
  return (
    <>
      {selectionState !== null && (
        <div
          className={classNames(styles.tableHeader, {
            [styles.firstHeaderCell]: true,
            [styles.lastHeaderCell]: columns.length === 0,
          })}
        >
          <div className={styles.cellInner}>
            <TableSelect
              selected={selectionState.allSelected}
              name="select-all"
              onSelect={selectionState.toggleAll}
            />
          </div>
        </div>
      )}
      {renderSelectionActions && selectionState?.atLeastOneSelected && (
        <div
          className={styles.tableHeader}
          style={{
            gridColumn: `span ${columns.length}`,
          }}
        >
          {renderSelectionActions(selectionState.selectedRows)}
        </div>
      )}
      {(selectionState === null || !selectionState.atLeastOneSelected) &&
        columns.map((column, index) => (
          <div
            key={column.key}
            className={classNames(styles.tableHeader, {
              [styles.firstHeaderCell]: selectionState === null && index === 0,
              [styles.lastHeaderCell]:
                index + (selectionState !== null ? 1 : 0) ===
                columns.length - 1,
            })}
          >
            {column.title}
          </div>
        ))}
    </>
  );
}
