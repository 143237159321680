import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Form,
  InputText,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  Button,
  FormAction,
  FormField,
  FormGrid,
  FormNotice,
  FormRow,
  FormTitle,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayAuthApi } from "@src/api/auth.api";
import { ReactComponent as XIcon } from "@src/assets/icons/x-lg.svg";
import { Link } from "@src/components/Link/Link";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ForgotPasswordOutletContext } from "./ForgotPassword";
import style from "./ForgotPasswordMail.module.scss";

const schema = zod.object({
  email: zod.string().nonempty().email(),
});

export type ForgotPasswordMailFormValue = zod.infer<typeof schema>;

export function ForgotPasswordMail() {
  const navigate = useNavigate();
  const { setData, data } = useOutletContext<ForgotPasswordOutletContext>();
  const formConfig = useFormConfig<typeof schema, ForgotPasswordMailFormValue>({
    schema,
    defaultValues: { email: data.email },
  });

  const handleSubmit = async (
    submittedData: ForgotPasswordMailFormValue
  ): Promise<void> => {
    try {
      await GatewayAuthApi.forgotPassword(submittedData.email);
      setData({ email: submittedData.email });
      navigate("/forgot-password/reset");
    } catch (err) {
      displayErrorToast("Une erreur est survenue lors de l'envoi du mail.");
    }
  };

  return (
    <Form formConfig={formConfig} onSubmit={handleSubmit}>
      <FormGrid disableHorizontalGutter>
        <FormTitle>
          <h1>Mot de passe oublié</h1>
        </FormTitle>
        <FormRow>
          <FormField>
            <FormNotice>
              Renseignez votre adresse mail, nous vous enverrons un lien pour
              récupérer votre compte.
            </FormNotice>
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <InputText<ForgotPasswordMailFormValue>
              name="email"
              label="Adresse email"
            />
          </FormField>
        </FormRow>

        <FormAction className={style.actions}>
          <Link to="/login">
            <Button type="button" variant="text" icon={<XIcon />}>
              Annuler
            </Button>
          </Link>
          <Button type="submit">Envoyer le code par email</Button>
        </FormAction>
      </FormGrid>
    </Form>
  );
}
