import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { Assujetti } from "@src/store/store.definition";
import { ReactComponent as ChevronLeftIcon } from "@src/assets/icons/chevron-left.svg";
import styles from "./AssujettiPaneContent.module.scss";
import { useInnerNavigation } from "./useInnerNavigation";
import {
  AssujettiParcoursConformite,
  AssujettiParcoursConformiteEnum,
} from "./pages/AssujettiParcoursConformite/AssujettiParcoursConformite";
import { AssujettiInfoForm } from "./pages/AssujettiInfoForm/AssujettiInfoForm";
import { AssujettiDetailConformite } from "./pages/AssujettiDetailConformite/AssujettiDetailConformite";

export type AssujettiPaneContentPage =
  | "information-personne"
  | "form-honorabilite"
  | "detail-conformite"
  | "form-formation-ias"
  | "form-formation-iobsp"
  | "form-capacite-pro-iobsp"
  | "form-capacite-pro-ias";

const PageToParcoursStep = {
  "form-honorabilite": AssujettiParcoursConformiteEnum.HONORABILITE,
  "form-formation-ias": AssujettiParcoursConformiteEnum.FORMATION_CONTINUE_IAS,
  "form-formation-iobsp":
    AssujettiParcoursConformiteEnum.FORMATION_CONTINUE_IOBSP,
  "form-capacite-pro-iobsp": AssujettiParcoursConformiteEnum.CAPACITE_PRO_IOBSP,
  "form-capacite-pro-ias": AssujettiParcoursConformiteEnum.CAPACITE_PRO_IAS,
};

type RenderPageOptions = {
  activePage: AssujettiPaneContentPage;
  millesime: number;
  readonly?: boolean;
  setEntry: (newEntry: Assujetti) => void;
  closePane: (unmpount: boolean) => void;
  assujetti: Assujetti | null;
  navigate: (
    nextPage: AssujettiPaneContentPage,
    resetHistoryWith?: AssujettiPaneContentPage[]
  ) => void;
  defaultSiren?: string;
};

function renderPage({
  activePage,
  millesime,
  setEntry,
  assujetti,
  closePane,
  navigate,
  readonly,
  defaultSiren,
}: RenderPageOptions) {
  switch (activePage) {
    case "information-personne":
      return (
        <AssujettiInfoForm
          defaultSiren={defaultSiren}
          readonly={readonly}
          assujetti={assujetti}
          millesime={millesime}
          afterSubmit={(submittedAssujetti, shouldCompleteConformite) => {
            setEntry(submittedAssujetti);
            if (shouldCompleteConformite) {
              navigate("form-honorabilite", [
                "detail-conformite",
                "form-honorabilite",
              ]);
            } else {
              closePane(true);
            }
          }}
        />
      );
    case "detail-conformite":
      if (!assujetti) return null;
      return (
        <AssujettiDetailConformite navigate={navigate} assujetti={assujetti} />
      );
    default:
      if (!assujetti) return null;
      if (Object.keys(PageToParcoursStep).includes(activePage)) {
        return (
          <AssujettiParcoursConformite
            readonly={readonly}
            assujetti={assujetti}
            closePane={() => closePane(true)}
            firstStep={PageToParcoursStep[activePage]}
          />
        );
      }
      return null;
  }
}
type AssujettiPaneContentProps = {
  assujetti: Assujetti | null;
  millesime: number;
  setEntry: (newEntry: Assujetti) => void;
  closePane: (unmpount: boolean) => void;
  initialPage: AssujettiPaneContentPage;
  readonly?: boolean;
  defaultSiren?: string;
};

export function AssujettiPaneContent({
  setEntry,
  millesime,
  assujetti,
  closePane,
  initialPage,
  readonly,
  defaultSiren,
}: AssujettiPaneContentProps) {
  const { activePage, goBack, navigate } =
    useInnerNavigation<AssujettiPaneContentPage>(initialPage);

  return (
    <div>
      {goBack && (
        <Button
          icon={<ChevronLeftIcon />}
          iconVariant="left"
          className={styles.goBackButton}
          variant="text"
          onClick={goBack}
        >
          Retour
        </Button>
      )}
      {renderPage({
        readonly,
        activePage,
        millesime,
        setEntry,
        assujetti,
        navigate,
        closePane,
        defaultSiren,
      })}
    </div>
  );
}
