import { GatewayContact, GatewayInvitation } from "@conformite/gateway";
import { captchaAxiosInstance } from "./utils/captchaAxiosInstance";

export class GatewayInvitationApi {
  public static async getContact(contactId: string, secret: string) {
    const { data } =
      await captchaAxiosInstance.get<GatewayContact.ContactWithAssociationsResponseType>(
        `${GatewayInvitation.invitationControllerURL}/contact/${contactId}/${secret}`
      );

    return data;
  }

  public static async createAccount(
    contactId: string,
    secret: string,
    payload: GatewayInvitation.InvitationUpdateContactRequestType
  ) {
    await captchaAxiosInstance.post<void>(
      `${GatewayInvitation.invitationControllerURL}/account/${contactId}/${secret}`,
      payload
    );
  }
}
