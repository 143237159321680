import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import {
  AdhesionStepEnum,
  ID_FORM_ADHESION,
  ParcoursAdhesionStepComponentProps,
} from "@src/adhesion/ParcoursAdhesion.definition";
import {
  EntrepriseOrganisationProfessionnelleForm,
  EntrepriseOrganisationProfessionnelleFormHandle,
} from "@src/societes/form/EntrepriseOrganisationProfessionnelleForm";
import { ParcoursAdhesionNextAction } from "@src/adhesion/AdhesionActions/ParcoursAdhesionNextAction";
import { GatewayInfoSocieteOrias } from "@conformite/gateway";
import { ParcoursAdhesionSaveAndQuitAction } from "@src/adhesion/AdhesionActions/ParcoursAdhesionSaveAndQuitAction";
import { useRef } from "react";

export const organisationProfessionnelleLabel: Record<
  GatewayInfoSocieteOrias.OrganisationProfessionnelle,
  string
> = {
  [GatewayInfoSocieteOrias.OrganisationProfessionnelle.APIC]: "APIC",
  [GatewayInfoSocieteOrias.OrganisationProfessionnelle.GCAB]: "GCAB",
  [GatewayInfoSocieteOrias.OrganisationProfessionnelle.PLANETE_CSCA]:
    "Planete CSCA",
  [GatewayInfoSocieteOrias.OrganisationProfessionnelle.AUTRE]: "Autre",
  [GatewayInfoSocieteOrias.OrganisationProfessionnelle.AUCUNE]: "Aucune",
};

export function AdhesionOrganisationProfessionnellePage({
  addData,
  changeStep,
  handleQuit,
  data,
}: ParcoursAdhesionStepComponentProps) {
  const formRef = useRef<EntrepriseOrganisationProfessionnelleFormHandle>(null);
  return (
    <ParcoursFullPageTemplate
      title="Organisation professionnelle"
      actions={
        <>
          <ParcoursAdhesionSaveAndQuitAction
            addData={addData}
            formConfig={() => formRef?.current?.getFormConfig()}
            handleQuit={handleQuit}
            transformValues={(formValues) => ({
              organisationsProfessionnelles:
                formValues.organisationsProfessionnelles,
            })}
          />
          <ParcoursAdhesionNextAction type="submit">
            Suivant
          </ParcoursAdhesionNextAction>
        </>
      }
    >
      <EntrepriseOrganisationProfessionnelleForm
        ref={formRef}
        defaultValues={data}
        formId={ID_FORM_ADHESION}
        onSubmit={(response) => {
          addData({
            organisationsProfessionnelles:
              response.organisationsProfessionnelles,
          });
          changeStep(AdhesionStepEnum.INFORMATIONS_COMPLEMENTAIRES);
        }}
      />
    </ParcoursFullPageTemplate>
  );
}
