export enum StatutSociete {
  INSCRIT = 'INSCRIT',
  SUPPRIME = 'SUPPRIME',
  JAMAIS_INSCRIT = 'JAMAIS_INSCRIT',
}

export enum ActiviteBancaire {
  SERVICE_PAIEMENT = 'SERVICE_PAIEMENT',
  CREDIT_CONSOMMATION = 'CREDIT_CONSOMMATION',
  REGROUPEMENT_CREDITS = 'REGROUPEMENT_CREDITS',
  CREDIT_IMMOBILIER = 'CREDIT_IMMOBILIER',
  PRET_VIAGER_HYPOTECAIRE = 'PRET_VIAGER_HYPOTECAIRE',
  AUTRES_ACTIVITES = 'AUTRES_ACTIVITES',
}

export enum CategorieRepresentation {
  COURTIERS_MOINS_20_SALARIES = 'COURTIERS_MOINS_20_SALARIES',
  COURTIERS_PLUS_20_SALARIES = 'COURTIERS_PLUS_20_SALARIES',
  COURTIER_GROSSISTE = 'COURTIER_GROSSISTE',
  COURTIER_AFFINITAIRE = 'COURTIER_AFFINITAIRE',
  COMPARATEUR = 'COMPARATEUR',
  MANDATAIRE_INTERMEDIAIRE_ASSURANCE = 'MANDATAIRE_INTERMEDIAIRE_ASSURANCE',
  COURTIER_OPERATION_DE_BANQUE_ET_SERVICES_DE_PAIEMENT = 'COURTIER_OPERATION_DE_BANQUE_ET_SERVICES_DE_PAIEMENT',
  MANDATAIRE_INTERMEDIAIRE_OPERATION_DE_BANQUE_ET_SERVICE_DE_PAIEMENT = 'MANDATAIRE_INTERMEDIAIRE_OPERATION_DE_BANQUE_ET_SERVICE_DE_PAIEMENT',
}

export enum Categorie {
  COA = 'COA',
  MIA = 'MIA',
  COBSP = 'COBSP',
  MIOBSP = 'MIOBSP',
}

export const CategorieIASList = [Categorie.COA, Categorie.MIA];
export const CategorieIOBSPList = [Categorie.COBSP, Categorie.MIOBSP];

export enum OrganisationProfessionnelle {
  PLANETE_CSCA = 'PLANETE_CSCA',
  APIC = 'APIC',
  GCAB = 'GCAB',
  AUTRE = 'AUTRE',
  AUCUNE = 'AUCUNE',
}

export type MandantType = {
  siren?: number;
  denomination?: string;
  activitesBancaires: ActiviteBancaire[];
};

export type InfoSocieteOriasType = {
  numeroOrias?: string;
  siren?: string;
  statut: StatutSociete;
  dateInscription?: string;
  dateSuppression?: string;
  collecteFonds?: boolean;
  activitesBancaires?: ActiviteBancaire[];
  mandants?: MandantType[];
  categories?: Categorie[];
};
