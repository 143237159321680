export function ControleQuinquennalExplainAxeRcProCoa() {
  return (
    <div>
      <p>
        Les documents sollicités ci-dessous permettront de vérifier la
        couverture de l&apos;activité de COA par une garantie RC Professionnelle
        conforme aux exigences légales (activité couverte, territoire et période
        de couverture, minimum de garantie et de franchise).
      </p>
      <br />
      <p>
        Pièce à fournir mentionnant la franchise et la territorialité de la
        garantie RC Professionnelle souscrite :
      </p>
      <ul>
        <li>Conditions particulières</li>
        <li>ou Conditions générales</li>
        <li>
          ou tout autre document tel que le tableau de garantie ou le certificat
          d’adhésion
        </li>
      </ul>
    </div>
  );
}
