import { add, format } from "date-fns";
import { useStore } from "./store.hooks";

export const useControleQuinquennal = () => {
  const storeValue = useStore();

  const { data, status, setData } = storeValue.controleQuinquennal;

  const setHasControleQuinquennal = (hasControl: boolean) =>
    setData({
      hasControlesQuinquennaux: hasControl,
    });

  const getDateFinDepot = (
    dateCreation: Date,
    dateDerniereRemediation: Date | null
  ) => {
    const dateFormat = "dd/MM/yyyy";
    if (dateDerniereRemediation) {
      return format(
        add(new Date(dateDerniereRemediation), {
          months: 2,
        }),
        dateFormat
      );
    }
    return format(add(new Date(dateCreation), { days: 21 }), dateFormat);
  };

  return {
    hasControlesQuinquennaux: data?.hasControlesQuinquennaux ?? false,
    status,
    setHasControleQuinquennal,
    getDateFinDepot,
  };
};
