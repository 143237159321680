import axios, {
  AxiosHeaders,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from "axios";
import { CustomError } from "@src/CustomError";

export const errorCaptchaName = [
  "CaptchaBadConfig",
  "CaptchaNotLoaded",
  "CaptchaFetchError",
  "CaptchaRefused",
  "CaptchaMissing",
] as const;
export type ErrorCaptchaName = (typeof errorCaptchaName)[number];

const errorCaptchaMessages: Record<ErrorCaptchaName, string> = {
  CaptchaBadConfig: "Erreur lors de la configuration du module recapcha",
  CaptchaNotLoaded: "Erreur lors du chargement du module recapcha",
  CaptchaFetchError: "Erreur lors de la récupération du token recapcha",
  CaptchaRefused:
    "Vous êtes identifié comme étant un robot, merci de nous contacter si c'est une erreur",
  CaptchaMissing: "Erreur lors du transfert du code recapcha",
};

export class CaptchaError extends CustomError<ErrorCaptchaName> {
  constructor(name: ErrorCaptchaName) {
    super(name, errorCaptchaMessages[name]);
  }
}

export function isCaptchaError(error: Error): error is CaptchaError {
  return errorCaptchaName.includes(error.name as ErrorCaptchaName);
}

export const fetchRecaptchaToken = async (retry = 0): Promise<string> => {
  try {
    if (!window.grecaptcha) throw new CaptchaError("CaptchaNotLoaded");
    return new Promise((resolve, reject) => {
      if (!window.grecaptcha) reject(new CaptchaError("CaptchaNotLoaded"));
      else {
        window.grecaptcha.ready(() => {
          if (!window.grecaptcha || !process.env.VITE_RECAPTCHA_KEY)
            reject(new CaptchaError("CaptchaBadConfig"));
          else {
            window.grecaptcha
              .execute(process.env.VITE_RECAPTCHA_KEY, {
                action: "submit",
              })
              .then((token) => {
                resolve(token);
              })
              .catch(() => {
                reject(new CaptchaError("CaptchaFetchError"));
              });
          }
        });
      }
    });
  } catch (err) {
    if (retry < 3) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          fetchRecaptchaToken(retry + 1)
            .then(resolve)
            .catch(reject);
        }, 500);
      });
    }
    throw err;
  }
};

export const captchaAxiosInstance = axios.create({
  baseURL: `${import.meta.env.VITE_SCHEME_GATEWAY}://${
    import.meta.env.VITE_URL_GATEWAY
  }`,
});

function getAxiosUrlWithToken(baseURL: string, url: string, token: string) {
  const urlInstance = new URL(
    `${baseURL}${url.startsWith("/") ? "" : "/"}${url}`
  );
  urlInstance.searchParams.append("captcha_token", token);
  return urlInstance.toString().replace(baseURL, "");
}

captchaAxiosInstance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    if (!import.meta.env.VITE_RECAPTCHA_KEY)
      return config as InternalAxiosRequestConfig<AxiosHeaders>;
    const controller = new AbortController();

    try {
      const token = await fetchRecaptchaToken();
      return {
        ...config,
        url: getAxiosUrlWithToken(
          config.baseURL as string,
          config.url as string,
          token
        ),
      } as InternalAxiosRequestConfig<AxiosHeaders>;
    } catch (err) {
      controller.abort(err);
      return {
        ...config,
        signal: controller.signal,
      } as InternalAxiosRequestConfig<AxiosHeaders>;
    }
  }
);
