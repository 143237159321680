import { GatewayControleQuinquennal } from "@conformite/gateway";
import {
  Button,
  Card,
  FormField,
  FormGrid,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as IconInfo } from "@src/assets/icons/info-circle-fill.svg";
import { Link } from "@src/components/Link/Link";
import { useControleQuinquennal } from "@src/store/store.controleQuinquennal";
import styles from "./CardControleQuinquennal.module.scss";

type CardControleQuinquennalProps = {
  controleQuinquennal: GatewayControleQuinquennal.ControleQuinquennalWithAxes;
};

export function CardControleQuinquennal({
  controleQuinquennal,
}: CardControleQuinquennalProps) {
  const { getDateFinDepot } = useControleQuinquennal();

  const message = (() => {
    const dateFinDepot = getDateFinDepot(
      controleQuinquennal.dateCreation,
      controleQuinquennal.dateDerniereRemediation
    );
    if (
      controleQuinquennal.status ===
      GatewayControleQuinquennal.ControleQuinquennalStatus.TERMINE
    ) {
      return `La vérification de votre conformité est terminée, nous vous invitons à télécharger votre attestation de fin de vérification`;
    }
    if (
      controleQuinquennal.dateDerniereRemediation &&
      controleQuinquennal.controleQuinquennalAxes.find(
        (controle) =>
          controle.status ===
          GatewayControleQuinquennal.ControleQuinquennalAxeStatus.REMEDIE
      )
    )
      return `Les pièces transmises ont été analysées et nécessitent une action de remédiation. Vous avez jusqu'au ${dateFinDepot} pour déposer l'ensemble des justificatifs complémentaires sollicités`;
    return `Vous avez jusqu'au ${dateFinDepot} pour déposer l'ensemble des pièces justificatives sollicitées`;
  })();

  return (
    <Card className={styles.card}>
      <FormGrid>
        <FormRow>
          <FormField>
            <div className={styles.grid}>
              <div className={styles.gridLeft}>
                <h3 className={styles.title}>
                  <IconInfo className={styles.icon} /> Vous faites l&apos;objet
                  d&apos;une vérification
                </h3>
                <p className={styles.text}>{message}</p>
              </div>
              <div className={styles.gridRight}>
                <Link to="/controle-quinquennal">
                  <Button variant="outline-accent">
                    {controleQuinquennal.status ===
                    GatewayControleQuinquennal.ControleQuinquennalStatus.TERMINE
                      ? "Consulter et télécharger votre attestation de fin de vérification"
                      : "Consulter et déposer les justificatifs"}
                  </Button>
                </Link>
              </div>
            </div>
          </FormField>
        </FormRow>
      </FormGrid>
    </Card>
  );
}
