import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Form,
  InputPassword,
  InputText,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  Button,
  FormAction,
  FormField,
  FormGrid,
  FormRow,
  FormTitle,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { Header } from "@src/components/Header/Header";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { Link } from "@src/components/Link/Link";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { useSession } from "@src/session/UserSessionContext";
import { useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import styles from "./Login.module.scss";

const schema = zod.object({
  email: zod.string().email().nonempty(),
  password: zod.string().nonempty(),
});

export type LoginFormValue = zod.infer<typeof schema>;

export function Login() {
  const session = useSession();
  const formConfig = useFormConfig<typeof schema, LoginFormValue>({
    schema,
  });

  const [query] = useSearchParams();
  const urlRedirect = query.get("url");
  const navigate = useNavigate();

  const [shouldForgotPassword, setShouldForgotPassword] = useState(false);

  const handleSubmit = async (submittedData: LoginFormValue): Promise<void> => {
    try {
      await session.login(submittedData.email, submittedData.password);
    } catch (err) {
      handleGatewayError({
        onResponse: (error) => {
          if (error.response.status === 401) {
            displayErrorToast("Identifiants incorrects");
            return;
          }
          if (
            error.response.status === 403 &&
            error.response.data.message === "NEW_PASSWORD_REQUIRED"
          ) {
            const userId = error.response.data.cause?.userId as string;
            navigate(
              `/new-password/${userId}?url=${urlRedirect ?? "/"}&session=${
                error.response.data.cause?.session as string
              }`,
              {
                replace: true,
              }
            );
            return;
          }
          if (
            error.response.status === 403 &&
            error.response.data.message === "CHANGE_PASSWORD_TOO_MANY_REQUEST"
          ) {
            setShouldForgotPassword(true);
          }
          displayErrorToast("Une erreur est survenue");
        },
        onUnhandled: (error) => {
          console.error(error);
          displayErrorToast("Une erreur est survenue");
        },
      })(err);
    }
  };

  if (session.isConnected) {
    return <Navigate to={urlRedirect ?? "/"} />;
  }

  return (
    <div>
      <Header />
      <MaxWidthContent>
        <Form formConfig={formConfig} onSubmit={handleSubmit}>
          <FormGrid disableHorizontalGutter>
            <FormTitle>
              <h1>Connectez-vous à votre espace</h1>
            </FormTitle>
            {shouldForgotPassword && (
              <FormRow>
                <FormField>
                  <InfoCard type="warning">
                    Votre compte est bloqué suite à trop de tentatives de
                    connexions. Merci de{" "}
                    <Link
                      to={`/forgot-password?email=${
                        formConfig.getValues().email
                      }`}
                    >
                      réinitialiser votre mot de passe
                    </Link>{" "}
                    pour pouvoir vous connecter.
                  </InfoCard>
                </FormField>
              </FormRow>
            )}
            <FormRow>
              <FormField>
                <InputText<LoginFormValue> name="email" label="Adresse email" />
              </FormField>
            </FormRow>
            <FormRow>
              <FormField>
                <InputPassword<LoginFormValue>
                  name="password"
                  label="Mot de passe"
                />
              </FormField>
            </FormRow>
            <FormAction className={styles.buttons}>
              <Link className={styles.link} to="/forgot-password">
                Mot de passe oublié
              </Link>
              <Button type="submit">Se connecter</Button>
            </FormAction>
          </FormGrid>
        </Form>
      </MaxWidthContent>
    </div>
  );
}
