import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import {
  SignupConfirmForm,
  SignupConfirmFormValues,
} from "@src/user/Signup/SignupConfirmForm";
import { useCallback, useState } from "react";
import { GatewayAuthApi } from "@src/api/auth.api";
import { GatewayInvitationApi } from "@src/api/invitation.api";
import { useNavigate } from "react-router-dom";
import { useSession } from "@src/session/UserSessionContext";
import { ParcoursAction } from "@src/parcours/NavigationButtons/ParcoursAction";
import { ReactComponent as Loader } from "@src/assets/loader.svg";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import {
  ID_FORM_INVITATION,
  ParcoursInvitationComponentProps,
} from "../ParcoursInvitation";

export function ParcoursInvitationConfirm({
  data,
  contactId,
  secret,
}: ParcoursInvitationComponentProps) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const session = useSession();

  const handleSubmit = useCallback(
    async (values: SignupConfirmFormValues) => {
      setIsLoading(true);
      try {
        await GatewayAuthApi.confirm(data.email, values.confirmCode);
        await GatewayInvitationApi.createAccount(contactId, secret, data);
        await session.login(data.email, data.password);
        navigate("/");
      } catch (error) {
        handleGatewayError({
          onResponse: (err) => {
            displayErrorToast(err.response.data.message);
          },
          onUnhandled: () => {
            displayErrorToast("Une erreur inconnue est survenue");
          },
        })(error);
      }
      setIsLoading(false);
    },
    [data, contactId, secret, session, navigate]
  );

  return (
    <ParcoursFullPageTemplate
      title="Vérification de l'adresse email"
      actions={
        <ParcoursAction
          formId={ID_FORM_INVITATION}
          type="submit"
          disabled={isLoading}
          icon={isLoading ? <Loader /> : undefined}
        >
          Suivant
        </ParcoursAction>
      }
    >
      <SignupConfirmForm
        email={data.email}
        onSubmit={handleSubmit}
        formId={ID_FORM_INVITATION}
      />
    </ParcoursFullPageTemplate>
  );
}
