import {
  Assujetti,
  AssujettiWithAvancement as ServiceAssujettiWithAvancement,
  RoleAssujetti as ServiceRoleAssujetti,
} from '../service/assujetti.definition';

export const assujettiControllerUrl = 'assujetti';
export const setAssujettiUrl = assujettiControllerUrl;

export type RoleAssujetti = ServiceRoleAssujetti;
export const RoleAssujetti = ServiceRoleAssujetti;

export interface SetAssujettiRequestType {
  id?: string;
  millesime: number;
  name: string;
  surname: string;
  sirenCompany: string;
  role: RoleAssujetti;
  email?: string;
}
export type SetAssujettiResponseType = AssujettiDto;

export const listAssujettiSuffix = `/list`;
export const listAssujettiUrl = `${assujettiControllerUrl}${listAssujettiSuffix}`;
export interface ListAssujettiRequestType {
  sirens: string[];
}
export type ListAssujettiResponseTypeWithAvancement = AssujettiWithAvancement[];

export type AssujettiWithAvancement = ServiceAssujettiWithAvancement;

export class AssujettiDto {
  constructor(
    readonly name: string,
    readonly surname: string,
    readonly sirenCompany: string,
    readonly role: RoleAssujetti,
    readonly email: string | null,
    readonly id: string,
    readonly millesime: number,
  ) {}

  static fromAssujetti(assujetti: Assujetti): AssujettiDto {
    return new AssujettiDto(
      assujetti.name,
      assujetti.surname,
      assujetti.sirenCompany,
      assujetti.role,
      assujetti.email,
      assujetti.id,
      assujetti.millesime,
    );
  }
}
export class AvancementDto {
  constructor(
    readonly idAssujetti: string,
    readonly millesime: number,
    readonly honorabilite: number | null,
    readonly capaciteProfessionnelleIas: number | null,
    readonly capaciteProfessionnelleIobsp: number | null,
    readonly formationContinueIas: number | null,
    readonly formationContinueIobsp: number | null,
  ) {}
}

export type DeleteMultipleAssujettiBySirensResponseType = number;
export type DeleteMultipleAssujettiByIdsResponseType = number;

export type DeleteMultipleAssujettiByIdRequestType = {
  ids: string[];
};

export type DeleteMultipleAssujettiBySirenRequestType = {
  sirens: string[];
};
