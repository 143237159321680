import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { Dialog } from "./Dialog";
import styles from "./ConfirmDialog.module.scss";

export type ConfirmDialogProps = {
  title?: string;
  message?: string;
  confirmText?: string;
  cancelText?: string;
  onClose?: (value: string) => void;
  dialogRef: React.MutableRefObject<HTMLDialogElement | null>;
};
export function ConfirmDialog({
  title,
  message,
  confirmText,
  cancelText,
  onClose,
  dialogRef,
}: ConfirmDialogProps) {
  return (
    <Dialog dialogRef={dialogRef}>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.message}>{message}</p>
      <div className={styles.actions}>
        {cancelText && (
          <Button
            variant="text"
            onClick={(e) => {
              e.stopPropagation();
              onClose?.("false");
            }}
          >
            {cancelText}
          </Button>
        )}
        <Button
          variant="filled"
          onClick={(e) => {
            e.stopPropagation();
            onClose?.("true");
          }}
        >
          {confirmText}
        </Button>
      </div>
    </Dialog>
  );
}
