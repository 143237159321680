import {
  GatewayAvancement,
  GatewayGarantieFinanciere,
} from "@conformite/gateway";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Form,
  InputButtonGroup,
  InputCheckbox,
  InputNumber,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  FormField,
  FormGrid,
  FormRow,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { chain } from "lodash";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { GatewayGarantieFinanciereApi } from "@src/api/conformite/garantieFinanciere.api";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { millesimeHasEveryDay } from "@src/conformite/conformite.helper";
import { useOnce } from "@src/hooks/useOnce";
import { useMe } from "@src/store/store.me";
import { useEffect } from "react";
import styles from "../ConformiteGarantieFinanciere.module.scss";
import {
  ConformiteGarantieFinanciereEditFiles,
  FormWithFiles,
} from "./ConformiteGarantieFinanciereEditFiles";
import {
  ConformiteGarantieFinanciereEditFilesNonDiffusable,
  FormWithFileNonDiffusable,
} from "./ConformiteGarantieFinanciereEditFilesNonDiffusable";

const buttonGroupOptionsBoolean = [
  {
    label: "Oui",
    value: true,
  },
  {
    label: "Non",
    value: false,
  },
];

const schema = (millesime: number) =>
  zod.object({
    millesime: zod.number(),
    files: zod
      .array(
        zod.object({
          pathname: zod.string().optional(),
          filename: zod.string().optional(),
          startDate: zod.date(),
          endDate: zod.date(),
          file: zod.instanceof(File).optional(),
          isValid: zod.boolean(),
        })
      )
      .min(1)
      .refine((files) => millesimeHasEveryDay(millesime, files), {
        message: "Vos attestations doivent couvrir l'année entière",
        path: ["0.startDate"],
      }),
    nonDiffusable: zod
      .object({
        fileNonDiffusable: zod.nullable(
          zod.object({
            pathname: zod.string().optional(),
            filename: zod.string().optional(),
            isValid: zod.boolean(),
            file: zod.instanceof(File).optional(),
          })
        ),
        noCommunication: zod.boolean().nullable(),
      })
      .refine(
        (data) =>
          !data.noCommunication ||
          (data.noCommunication && data.fileNonDiffusable),
        {
          message:
            "Vous devez fournir une attestation de la part de votre assureur",
          path: ["noCommunication"],
        }
      ),
    infoGarantie: zod
      .object({
        primesEncaisseesDehorsMandat: zod.number(),
        primesEncaisseesMontant: zod.number(),
        indemniteSinistreDehorsMandat: zod.number(),
        indemniteSinistreMontant: zod.number(),
        montantGarantie: zod.number(),
      })
      .superRefine((data, ctx) => {
        const garantieFinanciereSimulated =
          (data.primesEncaisseesDehorsMandat +
            data.indemniteSinistreDehorsMandat) /
          6;
        const garantieFinanciereSimulatedRounded =
          Math.round(garantieFinanciereSimulated * 100) / 100;

        if (garantieFinanciereSimulatedRounded <= 115_000) {
          if (data.montantGarantie < 115_000) {
            ctx.addIssue({
              code: zod.ZodIssueCode.custom,
              message:
                "Le montant de la garantie financière doit être supérieur ou égal 115 000 €",
              path: ["montantGarantie"],
            });
          }
        } else if (data.montantGarantie < garantieFinanciereSimulatedRounded) {
          ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            message: `Le montant de la garantie financière doit être supérieur ou égal ${garantieFinanciereSimulatedRounded} €`,
            path: ["montantGarantie"],
          });
        }
      }),
    encaissementFonds: zod.boolean(),
    montantFondRoulement: zod.number(),
  });
export type ConformiteGarantieFinanciereFormValuesIobsp = zod.infer<
  ReturnType<typeof schema>
>;

const getFileNonDiffusableWithoutFile = (
  file: {
    isValid: boolean;
    pathname?: string | undefined;
    filename?: string | undefined;
    file?: File | undefined;
  } | null
): GatewayGarantieFinanciere.ConformiteGarantieFinanciereFileNonDiffusable | null => {
  if (!file) return null;
  const { file: fileData, ...rest } = file;
  return rest;
};
export const useConformiteGarantieFinanciereIobspEdit = (
  siren: string,
  millesime: number,
  garantieFinanciere: GatewayGarantieFinanciere.ConformiteGarantieFinanciereIobsp | null,
  stopEditing: (
    value:
      | GatewayGarantieFinanciere.ConformiteGarantieFinanciereIobsp
      | undefined,
    avancement: GatewayAvancement.AvancementSociete
  ) => void
) => {
  const form = useFormConfig({
    schema: schema(millesime),
    defaultValues: {
      ...garantieFinanciere,
      millesime,
      nonDiffusable: {
        noCommunication: garantieFinanciere?.noCommunication ?? null,
        fileNonDiffusable: garantieFinanciere?.fileNonDiffusable,
      },
      files:
        garantieFinanciere?.files?.map((file) => ({
          ...file,
          startDate: file?.startDate
            ? new Date(file.startDate as unknown as string)
            : undefined,
          endDate: file?.endDate
            ? new Date(file.endDate as unknown as string)
            : undefined,
        })) ?? [],
      infoGarantie: {
        primesEncaisseesDehorsMandat:
          garantieFinanciere?.primesEncaisseesDehorsMandat,
        primesEncaisseesMontant: garantieFinanciere?.primesEncaisseesMontant,
        indemniteSinistreDehorsMandat:
          garantieFinanciere?.indemniteSinistreDehorsMandat,
        indemniteSinistreMontant: garantieFinanciere?.indemniteSinistreMontant,
        montantGarantie: garantieFinanciere?.montantGarantie,
      },
    } as ConformiteGarantieFinanciereFormValuesIobsp,
  });
  const onSubmit = async ({
    files,
    nonDiffusable,
    ...data
  }: ConformiteGarantieFinanciereFormValuesIobsp) => {
    const filesToSend = chain(files)
      .filter((file) => !!file?.file)
      .map((file) => file?.file)
      .value() as File[];
    const filesWithoutBlob = files.map((fileData) => {
      if (!fileData?.file) return fileData;
      const { file, ...rest } = fileData;
      return rest;
    });
    const payload: GatewayGarantieFinanciere.ConformiteGarantieFinanciereIobsp =
      {
        noCommunication: nonDiffusable.noCommunication ?? null,
        millesime: data.millesime,
        montantGarantie: data.infoGarantie.montantGarantie ?? null,
        encaissementFonds: data.encaissementFonds ?? null,
        montantFondRoulement: data.montantFondRoulement ?? null,
        primesEncaisseesMontant:
          data.infoGarantie.primesEncaisseesMontant ?? null,
        indemniteSinistreMontant:
          data.infoGarantie.indemniteSinistreMontant ?? null,
        primesEncaisseesDehorsMandat:
          data.infoGarantie.primesEncaisseesDehorsMandat ?? null,
        indemniteSinistreDehorsMandat:
          data.infoGarantie.indemniteSinistreDehorsMandat ?? null,
        files: filesWithoutBlob,
        fileNonDiffusable: getFileNonDiffusableWithoutFile(
          nonDiffusable.fileNonDiffusable
        ),
      };
    try {
      const avancement =
        await GatewayGarantieFinanciereApi.saveGarantieFinanciereIobsp(
          siren,
          millesime,
          payload,
          filesToSend.concat(
            nonDiffusable.fileNonDiffusable?.file
              ? [nonDiffusable.fileNonDiffusable?.file]
              : []
          )
        );
      stopEditing(payload, avancement);
    } catch (err) {
      handleGatewayError({
        onUnhandled: () => {
          displayErrorToast(
            "Une erreur est survenue lors de la récupération du formulaire"
          );
        },
      })(err);
    }
  };
  return {
    form,
    onSubmit,
  };
};

type ConformiteGarantieFinanciereEditFormIobspProps = {
  form: ReturnType<typeof useConformiteGarantieFinanciereIobspEdit>["form"];
  onSubmit?: ReturnType<
    typeof useConformiteGarantieFinanciereIobspEdit
  >["onSubmit"];
  millesime: number;
  garantieFinanciere: GatewayGarantieFinanciere.ConformiteGarantieFinanciereIobsp | null;
  disabled?: boolean;
  siren: string;
};

export function ConformiteGarantieFinanciereEditFormIobsp({
  form,
  onSubmit = () => Promise.resolve(),
  millesime,
  garantieFinanciere,
  disabled,
  siren,
}: ConformiteGarantieFinanciereEditFormIobspProps) {
  const { me } = useMe();

  const [
    encaissementFonds,
    indemniteSinistreDehorsMandat,
    primesEncaisseesDehorsMandat,
    noCommunication,
  ] = form.watch([
    "encaissementFonds",
    "infoGarantie.indemniteSinistreDehorsMandat",
    "infoGarantie.primesEncaisseesDehorsMandat",
    "nonDiffusable.noCommunication",
  ]);
  const montantCalculeGarantieFinanciere = Math.max(
    ((indemniteSinistreDehorsMandat || 0) +
      (primesEncaisseesDehorsMandat || 0)) /
      6,
    115_000
  ).toFixed(2);

  useOnce(() => {
    if (!disabled && garantieFinanciere !== null) {
      form.trigger().catch(console.error);
    }
  });

  const fieldTriggeringMontantGarantie = form.watch([
    "infoGarantie.primesEncaisseesDehorsMandat",
    "infoGarantie.primesEncaisseesMontant",
    "infoGarantie.indemniteSinistreDehorsMandat",
    "infoGarantie.indemniteSinistreMontant",
  ]);

  useEffect(() => {
    form.trigger("infoGarantie.montantGarantie").catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldTriggeringMontantGarantie, form.trigger]);

  return (
    <Form formConfig={form} onSubmit={onSubmit}>
      <fieldset disabled={disabled} className={styles.fieldset}>
        <FormGrid disableHorizontalGutter>
          <FormRow>
            <FormField>
              <InputButtonGroup<
                ConformiteGarantieFinanciereFormValuesIobsp,
                false,
                boolean
              >
                label="Encaissez-vous des fonds ?"
                name="encaissementFonds"
                variant="outline-pop"
                options={buttonGroupOptionsBoolean}
                disabled={disabled}
              />
            </FormField>
          </FormRow>
          {encaissementFonds && (
            <>
              <FormRow>
                <FormField>
                  <InputNumber<ConformiteGarantieFinanciereFormValuesIobsp>
                    name="infoGarantie.montantGarantie"
                    label="Quel est le montant à date de votre garantie financière ?"
                  />
                </FormField>
              </FormRow>
              <h4 className={styles.subtitle}>
                Attestation de couverture sur l&apos;année {millesime}
              </h4>
              {me?.entreprisePrincipale.millesimeActuel === 2024 && (
                <InfoCard className={styles.cardInformationsPJ}>
                  Dans le cadre du renouvellement 2024, si vous ne disposez pas
                  encore de votre attestation pour l&apos;année 2024/2025, nous
                  vous invitons à renseigner et enregistrer les éléments dont
                  vous disposez à ce jour. Vous pourrez compléter cet axe à
                  réception de votre attestation.
                </InfoCard>
              )}
              <ConformiteGarantieFinanciereEditFiles
                form={form as unknown as FormWithFiles}
                disabled={disabled}
                siren={siren}
              />
              {!noCommunication && (
                <>
                  <h4 className={styles.subtitle}>
                    Primes encaissées en {millesime - 1}
                  </h4>
                  <FormRow>
                    <FormField size="half">
                      <InputNumber<ConformiteGarantieFinanciereFormValuesIobsp>
                        name="infoGarantie.primesEncaisseesDehorsMandat"
                        label="En dehors de tout mandat exprès de l'assureur (en €)"
                      />
                    </FormField>
                    <FormField size="half">
                      <InputNumber<ConformiteGarantieFinanciereFormValuesIobsp>
                        name="infoGarantie.primesEncaisseesMontant"
                        label="Dans le cadre d'un mandat exprès de l'assureur (en €)"
                      />
                    </FormField>
                  </FormRow>
                  <h4 className={styles.subtitle}>
                    Indemnités sinistres encaissées en {millesime - 1}
                  </h4>
                  <FormRow>
                    <FormField size="half">
                      <InputNumber<ConformiteGarantieFinanciereFormValuesIobsp>
                        name="infoGarantie.indemniteSinistreDehorsMandat"
                        label="En dehors de tout mandat exprès de l'assureur (en €)"
                      />
                    </FormField>
                    <FormField size="half">
                      <InputNumber<ConformiteGarantieFinanciereFormValuesIobsp>
                        name="infoGarantie.indemniteSinistreMontant"
                        label="Dans le cadre d'un mandat exprès de l'assureur (en €)"
                      />
                    </FormField>
                  </FormRow>
                </>
              )}
              <FormRow>
                <FormField>
                  <InputCheckbox<ConformiteGarantieFinanciereFormValuesIobsp>
                    name="nonDiffusable.noCommunication"
                    label="Ne souhaite pas communiquer sur les primes et indemnités encaissées"
                  />
                </FormField>
              </FormRow>
              {noCommunication && (
                <>
                  {!disabled && (
                    <InfoCard
                      type="warning"
                      className={styles.noCommunicationWarning}
                    >
                      Attention : Si vous ne souhaitez pas communiquer sur les
                      primes et indemnités encaissées, vous devez fournir une
                      attestation de la part de votre assureur, garantissant que
                      la société est suffisamment assurée. La validation de
                      cette pièce nécessitant un traitement manuel, le délai de
                      réception de l&apos;attestation de conformité s&apos;en
                      trouvera allongé.
                    </InfoCard>
                  )}
                  <ConformiteGarantieFinanciereEditFilesNonDiffusable
                    form={form as unknown as FormWithFileNonDiffusable}
                    disabled={disabled}
                    siren={siren}
                    millesime={millesime}
                  />
                </>
              )}
              {!noCommunication && (
                <div className={styles.calculatedContainer}>
                  <p className={styles.calculatedLabel}>
                    Montant calculé de la garantie financière :
                  </p>
                  <p className={styles.calculatedValue}>
                    {montantCalculeGarantieFinanciere} €
                  </p>
                </div>
              )}
              <FormRow>
                <FormField>
                  <InputNumber<ConformiteGarantieFinanciereFormValuesIobsp>
                    name="montantFondRoulement"
                    label="Montant du fond de roulement (en €)"
                  />
                </FormField>
              </FormRow>
            </>
          )}
        </FormGrid>
      </fieldset>
    </Form>
  );
}
