import { GatewayControleQuinquennal } from "@conformite/gateway";
import { ControleQuinquennalApi } from "@src/api/controleQuinquennal.api";
import { useEntreprises } from "@src/store/store.entreprise";
import { useCallback, useEffect, useMemo, useState } from "react";

export const useControlesQuinquennaux = () => {
  const [controlesQuinquennaux, setControlesQuinquennaux] = useState<
    GatewayControleQuinquennal.ControleQuinquennalWithCompletion[]
  >([]);
  const { entreprisesBySiren } = useEntreprises();

  const entreprises = useMemo(
    () => Object.values(entreprisesBySiren ?? {}),
    [entreprisesBySiren]
  );
  const getEntreprise = useCallback(
    (controleQuinquennal: GatewayControleQuinquennal.ControleQuinquennal) =>
      entreprises.find(
        (entreprise) =>
          entreprise.hubspotId === controleQuinquennal.idEntreprise
      ),
    [entreprises]
  );
  useEffect(() => {
    ControleQuinquennalApi.getControlesQuinquennaux()
      .then((data) => {
        setControlesQuinquennaux(data);
      })
      .catch(console.error);
  }, []);
  return {
    controlesQuinquennaux,
    getEntreprise,
  };
};
