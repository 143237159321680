import { PrismicProvider } from "@prismicio/react";
import { useState } from "react";
import { prismicClient } from "../prismic";
import { useLeSaviezVous } from "./useLeSaviezVous";
import { LeSaviezVousList } from "./LeSaviezVousList";
import { CmsListPage } from "../CmsListPage/CmsListPage";

const PAGE_SIZE = 7;
function LeSaviezVousListPageContent() {
  const [page, setPage] = useState(1);
  const { data, total } = useLeSaviezVous({
    page,
    pageSize: PAGE_SIZE,
  });
  return (
    <CmsListPage
      title="Le saviez-vous ?"
      page={page}
      pageSize={PAGE_SIZE}
      total={total}
      setPage={setPage}
    >
      <LeSaviezVousList leSaviezVous={data} />
    </CmsListPage>
  );
}
export function LeSaviezVousListPage() {
  return (
    <PrismicProvider client={prismicClient}>
      <LeSaviezVousListPageContent />
    </PrismicProvider>
  );
}
