import { ReactElement, ReactNode } from "react";
import styles from "./PageWithSidebar.module.scss";

type PageWithSidebarProps = {
  children: ReactNode;
  sidebar: ReactElement;
};
export function PageWithSidebar({ children, sidebar }: PageWithSidebarProps) {
  return (
    <div>
      <div className={styles.sidebar}>{sidebar}</div>
      <div className={styles.content}>{children}</div>
    </div>
  );
}
