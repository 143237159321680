import {
  ArrayPath,
  FieldArray,
  Path,
  PathValue,
  UseFormReturn,
  useFieldArray,
} from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import { InputSelect } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { UIInputError } from "@lya-protect/lya-protect-form-library/dist/UI";
import { SelectOption } from "@lya-protect/lya-protect-form-library/dist/UI/Input/UIInputSelect";
import { TagList } from "./TagList";

type ValueWithId<Value = { value: string }> = { id: string } & Value;
export type Data<
  TextSearch extends string,
  TextSelected extends string,
  Value = { value: string }
> = {
  [data in TextSearch]: string;
} & {
  [data in TextSelected]: Value[];
};
export type ListComponentProps<Value = { value: string }> = {
  fields: ValueWithId<Value>[];
  remove: (index: number) => void;
};
type AutoCompleteWithListProps<
  Text extends string,
  TextArray extends string,
  Value = { value: string },
  D extends Data<Text, TextArray, Value> = Data<Text, TextArray, Value>
> = {
  form: UseFormReturn<D>;
  label: string;
  nameSelected: TextArray;
  nameSearch: Text;
  placeholder?: string;
  options: SelectOption<string>[];
  valueToAppend?: (
    item: string
  ) => FieldArray<
    Data<Text, TextArray, Value>,
    ArrayPath<Data<Text, TextArray, Value>>
  >;
  ListComponent?: (
    props: ListComponentProps<Value>
  ) => JSX.Element | JSX.Element[];
};

export function AutoCompleteWithList<
  Text extends string,
  TextArray extends string,
  Value = { value: string }
>({
  form,
  label,
  placeholder,
  nameSelected,
  nameSearch,
  options = [],
  valueToAppend = (item) =>
    ({ value: item } as unknown as FieldArray<
      Data<Text, TextArray, Value>,
      ArrayPath<Data<Text, TextArray, Value>>
    >),
  ListComponent = ({ fields, remove }) => (
    <TagList
      remove={remove}
      fields={fields as unknown as { id: string; value: string }[]}
    />
  ),
}: AutoCompleteWithListProps<Text, TextArray, Value>) {
  type D = Data<Text, TextArray, Value>;
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: nameSelected as ArrayPath<D>,
  });
  const errorMessage = form.getFieldState(nameSelected as unknown as Path<D>)
    .error?.message;
  return (
    <div>
      <InputSelect
        name={nameSearch}
        label={label}
        placeholder={placeholder}
        options={options}
        isSearchable
        onInputChange={(item) => {
          form.setValue(
            nameSearch as unknown as Path<D>,
            item as unknown as PathValue<D, Path<D>>
          );
        }}
        onChange={(item) => {
          form.setValue(
            nameSearch as unknown as Path<D>,
            "" as unknown as PathValue<D, Path<D>>
          );
          append(valueToAppend(item ?? ""));
        }}
      />
      <ListComponent fields={fields as ValueWithId<Value>[]} remove={remove} />
      {errorMessage && (
        <UIInputError
          errorMessage={errorMessage}
          className="input-container__error"
        />
      )}
    </div>
  );
}
