import { PrismicProvider } from "@prismicio/react";
import { useState } from "react";
import { ActualiteList } from "./ActualiteList";
import { prismicClient } from "../prismic";
import { useActualite } from "./useActualite";
import { CmsListPage } from "../CmsListPage/CmsListPage";

const PAGE_SIZE = 7;
function ActualiteListPageContent() {
  const [page, setPage] = useState(1);
  const { data, total } = useActualite({
    page,
    pageSize: PAGE_SIZE,
  });
  return (
    <CmsListPage
      title="Nos actualités"
      page={page}
      pageSize={PAGE_SIZE}
      total={total}
      setPage={setPage}
    >
      <ActualiteList actualites={data} />
    </CmsListPage>
  );
}
export function ActualiteListPage() {
  return (
    <PrismicProvider client={prismicClient}>
      <ActualiteListPageContent />
    </PrismicProvider>
  );
}
