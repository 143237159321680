/* eslint-disable react/no-danger */
import { SliceFactoryProps } from "../../SliceManager";
import styles from "./SliceEmbed.module.scss";

export function SliceEmbed({ slice }: SliceFactoryProps<"embedded">) {
  return (
    <div className={styles.embed}>
      <div
        dangerouslySetInnerHTML={{
          __html: slice.primary.embedded.html ?? "",
        }}
      />
    </div>
  );
}
