import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GatewayInvitationApi } from "@src/api/invitation.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { LoadingPage } from "@src/components/LoadingPage/LoadingPage";
import {
  ParcoursInvitation,
  ParcoursInvitationData,
  ParcoursInvitationDefaultData,
} from "./ParcoursInvitation";
import style from "./Invitation.module.scss";

const errorMessage =
  "Une erreur est survenue lors de la récupération du contact";

const useInvitation = () => {
  const { contactId, secret } = useParams();
  const [contact, setContact] = useState<ParcoursInvitationData>();

  if (!contactId || !secret) {
    displayErrorToast(errorMessage);
    throw new Error(errorMessage);
  }

  useEffect(() => {
    setTimeout(() => {
      GatewayInvitationApi.getContact(contactId, secret)
        .then((contactResponse) =>
          setContact({ ...ParcoursInvitationDefaultData, ...contactResponse })
        )
        .catch(
          handleGatewayError({
            onUnhandled: (err) => {
              console.error(err);
              displayErrorToast(errorMessage);
            },
          })
        );
    }, 1000);
  }, [contactId, secret]);

  return {
    contact,
    pathParams: { contactId, secret },
  };
};

export function Invitation() {
  const { contact, pathParams } = useInvitation();
  if (!contact)
    return (
      <div className={style.pageWrapper}>
        <LoadingPage />;
      </div>
    );
  return (
    <ParcoursInvitation
      initialData={contact}
      rootPath={`/invitation/${pathParams.contactId}/${pathParams.secret}`}
      contactId={pathParams.contactId}
      secret={pathParams.secret}
    />
  );
}
