export enum EtatEntiteHubspot {
  NON_ADHERENT = 'lead',
  ADHESION_EN_COURS = '208837366',
  PAIEMENT_EN_ATTENTE = '208782266',
  ADHERENT = 'customer',
  RADIE = '208837367',
  REMEDIATION = '208928505',
  A_RENOUVELER = '304143334',
  INVITATION = '311531485',
}

export enum HubspotContactCivilite {
  MONSIEUR = 'M',
  MADAME = 'F',
}

export enum HubspotContactFonction {
  REPRESENTANT_LEGAL = '1',
  GERANT = '2',
  DIRECTEUR_GENERAL = '3',
  DIRECTEUR_ADMINISTRATIF = '4',
  DIRECTEUR_RH = '5',
  RESPONSABLE_CONFORMITE = '6',
  AUTRES = '7',
}

export enum HubspotContactRole {
  ADMINISTRATEUR = 'ADMIN',
  RESPONSABLE_CONFORMITE = 'RESP_CONFORM',
  UTILISATEUR_SIMPLE = 'USER',
}

export type HubspotExaustiveContactPropertyKeys =
  | 'hs_analytics_average_page_views'
  | 'hs_analytics_first_referrer'
  | 'hs_analytics_first_timestamp'
  | 'hs_analytics_first_touch_converting_campaign'
  | 'hs_analytics_first_url'
  | 'hs_analytics_first_visit_timestamp'
  | 'hs_analytics_last_referrer'
  | 'hs_analytics_last_timestamp'
  | 'hs_analytics_last_touch_converting_campaign'
  | 'hs_analytics_last_url'
  | 'hs_analytics_last_visit_timestamp'
  | 'hs_analytics_num_event_completions'
  | 'hs_analytics_num_page_views'
  | 'hs_analytics_num_visits'
  | 'hs_analytics_revenue'
  | 'hs_analytics_source'
  | 'hs_analytics_source_data_1'
  | 'hs_analytics_source_data_2'
  | 'hs_latest_source'
  | 'hs_latest_source_data_1'
  | 'hs_latest_source_data_2'
  | 'hs_latest_source_timestamp'
  | 'currentlyinworkflow'
  | 'engagements_last_meeting_booked'
  | 'engagements_last_meeting_booked_campaign'
  | 'engagements_last_meeting_booked_medium'
  | 'engagements_last_meeting_booked_source'
  | 'first_conversion_date'
  | 'first_conversion_event_name'
  | 'hs_content_membership_notes'
  | 'hs_content_membership_status'
  | 'hs_feedback_last_nps_follow_up'
  | 'hs_feedback_last_nps_rating'
  | 'hs_feedback_last_survey_date'
  | 'hs_last_sales_activity_timestamp'
  | 'hs_sales_email_last_clicked'
  | 'hs_sales_email_last_opened'
  | 'hs_sales_email_last_replied'
  | 'hs_sequences_is_enrolled'
  | 'message'
  | 'notes_last_contacted'
  | 'notes_last_updated'
  | 'notes_next_activity_date'
  | 'num_contacted_notes'
  | 'num_notes'
  | 'civilite'
  | 'createdate'
  | 'email'
  | 'environnement'
  | 'firstname'
  | 'fonction'
  | 'hs_content_membership_email_confirmed'
  | 'hs_content_membership_registered_at'
  | 'hs_createdate'
  | 'hs_merged_object_ids'
  | 'hs_object_id'
  | 'hs_persona'
  | 'lastname'
  | 'lifecyclestage'
  | 'phone'
  | 'role'
  | 'hs_facebook_click_id'
  | 'hs_google_click_id'
  | 'hs_ip_timezone'
  | 'ip_city'
  | 'ip_country'
  | 'ip_country_code'
  | 'ip_state'
  | 'ip_state_code'
  | 'num_conversion_events'
  | 'num_unique_conversion_events'
  | 'recent_conversion_date'
  | 'recent_conversion_event_name'
  | 'closedate'
  | 'days_to_close'
  | 'first_deal_created_date'
  | 'hs_buying_role'
  | 'num_associated_deals'
  | 'recent_deal_amount'
  | 'recent_deal_close_date'
  | 'total_revenue'
  | 'hs_email_bad_address'
  | 'hs_email_bounce'
  | 'hs_email_click'
  | 'hs_email_customer_quarantined_reason'
  | 'hs_email_delivered'
  | 'hs_email_first_click_date'
  | 'hs_email_first_open_date'
  | 'hs_email_first_reply_date'
  | 'hs_email_first_send_date'
  | 'hs_email_hard_bounce_reason_enum'
  | 'hs_email_last_click_date'
  | 'hs_email_last_email_name'
  | 'hs_email_last_open_date'
  | 'hs_email_last_reply_date'
  | 'hs_email_last_send_date'
  | 'hs_email_open'
  | 'hs_email_optout'
  | 'hs_email_optout_186404669'
  | 'hs_email_optout_189388289'
  | 'hs_email_quarantined'
  | 'hs_email_quarantined_reason'
  | 'hs_email_replied'
  | 'hs_email_sends_since_last_engagement'
  | 'hs_emailconfirmationstatus'
  | 'hs_legal_basis'
  | 'recevoir_newsletter'
  | 'company_size'
  | 'date_of_birth'
  | 'degree'
  | 'field_of_study'
  | 'gender'
  | 'graduation_date'
  | 'job_function'
  | 'marital_status'
  | 'military_status'
  | 'relationship_status'
  | 'school'
  | 'seniority'
  | 'start_date'
  | 'work_email'
  | 'hs_is_unworked'
  | 'hs_lead_status'
  | 'hubspot_owner_assigneddate'
  | 'hubspot_owner_id'
  | 'hubspot_team_id'
  | 'id_entreprise_principale'
  | 'hubspotscore'
  | 'axes_de_conformite'
  | 'url_invitation'
  | 'url_take_over';

export type HubspotContactProperties<
  T extends HubspotExaustiveContactPropertyKeys = HubspotExaustiveContactPropertyKeys,
> = {
  [key in T]?: string;
};
export type HubspotGenericContactResponse<
  T extends HubspotExaustiveContactPropertyKeys,
> = {
  id: string;
  properties: {
    [key in T]?: string;
  };
};

export type HubspotExaustiveCompanyPropertyKeys =
  | 'environnement'
  | 'hs_analytics_first_timestamp'
  | 'hs_analytics_last_timestamp'
  | 'hs_analytics_last_visit_timestamp'
  | 'hs_analytics_num_page_views'
  | 'hs_analytics_num_visits'
  | 'engagements_last_meeting_booked'
  | 'engagements_last_meeting_booked_source'
  | 'hs_last_booked_meeting_date'
  | 'hs_last_logged_call_date'
  | 'hs_last_open_task_date'
  | 'hs_last_sales_activity_timestamp'
  | 'hs_lastmodifieddate'
  | 'millesime_actuel'
  | 'millesimes_coa'
  | 'millesimes_cobsp'
  | 'millesimes_mia'
  | 'millesimes_miobsp'
  | 'notes_last_contacted'
  | 'notes_last_updated'
  | 'notes_next_activity_date'
  | 'num_contacted_notes'
  | 'about_us'
  | 'annualrevenue'
  | 'categorie_de_representation'
  | 'city'
  | 'closedate'
  | 'code_naf'
  | 'complement_d_adresse'
  | 'controlable'
  | 'convention_collective'
  | 'country'
  | 'createdate'
  | 'createur'
  | 'date_adhesion'
  | 'date_derniere_conformite'
  | 'date_derniere_facture'
  | 'days_to_close'
  | 'derniere_facture'
  | 'description'
  | 'domain'
  | 'domaine_d_activite'
  | 'engagements_last_meeting_booked_medium'
  | 'etablissement_employeur'
  | 'first_contact_createdate'
  | 'forme_juridique'
  | 'founded_year'
  | 'greffe'
  | 'hs_analytics_last_touch_converting_campaign'
  | 'hs_analytics_latest_source'
  | 'hs_analytics_latest_source_data_1'
  | 'hs_analytics_latest_source_data_2'
  | 'hs_analytics_latest_source_timestamp'
  | 'hs_analytics_source'
  | 'hs_analytics_source_data_1'
  | 'hs_analytics_source_data_2'
  | 'hs_created_by_user_id'
  | 'hs_createdate'
  | 'hs_merged_object_ids'
  | 'hs_num_child_companies'
  | 'hs_object_id'
  | 'hs_parent_company_id'
  | 'industry'
  | 'is_public'
  | 'libelle_de_la_voie'
  | 'libelle_naf'
  | 'lifecyclestage'
  | 'name'
  | 'nom_personne_physique'
  | 'num_associated_contacts'
  | 'numberofemployees'
  | 'numero_de_voie'
  | 'objet_social'
  | 'organisations_professionnelle'
  | 'personne_morale'
  | 'phone'
  | 'prenom_personne_physique'
  | 'siren'
  | 'soumise_a_la_conformite'
  | 'state'
  | 'statut_rcs'
  | 'timezone'
  | 'total_money_raised'
  | 'total_revenue'
  | 'tranche_d_effectif'
  | 'type'
  | 'type_d_adhesion'
  | 'type_de_voie'
  | 'web_technologies'
  | 'website'
  | 'zip'
  | 'first_conversion_date'
  | 'first_conversion_event_name'
  | 'hs_analytics_first_touch_converting_campaign'
  | 'hs_analytics_first_visit_timestamp'
  | 'num_conversion_events'
  | 'recent_conversion_date'
  | 'recent_conversion_event_name'
  | 'first_deal_created_date'
  | 'hs_num_open_deals'
  | 'hs_total_deal_value'
  | 'num_associated_deals'
  | 'recent_deal_amount'
  | 'recent_deal_close_date'
  | 'activites_bancaires'
  | 'annee'
  | 'categories_d_activites'
  | 'categories_d_activites_accessoires'
  | 'chiffre_d_affaire'
  | 'chiffre_d_affaire_export'
  | 'collecte_de_fonds'
  | 'date_d_inscription_orias'
  | 'date_de_suppression_orias'
  | 'mandants'
  | 'numero_orias'
  | 'resultat'
  | 'sous_categories_d_activites_cobsp'
  | 'sous_categories_d_activites_miobsp'
  | 'statut_orias'
  | 'civilite'
  | 'nom'
  | 'prenom'
  | 'hs_lead_status'
  | 'hubspot_owner_assigneddate'
  | 'hubspot_owner_id'
  | 'hubspot_team_id'
  | 'facebook_company_page'
  | 'facebookfans'
  | 'googleplus_page'
  | 'linkedin_company_page'
  | 'twitterhandle'
  | 'hs_ideal_customer_profile'
  | 'hs_is_target_account'
  | 'hs_num_blockers'
  | 'hs_num_contacts_with_buying_roles'
  | 'hs_num_decision_makers'
  | 'type_souscripteur'
  | 'renouvellement_offert'
  | 'date_controle_quinquennal'
  | 'ticket_controle_quinquennal'
  | 'pipeline_controle_quinquennal';

export type HubspotCompanyProperties<
  T extends HubspotExaustiveCompanyPropertyKeys = HubspotExaustiveCompanyPropertyKeys,
> = {
  [key in T]?: string;
};

export type HubspotGenericCompanyResponse<
  T extends HubspotExaustiveCompanyPropertyKeys,
> = {
  id: string;
  properties: {
    [key in T]?: string;
  };
};

export enum HubspotAssociationTypes {
  primaryContactToCompany = 1,
  primaryCompanyToContact = 2,
  dealToContact = 3,
  contactToDeal = 4,
  primaryDealToCompany = 5,
  primaryCompanyToDeal = 6,
  childCompanyToParentCompany = 14,
  contactToTicket = 15,
  ticketToContact = 16,
  dealToLineItem = 19,
  lineItemToDeal = 20,
  primaryCompanyToTicket = 25,
  primaryTicketToCompany = 26,
  dealToTicket = 27,
  ticketToDeal = 28,
  dealToQuote = 63,
  quoteToDeal = 64,
  quoteToLineItem = 67,
  lineItemToQuote = 68,
  quoteToContact = 69,
  contactToQuote = 70,
  quoteToCompany = 71,
  companyToQuote = 72,
  contactToFeedbackSubmission = 97,
  feedbackSubmissionToContact = 98,
  ticketToFeedbackSubmission = 99,
  feedbackSubmissionToTicket = 100,
  companyToCall = 181,
  callToCompany = 182,
  companyToEmail = 185,
  emailToCompany = 186,
  companyToMeeting = 187,
  meetingToCompany = 188,
  companyToNote = 189,
  noteToCompany = 190,
  companyToTask = 191,
  taskToCompany = 192,
  contactToCall = 193,
  callToContact = 194,
  contactToEmail = 197,
  emailToContact = 198,
  contactToMeeting = 199,
  meetingToContact = 200,
  contactToNote = 201,
  noteToContact = 202,
  contactToTask = 203,
  taskToContact = 204,
  dealToCall = 205,
  callToDeal = 206,
  dealToEmail = 209,
  emailToDeal = 210,
  dealToMeeting = 211,
  meetingToDeal = 212,
  dealToNote = 213,
  noteToDeal = 214,
  dealToTask = 215,
  taskToDeal = 216,
  quoteToTask = 217,
  taskToQuote = 218,
  ticketToCall = 219,
  callToTicket = 220,
  ticketToEmail = 223,
  emailToTicket = 224,
  ticketToMeeting = 225,
  meetingToTicket = 226,
  ticketToNote = 227,
  noteToTicket = 228,
  ticketToTask = 229,
  taskToTicket = 230,
  contactToCompany = 279,
  companyToContact = 280,
  ticketToCompany = 339,
  companyToTicket = 340,
  dealToCompany = 341,
  companyToDeal = 342,
  callToMeeting = 399,
  meetingToCall = 400,
  postalMailToContact = 453,
  contactToPostalMail = 454,
  postalMailToTicket = 455,
  ticketToPostalMail = 456,
  postalMailToDeal = 457,
  dealToPostalMail = 458,
  postalMailToCompany = 459,
  companyToPostalMail = 460,
  companyToParentCompany = 14,
}
