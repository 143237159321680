import {
  GatewayAdhesion,
  GatewayEntreprise,
  GatewayPaiement,
} from "@conformite/gateway";
import { useCallback, useEffect, useState } from "react";
import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import {
  EntreprisePaymentForm,
  usePaymentButtonLabel,
} from "@src/societes/form/EntreprisePaymentForm";
import { AdhesionDataEntreprisesItem } from "@src/adhesion/AdhesionData/adhesionDataEntreprises";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { callCreateIntentionPaiementAdhesionEntreprise } from "@src/payment/utils/createIntentionPaiement";
import {
  getCreateEntrepriseParamsEntreprise,
  storeEntrepriseCreationRequest,
} from "@src/payment/utils/createEntreprises";
import { useMe } from "@src/store/store.me";
import { useNavigate } from "react-router-dom";
import {
  ParcoursEntrepriseStepComponentProps,
  ParcoursEntrepriseStepEnum,
} from "../ParcoursEntreprise";
import { ParcoursEntrepriseTemplate } from "../ParcoursEntrepriseTemplate";
import {
  ID_FORM_ENTREPRISE,
  ParcoursEntrepriseActions,
} from "../ParcoursEntrepriseActions";

const useIntention = (
  typeAdhesion: GatewayEntreprise.TypeAdhesionActive | undefined,
  data: AdhesionDataEntreprisesItem,
  catchError: (err: Error) => void
) => {
  const [paiementIntention, setPaiementIntention] = useState<
    GatewayAdhesion.CreateIntentionPaimentResponseType | undefined
  >();
  const navigate = useNavigate();
  useEffect(() => {
    if (!typeAdhesion) return;
    callCreateIntentionPaiementAdhesionEntreprise(typeAdhesion, data)
      .then((response) => {
        if (!response.paiement.intentId) {
          const createEntrepriseParams = getCreateEntrepriseParamsEntreprise(
            response,
            data,
            typeAdhesion
          );
          storeEntrepriseCreationRequest(typeAdhesion, createEntrepriseParams);
          navigate(`/adhesion/creation-entreprises`);
        } else {
          setPaiementIntention(response);
        }
        setPaiementIntention(response);
      })
      .catch(catchError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!typeAdhesion]);
  return paiementIntention;
};

export function EntreprisePaiementStep({
  changeStep,
  data,
}: ParcoursEntrepriseStepComponentProps) {
  const [isPaiementSubmitting, setPaiementIsSubmitting] = useState(false);
  const { me } = useMe();
  const [modePrelevement, setModePrelevement] =
    useState<GatewayPaiement.PaymentMethod>();

  const handleGatewayErrorCallback = useCallback(
    (error: Error) =>
      handleGatewayError({
        onUnhandled: (e) => {
          displayErrorToast("Une erreur est survenue");
          console.error(e);
        },
      })(error),
    []
  );

  const intention = useIntention(
    me?.entreprisePrincipale
      .typeAdhesion as GatewayEntreprise.TypeAdhesionActive,
    data,
    handleGatewayErrorCallback
  );

  const nextLabel = usePaymentButtonLabel(modePrelevement);

  if (!me) return <div />;

  const createEntrepriseParams = intention
    ? getCreateEntrepriseParamsEntreprise(
        intention,
        data,
        me.entreprisePrincipale
          .typeAdhesion as GatewayEntreprise.TypeAdhesionActive
      )
    : undefined;
  return (
    <ParcoursEntrepriseTemplate
      title="Paiement de l'adhésion"
      actions={
        !intention?.paiement.clientSecret ? null : (
          <ParcoursEntrepriseActions
            onPrevious={() =>
              changeStep(
                ParcoursEntrepriseStepEnum.INFORMATIONS_COMPLEMENTAIRES
              )
            }
            isLoading={isPaiementSubmitting}
          >
            {nextLabel}
          </ParcoursEntrepriseActions>
        )
      }
    >
      <EntreprisePaymentForm
        setPaiementIsSubmitting={setPaiementIsSubmitting}
        formId={ID_FORM_ENTREPRISE}
        isGroup
        onModePrelevementChange={setModePrelevement}
        intention={intention}
        createEntrepriseParams={createEntrepriseParams}
        changeStep={changeStep}
      />
    </ParcoursEntrepriseTemplate>
  );
}
