import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactNode } from "react";
import {
  GatewayContact,
  GatewayEntreprise,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { Pagination } from "@src/components/Pagination/Pagination";
import { ReactComponent as Trash } from "@src/assets/icons/trash.svg";
import { ListTemplate } from "@src/components/ListTemplate/ListTemplate";
import { FilterSearch } from "@src/components/FilterSearch/FilterSearch";
import { InputSearch } from "@src/components/InputSearch/InputSearch";
import { Table } from "@src/components/Table/Table";
import { IconStatus } from "@src/components/IconStatus/IconStatus";
import { useDialog } from "@src/components/Dialog/useDialog";
import { GatewayEntrepriseApi } from "@src/api/entreprise2.api";
import { useIsAllowed } from "@src/session/useIsAllowed";
import { FilterMultiSelect } from "@src/components/Filter/FilterMultiSelect";
import { FilterDropdown } from "@src/components/Filter/FilterDropdown";
import { SortDropdown } from "@src/components/Filter/SortDropdown";
import { SortSelect } from "@src/components/Filter/SortSelect";
import {
  SOCIETES_PAGE_SIZE,
  UseSocieteFilteredReturn,
} from "./useSocieteFiltered";
import { useEntrepriseAvancement } from "./useEntrepriseAvancement";
import style from "./Societes.module.scss";

const getLabelForSuppression = (nbSelectedRows: number) => {
  return {
    title: `Supprimer ${
      nbSelectedRows > 1
        ? "les sociétés sélectionnées"
        : "la société sélectionnée"
    }`,
    message: `Êtes-vous sur(e) de vouloir supprimer ${
      nbSelectedRows > 1 ? "ces sociétés" : "cette société"
    } ? Cette action est irréversible`,
  };
};
export type EntrepriseComponent = (props: {
  entreprise: GatewayEntreprise.EntrepriseAControllerResponseType;
}) => JSX.Element;
type SocieteListProps = {
  isLoading: boolean;
  adminActions?: ReactNode;
  SocieteConformite: EntrepriseComponent;
  SocieteActions: EntrepriseComponent;
  actionOnClick?: (
    entreprise: GatewayEntreprise.EntrepriseAControllerResponseType
  ) => void;
  onDeleteEntreprises: (entreprisesIds: string[]) => void;
} & UseSocieteFilteredReturn;
export function SocieteList({
  isLoading,
  SocieteConformite,
  adminActions,
  SocieteActions,
  actionOnClick,
  onDeleteEntreprises,
  page,
  setPage,
  numberOfContentUnpaginated: numberOfSocietes,
  filtersSelected,
  setFilterSelected,
  content: societesPaginated,
  sortSelected,
  setSortSelected,
}: SocieteListProps) {
  const { openConfirmDialog } = useDialog();
  const { getEntrepriseAvancement } = useEntrepriseAvancement();
  const isConforme = (
    entreprise: GatewayEntreprise.EntrepriseAControllerResponseType
  ) => getEntrepriseAvancement(entreprise) === 100;
  const { hasAllowedRole } = useIsAllowed();

  return (
    <ListTemplate
      isLoading={isLoading}
      header={
        <FilterSearch
          search={
            <InputSearch
              placeholder="Raison sociale, SIREN"
              name="search"
              value={filtersSelected?.raisonSociale[0] || ""}
              onChange={(nextValue) => {
                setFilterSelected("raisonSociale", [nextValue.target.value]);
                setPage(0);
              }}
            />
          }
          action={adminActions}
          filter={
            <>
              <FilterDropdown
                filtersSelected={filtersSelected}
                blacklist={["raisonSociale"]}
              >
                <FilterMultiSelect
                  name="filterConformite"
                  label="Conformité"
                  filtersSelected={filtersSelected}
                  setFilterSelected={setFilterSelected}
                  keyFitler="conformite"
                  options={[
                    {
                      value: "true",
                      label: "Conforme",
                    },
                    {
                      value: "false",
                      label: "Non conforme",
                    },
                  ]}
                />
                <FilterMultiSelect
                  name="filterCategorie"
                  label="Categorie"
                  filtersSelected={filtersSelected}
                  setFilterSelected={setFilterSelected}
                  keyFitler="categorie"
                  options={Object.values(GatewayInfoSocieteOrias.Categorie).map(
                    (categorie) => ({
                      value: categorie,
                      label: categorie,
                    })
                  )}
                />
              </FilterDropdown>
              <SortDropdown>
                <SortSelect
                  name="sort"
                  sortSelected={sortSelected}
                  setSortSelected={setSortSelected}
                  options={[
                    {
                      label: "Raison sociale",
                      value: "raisonSociale",
                    },
                    {
                      label: "Conformite",
                      value: "conformite",
                    },
                  ]}
                />
              </SortDropdown>
            </>
          }
        />
      }
      table={
        !isLoading && (
          <Table
            selectionOptions={
              hasAllowedRole(
                GatewayContact.ContactRoleUtilisateur.ADMINISTRATEUR
              )
                ? {
                    renderActions: (selectedRows) => {
                      const { title, message } = getLabelForSuppression(
                        selectedRows.length
                      );
                      return (
                        <Button
                          icon={<Trash />}
                          onClick={() => {
                            openConfirmDialog({
                              title,
                              message,
                              cancelText: "Annuler",
                              confirmText: "Supprimer",
                            })
                              .then((shouldcontinue) => {
                                if (shouldcontinue) {
                                  const ids = selectedRows
                                    .filter((entreprise) => !!entreprise)
                                    .map(({ hubspotId }) => hubspotId);
                                  GatewayEntrepriseApi.deleteEntreprise({
                                    ids,
                                  })
                                    .then(() => onDeleteEntreprises(ids))
                                    .catch(console.error);
                                }
                              })
                              .catch(console.error);
                          }}
                          variant="text"
                          className={style.deleteButton}
                        >
                          {title}
                        </Button>
                      );
                    },
                  }
                : undefined
            }
            data={societesPaginated}
            columns={[
              {
                key: "validSociete",
                size: "auto",
                render: (entreprise) => (
                  <IconStatus isValid={isConforme(entreprise)} />
                ),
              },
              {
                key: "raisonSociale",
                title: "Raison sociale",
                size: 1,
                render: (entreprise) => <p>{entreprise.raisonSociale}</p>,
              },
              {
                key: "siren",
                title: "SIREN",
                size: 1,
                render: (entreprise) => <p>{entreprise.siren}</p>,
              },
              {
                key: "conformite",
                title: "Conformité",
                size: 1,
                render: (entreprise) => (
                  <SocieteConformite entreprise={entreprise} />
                ),
              },
              {
                key: "actions",
                size: "auto",
                render: (entreprise) => (
                  <SocieteActions entreprise={entreprise} />
                ),
              },
            ]}
            onRowClick={actionOnClick}
          />
        )
      }
      footer={
        <Pagination
          page={page}
          pageSize={SOCIETES_PAGE_SIZE}
          total={numberOfSocietes}
          onChange={setPage}
        />
      }
    />
  );
}
