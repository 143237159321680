import {
  Button,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { useCallback, useMemo, useState } from "react";
import { ReactComponent as PlusCircle } from "@src/assets/icons/plus-circle.svg";
import { GatewayConformite, GatewayContact } from "@conformite/gateway";
import { differenceWith, keyBy } from "lodash";
import { useEntreprises } from "@src/store/store.entreprise";
import { useContactsWithAssociations } from "@src/store/store.contact-with-associations";
import { ContactsWithAssociations } from "@src/store/store.definition";
import { useMe } from "@src/store/store.me";
import { useIsAllowed } from "@src/session/useIsAllowed";
import { GatewayContactApi } from "@src/api/contact.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { useDialog } from "@src/components/Dialog/useDialog";
import { ParametrePageTemplate } from "../ParametrePageTemplate";
import { GestionUtilisateursByRole } from "./GestionUtilisateurByRole";
import { AjoutContactPane, AjoutContactPaneType } from "./AjoutContactPane";

export const labelsByAxesConformite: Record<
  GatewayConformite.AxeConformite,
  string
> = {
  [GatewayConformite.AxeConformite.HONORABILITE]: "Honorabilité",
  [GatewayConformite.AxeConformite.FORMATION_CONTINUE_IAS]:
    "Formation continue IAS",
  [GatewayConformite.AxeConformite.FORMATION_CONTINUE_IOBSP]:
    "Formation continue IOBSP",
  [GatewayConformite.AxeConformite.CAPACITE_PRO_IAS]: "Capacité pro IAS",
  [GatewayConformite.AxeConformite.CAPACITE_PRO_IOBSP]: "Capacité pro IOBSP",
  [GatewayConformite.AxeConformite.RC_PRO_COA]: "RC Pro COA",
  [GatewayConformite.AxeConformite.RC_PRO_MIA]: "RC Pro MIA",
  [GatewayConformite.AxeConformite.RC_PRO_COBSP]: "RC Pro COBSP",
  [GatewayConformite.AxeConformite.RC_PRO_MIOBSP]: "RC Pro MIOBSP",
  [GatewayConformite.AxeConformite.GARANTIE_FINANCIERE_IAS]:
    "Garanties Financières IAS",
  [GatewayConformite.AxeConformite.GARANTIE_FINANCIERE_IOBSP]:
    "Garantie financière IOBSP",
  [GatewayConformite.AxeConformite.DECLARATION_ACTIVITE_IAS]:
    "Déclaration d'activité IAS",
  [GatewayConformite.AxeConformite.DECLARATION_ACTIVITE_IOBSP]:
    "Déclaration d'activité IOBSP",
};

const roleLabelByRoleUtilisateur: Record<
  GatewayContact.ContactRoleUtilisateur,
  string
> = {
  [GatewayContact.ContactRoleUtilisateur.ADMINISTRATEUR]: "Administrateur",
  [GatewayContact.ContactRoleUtilisateur.RESPONSABLE_CONFORMITE]:
    "Responsable conformité",
  [GatewayContact.ContactRoleUtilisateur.UTILISATEUR_SIMPLE]: "Utilisateur",
};

export function GestionUtilisateurs() {
  const {
    contacts,
    addContact,
    updateContact,
    deleteContact,
    status: statusContacts,
  } = useContactsWithAssociations();
  const { me, status: statusMe } = useMe();
  const entreprisePrincipale = me?.entreprisePrincipale;
  const { entreprisesBySiren, status: statusEntreprises } = useEntreprises();
  const [openPane, setOpenPane] = useState<AjoutContactPaneType>();
  const [reopenKey, setReopenKey] = useState(0);
  const { hasAllowedRole } = useIsAllowed();
  const { openConfirmDialog } = useDialog();
  const [contactToUpdate, setContactToUpdate] =
    useState<GatewayContact.ContactWithAssociationsResponseType>();

  const handleUpdateOpenPane =
    (pane: AjoutContactPaneType) => (nextIsOpen: boolean) => {
      setOpenPane(nextIsOpen ? pane : undefined);
      setReopenKey((prev) => prev + 1);
    };

  const raisonsSocialesByHubspotId = useMemo<Record<string, string>>(() => {
    if (!entreprisesBySiren) return {};
    return Object.values(entreprisesBySiren).reduce(
      (acc, { hubspotId, raisonSociale }) => ({
        ...acc,
        [hubspotId]: raisonSociale,
      }),
      {}
    );
  }, [entreprisesBySiren]);

  const contactsByRole = useMemo<{
    admins: ContactsWithAssociations[];
    others: ContactsWithAssociations[];
  }>(() => {
    const response: {
      admins: ContactsWithAssociations[];
      others: ContactsWithAssociations[];
    } = { admins: [], others: [] };
    if (!contacts || contacts.length === 0) return response;

    response.admins = contacts.filter(
      ({ role }) =>
        role === GatewayContact.ContactRoleUtilisateur.ADMINISTRATEUR
    );

    response.others = differenceWith(
      contacts,
      response.admins,
      ({ id: idA }, { id: idB }) => idA === idB
    );

    return response;
  }, [contacts]);

  const contactsById = useMemo(
    () => keyBy(contacts, (contact) => contact.id),
    [contacts]
  );

  const handleDeleteUtilisateur = useCallback(
    (contactId: string) => {
      const contact = contactsById[contactId];
      if (!contact)
        return displayErrorToast(
          "Une erreur est survenue lors de la suppression du contact."
        );

      openConfirmDialog({
        title: `Êtes-vous sûr(e) de vouloir supprimer le compte de ${contact.prenom} ${contact.nom} ?`,
        confirmText: "Supprimer",
        cancelText: "Annuler",
      })
        .then((shouldContinue) => {
          if (shouldContinue)
            GatewayContactApi.delete(contact.id)
              .then(() => {
                deleteContact(contact.id);
              })
              .catch(
                handleGatewayError({
                  onUnhandled: () =>
                    displayErrorToast(
                      "Une erreur est survenue lors de la suppression du contact."
                    ),
                })
              );
        })
        .catch(console.error);

      return true;
    },
    [contactsById, openConfirmDialog, deleteContact]
  );

  const handleOpenUpdatePaneContact = (
    contactId: string,
    pane: AjoutContactPaneType
  ) => {
    const contact = contactsById[contactId];
    if (!contact) return displayErrorToast("Une erreur est survenue.");
    setContactToUpdate(contact);
    handleUpdateOpenPane(pane)(true);

    return true;
  };

  const pageIsLoading = useMemo(
    () =>
      !![statusContacts, statusMe, statusEntreprises].find(
        (status) => status !== "loaded"
      ),
    [statusContacts, statusMe, statusEntreprises]
  );

  return (
    <ParametrePageTemplate isFullPage title="Gestion des utilisateurs">
      {hasAllowedRole(GatewayContact.ContactRoleUtilisateur.ADMINISTRATEUR) && (
        <GestionUtilisateursByRole
          onUpdate={(contactId) =>
            handleOpenUpdatePaneContact(contactId, AjoutContactPaneType.ADMIN)
          }
          onDelete={handleDeleteUtilisateur}
          isLoading={pageIsLoading}
          data={contactsByRole.admins.map((admin) => ({
            entreprises: entreprisePrincipale?.raisonSociale ?? "",
            nom: `${admin.prenom} ${admin.nom}`,
            role: roleLabelByRoleUtilisateur[admin.role],
            id: admin.id,
          }))}
          title="Administrateurs"
          actions={
            <Button
              icon={<PlusCircle />}
              variant="outline"
              onClick={() => {
                setContactToUpdate(undefined);
                handleUpdateOpenPane(AjoutContactPaneType.ADMIN)(true);
              }}
            >
              Ajouter un administrateur
            </Button>
          }
          info="Seuls les administrateurs peuvent ajouter des sociétés, gérer les adhésions et payer les cotisations."
        />
      )}
      <GestionUtilisateursByRole
        onDelete={handleDeleteUtilisateur}
        onUpdate={(contactId) =>
          handleOpenUpdatePaneContact(contactId, AjoutContactPaneType.USER)
        }
        isLoading={pageIsLoading}
        data={contactsByRole.others.map(
          ({
            nom,
            prenom,
            role,
            entreprisesAssociesIds,
            axesConformite,
            id,
          }) => ({
            nom: `${prenom} ${nom}`,
            entreprises: entreprisesAssociesIds
              .map((entrepriseId) => raisonsSocialesByHubspotId[entrepriseId])
              .join(", "),
            role: `${roleLabelByRoleUtilisateur[role]} ${
              GatewayContact.ContactRoleUtilisateur.UTILISATEUR_SIMPLE === role
                ? ` : ${axesConformite
                    ?.map((axe) => labelsByAxesConformite[axe])
                    ?.join(", ")}`
                : ""
            }`,
            id,
          })
        )}
        title="Responsables conformité et utilisateurs"
        actions={
          <Button
            icon={<PlusCircle />}
            variant="outline"
            onClick={() => {
              setContactToUpdate(undefined);
              handleUpdateOpenPane(AjoutContactPaneType.USER)(true);
            }}
          >
            Ajouter un utilisateur
          </Button>
        }
        info={
          <p>
            Les responsables conformité ont accès à toutes les informations des
            sociétés qui leur sont rattachées, et peuvent ajouter un utilisateur
            simple. <br />
            Les utilisateurs simples accèdent uniquement à certains axes des
            sociétés qui leur sont rattachées.
          </p>
        }
      />
      <AjoutContactPane
        type={openPane}
        reopenKey={reopenKey}
        executeAfterAdd={addContact}
        executeAfterUpdate={updateContact}
        contact={contactToUpdate}
      />
    </ParametrePageTemplate>
  );
}
