import { GatewayContact } from "@conformite/gateway";
import { GatewayAssujettiApi } from "@src/api/assujetti.api";
import { GatewayContactApi } from "@src/api/contact.api";
import { ControleQuinquennalApi } from "@src/api/controleQuinquennal.api";
import { GatewayEntrepriseApi } from "@src/api/entreprise2.api";
import { GatewayMeApi } from "@src/api/me.api";
import { StoreContextValue, StoreEntry } from "./store";
import {
  Assujetti,
  AssujettiByMillessime,
  ContactsWithAssociations,
  ControleQuinquennalStore,
  EntreprisesByMillesime,
  EntreprisesBySiren,
  Me,
} from "./store.definition";
import {
  StoreEntryMillesime,
  retrieveMillesimeFromLocalStorage,
} from "./store.millesime";

export async function refreshControleQuinquennal(
  controleQuinquennalStore: StoreEntry<ControleQuinquennalStore>
) {
  controleQuinquennalStore.setStatus("loading");
  const controlesQuinquennauxApiResponse =
    await ControleQuinquennalApi.getControlesQuinquennaux();
  controleQuinquennalStore.setData({
    hasControlesQuinquennaux: controlesQuinquennauxApiResponse.length > 0,
  });
  controleQuinquennalStore.setStatus("loaded");
  return controlesQuinquennauxApiResponse;
}
export function refreshMe(meStore: StoreEntry<Me>) {
  meStore.setStatus("loading");
  return GatewayMeApi.getMe().then((meApi) => {
    meStore.setData(meApi);
    meStore.setStatus("loaded");
    return meApi;
  });
}

export async function refreshEntreprisesOfMillesime(
  entreprisesStore: StoreEntry<EntreprisesByMillesime>,
  selectedMillesime: number
) {
  entreprisesStore.setStatus("loading");
  const entreprises = await GatewayEntrepriseApi.listMyEntreprises(
    selectedMillesime
  );
  const entreprisesOfMillesime =
    entreprises?.reduce((acc, entreprise) => {
      acc[entreprise.siren] = entreprise;
      return acc;
    }, {} as EntreprisesBySiren) || {};
  const entreprisesByMillesime = {
    ...entreprisesStore.data,
    [selectedMillesime]: entreprisesOfMillesime,
  };
  entreprisesStore.setData(entreprisesByMillesime);
  entreprisesStore.setStatus("loaded");
  return entreprisesByMillesime;
}

export async function refreshAssujettiOfMillesime(
  assujettiStore: StoreEntry<AssujettiByMillessime>,
  selectedMillesime: number
) {
  assujettiStore.setStatus("loading");
  const array = await GatewayAssujettiApi.list(selectedMillesime);
  const assujettiOfMillesime = array.reduce((map, item) => {
    map[item.id] = item;
    return map;
  }, {} as Record<string, Assujetti>);
  const assujettiByMillesime = {
    ...assujettiStore.data,
    [selectedMillesime]: assujettiOfMillesime,
  };
  assujettiStore.setData(assujettiByMillesime);
  assujettiStore.setStatus("loaded");
  return assujettiByMillesime;
}

export async function refreshContactsWithAssociations(
  contactsStore: StoreEntry<ContactsWithAssociations[]>,
  isAllowed: boolean
) {
  if (!isAllowed) {
    contactsStore.setData(undefined);
    contactsStore.setStatus("loaded");
    return undefined;
  }
  contactsStore.setStatus("loading");
  const contacts = await GatewayContactApi.retrieveContactsWithAssociations();
  contactsStore.setData(contacts);
  contactsStore.setStatus("loaded");
  return contacts;
}

export function refreshMillesime(
  millesimeStore: StoreEntryMillesime,
  me: Me
): number {
  const millesime = retrieveMillesimeFromLocalStorage(
    me.entreprisePrincipale?.millesimeActuel ?? new Date().getFullYear()
  );
  millesimeStore.setSelected(millesime);
  return millesime;
}

export async function refreshMillesimeDependencies(
  storeValue: StoreContextValue,
  millesime: number
) {
  await Promise.all([
    refreshEntreprisesOfMillesime(storeValue.entreprises, millesime),
    refreshAssujettiOfMillesime(storeValue.assujetti, millesime),
  ]);
}

export async function refreshMeDependencies(
  storeValue: StoreContextValue,
  me: Me
) {
  refreshMillesime(storeValue.millesime, me);
  await refreshContactsWithAssociations(
    storeValue.contactsWithAssociations,
    me.contact &&
      me.contact.role !==
        GatewayContact.ContactRoleUtilisateur.UTILISATEUR_SIMPLE
  );
}

export function resetStore(storeValue: StoreContextValue) {
  storeValue.assujetti.reset();
  storeValue.contactsWithAssociations.reset();
  storeValue.entreprises.reset();
  storeValue.me.reset();
  storeValue.millesime.reset();
}
