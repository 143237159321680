import {
  displayErrorToast,
  Toaster,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { CreationEntrepriseParams } from "@src/api/adhesion2.api";
import { LoadingPageAfterPaiement } from "@src/components/LoadingPage/LoadingPage";
import { ToastContent } from "@src/components/ToastContent/ToastContent";
import {
  createEntreprises,
  retrieveEntrepriseCreationRequest,
} from "../../../payment/utils/createEntreprises";

type AdhesionCreationEntrepriseAfterPaiementProps = {
  isEntreprise?: boolean;
};
export function AdhesionCreationEntrepriseAfterPaiement({
  isEntreprise,
}: AdhesionCreationEntrepriseAfterPaiementProps) {
  const { search } = useLocation();
  const navigate = useNavigate();
  const navigateAfterSuccess = () => {
    if (isEntreprise) {
      navigate(`/adhesion-entreprise/remerciements${search}`);
    } else {
      navigate(`/adhesion/remerciements${search}`);
    }
  };

  useEffect(() => {
    try {
      const { typeAdhesion, creationEntrepriseParams } =
        retrieveEntrepriseCreationRequest();
      createEntreprises(
        typeAdhesion,
        creationEntrepriseParams as CreationEntrepriseParams
      )
        .then(() => {
          navigateAfterSuccess();
        })
        .catch(
          handleGatewayError({
            onResponse: (err) => {
              if (err.response.data.statusCode === 409) {
                navigateAfterSuccess();
                return;
              }
              displayErrorToast(
                "Une erreur est survenue lors du renouvellement des entreprises"
              );
            },
            onUnhandled: () => {
              displayErrorToast(
                "Une erreur est survenue lors de la création des entreprises",
                {
                  hideAfter: false,
                }
              );
            },
          })
        );
    } catch (err) {
      displayErrorToast(
        `Une erreur est survenue lors de l'envoi des entreprises : ${
          (err as Error).message
        }`,
        {
          canal: "bla",
          hideAfter: false,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Toaster
        canal="bla"
        positionning="local"
        position={{
          vertical: "bottom",
          horizontal: "center",
        }}
        ToastContentComponent={ToastContent}
        defaultHideAfter={5000}
      />
      <div style={{ minHeight: "100vh" }}>
        <LoadingPageAfterPaiement />
      </div>
    </>
  );
}
