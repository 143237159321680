import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";

const errorMessage = {
  password:
    "Votre mot de passe n'est pas assez sécurisé, vous devez avoir au moins 8 caractères dont 1 majuscule, 1 minuscule, 1 chiffre et un caractère spécial",
  confirmPassword:
    "La confirmation de votre mot de passe ne correspond pas à la valeur du champ Mot de passe",
};

export const passwordWithConfirmationSchema = zod
  .object({
    password: zod
      .string()
      .nonempty(errorMessage.password)
      .regex(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
        errorMessage.password
      ),
    confirm: zod.string().nonempty(errorMessage.confirmPassword),
  })
  .refine((data) => data.password === data.confirm, {
    message: errorMessage.confirmPassword,
    path: ["confirm"],
  });
