import { ParcoursAction } from "@src/parcours/NavigationButtons/ParcoursAction";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import { SignupForm, SignupFormValues } from "@src/user/Signup/SignupForm";
import { useCallback, useState } from "react";
import { GatewayAuthApi } from "@src/api/auth.api";
import { ReactComponent as Loader } from "@src/assets/loader.svg";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import {
  ID_FORM_INVITATION,
  ParcoursInvitationComponentProps,
  ParcoursInvitationStepEnum,
} from "../ParcoursInvitation";

export function ParcoursInvitationInformationsConnexion({
  addData,
  data,
  changeStep,
}: ParcoursInvitationComponentProps) {
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = useCallback(
    async (values: SignupFormValues) => {
      setIsLoading(true);
      try {
        await GatewayAuthApi.signup(
          values.email.email,
          values.password.password
        );
      } catch (err) {
        handleGatewayError({
          onResponse: (error) => {
            if (error.status === 409) {
              displayErrorToast(
                "Désolé, nous ne pouvons pas créer votre compte, l'adresse\
              email que vous avez renseigné est déjà utilisée",
                {
                  canal: "adhesion",
                }
              );
            } else {
              displayErrorToast(error.response.data.message, {
                canal: "adhesion",
              });
            }
          },
          onUnhandled: () => {
            displayErrorToast("Une erreur inconnue est survenue", {
              canal: "adhesion",
            });
          },
        })(err);
      }
      addData({
        email: values.email.email,
        password: values.password.password,
        consentement: values.consentCGV,
        veutRecevoirNewsletter: values.consentNewsletter,
      });
      setIsLoading(false);

      changeStep(ParcoursInvitationStepEnum.CONFIRMATION_EMAIL);
    },
    [addData, changeStep]
  );

  return (
    <ParcoursFullPageTemplate
      title="Informations de connexion"
      actions={
        <ParcoursAction
          formId={ID_FORM_INVITATION}
          type="submit"
          disabled={isLoading}
          icon={isLoading ? <Loader /> : undefined}
        >
          Suivant
        </ParcoursAction>
      }
    >
      <SignupForm
        formId={ID_FORM_INVITATION}
        onSubmit={handleSubmit}
        defaultValues={{
          email: { email: data.email },
          consentNewsletter: data.veutRecevoirNewsletter,
        }}
      />
    </ParcoursFullPageTemplate>
  );
}
