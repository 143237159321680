import {
  Button,
  FormField,
  FormGrid,
  FormRow,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { Dialog, DialogComponentProps } from "@src/components/Dialog/Dialog";
import { ReactComponent as XLg } from "@src/assets/icons/x-lg.svg";
import { useState } from "react";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Form,
  InputText,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { GatewayChangeEmailAPI } from "@src/api/change-email.api";
import { useMe } from "@src/store/store.me";
import style from "./ConfirmUpdateMailDialog.module.scss";

type ConfirmUpdateMailDialogProps = {
  onClose: () => void;
  email: string;
} & DialogComponentProps;

type InformationsStepContentProps = {
  isLoading: boolean;
  onCancel: () => unknown;
  onConfirm: () => unknown;
} & Pick<ConfirmUpdateMailDialogProps, "email">;

function InformationStepContent({
  onCancel,
  onConfirm,
  email,
  isLoading,
}: InformationsStepContentProps) {
  return (
    <>
      <FormRow>
        <FormField>
          <p className={style.information}>
            Nous allons vous envoyer un code à cette nouvelle adresse mail afin
            de la vérifier :
          </p>
          <p className={style.emailAddress}>{email}</p>
        </FormField>
      </FormRow>
      <FormRow>
        <FormField className={style.actions}>
          <Button variant="text" onClick={onCancel} icon={<XLg />}>
            Annuler la modification
          </Button>

          <Button onClick={onConfirm} disabled={isLoading}>
            Recevoir le code
          </Button>
        </FormField>
      </FormRow>
      <div />
    </>
  );
}

type ConfirmCodeStepContentProps = {
  onCancel: () => void;
  onConfirm: (values: ConfirmCodeValues) => void;
};
const confirmCodeSchema = zod.object({ code: zod.string() });
type ConfirmCodeValues = zod.infer<typeof confirmCodeSchema>;
const CONFIRM_CODE_FORM_ID = "confirm-code-form-id";
function ConfirmCodeStepContent({
  onCancel,
  onConfirm,
}: ConfirmCodeStepContentProps) {
  const formConfig = useFormConfig<typeof confirmCodeSchema, ConfirmCodeValues>(
    { schema: confirmCodeSchema }
  );

  return (
    <>
      <FormRow>
        <FormField>
          <p className={style.information}>
            Merci de renseigner le code que nous vous avons envoyé à votre
            nouvelle adresse :
          </p>
        </FormField>
      </FormRow>

      <FormRow>
        <FormField size="half">
          <Form
            formConfig={formConfig}
            onSubmit={onConfirm}
            id={CONFIRM_CODE_FORM_ID}
          >
            <InputText<ConfirmCodeValues> name="code" label="Code" required />
          </Form>
        </FormField>
      </FormRow>

      <FormRow>
        <FormField className={style.actions}>
          <Button variant="text" onClick={onCancel} icon={<XLg />}>
            Annuler la modification
          </Button>
          <Button
            type="submit"
            form={CONFIRM_CODE_FORM_ID}
            disabled={formConfig.formState.isSubmitting}
          >
            Modifier
          </Button>
        </FormField>
      </FormRow>
    </>
  );
}

export function ConfirmUpdateMailDialog({
  dialogRef,
  onClose,
  email,
}: ConfirmUpdateMailDialogProps) {
  const [step, setStep] = useState<"INFORMATIONS" | "CONFIRM_CODE">(
    "INFORMATIONS"
  );
  const [emailHash, setEmailHash] = useState<string>();
  const [isInformationLoading, setIsInformationLoading] = useState(false);
  const { updateContact } = useMe();

  return (
    <Dialog dialogRef={dialogRef}>
      <FormGrid>
        <FormRow>
          <FormField>
            <h3 className={style.title}>Modification de votre adresse mail</h3>
          </FormField>
        </FormRow>

        {step === "INFORMATIONS" && (
          <InformationStepContent
            isLoading={isInformationLoading}
            onCancel={onClose}
            onConfirm={async (): Promise<void> => {
              try {
                setIsInformationLoading(true);
                const emailHashResponse =
                  await GatewayChangeEmailAPI.changeEmail({ email });
                setEmailHash(emailHashResponse);
                setStep("CONFIRM_CODE");
              } catch (err) {
                handleGatewayError({
                  onResponse: (e) => {
                    displayErrorToast(e.response.data.message);
                  },
                  onUnhandled: (e) => {
                    console.error(e);
                    displayErrorToast(
                      "Une erreur est survenue lors du changement de votre adresse mail"
                    );
                  },
                })(err);
                onClose();
              } finally {
                setIsInformationLoading(false);
              }
            }}
            email={email}
          />
        )}

        {step === "CONFIRM_CODE" && (
          <ConfirmCodeStepContent
            onCancel={onClose}
            onConfirm={(values) => {
              if (!emailHash)
                displayErrorToast("Une erreur inconnue est survenue");
              else
                GatewayChangeEmailAPI.verify({ ...values, email, emailHash })
                  .then(() => {
                    updateContact({ email });
                    onClose();
                  })
                  .catch(console.error);
            }}
          />
        )}
      </FormGrid>
    </Dialog>
  );
}
