import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import {
  AdhesionStepEnum,
  ParcoursAdhesionStepComponentProps,
} from "@src/adhesion/ParcoursAdhesion.definition";
import { GatewayAdhesion2Api } from "@src/api/adhesion2.api";
import { GatewayAdhesion } from "@conformite/gateway";
import { useCallback } from "react";
import { useStoreRefresh } from "@src/store/store.refresh";
import { useSession } from "@src/session/UserSessionContext";
import { useOnce } from "@src/hooks/useOnce";
import { LoadingPage } from "@src/components/LoadingPage/LoadingPage";

export function AdhesionInit({
  changeStep,
  data,
}: ParcoursAdhesionStepComponentProps) {
  const session = useSession();
  const refreshStore = useStoreRefresh();
  const initAccount = useCallback(async () => {
    await GatewayAdhesion2Api.init({
      contact: {
        civilite: data.informationsPersonnelles.civilite,
        nom: data.informationsPersonnelles.nom,
        prenom: data.informationsPersonnelles.prenom,
        telephone: data.informationsPersonnelles.telephone,
        fonction: data.informationsPersonnelles.fonction,
        email: data.identifiants.email,
        consentement: !!data.consents.consentCGV,
        veutRecevoirNewsletter: !!data.consents.consentNewsletter,
      },
      entreprisePrincipale: {
        siren: data.entreprise.siren,
        raisonSociale: data.entreprise.raisonSociale,
        adresse: {
          typeVoie: data.entrepriseAdresse.typeVoie,
          libelleVoie: data.entrepriseAdresse.libelleVoie,
          numeroVoie: data.entrepriseAdresse.numeroVoie,
          complementAdresse: data.entrepriseAdresse.complementAdresse,
          codePostal: data.entrepriseAdresse.codePostal,
          ville: data.entrepriseAdresse.ville,
          pays: data.entrepriseAdresse.pays,
        },
        formeJuridique: data.entreprise.formeJuridique,
        trancheEffectif: data.entreprise.trancheEffectif,
        typeSouscripteur: data.entreprise.typeSouscripteur,
      } as GatewayAdhesion.InitRequestType["entreprisePrincipale"],
    });
    if (data.identifiants.password) {
      await session.login(data.identifiants.email, data.identifiants.password);
    } else {
      await session.refresh();
    }
    refreshStore();
    changeStep(AdhesionStepEnum.CREATION_SUCCESS);
  }, [changeStep, data, refreshStore, session]);
  useOnce(() => {
    initAccount().catch((err) => {
      handleGatewayError({
        onResponse: (error) => {
          displayErrorToast(error.response.data.message, {
            canal: "adhesion",
          });
        },
        onUnhandled: (error) => {
          console.error(error);
          displayErrorToast("Une erreur inconnue est survenue", {
            canal: "adhesion",
          });
        },
      })(err);
    });
  });

  return <LoadingPage />;
}
