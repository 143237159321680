import { InputDate } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  FormField,
  FormGrid,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { FormWithFiles } from "./ConformiteGarantieFinanciereEditFiles";

type ConformiteGarantieFinanciereEditFileValuesProps = {
  index: number;
  form: FormWithFiles;
};
type ConformiteGarantieFinanciereEditFileValue = {
  files: { startDate: Date; endDate: Date; orias: string }[];
};
export function ConformiteGarantieFinanciereEditFileValues({
  index,
  form,
}: ConformiteGarantieFinanciereEditFileValuesProps) {
  return (
    <FormGrid disableHorizontalGutter>
      <FormRow>
        <FormField size="half">
          <InputDate<ConformiteGarantieFinanciereEditFileValue>
            name={`files.${index}.startDate`}
            label="Date de début de validité"
            onChange={(value) => {
              form.setValue(`files.${index}.startDate`, value as Date, {
                shouldDirty: true,
              });
              form.trigger(`files.0.startDate`).catch(console.error);
            }}
          />
        </FormField>
        <FormField size="half">
          <InputDate<ConformiteGarantieFinanciereEditFileValue>
            name={`files.${index}.endDate`}
            label="Date de fin de validité"
            onChange={(value) => {
              form.setValue(`files.${index}.endDate`, value as Date, {
                shouldDirty: true,
              });
              form.trigger(`files.0.startDate`).catch(console.error);
            }}
          />
        </FormField>
      </FormRow>
    </FormGrid>
  );
}
