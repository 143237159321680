import { useAssujetti } from "@src/store/store.assujetti";
import { useCallback, useMemo } from "react";
import { TitleWithNumber } from "@src/components/TitleWithNumber/TitleWithNumber";
import { useMillesime } from "@src/store/store.millesime";
import { SidePane } from "@src/components/SidePane/SidePane";
import {
  AssujettiPaneContent,
  AssujettiPaneContentPage,
} from "@src/assujettis/AssujettiPaneContent/AssujettiPaneContent";
import { useAssujettiPane } from "@src/assujettis/AssujettiPaneContent/useAssujettiPane";
import { useDialog } from "@src/components/Dialog/useDialog";
import { Assujetti } from "@src/store/store.definition";
import { GatewayAssujettiApi } from "@src/api/assujetti.api";
import { useAssujetisAvancement } from "@src/assujettis/useAssujettiAvancement";
import {
  ParcoursConformiteStepComponentProps,
  useParcoursConformite,
} from "../../ParcoursConformite";
import { ConformiteImportAssujettis } from "./ConformiteImportAssujettis";
import { ConformiteAssujettisList } from "./ConformiteAssujettisList";
import { ParcoursConformiteTemplate } from "../../ParcoursConformiteTemplate";
import { ParcoursConformiteLoaderStep } from "../../ParcoursConformiteLoaderStep";

export function ConformiteAssujettisStep({
  siren,
  changeStep,
  currentStep,
}: ParcoursConformiteStepComponentProps) {
  const { entreprise } = useParcoursConformite();
  const { assujettisBySocietes, assujettis, removeEntries, setEntry } =
    useAssujetti();
  const { getAssujetisAvancement } = useAssujetisAvancement();
  const { selected: millesime } = useMillesime();
  const [assujettiPaneState, setAssujettiPaneState] = useAssujettiPane();
  const { openConfirmDialog } = useDialog();

  const assujettisOfEntreprise = useMemo(
    () => assujettisBySocietes[siren] ?? [],
    [assujettisBySocietes, siren]
  );

  const handleUpdatePane = useCallback(
    (id: string, pane: AssujettiPaneContentPage) => {
      if (!assujettis || !assujettis[id]) return;

      setAssujettiPaneState((prevState) => ({
        assujetti: assujettis[id],
        initialPage: pane,
        isOpen: true,
        paneSession:
          prevState.assujetti?.id === id && pane === prevState.initialPage
            ? prevState.paneSession
            : prevState.paneSession + 1,
        defaultSiren: siren,
      }));
    },
    [assujettis, setAssujettiPaneState, siren]
  );

  const handleOpenAddAssujettiPane = useCallback(
    () =>
      setAssujettiPaneState((prevState) => ({
        assujetti: null,
        initialPage: "information-personne",
        isOpen: true,
        paneSession:
          prevState.assujetti === null &&
          prevState.initialPage === "information-personne"
            ? prevState.paneSession
            : prevState.paneSession + 1,
        defaultSiren: siren,
      })),
    [siren, setAssujettiPaneState]
  );

  const handleDeleteMultipleAssujettis = useCallback(
    async (selectedRows: Assujetti[]) => {
      if (!millesime) return;
      const shouldContinue = await openConfirmDialog({
        title: `Êtes-vous sur(e) de vouloir supprimer ${
          selectedRows.length > 1
            ? "ces personnes assujetties"
            : "cette personne assujettie"
        } ?`,
        message: "Cette action est irréversible.",
        confirmText: "Confirmer",
        cancelText: "Annuler",
      });

      if (!shouldContinue) return;

      await GatewayAssujettiApi.deleteMultiple(
        selectedRows.map((r) => r.id),
        millesime
      );
      removeEntries(
        selectedRows.map((r) => r.id),
        millesime
      );
    },
    [millesime, openConfirmDialog, removeEntries]
  );

  const isConforme = useMemo(
    () =>
      assujettisOfEntreprise.every(
        (assujetti) => getAssujetisAvancement(assujetti) === 100
      ),
    [assujettisOfEntreprise, getAssujetisAvancement]
  );

  if (!millesime || !entreprise)
    return (
      <ParcoursConformiteLoaderStep
        currentStep={currentStep}
        changeStep={changeStep}
        title="Personnes assujetties"
        size="large"
      />
    );
  return (
    <>
      <ParcoursConformiteTemplate
        currentStep={currentStep}
        changeStep={changeStep}
        isConforme={isConforme}
        title={
          <TitleWithNumber
            title="Personnes assujetties"
            number={
              assujettisOfEntreprise.length > 0
                ? assujettisOfEntreprise.length
                : undefined
            }
          />
        }
        size="large"
      >
        {assujettisOfEntreprise.length === 0 && (
          <ConformiteImportAssujettis
            siren={siren}
            onOpenAddAssujetti={handleOpenAddAssujettiPane}
          />
        )}
        {assujettisOfEntreprise.length > 0 && (
          <ConformiteAssujettisList
            onUpdatePane={handleUpdatePane}
            onDeleteMultipleAssujettis={handleDeleteMultipleAssujettis}
            onOpenAddAssujettiPane={handleOpenAddAssujettiPane}
            siren={siren}
            assujettisWithAvancement={assujettisOfEntreprise}
          />
        )}
      </ParcoursConformiteTemplate>

      {millesime && (
        <SidePane
          isOpen={assujettiPaneState.isOpen}
          setIsOpen={(nextIsOpen) =>
            setAssujettiPaneState((prevSate) => ({
              ...prevSate,
              isOpen: nextIsOpen,
            }))
          }
        >
          {(closePane) => (
            <AssujettiPaneContent
              key={assujettiPaneState.paneSession}
              millesime={millesime}
              setEntry={(assujetti) => {
                setEntry(assujetti);
                setAssujettiPaneState((prevState) => ({
                  ...prevState,
                  assujetti,
                }));
              }}
              closePane={closePane}
              assujetti={assujettiPaneState.assujetti}
              initialPage={assujettiPaneState.initialPage}
              defaultSiren={assujettiPaneState.defaultSiren}
            />
          )}
        </SidePane>
      )}
    </>
  );
}
