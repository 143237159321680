import { ConformiteAssujettiList } from "./ConformiteAssujettiList";

export function ConformiteAssujettiListFormationContinueIobsp() {
  return (
    <ConformiteAssujettiList
      title="Formation Continue IOBSP"
      avancementType="formationContinueIobsp"
      backLink="/conformite/formation-continue/iobsp"
    />
  );
}
