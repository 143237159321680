const dateFormatter = new Intl.DateTimeFormat("fr-FR");

export const formatDate = (date: Date) => {
  return dateFormatter.format(date);
};

export function nullableDateStringToDate(
  dateString: string | null | undefined
): Date | null {
  return dateString ? new Date(dateString) : null;
}

export function getDateWithoutTime(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function wasLessThanOneYearAgo(value: Date) {
  const needVerifiedAt = getDateWithoutTime(value);
  const today = getDateWithoutTime(new Date());
  needVerifiedAt.setFullYear(needVerifiedAt.getFullYear() + 1);
  return needVerifiedAt.getTime() >= today.getTime();
}

export function isBeforeToday(value: Date) {
  const today = getDateWithoutTime(new Date());
  const dateWithoutTime = getDateWithoutTime(value);
  return dateWithoutTime.getTime() < today.getTime();
}
