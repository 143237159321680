import Fuse from "fuse.js";
import { UseFormReturn } from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import { GatewayDeclarationActivite } from "@conformite/gateway";
import { useCallback, useMemo } from "react";
import {
  AutoCompleteWithList,
  ListComponentProps,
} from "@src/components/AutocompleteWithList/AutocompleteWithList";
import { TagList } from "@src/components/AutocompleteWithList/TagList";
import { invertMap } from "@src/helper/object.helper";

export const activiteIobspToLabel: Record<
  GatewayDeclarationActivite.ActiviteIobsp,
  string
> = {
  CREDIT_IMMOBILIER: "Crédit immobilier",
  CREDIT_PROFESSIONNEL: "Crédit professionnel",
  SERVICES_DE_PAIEMENT: "Services de paiement",
  REGROUPEMENTS_DE_CREDIT: "Regroupements de crédit",
  CREDIT_A_LA_CONSOMMATION: "Crédit à la consommation",
  PRET_VIAGER_HYPOTHECAIRE: "Prêt viager hypothécaire",
  AUTRES: "Autres",
};
const labelToActivite: Record<
  string,
  GatewayDeclarationActivite.ActiviteIobsp
> = invertMap(activiteIobspToLabel);
export type ActivitesData = {
  activite: string;
  activites: { value: string }[];
};

type ActivitesIobspProps = {
  form: UseFormReturn<ActivitesData>;
  activitePrincipale: GatewayDeclarationActivite.ActiviteIobsp | null;
};
const activites = Object.keys(labelToActivite);
export function ActivitesIobsp({
  form,
  activitePrincipale,
}: ActivitesIobspProps) {
  const [value, valuesSelected] = form.watch(["activite", "activites"]);
  const activitesWithoutSelected = useMemo(
    () =>
      activites.filter(
        (activite) =>
          valuesSelected.every((v) => v.value !== labelToActivite[activite]) &&
          labelToActivite[activite] !== activitePrincipale
      ),
    [activitePrincipale, valuesSelected]
  );
  const fuse = new Fuse(activitesWithoutSelected, {
    threshold: 0.4,
  });
  const ListComponent = useCallback(
    ({ fields, remove }: ListComponentProps) => (
      <TagList
        remove={remove}
        fields={fields.map((field) => ({
          id: field.id,
          value:
            activiteIobspToLabel[
              field.value as GatewayDeclarationActivite.ActiviteIobsp
            ],
        }))}
      />
    ),
    []
  );
  return (
    <AutoCompleteWithList<"activite", "activites">
      form={form}
      label="Sélectionnez les activités à ajouter"
      placeholder="Selectionner"
      options={(value
        ? fuse.search(value, { limit: 20 }).map((r) => r.item)
        : activitesWithoutSelected
      ).map((item) => ({
        label: item,
        value: labelToActivite[item],
      }))}
      nameSelected="activites"
      nameSearch="activite"
      valueToAppend={(item) => ({ value: item })}
      ListComponent={ListComponent}
    />
  );
}
