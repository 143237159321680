import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as InfoCircle } from "@src/assets/icons/info-circle-fill.svg";
import { ReactComponent as Loader } from "@src/assets/loader.svg";
import style from "./ControleQuinquennalItemActions.module.scss";

type ControleQuinquennalItemActionsProps = {
  onValidate?: () => void;
  isLoading: boolean;
  isDisabled: boolean;
};

export function ControleQuinquennalItemActions({
  onValidate,
  isLoading,
  isDisabled,
}: ControleQuinquennalItemActionsProps) {
  return (
    <div className={style.actions}>
      <div>
        {isDisabled && (
          <div className={style.desc}>
            <InfoCircle />
            <p>
              Vous devez importer toutes les pièces demandées et cocher les
              boutons « Complet » pour valider votre dépôt.
            </p>
          </div>
        )}
      </div>
      <Button
        onClick={onValidate}
        disabled={isLoading || isDisabled}
        icon={isLoading ? <Loader /> : undefined}
        className={style.actionDepot}
      >
        Valider le dépôt
      </Button>
    </div>
  );
}
