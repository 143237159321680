import { GatewayCapacitePro } from "@conformite/gateway";

export const labelsByStatusProfessionel: Record<
  GatewayCapacitePro.StatusProfessionel,
  string
> = {
  CADRE: "Cadre",
  NON_CADRE: "Hors cadre",
  TNS: "TNS",
};

export const labelsByGradeDiplome: Record<
  GatewayCapacitePro.GradeDiplome,
  string
> = {
  NIVEAU_1: "Niveau 1 / Maîtrise des savoirs de base",
  NIVEAU_2:
    "Niveau 2 / Aptitude à effecter des activités simples et à résoudre des problèmes courants",
  NIVEAU_3: "Niveau 3 / CAP",
  NIVEAU_4: "Niveau 4 / BAC",
  NIVEAU_5: "Niveau 5 / BAC + 2",
  NIVEAU_6: "Niveau 6 / LICENCE",
  NIVEAU_7: "Niveau 7 / MASTER | MBA | Executive MBA",
  NIVEAU_8: "Niveau 8 / DOCTORAT | DBA | PhD ",
};
