import {
  GatewayAvancement,
  GatewayConformiteRcPro,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { userSession } from "@src/session/UserSession";

export class GatewayRcProApi {
  static async getRcPro(
    siren: string,
    millesime: number,
    categorie: GatewayInfoSocieteOrias.Categorie,
    isAdmin = false
  ): Promise<GatewayConformiteRcPro.ConformiteRcProProperty | null> {
    const response =
      await userSession.axiosInstance.get<GatewayConformiteRcPro.ConformiteRcProProperty | null>(
        this.getUrl(siren, categorie, millesime) + (isAdmin ? "/admin" : "")
      );
    return response.data;
  }

  static async saveRcPro(
    siren: string,
    millesime: number,
    categorie: GatewayInfoSocieteOrias.Categorie,
    rcPro: GatewayConformiteRcPro.ConformiteRcProProperty,
    files: File[]
  ): Promise<GatewayAvancement.AvancementSociete> {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("documents", file);
    });
    Object.entries(rcPro).forEach(([key, value]) => {
      formData.append(
        key,
        key === "files" ? JSON.stringify(value) : String(value)
      );
    });
    const response =
      await userSession.axiosInstance.post<GatewayAvancement.AvancementSociete>(
        this.getUrl(siren, categorie, millesime),
        formData
      );
    return response.data;
  }

  private static getUrl(
    siren: string,
    categorie: GatewayInfoSocieteOrias.Categorie,
    millesime: number
  ) {
    return `/${
      GatewayConformiteRcPro.rcProUrl
    }/${siren}/${millesime}/${categorie.toLowerCase()}`;
  }
}
