import { useNavigate } from "react-router-dom";
import { useEntrepriseAvancement } from "@src/societes/useEntrepriseAvancement";
import { ConformiteSocieteList } from "./ConformiteSocieteList";

export function ConformiteSocieteListHonorabilite() {
  const navigate = useNavigate();
  const { getEntrepriseAvancementForAssujettiAxe } = useEntrepriseAvancement();

  return (
    <ConformiteSocieteList
      getFormAvancement={(entreprise) =>
        getEntrepriseAvancementForAssujettiAxe(entreprise, "honorabilite")
      }
      title="Honorabilité"
      onSocieteRedirect={(entreprise) => {
        navigate(`${entreprise.siren}?fromAxe`);
      }}
    />
  );
}
