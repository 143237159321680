import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Form,
  InputPassword,
  InputText,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  Button,
  FormAction,
  FormBloc,
  FormField,
  FormGrid,
  FormNotice,
  FormRow,
  FormTitle,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { Navigate, useNavigate, useOutletContext } from "react-router-dom";
import { GatewayAuthApi } from "@src/api/auth.api";
import { PasswordCriterias } from "@src/components/PasswordCriterias/PasswordCriterias";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { Link } from "@src/components/Link/Link";
import { ReactComponent as XIcon } from "@src/assets/icons/x-lg.svg";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { ForgotPasswordOutletContext } from "./ForgotPassword";
import style from "./ForgotPasswordReset.module.scss";

const schema = zod.object({
  code: zod.string().nonempty(),
  password: zod
    .object({
      password: zod
        .string()
        .nonempty()
        .regex(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
          "Votre mot de passe n'est pas assez sécurisé, vous devez avoir au moins 8 caractères dont 1 majuscule, 1 minuscule, 1 chiffre et un caractère spécial"
        ),
      confirm: zod.string().nonempty(),
    })
    .refine((data) => data.password === data.confirm, {
      message:
        "La confirmation de votre mot de passe ne correspond pas à la valeur du champ Mot de passe",
      path: ["confirm"],
    }),
});

export type ForgotPasswordResetFormValue = zod.infer<typeof schema>;

export function ForgotPasswordReset() {
  const navigate = useNavigate();
  const { data } = useOutletContext<ForgotPasswordOutletContext>();
  const formConfig = useFormConfig<typeof schema, ForgotPasswordResetFormValue>(
    {
      schema,
    }
  );
  const passwordValue = formConfig.watch("password.password");

  const handleSubmit = async (
    submittedData: ForgotPasswordResetFormValue
  ): Promise<void> => {
    try {
      await GatewayAuthApi.confirmForgotPassword(
        data.email,
        submittedData.code,
        submittedData.password.password
      );
      navigate("/forgot-password/success");
    } catch (err) {
      handleGatewayError({
        onResponse: (error) => {
          displayErrorToast(error.response.data.message);
        },
        onUnhandled: () => {
          displayErrorToast("Une erreur inconnue est survenue");
        },
      })(err);
    }
  };

  if (!data.email) return <Navigate to="/login" />;

  return (
    <Form formConfig={formConfig} onSubmit={handleSubmit}>
      <FormGrid disableHorizontalGutter>
        <FormTitle>
          <h1>Mot de passe oublié</h1>
        </FormTitle>
        <FormRow>
          <FormField>
            <InfoCard type="info">
              Un mail vous a été envoyé à l&apos;adresse <b>{data.email}</b>
              <br />
              Il contient le code de confirmation à renseigner ci-dessous.
            </InfoCard>
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <FormNotice>
              Choisissez un nouveau mot de passe pour votre compte.
            </FormNotice>
          </FormField>
        </FormRow>

        <FormRow>
          <FormField>
            <InputText<ForgotPasswordResetFormValue>
              name="code"
              label="Code de confirmation"
              required
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <InputPassword<ForgotPasswordResetFormValue>
              name="password.password"
              label="Nouveau mot de passe"
              required
            />
          </FormField>
        </FormRow>
        <FormBloc>
          <PasswordCriterias password={passwordValue} />
        </FormBloc>
        <FormRow>
          <FormField>
            <InputPassword<ForgotPasswordResetFormValue>
              name="password.confirm"
              label="Confirmez votre mot de passe"
              required
            />
          </FormField>
        </FormRow>
        <FormAction className={style.actions}>
          <Link to="/login">
            <Button type="button" variant="text" icon={<XIcon />}>
              Annuler
            </Button>
          </Link>
          <Button type="submit">Enregistrer le mot de passe</Button>
        </FormAction>
      </FormGrid>
    </Form>
  );
}
