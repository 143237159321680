import { GatewayControleQuinquennal } from "@conformite/gateway";
import { userSession } from "@src/session/UserSession";

export class ControleQuinquennalApi {
  static async getControlesQuinquennaux(): Promise<
    GatewayControleQuinquennal.ControleQuinquennalWithCompletion[]
  > {
    const { data } = await userSession.axiosInstance.get<
      GatewayControleQuinquennal.ControleQuinquennalWithCompletion[]
    >(`/controle-quinquennal`);

    return data.map((controle) => ({
      ...controle,
      dateDepot: controle.dateDepot ? new Date(controle.dateDepot) : null,
    }));
  }

  static async getControleQuinquennal(
    idControleQuinquennal: string
  ): Promise<GatewayControleQuinquennal.ControleQuinquennalWithAxes> {
    const { data } =
      await userSession.axiosInstance.get<GatewayControleQuinquennal.ControleQuinquennalWithAxes>(
        `/controle-quinquennal/${idControleQuinquennal}`
      );

    return {
      ...data,
      dateDepot: data.dateDepot ? new Date(data.dateDepot) : null,
    };
  }

  static async getControleQuinquennalAxe(
    idControleQuinquennal: string,
    idAxe: string
  ): Promise<GatewayControleQuinquennal.ControleQuinquennalAxeWithFiles> {
    const { data } =
      await userSession.axiosInstance.get<GatewayControleQuinquennal.ControleQuinquennalAxeWithFiles>(
        `/controle-quinquennal/${idControleQuinquennal}/axes/${idAxe}`
      );

    return data;
  }

  static async saveControleQuinquennalAxe(
    idControleQuinquennal: string,
    idAxe: string,
    payload: GatewayControleQuinquennal.ControleQuinquennalAxeUpdate,
    files: File[]
  ): Promise<GatewayControleQuinquennal.ControleQuinquennalAxeWithFiles> {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("documents", file);
    });
    Object.entries(payload).forEach(([key, value]) => {
      formData.append(
        key,
        key === "idFiles" ? JSON.stringify(value) : String(value)
      );
    });
    const response =
      await userSession.axiosInstance.put<GatewayControleQuinquennal.ControleQuinquennalAxeWithFiles>(
        `/controle-quinquennal/${idControleQuinquennal}/axes/${idAxe}`,
        formData
      );
    return response.data;
  }

  static async validateDepot(idControleQuinquennal: string): Promise<void> {
    await userSession.axiosInstance.put(
      `/controle-quinquennal/${idControleQuinquennal}/validate`
    );
  }

  static async getControleQuinquennalAxeByEntrepriseId(
    entrepriseId: string,
    millesime: number
  ) {
    const { data } =
      await userSession.axiosInstance.get<GatewayControleQuinquennal.ControleQuinquennalWithAxes>(
        `/admin/controle-quinquennal/${entrepriseId}/${millesime}`
      );
    return data;
  }

  static async createRemediation(
    idControleQuinquennal: string,
    idAxe: string,
    payload: GatewayControleQuinquennal.ControleQuinquenalRemediationRequestType,
    files: File[]
  ) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("documents", file);
    });
    Object.entries(payload).forEach(([key, value]) => {
      formData.append(
        key,
        key === "idFiles" ? JSON.stringify(value) : String(value)
      );
    });
    const { data } =
      await userSession.axiosInstance.post<GatewayControleQuinquennal.ControleQuinquenalRemediationResponseType>(
        `/admin/controle-quinquennal/${idControleQuinquennal}/axes/${idAxe}/remediation`,
        formData
      );
    return data;
  }

  static async updateRemediation(
    idControleQuinquennal: string,
    idAxe: string,
    idRemediation: string,
    payload: GatewayControleQuinquennal.ControleQuinquenalRemediationRequestType,
    files: File[]
  ) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("documents", file);
    });
    Object.entries(payload).forEach(([key, value]) => {
      formData.append(
        key,
        key === "idFiles" ? JSON.stringify(value) : String(value)
      );
    });
    const { data } =
      await userSession.axiosInstance.put<GatewayControleQuinquennal.ControleQuinquenalRemediationResponseType>(
        `/admin/controle-quinquennal/${idControleQuinquennal}/axes/${idAxe}/remediation/${idRemediation}`,
        formData
      );
    return data;
  }

  static async getRemediationByAxeId(
    idControleQuinquennal: string,
    axeId: string
  ) {
    const { data } = await userSession.axiosInstance.get<
      GatewayControleQuinquennal.ControleQuinquennalRemediationWithFileResponseType[]
    >(
      `/controle-quinquennal/${idControleQuinquennal}/axes/${axeId}/remediation`
    );

    return data;
  }

  static async getRapport(idControleQuinquennal: string) {
    const { data } =
      await userSession.axiosInstance.get<GatewayControleQuinquennal.ControleQuinquennalRapport | null>(
        `/controle-quinquennal/${idControleQuinquennal}/rapport`
      );

    return data;
  }

  static async validateAdmin(
    controleQuinquennal: GatewayControleQuinquennal.ControleQuinquennal,
    siren: string,
    file?: File
  ) {
    const formData = new FormData();
    if (file) formData.append("document", file);
    return userSession.axiosInstance.put(
      `/admin/controle-quinquennal/${controleQuinquennal.id}/validate/${siren}`,
      formData
    );
  }

  static async deleteRemediation(remediationId: string) {
    return userSession.axiosInstance.delete(
      `/admin/controle-quinquennal/remediation/${remediationId}`
    );
  }
}
