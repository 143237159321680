import {
  FormGrid,
  FormTitle,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { createPortal } from "react-dom";
import { isString } from "lodash";
import { ReactNode } from "react";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { SUBMIT_BUTTON_PORTAL_TARGET_ID } from "@src/parcours/UIParcoursFullPage";
import styles from "./ParcoursFullPageTemplate.module.scss";

type ParcoursFullPageTemplateActionsProps = {
  children: ReactNode;
};

function ParcoursFullPageTemplateActions({
  children,
}: ParcoursFullPageTemplateActionsProps) {
  const actionContainer = document.getElementById(
    SUBMIT_BUTTON_PORTAL_TARGET_ID
  );
  return actionContainer ? createPortal(children, actionContainer) : null;
}

type ParcoursFullPageTemplateContentProps = {
  children?: ReactNode;
  actions?: ReactNode;
  title?: ReactNode;
};
function ParcoursFullPageTemplateContent({
  children,
  actions,
  title,
}: ParcoursFullPageTemplateContentProps) {
  return (
    <>
      <FormGrid disableHorizontalGutter>
        {title && (
          <FormTitle>
            {isString(title) && <h1>{title}</h1>}
            {!isString(title) && title}
          </FormTitle>
        )}
        {children}
      </FormGrid>
      <ParcoursFullPageTemplateActions>
        {actions}
      </ParcoursFullPageTemplateActions>
    </>
  );
}

export type ParcoursFullPageTemplateProps = {
  children?: ReactNode;
  actions?: ReactNode;
  title?: ReactNode;
  size?: "medium" | "large";
};
export function ParcoursFullPageTemplate({
  children,
  actions,
  title,
  size = "medium",
}: ParcoursFullPageTemplateProps) {
  return (
    <MaxWidthContent size={size}>
      <div className={styles.content}>
        <ParcoursFullPageTemplateContent title={title} actions={actions}>
          {children}
        </ParcoursFullPageTemplateContent>
      </div>
    </MaxWidthContent>
  );
}
