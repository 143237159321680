import { useNavigate, useParams } from "react-router-dom";
import {
  GatewayAvancement,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { ConformiteSocieteList } from "./ConformiteSocieteList";

export function ConformiteSocieteListRcPro() {
  const { categorie } = useParams<{ categorie: string }>();
  const navigate = useNavigate();
  if (!categorie) return null;
  const avancementType = `rcPro${categorie
    .charAt(0)
    .toUpperCase()}${categorie.slice(
    1
  )}` as keyof GatewayAvancement.AvancementSociete;

  return (
    <ConformiteSocieteList
      getFormAvancement={(entreprise) =>
        entreprise.avancement[avancementType] as number
      }
      defaultFilters={
        categorie
          ? {
              categories: [
                categorie.toUpperCase() as GatewayInfoSocieteOrias.Categorie,
              ],
            }
          : undefined
      }
      title={`RC Pro ${categorie.toUpperCase()}`}
      onSocieteRedirect={(entreprise) => {
        navigate(`${entreprise.siren}?fromAxe`);
      }}
    />
  );
}
