import { RichTextField } from "@prismicio/client";
import {
  UseCmsDocumentListProps,
  useCmsDocumentList,
} from "../useCmsDocumentList";

export type PrismicLeSaviezVous = {
  title: RichTextField;
  description: RichTextField;
};
export type UseLeSaviezVousProps = {
  pageSize: number;
  page: number;
};
export const useLeSaviezVous = ({
  pageSize,
  page,
}: Omit<UseCmsDocumentListProps, "documentType">) => {
  return useCmsDocumentList<PrismicLeSaviezVous>({
    documentType: "le_saviez_vous",
    pageSize,
    page,
  });
};
