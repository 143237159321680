import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import { ParcoursAction } from "@src/parcours/NavigationButtons/ParcoursAction";
import { ParcoursRenouvellementStepComponentProps } from "@src/renouvellement/ParcoursRenouvellement.definition";
import {
  getCreateEntrepriseParamsRenouvellement,
  storeEntrepriseCreationRequest,
} from "@src/payment/utils/createEntreprises";
import { useMe } from "@src/store/store.me";
import { GatewayEntreprise } from "@conformite/gateway";
import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { useNavigate } from "react-router-dom";
import { useEntreprises } from "@src/store/store.entreprise";
import style from "./RenouvellementIntroductionPage.module.scss";

export function RenouvellementOffertIntroductionPage({
  data,
  addData,
}: ParcoursRenouvellementStepComponentProps) {
  const { me } = useMe();
  const navigate = useNavigate();
  const { entreprisesBySiren } = useEntreprises();

  if (!me || !entreprisesBySiren) return <div />;
  return (
    <ParcoursFullPageTemplate
      actions={
        <ParcoursAction
          onClick={() => {
            const newData = {
              ...data,
              entreprisesAControler: Object.fromEntries(
                Object.entries(data.entreprisesAControler).map(
                  ([key, entrepriseAController]) => [
                    key,
                    {
                      ...entrepriseAController,
                      chiffreAffaire:
                        entreprisesBySiren[entrepriseAController.siren]
                          .chiffreAffaire,
                    },
                  ]
                )
              ),
            };
            addData(newData);
            if (!me.entreprisePrincipale.renouvellementOffert) {
              console.error(
                "Le renouvellement doit être offert pour continuer sur cette page"
              );
              displayErrorToast("Une erreur inconnue est survenue");
              return;
            }
            const createEntrepriseParams =
              getCreateEntrepriseParamsRenouvellement(
                null,
                newData,
                me.entreprisePrincipale.renouvellementOffert
              );
            storeEntrepriseCreationRequest(
              data.typeAdhesion as GatewayEntreprise.TypeAdhesionActive,
              createEntrepriseParams
            );
            navigate("/renouvellement/maj-entreprises");
          }}
        >
          Suivant
        </ParcoursAction>
      }
      title={
        <h1>
          Vous venez d&apos;adhérer à Endya et vous souhaitez renouveler votre
          adhésion pour l&apos;année{" "}
          {me.entreprisePrincipale.millesimeActuel + 1}. <br />
          <span className={style.textAccent}>
            Endya vous offre ce renouvellement.
          </span>
        </h1>
      }
    />
  );
}
