import { ConformiteRcProForm } from "@src/conformite/rcPro/edit/ConformiteRcProForm";
import { useConformiteRcProEdit } from "@src/conformite/rcPro/edit/useConformiteRcProEdit";
import { useMillesime } from "@src/store/store.millesime";
import {
  GatewayConformiteRcPro,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { useCallback, useEffect, useState } from "react";
import { GatewayRcProApi } from "@src/api/conformite/rcPro.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { categorieToAvancementKey } from "@src/societes/societe.helper";
import { useEntreprises } from "@src/store/store.entreprise";
import {
  ParcoursConformiteStepComponentProps,
  useParcoursConformite,
} from "../../ParcoursConformite";
import { ParcoursConformiteTemplate } from "../../ParcoursConformiteTemplate";
import { ParcoursConformiteLoaderStep } from "../../ParcoursConformiteLoaderStep";

type FormContainerProps = {
  siren: string;
  millesime: number;
  categorie: GatewayInfoSocieteOrias.Categorie;
  rcPro: GatewayConformiteRcPro.ConformiteRcProProperty | null;
};

function FormContainer({
  siren,
  millesime,
  categorie,
  rcPro,
  changeStep,
  currentStep,
}: FormContainerProps & ParcoursConformiteStepComponentProps) {
  const { entreprise } = useParcoursConformite();
  const { updateAvancement } = useEntreprises();
  const {
    formConfig,
    hasRcpIndividuelleSouscritSetToNo,
    onSubmit,
    properties,
  } = useConformiteRcProEdit(
    siren,
    millesime,
    categorie,
    rcPro,
    (_, avancement) => {
      if (avancement) updateAvancement(avancement);
    }
  );

  const handleSubmit = useCallback(async () => {
    if (formConfig.formState.isDirty) await onSubmit(formConfig.getValues());
  }, [onSubmit, formConfig]);

  return (
    <ParcoursConformiteTemplate
      currentStep={currentStep}
      changeStep={changeStep}
      key={categorie}
      isConforme={
        entreprise?.avancement[categorieToAvancementKey[categorie]] === 100
      }
      isDirty={formConfig.formState.isDirty}
      title={`RC pro ${categorie}`}
      size="large"
      onSubmit={handleSubmit}
    >
      <ConformiteRcProForm
        formConfig={formConfig}
        hasRcpIndividuelleSouscritSetToNo={hasRcpIndividuelleSouscritSetToNo}
        properties={properties}
      />
    </ParcoursConformiteTemplate>
  );
}

export function ConformiteRcProStep({
  siren,
  categorie,
  changeStep,
  currentStep,
  ...props
}: ParcoursConformiteStepComponentProps & {
  categorie: GatewayInfoSocieteOrias.Categorie;
}) {
  const { selected: millesime } = useMillesime();
  const [rcPro, setRcPro] =
    useState<GatewayConformiteRcPro.ConformiteRcProProperty | null>();
  useEffect(() => {
    if (!millesime) return;
    GatewayRcProApi.getRcPro(siren, millesime, categorie)
      .then((data) => {
        setRcPro(data);
      })
      .catch((error) => {
        handleGatewayError({
          onUnhandled: () => {
            displayErrorToast(
              "Une erreur est survenue lors de la récupération du formulaire"
            );
          },
        })(error);
      });
  }, [siren, millesime, setRcPro, categorie]);

  if (!millesime || rcPro === undefined)
    return (
      <ParcoursConformiteLoaderStep
        currentStep={currentStep}
        changeStep={changeStep}
        title={`RC pro ${categorie}`}
        size="large"
      />
    );

  return (
    <FormContainer
      {...props}
      currentStep={currentStep}
      changeStep={changeStep}
      siren={siren}
      categorie={categorie}
      millesime={millesime}
      rcPro={rcPro}
    />
  );
}
