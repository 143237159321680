import {
  Button,
  FormField,
  FormGrid,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import { useCallback } from "react";
import style from "./ConformiteIntroductionStep.module.scss";
import {
  ParcoursConformiteStepComponentProps,
  ParcoursConformiteStepEnum,
} from "../../ParcoursConformite";

export function ConformiteIntroductionStep({
  changeStep,
}: ParcoursConformiteStepComponentProps) {
  const handleNextStep = useCallback(() => {
    changeStep(ParcoursConformiteStepEnum.IMPORT_ASSUJETTIS);
  }, [changeStep]);

  return (
    <ParcoursFullPageTemplate
      title={
        <h1>
          Vous allez accéder au{" "}
          <span className={style.highlightTitle}>formulaire de conformité</span>
        </h1>
      }
    >
      <FormGrid>
        <FormRow>
          <FormField>
            <InfoCard type="neutral" className={style.infoCard}>
              Vous pouvez à tout moment sauvegarder votre saisie et y revenir
              plus tard.
            </InfoCard>
          </FormField>
        </FormRow>

        <FormRow>
          <FormField>
            <InfoCard type="neutral" className={style.infoCard}>
              Si vous avez déjà saisie des informations, elles apparaîtront ici
              pour que vous puissiez les vérifier.
            </InfoCard>
          </FormField>
        </FormRow>

        <FormRow>
          <FormField>
            <Button onClick={handleNextStep}>Renseigner ma conformité</Button>
          </FormField>
        </FormRow>
      </FormGrid>
    </ParcoursFullPageTemplate>
  );
}
