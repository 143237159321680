import { FormApiConformiteCapaciteProfessionnelle } from 'form-api';

export const capaciteProfessionnelleUrl = 'capacite-professionnelle';

export const CategorieIAS =
  FormApiConformiteCapaciteProfessionnelle.CategorieIAS;
export type CategorieIAS =
  FormApiConformiteCapaciteProfessionnelle.CategorieIAS;
export const CategorieIOBSP =
  FormApiConformiteCapaciteProfessionnelle.CategorieIOBSP;
export type CategorieIOBSP =
  FormApiConformiteCapaciteProfessionnelle.CategorieIOBSP;

export const GradeDiplome =
  FormApiConformiteCapaciteProfessionnelle.GradeDiplome;
export type GradeDiplome =
  FormApiConformiteCapaciteProfessionnelle.GradeDiplome;

export const StatusProfessionel =
  FormApiConformiteCapaciteProfessionnelle.StatusProfessionel;
export type StatusProfessionel =
  FormApiConformiteCapaciteProfessionnelle.StatusProfessionel;

export const CapaciteProNiveauIAS =
  FormApiConformiteCapaciteProfessionnelle.CapaciteProNiveauIAS;
export type CapaciteProNiveauIAS =
  FormApiConformiteCapaciteProfessionnelle.CapaciteProNiveauIAS;

export const CapaciteProNiveauIOBSP =
  FormApiConformiteCapaciteProfessionnelle.CapaciteProNiveauIOBSP;
export type CapaciteProNiveauIOBSP =
  FormApiConformiteCapaciteProfessionnelle.CapaciteProNiveauIOBSP;

export const TypeJustificatifIAS =
  FormApiConformiteCapaciteProfessionnelle.TypeJustificatifIAS;
export type TypeJustificatifIAS =
  FormApiConformiteCapaciteProfessionnelle.TypeJustificatifIAS;

export const TypeJustificatifIOBSP =
  FormApiConformiteCapaciteProfessionnelle.TypeJustificatifIOBSP;
export type TypeJustificatifIOBSP =
  FormApiConformiteCapaciteProfessionnelle.TypeJustificatifIOBSP;

export type CapaciteProfessionnelleIAS =
  FormApiConformiteCapaciteProfessionnelle.CapaciteProfessionnelleIAS;
export type CapaciteProfessionnelleIOBSP =
  FormApiConformiteCapaciteProfessionnelle.CapaciteProfessionnelleIOBSP;
