import {
  Emphasis,
  FormField,
  FormGrid,
  FormNotice,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ParcoursAction } from "@src/parcours/NavigationButtons/ParcoursAction";
import {
  EntrepriseInformationsPersonnellesForm,
  EntrepriseInformationsPersonnellesFormValues,
} from "@src/societes/form/EntrepriseInformationsPersonnellesForm";
import { useCallback } from "react";
import style from "./UtilisateurForm.module.scss";
import {
  ParcoursAjoutUtilisateurStep,
  ParcoursAjoutUtilisateurStepComponentProps as ParcoursAjoutContactStepComponentProps,
} from "./ParcoursAjoutUtilisateur";

const FORM_ID = "ajout-utilisateur-pane";

export function AjoutUtilisateurInformationsStep({
  changeStep,
  addData,
  data,
  isEdit,
}: ParcoursAjoutContactStepComponentProps) {
  const handleSubmit = useCallback(
    (values: EntrepriseInformationsPersonnellesFormValues) => {
      addData(values);
      changeStep(ParcoursAjoutUtilisateurStep.AUTORISATIONS);
    },
    [addData, changeStep]
  );

  return (
    <FormGrid>
      <FormRow>
        <FormField>
          <h2>Ajouter un utilisateur</h2>
        </FormField>
      </FormRow>
      <FormRow>
        <FormNotice>
          <Emphasis>*</Emphasis> Champs obligatoires
        </FormNotice>
      </FormRow>
      <FormRow>
        <FormField>
          <EntrepriseInformationsPersonnellesForm
            defaultValues={data}
            onSubmit={handleSubmit}
            formId={FORM_ID}
            withRole
            withEmail
            disabledEmail={isEdit}
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField className={style.action}>
          <ParcoursAction formId={FORM_ID} type="submit">
            Suivant
          </ParcoursAction>
        </FormField>
      </FormRow>
    </FormGrid>
  );
}
