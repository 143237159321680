export enum CapaciteProNiveauIAS {
  NIVEAU_1 = 'NIVEAU_1',
  NIVEAU_2 = 'NIVEAU_2',
  NIVEAU_3 = 'NIVEAU_3',
}

export enum CapaciteProNiveauIOBSP {
  NIVEAU_1 = 'NIVEAU_1',
  NIVEAU_2 = 'NIVEAU_2',
}

export enum StatusProfessionel {
  CADRE = 'CADRE',
  NON_CADRE = 'NON_CADRE',
  TNS = 'TNS',
}

export enum CategorieIAS {
  DIRIGEANT = 'DIRIGEANT',
  SALARIE_AVEC_RESPONSABLE_NIVEAU_1 = 'SALARIE_AVEC_RESPONSABLE_NIVEAU_1',
  SALARIE_SANS_RESPONSABLE_NIVEAU_1 = 'SALARIE_SANS_RESPONSABLE_NIVEAU_1',
  SALARIE_DEBOUT = 'SALARIE_DEBOUT',
  SALARIE_ANIMER_RESEAU_PRODUCTION = 'SALARIE_ANIMER_RESEAU_PRODUCTION',
  SALARIE_RESPONSABLE_RESEAU_PRODUCTION = 'SALARIE_RESPONSABLE_RESEAU_PRODUCTION',
}

export enum CategorieIOBSP {
  DIRIGEANT_COBSP = 'DIRIGEANT_COBSP',
  SALARIE_COBSP = 'SALARIE_COBSP',
  DIRIGEANT_MIOBSP_PRINCIPAL = 'DIRIGEANT_MIOBSP_PRINCIPAL',
  SALARIE_MIOBSP_PRINCIPAL = 'SALARIE_MIOBSP_PRINCIPAL',
  DIRIGEANT_MIOBSP_ACCESSOIRE = 'DIRIGEANT_MIOBSP_ACCESSOIRE',
  SALARIE_MIOBSP_ACCESSOIRE = 'SALARIE_MIOBSP_ACCESSOIRE',
}

export enum GradeDiplome {
  NIVEAU_1 = 'NIVEAU_1',
  NIVEAU_2 = 'NIVEAU_2',
  NIVEAU_3 = 'NIVEAU_3',
  NIVEAU_4 = 'NIVEAU_4',
  NIVEAU_5 = 'NIVEAU_5',
  NIVEAU_6 = 'NIVEAU_6',
  NIVEAU_7 = 'NIVEAU_7',
  NIVEAU_8 = 'NIVEAU_8',
}

export enum TypeJustificatifIAS {
  GRADE_MASTER = 'GRADE_MASTER',
  ECOLE_COMMERCE = 'ECOLE_COMMERCE',
  DIPLOME_ETRANGER_MASTER = 'DIPLOME_ETRANGER_MASTER',
  BTS_313 = 'BTS_313',
  LICENCE_313 = 'LICENCE_313',
  CQP_313 = 'CQP_313',
  STAGE_PRO_NIVEAU_1 = 'STAGE_PRO_NIVEAU_1',
  STAGE_PRO_NIVEAU_2 = 'STAGE_PRO_NIVEAU_2',
  FORMATION_NIVEAU_3 = 'FORMATION_NIVEAU_3',
  EXP_PRO_1_AN_CADRE = 'EXP_PRO_1_AN_CADRE',
  EXP_PRO_2_ANS_NON_CADRE = 'EXP_PRO_2_ANS_NON_CADRE',
  EXP_PRO_2_ANS_CADRE = 'EXP_PRO_2_ANS_CADRE',
  EXP_PRO_4_ANS_NON_CADRE = 'EXP_PRO_4_ANS_NON_CADRE',
  EXP_PRO_6_MOIS = 'EXP_PRO_6_MOIS',
}

export enum TypeJustificatifIOBSP {
  LICENCE = 'LICENCE',
  BTS = 'BTS',
  ECOLE_COMMERCE_NIVEAU_1 = 'ECOLE_COMMERCE_NIVEAU_1',
  DIPLOME_ETRANGER_NIVEAU_1 = 'DIPLOME_ETRANGER_NIVEAU_1',
  STAGE_PRO_NIVEAU_1_150H = 'STAGE_PRO_NIVEAU_1_150H',
  STAGE_PRO_NIVEAU_2_80H = 'STAGE_PRO_NIVEAU_2_80H',
  STAGE_PRO_100H_CREDIT_IMMOBILIER = 'STAGE_PRO_100H_CREDIT_IMMOBILIER',
  STAGE_PRO_74H_REGROUPEMENT_CREDIT = 'STAGE_PRO_74H_REGROUPEMENT_CREDIT',
  EXP_1_AN_NIVEAU_1__FORMATION_COMP = 'EXP_1_AN_NIVEAU_1__FORMATION_COMP',
  NIVEAU_2__EXP_1_AN_NIVEAU_2__FORMATION_COMP = 'NIVEAU_2__EXP_1_AN_NIVEAU_2__FORMATION_COMP',
  EXP_1_AN_NIVEAU_1__FORMATION_COMP_SALARIE = 'EXP_1_AN_NIVEAU_1__FORMATION_COMP_SALARIE',
  NIVEAU_2__EXP_1_AN_NIVEAU_2__FORMATION_COMP_SALARIE = 'NIVEAU_2__EXP_1_AN_NIVEAU_2__FORMATION_COMP_SALARIE',
  EXP_1_AN_NIVEAU_2__FORMATION_COMP = 'EXP_1_AN_NIVEAU_2__FORMATION_COMP',
  EXP_1_AN_NIVEAU_2__FORMATION_COMP_SALARIE = 'EXP_1_AN_NIVEAU_2__FORMATION_COMP_SALARIE',
  NIVEAU_3__EXP_1_AN_NIVEAU_3__FORMATION_COMP = 'NIVEAU_3__EXP_1_AN_NIVEAU_3__FORMATION_COMP',
  NIVEAU_3__EXP_1_AN_NIVEAU_3__FORMATION_COMP_SALARIE = 'NIVEAU_3__EXP_1_AN_NIVEAU_3__FORMATION_COMP_SALARIE',
  EXP_ETRANGER__STAGE_3_MOIS = 'EXP_ETRANGER__STAGE_3_MOIS',
  EXP_PRO_6_MOIS_SALARIE_NIVEAU_1 = 'EXP_PRO_6_MOIS_SALARIE_NIVEAU_1',
  EXP_PRO_6_MOIS_SALARIE_NIVEAU_2 = 'EXP_PRO_6_MOIS_SALARIE_NIVEAU_2',
}
