import {
  LocalStorageKeys,
  localStorageHelper,
} from "@src/helper/localStorage.helper";
import { UserSessionData } from "./UserSession";

export function storeSessionInLocalStorage(userSession: UserSessionData) {
  if (userSession.accessToken)
    localStorageHelper.setItem(
      LocalStorageKeys.ACCESS_TOKEN,
      userSession.accessToken
    );
  else localStorageHelper.removeItem(LocalStorageKeys.ACCESS_TOKEN);
  if (userSession.refreshToken)
    localStorageHelper.setItem(
      LocalStorageKeys.REFRESH_TOKEN,
      userSession.refreshToken
    );
  else localStorageHelper.removeItem(LocalStorageKeys.REFRESH_TOKEN);
  if (userSession.idToken)
    localStorageHelper.setItem(LocalStorageKeys.ID_TOKEN, userSession.idToken);
  else localStorageHelper.removeItem(LocalStorageKeys.ID_TOKEN);
}

export function retrieveSessionFromLocalStorage(): UserSessionData {
  const accessToken = localStorageHelper.getItem(LocalStorageKeys.ACCESS_TOKEN);
  const refreshToken = localStorageHelper.getItem(
    LocalStorageKeys.REFRESH_TOKEN
  );
  const idToken = localStorageHelper.getItem(LocalStorageKeys.ID_TOKEN);
  let session: UserSessionData = {
    isConnected: false,
    accessToken: null,
    refreshToken: null,
    idToken: null,
  };

  if (accessToken && refreshToken && idToken) {
    session = {
      accessToken,
      refreshToken,
      idToken,
      isConnected: true,
    };
  }
  return session;
}
