import { useCallback } from "react";
import { ParcoursAction } from "@src/parcours/NavigationButtons/ParcoursAction";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import {
  EntrepriseSousCategoriesActiviteForm,
  EntrepriseSousCategoriesActiviteFormValues,
} from "@src/societes/form/EntrepriseSousCategoriesActiviteForm";
import { GatewayInfoSocieteOrias } from "@conformite/gateway";
import {
  ID_FORM_RENOUVELLEMENT,
  ParcoursRenouvellementStepComponentProps,
  RenouvellementStepEnum,
} from "@src/renouvellement/ParcoursRenouvellement.definition";

export function RenouvellementSousCategoriesPage({
  data,
  setData,
  changeStep,
}: ParcoursRenouvellementStepComponentProps) {
  const entreprise =
    data.entreprisesAControler[data.resume.entreprisePrincipale.siren];
  const activitesEntreprises = [
    ...entreprise.categoriesActivites,
    ...entreprise.categoriesActivitesAccessoires,
  ];
  const needCOBSP =
    activitesEntreprises.includes(GatewayInfoSocieteOrias.Categorie.COBSP) ??
    false;
  const needMIOBSP =
    activitesEntreprises.includes(GatewayInfoSocieteOrias.Categorie.MIOBSP) ??
    false;

  const handleSubmit = useCallback(
    (values: EntrepriseSousCategoriesActiviteFormValues) => {
      setData({
        ...data,
        entreprisesAControler: {
          ...data.entreprisesAControler,
          [entreprise.siren]: {
            ...entreprise,
            sousCategorieCOBSP: values.sousCategoriesCOBSP,
            sousCategoriesIOBSP: values.sousCategoriesMIOBSP,
          },
        },
      });
      changeStep(RenouvellementStepEnum.ORGANISATIONS_PROFESSIONNELLES);
    },
    [setData, data, entreprise, changeStep]
  );

  return (
    <ParcoursFullPageTemplate
      title="Sélection des sous-catégories"
      actions={
        <ParcoursAction type="submit" formId={ID_FORM_RENOUVELLEMENT}>
          Suivant
        </ParcoursAction>
      }
    >
      <EntrepriseSousCategoriesActiviteForm
        formId={ID_FORM_RENOUVELLEMENT}
        defaultValues={{
          needCOBSP,
          needMIOBSP,
          sousCategoriesCOBSP: needCOBSP ? entreprise.sousCategorieCOBSP : [],
          sousCategoriesMIOBSP: needMIOBSP
            ? entreprise.sousCategoriesIOBSP
            : [],
        }}
        onSubmit={handleSubmit}
      />
    </ParcoursFullPageTemplate>
  );
}
