import { useCallback } from "react";
import { ParcoursAction } from "@src/parcours/NavigationButtons/ParcoursAction";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import {
  EntrepriseInformationsPersonnellesForm,
  EntrepriseInformationsPersonnellesFormValues,
} from "@src/societes/form/EntrepriseInformationsPersonnellesForm";
import {
  ID_FORM_RENOUVELLEMENT,
  ParcoursRenouvellementStepComponentProps,
  RenouvellementStepEnum,
} from "@src/renouvellement/ParcoursRenouvellement.definition";

export function RenouvellementInformationsPersonnellesPage({
  data,
  setData,
  changeStep,
}: ParcoursRenouvellementStepComponentProps) {
  const handleSubmit = useCallback(
    (values: EntrepriseInformationsPersonnellesFormValues) => {
      setData({
        ...data,
        resume: {
          ...data.resume,
          contact: values,
        },
      });
      changeStep(RenouvellementStepEnum.RESUME_ENTREPRISE);
    },
    [setData, data, changeStep]
  );
  return (
    <ParcoursFullPageTemplate
      title="Informations personnelles"
      actions={
        <ParcoursAction type="submit" formId={ID_FORM_RENOUVELLEMENT}>
          Enregistrer les modifications
        </ParcoursAction>
      }
    >
      <EntrepriseInformationsPersonnellesForm
        formId={ID_FORM_RENOUVELLEMENT}
        defaultValues={{
          civilite: data.resume.contact.civilite,
          fonction: data.resume.contact.fonction,
          nom: data.resume.contact.nom,
          prenom: data.resume.contact.prenom,
          telephone: data.resume.contact.telephone,
        }}
        onSubmit={handleSubmit}
      />
    </ParcoursFullPageTemplate>
  );
}
