import { ReactNode } from "react";
import classNames from "classnames";
import style from "./MaxWidthContent.module.scss";

export type MaxWidthContentProps = {
  children: ReactNode;
  size?: "medium" | "big" | "large";
  className?: string;
};
export function MaxWidthContent({
  children,
  size = "medium",
  className,
}: MaxWidthContentProps) {
  return (
    <div className={classNames(style.maxcontent, className)} data-size={size}>
      {children}
    </div>
  );
}
