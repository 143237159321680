import { useCallback } from "react";
import {
  ParcoursEntrepriseStepEnum,
  ParcoursEntrepriseStepComponentProps,
} from "@src/societes/parcours/ParcoursEntreprise";
import { ParcoursEntrepriseTemplate } from "@src/societes/parcours/ParcoursEntrepriseTemplate";
import {
  ID_FORM_ENTREPRISE,
  ParcoursEntrepriseActions,
} from "@src/societes/parcours/ParcoursEntrepriseActions";
import {
  EntrepriseIdentificationEtAdresseForm,
  EntreprisePrincipaleIdentificationEtAdresseFormValues,
} from "@src/societes/form/EntrepriseIdentificationEtAdresseForm";

export function EntrepriseIdentificationEtAdresseStep({
  data,
  addData,
  changeStep,
}: ParcoursEntrepriseStepComponentProps) {
  const handleSubmit = useCallback(
    (values: EntreprisePrincipaleIdentificationEtAdresseFormValues) => {
      addData(values);
      changeStep(ParcoursEntrepriseStepEnum.INFO_ACTIVITE);
    },
    [addData, changeStep]
  );

  return (
    <ParcoursEntrepriseTemplate
      title="Ajouter une société"
      actions={
        <ParcoursEntrepriseActions
          onPrevious={() => changeStep(ParcoursEntrepriseStepEnum.SIREN)}
        />
      }
    >
      <EntrepriseIdentificationEtAdresseForm
        isEntrepriseAController
        defaultValues={
          data as EntreprisePrincipaleIdentificationEtAdresseFormValues
        }
        formId={ID_FORM_ENTREPRISE}
        onSubmit={handleSubmit}
      />
    </ParcoursEntrepriseTemplate>
  );
}
