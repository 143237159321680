import {
  Form,
  InputButtonGroup,
  InputCheckbox,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Card,
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import {
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useMemo,
} from "react";
import { GatewayInfoSocieteOrias } from "@conformite/gateway";
import { ReactComponent as NestingAngle } from "@src/assets/icons/nesting-angle.svg";

import { UseFormReturn } from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import style from "./EntrepriseInfoActiviteForm.module.scss";

export const categorieActiviteLabel: Record<
  GatewayInfoSocieteOrias.Categorie,
  string
> = {
  [GatewayInfoSocieteOrias.Categorie.COA]:
    "Courtier d'assurance ou de réassurance (COA)",
  [GatewayInfoSocieteOrias.Categorie.COBSP]:
    "Courtier en opérations de banque et service de paiement (COBSP)",
  [GatewayInfoSocieteOrias.Categorie.MIA]:
    "Mandataire d'intermédiaire en assurance (MIA)",
  [GatewayInfoSocieteOrias.Categorie.MIOBSP]:
    "Mandataire d'intermédiaire en opérations bancaires et services de paiement (MIOBSP)",
};

function InputTitreAccessoire({
  name,
}: {
  name: Exclude<keyof EntrepriseInfoActiviteFormValues, "categories">;
}) {
  return (
    <div className={style.accessoireWrapper}>
      <NestingAngle className={style.accessoireJoinIcon} />
      <InputCheckbox<EntrepriseInfoActiviteFormValues>
        name={name}
        className={style.accessoireCheckbox}
        label="À titre accessoire"
      />
    </div>
  );
}

const schema = zod.object({
  numeroOrias: zod.string().optional(),
  statutOrias: zod.nativeEnum(GatewayInfoSocieteOrias.StatutSociete),
  categories: zod
    .array(zod.nativeEnum(GatewayInfoSocieteOrias.Categorie))
    .min(1, {
      message:
        "Vous devez choisir au moins une catégorie à laquelle vous inscrire",
    }),
  coaAccessoire: zod.boolean(),
  miaAccessoire: zod.boolean(),
  cobspAccessoire: zod.boolean(),
  miobspAccessoire: zod.boolean(),
});
export type EntrepriseInfoActiviteFormValues = zod.infer<typeof schema>;

const labelByStatutSociete: Record<
  GatewayInfoSocieteOrias.StatutSociete,
  string
> = {
  [GatewayInfoSocieteOrias.StatutSociete.INSCRIT]: "Inscrit",
  [GatewayInfoSocieteOrias.StatutSociete.JAMAIS_INSCRIT]: "Non inscrit",
  [GatewayInfoSocieteOrias.StatutSociete.SUPPRIME]: "Supprimé ",
};

type EntrepriseInfoActiviteWithDataProps = {
  onSubmit: (
    data: EntrepriseInfoActiviteFormValues & { numeroOrias?: string }
  ) => void;
  defaultValues: EntrepriseInfoActiviteFormValues;
  formId?: string;
};

export type EntrepriseInfoActiviteFormHandle = {
  getFormConfig: () => UseFormReturn<EntrepriseInfoActiviteFormValues>;
};

export const EntrepriseInfoActiviteForm = forwardRef<
  EntrepriseInfoActiviteFormHandle,
  EntrepriseInfoActiviteWithDataProps
>(({ defaultValues, onSubmit, formId }, ref) => {
  const formConfig = useFormConfig<
    typeof schema,
    EntrepriseInfoActiviteFormValues
  >({
    schema,
    defaultValues,
  });

  const categoriesWatched = formConfig.watch("categories");
  const numeroOrias = formConfig.watch("numeroOrias");
  const statutOrias = formConfig.watch("statutOrias");

  const categoriesOptions: {
    value: GatewayInfoSocieteOrias.Categorie;
    label: string;
    description?: ReactElement;
  }[] = useMemo(
    () => [
      {
        value: GatewayInfoSocieteOrias.Categorie.COA,
        label: categorieActiviteLabel[GatewayInfoSocieteOrias.Categorie.COA],
        description: categoriesWatched?.includes(
          GatewayInfoSocieteOrias.Categorie.COA
        ) ? (
          <InputTitreAccessoire name="coaAccessoire" />
        ) : undefined,
      },
      {
        value: GatewayInfoSocieteOrias.Categorie.MIA,
        label: categorieActiviteLabel[GatewayInfoSocieteOrias.Categorie.MIA],
        description: categoriesWatched?.includes(
          GatewayInfoSocieteOrias.Categorie.MIA
        ) ? (
          <InputTitreAccessoire name="miaAccessoire" />
        ) : undefined,
      },
      {
        value: GatewayInfoSocieteOrias.Categorie.COBSP,
        label: categorieActiviteLabel[GatewayInfoSocieteOrias.Categorie.COBSP],
        description: categoriesWatched?.includes(
          GatewayInfoSocieteOrias.Categorie.COBSP
        ) ? (
          <InputTitreAccessoire name="cobspAccessoire" />
        ) : undefined,
      },
      {
        value: GatewayInfoSocieteOrias.Categorie.MIOBSP,
        label: categorieActiviteLabel[GatewayInfoSocieteOrias.Categorie.MIOBSP],
        description: categoriesWatched?.includes(
          GatewayInfoSocieteOrias.Categorie.MIOBSP
        ) ? (
          <InputTitreAccessoire name="miobspAccessoire" />
        ) : undefined,
      },
    ],
    [categoriesWatched]
  );

  useEffect(() => {
    if (!categoriesWatched?.includes(GatewayInfoSocieteOrias.Categorie.COA)) {
      formConfig.setValue("coaAccessoire", false);
    }
    if (!categoriesWatched?.includes(GatewayInfoSocieteOrias.Categorie.MIA)) {
      formConfig.setValue("miaAccessoire", false);
    }
    if (!categoriesWatched?.includes(GatewayInfoSocieteOrias.Categorie.COBSP)) {
      formConfig.setValue("cobspAccessoire", false);
    }
    if (
      !categoriesWatched?.includes(GatewayInfoSocieteOrias.Categorie.MIOBSP)
    ) {
      formConfig.setValue("miobspAccessoire", false);
    }
  }, [categoriesWatched, formConfig]);

  useImperativeHandle(ref, () => ({
    getFormConfig() {
      return formConfig;
    },
  }));

  return (
    <Form formConfig={formConfig} onSubmit={onSubmit} id={formId}>
      <Card variant="accent" className={style.informationsOrias}>
        <div>
          <span>Numéro ORIAS : </span>
          <strong className={style.value}>{numeroOrias ?? "-"}</strong>
        </div>

        <div>
          <span>Statut ORIAS : </span>
          <strong className={style.value}>
            {labelByStatutSociete[statutOrias]}
          </strong>
        </div>
      </Card>

      <FormRow>
        <FormField>
          <InputButtonGroup<
            EntrepriseInfoActiviteFormValues,
            true,
            GatewayInfoSocieteOrias.Categorie
          >
            label={
              <b className={style.label}>
                Dans quelle(s) catégories(s) vous inscrivez-vous ?
              </b>
            }
            options={categoriesOptions}
            name="categories"
            variant="outline-pop"
            size="large"
            isMultiple
          />
        </FormField>
      </FormRow>
    </Form>
  );
});
