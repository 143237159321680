import { useState } from "react";
import { GatewayInfoSocietePappers } from "@conformite/gateway";
import { AdhesionDataEntreprisesItem } from "@src/adhesion/AdhesionData/adhesionDataEntreprises";
import {
  ParcoursEntrepriseStepComponentProps,
  ParcoursEntrepriseStepEnum,
} from "@src/societes/parcours/ParcoursEntreprise";
import { ParcoursEntrepriseTemplate } from "@src/societes/parcours/ParcoursEntrepriseTemplate";
import {
  ID_FORM_ENTREPRISE,
  ParcoursEntrepriseActions,
} from "@src/societes/parcours/ParcoursEntrepriseActions";
import { EntrepriseSirenForm } from "@src/societes/form/EntrepriseSirenForm";

const responseToData = (
  responsePappers: GatewayInfoSocietePappers.GetInfoSocietePappersResponse
): Partial<AdhesionDataEntreprisesItem> => {
  return {
    siren: responsePappers.siren,
    raisonSociale: responsePappers.raisonSociale,
    formeJuridique: responsePappers.formeJuridique,
    trancheEffectif: responsePappers.trancheEffectif,
    pays: responsePappers.adresse?.pays,
    codePostal: responsePappers.adresse?.codePostal,
    ville: responsePappers.adresse?.ville,
    typeVoie: responsePappers.adresse?.typeVoie,
    numeroVoie: responsePappers.adresse?.numeroVoie,
    libelleVoie: responsePappers.adresse?.libelleVoie,
    complementAdresse: responsePappers.adresse?.complementAdresse ?? undefined,
  };
};

export function EntrepriseSirenStep({
  addData,
  data,
  changeStep,
  isEdit,
  fromParcoursAdhesion,
}: ParcoursEntrepriseStepComponentProps) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div>
      <ParcoursEntrepriseTemplate
        title="Ajouter une entreprise"
        actions={<ParcoursEntrepriseActions isLoading={isLoading} />}
      >
        <EntrepriseSirenForm
          fromParcoursAdhesion={fromParcoursAdhesion}
          isEdit={isEdit}
          formId={ID_FORM_ENTREPRISE}
          executeAfterSubmit={(responsePappers) => {
            if (responsePappers) addData(responseToData(responsePappers));
            changeStep(ParcoursEntrepriseStepEnum.IDENTITE_ET_ADRESSE);
          }}
          defaultValues={{ siren: data.siren }}
          setIsLoading={setIsLoading}
        />
      </ParcoursEntrepriseTemplate>
    </div>
  );
}
