import { DialogContainer } from "./Dialog";
import { DialogContext, useDialogContextState } from "./DialogContext";

type DialogProviderProps = { children: React.ReactNode };
export function DialogProvider({ children }: DialogProviderProps) {
  const dialogContext = useDialogContextState();
  return (
    <DialogContext.Provider value={dialogContext}>
      {children}
      <DialogContainer />
    </DialogContext.Provider>
  );
}
