import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  InputButtonGroup,
  InputDate,
  InputSelect,
  InputText,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  FormField,
  FormGrid,
  FormRow,
  FormSubTitle,
  FormTitle,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayCapacitePro } from "@conformite/gateway";
import { useMemo } from "react";
import { IconStatus } from "@src/components/IconStatus/IconStatus";
import { GatewayCapaciteProApi } from "@src/api/conformite/capacitePro.api";
import { recordToSelectOptions } from "@src/adhesion/AdhesionData/adhesionData";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { useAssujetti } from "@src/store/store.assujetti";
import { SelectOption } from "@lya-protect/lya-protect-form-library/dist/UI/Input/UIInputSelect";
import { UseFormReturn } from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import { AssujettiConformiteData } from "../../../AssujettiParcoursConformite";
import styles from "./ConformiteFormCapaciteProIAS.module.scss";
import {
  labelsByGradeDiplome,
  labelsByStatusProfessionel,
} from "../formsCapacitePro.definition";
import {
  capaciteProIASSchema,
  getTypeJustificatifOptionsIAS,
  labelsByFormCapaciteProNiveauIAS,
  labelsByCategorieIAS,
} from "./formCapaciteProIAS.definition";

export type CapaciteProIASFormValues = zod.infer<typeof capaciteProIASSchema>;

function getDefaultValues(
  formData: GatewayCapacitePro.CapaciteProfessionnelleIAS | null
): CapaciteProIASFormValues {
  return {
    datePriseFonction: formData?.datePriseFonction ?? null,
    capacite: {
      conditionCapaciteProfessionnelle:
        formData?.conditionCapaciteProfessionnelle,
      niveau: formData?.niveau,
      statutProfessionel: formData?.statusProfessionel,
      categorie: formData?.categorie,
      gradeDiplome: formData?.gradeDiplome,
      nomDiplome: formData?.nomDiplome,
      typeJustificatif: formData?.typeJustificatif,
    },
  } as CapaciteProIASFormValues;
}

function formatFormValuesToApiPayload(
  values: CapaciteProIASFormValues
): GatewayCapacitePro.CapaciteProfessionnelleIAS {
  return {
    datePriseFonction: values.datePriseFonction ?? null,
    conditionCapaciteProfessionnelle:
      values.capacite.conditionCapaciteProfessionnelle ?? null,
    niveau: values.capacite.niveau ?? null,
    statusProfessionel: values.capacite.statutProfessionel ?? null,
    categorie: values.capacite.categorie ?? null,
    gradeDiplome: values.capacite.gradeDiplome ?? null,
    nomDiplome: values.capacite.nomDiplome ?? null,
    typeJustificatif: values.capacite.typeJustificatif ?? null,
  };
}

export const useCapaciteProIASForm = (
  formData: GatewayCapacitePro.CapaciteProfessionnelleIAS | null
) => {
  const formConfig = useFormConfig<
    typeof capaciteProIASSchema,
    CapaciteProIASFormValues
  >({
    schema: capaciteProIASSchema,
    defaultValues: getDefaultValues(formData),
  });

  const conditionCapaciteProfessionnelleValue = formConfig.watch(
    "capacite.conditionCapaciteProfessionnelle"
  );
  const categorieValue = formConfig.watch("capacite.categorie");
  const typeJustificatifOptions = useMemo(
    () => getTypeJustificatifOptionsIAS(categorieValue),
    [categorieValue]
  );
  return {
    formConfig,
    conditionCapaciteProfessionnelleValue,
    typeJustificatifOptions,
  };
};
export const useCapaciteProIAS = (
  data: AssujettiConformiteData,
  formData: GatewayCapacitePro.CapaciteProfessionnelleIAS | null,
  setFormData: (data: GatewayCapacitePro.CapaciteProfessionnelleIAS) => void
) => {
  const {
    formConfig,
    conditionCapaciteProfessionnelleValue,
    typeJustificatifOptions,
  } = useCapaciteProIASForm(formData);
  const { updateAvancement } = useAssujetti();
  const onSubmit = async (values: CapaciteProIASFormValues) => {
    const payload = formatFormValuesToApiPayload(values);
    const avancement = await GatewayCapaciteProApi.saveIAS(
      data.assujetti.id,
      data.assujetti.millesime,
      payload
    );
    updateAvancement(data.assujetti.millesime, data.assujetti.id, avancement);
    setFormData(payload);
  };
  return {
    formConfig,
    conditionCapaciteProfessionnelleValue,
    typeJustificatifOptions,
    onSubmit,
  };
};

type ConformiteFormCapaciteProIASContentProps = {
  hideTitle?: boolean;
  formConfig: UseFormReturn<CapaciteProIASFormValues>;
  data: AssujettiConformiteData;
  conditionCapaciteProfessionnelleValue: boolean;
  typeJustificatifOptions: SelectOption<GatewayCapacitePro.TypeJustificatifIAS>[];
};
export function ConformiteFormCapaciteProIASContent({
  hideTitle,
  formConfig,
  data,
  conditionCapaciteProfessionnelleValue,
  typeJustificatifOptions,
}: ConformiteFormCapaciteProIASContentProps) {
  return (
    <FormGrid disableHorizontalGutter>
      {!hideTitle && (
        <FormTitle className={styles.header}>
          <h2 className={styles.title}>
            <b>Capacité Professionnelle IAS</b> - {data.assujetti.surname}{" "}
            {data.assujetti.name}
          </h2>
          <IconStatus isValid />
        </FormTitle>
      )}
      <FormRow>
        <FormField>
          <InputDate<CapaciteProIASFormValues>
            label="Date de prise de fonction"
            name="datePriseFonction"
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <InputButtonGroup<CapaciteProIASFormValues, false, boolean>
            size="medium"
            label="La personne est-elle soumise à la condition de capacité professionnelle ?"
            name="capacite.conditionCapaciteProfessionnelle"
            variant="outline-pop"
            onChange={(value) => {
              if (value === false) {
                formConfig.setValue(
                  "capacite.conditionCapaciteProfessionnelle",
                  false,
                  {
                    shouldValidate: true,
                  }
                );
                (
                  [
                    "capacite.categorie",
                    "capacite.gradeDiplome",
                    "capacite.niveau",
                    "capacite.nomDiplome",
                    "capacite.statutProfessionel",
                    "capacite.typeJustificatif",
                  ] as const
                ).forEach((name) => {
                  formConfig.setValue(name, null, {
                    shouldValidate: true,
                  });
                });
              } else {
                formConfig.setValue(
                  "capacite.conditionCapaciteProfessionnelle",
                  true,
                  {
                    shouldValidate: true,
                  }
                );
              }
            }}
            options={[
              { label: "Oui", value: true },
              { label: "Non", value: false },
            ]}
          />
        </FormField>
      </FormRow>
      {conditionCapaciteProfessionnelleValue && (
        <>
          <FormRow>
            <FormField>
              <InputButtonGroup<
                CapaciteProIASFormValues,
                false,
                GatewayCapacitePro.CapaciteProNiveauIAS
              >
                size="medium"
                label="Niveau de capacité professionnelle"
                name="capacite.niveau"
                variant="outline-pop"
                options={recordToSelectOptions<GatewayCapacitePro.CapaciteProNiveauIAS>(
                  labelsByFormCapaciteProNiveauIAS
                )}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <InputButtonGroup<
                CapaciteProIASFormValues,
                false,
                GatewayCapacitePro.StatusProfessionel
              >
                size="medium"
                label="Statut professionnel"
                name="capacite.statutProfessionel"
                variant="outline-pop"
                options={recordToSelectOptions<GatewayCapacitePro.StatusProfessionel>(
                  labelsByStatusProfessionel
                )}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <InputSelect<
                CapaciteProIASFormValues,
                GatewayCapacitePro.CategorieIAS
              >
                label="Catégorie"
                name="capacite.categorie"
                onChange={(value) => {
                  formConfig.setValue("capacite.categorie", value, {
                    shouldValidate: true,
                  });
                  formConfig.setValue("capacite.typeJustificatif", null, {
                    shouldValidate: true,
                  });
                }}
                options={recordToSelectOptions<GatewayCapacitePro.CategorieIAS>(
                  labelsByCategorieIAS
                )}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <InputSelect<
                CapaciteProIASFormValues,
                GatewayCapacitePro.GradeDiplome
              >
                label="Grade de diplôme obtenu"
                name="capacite.gradeDiplome"
                options={recordToSelectOptions<GatewayCapacitePro.GradeDiplome>(
                  labelsByGradeDiplome
                )}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <InputText<CapaciteProIASFormValues>
                label="Nom de diplôme obtenu"
                name="capacite.nomDiplome"
              />
            </FormField>
          </FormRow>
          <FormSubTitle>
            <h2>Pièces justificatives</h2>
          </FormSubTitle>
          <FormRow>
            <FormField>
              <InputSelect<CapaciteProIASFormValues, string>
                label="Type de justificatif"
                name="capacite.typeJustificatif"
                options={typeJustificatifOptions}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <InfoCard type="info">
                Seuls les justificatifs listés ci-dessus sont recevables au vu
                du niveau de l&apos;expérience de la personne.
              </InfoCard>
            </FormField>
          </FormRow>
        </>
      )}
    </FormGrid>
  );
}
