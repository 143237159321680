import { GatewayContact, GatewayEntreprise } from "@conformite/gateway";
import "@lya-protect/lya-protect-form-library/dist/style.css";
import { ReactElement, useEffect, useMemo } from "react";
import {
  Navigate,
  Params,
  RouteObject,
  RouterProvider,
  createBrowserRouter,
  useLocation,
  useParams,
} from "react-router-dom";
import { ParcoursAdhesion } from "./adhesion/ParcoursAdhesion";
import { AdhesionCreationEntrepriseAfterPaiement } from "./adhesion/Steps/AdhesionCreationEntrepriseAfterPaiement/AdhesionCreationEntrepriseAfterPaiement";
import { AdhesionRemerciements } from "./adhesion/Steps/AdhesionRemerciements/AdhesionRemerciements";
import { AdminSwitchSession } from "./admin/AdminSwitchSession";
import "./app.scss";
import { Assujettis } from "./assujettis/Assujettis";
import { ActualiteItem } from "./cms/Actualite/ActualiteItem";
import { ActualiteListPage } from "./cms/Actualite/ActualiteListPage";
import { LeSaviezVousListPage } from "./cms/LeSaviezVous/LeSaviezVousListPage";
import { CmsPreview } from "./cms/Preview/Preview";
import { ServicePage } from "./cms/Service/ServicePage";
import { ConformiteAssujettiListCapaciteProfessionnelleIas } from "./conformite/assujettis/ConformiteAssujettiListCapaciteProfessionnelleIas";
import { ConformiteAssujettiListCapaciteProfessionnelleIobsp } from "./conformite/assujettis/ConformiteAssujettiListCapaciteProfessionnelleIobsp";
import { ConformiteAssujettiListFormationContinueIas } from "./conformite/assujettis/ConformiteAssujettiListFormationContinueIas";
import { ConformiteAssujettiListFormationContinueIobsp } from "./conformite/assujettis/ConformiteAssujettiListFormationContinueIobsp";
import { ConformiteAssujettiListHonorabilite } from "./conformite/assujettis/ConformiteAssujettiListHonorabilite";
import { ConformiteCapaciteProfessionnelleIas } from "./conformite/capaciteProfessionnelle/ConformiteCapaciteProfessionnelleIas";
import { ConformiteCapaciteProfessionnelleIobsp } from "./conformite/capaciteProfessionnelle/ConformiteCapaciteProfessionnelleIobsp";
import { ConformiteDeclarationActiviteIas } from "./conformite/declarationActivite/ConformiteDeclarationActiviteIas";
import { ConformiteDeclarationActiviteIobsp } from "./conformite/declarationActivite/ConformiteDeclarationActiviteIobsp";
import { DocumentViewer } from "./conformite/document/DocumentViewer";
import { GarantieFinanciereDocumentValidator } from "./conformite/document/GarantieFinanciereDocumentValidator";
import { OcrDocumentRefuser } from "./conformite/document/OcrDocumentRefuser";
import { ConformiteFormationContinueIas } from "./conformite/formationContinue/ConformiteFormationContinueIas";
import { ConformiteFormationContinueIobsp } from "./conformite/formationContinue/ConformiteFormationContinueIobsp";
import { ConformiteGarantieFinanciereIas } from "./conformite/garantieFinanciere/ConformiteGarantieFinanciereIas";
import { ConformiteGarantieFinanciereIobsp } from "./conformite/garantieFinanciere/ConformiteGarantieFinanciereIobsp";
import { ConformiteHonorabilite } from "./conformite/honorabililte/ConformiteHonorabilite";
import { ConformiteRcProContainer } from "./conformite/rcPro/ConformiteRcPro";
import { ConformiteSocieteListCapaciteProfessionnelleIas } from "./conformite/societes/ConformiteSocieteListCapaciteProfessionelleIas";
import { ConformiteSocieteListCapaciteProfessionnelleIobsp } from "./conformite/societes/ConformiteSocieteListCapaciteProfessionelleIobsp";
import { ConformiteSocieteListDeclarationActiviteIas } from "./conformite/societes/ConformiteSocieteListDeclarationActiviteIas";
import { ConformiteSocieteListDeclarationActiviteIobsp } from "./conformite/societes/ConformiteSocieteListDeclarationActiviteIobsp";
import { ConformiteSocieteListFormationContinueIas } from "./conformite/societes/ConformiteSocieteListFormationContinueIas";
import { ConformiteSocieteListFormationContinueIobsp } from "./conformite/societes/ConformiteSocieteListFormationContinueIobsp";
import { ConformiteSocieteListGarantieFinanciereIas } from "./conformite/societes/ConformiteSocieteListGarantieFinanciereIas";
import { ConformiteSocieteListGarantieFinanciereIobsp } from "./conformite/societes/ConformiteSocieteListGarantieFinanciereIobsp";
import { ConformiteSocieteListHonorabilite } from "./conformite/societes/ConformiteSocieteListHonorabilite";
import { ConformiteSocieteListRcPro } from "./conformite/societes/ConformiteSocieteListRcPro";
import { ParcoursConformite } from "./conformite/tunnel/ParcoursConformite";
import { ControleQuinquennalAxe } from "./controleQuinquennal/adherent/axe/ControleQuinquennalAxe";
import { ControleQuinquennalItem } from "./controleQuinquennal/adherent/item/ControleQuinquennalItem";
import { ControleQuinquennalPage } from "./controleQuinquennal/adherent/list/ControleQuinquennalPage";
import { ControleQuinquennalAdminAxePage } from "./controleQuinquennal/admin/axe/ControleQuinquennalAdminAxePage";
import { ControleQuinquennalAdminPage } from "./controleQuinquennal/admin/list/ControleQuinquennalAdminPage";
import { ControleQuinquennalRapportPage } from "./controleQuinquennal/admin/rapport/ControleQuinquennalRapport";
import { ControleQuinquennalRemediationPage } from "./controleQuinquennal/admin/remediation/ControleQuinquennalRemediationPage";
import { Home } from "./home/Home";
import { Invitation } from "./invitation/Invitation";
import {
  loadHubspotConversation,
  removeHubspotConversation,
} from "./loadHubspotConversation";
import { Factures } from "./parametres/factures/Factures";
import { GestionUtilisateurs } from "./parametres/gestionUtilisateurs/GestionUtilisateurs";
import { IdentifiantsConnexion } from "./parametres/identifiantsConnexion/IdentifiantsConnexion";
import { InformationsPersonnelles } from "./parametres/informationsPersonnelles/InformationsPersonnelles";
import { InformationsSociete } from "./parametres/informationsSociete/InformationsSociete";
import { ParcoursRenouvellement } from "./renouvellement/ParcoursRenouvellement";
import { RenouvellementAfterPaiement } from "./renouvellement/steps/renouvellementAfterPaiement/RenouvellementAfterPaiement";
import { RenouvellementRemerciements } from "./renouvellement/steps/renouvellementRemerciements/RenouvellementRemerciements";
import { useSession } from "./session/UserSessionContext";
import { useIsAllowed } from "./session/useIsAllowed";
import { SocietesContainer } from "./societes/Societes";
import { SocieteContainer } from "./societes/societe/Societe";
import { SocieteInformationContainer } from "./societes/societe/informations/SocieteInformation";
import { Me } from "./store/store.definition";
import { useMe } from "./store/store.me";
import { ForgotPassword } from "./user/ForgotPassword/ForgotPassword";
import { ForgotPasswordMail } from "./user/ForgotPassword/ForgotPasswordMail";
import { ForgotPasswordReset } from "./user/ForgotPassword/ForgotPasswordReset";
import { ForgotPasswordSuccess } from "./user/ForgotPassword/ForgotPasswordSuccess";
import { Login } from "./user/Login/Login";
import { NewPassword } from "./user/Login/NewPassword";

type PrivateRouteProps = {
  children: ReactElement;

  redirectNotAuthorizedPath?: string;
  getUrlRedirectBadTypeAdhesion?: (me: Me, urlParams: Params<string>) => string;
  allowedRoles?: GatewayContact.ContactRoleUtilisateur[];
  onlyWithTypeAdhesion?: GatewayEntreprise.TypeAdhesion[];
  withHubspotChat?: boolean;
};

function PrivateRoute({
  children,
  redirectNotAuthorizedPath = "/login",
  getUrlRedirectBadTypeAdhesion,
  allowedRoles,
  onlyWithTypeAdhesion,
  withHubspotChat = false,
}: PrivateRouteProps) {
  const session = useSession();
  const params = useParams();
  const { me } = useMe();
  const { pathname, search } = useLocation();
  const { hasAllowedRole } = useIsAllowed();

  useEffect(() => {
    if (withHubspotChat) {
      loadHubspotConversation();
    } else {
      removeHubspotConversation();
    }
  }, [withHubspotChat]);

  if (allowedRoles) {
    if (!me) return <div />;
    if (!hasAllowedRole(...allowedRoles))
      return <Navigate to={redirectNotAuthorizedPath} />;
  }

  if (onlyWithTypeAdhesion) {
    if (!me) return <div />;
    if (!onlyWithTypeAdhesion.includes(me.entreprisePrincipale.typeAdhesion))
      return (
        <Navigate
          to={
            getUrlRedirectBadTypeAdhesion
              ? getUrlRedirectBadTypeAdhesion(me, params)
              : redirectNotAuthorizedPath
          }
        />
      );
  }

  if (session.isConnected && !pathname.startsWith("/adhesion/") && me) {
    if (
      !me.entreprisePrincipale ||
      me.entreprisePrincipale.etat ===
        GatewayEntreprise.EtatEntreprise.ADHESION_EN_COURS ||
      me.entreprisePrincipale.etat ===
        GatewayEntreprise.EtatEntreprise.NON_ADHERENT
    ) {
      return <Navigate to="/adhesion" />;
    }
  }

  return session.isConnected ? (
    children
  ) : (
    <Navigate
      to={`${redirectNotAuthorizedPath}?url=${encodeURIComponent(
        pathname + search
      )}`}
    />
  );
}

export const useRoutes = (): RouteObject[] => {
  return useMemo(
    () => [
      {
        path: "/",
        element: (
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        ),
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
        children: [
          {
            path: "",
            element: <ForgotPasswordMail />,
          },
          {
            path: "reset",
            element: <ForgotPasswordReset />,
          },
          {
            path: "success",
            element: <ForgotPasswordSuccess />,
          },
        ],
      },
      {
        path: "/renouvellement/maj-entreprises",
        element: (
          <PrivateRoute>
            <RenouvellementAfterPaiement />
          </PrivateRoute>
        ),
      },
      {
        path: "/renouvellement/remerciements",
        element: (
          <PrivateRoute>
            <RenouvellementRemerciements />
          </PrivateRoute>
        ),
      },
      {
        path: "/adhesion/creation-entreprises",
        element: (
          <PrivateRoute>
            <AdhesionCreationEntrepriseAfterPaiement />
          </PrivateRoute>
        ),
      },
      {
        path: "/adhesion/remerciements",
        element: (
          <PrivateRoute>
            <AdhesionRemerciements />
          </PrivateRoute>
        ),
      },
      {
        path: "/adhesion-entreprise/creation-entreprises",
        element: (
          <PrivateRoute>
            <AdhesionCreationEntrepriseAfterPaiement isEntreprise />
          </PrivateRoute>
        ),
      },
      {
        path: "/adhesion-entreprise/remerciements",
        element: (
          <PrivateRoute>
            <AdhesionRemerciements isEntreprise />
          </PrivateRoute>
        ),
      },
      {
        path: "/adhesion/*",
        element: <ParcoursAdhesion />,
      },
      {
        path: "/societes",
        element: (
          <PrivateRoute
            onlyWithTypeAdhesion={[
              GatewayEntreprise.TypeAdhesion.GROUPE,
              GatewayEntreprise.TypeAdhesion.GROUPEMENT_MANDATAIRES,
            ]}
            getUrlRedirectBadTypeAdhesion={(me) =>
              `/societes/${me.entreprisePrincipale.siren}`
            }
          >
            <SocietesContainer />
          </PrivateRoute>
        ),
      },

      {
        path: "/societes/:id",
        element: (
          <PrivateRoute>
            <SocieteContainer />
          </PrivateRoute>
        ),
      },
      {
        path: "/societes/:id/informations",
        element: (
          <PrivateRoute
            redirectNotAuthorizedPath="/"
            allowedRoles={[
              GatewayContact.ContactRoleUtilisateur.ADMINISTRATEUR,
            ]}
          >
            <SocieteInformationContainer />
          </PrivateRoute>
        ),
      },
      {
        path: "/personnes-assujetties",
        element: (
          <PrivateRoute>
            <Assujettis />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/rc-pro/:categorie/:id",
        element: (
          <PrivateRoute>
            <ConformiteRcProContainer />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/rc-pro/:categorie",
        element: (
          <PrivateRoute
            onlyWithTypeAdhesion={[
              GatewayEntreprise.TypeAdhesion.GROUPE,
              GatewayEntreprise.TypeAdhesion.GROUPEMENT_MANDATAIRES,
            ]}
            getUrlRedirectBadTypeAdhesion={(me, params) => {
              return `/conformite/rc-pro/${
                (params as { categorie: string }).categorie
              }/${me.entreprisePrincipale.siren}`;
            }}
          >
            <ConformiteSocieteListRcPro />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/declaration-activite/ias/:id",
        element: (
          <PrivateRoute>
            <ConformiteDeclarationActiviteIas />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/declaration-activite/ias",
        element: (
          <PrivateRoute
            onlyWithTypeAdhesion={[
              GatewayEntreprise.TypeAdhesion.GROUPE,
              GatewayEntreprise.TypeAdhesion.GROUPEMENT_MANDATAIRES,
            ]}
            getUrlRedirectBadTypeAdhesion={(me) =>
              `/conformite/declaration-activite/ias/${me.entreprisePrincipale.siren}`
            }
          >
            <ConformiteSocieteListDeclarationActiviteIas />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/declaration-activite/iobsp/:id",
        element: (
          <PrivateRoute>
            <ConformiteDeclarationActiviteIobsp />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/declaration-activite/iobsp",
        element: (
          <PrivateRoute
            onlyWithTypeAdhesion={[
              GatewayEntreprise.TypeAdhesion.GROUPE,
              GatewayEntreprise.TypeAdhesion.GROUPEMENT_MANDATAIRES,
            ]}
            getUrlRedirectBadTypeAdhesion={(me) =>
              `/conformite/declaration-activite/iobsp/${me.entreprisePrincipale.siren}`
            }
          >
            <ConformiteSocieteListDeclarationActiviteIobsp />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/garantie-financiere/ias/:id",
        element: (
          <PrivateRoute>
            <ConformiteGarantieFinanciereIas />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/garantie-financiere/ias",
        element: (
          <PrivateRoute
            onlyWithTypeAdhesion={[
              GatewayEntreprise.TypeAdhesion.GROUPE,
              GatewayEntreprise.TypeAdhesion.GROUPEMENT_MANDATAIRES,
            ]}
            getUrlRedirectBadTypeAdhesion={(me) =>
              `/conformite/garantie-financiere/ias/${me.entreprisePrincipale.siren}`
            }
          >
            <ConformiteSocieteListGarantieFinanciereIas />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/garantie-financiere/iobsp/:id",
        element: (
          <PrivateRoute>
            <ConformiteGarantieFinanciereIobsp />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/garantie-financiere/iobsp",
        element: (
          <PrivateRoute
            onlyWithTypeAdhesion={[
              GatewayEntreprise.TypeAdhesion.GROUPE,
              GatewayEntreprise.TypeAdhesion.GROUPEMENT_MANDATAIRES,
            ]}
            getUrlRedirectBadTypeAdhesion={(me) =>
              `/conformite/garantie-financiere/iobsp/${me.entreprisePrincipale.siren}`
            }
          >
            <ConformiteSocieteListGarantieFinanciereIobsp />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/honorabilite/:siren/:id",
        element: (
          <PrivateRoute>
            <ConformiteHonorabilite />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/honorabilite/:siren",
        element: (
          <PrivateRoute>
            <ConformiteAssujettiListHonorabilite />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/honorabilite",
        element: (
          <PrivateRoute
            onlyWithTypeAdhesion={[
              GatewayEntreprise.TypeAdhesion.GROUPE,
              GatewayEntreprise.TypeAdhesion.GROUPEMENT_MANDATAIRES,
            ]}
            getUrlRedirectBadTypeAdhesion={(me) =>
              `/conformite/honorabilite/${me.entreprisePrincipale.siren}`
            }
          >
            <ConformiteSocieteListHonorabilite />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/capacite-professionnelle/ias/:siren/:id",
        element: (
          <PrivateRoute>
            <ConformiteCapaciteProfessionnelleIas />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/capacite-professionnelle/ias/:siren",
        element: (
          <PrivateRoute>
            <ConformiteAssujettiListCapaciteProfessionnelleIas />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/capacite-professionnelle/ias",
        element: (
          <PrivateRoute
            onlyWithTypeAdhesion={[
              GatewayEntreprise.TypeAdhesion.GROUPE,
              GatewayEntreprise.TypeAdhesion.GROUPEMENT_MANDATAIRES,
            ]}
            getUrlRedirectBadTypeAdhesion={(me) =>
              `/conformite/capacite-professionnelle/ias/${me.entreprisePrincipale.siren}`
            }
          >
            <ConformiteSocieteListCapaciteProfessionnelleIas />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/capacite-professionnelle/iobsp/:siren/:id",
        element: (
          <PrivateRoute>
            <ConformiteCapaciteProfessionnelleIobsp />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/capacite-professionnelle/iobsp/:siren",
        element: (
          <PrivateRoute>
            <ConformiteAssujettiListCapaciteProfessionnelleIobsp />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/capacite-professionnelle/iobsp",
        element: (
          <PrivateRoute
            onlyWithTypeAdhesion={[
              GatewayEntreprise.TypeAdhesion.GROUPE,
              GatewayEntreprise.TypeAdhesion.GROUPEMENT_MANDATAIRES,
            ]}
            getUrlRedirectBadTypeAdhesion={(me) =>
              `/conformite/capacite-professionnelle/iobsp/${me.entreprisePrincipale.siren}`
            }
          >
            <ConformiteSocieteListCapaciteProfessionnelleIobsp />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/formation-continue/ias/:siren/:id",
        element: (
          <PrivateRoute>
            <ConformiteFormationContinueIas />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/formation-continue/ias/:siren",
        element: (
          <PrivateRoute>
            <ConformiteAssujettiListFormationContinueIas />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/formation-continue/ias",
        element: (
          <PrivateRoute
            onlyWithTypeAdhesion={[
              GatewayEntreprise.TypeAdhesion.GROUPE,
              GatewayEntreprise.TypeAdhesion.GROUPEMENT_MANDATAIRES,
            ]}
            getUrlRedirectBadTypeAdhesion={(me) =>
              `/conformite/formation-continue/ias/${me.entreprisePrincipale.siren}`
            }
          >
            <ConformiteSocieteListFormationContinueIas />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/formation-continue/iobsp/:siren/:id",
        element: (
          <PrivateRoute>
            <ConformiteFormationContinueIobsp />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/formation-continue/iobsp/:siren",
        element: (
          <PrivateRoute>
            <ConformiteAssujettiListFormationContinueIobsp />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/formation-continue/iobsp",
        element: (
          <PrivateRoute
            onlyWithTypeAdhesion={[
              GatewayEntreprise.TypeAdhesion.GROUPE,
              GatewayEntreprise.TypeAdhesion.GROUPEMENT_MANDATAIRES,
            ]}
            getUrlRedirectBadTypeAdhesion={(me) =>
              `/conformite/formation-continue/iobsp/${me.entreprisePrincipale.siren}`
            }
          >
            <ConformiteSocieteListFormationContinueIobsp />
          </PrivateRoute>
        ),
      },
      {
        path: "/renouvellement/*",
        element: (
          <PrivateRoute>
            <ParcoursRenouvellement />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/garantie-financiere/:siren/:millesime/:categorie/document/validate",
        element: (
          <PrivateRoute>
            <GarantieFinanciereDocumentValidator />
          </PrivateRoute>
        ),
      },
      {
        path: "/conformite/:type/:siren/:millesime/:categorie/document/refuse",
        element: (
          <PrivateRoute>
            <OcrDocumentRefuser />
          </PrivateRoute>
        ),
      },
      {
        path: "/documents/:siren",
        element: (
          <PrivateRoute>
            <DocumentViewer />
          </PrivateRoute>
        ),
      },
      {
        path: "/parametres/informations-personnelles",
        element: (
          <PrivateRoute>
            <InformationsPersonnelles />
          </PrivateRoute>
        ),
      },
      {
        path: "/parametres/informations-societe",
        element: (
          <PrivateRoute>
            <InformationsSociete />
          </PrivateRoute>
        ),
      },

      {
        path: "/parametres/gestion-utilisateurs",
        element: (
          <PrivateRoute
            allowedRoles={[
              GatewayContact.ContactRoleUtilisateur.ADMINISTRATEUR,
              GatewayContact.ContactRoleUtilisateur.RESPONSABLE_CONFORMITE,
            ]}
            redirectNotAuthorizedPath="/"
          >
            <GestionUtilisateurs />
          </PrivateRoute>
        ),
      },
      {
        path: "/parametres/factures",
        element: (
          <PrivateRoute
            allowedRoles={[
              GatewayContact.ContactRoleUtilisateur.ADMINISTRATEUR,
            ]}
            redirectNotAuthorizedPath="/"
          >
            <Factures />
          </PrivateRoute>
        ),
      },
      {
        path: "/parametres/identifiants",
        element: (
          <PrivateRoute>
            <IdentifiantsConnexion />
          </PrivateRoute>
        ),
      },
      { path: "/invitation/:contactId/:secret/*", element: <Invitation /> },
      {
        path: "/new-password/:userId",
        element: <NewPassword />,
      },
      {
        path: "/admin/take-over/:contactId/:entreprisePrincipalId",
        element: (
          <PrivateRoute>
            <AdminSwitchSession />
          </PrivateRoute>
        ),
      },
      {
        path: "/actualites",
        element: (
          <PrivateRoute>
            <ActualiteListPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/actualites/:slug",
        element: (
          <PrivateRoute>
            <ActualiteItem />
          </PrivateRoute>
        ),
      },
      {
        path: "/services/:slug",
        element: (
          <PrivateRoute>
            <ServicePage />
          </PrivateRoute>
        ),
      },
      {
        path: "/le-saviez-vous",
        element: (
          <PrivateRoute>
            <LeSaviezVousListPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/preview",
        element: <CmsPreview />,
      },
      {
        path: "societes/:siren/conformite/*",
        element: (
          <PrivateRoute>
            <ParcoursConformite />
          </PrivateRoute>
        ),
      },
      {
        path: "controle-quinquennal",
        element: (
          <PrivateRoute withHubspotChat>
            <ControleQuinquennalPage />
          </PrivateRoute>
        ),
      },
      {
        path: "controle-quinquennal/:id",
        element: (
          <PrivateRoute withHubspotChat>
            <ControleQuinquennalItem />
          </PrivateRoute>
        ),
      },
      {
        path: "controle-quinquennal/:id/:idAxe",
        element: (
          <PrivateRoute withHubspotChat>
            <ControleQuinquennalAxe />
          </PrivateRoute>
        ),
      },
      {
        path: "admin/controle-quinquennal/:idEntreprise",
        element: (
          <PrivateRoute>
            <ControleQuinquennalAdminPage />
          </PrivateRoute>
        ),
      },
      {
        path: "admin/controle-quinquennal/:idEntreprise/rapport",
        element: (
          <PrivateRoute>
            <ControleQuinquennalRapportPage />
          </PrivateRoute>
        ),
      },
      {
        path: "admin/controle-quinquennal/:idEntreprise/:idAxe",
        element: (
          <PrivateRoute>
            <ControleQuinquennalAdminAxePage />
          </PrivateRoute>
        ),
      },
      {
        path: "admin/controle-quinquennal/:idEntreprise/:idAxe/remediation",
        element: (
          <PrivateRoute>
            <ControleQuinquennalRemediationPage />
          </PrivateRoute>
        ),
      },
      {
        path: "admin/controle-quinquennal/:idEntreprise/:idAxe/remediation/:idRemediation",
        element: (
          <PrivateRoute>
            <ControleQuinquennalRemediationPage />
          </PrivateRoute>
        ),
      },
    ],
    []
  );
};

export function Router(): ReactElement {
  const routes = useRoutes();
  const router = createBrowserRouter(routes);
  return RouterProvider({ router });
}
