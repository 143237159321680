import { PageWithSidebar } from "@src/components/PageWithSidebar/PageWithSidebar";
import { PropsWithChildren } from "react";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { UserMenu } from "@src/components/UserMenu/UserMenu";
import { MainPageSidebar } from "@src/components/MainPageTemplate/MainPageSidebar";
import { Link } from "@src/components/Link/Link";
import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as ChevronLeft } from "@src/assets/icons/chevron-left.svg";
import style from "./CmsPageTemplate.module.scss";

type ParametrePageTemplateProps = {
  isFullPage?: boolean;
  size?: "medium" | "big" | "large";
} & PropsWithChildren;

export function CmsPageTemplate({
  children,
  isFullPage = false,
  size = "large",
}: ParametrePageTemplateProps) {
  return (
    <PageWithSidebar sidebar={<MainPageSidebar />}>
      <div className={style.template}>
        <div className={style.scrollablePart}>
          <div className={style.header}>
            <Link to="/" className={style.back}>
              <Button variant="text" icon={<ChevronLeft />}>
                Retour à l&apos;accueil
              </Button>
            </Link>
            <div className={style.userMenuWrapper}>
              <UserMenu />
            </div>
          </div>

          <div className={style.content}>
            {isFullPage ? (
              children
            ) : (
              <MaxWidthContent size={size}>{children}</MaxWidthContent>
            )}
            <Link to="/" className={style.back}>
              <Button variant="text" icon={<ChevronLeft />}>
                Retour à l&apos;accueil
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </PageWithSidebar>
  );
}
