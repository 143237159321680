export const changeEmailUrl = '/changeEmail';
export interface ChangeEmailType {
  email: string;
  accessToken: string;
}

export const verifyEmailUrl = '/changeEmail/verify';
export interface VerifyEmailType {
  code: string;
  accessToken: string;
  email: string;
  emailHash: string;
}
