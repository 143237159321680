import { UIInputText } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ChangeEventHandler } from "react";
import styles from "./InputSearch.module.scss";

type InputSearchProps = {
  placeholder: string;
  hasError?: boolean;
  name: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  value?: string;
};
export function InputSearch({
  placeholder,
  hasError = false,
  name,
  onChange,
  value,
}: InputSearchProps) {
  return (
    <div className={styles.wrapper}>
      <UIInputText
        value={value}
        placeholder={placeholder}
        hasError={hasError}
        name={name}
        onChange={onChange}
        className={styles.inputsearch}
      />
    </div>
  );
}
