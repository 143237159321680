import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Form,
  InputText,
  SubmitHandler,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { useEffect } from "react";

const schema = zod.object({
  email: zod.string().email(),
});
export type UpdateMailFormValues = zod.infer<typeof schema>;

type UpdateMailFormProps = {
  onSubmit: SubmitHandler<UpdateMailFormValues>;
  formId: string;
  defaultValues?: UpdateMailFormValues;
  setIsDirty: (isDirty: boolean) => void;
};

export function UpdateMailForm({
  onSubmit,
  formId,
  defaultValues,
  setIsDirty,
}: UpdateMailFormProps) {
  const formConfig = useFormConfig<typeof schema, UpdateMailFormValues>({
    schema,
    defaultValues,
  });
  useEffect(() => {
    setIsDirty(formConfig.formState.isDirty);
  }, [formConfig.formState.isDirty, setIsDirty]);
  return (
    <Form formConfig={formConfig} onSubmit={onSubmit} id={formId}>
      <FormRow>
        <FormField>
          <InputText<UpdateMailFormValues> name="email" label="Adresse mail" />
        </FormField>
      </FormRow>
    </Form>
  );
}
