import { ParcoursAction } from "@src/parcours/NavigationButtons/ParcoursAction";
import { ReactComponent as SaveIcon } from "@src/assets/icons/save.svg";
import style from "./SaveAndQuitButton.module.scss";

type SaveAndQuitButtonProps = { onClick?: () => void | Promise<void> };
export function SaveAndQuitButton({ onClick }: SaveAndQuitButtonProps) {
  return (
    <ParcoursAction
      className={style.saveAndQuitButton}
      variant="text"
      icon={<SaveIcon />}
      onClick={onClick}
    >
      Sauvegarder et quitter
    </ParcoursAction>
  );
}
