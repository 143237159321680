import styles from "./TitleWithNumber.module.scss";

type TitleWithNumberProps = {
  title: string;
  number?: number;
};
export function TitleWithNumber({ title, number }: TitleWithNumberProps) {
  return (
    <div className={styles.title}>
      <h1>{title}</h1>
      {number && <p>({number})</p>}
    </div>
  );
}
