export enum LocalStorageKeys {
  ACCESS_TOKEN = "access_token",
  REFRESH_TOKEN = "refresh_token",
  ID_TOKEN = "id_token",
  SELECTED_MILLESIME = "selected_millesime",
  CREATION_ENTREPRISES_PARAMS = "creation_entreprises_params",
  CREATION_ENTREPRISES_TYPE = "creation_entreprises_type",
  CREATION_ENTREPRISES_TYPE_PAIEMENT = "creation_entreprises_type_paiement",
  ADMIN_CONTACT_ID = "adminContactId",
  ADMIN_ENTREPRISE_PRINCIPAL_ID = "adminEntreprisePrincipalId",
}

class LocalStorageHelper {
  // eslint-disable-next-line no-useless-constructor
  constructor(private readonly storage: Storage) {}

  setItem(key: LocalStorageKeys, value: string) {
    this.storage.setItem(key, value);
  }

  getItem(key: LocalStorageKeys) {
    return this.storage.getItem(key);
  }

  removeItem(key: LocalStorageKeys) {
    return this.storage.removeItem(key);
  }
}
export const localStorageHelper = new LocalStorageHelper(localStorage);
