import {
  GatewayControleQuinquennal,
  GatewayEntreprise,
} from "@conformite/gateway";
import { Button, Card } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ControleQuinquennalApi } from "@src/api/controleQuinquennal.api";
import { ReactComponent as ArrowRight } from "@src/assets/icons/arrow-right.svg";
import { ReactComponent as DownloadIcon } from "@src/assets/icons/download.svg";
import { ComplianceProgressBar } from "@src/components/ComplianceProgressBar/ComplianceProgressBar";
import { IconStatus } from "@src/components/IconStatus/IconStatus";
import { Link } from "@src/components/Link/Link";
import { TagWithIcon } from "@src/components/Tag/TagWithIcon";
import { downloadFile, getDownloadedFile } from "@src/helper/file.helper";
import classNames from "classnames";
import { format } from "date-fns";
import styles from "./ControleQuinquennalCard.module.scss";

type ControleQuinquennalCardActionProps = {
  controleQuinquennal: GatewayControleQuinquennal.ControleQuinquennalWithCompletion;
  entreprise?: GatewayEntreprise.EntrepriseAControllerResponseType;
};

function ControleQuinquennalCardAction({
  controleQuinquennal,
  entreprise,
}: ControleQuinquennalCardActionProps) {
  switch (controleQuinquennal.status) {
    case GatewayControleQuinquennal.ControleQuinquennalStatus.EN_COURS:
    case GatewayControleQuinquennal.ControleQuinquennalStatus.DEPOSE:
      return (
        <Link to={`/controle-quinquennal/${controleQuinquennal.id}`}>
          <Button
            variant="text"
            className={styles.action}
            icon={<ArrowRight />}
          >
            Accéder
          </Button>
        </Link>
      );
    case GatewayControleQuinquennal.ControleQuinquennalStatus.TERMINE:
      return (
        <Button
          variant="text"
          icon={<DownloadIcon />}
          onClick={() => {
            ControleQuinquennalApi.getRapport(controleQuinquennal.id)
              .then((rapport) => {
                if (!rapport) return null;
                return getDownloadedFile(
                  entreprise?.siren ?? "",
                  controleQuinquennal.millesime,
                  rapport.pathname,
                  rapport.filename
                );
              })
              .then((downloadedFile) => {
                if (!downloadedFile) return;
                downloadFile(downloadedFile.file, downloadedFile.filename);
              })
              .catch(console.error);
          }}
        >
          Télécharger le rapport
        </Button>
      );
    case GatewayControleQuinquennal.ControleQuinquennalStatus.ANNULE:
      return null;
    default:
      throw new Error(`Unknown status ${controleQuinquennal.status as string}`);
  }
}

type ControleQuinquennalCardProps = {
  controleQuinquennal: GatewayControleQuinquennal.ControleQuinquennalWithCompletion;
  entreprise?: GatewayEntreprise.EntrepriseAControllerResponseType;
};
export function ControlQuinquennalCard({
  controleQuinquennal,
  entreprise,
}: ControleQuinquennalCardProps) {
  return (
    <Card
      className={classNames(styles.controleQuinquennal, {
        [styles.archived]:
          controleQuinquennal.status ===
          GatewayControleQuinquennal.ControleQuinquennalStatus.TERMINE,
      })}
    >
      <p className={styles.millesime}>
        {controleQuinquennal.status ===
          GatewayControleQuinquennal.ControleQuinquennalStatus.TERMINE && (
          <IconStatus className={styles.millesimeStatus} isValid />
        )}
        Année {controleQuinquennal.millesime}
      </p>
      <p className={styles.entreprise}>{entreprise?.raisonSociale}</p>
      {controleQuinquennal.status ===
        GatewayControleQuinquennal.ControleQuinquennalStatus.EN_COURS && (
        <ComplianceProgressBar
          progress={Math.round(controleQuinquennal.completion)}
        />
      )}
      {controleQuinquennal.status ===
        GatewayControleQuinquennal.ControleQuinquennalStatus.DEPOSE &&
        controleQuinquennal.dateDepot && (
          <TagWithIcon variant="success" className={styles.tag}>
            {`Dossier déposé le ${format(
              controleQuinquennal.dateDepot,
              "dd/MM/yyyy"
            )}`}
          </TagWithIcon>
        )}
      <div className={styles.actionContainer}>
        <ControleQuinquennalCardAction
          controleQuinquennal={controleQuinquennal}
          entreprise={entreprise}
        />
      </div>
    </Card>
  );
}
