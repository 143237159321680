import { ReactComponent as CheckCircle } from "@src/assets/icons/check-circle-fill.svg";
import { ReactComponent as XCircle } from "@src/assets/icons/x-circle-fill.svg";
import classNames from "classnames";
import styles from "./IconStatus.module.scss";

type IconStatusProps = {
  isValid: boolean;
  className?: string;
};
export function IconStatus({ isValid, className }: IconStatusProps) {
  if (isValid)
    return <CheckCircle className={classNames(styles.valid, className)} />;
  return <XCircle className={classNames(styles.invalid, className)} />;
}
