import {
  Card,
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ResumeBlockComponentProps } from "./RenouvellementResumeEntreprisePage";

export function ResumeAdresse({ data }: ResumeBlockComponentProps) {
  return (
    <Card variant="accent">
      <FormRow>
        <FormField>
          <b>
            {data.resume.entreprisePrincipale.adresse.numeroVoie}{" "}
            {data.resume.entreprisePrincipale.adresse.typeVoie}{" "}
            {data.resume.entreprisePrincipale.adresse.libelleVoie}
            <br />
            {data.resume.entreprisePrincipale.adresse.complementAdresse}
            <br />
            {data.resume.entreprisePrincipale.adresse.codePostal}{" "}
            {data.resume.entreprisePrincipale.adresse.ville}{" "}
            {data.resume.entreprisePrincipale.adresse.pays}
          </b>
        </FormField>
      </FormRow>
    </Card>
  );
}
