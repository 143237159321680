import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as Trash } from "@src/assets/icons/trash.svg";
import styles from "./FileRead.module.scss";
import { DownloadFile } from "../DownloadFile/DownloadFile";

type FileReadProps = {
  onDelete: () => void;
  deletable?: boolean;
  name?: string;
  siren?: string;
  millesime?: number;
  filename?: string;
  pathname?: string;
};
export function FileRead({
  name,
  onDelete,
  deletable,
  siren,
  millesime,
  filename,
  pathname,
}: FileReadProps) {
  const showDownload = !deletable && filename && pathname && siren && millesime;
  return (
    <div className={styles.file}>
      {name}
      {deletable && (
        <Button variant="text" icon={<Trash />} onClick={onDelete} />
      )}
      {!!showDownload && (
        <DownloadFile
          siren={siren}
          millesime={millesime}
          filename={filename}
          pathname={pathname}
        />
      )}
    </div>
  );
}
