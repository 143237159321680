import styles from "./readonlyFieldSet.module.scss";

type ReadonlyFieldSetProps = {
  children: React.ReactNode;
  readonly?: boolean;
};
export function ReadonlyFieldSet({
  children,
  readonly,
}: ReadonlyFieldSetProps) {
  return (
    <fieldset className={styles["readonly-fieldset"]} disabled={readonly}>
      {children}
    </fieldset>
  );
}
