import { ReactElement, useCallback, useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import {
  ParcoursErrorHandlers,
  StepConfigMap,
  UIParcoursComponentProps,
} from "./Parcours.types";
import { useActiveStepConfig, useParcoursData } from "./Parcours.helpers";

type ParcoursProps<
  TPath extends string,
  TData,
  TStepProperties extends Record<string, unknown>
> = {
  config: StepConfigMap<TPath, TData, TStepProperties>;
  errorHandlers: ParcoursErrorHandlers;
  UIParcoursComponent: (props: UIParcoursComponentProps<TPath>) => ReactElement;
  firstStep: TPath;
  defaultHistory?: TPath[] | null;
  initialData: TData;
  withRouter?: boolean;
  rootPath?: string;
  handleQuit?: (data: TData, history: TPath[]) => Promise<void>;
  afterChangeStep?: (data: TData, history: TPath[]) => void;
  stepProperties?: TStepProperties;
};

function ParcoursInner<
  TPath extends string,
  TData,
  TStepProperties extends Record<string, unknown>
>({
  withRouter = true,
  config,
  firstStep,
  errorHandlers,
  initialData,
  rootPath,
  handleQuit,
  UIParcoursComponent,
  afterChangeStep,
  defaultHistory,
  stepProperties = {} as TStepProperties,
}: ParcoursProps<TPath, TData, TStepProperties>) {
  const {
    activeStepPath,
    changeStep,
    changeToPrevStep,
    component,
    progress,
    centeredContent,
    navigating,
    history,
    allowPrevious,
  } = useActiveStepConfig(
    defaultHistory && defaultHistory.length > 0
      ? defaultHistory[defaultHistory.length - 1]
      : firstStep,
    defaultHistory,
    errorHandlers,
    config,
    withRouter,
    rootPath
  );

  const { data, setData, addData, getData } = useParcoursData(initialData);

  const handleQuitHydrated = useMemo(
    () => (handleQuit ? () => handleQuit(getData(), history) : undefined),
    [handleQuit, getData, history]
  );

  const changeStepHydrated = useCallback(
    (nextStep: TPath) => {
      changeStep(nextStep);
      const nextHistory = history.includes(nextStep)
        ? history.slice(0, history.indexOf(nextStep) + 1)
        : [...history, nextStep];

      if (afterChangeStep) afterChangeStep(getData(), nextHistory);
    },
    [changeStep, afterChangeStep, getData, history]
  );
  const changeToPrevStepHydrated = useCallback(() => {
    changeToPrevStep();
    if (afterChangeStep) afterChangeStep(getData(), history.slice(0, -1));
  }, [changeToPrevStep, afterChangeStep, getData, history]);

  const StepComponent = component;

  return (
    <UIParcoursComponent
      progress={progress}
      centeredContent={centeredContent}
      hasPrevious={allowPrevious !== false}
      history={history}
      handleQuit={handleQuitHydrated}
      changeToPrevStep={changeToPrevStepHydrated}
    >
      {!navigating && (
        <StepComponent
          currentStep={activeStepPath}
          changeStep={changeStepHydrated}
          data={data}
          setData={setData}
          addData={addData}
          handleQuit={handleQuitHydrated}
          getData={getData}
          {...stepProperties}
        />
      )}
    </UIParcoursComponent>
  );
}

export function Parcours<
  TPath extends string,
  TData,
  TStepProperties extends Record<string, unknown> = Record<string, never>
>({ ...props }: ParcoursProps<TPath, TData, TStepProperties>) {
  if (props.withRouter !== false)
    return (
      <Routes>
        <Route
          path="/*"
          element={<ParcoursInner<TPath, TData, TStepProperties> {...props} />}
        />
      </Routes>
    );
  return <ParcoursInner<TPath, TData, TStepProperties> {...props} />;
}
