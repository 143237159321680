import { GatewayChangeEmail } from "@conformite/gateway";
import { userSession } from "@src/session/UserSession";

export class GatewayChangeEmailAPI {
  public static async changeEmail(
    payload: Omit<GatewayChangeEmail.ChangeEmailType, "accessToken">
  ): Promise<string> {
    const payloadWithAccessToken: GatewayChangeEmail.ChangeEmailType = {
      ...payload,
      accessToken: userSession.sessionData.accessToken as string,
    };
    const { data } = await userSession.axiosInstance.post<string>(
      GatewayChangeEmail.changeEmailUrl,
      payloadWithAccessToken
    );
    return data;
  }

  public static async verify(
    payload: Omit<GatewayChangeEmail.VerifyEmailType, "accessToken">
  ) {
    const payloadWithAccessToken: GatewayChangeEmail.VerifyEmailType = {
      ...payload,
      accessToken: userSession.sessionData.accessToken as string,
    };
    await userSession.axiosInstance.post<string>(
      GatewayChangeEmail.verifyEmailUrl,
      payloadWithAccessToken
    );
  }
}
