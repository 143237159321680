import { ConformiteAssujettiList } from "./ConformiteAssujettiList";

export function ConformiteAssujettiListCapaciteProfessionnelleIas() {
  return (
    <ConformiteAssujettiList
      title="Capacité Professionnelle IAS"
      avancementType="capaciteProfessionnelleIas"
      backLink="/conformite/capacite-professionnelle/ias"
    />
  );
}
