import { GatewayEntreprise } from "@conformite/gateway";
import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { Dropdown } from "@src/components/DropDown/Dropdown";
import { ReactComponent as ThreeDots } from "@src/assets/icons/three-dots.svg";
import style from "./Societes.module.scss";
import { useDownloadAttestation } from "./attestation/useDownloadAttestation";

type EntrepriseActionsProps = {
  entreprise: GatewayEntreprise.EntrepriseAControllerResponseType;
};
export function EntrepriseActions({ entreprise }: EntrepriseActionsProps) {
  const { downloadAttestation, getIsAllowedToDownloadAttestation } =
    useDownloadAttestation();

  if (!getIsAllowedToDownloadAttestation(entreprise)) return <div />;
  return (
    <div aria-hidden onClick={(e) => e.stopPropagation()}>
      <Dropdown
        variant="text"
        icon={<ThreeDots className={style.editIcon} />}
        placement="bottom-end"
      >
        {({ close }) => (
          <div>
            <Button
              variant="text"
              onClick={(event) => {
                downloadAttestation(entreprise)(event);
                close();
              }}
            >
              Télécharger l&apos;attestation d&apos;adhésion
            </Button>
          </div>
        )}
      </Dropdown>
    </div>
  );
}
