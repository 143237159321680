import { PropsWithChildren, useMemo } from "react";
import style from "./Gauge.module.scss";

type GaugeProps = {
  percent: number;
  isValid?: boolean;
} & PropsWithChildren;

const clamp = (min: number, num: number, max: number) =>
  Math.min(Math.max(num, min), max);

const RAYON = 45;
const pathLength = 2 * Math.PI * RAYON;
export function Gauge({ percent, children, isValid = false }: GaugeProps) {
  const dashoffsetPathLength = useMemo(() => {
    if (!pathLength) return undefined;
    return clamp(
      0,
      pathLength * ((100 - clamp(0, percent, 100)) / 100),
      pathLength - pathLength / 100 // to have a small curser if(0%)
    );
  }, [percent]);

  return (
    <div className={style.gaugeWrapper}>
      <svg
        className={style.gauge}
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <circle
          className={style.gaugeCircle}
          data-valid={isValid}
          r={RAYON}
          cx="50"
          cy="50"
        />
        {!isValid && (
          <path
            className={style.meter}
            d="M5,50a45,45 0 1,0 90,0a45,45 0 1,0 -90,0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDashoffset={pathLength}
            strokeDasharray={pathLength}
            style={{
              strokeDashoffset: dashoffsetPathLength,
            }}
          />
        )}
      </svg>
      {children}
    </div>
  );
}
