import {
  GatewayConformiteRcPro,
  GatewayEntreprise,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import {
  Button,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import {
  Navigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { merge } from "lodash";
import { Link } from "@src/components/Link/Link";
import { MainContentPageTemplate } from "@src/components/MainPageTemplate/MainContentPageTemplate";
import { ReactComponent as ChevronLeft } from "@src/assets/icons/chevron-left.svg";
import { useEntreprises } from "@src/store/store.entreprise";
import { SkeletonCard } from "@src/components/Skeletons/card/SkeletonCard";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { GatewayRcProApi } from "@src/api/conformite/rcPro.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { useMillesime } from "@src/store/store.millesime";
import { categorieToAvancementKey } from "@src/societes/societe.helper";
import { useHasForbiddenTypeAdhesion } from "@src/session/useIsAllowed";
import styles from "./ConformiteRcPro.module.scss";
import { ConformiteRcProRead } from "./read/ConformiteRcProRead";
import { ConformiteRcProEdit } from "./edit/ConformiteRcProEdit";
import { RcProPropertyDefault, isCategorie } from "./conformiteRcPro.helper";

type UseRcPro = {
  id?: string;
  millesime?: number;
  categorie?: string;
  isAdmin?: boolean;
};
export const useRcPro = ({
  id,
  millesime,
  categorie,
  isAdmin = false,
}: UseRcPro) => {
  const [rcPro, setRcPro] =
    useState<GatewayConformiteRcPro.ConformiteRcProProperty | null>(null);
  const retrieveRcPro = useCallback(() => {
    if (!id || !millesime || !categorie || !isCategorie(categorie)) return;
    GatewayRcProApi.getRcPro(id, millesime, categorie, isAdmin)
      .then((data) => {
        setRcPro(data);
      })
      .catch((error) => {
        handleGatewayError({
          onUnhandled: () => {
            displayErrorToast(
              "Une erreur est survenue lors de la récupération du formulaire"
            );
          },
        })(error);
      });
  }, [id, millesime, categorie, isAdmin]);

  useEffect(() => {
    retrieveRcPro();
  }, [id, categorie, millesime, retrieveRcPro]);
  const properties = useMemo(() => {
    const defaultProperties =
      RcProPropertyDefault[categorie as GatewayInfoSocieteOrias.Categorie];
    return merge({ ...defaultProperties }, rcPro);
  }, [rcPro, categorie]);
  return {
    rcPro,
    properties,
    retrieveRcPro,
  };
};
export function ConformiteRcProContainer() {
  const hasForbiddenTypeAdhesion = useHasForbiddenTypeAdhesion();
  const { id, categorie: categorieLowercase } = useParams<{
    id: string;
    categorie: string;
  }>();
  const [query] = useSearchParams();
  const fromAxe = query.get("fromAxe");
  const { selected: millesime } = useMillesime();
  const { entreprisesBySiren, updateAvancement } = useEntreprises();
  const [isEditing, setIsEditing] = useState(false);
  const { search } = useLocation();
  const societe = id && entreprisesBySiren ? entreprisesBySiren[id] : undefined;
  const categorie = categorieLowercase?.toUpperCase();

  const { rcPro, properties, retrieveRcPro } = useRcPro({
    id,
    millesime,
    categorie,
  });

  if (!id) {
    return <Navigate to="/societes" />;
  }

  if (!categorie || !isCategorie(categorie)) {
    return <Navigate to={`/societes/${id}${search}`} />;
  }
  const isConforme =
    societe?.avancement[categorieToAvancementKey[categorie]] === 100;

  return (
    <MainContentPageTemplate
      title={
        <div className={styles.title}>
          <h1>
            RC Pro
            {` ${categorieLowercase?.charAt(0).toUpperCase() ?? ""}${
              categorieLowercase?.slice(1).toUpperCase() ?? ""
            }`}
          </h1>
          <h2>{societe?.raisonSociale}</h2>
        </div>
      }
      actionNavigation={
        hasForbiddenTypeAdhesion([
          GatewayEntreprise.TypeAdhesion.SOCIETE_INDIVIDUELLE,
        ]) ? null : (
          <Link
            to={
              fromAxe === null
                ? `/societes/${id}`
                : `/conformite/rc-pro/${categorieLowercase || ""}`
            }
          >
            <Button variant="text" icon={<ChevronLeft />}>
              Retour
            </Button>
          </Link>
        )
      }
    >
      {!societe && (
        <MaxWidthContent size="large">
          <SkeletonCard height={500} />
        </MaxWidthContent>
      )}
      {millesime && societe && !isEditing && (
        <ConformiteRcProRead
          properties={properties}
          isConforme={isConforme}
          millesime={millesime}
          setToEdit={() => setIsEditing(true)}
          siren={societe.siren}
        />
      )}
      {millesime && societe && isEditing && (
        <ConformiteRcProEdit
          siren={id}
          millesime={millesime}
          isConforme={isConforme}
          categorie={categorie}
          rcPro={rcPro}
          stopEditing={(value, avancement) => {
            setIsEditing(false);
            if (value) retrieveRcPro();
            if (avancement) updateAvancement(avancement);
          }}
        />
      )}
    </MainContentPageTemplate>
  );
}
