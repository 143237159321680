import {
  UIInputContainer,
  UIInputRadioGroup,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { RadioOption } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { DefaultSort } from "./useFilterPagination";
import styles from "./SortSelect.module.scss";

type SortSelectProps<SortKeys extends string> = {
  label?: string;
  name: string;
  sortSelected: DefaultSort<SortKeys> | null;
  setSortSelected: (sort: DefaultSort<SortKeys> | null) => void;
  options: RadioOption<SortKeys>[];
};
export function SortSelect<SortKeys extends string>({
  name,
  label,
  options,
  sortSelected,
  setSortSelected,
}: SortSelectProps<SortKeys>) {
  return (
    <UIInputContainer name={name} label={label} hideError>
      <UIInputRadioGroup
        className={styles.sortSelect}
        name={name}
        hasError={false}
        value={sortSelected?.name ?? undefined}
        options={options}
        onChange={(e: { target: { value: SortKeys } }) => {
          setSortSelected({
            name: e.target.value,
            order: "asc",
          });
        }}
      />
    </UIInputContainer>
  );
}
