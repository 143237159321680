import { ReactNode } from "react";
import styles from "./ClickableCard.module.scss";

type ClickableCardProps = {
  onClick: () => void;
  children: ReactNode;
};
export function ClickableCard({ children, onClick }: ClickableCardProps) {
  return (
    <button onClick={onClick} className={styles.card}>
      {children}
    </button>
  );
}
