import { GatewayInfoSocieteOrias } from "@conformite/gateway";
import { EntrepriseSousCategoriesActiviteForm } from "@src/societes/form/EntrepriseSousCategoriesActiviteForm";
import {
  ParcoursEntrepriseStepComponentProps,
  ParcoursEntrepriseStepEnum,
} from "@src/societes/parcours/ParcoursEntreprise";
import {
  ID_FORM_ENTREPRISE,
  ParcoursEntrepriseActions,
} from "@src/societes/parcours/ParcoursEntrepriseActions";
import { ParcoursEntrepriseTemplate } from "@src/societes/parcours/ParcoursEntrepriseTemplate";

export function EntrepriseSousCategoriesActiviteStep({
  data,
  addData,
  changeStep,
}: ParcoursEntrepriseStepComponentProps) {
  const needCOBSP =
    data.categoriesOrias?.includes(GatewayInfoSocieteOrias.Categorie.COBSP) ??
    false;
  const needMIOBSP =
    data.categoriesOrias?.includes(GatewayInfoSocieteOrias.Categorie.MIOBSP) ??
    false;

  return (
    <ParcoursEntrepriseTemplate
      title="Informations sur l'activité"
      actions={
        <ParcoursEntrepriseActions
          onPrevious={() =>
            changeStep(ParcoursEntrepriseStepEnum.INFO_ACTIVITE)
          }
        />
      }
    >
      <EntrepriseSousCategoriesActiviteForm
        onSubmit={({ sousCategoriesCOBSP, sousCategoriesMIOBSP }) => {
          addData({
            sousCategorieCobsp: sousCategoriesCOBSP,
            sousCategorieMiobsp: sousCategoriesMIOBSP,
          });
          changeStep(ParcoursEntrepriseStepEnum.ORGANISTATION_PROFESSIONNELLE);
        }}
        defaultValues={{
          needCOBSP,
          needMIOBSP,
          sousCategoriesCOBSP: needCOBSP ? data.sousCategorieCobsp || [] : [],
          sousCategoriesMIOBSP: needMIOBSP
            ? data.sousCategorieMiobsp || []
            : [],
        }}
        formId={ID_FORM_ENTREPRISE}
      />
    </ParcoursEntrepriseTemplate>
  );
}
