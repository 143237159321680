import { useEffect, useState } from "react";
import { GatewayPaymentApi } from "@src/api/payment.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import {
  Button,
  Card,
  FormField,
  FormGrid,
  FormRow,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { Link } from "@src/components/Link/Link";
import { SkeletonCard } from "@src/components/Skeletons/card/SkeletonCard";
import { ParametrePageTemplate } from "../ParametrePageTemplate";

export function Factures() {
  const [urlPortalSession, setUrlPortalSession] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    GatewayPaymentApi.getBillingPortalSession()
      .then((resp) => {
        setUrlPortalSession(resp);
        setIsLoading(false);
      })
      .catch(
        handleGatewayError({
          onUnhandled: (error) => {
            console.error(error);
            displayErrorToast(
              "Une erreur est survenue lors de la récupération de vos factures."
            );
          },
        })
      );
  }, []);

  return (
    <ParametrePageTemplate title="Mes factures">
      {isLoading && !urlPortalSession && <SkeletonCard />}
      {!isLoading && urlPortalSession && (
        <Card>
          <FormGrid>
            <FormRow>
              <FormField>
                <p>
                  Pour accéder à vos factures et reçus, cliquez sur le lien
                  ci-dessous. Vous serez redirigé sur votre espace facturation
                  de Stripe.
                </p>
              </FormField>
            </FormRow>
            <FormRow>
              <FormField>
                <Link to={urlPortalSession} target="_blank">
                  <Button>Voir mes factures</Button>
                </Link>
              </FormField>
            </FormRow>
          </FormGrid>
        </Card>
      )}
    </ParametrePageTemplate>
  );
}
