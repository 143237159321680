import {
  Button,
  Card,
  FormField,
  FormRow,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  GatewayAdhesion,
  GatewayEntreprise,
  GatewayPaiement,
} from "@conformite/gateway";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  InputButtonGroup,
  InputCheckbox,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { toEuro } from "@src/helper/currency.helper";
import {
  AdhesionStepEnum,
  ParcoursAdhesionStepComponentProps,
} from "@src/adhesion/ParcoursAdhesion.definition";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { GatewayPaymentApi } from "@src/api/payment.api";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { usePaymentButtonLabel } from "@src/societes/form/EntreprisePaymentForm";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import { ParcoursAdhesionNextAction } from "@src/adhesion/AdhesionActions/ParcoursAdhesionNextAction";
import { useOnce } from "@src/hooks/useOnce";
import { AdhesionData } from "@src/adhesion/AdhesionData/adhesionData";
import { useNavigate } from "react-router-dom";
import { LoadingPage } from "@src/components/LoadingPage/LoadingPage";
import { ReactComponent as Loader } from "@src/assets/loader.svg";
import { ReactComponent as ArrowRight } from "@src/assets/icons/arrow-right.svg";
import {
  LocalStorageKeys,
  localStorageHelper,
} from "@src/helper/localStorage.helper";
import style from "./AdhesionPaiementPage.module.scss";
import { AdhesionPaiementStripeForm } from "./AdhesionPaiementStripeForm";
import { callCreateIntentionPaiementAdhesion } from "../../../payment/utils/createIntentionPaiement";
import {
  getCreateEntrepriseParams,
  storeEntrepriseCreationRequest,
} from "../../../payment/utils/createEntreprises";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC);

export function getPaiementMethodOptions(amount: number) {
  if (amount > 10000) {
    return [
      {
        label: "Carte bancaire",
        value: GatewayPaiement.PaymentMethod.CARD,
      },
      {
        label: "Virement bancaire",
        value: GatewayPaiement.PaymentMethod.TRANSFER,
      },
    ];
  }
  return [
    {
      label: "Carte bancaire",
      value: GatewayPaiement.PaymentMethod.CARD,
    },
    {
      label: "Prélévement SEPA",
      value: GatewayPaiement.PaymentMethod.SEPA,
    },
    {
      label: "Virement bancaire",
      value: GatewayPaiement.PaymentMethod.TRANSFER,
    },
  ];
}

function useIntention(data: AdhesionData) {
  const [paiementIntention, setPaiementIntention] = useState<
    GatewayAdhesion.CreateIntentionPaimentResponseType | undefined
  >();

  const navigate = useNavigate();

  useOnce(() => {
    callCreateIntentionPaiementAdhesion(data)
      .then((response) => {
        if (!response.paiement.intentId) {
          const createEntrepriseParams = getCreateEntrepriseParams(
            response,
            data
          );
          storeEntrepriseCreationRequest(
            data.typeEntreprise.type as GatewayEntreprise.TypeAdhesionActive,
            createEntrepriseParams
          );
          navigate("/adhesion/creation-entreprises", { replace: true });
        } else {
          setPaiementIntention(response);
        }
      })
      .catch(
        handleGatewayError({
          onUnhandled: (err) => {
            console.error(err);
            displayErrorToast("Une erreur est survenue");
          },
        })
      );
  });
  return paiementIntention;
}

export function AdhesionPaiementPage({
  data,
  changeStep,
}: ParcoursAdhesionStepComponentProps) {
  const [isPaiementSubmitting, setIsPaiementSubmitting] = useState(false);
  const isGroup = useMemo(
    () =>
      data.typeEntreprise.type !==
      GatewayEntreprise.TypeAdhesion.SOCIETE_INDIVIDUELLE,
    [data.typeEntreprise]
  );

  const paiementIntention = useIntention(data);

  const options = useMemo<StripeElementsOptions>(
    () => ({ clientSecret: paiementIntention?.paiement.clientSecret }),
    [paiementIntention]
  );

  const [key, setKey] = useState(0);

  const schema = useMemo(
    () =>
      zod.object({
        sengageEnRepresentant: zod
          .boolean()
          .optional()
          .refine((val) => {
            return !isGroup || val === true;
          }, "Vous devez cocher cette case pour continuer"),
        modePrelevement: zod.nativeEnum(GatewayPaiement.PaymentMethod),
      }),
    [isGroup]
  );

  const formConfig = useFormConfig<typeof schema, AdhesionPaiementFormValues>({
    schema,
    defaultValues: { modePrelevement: GatewayPaiement.PaymentMethod.CARD },
  });

  type AdhesionPaiementFormValues = zod.infer<typeof schema>;

  const modePrelevementSelectionne = formConfig.watch("modePrelevement");

  const updateinvoice = useCallback(
    async (modePrelevement: GatewayPaiement.PaymentMethod) => {
      if (!paiementIntention?.paiement.invoiceId) return;
      try {
        await GatewayPaymentApi.updateInvoice(
          paiementIntention.paiement.invoiceId,
          {
            paymentMethod: modePrelevement,
          }
        );
        setKey((prev) => prev + 1);
      } catch (error) {
        handleGatewayError({
          onUnhandled: () => displayErrorToast("Une erreur est survenue"),
        })(error);
      }
    },
    [paiementIntention]
  );

  useEffect(() => {
    localStorageHelper.setItem(
      LocalStorageKeys.CREATION_ENTREPRISES_TYPE_PAIEMENT,
      modePrelevementSelectionne === GatewayPaiement.PaymentMethod.CARD
        ? "CB"
        : "AUTRES"
    );
    if (modePrelevementSelectionne !== GatewayPaiement.PaymentMethod.TRANSFER) {
      updateinvoice(modePrelevementSelectionne).catch(console.error);
    }
  }, [modePrelevementSelectionne, updateinvoice]);

  const nextLabel = usePaymentButtonLabel(modePrelevementSelectionne);

  if (!paiementIntention?.paiement.intentId) return <LoadingPage />;

  const createEntrepriseParams = getCreateEntrepriseParams(
    paiementIntention,
    data
  );

  return (
    <ParcoursFullPageTemplate
      title="Paiement de l'adhésion"
      actions={
        <ParcoursAdhesionNextAction
          type="submit"
          disabled={isPaiementSubmitting}
          icon={isPaiementSubmitting ? <Loader /> : undefined}
        >
          {nextLabel}
        </ParcoursAdhesionNextAction>
      }
    >
      <FormRow>
        <FormField>
          <Card
            variant="accent"
            className={style.adhesionPaiementPage__cardPricing}
          >
            <h4>
              <b>
                {`${
                  isGroup
                    ? "Le montant total des adhésions"
                    : "Le montant de votre adhésion"
                } pour l'année ${new Date().getFullYear()} s'élève à `}
                <span className={style.adhesionPaiementPage__labelPricing}>
                  {paiementIntention.paiement.montant
                    ? toEuro(paiementIntention.paiement.montant, {
                        hideUselessFractionDigits: true,
                      })
                    : "-"}
                </span>
                {paiementIntention.paiement.subtotal !==
                  paiementIntention.paiement.montant && (
                  <span
                    className={
                      style.adhesionPaiementPage__labelPricingWithoutCoupon
                    }
                  >
                    {paiementIntention.paiement.subtotal
                      ? toEuro(paiementIntention.paiement.subtotal, {
                          hideUselessFractionDigits: true,
                        })
                      : "-"}
                  </span>
                )}
              </b>
            </h4>
            <Button
              icon={<ArrowRight />}
              variant="text"
              onClick={() => changeStep(AdhesionStepEnum.CODE_REDUCTION)}
            >
              Renseigner un code
            </Button>
          </Card>
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          {options.clientSecret && paiementIntention.paiement.intentId && (
            <Elements key={key} stripe={stripePromise} options={options}>
              <AdhesionPaiementStripeForm<AdhesionPaiementFormValues>
                setIsPaiementSubmitting={setIsPaiementSubmitting}
                entrepriseCreationParams={createEntrepriseParams}
                typeAdhesion={
                  data.typeEntreprise
                    .type as GatewayEntreprise.TypeAdhesionActive
                }
                formConfig={formConfig}
                modePrelevementSelectionne={modePrelevementSelectionne}
                formContent={
                  <>
                    {isGroup && (
                      <FormRow>
                        <FormField>
                          <InputCheckbox<AdhesionPaiementFormValues>
                            name="sengageEnRepresentant"
                            label="Je m'engage en tant que représentant des sociétés de ce groupe."
                          />
                        </FormField>
                      </FormRow>
                    )}

                    <FormRow>
                      <FormField>
                        <InputButtonGroup<
                          AdhesionPaiementFormValues,
                          false,
                          GatewayPaiement.PaymentMethod
                        >
                          name="modePrelevement"
                          variant="outline-pop"
                          options={getPaiementMethodOptions(
                            paiementIntention.paiement.montant
                          )}
                        />
                      </FormField>
                    </FormRow>

                    {modePrelevementSelectionne ===
                      GatewayPaiement.PaymentMethod.TRANSFER && (
                      <FormRow>
                        <FormField>
                          <InfoCard type="warning">
                            Le paiement par virement nécessite une action
                            manuelle de notre part, ce qui rallonge le délai de
                            traitement de votre dossier.
                          </InfoCard>
                        </FormField>
                      </FormRow>
                    )}
                    <FormRow>
                      <FormField>
                        <hr className={style.adhesionPaiementForm__separator} />
                      </FormField>
                    </FormRow>
                  </>
                }
              />
            </Elements>
          )}
        </FormField>
      </FormRow>
    </ParcoursFullPageTemplate>
  );
}
