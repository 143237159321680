import {
  Button,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { useEffect, useState } from "react";
import { GatewayPaiement } from "@conformite/gateway";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { Header } from "@src/components/Header/Header";
import { GatewayPaymentApi } from "@src/api/payment.api";
import { Link } from "@src/components/Link/Link";
import { PaymentRemerciements } from "@src/societes/form/EntreprisePayment/EntreprisePaymentRemerciements";
import { useStoreRefresh } from "@src/store/store.refresh";
import { useMe } from "@src/store/store.me";
import { useMillesime } from "@src/store/store.millesime";
import { retrieveEntrepriseCreationRequest } from "@src/payment/utils/createEntreprises";

function useSetMillesimeToLastYear() {
  const { me } = useMe();
  const { selected, setSelected } = useMillesime();
  useEffect(() => {
    if (me?.entreprisePrincipale.millesimeActuel !== selected) {
      setSelected(me?.entreprisePrincipale.millesimeActuel);
    }
  }, [me, selected, setSelected]);
}

export function RenouvellementRemerciements() {
  const {
    creationEntrepriseParams: [invoiceId],
  } = retrieveEntrepriseCreationRequest();
  const [paymentInfos, setPaymentInfos] =
    useState<GatewayPaiement.InvoiceInfosResponseType>();
  const refresh = useStoreRefresh();

  useEffect(() => {
    if (invoiceId) {
      GatewayPaymentApi.getInvoiceInformations(invoiceId)
        .then(setPaymentInfos)
        .catch((e) => {
          displayErrorToast(
            "Impossible de récupérer les informations de paiement"
          );
          console.error(e);
        })
        .finally(refresh);
    } else {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId]);

  useSetMillesimeToLastYear();

  return (
    <div>
      <Header />
      <MaxWidthContent size="medium">
        <PaymentRemerciements
          paymentInfos={paymentInfos}
          informations="Vous pouvez continuer d'utiliser l'application"
          title="Renouvellement finalisé"
          action={
            <Link to="/">
              <Button>Revenir sur l&apos;application</Button>
            </Link>
          }
        />
      </MaxWidthContent>
    </div>
  );
}
