export class CustomError<T extends string> extends Error {
  name: T;

  constructor(name: T, message?: string, cause?: unknown) {
    super(message, {
      cause,
    });
    this.name = name;
  }
}
