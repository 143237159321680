import {
  GatewayAuthentication,
  GatewayConformite,
  GatewayContact,
  GatewayEntreprise,
} from "@conformite/gateway";
import jwt from "jwt-decode";
import { useCallback, useMemo } from "react";
import { useMe } from "@src/store/store.me";
import { useSession } from "./UserSessionContext";

export type Jwt = {
  "cognito:groups": GatewayAuthentication.Role[];
};

const useCognitoAccess = () => {
  const { idToken } = useSession();
  const groups = useMemo(() => {
    if (!idToken) return [];
    const user = jwt<Jwt>(idToken);
    return user["cognito:groups"];
  }, [idToken]);

  const hasAllowedCognitoRole = (...roles: GatewayAuthentication.Role[]) =>
    roles.some((role) => groups.includes(role));

  return { hasAllowedCognitoRole };
};

const useRoleAccess = () => {
  const { me } = useMe();

  const hasAllowedRole = useCallback(
    (...requiredRoles: GatewayContact.ContactRoleUtilisateur[]) => {
      return !!me && requiredRoles.includes(me.contact.role);
    },
    [me]
  );

  return { hasAllowedRole };
};

const useAxesConformiteAccess = () => {
  const { me } = useMe();

  const hasAllowedAxeConformite = useCallback(
    (requiredAxe: GatewayConformite.AxeConformite) => {
      if (!me) return false;
      if (
        me.contact.role !==
        GatewayContact.ContactRoleUtilisateur.UTILISATEUR_SIMPLE
      )
        return true;
      return me.contact.axesConformite.includes(requiredAxe);
    },
    [me]
  );

  return { hasAllowedAxeConformite };
};

export const useHasForbiddenTypeAdhesion = () => {
  const { me } = useMe();

  const hasForbiddenTypeAdhesion = useCallback(
    (forbiddenTypes: GatewayEntreprise.TypeAdhesionActive[]) => {
      if (!me) return false;
      if (
        me.entreprisePrincipale.typeAdhesion ===
        GatewayEntreprise.TypeAdhesion.EN_COURS
      )
        return true;
      return forbiddenTypes.includes(me.entreprisePrincipale.typeAdhesion);
    },
    [me]
  );

  return hasForbiddenTypeAdhesion;
};

export const useIsAllowed = () => {
  const { hasAllowedCognitoRole } = useCognitoAccess();
  const { hasAllowedRole } = useRoleAccess();
  const { hasAllowedAxeConformite } = useAxesConformiteAccess();

  return { hasAllowedCognitoRole, hasAllowedRole, hasAllowedAxeConformite };
};
