import { EtatEntiteHubspot } from '../../common/hubspot/hubspot.definition';
import {
  ContactCivilite as ContactCiviliteService,
  ContactFonction as ContactFonctionService,
  ContactRole,
  ContactRole as ContactRoleUtilisateurService,
} from '../service/contact.definition';
import { EntrepriseStatus } from '../../entreprise/service/entreprise.definition';
import { AxeConformite } from '../../conformite/common/controller/conformite.definition';

export const contactControllerURL = '/contact';

export type ContactFonction = ContactFonctionService;
export const ContactFonction = ContactFonctionService;

export type ContactCivilite = ContactCiviliteService;
export const ContactCivilite = ContactCiviliteService;

export type ContactRoleUtilisateur = ContactRoleUtilisateurService;
export const ContactRoleUtilisateur = ContactRoleUtilisateurService;

export interface ContactWithAssociationsResponseType {
  id: string;
  civilite: ContactCivilite;
  nom: string;
  prenom: string;
  telephone?: string;
  fonction: ContactFonction;
  email: string;
  etat: EntrepriseStatus;
  entreprisePrincipaleId: string;
  role: ContactRoleUtilisateur;
  consentement: boolean;
  veutRecevoirNewsletter?: boolean;
  entreprisesAssociesIds: string[];
  axesConformite: AxeConformite[];
}
export type EtatContact = EtatEntiteHubspot;
export const EtatContact = EtatEntiteHubspot;

export interface UpdateContactRequestType {
  civilite: ContactCivilite;
  nom: string;
  prenom: string;
  telephone?: string;
  fonction: ContactFonction;
  role: ContactRole;
  entreprises: string[];
  axesConformite: AxeConformite[];
}
