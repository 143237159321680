import {
  Card,
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { fonctionOptionsRecord } from "@src/societes/form/EntrepriseInformationsPersonnellesForm";
import { ResumeBlockComponentProps } from "./RenouvellementResumeEntreprisePage";

export function ResumeInfoPersonnelle({ data }: ResumeBlockComponentProps) {
  return (
    <Card variant="accent">
      <FormRow>
        <FormField>
          Civilité : <b>{data.resume.contact.civilite}</b>
        </FormField>
        <FormField>
          Nom : <b>{data.resume.contact.nom}</b>
        </FormField>
      </FormRow>

      <FormRow>
        <FormField>
          Prénom : <b>{data.resume.contact.prenom}</b>
        </FormField>
        <FormField>
          Téléphone : <b>{data.resume.contact.telephone}</b>
        </FormField>
      </FormRow>

      <FormRow>
        <FormField>
          Fonction :{" "}
          <b>{fonctionOptionsRecord[data.resume.contact.fonction]}</b>
        </FormField>
      </FormRow>
    </Card>
  );
}
