import { ReactComponent as Download } from "@src/assets/icons/download.svg";
import { PrismicRichText } from "@prismicio/react";
import { SliceFactoryProps } from "../../SliceManager";
import styles from "./SliceDocuments.module.scss";

export function SliceDocuments({
  slice,
}: SliceFactoryProps<"doc_a_telecharger">) {
  return (
    <div>
      <PrismicRichText field={slice.primary.titre} />
      <div className={styles.list}>
        {slice.items.map((item) => (
          <a
            href={item.document.url}
            download
            target="_blank"
            rel="noreferrer"
            className={styles.item}
          >
            <div className={styles.col}>
              <span className={styles.nom}>{item.nom}</span>
              <span className={styles.desc}>{item.description}</span>
            </div>
            <Download />
          </a>
        ))}
      </div>
    </div>
  );
}
