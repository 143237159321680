import {
  Button,
  FormField,
  FormGrid,
  FormRow,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { useEffect, useState } from "react";
import { GatewayMe } from "@conformite/gateway";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { ReactComponent as Download } from "@src/assets/icons/download.svg";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { GatewayMeApi } from "@src/api/me.api";

export function AdhesionVirementPage() {
  const [me, setMe] = useState<GatewayMe.GetMeResponse>();

  useEffect(() => {
    GatewayMeApi.getMe()
      .then(setMe)
      .catch((error) =>
        handleGatewayError({
          onUnhandled: () => {
            displayErrorToast("Une erreur est survenue");
            console.error(error);
          },
        })(error)
      );
  }, []);

  if (!me) return null;
  return (
    <FormGrid>
      <FormRow>
        <FormField>
          <h2>Paiement par virement</h2>
        </FormField>
      </FormRow>

      <FormRow>
        <FormField>
          <InfoCard>
            Indiquez le numéro SIREN dans la référence du virement :{" "}
            <b>{me.entreprisePrincipale.siren}</b>
          </InfoCard>
        </FormField>
      </FormRow>

      <FormRow>
        <FormField>
          <Button icon={<Download />} variant="text">
            <a
              href="https://endya-public.s3.eu-west-3.amazonaws.com/rib_endya.pdf"
              target="_blank"
              rel="noreferrer"
              download
              style={{ textDecoration: "none" }}
            >
              Télécharger le RIB
            </a>
          </Button>
        </FormField>
      </FormRow>
    </FormGrid>
  );
}
