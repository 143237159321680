import { Button, Card } from "@lya-protect/lya-protect-form-library/dist/UI";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ReactComponent as Loader } from "@src/assets/loader.svg";
import { ReactComponent as XCircle } from "@src/assets/icons/x-circle.svg";
import { ReactComponent as CheckCircle } from "@src/assets/icons/check-circle-outline.svg";
import classNames from "classnames";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { GatewayOcrApi } from "@src/api/ocr.api";
import styles from "./Document.module.scss";

type OcrType = "garantieFinanciere" | "rcPro";

function getValidParams(
  siren?: string,
  millesime?: string,
  categorie?: string,
  type?: string,
  pathname?: string | null
) {
  const acceptedCategoriesByType = {
    garantieFinanciere: ["ias", "iobsp"],
    rcPro: ["coa", "mia", "cobsp", "miobsp"],
  };
  if (
    !siren ||
    !millesime ||
    !/^\d+$/.test(millesime) ||
    !type ||
    !["garantieFinanciere", "rcPro"].includes(type) ||
    !categorie ||
    !acceptedCategoriesByType[type as "garantieFinanciere" | "rcPro"].includes(
      categorie
    ) ||
    !pathname
  ) {
    return null;
  }
  return {
    siren,
    millesime: Number(millesime),
    categorie,
    type: type as OcrType,
    pathname,
  };
}

export function OcrDocumentRefuser() {
  const [query] = useSearchParams();
  const pathname = query.get("pathname");
  const { siren, millesime, categorie, type } = useParams<{
    siren: string;
    millesime: string;
    categorie: string;
    type: string;
  }>();

  const validParams = getValidParams(
    siren,
    millesime,
    categorie,
    type,
    pathname
  );

  const [status, setStatus] = useState<"idle" | "error" | "success">("idle");
  useEffect(() => {
    if (!validParams) return;

    GatewayOcrApi.refuseDocument(
      validParams.siren,
      validParams.millesime,
      validParams.categorie,
      validParams.type,
      validParams.pathname
    )
      .then(() => {
        setStatus("success");
      })
      .catch((error) => {
        console.error(error);
        handleGatewayError({
          onUnhandled: () => {
            setStatus("error");
          },
        })(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!validParams) {
    return (
      <Card className={styles.card}>
        <>
          <div className={styles.header}>
            <XCircle className={styles.icon} />
            <h1>OUPS</h1>
          </div>
          <p>
            Les paramètres de validations sont invalides, vérifiez l&apos;url
          </p>
        </>
      </Card>
    );
  }

  if (status === "idle") {
    return <Loader />;
  }
  return (
    <Card className={styles.card}>
      {status === "error" && (
        <>
          <div className={styles.header}>
            <XCircle className={styles.icon} />
            <h1>OUPS</h1>
          </div>
          <p>une erreur est survenue lors du refus du document.</p>
        </>
      )}
      {status === "success" && (
        <>
          <div className={styles.header}>
            <CheckCircle
              className={classNames(styles.icon, styles.iconSuccess)}
            />
            <h1>Le document a été refusé avec succès</h1>
          </div>
          <p>
            Le refus du document a été prise en compte. Vous pouvez fermer cette
            page
          </p>
        </>
      )}
      <Button
        onClick={() => {
          window.close();
        }}
      >
        Fermer la page
      </Button>
    </Card>
  );
}
