import {
  Button,
  FormField,
  FormGrid,
  FormNotice,
  FormRow,
  FormTitle,
  FormMesh,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import {
  InputDate,
  InputNumber,
  InputText,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  GatewayFormationContinue,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  useFieldArray,
} from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import { isNil } from "lodash";
import { IconStatus } from "@src/components/IconStatus/IconStatus";
import { ReactComponent as PlusCircle } from "@src/assets/icons/plus-circle.svg";
import { ReactComponent as TrashIcon } from "@src/assets/icons/trash.svg";
import { GatewayFormationContinueApi } from "@src/api/conformite/formationContinue.api";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { useAssujetti } from "@src/store/store.assujetti";
import { isBeforeToday, wasLessThanOneYearAgo } from "@src/helper/date.helper";
import { isToday } from "date-fns";
import { AssujettiConformiteData } from "../../../AssujettiParcoursConformite";
import styles from "./ConformiteFormFormationIOBSP.module.scss";
import { NextVerificatioCard } from "../../../Components/NextVerificationCard/NextVerificationCard";

export const formationContinueIobspSchema = zod.object({
  id: zod.string().optional(),
  dateDernierVerification: zod
    .date()
    .refine((value) => {
      if (!value) return false;
      return wasLessThanOneYearAgo(value);
    }, "La dernière vérification date d'il y a plus d'un an. Elle n'est donc pas valable. Merci de vérifier à nouveau")
    .refine((value) => {
      if (!value) return false;
      return isBeforeToday(value) || isToday(value);
    }, "La date doit être inférieure ou égale à la date du jour."),
  heures: zod
    .object({
      sousActivitesFinancieres: zod.array(zod.string()),
      heuresFormationCreditImmobilier: zod.number().nullish(),
      heuresFormationCreditConsommation: zod.number().nullish(),
      heuresFormationCreditServicePaiement: zod.number().nullish(),
      heuresFormationCreditRegroupementCredits: zod.number().nullish(),
      heuresFormationCreditPretViagerHypotecaire: zod.number().nullish(),
    })
    .superRefine((data, ctx) => {
      if (
        data.sousActivitesFinancieres.includes(
          GatewayInfoSocieteOrias.ActiviteBancaire.CREDIT_IMMOBILIER
        )
      ) {
        if (isNil(data.heuresFormationCreditImmobilier)) {
          ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            path: ["heuresFormationCreditImmobilier"],
            message: "Champ requis",
          });
        } else if (data.heuresFormationCreditImmobilier < 7) {
          ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            path: ["heuresFormationCreditImmobilier"],
            message: "Il faut réaliser au minimum 7 heures de formations",
          });
        }
      }
      if (
        data.sousActivitesFinancieres.includes(
          GatewayInfoSocieteOrias.ActiviteBancaire.CREDIT_CONSOMMATION
        ) &&
        isNil(data.heuresFormationCreditConsommation)
      ) {
        ctx.addIssue({
          code: zod.ZodIssueCode.custom,
          path: ["heuresFormationCreditConsommation"],
          message: "Champ requis",
        });
      }
      if (
        data.sousActivitesFinancieres.includes(
          GatewayInfoSocieteOrias.ActiviteBancaire.SERVICE_PAIEMENT
        ) &&
        isNil(data.heuresFormationCreditServicePaiement)
      ) {
        ctx.addIssue({
          code: zod.ZodIssueCode.custom,
          path: ["heuresFormationCreditServicePaiement"],
          message: "Champ requis",
        });
      }
      if (
        data.sousActivitesFinancieres.includes(
          GatewayInfoSocieteOrias.ActiviteBancaire.REGROUPEMENT_CREDITS
        ) &&
        isNil(data.heuresFormationCreditRegroupementCredits)
      ) {
        ctx.addIssue({
          code: zod.ZodIssueCode.custom,
          path: ["heuresFormationCreditRegroupementCredits"],
          message: "Champ requis",
        });
      }
      if (
        data.sousActivitesFinancieres.includes(
          GatewayInfoSocieteOrias.ActiviteBancaire.PRET_VIAGER_HYPOTECAIRE
        ) &&
        isNil(data.heuresFormationCreditPretViagerHypotecaire)
      ) {
        ctx.addIssue({
          code: zod.ZodIssueCode.custom,
          path: ["heuresFormationCreditPretViagerHypotecaire"],
          message: "Champ requis",
        });
      }
    }),
  formations: zod.array(
    zod.object({
      theme: zod.string().nullable(),
      nombreHeures: zod.number().nullable(),
    })
  ),
});

export type FormationContinueIobspSchemaFormValues = zod.infer<
  typeof formationContinueIobspSchema
>;

function getDefaultValues(
  formData: GatewayFormationContinue.FormationContinueIobsp | null,
  sousActivitesFinancieres: GatewayInfoSocieteOrias.ActiviteBancaire[]
) {
  return {
    heures: {
      sousActivitesFinancieres,
      heuresFormationCreditConsommation:
        formData?.heuresFormationCreditConsommation,
      heuresFormationCreditImmobilier:
        formData?.heuresFormationCreditImmobilier,
      heuresFormationCreditPretViagerHypotecaire:
        formData?.heuresFormationCreditPretViagerHypotecaire,
      heuresFormationCreditRegroupementCredits:
        formData?.heuresFormationCreditRegroupementCredits,
      heuresFormationCreditServicePaiement:
        formData?.heuresFormationCreditServicePaiement,
    },
    id: formData?.id,
    dateDernierVerification: formData?.dateDernierVerification ?? new Date(),
    formations:
      formData?.formations && formData?.formations?.length > 0
        ? formData?.formations
        : [{ theme: null, nombreHeures: null }],
  } as FormationContinueIobspSchemaFormValues;
}

function formatFormValuesToApiPayload(
  values: FormationContinueIobspSchemaFormValues
): GatewayFormationContinue.FormationContinueIobsp {
  return {
    dateDernierVerification: values.dateDernierVerification ?? null,
    heuresFormationCreditConsommation:
      values.heures.heuresFormationCreditConsommation ?? null,
    heuresFormationCreditImmobilier:
      values.heures.heuresFormationCreditImmobilier ?? null,
    heuresFormationCreditPretViagerHypotecaire:
      values.heures.heuresFormationCreditPretViagerHypotecaire ?? null,
    heuresFormationCreditRegroupementCredits:
      values.heures.heuresFormationCreditRegroupementCredits ?? null,
    heuresFormationCreditServicePaiement:
      values.heures.heuresFormationCreditServicePaiement ?? null,
    formations: values.formations.map((formation) => ({
      nombreHeures: formation.nombreHeures ?? null,
      theme: formation.theme ?? null,
    })),
    id: values.id,
  };
}

export const useFormationContinueIobspForm = (
  formData: GatewayFormationContinue.FormationContinueIobsp | null,
  sousActivitesFinancieres: GatewayInfoSocieteOrias.ActiviteBancaire[]
) => {
  const formConfig = useFormConfig<
    typeof formationContinueIobspSchema,
    FormationContinueIobspSchemaFormValues
  >({
    schema: formationContinueIobspSchema,
    defaultValues: getDefaultValues(formData, sousActivitesFinancieres),
  });
  const { fields, append, remove } =
    useFieldArray<FormationContinueIobspSchemaFormValues>({
      control: formConfig.control,
      name: "formations",
    });
  const lastVerifiedAt = formConfig.watch("dateDernierVerification");
  return { formConfig, fields, append, remove, lastVerifiedAt };
};

export const useFormationContinueIobsp = (
  formData: GatewayFormationContinue.FormationContinueIobsp | null,
  data: AssujettiConformiteData,
  setFormData: (data: GatewayFormationContinue.FormationContinueIobsp) => void,
  sousActivitesFinancieres: GatewayInfoSocieteOrias.ActiviteBancaire[]
) => {
  const { updateAvancement } = useAssujetti();

  const onSubmit = async (values: FormationContinueIobspSchemaFormValues) => {
    const payload = formatFormValuesToApiPayload(values);

    const avancement = await GatewayFormationContinueApi.saveIOBSP(
      data.assujetti.id,
      data.assujetti.millesime,
      payload
    );
    updateAvancement(data.assujetti.millesime, data.assujetti.id, avancement);
    setFormData(payload);
  };

  const { formConfig, fields, append, remove, lastVerifiedAt } =
    useFormationContinueIobspForm(formData, sousActivitesFinancieres);

  return { formConfig, fields, append, remove, onSubmit, lastVerifiedAt };
};

type ConformiteFormFormationIobspContent = {
  hideTitle?: boolean;
  data: AssujettiConformiteData;
  lastVerifiedAt: Date;
  fields: FieldArrayWithId<
    FormationContinueIobspSchemaFormValues,
    "formations"
  >[];
  append: UseFieldArrayAppend<
    FormationContinueIobspSchemaFormValues,
    "formations"
  >;
  remove: UseFieldArrayRemove;
  sousActivitesFinancieres: GatewayInfoSocieteOrias.ActiviteBancaire[];
};
export function ConformiteFormFormationIobspContent({
  hideTitle,
  data,
  append,
  remove,
  lastVerifiedAt,
  fields,
  sousActivitesFinancieres,
}: ConformiteFormFormationIobspContent) {
  return (
    <FormGrid disableHorizontalGutter>
      {!hideTitle && (
        <FormTitle className={styles.header}>
          <h2 className={styles.title}>
            <b>Formation continue IOBSP</b> - {data.assujetti.surname}{" "}
            {data.assujetti.name}
          </h2>
          <IconStatus
            isValid={data.assujetti.avancement.formationContinueIobsp === 100}
          />
        </FormTitle>
      )}
      {sousActivitesFinancieres.includes(
        GatewayInfoSocieteOrias.ActiviteBancaire.CREDIT_IMMOBILIER
      ) && (
        <FormNotice>
          <FormRow>
            <InfoCard type="info" className={hideTitle ? styles.notice : ""}>
              La personne doit avoir suivi une formation de 7h en crédit
              immobilier pour 2023
            </InfoCard>
          </FormRow>
        </FormNotice>
      )}
      <FormRow>
        <FormField size="half">
          <InputDate<FormationContinueIobspSchemaFormValues>
            name="dateDernierVerification"
            label="Date de saisie"
          />
        </FormField>
      </FormRow>
      <FormMesh columns={2}>
        {sousActivitesFinancieres.includes(
          GatewayInfoSocieteOrias.ActiviteBancaire.CREDIT_IMMOBILIER
        ) && (
          <FormField>
            <InputNumber<FormationContinueIobspSchemaFormValues>
              name="heures.heuresFormationCreditImmobilier"
              label="Heures formation crédit immobilier"
            />
          </FormField>
        )}
        {sousActivitesFinancieres.includes(
          GatewayInfoSocieteOrias.ActiviteBancaire.CREDIT_CONSOMMATION
        ) && (
          <FormField>
            <InputNumber<FormationContinueIobspSchemaFormValues>
              name="heures.heuresFormationCreditConsommation"
              label="Heures formation crédit consommation"
            />
          </FormField>
        )}
        {sousActivitesFinancieres.includes(
          GatewayInfoSocieteOrias.ActiviteBancaire.SERVICE_PAIEMENT
        ) && (
          <FormField>
            <InputNumber<FormationContinueIobspSchemaFormValues>
              name="heures.heuresFormationCreditServicePaiement"
              label="Heures formation service de paiement"
            />
          </FormField>
        )}
        {sousActivitesFinancieres.includes(
          GatewayInfoSocieteOrias.ActiviteBancaire.REGROUPEMENT_CREDITS
        ) && (
          <FormField>
            <InputNumber<FormationContinueIobspSchemaFormValues>
              name="heures.heuresFormationCreditRegroupementCredits"
              label="Heures formation regroupement de crédits"
            />
          </FormField>
        )}
        {sousActivitesFinancieres.includes(
          GatewayInfoSocieteOrias.ActiviteBancaire.PRET_VIAGER_HYPOTECAIRE
        ) && (
          <FormField>
            <InputNumber<FormationContinueIobspSchemaFormValues>
              name="heures.heuresFormationCreditPretViagerHypotecaire"
              label="Heures formation prêt viager hypothécaires"
            />
          </FormField>
        )}
      </FormMesh>

      {fields.map(({ id }, index) => (
        <FormRow key={id}>
          <FormField size="half" takeFullSizeMobile={false}>
            <InputText name={`formations.${index}.theme`} label="Thème" />
          </FormField>
          <FormField
            size="half"
            takeFullSizeMobile={false}
            className={styles.hoursField}
          >
            <InputNumber
              name={`formations.${index}.nombreHeures`}
              label="Nombre d'heures"
            />
            {fields.length > 1 && (
              <Button
                type="button"
                onClick={() => remove(index)}
                className={styles.trashButton}
                variant="text"
                iconVariant="left"
                icon={<TrashIcon />}
              />
            )}
          </FormField>
        </FormRow>
      ))}
      <FormRow>
        <FormField>
          <Button
            variant="text"
            icon={<PlusCircle />}
            iconVariant="left"
            onClick={() =>
              append({
                nombreHeures: null,
                theme: null,
              } as unknown as FormationContinueIobspSchemaFormValues["formations"][0])
            }
          >
            Ajouter une formation supplémentaire
          </Button>
        </FormField>
      </FormRow>
      <NextVerificatioCard lastVerifiedAt={lastVerifiedAt} />
    </FormGrid>
  );
}
