import { GatewayMe } from "@conformite/gateway";
import { userSession } from "@src/session/UserSession";

export class GatewayMeApi {
  public static async getMe(): Promise<GatewayMe.GetMeResponse> {
    const response =
      await userSession.axiosInstance.get<GatewayMe.GetMeResponse>(
        GatewayMe.meControllerUrl
      );
    return response.data;
  }

  public static async update(
    payload: GatewayMe.UpdateMeRequestType
  ): Promise<GatewayMe.GetMeResponse> {
    const { data } =
      await userSession.axiosInstance.patch<GatewayMe.GetMeResponse>(
        GatewayMe.meControllerUrl,
        payload
      );
    return data;
  }
}
