import {
  FieldValues,
  UseFormReturn,
} from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import {
  Form,
  SubmitHandler,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { FormGrid } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactElement, ReactNode } from "react";
import { useOnce } from "@src/hooks/useOnce";
import { ReadonlyFieldSet } from "./ReadonlyFieldSet";

type FormParcoursConformiteProps<TFieldValues extends FieldValues> = {
  children: ReactNode;
  formConfig: UseFormReturn<TFieldValues>;
  onSubmit: SubmitHandler<TFieldValues>;
  renderActions: (submitIfValid: () => Promise<void>) => ReactElement;
  className?: string;
  id?: string;
  validateOnMount?: boolean;
  readonly?: boolean;
};

export function FormParcoursConformite<TFieldValues extends FieldValues>({
  children,
  formConfig,
  onSubmit,
  renderActions,
  className,
  id,
  readonly,
  validateOnMount = false,
}: FormParcoursConformiteProps<TFieldValues>) {
  const submitAnyway = async (): Promise<void> => {
    // submit ignoring validations
    await onSubmit(formConfig.getValues());
  };

  useOnce(() => {
    if (readonly || validateOnMount) {
      formConfig.trigger().catch(console.error);
    }
  });

  return (
    <Form
      formConfig={formConfig}
      onSubmit={onSubmit}
      className={className}
      id={id}
    >
      <FormGrid disableHorizontalGutter>
        <ReadonlyFieldSet readonly={readonly}>{children}</ReadonlyFieldSet>
        {renderActions(readonly ? () => Promise.resolve() : submitAnyway)}
      </FormGrid>
    </Form>
  );
}
