import { Button, Card } from "@lya-protect/lya-protect-form-library/dist/UI";
import { useDownloadAttestation } from "@src/societes/attestation/useDownloadAttestation";
import {
  GatewayAvancement,
  GatewayConformite,
  GatewayEntreprise,
} from "@conformite/gateway";
import { useMemo } from "react";
import { IconStatus } from "@src/components/IconStatus/IconStatus";
import { ReactComponent as Download } from "@src/assets/icons/download.svg";
import { useMillesime } from "@src/store/store.millesime";
import { getAxesConformiteEntrepriseByActivites } from "@src/societes/societe.helper";
import { TagConformite } from "@src/components/Tag/TagConformite";
import { useNavigate } from "react-router-dom";
import {
  ParcoursConformiteStepComponentProps,
  useParcoursConformite,
} from "../../ParcoursConformite";
import { ParcoursConformiteTemplate } from "../../ParcoursConformiteTemplate";
import style from "./ConformiteRecapitulatif.module.scss";

type DownloadAttestationCardProps = {
  millesime: number;
  entreprise: GatewayEntreprise.EntrepriseAControllerResponseType;
};
function DownloadAttestationCard({
  millesime,
  entreprise,
}: DownloadAttestationCardProps) {
  const { downloadAttestation, getIsAllowedToDownloadAttestation } =
    useDownloadAttestation();

  const canDownload = useMemo(() => {
    return getIsAllowedToDownloadAttestation(entreprise);
  }, [getIsAllowedToDownloadAttestation, entreprise]);

  return (
    <Card className={style.downloadAttestationCard}>
      {canDownload && (
        <>
          <div className={style.downloadAttestationCardTitle}>
            <IconStatus isValid={canDownload} />
            <h3>Conformité {millesime} valide</h3>
          </div>
          <Button icon={<Download />} onClick={downloadAttestation(entreprise)}>
            Télécharger l&apos;attestation
          </Button>
        </>
      )}
      {!canDownload && (
        <>
          <div className={style.downloadAttestationCardTitle}>
            <IconStatus isValid={canDownload} />
            <h3>Conformité {millesime} non valide</h3>
          </div>
          <p>
            Votre attestation sera disponible lorsque tous les axes seront
            valides.
          </p>
        </>
      )}
    </Card>
  );
}

const labelByAxeEntreprise: Record<
  GatewayConformite.AxeConformiteEntreprise,
  string
> = {
  [GatewayConformite.AxeConformiteEntreprise.RC_PRO_COA]: "RC COA",
  [GatewayConformite.AxeConformiteEntreprise.RC_PRO_MIA]: "RC MIA",
  [GatewayConformite.AxeConformiteEntreprise.RC_PRO_COBSP]: "RC COBSP",
  [GatewayConformite.AxeConformiteEntreprise.RC_PRO_MIOBSP]: "RC MIOBSP",
  [GatewayConformite.AxeConformiteEntreprise.GARANTIE_FINANCIERE_IAS]:
    "Garantie financière IAS",
  [GatewayConformite.AxeConformiteEntreprise.GARANTIE_FINANCIERE_IOBSP]:
    "Garantie financière IOBSP",
  [GatewayConformite.AxeConformiteEntreprise.DECLARATION_ACTIVITE_IAS]:
    "Déclaration d'activité IAS",
  [GatewayConformite.AxeConformiteEntreprise.DECLARATION_ACTIVITE_IOBSP]:
    "Déclaration d'activité IOBSP",
};

type KeyAvancement = keyof Omit<
  GatewayAvancement.AvancementSociete,
  "millesime" | "siren"
>;
const keyAxeByAxe: Record<
  GatewayConformite.AxeConformiteEntreprise,
  KeyAvancement
> = {
  [GatewayConformite.AxeConformiteEntreprise.RC_PRO_COA]: "rcProCoa",
  [GatewayConformite.AxeConformiteEntreprise.RC_PRO_MIA]: "rcProMia",
  [GatewayConformite.AxeConformiteEntreprise.RC_PRO_COBSP]: "rcProCobsp",
  [GatewayConformite.AxeConformiteEntreprise.RC_PRO_MIOBSP]: "rcProMiobsp",
  [GatewayConformite.AxeConformiteEntreprise.GARANTIE_FINANCIERE_IAS]:
    "garantieFinanciereIas",
  [GatewayConformite.AxeConformiteEntreprise.GARANTIE_FINANCIERE_IOBSP]:
    "garantieFinanciereIobsp",
  [GatewayConformite.AxeConformiteEntreprise.DECLARATION_ACTIVITE_IAS]:
    "declarationActiviteIas",
  [GatewayConformite.AxeConformiteEntreprise.DECLARATION_ACTIVITE_IOBSP]:
    "declarationActiviteIobsp",
};
export function ConformiteRecapitulatif({
  currentStep,
  changeStep,
}: ParcoursConformiteStepComponentProps) {
  const navigate = useNavigate();
  const { entreprise, parcoursStepByAxeConformite } = useParcoursConformite();
  const { selected: millesime } = useMillesime();

  const axesConformite = useMemo<
    {
      label: string;
      axe: GatewayConformite.AxeConformite;
      keyAvancement: KeyAvancement;
    }[]
  >(() => {
    if (!entreprise) return [];
    const axesEntreprise = getAxesConformiteEntrepriseByActivites(
      entreprise.categoriesActivites
    );

    return axesEntreprise.map((axe) => ({
      label:
        labelByAxeEntreprise[axe as GatewayConformite.AxeConformiteEntreprise],
      axe,
      keyAvancement:
        keyAxeByAxe[axe as GatewayConformite.AxeConformiteEntreprise],
    }));
  }, [entreprise]);

  if (!entreprise || !millesime) return <div />;
  return (
    <ParcoursConformiteTemplate
      currentStep={currentStep}
      changeStep={changeStep}
      actions={<Button onClick={() => navigate("/")}>Quitter</Button>}
      size="large"
      title="Récapitulatif de votre conformité"
    >
      <DownloadAttestationCard entreprise={entreprise} millesime={millesime} />
      <span className={style.subtitle}>Axes de conformité</span>
      <div className={style.conformites}>
        {axesConformite.map(({ label, axe, keyAvancement }) => {
          return (
            <Card className={style.cardConformite}>
              <h3>{label}</h3>
              <div className={style.cardConformiteRight}>
                <TagConformite
                  isConforme={entreprise.avancement[keyAvancement] === 100}
                  year={millesime}
                />

                <Button
                  variant="outline"
                  onClick={() => changeStep(parcoursStepByAxeConformite[axe])}
                >
                  Accéder
                </Button>
              </div>
            </Card>
          );
        })}
      </div>
    </ParcoursConformiteTemplate>
  );
}
