import {
  AssujettiRowImport,
  AssujettiRowImportAssujetti,
  AssujettiRowImportCapaciteProIAS,
  AssujettiRowImportCapaciteProIOBSP,
  AssujettiRowImportFormationContinueIAS,
  AssujettiRowImportFormationContinueIOBSP,
  AssujettiRowImportHonorabilite,
  AssujettiRowFormation,
  AssujettiRowImportResponse,
} from '../service/importExportAssujetti.definition';

export const importExportAssujettiControllerUrl = '/assujetti-import-export';

export type {
  AssujettiRowFormation,
  AssujettiRowImport,
  AssujettiRowImportAssujetti,
  AssujettiRowImportCapaciteProIAS,
  AssujettiRowImportCapaciteProIOBSP,
  AssujettiRowImportFormationContinueIAS,
  AssujettiRowImportFormationContinueIOBSP,
  AssujettiRowImportHonorabilite,
};

export type ImportAssujetiRequestType = {
  rows: {
    data: AssujettiRowImport;
    index: number;
  }[];
};

export type ImportAssujettiByEntrepriseRequestType = {
  siren: string;
};

export type ImportAssujetiResponseType = {
  failures: { message: string; rowIndex: number }[];
  success: AssujettiRowImportResponse[];
};

export type AdminImportAssujettiRow = {
  entreprisePrincipaleId: string;
} & AssujettiRowImport;

export type AdminImportAssujettiRequestType = {
  rows: AssujettiRowImport[];
};

export type ExportAssujettiResponseType = AssujettiRowImport[];
