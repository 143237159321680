import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Form,
  InputPassword,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  FormBloc,
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { PasswordCriterias } from "@src/components/PasswordCriterias/PasswordCriterias";
import { passwordWithConfirmationSchema } from "@src/user/Signup/password.definition";

const schema = zod.object({
  previousPassword: zod.string(),
  password: passwordWithConfirmationSchema,
});
export type UpdatePasswordFormValues = zod.infer<typeof schema>;

type UpdatePasswordFormProps = {
  onSubmit: (values: UpdatePasswordFormValues) => Promise<void>;
  formId: string;
  defaultValues?: UpdatePasswordFormValues;
};

export function UpdatePasswordForm({
  onSubmit,
  formId,
  defaultValues,
}: UpdatePasswordFormProps) {
  const formConfig = useFormConfig<typeof schema, UpdatePasswordFormValues>({
    schema,
    defaultValues,
  });

  const passwordValue = formConfig.watch("password.password");

  return (
    <Form formConfig={formConfig} onSubmit={onSubmit} id={formId}>
      <FormRow>
        <FormField>
          <InputPassword<UpdatePasswordFormValues>
            name="previousPassword"
            label="Ancien mot de passe"
            required
          />
        </FormField>
      </FormRow>

      <FormRow>
        <FormField>
          <InputPassword<UpdatePasswordFormValues>
            name="password.password"
            label="Nouveau mot de passe"
            required
          />
        </FormField>
      </FormRow>

      <FormBloc>
        <PasswordCriterias password={passwordValue} />
      </FormBloc>

      <FormRow>
        <FormField>
          <InputPassword<UpdatePasswordFormValues>
            name="password.confirm"
            label="Confirmer le nouveau mot de passe"
            required
          />
        </FormField>
      </FormRow>
    </Form>
  );
}
