import { ContactRole } from '../../contact/service/contact.definition';
import {
  ContactCivilite,
  ContactFonction,
} from '../../contact/controller/contact.definition';
import { AxeConformite } from '../../conformite/common/controller/conformite.definition';

export const creationContactControllerURL = '/contact/creation';

export interface CreationContactRequestType {
  civilite: ContactCivilite;
  nom: string;
  prenom: string;
  telephone?: string;
  email: string;
  fonction: ContactFonction;
  role: ContactRole;
  entreprises: string[];
  axesConformite: AxeConformite[];
}
