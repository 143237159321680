import { ReactComponent as Loader } from "@src/assets/loader.svg";
import classNames from "classnames";
import { useMemo } from "react";
import {
  LocalStorageKeys,
  localStorageHelper,
} from "@src/helper/localStorage.helper";
import style from "./LoadingPage.module.scss";

type LoadingPageProps = {
  className?: string;
};

export function LoadingPage({ className }: LoadingPageProps) {
  return (
    <div className={classNames(style.page, className)}>
      <Loader className={style.loader} />
      <h2>Veuillez patienter</h2>
      <p className={style.durationInfo}>
        Nous en avons pour moins d&apos;une minute.
      </p>
    </div>
  );
}

export function LoadingPageAfterPaiement() {
  const isAlreadyPaid = useMemo(
    () =>
      localStorageHelper.getItem(
        LocalStorageKeys.CREATION_ENTREPRISES_TYPE_PAIEMENT
      ) === "CB",
    []
  );

  return (
    <div className={style.page}>
      <div className={style.pageContent}>
        <Loader className={style.loader} />
        <h2>
          {isAlreadyPaid && "Votre paiement a bien été accepté. "}
          Veuillez patienter.
        </h2>
        <p className={style.durationInfo}>
          Nous en avons pour moins d&apos;une minute.
        </p>
      </div>
      <div className={style.pageBottom}>
        <p className={style.moreInfo}>
          Si la page de confirmation de renouvellement ne s&apos;affiche pas,
          merci de contacter :{" "}
          <a
            href="mailto:support@endya.fr"
            onClick={(e) => {
              window.open("mailto:support@endya.fr", "mail");
              e.preventDefault();
            }}
            className={style.link}
          >
            support@endya.fr
          </a>
        </p>
      </div>
    </div>
  );
}
