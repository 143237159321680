import { format } from "date-fns";
import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayImportExportAssujettiAPI } from "@src/api/importExportAssujetti.api";
import { useMillesime } from "@src/store/store.millesime";

import {
  importWorkBookFromFile,
  insertDataInSheet,
  downloadSheetFromWorkbook,
  addAssujettiDataValidationToSheet,
} from "@src/helper/excel/excel.export.helper";
import { transformAPIRowsForToRows } from "./ExportAssujettiExcelMapping";
import {
  assujettiExcelExportTemplate,
  assujettiExcelTemplate,
} from "../AssujettiExcelTemplate";

export function ExportAssujettiButton() {
  const { selected: millesime } = useMillesime();

  if (!millesime) return null;

  const exportAssujettis = async () => {
    const workbook = await importWorkBookFromFile(
      "modele_personnes_assujetties.xlsx"
    );
    const sheet = workbook.worksheets[0];
    const exportData = await GatewayImportExportAssujettiAPI.exportAssujetti(
      millesime
    );
    insertDataInSheet(
      exportData.map(transformAPIRowsForToRows),
      sheet,
      assujettiExcelTemplate,
      assujettiExcelExportTemplate
    );
    addAssujettiDataValidationToSheet(sheet, exportData.length);

    await downloadSheetFromWorkbook(
      `export_p_assujetties_${format(new Date(), "dd-MM-yyyy_HH:mm")}.xlsx`,
      workbook
    );
  };

  return (
    <Button
      variant="text"
      onClick={() => {
        exportAssujettis().finally(() => undefined);
      }}
    >
      Exporter la liste des personnes assujetties
    </Button>
  );
}
