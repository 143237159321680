import { GatewayContact } from "@conformite/gateway";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Form,
  InputButtonGroup,
  InputMask,
  InputSelect,
  InputText,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { recordToSelectOptions } from "@src/adhesion/AdhesionData/adhesionData";
import {
  REGEX_PHONE_AND_MOBILE,
  cleanMaskedInput,
} from "@src/validation.helper";
import { useEffect } from "react";

export const fonctionOptionsRecord: Record<
  GatewayContact.ContactFonction,
  string
> = {
  REPRESENTANT_LEGAL: "Représentant légal de la structure",
  GERANT: "Gérant",
  DIRECTEUR_GENERAL: "Directeur général",
  DIRECTEUR_ADMINISTRATIF: "Directeur Administratif",
  DIRECTEUR_RH: "Directeur des ressources humaines",
  RESPONSABLE_CONFORMITE: "Responsable conformité",
  AUTRES: "Autre",
};
const fonctionOptions = recordToSelectOptions(fonctionOptionsRecord);
const fonctionValues = fonctionOptions.map((o) => o.value) as [
  (typeof fonctionOptions)[number]["value"]
];

const getSchema = (withRole: boolean, withEmail: boolean) =>
  zod.object({
    role: withRole
      ? zod.nativeEnum(GatewayContact.ContactRoleUtilisateur, {
          errorMap: () => ({ message: "Veuillez renseigner votre rôle" }),
        })
      : zod.nativeEnum(GatewayContact.ContactRoleUtilisateur).optional(),
    civilite: zod.nativeEnum(GatewayContact.ContactCivilite, {
      errorMap: () => ({ message: "Veuillez renseigner votre civilité" }),
    }),
    nom: zod.string().min(1, { message: "Veuillez renseigner votre nom" }),
    prenom: zod
      .string()
      .min(1, { message: "Veuillez renseigner votre prénom" }),
    telephone: zod.preprocess(
      cleanMaskedInput,
      zod.string().min(1).regex(REGEX_PHONE_AND_MOBILE, {
        message: "Veuillez renseigner votre numéro de téléphone",
      })
    ),
    email: withEmail
      ? zod.string().email("Veuillez renseigner une adresse mail valide")
      : zod.string().optional(),
    fonction: zod.enum(fonctionValues, {
      errorMap: () => ({ message: "Veuillez renseigner votre fonction" }),
    }),
  });

export type EntrepriseInformationsPersonnellesFormValues = zod.infer<
  ReturnType<typeof getSchema>
>;

type EntrepriseInformationsPersonnellesFormProps = {
  onSubmit: (
    response: EntrepriseInformationsPersonnellesFormValues
  ) => void | Promise<void>;
  formId?: string;
  defaultValues: Partial<EntrepriseInformationsPersonnellesFormValues>;
  withRole?: boolean;
  withEmail?: boolean;
  disabledEmail?: boolean;
  setIsDirty?: (isDirty: boolean) => void;
};

export function EntrepriseInformationsPersonnellesForm({
  onSubmit,
  formId,
  defaultValues,
  withRole = false,
  withEmail = false,
  disabledEmail = false,
  setIsDirty,
}: EntrepriseInformationsPersonnellesFormProps) {
  const formConfig = useFormConfig<
    ReturnType<typeof getSchema>,
    EntrepriseInformationsPersonnellesFormValues
  >({
    schema: getSchema(withRole, withEmail),
    defaultValues,
  });

  useEffect(
    () => setIsDirty?.(formConfig.formState.isDirty),
    [formConfig.formState.isDirty, setIsDirty]
  );

  return (
    <Form formConfig={formConfig} onSubmit={onSubmit} id={formId}>
      {withRole && (
        <FormRow>
          <FormField>
            <InputButtonGroup<
              EntrepriseInformationsPersonnellesFormValues,
              false,
              GatewayContact.ContactRoleUtilisateur
            >
              label="Rôle"
              name="role"
              variant="outline-pop"
              size="medium"
              options={[
                {
                  label: "Responsable conformité",
                  value:
                    GatewayContact.ContactRoleUtilisateur
                      .RESPONSABLE_CONFORMITE,
                },
                {
                  label: "Utilisateur simple",
                  value:
                    GatewayContact.ContactRoleUtilisateur.UTILISATEUR_SIMPLE,
                },
              ]}
              required
            />
          </FormField>
        </FormRow>
      )}
      <FormRow>
        <FormField>
          <InputButtonGroup<
            EntrepriseInformationsPersonnellesFormValues,
            false,
            GatewayContact.ContactCivilite
          >
            label="Civilité"
            name="civilite"
            variant="outline-pop"
            size="medium"
            options={[
              {
                label: "Monsieur",
                value: GatewayContact.ContactCivilite.MONSIEUR,
              },
              {
                label: "Madame",
                value: GatewayContact.ContactCivilite.MADAME,
              },
            ]}
            required
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <InputText<EntrepriseInformationsPersonnellesFormValues>
            name="nom"
            label="Nom"
            required
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <InputText<EntrepriseInformationsPersonnellesFormValues>
            name="prenom"
            label="Prénom"
            required
          />
        </FormField>
      </FormRow>
      {withEmail && (
        <FormRow>
          <FormField>
            <InputText<EntrepriseInformationsPersonnellesFormValues>
              name="email"
              label="Adresse mail"
              required
              disabled={disabledEmail}
            />
          </FormField>
        </FormRow>
      )}
      <FormRow>
        <FormField size="half">
          <InputMask
            name="telephone"
            mask="99 99 99 99 99"
            label="Téléphone"
            required
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <InputSelect<
            EntrepriseInformationsPersonnellesFormValues,
            GatewayContact.ContactFonction
          >
            name="fonction"
            label="Fonction"
            placeholder="Sélectionner"
            options={fonctionOptions}
            required
          />
        </FormField>
      </FormRow>
    </Form>
  );
}
