import { useNavigate } from "react-router-dom";
import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { useCallback, useMemo, useRef, useState } from "react";
import { GatewayContact, GatewayEntreprise } from "@conformite/gateway";
import { MainContentPageTemplate } from "@src/components/MainPageTemplate/MainContentPageTemplate";
import { TitleWithNumber } from "@src/components/TitleWithNumber/TitleWithNumber";
import { Dropdown } from "@src/components/DropDown/Dropdown";
import { useIsAllowed } from "@src/session/useIsAllowed";
import { useEntreprises } from "@src/store/store.entreprise";
import { SidePane } from "@src/components/SidePane/SidePane";
import { AdhesionDataEntreprisesItem } from "@src/adhesion/AdhesionData/adhesionDataEntreprises";
import { ComplianceProgressBar } from "@src/components/ComplianceProgressBar/ComplianceProgressBar";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { MillesimeSelect } from "@src/components/MillesimeSelect/MillesimeSelect";
import { useMillesime } from "@src/store/store.millesime";
import { GatewayEntrepriseApi } from "@src/api/entreprise2.api";
import { useSocieteFiltered } from "./useSocieteFiltered";
import { ParcoursEntreprise } from "./parcours/ParcoursEntreprise";
import { EntrepriseComponent, SocieteList } from "./SocieteList";
import { EntrepriseActions } from "./SocieteAction";
import { useEntrepriseAvancement } from "./useEntrepriseAvancement";

type SocieteProps = {
  entreprises?: GatewayEntreprise.EntrepriseAControllerResponseType[];
  isLoading: boolean;
  onAddEntreprise: (
    handleClose: () => void
  ) => (data: AdhesionDataEntreprisesItem) => void;
  onDeleteEntreprises: (entreprisesIds: string[]) => void;
  millesime: number;
};

function Societes({
  entreprises,
  isLoading,
  onAddEntreprise,
  onDeleteEntreprises,
}: SocieteProps) {
  const { hasAllowedRole } = useIsAllowed();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const unmountAddSocieteKey = useRef(0);
  const closeDialog = useCallback(() => {
    setIsOpen(false);
    unmountAddSocieteKey.current += 1;
  }, []);
  const { getEntrepriseAvancement } = useEntrepriseAvancement();
  const SocieteAvancement = useCallback<EntrepriseComponent>(
    ({ entreprise }) => (
      <ComplianceProgressBar progress={getEntrepriseAvancement(entreprise)} />
    ),
    [getEntrepriseAvancement]
  );

  const props = useSocieteFiltered(entreprises ?? []);

  return (
    <>
      <SocieteList
        {...props}
        isLoading={isLoading}
        SocieteConformite={SocieteAvancement}
        adminActions={
          hasAllowedRole(
            GatewayContact.ContactRoleUtilisateur.ADMINISTRATEUR
          ) && (
            <Dropdown content="Actions" placement="bottom-end">
              {({ close }) => (
                <div>
                  <Button
                    variant="text"
                    onClick={() => {
                      setIsOpen(true);
                      close();
                    }}
                  >
                    Ajouter une société
                  </Button>
                </div>
              )}
            </Dropdown>
          )
        }
        SocieteActions={EntrepriseActions}
        actionOnClick={(entreprise) => {
          navigate(`/societes/${entreprise.siren}`);
        }}
        onDeleteEntreprises={onDeleteEntreprises}
      />
      <SidePane isOpen={isOpen} setIsOpen={setIsOpen}>
        {() => (
          <ParcoursEntreprise
            key={unmountAddSocieteKey.current}
            onSubmit={onAddEntreprise(closeDialog)}
            withPayment
          />
        )}
      </SidePane>
    </>
  );
}

export function SocietesContainer() {
  const { entreprisesBySiren, setEntreprises, setEntreprise, status } =
    useEntreprises();
  const { selected } = useMillesime();
  const { hasAllowedRole } = useIsAllowed();

  const entreprisesArray = useMemo(
    () => Object.values(entreprisesBySiren ?? {}),
    [entreprisesBySiren]
  );

  const handleAddEntreprise = useCallback(
    (handleClose: () => void) => (data: AdhesionDataEntreprisesItem) => {
      if (!selected) throw Error("No millesime selected");
      handleClose();
      GatewayEntrepriseApi.getBySiren(data.siren as string, selected)
        .then((newEntreprise) => {
          setEntreprise(newEntreprise);
        })
        .catch(() =>
          handleGatewayError({
            onUnhandled: () =>
              "Une erreur est survenue lors de la récupération de la nouvelle entreprise",
          })
        );
    },
    [selected, setEntreprise]
  );

  const handleDeleteEntreprise = useCallback(
    (deletedIds: string[]) => {
      setEntreprises(
        entreprisesArray.filter(
          (entreprise) => !deletedIds.includes(entreprise.hubspotId ?? "")
        )
      );
    },
    [setEntreprises, entreprisesArray]
  );

  return (
    <MainContentPageTemplate
      contentSelection={
        hasAllowedRole(
          GatewayContact.ContactRoleUtilisateur.ADMINISTRATEUR,
          GatewayContact.ContactRoleUtilisateur.RESPONSABLE_CONFORMITE
        ) && <MillesimeSelect />
      }
      title={
        <TitleWithNumber
          title="Conformité des sociétés"
          number={entreprisesArray.length}
        />
      }
    >
      {selected && (
        <Societes
          millesime={selected}
          entreprises={entreprisesArray}
          isLoading={status !== "loaded"}
          onAddEntreprise={handleAddEntreprise}
          onDeleteEntreprises={handleDeleteEntreprise}
        />
      )}
    </MainContentPageTemplate>
  );
}
