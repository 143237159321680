import { useMatch } from "react-router-dom";
import { ReactElement } from "react";
import classNames from "classnames";
import Logo from "@src/assets/logo.png";
import { useIsAllowed } from "@src/session/useIsAllowed";
import { GatewayAuthentication } from "@conformite/gateway";
import { useMe } from "@src/store/store.me";
import styles from "./SideBar.module.scss";
import { Link } from "../Link/Link";

type SidebarLinkProps = {
  to: string;
  children: string | ReactElement;
  precise?: boolean;
};
export function SidebarLink({
  to,
  children,
  precise = false,
}: SidebarLinkProps) {
  const match = useMatch(`${to}${precise ? "" : "/*"}`);
  return (
    <Link
      to={to}
      className={classNames(styles.link, { [styles.active]: !!match })}
    >
      {children}
    </Link>
  );
}
export function SidebarSubtitle({ children }: { children: string }) {
  return <p className={styles.subtitle}>{children}</p>;
}
export function SidebarDelimiter() {
  return <div className={styles.delimiter} />;
}
type SidebarProps = {
  children: (false | JSX.Element | JSX.Element[])[];
};
export function Sidebar({ children }: SidebarProps) {
  const { hasAllowedCognitoRole } = useIsAllowed();
  const { me } = useMe();
  return (
    <div className={styles.sidebar}>
      <Link to="/">
        <img src={Logo} alt="endya logo" />
      </Link>
      {hasAllowedCognitoRole(GatewayAuthentication.Role.ENDYA) && (
        <p className={styles.connectedAs}>
          Connecté en tant que {me?.contact.prenom} {me?.contact.nom}
        </p>
      )}
      {children}
    </div>
  );
}
