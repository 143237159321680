import { useState } from "react";
import {
  localStorageHelper,
  LocalStorageKeys,
} from "@src/helper/localStorage.helper";
import { useStore } from "./store.hooks";

export const retrieveMillesimeFromLocalStorage = (
  millesimeActuel: number
): number => {
  const millesime = Number(
    localStorageHelper.getItem(LocalStorageKeys.SELECTED_MILLESIME)
  );
  if (
    Number.isNaN(millesime) ||
    millesime < millesimeActuel - 2 ||
    millesime > millesimeActuel
  ) {
    localStorageHelper.setItem(
      LocalStorageKeys.SELECTED_MILLESIME,
      millesimeActuel.toString()
    );
    return millesimeActuel;
  }
  return millesime;
};

export const useMillesime = () => {
  const { millesime } = useStore();
  return millesime;
};

export type StoreEntryMillesime = {
  setSelected: (newMillesime: number | undefined) => void;
  selected: number | undefined;
  refreshCount: number;
  reset: () => void;
};

export const useStoreEntryMillesime = (): StoreEntryMillesime => {
  const [refreshCount, setRefreshCount] = useState(0);
  const [millesime, _setMillesime] = useState<number | undefined>(undefined);

  const setMillesime = (newMillesime: number | undefined) => {
    if (!newMillesime)
      localStorageHelper.removeItem(LocalStorageKeys.SELECTED_MILLESIME);
    else
      localStorageHelper.setItem(
        LocalStorageKeys.SELECTED_MILLESIME,
        newMillesime.toString()
      );
    setRefreshCount((prevCount) => prevCount + 1);
    _setMillesime(newMillesime);
  };

  const reset = () => {
    localStorageHelper.removeItem(LocalStorageKeys.SELECTED_MILLESIME);
    setRefreshCount((prevCount) => prevCount + 1);
    _setMillesime(undefined);
  };

  return {
    selected: millesime,
    setSelected: setMillesime,
    refreshCount,
    reset,
  };
};
