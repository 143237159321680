export const toEuro = (
  value: number,
  options?: Intl.NumberFormatOptions & { hideUselessFractionDigits?: boolean }
) => {
  const formatterOptions: Intl.NumberFormatOptions = {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 2,
    ...options,
  };

  if (options?.hideUselessFractionDigits) {
    if (value % 1 === 0) {
      formatterOptions.maximumFractionDigits = 0;
    }
  }

  const euroFormatter = new Intl.NumberFormat("fr-FR", formatterOptions);

  return euroFormatter.format(value);
};
