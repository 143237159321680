import {
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import {
  Form,
  InputButtonGroup,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import { GatewayEntreprise } from "@conformite/gateway";
import { SelectOption } from "@lya-protect/lya-protect-form-library/dist/UI/Input/UIInputSelect";
import { ParcoursAdhesionSaveAndQuitAction } from "@src/adhesion/AdhesionActions/ParcoursAdhesionSaveAndQuitAction";
import { ParcoursAdhesionNextAction } from "@src/adhesion/AdhesionActions/ParcoursAdhesionNextAction";
import {
  AdhesionStepEnum,
  ID_FORM_ADHESION,
  ParcoursAdhesionStepComponentProps,
} from "@src/adhesion/ParcoursAdhesion.definition";
import { ParcoursFullPageTemplate } from "../../../parcours/Template/ParcoursFullPageTemplate";

const schema = zod.object({
  type: zod.nativeEnum(GatewayEntreprise.TypeAdhesionActive),
});

const typeEntrepriseOptions: SelectOption<GatewayEntreprise.TypeAdhesion>[] = [
  {
    label: "Société individuelle",
    value: GatewayEntreprise.TypeAdhesion.SOCIETE_INDIVIDUELLE,
  },
  { label: "Groupe", value: GatewayEntreprise.TypeAdhesion.GROUPE },
  {
    label: "Responsable d'un réseau de mandataires",
    value: GatewayEntreprise.TypeAdhesion.GROUPEMENT_MANDATAIRES,
  },
];

export type AdhesionTypeEntrepriseFormValues = zod.infer<typeof schema>;

export function AdhesionTypeEntreprise({
  addData,
  changeStep,
  data,
  handleQuit,
}: ParcoursAdhesionStepComponentProps) {
  const formConfig = useFormConfig<
    typeof schema,
    AdhesionTypeEntrepriseFormValues
  >({
    schema,
    defaultValues: data.typeEntreprise,
  });

  const handleSubmit = (submittedData: AdhesionTypeEntrepriseFormValues) => {
    addData({ typeEntreprise: submittedData });
    if (submittedData.type === "SOCIETE_INDIVIDUELLE")
      changeStep(AdhesionStepEnum.INFO_ACTIVITE);
    else changeStep(AdhesionStepEnum.MODE_IMPORT);
  };
  return (
    <ParcoursFullPageTemplate
      title="Vous adhérez en tant que…"
      actions={
        <>
          <ParcoursAdhesionSaveAndQuitAction
            addData={addData}
            formConfig={formConfig}
            handleQuit={handleQuit}
            transformValues={(formValues) => ({ typeEntreprise: formValues })}
          />
          <ParcoursAdhesionNextAction type="submit">
            Suivant
          </ParcoursAdhesionNextAction>
        </>
      }
    >
      <Form
        formConfig={formConfig}
        onSubmit={handleSubmit}
        id={ID_FORM_ADHESION}
      >
        <FormRow>
          <FormField>
            <InputButtonGroup<
              AdhesionTypeEntrepriseFormValues,
              false,
              GatewayEntreprise.TypeAdhesion
            >
              name="type"
              options={typeEntrepriseOptions}
              variant="outline-pop"
              size="large"
            />
          </FormField>
        </FormRow>
      </Form>
    </ParcoursFullPageTemplate>
  );
}
