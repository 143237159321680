import { GatewayContact } from "@conformite/gateway";
import { MainContentPageTemplate } from "@src/components/MainPageTemplate/MainContentPageTemplate";
import { MillesimeSelect } from "@src/components/MillesimeSelect/MillesimeSelect";
import { TitleWithNumber } from "@src/components/TitleWithNumber/TitleWithNumber";
import { useIsAllowed } from "@src/session/useIsAllowed";
import { useAssujetti } from "@src/store/store.assujetti";
import { useMillesime } from "@src/store/store.millesime";
import { AssujettiMainList } from "./AssujettiMainList/AssujettiMainList";

export function Assujettis() {
  const { hasAllowedRole } = useIsAllowed();
  const { assujettis, setEntry, removeEntries, setEntriesOfMillesime } =
    useAssujetti();
  const { selected } = useMillesime();
  const assujettiArray = assujettis ? Object.values(assujettis) : undefined;

  return (
    <MainContentPageTemplate
      title={
        <TitleWithNumber
          title="Personnes assujetties"
          number={assujettiArray ? assujettiArray.length : 0}
        />
      }
      contentSelection={
        hasAllowedRole(
          GatewayContact.ContactRoleUtilisateur.ADMINISTRATEUR,
          GatewayContact.ContactRoleUtilisateur.RESPONSABLE_CONFORMITE
        ) && <MillesimeSelect />
      }
    >
      {selected !== undefined && (
        <AssujettiMainList
          millesime={selected}
          assujettis={assujettiArray}
          setEntry={setEntry}
          removeEntries={removeEntries}
          setEntries={(entries, clean) =>
            setEntriesOfMillesime(entries, selected, clean)
          }
        />
      )}
    </MainContentPageTemplate>
  );
}
