import {
  EntrepriseOrganisationProfessionnelleForm,
  EntrepriseOrganisationProfessionnelleFormValues,
} from "@src/societes/form/EntrepriseOrganisationProfessionnelleForm";
import {
  ParcoursEntrepriseStepComponentProps,
  ParcoursEntrepriseStepEnum,
} from "@src/societes/parcours/ParcoursEntreprise";
import {
  ID_FORM_ENTREPRISE,
  ParcoursEntrepriseActions,
} from "@src/societes/parcours/ParcoursEntrepriseActions";
import { ParcoursEntrepriseTemplate } from "@src/societes/parcours/ParcoursEntrepriseTemplate";
import { CATEGORIE_WITH_SOUS_CATEGORIE } from "./EntrepriseInfoActiviteStep";

export function EntrepriseOrganisationProfessionnelleStep({
  addData,
  changeStep,
  data,
}: ParcoursEntrepriseStepComponentProps) {
  return (
    <ParcoursEntrepriseTemplate
      title="Organisation professionnelle"
      actions={
        <ParcoursEntrepriseActions
          onPrevious={() => {
            const prevStep = data.categoriesOrias?.some((categorie) =>
              CATEGORIE_WITH_SOUS_CATEGORIE.includes(categorie)
            )
              ? ParcoursEntrepriseStepEnum.SOUS_CATEGORIES
              : ParcoursEntrepriseStepEnum.INFO_ACTIVITE;
            changeStep(prevStep);
          }}
        />
      }
    >
      <EntrepriseOrganisationProfessionnelleForm
        formId={ID_FORM_ENTREPRISE}
        onSubmit={(
          response: EntrepriseOrganisationProfessionnelleFormValues
        ) => {
          addData({ organisationPro: response.organisationsProfessionnelles });
          changeStep(ParcoursEntrepriseStepEnum.INFORMATIONS_COMPLEMENTAIRES);
        }}
        defaultValues={{
          organisationsProfessionnelles: data.organisationPro ?? [],
        }}
      />
    </ParcoursEntrepriseTemplate>
  );
}
