import { UseFormReturn } from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import {
  InputDate,
  InputText,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  FormField,
  FormGrid,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { FormDataFiles } from "./ConformiteRcProEditFiles";

type ConformiteRcProEditFileValuesProps = {
  index: number;
  isMandant: boolean;
  form: UseFormReturn<FormDataFiles>;
};
type ConformiteRcProEditFileValue = {
  files: { startDate: Date; endDate: Date; orias: string }[];
};
export function ConformiteRcProEditFileValues({
  index,
  isMandant,
  form,
}: ConformiteRcProEditFileValuesProps) {
  return (
    <FormGrid disableHorizontalGutter>
      {!isMandant && (
        <FormRow>
          <FormField size="half">
            <InputDate<ConformiteRcProEditFileValue>
              name={`files.${index}.startDate`}
              label="Date de début de validité"
              onChange={(value) => {
                form.setValue(`files.${index}.startDate`, value as Date);
                form.trigger(`files.0.startDate`).catch(console.error);
              }}
            />
          </FormField>
          <FormField size="half">
            <InputDate<ConformiteRcProEditFileValue>
              name={`files.${index}.endDate`}
              label="Date de fin de validité"
              onChange={(value) => {
                form.setValue(`files.${index}.endDate`, value as Date);
                form.trigger(`files.0.startDate`).catch(console.error);
              }}
            />
          </FormField>
        </FormRow>
      )}
      {isMandant && (
        <FormRow>
          <FormField>
            <InputText<ConformiteRcProEditFileValue>
              name={`files.${index}.orias`}
              label="Numéro Orias du mandant"
            />
          </FormField>
        </FormRow>
      )}
    </FormGrid>
  );
}
