import { GatewayAuthentication } from "@conformite/gateway";
import { userSession } from "@src/session/UserSession";
import { captchaAxiosInstance } from "./utils/captchaAxiosInstance";

export class GatewayAuthApi {
  public static async forgotPassword(
    email: string
  ): Promise<GatewayAuthentication.ForgotPasswordRequestType> {
    const response =
      await captchaAxiosInstance.post<GatewayAuthentication.ForgotPasswordRequestType>(
        `${GatewayAuthentication.forgotPasswordRequestUrl}`,
        {
          email,
        }
      );
    return response.data;
  }

  public static async confirmForgotPassword(
    email: string,
    code: string,
    password: string
  ): Promise<GatewayAuthentication.ConfirmForgotPasswordRequestType> {
    const response =
      await captchaAxiosInstance.post<GatewayAuthentication.ConfirmForgotPasswordRequestType>(
        `${GatewayAuthentication.configForgotPasswordRequestUrl}`,
        {
          email,
          code,
          password,
        }
      );
    return response.data;
  }

  public static async confirm(email: string, code: string): Promise<void> {
    await captchaAxiosInstance.post(`${GatewayAuthentication.confirmUrl}`, {
      email,
      code,
    });
  }

  public static async signup(
    email: string,
    password: string
  ): Promise<GatewayAuthentication.SignupResponseType> {
    const response =
      await captchaAxiosInstance.post<GatewayAuthentication.SignupResponseType>(
        `${GatewayAuthentication.signupRequestUrl}`,
        {
          email,
          password,
        }
      );
    return response.data;
  }

  public static async resendCode(
    email: string
  ): Promise<GatewayAuthentication.ResendCodeResponseType> {
    const response =
      await captchaAxiosInstance.post<GatewayAuthentication.ResendCodeResponseType>(
        `${GatewayAuthentication.resendCodeRequestUrl}`,
        {
          email,
        }
      );
    return response.data;
  }

  public static async changePassword(
    payload: Omit<GatewayAuthentication.ChangePasswordType, "accessToken">
  ) {
    const payloadWithAccessToken: GatewayAuthentication.ChangePasswordType = {
      ...payload,
      accessToken: userSession.sessionData.accessToken as string,
    };
    await userSession.axiosInstance.post<string>(
      GatewayAuthentication.changePasswordUrl,
      payloadWithAccessToken
    );
  }

  public static async confirmPassword(
    payload: GatewayAuthentication.NewPasswordType
  ) {
    const response =
      await captchaAxiosInstance.post<GatewayAuthentication.SigninResponseType>(
        GatewayAuthentication.newPasswordUrl,
        payload
      );
    return response.data;
  }
}
