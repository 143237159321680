import { GatewayConformiteRcPro } from "@conformite/gateway";
import {
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { useMemo } from "react";
import {
  PropertyKeyWithoutFiles,
  blacklistedProperties,
  propertiesToText,
} from "../conformiteRcPro.helper";
import { PropertyStatus } from "./ConformiteRcProReadStatus";
import styles from "../ConformiteRcPro.module.scss";

const useConformiteRcProReadProperties = (
  properties: GatewayConformiteRcPro.ConformiteRcProProperty
) => {
  const hasRcpIndividuelleSouscritSetToNo =
    (
      properties as {
        rcpIndividuelleSouscrit?: GatewayConformiteRcPro.ConformiteRcProPropertyType;
      }
    ).rcpIndividuelleSouscrit ===
    GatewayConformiteRcPro.ConformiteRcProPropertyType.NON;
  return useMemo(
    () =>
      Object.entries(properties).filter(([key]) => {
        if (blacklistedProperties.includes(key)) return false;
        if (key !== "courvertRcMandant") return true;
        return hasRcpIndividuelleSouscritSetToNo;
      }),
    [hasRcpIndividuelleSouscritSetToNo, properties]
  );
};

type ConformiteRcProReadPropertiesProps = {
  properties: GatewayConformiteRcPro.ConformiteRcProProperty;
};
export function ConformiteRcProReadProperties({
  properties,
}: ConformiteRcProReadPropertiesProps) {
  const propertiesToShow = useConformiteRcProReadProperties(properties);
  return (
    <>
      {propertiesToShow.map(([key, value]) => (
        <FormRow key={key}>
          <FormField>
            <div className={styles.property}>
              <p className={styles.propertyText}>
                {propertiesToText[key as PropertyKeyWithoutFiles]}
              </p>
              <PropertyStatus
                value={
                  value as GatewayConformiteRcPro.ConformiteRcProPropertyType
                }
              />
            </div>
          </FormField>
        </FormRow>
      ))}
    </>
  );
}
