import {
  GatewayDeclarationActivite,
  GatewayEntreprise,
} from "@conformite/gateway";
import {
  Button,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Link } from "@src/components/Link/Link";
import { MainContentPageTemplate } from "@src/components/MainPageTemplate/MainContentPageTemplate";
import { ReactComponent as ChevronLeft } from "@src/assets/icons/chevron-left.svg";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { useMillesime } from "@src/store/store.millesime";
import { GatewayDeclarationActiviteApi } from "@src/api/conformite/declarationActivite.api";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { SkeletonCard } from "@src/components/Skeletons/card/SkeletonCard";
import { useEntreprises } from "@src/store/store.entreprise";
import { useHasForbiddenTypeAdhesion } from "@src/session/useIsAllowed";
import styles from "./ConformiteDeclarationActivite.module.scss";
import { ConformiteDeclarationActiviteIobspRead } from "./read/ConformiteDeclarationActiviteIobspRead";
import { ConformiteDeclarationActiviteIobspEdit } from "./edit/ConformiteDeclarationActiviteIobspEdit";

export function ConformiteDeclarationActiviteIobsp() {
  const { id } = useParams<{
    id: string;
  }>();
  const hasForbiddenTypeAdhesion = useHasForbiddenTypeAdhesion();

  const [query] = useSearchParams();
  const fromAxe = query.get("fromAxe");
  const { entreprisesBySiren, updateAvancement } = useEntreprises();
  const [isEditing, setIsEditing] = useState(false);
  const { selected } = useMillesime();
  const societe = id && entreprisesBySiren ? entreprisesBySiren[id] : undefined;
  const [declarationActivite, setDeclarationActivite] =
    useState<GatewayDeclarationActivite.DeclarationActiviteIobsp | null>();
  useEffect(() => {
    if (!id || !selected) return;
    GatewayDeclarationActiviteApi.getIobsp(id, selected)
      .then((data) => {
        setDeclarationActivite(data);
      })
      .catch((error) => {
        handleGatewayError({
          onUnhandled: () => {
            displayErrorToast(
              "Une erreur est survenue lors de la récupération du formulaire"
            );
          },
        })(error);
      });
  }, [id, selected]);

  if (!id) {
    return <Navigate to="/societes" />;
  }

  const isConforme = societe?.avancement.declarationActiviteIobsp === 100;
  const isReadyToRender =
    selected && !!societe && declarationActivite !== undefined;

  return (
    <MainContentPageTemplate
      title={
        <div className={styles.title}>
          <h1>Declaration Activité IOBSP</h1>
          <h2>{societe?.raisonSociale}</h2>
        </div>
      }
      actionNavigation={
        hasForbiddenTypeAdhesion([
          GatewayEntreprise.TypeAdhesion.SOCIETE_INDIVIDUELLE,
        ]) ? null : (
          <Link
            to={
              fromAxe === null
                ? `/societes/${id}`
                : "/conformite/declaration-activite/iobsp"
            }
          >
            <Button variant="text" icon={<ChevronLeft />}>
              Retour
            </Button>
          </Link>
        )
      }
    >
      {!isReadyToRender && (
        <MaxWidthContent size="large">
          <SkeletonCard height={500} />
        </MaxWidthContent>
      )}
      {isReadyToRender && !isEditing && (
        <ConformiteDeclarationActiviteIobspRead
          declarationActivite={declarationActivite}
          isConforme={isConforme}
          setToEdit={() => setIsEditing(true)}
          millesime={selected}
        />
      )}
      {isReadyToRender && isEditing && (
        <ConformiteDeclarationActiviteIobspEdit
          siren={societe.siren}
          millesime={selected}
          declarationActivite={declarationActivite}
          isConforme={isConforme}
          stopEditing={(value, avancement) => {
            setIsEditing(false);
            if (value) setDeclarationActivite(value);
            if (avancement) updateAvancement(avancement);
          }}
        />
      )}
    </MainContentPageTemplate>
  );
}
