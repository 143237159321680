import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { useParams } from "react-router-dom";
import { Link } from "@src/components/Link/Link";
import {
  Sidebar,
  SidebarDelimiter,
  SidebarLink,
  SidebarSubtitle,
} from "@src/components/SideBar/SideBar";
import { ReactComponent as ChevronLeft } from "@src/assets/icons/chevron-left.svg";

export function SocieteInformationSidebar() {
  const { id } = useParams<{ id: string }>();
  return (
    <Sidebar>
      <Link to={`/societes/${id ?? ""}`}>
        <Button variant="text" icon={<ChevronLeft />}>
          Retour
        </Button>
      </Link>
      <SidebarDelimiter />
      <SidebarSubtitle>Informations société</SidebarSubtitle>
      <SidebarLink to={`/societes/${id ?? ""}/informations`}>
        Général
      </SidebarLink>
    </Sidebar>
  );
}
