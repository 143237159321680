import { InputSwitch } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as ChevronLeft } from "@src/assets/icons/chevron-left.svg";
import { ReactComponent as ChevronRight } from "@src/assets/icons/chevron-right.svg";
import { ReactComponent as Loader } from "@src/assets/loader.svg";
import { AxeFormValues } from "./ControleQuinquennalAxe";
import style from "./ControleQuinquennalPageActions.module.scss";

type ControleQuinquennalPageActionsProps = {
  onNext?: () => void;
  onPrevious?: () => void;
  isLoading: boolean;
  isLast: boolean;
  isValid: boolean;
};

export function ControleQuinquennalPageActions({
  onNext,
  onPrevious,
  isLoading,
  isLast,
  isValid,
}: ControleQuinquennalPageActionsProps) {
  return (
    <div className={style.actions}>
      <div>
        {onPrevious && (
          <Button
            onClick={onPrevious}
            disabled={isLoading}
            icon={isLoading ? <Loader /> : <ChevronLeft />}
            variant="text"
          >
            Précédent
          </Button>
        )}
      </div>
      <div className={style.right}>
        <InputSwitch<AxeFormValues>
          label="Import complet"
          name="complet"
          className={style.switch}
          disabled={!isValid}
        />
        <div>
          {onNext && (
            <Button
              onClick={onNext}
              variant="text"
              disabled={isLoading}
              icon={isLoading ? <Loader /> : <ChevronRight />}
              className={style.next}
            >
              {isLast ? "Enregistrer" : "Suivant"}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
