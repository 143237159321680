import { PropsWithChildren } from "react";
import styles from "./BulletNumber.module.scss";

type BulletNumberVariant = "info" | "error";
type BulletNumberSize = "small" | "medium";
type BulletNumberProps = PropsWithChildren<{
  value: number;
  variant?: BulletNumberVariant;
  size?: BulletNumberSize;
}>;
export function BulletNumber({
  value,
  variant = "info",
  size = "small",
}: BulletNumberProps) {
  return (
    <div className={styles.bullet} data-variant={variant} data-size={size}>
      {value}
    </div>
  );
}
