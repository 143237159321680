import { GatewayConformiteRcPro } from "@conformite/gateway";
import { InputButtonGroup } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { Path } from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import {
  PropertyKeyWithoutFiles,
  blacklistedProperties,
  propertiesToText,
} from "../conformiteRcPro.helper";

const PropertyOption = [
  {
    label: "À verifier",
    value: GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
  },
  {
    label: "Oui",
    value: GatewayConformiteRcPro.ConformiteRcProPropertyType.OUI,
  },
  {
    label: "Non",
    value: GatewayConformiteRcPro.ConformiteRcProPropertyType.NON,
  },
];
type ConformiteRcProEditPropertiesProps = {
  properties: GatewayConformiteRcPro.ConformiteRcProProperty | null;
  hasRcpIndividuelleSouscritSetToNo: boolean;
};
export function ConformiteRcProEditProperties({
  properties,
  hasRcpIndividuelleSouscritSetToNo,
}: ConformiteRcProEditPropertiesProps) {
  const propertiesToShow = Object.keys(properties ?? {}).filter((key) => {
    if (blacklistedProperties.includes(key)) return false;
    if (key !== "courvertRcMandant") return true;
    return hasRcpIndividuelleSouscritSetToNo;
  });
  return (
    <>
      {propertiesToShow.map((key) => (
        <FormRow key={key}>
          <FormField>
            <InputButtonGroup<
              GatewayConformiteRcPro.ConformiteRcProProperty,
              true,
              GatewayConformiteRcPro.ConformiteRcProPropertyType
            >
              label={propertiesToText[key as PropertyKeyWithoutFiles]}
              options={PropertyOption}
              name={key as Path<GatewayConformiteRcPro.ConformiteRcProProperty>}
              variant="outline-pop"
              size="medium"
            />
          </FormField>
        </FormRow>
      ))}
    </>
  );
}
