import { GatewayControleQuinquennal } from "@conformite/gateway";
import { ControleQuinquennalApi } from "@src/api/controleQuinquennal.api";
import { useEntreprises } from "@src/store/store.entreprise";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const useControleQuinquennalItem = () => {
  const [controleQuinquennal, setControleQuinquennal] =
    useState<GatewayControleQuinquennal.ControleQuinquennalWithAxes>();
  const { id } = useParams<{ id: string }>();

  const { entreprisesBySiren } = useEntreprises();

  const entreprise = useMemo(
    () =>
      Object.values(entreprisesBySiren ?? {}).find(
        (e) => e.hubspotId === controleQuinquennal?.idEntreprise
      ),
    [controleQuinquennal, entreprisesBySiren]
  );
  useEffect(() => {
    if (!id) return;
    ControleQuinquennalApi.getControleQuinquennal(id)
      .then((data) => {
        setControleQuinquennal(data);
      })
      .catch(console.error);
  }, [id]);
  return {
    controleQuinquennal,
    entreprise,
    areAllAxesComplet:
      controleQuinquennal?.controleQuinquennalAxes?.every((a) => a.isComplet) ??
      false,
  };
};
