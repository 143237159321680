import Fuse from "fuse.js";
import { UseFormReturn } from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import { GatewayDeclarationActivite } from "@conformite/gateway";
import { useCallback, useMemo } from "react";
import {
  AutoCompleteWithList,
  ListComponentProps,
} from "@src/components/AutocompleteWithList/AutocompleteWithList";
import { TagList } from "@src/components/AutocompleteWithList/TagList";
import { invertMap } from "@src/helper/object.helper";

export const activiteIasToLabel: Record<
  GatewayDeclarationActivite.ActiviteIas,
  string
> = {
  ASSURANCE_VIE_DONT_CONTRAT_DE_CAPITALISATION:
    "Assurance vie (dont contrat de capitalisation)",
  SANTE_PREVOYANCE_DEPENDANCE: "Santé/Prévoyance/Dépendance",
  OBSEQUES: "Obsèques",
  EMPRUNTEUR: "Emprunteur",
  AUTOMOBILE: "Automobile",
  MULTIRISQUES_HABITATION: "Multi Risque Habitation",
  CONSTRUCTION: "Construction",
  AFFINITAIRES: "Affinitaires",
  PROTECTION_JURIDIQUE: "Protection juridique",
  ASSISTANCE: "Assistance",
  GRANDS_RISQUES: "Grands Risques",
  REASSURANCE: "Réassurance",
  AUTRES: "Autres",
};
const labelToActivite: Record<string, GatewayDeclarationActivite.ActiviteIas> =
  invertMap(activiteIasToLabel);
export type ActivitesData = {
  activite: string;
  activites: { value: string }[];
};

type ActivitesIasProps = {
  form: UseFormReturn<ActivitesData>;
  activitePrincipale: GatewayDeclarationActivite.ActiviteIas | null;
};
const activites = Object.keys(labelToActivite);
export function ActivitesIas({ form, activitePrincipale }: ActivitesIasProps) {
  const [value, valuesSelected] = form.watch(["activite", "activites"]);
  const activitesWithoutSelected = useMemo(
    () =>
      activites.filter(
        (activite) =>
          valuesSelected.every((v) => v.value !== labelToActivite[activite]) &&
          labelToActivite[activite] !== activitePrincipale
      ),
    [activitePrincipale, valuesSelected]
  );
  const fuse = new Fuse(activitesWithoutSelected, {
    threshold: 0.4,
  });
  const ListComponent = useCallback(
    ({ fields, remove }: ListComponentProps) => (
      <TagList
        remove={remove}
        fields={fields.map((field) => ({
          id: field.id,
          value:
            activiteIasToLabel[
              field.value as GatewayDeclarationActivite.ActiviteIas
            ],
        }))}
      />
    ),
    []
  );
  return (
    <AutoCompleteWithList<"activite", "activites">
      form={form}
      label="Sélectionnez les activités représentants au minimum 10% du CA"
      placeholder="Selectionner"
      options={(value
        ? fuse.search(value, { limit: 20 }).map((r) => r.item)
        : activitesWithoutSelected
      ).map((item) => ({
        label: item,
        value: labelToActivite[item],
      }))}
      nameSelected="activites"
      nameSearch="activite"
      valueToAppend={(item) => ({ value: item })}
      ListComponent={ListComponent}
    />
  );
}
