import style from "./ProgressBar.module.scss";

export type ProgressBarProps = {
  step: number;
  total: number;
};
export function ProgressBar({ step, total }: ProgressBarProps) {
  return (
    <div className={style.progress}>
      <div
        className={style.bar}
        style={{ width: `${Math.round((step / total) * 100)}%` }}
      />
    </div>
  );
}
