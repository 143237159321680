import { useCallback } from "react";
import { ParcoursAction } from "@src/parcours/NavigationButtons/ParcoursAction";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import {
  EntrepriseInfoActiviteForm,
  EntrepriseInfoActiviteFormValues,
} from "@src/societes/form/EntrepriseInfoActiviteForm";
import { GatewayInfoSocieteOrias } from "@conformite/gateway";
import { partition } from "lodash";
import { isCategorieAccessoire } from "@src/payment/utils/createIntentionPaiement";
import {
  ID_FORM_RENOUVELLEMENT,
  ParcoursRenouvellementStepComponentProps,
  RenouvellementStepEnum,
} from "@src/renouvellement/ParcoursRenouvellement.definition";

export function RenouvellementInfoActivitePage({
  data,
  setData,
  changeStep,
}: ParcoursRenouvellementStepComponentProps) {
  const entreprise =
    data.entreprisesAControler[data.resume.entreprisePrincipale.siren];

  const handleSubmit = useCallback(
    (values: EntrepriseInfoActiviteFormValues) => {
      const [categorieAccessoire, categorieNonAccessoire] = partition(
        values.categories,
        (categorie) =>
          isCategorieAccessoire(categorie, {
            coaAccessoire: !!values.coaAccessoire,
            miaAccessoire: !!values.miaAccessoire,
            cobspAccessoire: !!values.cobspAccessoire,
            miobspAccessoire: !!values.miobspAccessoire,
          })
      );

      setData({
        ...data,
        entreprisesAControler: {
          ...data.entreprisesAControler,
          [entreprise.siren]: {
            ...entreprise,
            categoriesActivites: categorieNonAccessoire,
            categoriesActivitesAccessoires: categorieAccessoire,
          },
        },
      });
      changeStep(RenouvellementStepEnum.SOUS_CATEGORIES);
      if (
        categorieNonAccessoire.find((categorie) =>
          [
            GatewayInfoSocieteOrias.Categorie.COBSP,
            GatewayInfoSocieteOrias.Categorie.MIOBSP,
          ].includes(categorie)
        )
      )
        changeStep(RenouvellementStepEnum.SOUS_CATEGORIES);
      else changeStep(RenouvellementStepEnum.ORGANISATIONS_PROFESSIONNELLES);
    },
    [setData, data, entreprise, changeStep]
  );

  return (
    <ParcoursFullPageTemplate
      title="Information activité"
      actions={
        <ParcoursAction type="submit" formId={ID_FORM_RENOUVELLEMENT}>
          Suivant
        </ParcoursAction>
      }
    >
      <EntrepriseInfoActiviteForm
        formId={ID_FORM_RENOUVELLEMENT}
        defaultValues={{
          numeroOrias: entreprise.numeroOrias,
          statutOrias: entreprise.numeroOrias
            ? GatewayInfoSocieteOrias.StatutSociete.INSCRIT
            : GatewayInfoSocieteOrias.StatutSociete.JAMAIS_INSCRIT,
          categories: [
            ...entreprise.categoriesActivites,
            ...entreprise.categoriesActivitesAccessoires,
          ],
          coaAccessoire: entreprise.categoriesActivitesAccessoires.includes(
            GatewayInfoSocieteOrias.Categorie.COA
          ),
          cobspAccessoire: entreprise.categoriesActivitesAccessoires.includes(
            GatewayInfoSocieteOrias.Categorie.COBSP
          ),
          miaAccessoire: entreprise.categoriesActivitesAccessoires.includes(
            GatewayInfoSocieteOrias.Categorie.MIA
          ),
          miobspAccessoire: entreprise.categoriesActivitesAccessoires.includes(
            GatewayInfoSocieteOrias.Categorie.MIOBSP
          ),
        }}
        onSubmit={handleSubmit}
      />
    </ParcoursFullPageTemplate>
  );
}
