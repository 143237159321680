import { RichTextField } from "@prismicio/client";
import { useSinglePrismicDocument } from "@prismicio/react";

const querySidebarAccueil = `
    {
      sidebar_accueil {
        group {
          pages {
            ...on generique {
              titre_lien
            }
          }
        }
      }
    }
`;

type PrismicSidebarAccueil = {
  group: {
    pages?: {
      id: string;
      uid: string;
      data?: {
        titre_lien: RichTextField;
      };
    };
  }[];
};
export const useSidebarAccueil = () => {
  const data = useSinglePrismicDocument("sidebar_accueil", {
    graphQuery: querySidebarAccueil,
  });
  const accueilSidebar = data[0]?.data as unknown as
    | PrismicSidebarAccueil
    | undefined;
  return { accueilSidebar };
};
