import {
  GatewayAvancement,
  GatewayConformite,
  GatewayGarantieFinanciere,
} from "@conformite/gateway";
import { userSession } from "@src/session/UserSession";

export class GatewayGarantieFinanciereApi {
  static async getGarantieFinanciereIas(
    siren: string,
    millesime: number,
    isAdmin = false
  ): Promise<GatewayGarantieFinanciere.ConformiteGarantieFinanciereIas | null> {
    return this.getGarantieFinanciere(
      siren,
      millesime,
      "ias",
      isAdmin
    ) as Promise<GatewayGarantieFinanciere.ConformiteGarantieFinanciereIas | null>;
  }

  static async saveGarantieFinanciereIas(
    siren: string,
    millesime: number,
    garantieFinanciere: GatewayGarantieFinanciere.ConformiteGarantieFinanciereIas,
    files: File[]
  ): Promise<GatewayAvancement.AvancementSociete> {
    return this.saveGarantieFinanciere(
      siren,
      millesime,
      "ias",
      garantieFinanciere,
      files
    );
  }

  static async getGarantieFinanciereIobsp(
    siren: string,
    millesime: number,
    isAdmin = false
  ): Promise<GatewayGarantieFinanciere.ConformiteGarantieFinanciereIobsp | null> {
    return this.getGarantieFinanciere(
      siren,
      millesime,
      "iobsp",
      isAdmin
    ) as Promise<GatewayGarantieFinanciere.ConformiteGarantieFinanciereIobsp | null>;
  }

  static async saveGarantieFinanciereIobsp(
    siren: string,
    millesime: number,
    garantieFinanciere: GatewayGarantieFinanciere.ConformiteGarantieFinanciereIobsp,
    files: File[]
  ): Promise<GatewayAvancement.AvancementSociete> {
    return this.saveGarantieFinanciere(
      siren,
      millesime,
      "iobsp",
      garantieFinanciere,
      files
    );
  }

  private static async getGarantieFinanciere(
    siren: string,
    millesime: number,
    categorie: GatewayConformite.IasIobsp,
    isAdmin: boolean
  ): Promise<
    | GatewayGarantieFinanciere.ConformiteGarantieFinanciereIas
    | GatewayGarantieFinanciere.ConformiteGarantieFinanciereIobsp
    | null
  > {
    const response =
      await userSession.axiosInstance.get<GatewayGarantieFinanciere.ConformiteGarantieFinanciereIas | null>(
        this.getUrl(siren, categorie, millesime) + (isAdmin ? "/admin" : "")
      );
    return response.data;
  }

  private static async saveGarantieFinanciere(
    siren: string,
    millesime: number,
    categorie: GatewayConformite.IasIobsp,
    garantieFinanciere:
      | GatewayGarantieFinanciere.ConformiteGarantieFinanciereIas
      | GatewayGarantieFinanciere.ConformiteGarantieFinanciereIobsp,
    files: File[]
  ): Promise<GatewayAvancement.AvancementSociete> {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("documents", file);
    });
    Object.entries(garantieFinanciere).forEach(([key, value]) => {
      formData.append(
        key,
        ["files", "fileNonDiffusable"].includes(key)
          ? JSON.stringify(value)
          : String(value)
      );
    });
    const response =
      await userSession.axiosInstance.post<GatewayAvancement.AvancementSociete>(
        this.getUrl(siren, categorie, millesime),
        formData
      );
    return response.data;
  }

  public static async validateDocument(
    siren: string,
    millesime: number,
    categorie: GatewayConformite.IasIobsp,
    pathname: string
  ) {
    await userSession.axiosInstance.put(
      `${this.getUrl(
        siren,
        categorie,
        millesime
      )}/documents/validate?pathname=${encodeURIComponent(pathname)}`
    );
  }

  private static getUrl(
    siren: string,
    categorie: GatewayConformite.IasIobsp,
    millesime: number
  ) {
    return `/${GatewayGarantieFinanciere.garantieFinanciereUrl}/${siren}/${millesime}/${categorie}`;
  }
}
