import {
  GatewayEntreprise,
  GatewayGarantieFinanciere,
} from "@conformite/gateway";
import {
  Button,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { Link } from "@src/components/Link/Link";
import { MainContentPageTemplate } from "@src/components/MainPageTemplate/MainContentPageTemplate";
import { ReactComponent as ChevronLeft } from "@src/assets/icons/chevron-left.svg";
import { useEntreprises } from "@src/store/store.entreprise";
import { SkeletonCard } from "@src/components/Skeletons/card/SkeletonCard";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { useMillesime } from "@src/store/store.millesime";
import { GatewayGarantieFinanciereApi } from "@src/api/conformite/garantieFinanciere.api";
import { useHasForbiddenTypeAdhesion } from "@src/session/useIsAllowed";
import styles from "./ConformiteGarantieFinanciere.module.scss";
import { ConformiteGarantieFinanciereReadIobsp } from "./read/ConformiteGarantieFinanciereReadIobsp";
import { ConformiteGarantieFinanciereIobspEdit } from "./edit/ConformiteGarantieFinanciereIobspEdit";

export function ConformiteGarantieFinanciereIobsp() {
  const { id } = useParams<{
    id: string;
  }>();
  const [query] = useSearchParams();
  const hasForbiddenTypeAdhesion = useHasForbiddenTypeAdhesion();
  const fromAxe = query.get("fromAxe");
  const { selected: millesime } = useMillesime();
  const { entreprisesBySiren, updateAvancement } = useEntreprises();
  const [isEditing, setIsEditing] = useState(false);
  const [garantieFinanciere, setGarantieFinanciere] =
    useState<GatewayGarantieFinanciere.ConformiteGarantieFinanciereIobsp | null>();
  const [rerender, setRerender] = useState(Math.random());
  const societe = id && entreprisesBySiren ? entreprisesBySiren[id] : undefined;
  useEffect(() => {
    setRerender(Math.random());
  }, [garantieFinanciere]);

  const retrieveGarantieFinanciere = useCallback(() => {
    if (!id || !millesime) return;
    GatewayGarantieFinanciereApi.getGarantieFinanciereIobsp(id, millesime)
      .then((data) => {
        setGarantieFinanciere(data);
      })
      .catch((error) => {
        handleGatewayError({
          onUnhandled: () => {
            displayErrorToast(
              "Une erreur est survenue lors de la récupération du formulaire"
            );
          },
        })(error);
      });
  }, [id, millesime]);
  useEffect(() => {
    retrieveGarantieFinanciere();
  }, [retrieveGarantieFinanciere]);

  const isConforme = societe?.avancement.garantieFinanciereIobsp === 100;

  if (!id) {
    return <Navigate to="/societes" />;
  }
  return (
    <MainContentPageTemplate
      title={
        <div className={styles.title}>
          <h1>Garantie financière IOBSP</h1>
          <h2>{societe?.raisonSociale}</h2>
        </div>
      }
      actionNavigation={
        hasForbiddenTypeAdhesion([
          GatewayEntreprise.TypeAdhesion.SOCIETE_INDIVIDUELLE,
        ]) ? null : (
          <Link
            to={
              fromAxe === null
                ? `/societes/${id}`
                : `/conformite/garantie-financiere/iobsp`
            }
          >
            <Button variant="text" icon={<ChevronLeft />}>
              Retour
            </Button>
          </Link>
        )
      }
    >
      {(!societe || garantieFinanciere === undefined) && (
        <MaxWidthContent size="large">
          <SkeletonCard height={500} />
        </MaxWidthContent>
      )}
      {millesime &&
        societe &&
        garantieFinanciere !== undefined &&
        !isEditing && (
          <ConformiteGarantieFinanciereReadIobsp
            key={rerender}
            siren={societe.siren}
            garantieFinanciere={garantieFinanciere}
            isConforme={isConforme}
            millesime={millesime}
            setToEdit={() => setIsEditing(true)}
          />
        )}
      {millesime &&
        societe &&
        garantieFinanciere !== undefined &&
        isEditing && (
          <ConformiteGarantieFinanciereIobspEdit
            siren={societe.siren}
            millesime={millesime}
            isConforme={isConforme}
            garantieFinanciere={garantieFinanciere}
            stopEditing={(value, avancement) => {
              setIsEditing(false);
              if (value) retrieveGarantieFinanciere();
              if (avancement) updateAvancement(avancement);
            }}
          />
        )}
    </MainContentPageTemplate>
  );
}
