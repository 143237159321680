import { GatewayInfoSocieteOrias } from "@conformite/gateway";
import { intersection } from "lodash";
import { Assujetti } from "@src/store/store.definition";
import { useEntreprises } from "@src/store/store.entreprise";
import { activitesFromEntreprise } from "@src/helper/entreprise.helper";

export const isIas = (categories: GatewayInfoSocieteOrias.Categorie[]) =>
  intersection(GatewayInfoSocieteOrias.CategorieIASList, categories).length > 0;
export const isIobsp = (categories: GatewayInfoSocieteOrias.Categorie[]) =>
  intersection(GatewayInfoSocieteOrias.CategorieIOBSPList, categories).length >
  0;
export const getAssujettiAvancementFromCategories =
  (categories: GatewayInfoSocieteOrias.Categorie[]) =>
  (assujetti: Assujetti): number => {
    const formToKeep = [
      assujetti.avancement.honorabilite,
      isIas(categories)
        ? [
            assujetti.avancement.capaciteProfessionnelleIas,
            assujetti.avancement.formationContinueIas,
          ]
        : [],
      isIobsp(categories)
        ? [
            assujetti.avancement.capaciteProfessionnelleIobsp,
            assujetti.avancement.formationContinueIobsp,
          ]
        : [],
    ].flat();
    return Math.floor(
      formToKeep.reduce<number>((acc, curr) => acc + (curr ?? 0), 0) /
        formToKeep.length
    );
  };
export const useAssujetisAvancement = () => {
  const { entreprisesBySiren } = useEntreprises();
  return {
    getAssujetisAvancement: (assujetti: Assujetti): number => {
      const entreprise = entreprisesBySiren?.[assujetti.sirenCompany];
      if (!entreprise) return 0;
      const categories = activitesFromEntreprise(entreprise);
      return getAssujettiAvancementFromCategories(categories)(assujetti);
    },
  };
};
