import { union } from "lodash";
import { useStore } from "./store.hooks";
import { ContactsWithAssociations } from "./store.definition";

export const useContactsWithAssociations = () => {
  const storeValue = useStore();
  const { setData, data, status } = storeValue.contactsWithAssociations;

  function updateContact(contact: ContactsWithAssociations) {
    setData((prev) =>
      prev?.map((prevVal) => (prevVal.id === contact.id ? contact : prevVal))
    );
  }

  function addContact(contact: ContactsWithAssociations) {
    setData((prev) => union(prev, [contact]));
  }

  function deleteContact(contactId: string) {
    setData((prev) => prev?.filter((contact) => contact.id !== contactId));
  }

  return {
    contacts: data,
    status,
    addContact,
    deleteContact,
    updateContact,
  };
};
