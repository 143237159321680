/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from "react";
import classNames from "classnames";
import { PrismicRichText } from "@prismicio/react";
import { SliceFactoryProps } from "../../SliceManager";
import styles from "./SliceFaq.module.scss";

export function SliceFaq({ slice }: SliceFactoryProps<"faq">) {
  const [isOpen, setIsOpen] = useState(slice.items.map(() => false));
  const [isRubriqueOpen, setIsRubriqueOpen] = useState(false);

  return (
    <div
      className={classNames(styles.container, {
        [styles.active]: isRubriqueOpen,
        [styles.isAccordeon]: slice.primary.is_accordeon,
      })}
    >
      <h2
        className={styles.rubrique}
        onClick={() => setIsRubriqueOpen(!isRubriqueOpen)}
      >
        {slice.primary.rubrique[0].text}
      </h2>
      <div className={styles.list}>
        {slice.items.map((item, i) => (
          <div
            className={classNames(styles.solo, {
              [styles.active]: isOpen[i],
            })}
          >
            <h3
              onClick={() =>
                setIsOpen((prev) => prev.map((v, j) => (i === j ? !v : v)))
              }
              className={styles.question}
            >
              {item.question}
            </h3>
            <div className={styles.response}>
              <PrismicRichText field={item.reponse} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
