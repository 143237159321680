import {
  Button,
  FormAction,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayHonorabilite } from "@conformite/gateway";
import { ReactComponent as SaveIcon } from "@src/assets/icons/save.svg";
import { GatewayHonorabiliteApi } from "@src/api/conformite/honorabilite.api";
import {
  ConformiteStepComponentProps,
  AssujettiParcoursConformiteEnum,
  useChangeStepConformite,
} from "../../AssujettiParcoursConformite";
import styles from "./ConformiteFormHonorabilite.module.scss";
import { FormParcoursConformite } from "../../Components/FormParcoursConformite";
import {
  ComponentWithFormData,
  WithFormData,
} from "../../Components/ComponentWithFormData";
import {
  ConformiteFormHonorabiliteContent,
  useConformiteFormHonorabilite,
} from "./ConformiteFormHonorabiliteContent";

export function ConformiteFormHonorabiliteWithData({
  data,
  formData,
  setFormData,
  changeStep,
  closePane,
  entreprise,
  hideTitle,
  readonly,
}: WithFormData<
  ConformiteStepComponentProps & {
    hideTitle?: boolean;
  },
  GatewayHonorabilite.Honorabilite
>) {
  const { goNext } = useChangeStepConformite(
    AssujettiParcoursConformiteEnum.HONORABILITE,
    changeStep,
    closePane,
    entreprise
  );
  const { formConfig, onSubmit, lastVerifiedAt } =
    useConformiteFormHonorabilite(data, formData, setFormData);

  return (
    <FormParcoursConformite
      readonly={readonly}
      validateOnMount={formData !== null}
      renderActions={(submitAnyway) => (
        <FormAction className={styles.actions}>
          <Button
            variant="text"
            onClick={() => {
              submitAnyway().finally(closePane);
            }}
            type="button"
            iconVariant="left"
            icon={<SaveIcon />}
            disabled={formConfig.formState.isSubmitting}
          >
            {readonly ? "Fermer" : "Sauvegarder et fermer"}
          </Button>

          <Button
            type="submit"
            onClick={() => {
              submitAnyway().finally(goNext);
            }}
            disabled={formConfig.formState.isSubmitting}
          >
            Suivant
          </Button>
        </FormAction>
      )}
      formConfig={formConfig}
      onSubmit={onSubmit}
    >
      <ConformiteFormHonorabiliteContent
        hideTitle={hideTitle}
        data={data}
        lastVerifiedAt={lastVerifiedAt}
      />
    </FormParcoursConformite>
  );
}

export function ConformiteFormHonorabilite({
  data,
  ...otherProps
}: ConformiteStepComponentProps & { hideTitle?: boolean }) {
  return (
    <ComponentWithFormData
      FormComponent={ConformiteFormHonorabiliteWithData}
      formProps={{ data, ...otherProps }}
      fetchData={async () =>
        GatewayHonorabiliteApi.get(data.assujetti.id, data.assujetti.millesime)
      }
    />
  );
}
