import { cleanProperty } from "@src/helper/clean.helper";

export const filterSearchByKeys =
  <T>(keysToCheck: (keyof T)[]) =>
  (content: T, values: string[]) => {
    if (values.length === 0) return true;
    return values.some((search) => {
      const searchCleaned = cleanProperty(search);
      if (!searchCleaned) return true;
      const cleanProperties = keysToCheck.map((key) => {
        const valueToCheck = content[key];
        if (valueToCheck === undefined) {
          return undefined;
        }
        if (typeof valueToCheck !== "string") {
          throw new Error(
            `searchByValue: key ${String(key)} of content is not a string`
          );
        }
        return cleanProperty(valueToCheck);
      });
      return cleanProperties.some((property) =>
        property?.includes(searchCleaned)
      );
    });
  };
