import { ConformiteAssujettiList } from "./ConformiteAssujettiList";

export function ConformiteAssujettiListHonorabilite() {
  return (
    <ConformiteAssujettiList
      title="Honorabilité"
      avancementType="honorabilite"
      backLink="/conformite/honorabilite"
    />
  );
}
