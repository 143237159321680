import {
  Button,
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactElement, useCallback } from "react";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import { ReactComponent as Pencil } from "@src/assets/icons/pencil.svg";
import { ParcoursAction } from "@src/parcours/NavigationButtons/ParcoursAction";
import {
  ParcoursRenouvellementStepComponentProps,
  RenouvellementStepEnum,
} from "@src/renouvellement/ParcoursRenouvellement.definition";
import { useMe } from "@src/store/store.me";
import { GatewayEntreprise } from "@conformite/gateway";
import { SaveAndQuitButton } from "@src/parcours/NavigationButtons/SaveAndQuitButton";
import style from "./RenouvellementResumeEntreprisePage.module.scss";
import { ResumeIdentification } from "./ResumeIdentification";
import { ResumeAdresse } from "./ResumeAdresse";
import { ResumeInfoActivite } from "./ResumeInfoActivite";
import { RenouvellementData } from "../RenouvellementData";
import { ResumeInfoPersonnelle } from "./ResumeInfoPersonnelle";

type ResumeBlockProps = {
  title: string;
  children: ReactElement;
  changeStep: () => void;
};
function ResumeBlock({ title, children, changeStep }: ResumeBlockProps) {
  return (
    <div className={style.resumeBlock}>
      <FormRow>
        <FormField>
          <div className={style.headerWrapper}>
            <h2>{title}</h2>
            <Button icon={<Pencil />} variant="text" onClick={changeStep}>
              Modifier
            </Button>
          </div>
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>{children}</FormField>
      </FormRow>
    </div>
  );
}
export type ResumeBlockComponentProps = { data: RenouvellementData };

export function RenouvellementResumeEntreprisePage({
  data,
  changeStep,
  handleQuit,
}: ParcoursRenouvellementStepComponentProps) {
  const { me } = useMe();
  const handleChangeStep = useCallback(
    (step: RenouvellementStepEnum) => () => changeStep(step),
    [changeStep]
  );
  return (
    <ParcoursFullPageTemplate
      actions={
        <>
          <SaveAndQuitButton onClick={handleQuit} />
          <ParcoursAction
            onClick={() => {
              if (
                me?.entreprisePrincipale.typeAdhesion ===
                GatewayEntreprise.TypeAdhesion.SOCIETE_INDIVIDUELLE
              ) {
                changeStep(RenouvellementStepEnum.CHIFFRE_AFFAIRE);
              } else {
                changeStep(RenouvellementStepEnum.MISE_A_JOUR_SOCIETES);
              }
            }}
          >
            Suivant
          </ParcoursAction>
        </>
      }
    >
      <ResumeBlock
        title="Identification"
        changeStep={handleChangeStep(RenouvellementStepEnum.IDENTIFICATION)}
      >
        <ResumeIdentification data={data} />
      </ResumeBlock>

      <ResumeBlock
        title="Adresse de l'entreprise"
        changeStep={handleChangeStep(RenouvellementStepEnum.ADRESSE)}
      >
        <ResumeAdresse data={data} />
      </ResumeBlock>

      <ResumeBlock
        title="Informations personnelles"
        changeStep={handleChangeStep(
          RenouvellementStepEnum.INFORMATIONS_PERSONNELLES
        )}
      >
        <ResumeInfoPersonnelle data={data} />
      </ResumeBlock>

      {me?.entreprisePrincipale.typeAdhesion ===
        GatewayEntreprise.TypeAdhesion.SOCIETE_INDIVIDUELLE && (
        <ResumeBlock
          title="Informations sur votre activité"
          changeStep={handleChangeStep(
            RenouvellementStepEnum.INFORMATIONS_ACTIVITE
          )}
        >
          <ResumeInfoActivite data={data} />
        </ResumeBlock>
      )}
    </ParcoursFullPageTemplate>
  );
}
