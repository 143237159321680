export enum ControleQuinquennalStatus {
  EN_COURS = 'EN_COURS',
  DEPOSE = 'DEPOSE',
  TERMINE = 'TERMINE',
  ANNULE = 'ANNULE',
}

export enum ControleQuinquennalAxeType {
  ASSUJETTI = 'ASSUJETTI',
  RCPRO_COA = 'RCPRO_COA',
  RCPRO_MIA = 'RCPRO_MIA',
  RCPRO_COBSP = 'RCPRO_COBSP',
  RCPRO_MIOBSP = 'RCPRO_MIOBSP',
  GARANTIE_FINANCIERE_IAS = 'GARANTIE_FINANCIERE_IAS',
  GARANTIE_FINANCIERE_IOBSP = 'GARANTIE_FINANCIERE_IOBSP',
}

export enum ControleQuinquennalAxeStatus {
  EN_COURS = 'EN_COURS',
  VALIDE = 'VALIDE',
  REMEDIE = 'REMEDIE',
}
