import classNames from "classnames";
import { useState, Fragment } from "react";
import { ColumnDefinition } from "../Table.definition";
import { TablePlaceholder } from "./TablePlaceholder";
import { TableSelect } from "./TableSelect";
import { TableSeparator } from "./TableSeparator";
import styles from "../Table.module.scss";
import { TableSelectionState } from "./useTableSelection";

type TableBodyProps<T> = {
  columns: ColumnDefinition<T>[];
  selectionState: TableSelectionState<T> | null;
  data: T[];
  onRowClick?: (row: T, index: number) => void;
  textNoData: string;
  noDarkenColor?: boolean;
};
export function TableBody<T>({
  data,
  columns,
  onRowClick,
  selectionState,
  textNoData,
  noDarkenColor = false,
}: TableBodyProps<T>) {
  const [hoverCell, setHoverCell] = useState<number>();

  const getSeparatorKey = (index: number) => `separator-${index}`;
  const cellIsFirst = (colIndex: number) => {
    const trueIndex = selectionState !== null ? colIndex + 1 : colIndex;
    return trueIndex === 0;
  };
  const cellIsLast = (colIndex: number) => colIndex === columns.length - 1;

  return (
    <>
      {data.map((row, dataIndex) => (
        <Fragment key={JSON.stringify(row) + String(dataIndex)}>
          {selectionState !== null && (
            <div
              role="gridcell"
              data-row={dataIndex}
              className={classNames(styles.cell, {
                [styles.clickable]: onRowClick,
                [styles.hovered]: hoverCell === dataIndex,
                [styles.darken]: dataIndex % 2 === 0,
                [styles.lastRow]: dataIndex === data.length - 1,
                [styles.firstHeaderCell]: true,
                [styles.lastHeaderCell]: columns.length === 0,
              })}
              tabIndex={0}
              onMouseEnter={() => setHoverCell(dataIndex)}
              onMouseLeave={() => setHoverCell(undefined)}
            >
              <div className={styles.cellInner}>
                <TableSelect
                  name={`selectCell-${dataIndex}`}
                  onSelect={(nextSelected) =>
                    selectionState.selectIndex(dataIndex, nextSelected)
                  }
                  selected={selectionState.selectedIndexes[dataIndex]}
                />
              </div>
            </div>
          )}
          {columns.map((column, columnIndex) => {
            return (
              <div
                role="gridcell"
                key={`row-${column.key}`}
                data-row={dataIndex}
                className={classNames(
                  styles.cell,
                  {
                    [styles.clickable]: onRowClick,
                    [styles.hovered]: hoverCell === dataIndex,
                    [styles.darken]: !noDarkenColor && dataIndex % 2 === 0,
                    [styles.lastRow]: dataIndex === data.length - 1,
                    [styles.firstHeaderCell]: cellIsFirst(columnIndex),
                    [styles.lastHeaderCell]: cellIsLast(columnIndex),
                  },
                  column.className
                )}
                onClick={
                  onRowClick ? () => onRowClick(row, dataIndex) : undefined
                }
                onKeyDown={
                  onRowClick ? () => onRowClick(row, dataIndex) : undefined
                }
                tabIndex={0}
                onMouseEnter={() => setHoverCell(dataIndex)}
                onMouseLeave={() => setHoverCell(undefined)}
              >
                <div className={styles.cellInner}>{column.render(row)}</div>
              </div>
            );
          })}
          {dataIndex !== data.length - 1 && (
            <TableSeparator
              key={getSeparatorKey(dataIndex)}
              selectable={selectionState !== null}
              columns={columns}
            />
          )}
        </Fragment>
      ))}
      {data.length === 0 && (
        <TablePlaceholder
          nCol={columns.length}
          textNoData={textNoData}
          selectable={selectionState !== null}
        />
      )}
    </>
  );
}
