import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as XLg } from "@src/assets/icons/x-lg.svg";
import { ReactComponent as Loader } from "@src/assets/loader.svg";
import styles from "./ConformiteSocieteActions.module.scss";

type ConformiteSocieteActionsProps = {
  isLoading: boolean;
  stopEditing: () => void;
  onClick: () => void;
};
export function ConformiteSocieteActions({
  isLoading,
  stopEditing,
  onClick,
}: ConformiteSocieteActionsProps) {
  return (
    <div className={styles.actions}>
      <Button variant="text" icon={<XLg />} onClick={stopEditing}>
        Annuler les modifications
      </Button>
      <Button
        onClick={onClick}
        type="submit"
        icon={isLoading ? <Loader /> : undefined}
      >
        Enregistrer
      </Button>
    </div>
  );
}
