import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as XLg } from "@src/assets/icons/x-lg.svg";
import { ReactComponent as Loader } from "@src/assets/loader.svg";
import style from "./ParametrePageActions.module.scss";

type ParametrePageActionsProps = {
  onCancel: () => void;
  formId: string;
  isLoading?: boolean;
};

export function ParametrePageActions({
  onCancel,
  formId,
  isLoading,
}: ParametrePageActionsProps) {
  return (
    <div className={style.actions}>
      <Button onClick={onCancel} icon={<XLg />} variant="text">
        Annuler les modifications
      </Button>
      <Button
        form={formId}
        type="submit"
        disabled={isLoading}
        icon={isLoading ? <Loader /> : undefined}
      >
        Enregistrer
      </Button>
    </div>
  );
}
