import {
  FieldValues,
  UseFormReturn,
} from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import { SaveAndQuitButton } from "@src/parcours/NavigationButtons/SaveAndQuitButton";

async function saveAndQuit<TValues extends FieldValues, TData>(
  formConfig: UseFormReturn<TValues>,
  addData: (data: Partial<TData>) => void,
  transformValues: (formValues: TValues) => Partial<TData>,
  handleQuit: () => Promise<void>
) {
  addData(transformValues(formConfig.getValues()));
  return handleQuit();
}

type ParcoursAdhesionSaveAndQuitActionProps<
  TValues extends FieldValues,
  TData
> = {
  formConfig?:
    | UseFormReturn<TValues>
    | (() => UseFormReturn<TValues> | undefined);
  addData: (data: Partial<TData>) => void;
  transformValues: (formValues: TValues) => Partial<TData>;
  handleQuit?: () => Promise<void>;
};

export function ParcoursAdhesionSaveAndQuitAction<
  TValues extends FieldValues,
  TData
>({
  addData,
  formConfig,
  handleQuit,
  transformValues,
}: ParcoursAdhesionSaveAndQuitActionProps<TValues, TData>) {
  if (!handleQuit) return null;
  return (
    <SaveAndQuitButton
      onClick={() => {
        const loadedFormConfig =
          typeof formConfig === "function" ? formConfig() : formConfig;
        if (!loadedFormConfig)
          return Promise.reject(new Error("No form config retrieved"));
        return saveAndQuit(
          loadedFormConfig,
          addData,
          transformValues,
          handleQuit
        );
      }}
    />
  );
}
