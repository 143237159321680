import {
  Button,
  Card,
  FormTitle,
  displaySucessToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as ChevronLeft } from "@src/assets/icons/chevron-left.svg";
import { Link } from "@src/components/Link/Link";
import { MainContentPageTemplate } from "@src/components/MainPageTemplate/MainContentPageTemplate";
import { useAssujetti } from "@src/store/store.assujetti";
import { useEntreprises } from "@src/store/store.entreprise";
import { useParams, useSearchParams } from "react-router-dom";
import { IconStatus } from "@src/components/IconStatus/IconStatus";
import {
  ConformiteFormHonorabiliteContent,
  useConformiteFormHonorabilite,
} from "@src/assujettis/AssujettiPaneContent/pages/AssujettiParcoursConformite/Steps/ConformiteFormHonorabilite/ConformiteFormHonorabiliteContent";
import { useEffect, useState } from "react";
import { GatewayHonorabiliteApi } from "@src/api/conformite/honorabilite.api";
import {
  GatewayAssujetti,
  GatewayEntreprise,
  GatewayHonorabilite,
} from "@conformite/gateway";
import { Form } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { useOnce } from "@src/hooks/useOnce";
import styles from "../assujettis/AssujettiForm.module.scss";

type ConformiteHonorabiliteContentProps = {
  assujetti: GatewayAssujetti.AssujettiWithAvancement;
  entreprise: GatewayEntreprise.EntrepriseAControllerResponseType;
  fromAxe: string | null;
  honorabilite: GatewayHonorabilite.Honorabilite | null;
};
function ConformiteHonorabiliteContent({
  assujetti,
  fromAxe,
  entreprise,
  honorabilite,
}: ConformiteHonorabiliteContentProps) {
  const { lastVerifiedAt, onSubmit, formConfig } =
    useConformiteFormHonorabilite({ assujetti }, honorabilite, () => {
      displaySucessToast("La conformité a bien été mise à jour");
    });
  useOnce(() => {
    if (honorabilite !== null) formConfig.trigger().catch(console.error);
  });
  if (!entreprise || !assujetti) return null;
  return (
    <MainContentPageTemplate
      title={
        <FormTitle className={styles.header}>
          <h1 className={styles.title}>
            <b>Honorabilité</b> - {assujetti.surname} {assujetti.name}
          </h1>
          <IconStatus isValid={assujetti.avancement.honorabilite === 100} />
        </FormTitle>
      }
      actionNavigation={
        <Link
          to={`/conformite/honorabilite/${entreprise.siren}${
            fromAxe === null ? "" : "?fromAxe"
          }`}
        >
          <Button variant="text" icon={<ChevronLeft />}>
            Retour
          </Button>
        </Link>
      }
    >
      <Card>
        <Form formConfig={formConfig} onSubmit={onSubmit}>
          <ConformiteFormHonorabiliteContent
            hideTitle
            data={{ assujetti }}
            lastVerifiedAt={lastVerifiedAt}
          />
        </Form>
        <div className={styles.action}>
          <Button
            onClick={() => {
              onSubmit(formConfig.getValues()).catch((e) => {
                console.error(e);
              });
            }}
          >
            Sauvegarder
          </Button>
        </div>
      </Card>
    </MainContentPageTemplate>
  );
}

export function ConformiteHonorabilite() {
  const { entreprisesBySiren } = useEntreprises();
  const { assujettis } = useAssujetti();
  const { siren, id } = useParams();
  const entreprise = entreprisesBySiren?.[siren || ""];
  const assujetti = assujettis?.[id || ""];
  const [query] = useSearchParams();
  const fromAxe = query.get("fromAxe");
  const [honorabilite, setHonorabilite] =
    useState<GatewayHonorabilite.Honorabilite | null>();
  useEffect(() => {
    if (!assujetti) return;
    GatewayHonorabiliteApi.get(assujetti.id, assujetti.millesime)
      .then((data) => {
        setHonorabilite(data);
      })
      .catch((e) => {
        console.error(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assujetti]);
  if (!entreprise || !assujetti || !siren || honorabilite === undefined)
    return null;
  return (
    <ConformiteHonorabiliteContent
      entreprise={entreprise}
      assujetti={assujetti}
      fromAxe={fromAxe}
      honorabilite={honorabilite}
    />
  );
}
