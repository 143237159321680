import { clamp } from "lodash";
import classNames from "classnames";
import styles from "./LoaderBar.module.scss";

type LoaderBarProps = {
  progress: number;
  total: number;
};
export function LoaderBar({ progress, total }: LoaderBarProps) {
  const percentage = clamp(total === 0 ? 0 : (progress / total) * 100, 0, 100);
  return (
    <div className={styles.loaderContainer}>
      <div
        style={{
          width: `${percentage}%`,
        }}
        className={classNames(styles.loaderContent, {
          [styles.complete]: percentage === 100,
        })}
      />
    </div>
  );
}
