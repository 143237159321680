import { GatewayControleQuinquennal } from "@conformite/gateway";
import { ControleQuinquennalApi } from "@src/api/controleQuinquennal.api";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAssujetti } from "@src/store/store.assujetti";
import { useControleQuinquennalItem } from "../item/useControleQuinquennalItem";

const getAxeBefore = (
  controleQuinquennal:
    | GatewayControleQuinquennal.ControleQuinquennalWithAxes
    | undefined,
  indexAxe: number | undefined,
  axe: GatewayControleQuinquennal.ControleQuinquennalAxeWithFiles | undefined,
  axesRemedies: GatewayControleQuinquennal.ControleQuinquennalAxeWithNumberOfFiles[] = []
) => {
  if (!controleQuinquennal || indexAxe === undefined || indexAxe === 0)
    return undefined;
  if (axesRemedies.length > 0) {
    const firstAxeRemedie = axesRemedies[0];
    if (firstAxeRemedie.type === axe?.type) return undefined;
    const axePos = axesRemedies.findIndex(
      (axeToFind) => axeToFind.idAxe === axe?.idAxe
    );
    if (axePos) {
      return axesRemedies[axePos - 1];
    }
  }
  return controleQuinquennal?.controleQuinquennalAxes[indexAxe - 1];
};
const getAxeAfter = (
  controleQuinquennal:
    | GatewayControleQuinquennal.ControleQuinquennalWithAxes
    | undefined,
  indexAxe: number | undefined,
  axe: GatewayControleQuinquennal.ControleQuinquennalAxeWithFiles | undefined,
  axesRemedies: GatewayControleQuinquennal.ControleQuinquennalAxeWithNumberOfFiles[] = []
) => {
  if (!controleQuinquennal || indexAxe === undefined) return undefined;
  if (indexAxe > controleQuinquennal.controleQuinquennalAxes.length)
    return undefined;
  if (axesRemedies.length > 0) {
    const lastAxeRemedie = axesRemedies[axesRemedies.length - 1];
    if (lastAxeRemedie.type === axe?.type) return undefined;
    const axePos = axesRemedies.findIndex(
      (axeToFind) => axeToFind.idAxe === axe?.idAxe
    );
    if (axePos) {
      return axesRemedies[axePos + 1];
    }
  }
  return controleQuinquennal?.controleQuinquennalAxes[indexAxe + 1];
};
export const useControleQuinquennalAxe = () => {
  const { id, idAxe } = useParams<{ id: string; idAxe: string }>();
  const [axe, setAxe] =
    useState<GatewayControleQuinquennal.ControleQuinquennalAxeWithFiles>();
  const [remediations, setRemediations] = useState<
    GatewayControleQuinquennal.ControleQuinquennalRemediationWithFileResponseType[]
  >([]);
  const { assujettisBySocietes } = useAssujetti();
  useEffect(() => {
    if (!id || !idAxe) return;
    ControleQuinquennalApi.getControleQuinquennalAxe(id, idAxe)
      .then((res) => {
        setAxe(res);
      })
      .catch(console.error);
    ControleQuinquennalApi.getRemediationByAxeId(id, idAxe)
      .then((res) => {
        setRemediations(res);
      })
      .catch(console.error);
  }, [id, idAxe]);
  const { controleQuinquennal, entreprise } = useControleQuinquennalItem();
  const indexAxe = controleQuinquennal?.controleQuinquennalAxes.findIndex(
    (a) => a.idAxe === idAxe
  );

  const axesRemedies =
    controleQuinquennal?.controleQuinquennalAxes.filter(
      (axeToFilter) =>
        axeToFilter.status ===
        GatewayControleQuinquennal.ControleQuinquennalAxeStatus.REMEDIE
    ) ?? [];
  return {
    axe,
    remediations,
    idControleQuinquennal: id,
    millesime: controleQuinquennal?.millesime ?? 0,
    siren: entreprise?.siren ?? "",
    isReadonly:
      controleQuinquennal?.status !==
        GatewayControleQuinquennal.ControleQuinquennalStatus.EN_COURS ||
      axe?.status ===
        GatewayControleQuinquennal.ControleQuinquennalAxeStatus.VALIDE,
    axeBefore: getAxeBefore(controleQuinquennal, indexAxe, axe, axesRemedies),
    axeAfter: getAxeAfter(controleQuinquennal, indexAxe, axe, axesRemedies),
    numberOfAssujettis:
      assujettisBySocietes[entreprise?.siren ?? ""]?.length ?? 0,
  };
};
