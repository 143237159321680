import {
  UseFormReturn,
  useFieldArray,
} from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import { FileRead } from "@src/components/FileRead/FileRead";
import { FileInputButton } from "@src/components/FileInputButton/FileInputButton";
import { useMillesime } from "@src/store/store.millesime";
import { millesimeHasEveryDay } from "@src/conformite/conformite.helper";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import styles from "../ConformiteGarantieFinanciere.module.scss";
import { ConformiteGarantieFinanciereEditFileValues } from "./ConformiteGarantieFinanciereEditFileValues";

export type FormWithFiles = UseFormReturn<{
  files: {
    pathname?: string;
    filename?: string;
    startDate?: Date;
    endDate?: Date;
    file?: File;
    isValid: boolean;
  }[];
}>;

type ConformiteGarantieFinanciereEditFilesProps = {
  form: FormWithFiles;
  disabled?: boolean;
  siren: string;
};
export function ConformiteGarantieFinanciereEditFiles({
  form,
  disabled,
  siren,
}: ConformiteGarantieFinanciereEditFilesProps) {
  const { files: filesWatched } = form.watch();
  const { selected: millesime } = useMillesime();
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "files",
  });
  const fileLabel = "Ajouter l'attestation de couverture";
  const shouldShowAddFile =
    millesime &&
    !disabled &&
    (!filesWatched ||
      (filesWatched.every((file) => !!file?.startDate && !!file?.endDate) &&
        !millesimeHasEveryDay(millesime, filesWatched)));

  return (
    <div className={styles.filesContainer}>
      {fields.map((field, index) => (
        <div key={field.id} className={styles.fileContainer}>
          <FileRead
            name={field.filename}
            deletable={!disabled}
            onDelete={() => {
              remove(index);
            }}
            siren={siren}
            millesime={millesime}
            filename={field.filename}
            pathname={field.pathname}
          />
          {!field.isValid && (
            <InfoCard type="error">
              Votre document n&apos;est pas valide. Veuillez le remplacer par un
              document valide.
            </InfoCard>
          )}

          <ConformiteGarantieFinanciereEditFileValues
            index={index}
            form={form}
          />
        </div>
      ))}
      {shouldShowAddFile && (
        <FileInputButton
          accept="application/pdf"
          variant="input"
          onChange={(e) => {
            const file = e.target.files?.item(0);
            if (!file) return;
            append({
              filename: file.name,
              file,
              isValid: true,
            });
          }}
        >
          {fileLabel}
        </FileInputButton>
      )}
    </div>
  );
}
