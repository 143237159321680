export enum AxeConformiteAssujetti {
  HONORABILITE = 'HONORABILITE',
  FORMATION_CONTINUE_IAS = 'FORME_CONTINUE_IAS',
  FORMATION_CONTINUE_IOBSP = 'FORMATION_CONTINUE_IOBSP',
  CAPACITE_PRO_IAS = 'CAPACITE_PRO_IAS',
  CAPACITE_PRO_IOBSP = 'CAPACITE_PRO_IOBSP',
}

export enum AxeConformiteEntreprise {
  RC_PRO_COA = 'RC_PRO_COA',
  RC_PRO_MIA = 'RC_PRO_MIA',
  RC_PRO_COBSP = 'RC_PRO_COBSP',
  RC_PRO_MIOBSP = 'RC_PRO_IOBSP',
  GARANTIE_FINANCIERE_IAS = 'GARANTIE_FINANCIERE_IAS',
  GARANTIE_FINANCIERE_IOBSP = 'GARANTIE_FINANCIERE_IOBSP',
  DECLARATION_ACTIVITE_IAS = 'DECLARATION_ACTIVITE_IAS',
  DECLARATION_ACTIVITE_IOBSP = 'DECLARATION_ACTIVITE_IOBSP',
}

export type AxeConformite = AxeConformiteAssujetti | AxeConformiteEntreprise;
export const AxeConformite = {
  ...AxeConformiteAssujetti,
  ...AxeConformiteEntreprise,
};
