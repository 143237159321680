import { UIInputCheckbox } from "@lya-protect/lya-protect-form-library/dist/UI";
import classNames from "classnames";
import { ChangeEvent } from "react";
import styles from "../Table.module.scss";

type TableSelectProps = {
  onSelect: (selected: boolean) => void;
  selected: boolean;
  name: string;
};

export function TableSelect({ onSelect, name, selected }: TableSelectProps) {
  return (
    <div className={classNames(styles.selectCell)}>
      <UIInputCheckbox
        name={name}
        value={selected}
        hasError={false}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onSelect(e.target.checked)
        }
      />
    </div>
  );
}
