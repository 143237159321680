import classNames from "classnames";
import styles from "./FieldSet.module.scss";

type FieldSetProps = {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
};
export function FieldSet({ children, className, disabled }: FieldSetProps) {
  return (
    <fieldset
      disabled={disabled}
      className={classNames(styles.fieldset, className)}
    >
      {children}
    </fieldset>
  );
}
