import { Card } from "@lya-protect/lya-protect-form-library/dist/UI";
import {
  GatewayAvancement,
  GatewayGarantieFinanciere,
} from "@conformite/gateway";
import { TagConformite } from "@src/components/Tag/TagConformite";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { ConformiteSocieteActions } from "@src/conformite/societeActions/ConformiteSocieteActions";
import styles from "../ConformiteGarantieFinanciere.module.scss";
import {
  ConformiteGarantieFinanciereEditFormIobsp,
  useConformiteGarantieFinanciereIobspEdit,
} from "./ConformiteGarantieFinanciereIobspEditForm";

type ConformiteGarantieFinanciereIobspEditProps = {
  isConforme: boolean;
  millesime: number;
  garantieFinanciere: GatewayGarantieFinanciere.ConformiteGarantieFinanciereIobsp | null;
  stopEditing: (
    value?: GatewayGarantieFinanciere.ConformiteGarantieFinanciereIobsp,
    avancement?: GatewayAvancement.AvancementSociete
  ) => void;
  disabled?: boolean;
  siren: string;
};

export function ConformiteGarantieFinanciereIobspEdit({
  isConforme,
  millesime,
  stopEditing,
  garantieFinanciere,
  disabled,
  siren,
}: ConformiteGarantieFinanciereIobspEditProps) {
  const { form, onSubmit } = useConformiteGarantieFinanciereIobspEdit(
    siren,
    millesime,
    garantieFinanciere,
    stopEditing
  );

  return (
    <MaxWidthContent size="large" className={styles.contentEditable}>
      <Card className={styles.content}>
        <div className={styles.cardHeaderLeft}>
          <h3>Formulaire</h3>
          <TagConformite isConforme={isConforme} year={millesime} isEditing />
        </div>
        <ConformiteGarantieFinanciereEditFormIobsp
          form={form}
          millesime={millesime}
          garantieFinanciere={garantieFinanciere}
          siren={siren}
          disabled={disabled}
        />
        {!disabled && (
          <ConformiteSocieteActions
            onClick={() => {
              onSubmit(form.getValues()).catch(console.error);
            }}
            isLoading={false}
            stopEditing={() => stopEditing()}
          />
        )}
      </Card>
    </MaxWidthContent>
  );
}
