import { useCallback } from "react";
import { ParcoursAction } from "@src/parcours/NavigationButtons/ParcoursAction";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import {
  ID_FORM_RENOUVELLEMENT,
  ParcoursRenouvellementStepComponentProps,
  RenouvellementStepEnum,
} from "@src/renouvellement/ParcoursRenouvellement.definition";
import {
  RenouvellementEntrepriseInformationsComplementairesForm,
  RenouvellementEntrepriseInformationsComplementairesFormValues,
} from "./RenouvellementInformationsComplementairesForm";

export function RenouvellementInformationsComplementairesPage({
  data,
  setData,
  changeStep,
}: ParcoursRenouvellementStepComponentProps) {
  const entreprise =
    data.entreprisesAControler[data.resume.entreprisePrincipale.siren];

  const handleSubmit = useCallback(
    (values: RenouvellementEntrepriseInformationsComplementairesFormValues) => {
      setData({
        ...data,
        entreprisesAControler: {
          ...data.entreprisesAControler,
          [entreprise.siren]: {
            ...entreprise,
            categorieRepresentation: values.categorieRepresentation,
          },
        },
      });
      changeStep(RenouvellementStepEnum.RESUME_ENTREPRISE);
    },
    [setData, data, entreprise, changeStep]
  );

  return (
    <ParcoursFullPageTemplate
      title="Informations complémentaires"
      actions={
        <ParcoursAction type="submit" formId={ID_FORM_RENOUVELLEMENT}>
          Enregistrer les modifications
        </ParcoursAction>
      }
    >
      <RenouvellementEntrepriseInformationsComplementairesForm
        formId={ID_FORM_RENOUVELLEMENT}
        defaultValues={{
          categorieRepresentation: entreprise.categorieRepresentation,
        }}
        onSubmit={handleSubmit}
      />
    </ParcoursFullPageTemplate>
  );
}
