import {
  Card,
  Emphasis,
  FormGrid,
  FormNotice,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import {
  EntrepriseInformationsPersonnellesForm,
  EntrepriseInformationsPersonnellesFormValues,
} from "@src/societes/form/EntrepriseInformationsPersonnellesForm";
import { useCallback, useState } from "react";
import { SkeletonCard } from "@src/components/Skeletons/card/SkeletonCard";
import { useMe } from "@src/store/store.me";
import { GatewayMeApi } from "@src/api/me.api";
import { GatewayMe } from "@conformite/gateway";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { ParametrePageTemplate } from "../ParametrePageTemplate";
import { ParametrePageActions } from "../ParametrePageActions";

const FORM_ID = "infos-perso-form-id";
export function InformationsPersonnelles() {
  const { me, updateMe } = useMe();
  const [isLoading, setIsLoading] = useState(false);
  const [formKey, setFormKey] = useState(0);
  const [showActions, setShowActions] = useState(false);

  const handleResetForm = useCallback(() => {
    setFormKey((prev) => prev + 1);
  }, []);

  const handleSubmit = useCallback(
    (values: EntrepriseInformationsPersonnellesFormValues) => {
      setIsLoading(true);
      GatewayMeApi.update(values as GatewayMe.UpdateMeRequestType)
        .then((response) => {
          updateMe(response);
          handleResetForm();
        })
        .catch(
          handleGatewayError({
            onUnhandled: (error) => {
              console.error(error);
              displayErrorToast(
                "Une erreur est survenue lors de la modification de vos informations"
              );
            },
          })
        )
        .finally(() => setIsLoading(false));
    },
    [updateMe, handleResetForm]
  );

  return (
    <ParametrePageTemplate
      title="Informations personnelles"
      actions={
        showActions && (
          <ParametrePageActions
            onCancel={handleResetForm}
            formId={FORM_ID}
            isLoading={isLoading}
          />
        )
      }
    >
      {!me && <SkeletonCard height={500} />}
      {me && (
        <Card>
          <FormGrid disableHorizontalGutter>
            <FormNotice>
              <Emphasis>*</Emphasis> Champs obligatoires
            </FormNotice>

            <EntrepriseInformationsPersonnellesForm
              key={formKey}
              setIsDirty={setShowActions}
              defaultValues={{
                civilite: me.contact.civilite,
                nom: me.contact.nom,
                prenom: me.contact.prenom,
                telephone: me.contact.telephone,
                fonction: me.contact.fonction,
              }}
              onSubmit={handleSubmit}
              formId={FORM_ID}
            />
          </FormGrid>
        </Card>
      )}
    </ParametrePageTemplate>
  );
}
