function LinkDocumentationHere() {
  return <a href="https://aide.endya.fr/faq/outils-conformite">ici</a>;
}

export function ControleQuinquennalExplainAxeAssujetti() {
  return (
    <div>
      <p>
        Les documents sollicités ci-dessous permettront de vérifier le respect
        de l’exigence d’honorabilité (collaborateurs), l’adéquation du niveau de
        capacité professionnelle initiale avec les missions exercées et le cas
        échéant le suivi d’une formation continue annuelle adaptée aux produits
        distribués et aux fonctions occupées (dirigeants + collaborateurs).
      </p>
      <br />
      <p>Pièces à fournir pour chaque personne assujettie :</p>
      <ul>
        <li>
          <b>Honorabilité – collaborateur(s) uniquement : </b>
        </li>
        <ul>
          <li>Déclaration sur l’honneur ou formulaire B3 de moins d’1 an </li>
        </ul>
      </ul>

      <ul>
        <li>
          <b>
            Capacité professionnelle initiale – collaborateur(s) uniquement :
          </b>
        </li>
        <ul>
          <li>Organigramme</li>
          <li>Fiche(s) de poste des collaborateurs</li>
          <li>
            Justificatif(s) de capacité professionnelle initiale IAS et/ou IOBSP
            tel(s) que déclaré(s) lors de l’adhésion (Attestations de
            fonctions/Diplômes/Formations)
          </li>
        </ul>
      </ul>

      <ul>
        <li>
          <b>
            Formation continue annuelle (N-1) – dirigeant(s) et collaborateur(s)
            :
          </b>
        </li>
        <ul>
          <li>Registre de formation continue</li>
          <li>
            Certificat(s) de chacune des formations suivies dans l’année N-1 par
            le(s) dirigeant(s) et collaborateur(s) pour les activités d’IAS
            et/ou d’IOBSP
          </li>
          <li>Programme(s) de chacune des formations suivies</li>
        </ul>
      </ul>
      <ul>
        <li>
          <b>
            Si vous exercez l’activité de distribution d&apos;assurance à titre
            accessoire au sens de l&apos;article L 511-1 III du code des
            assurances et afin de justifier l&apos;absence de formation :
          </b>
        </li>
        <ul>
          <li>
            Déclaration sur l&apos;honneur attestant de la pratique de
            l&apos;activité d&apos;IAS à titre accessoire accessible dans la
            documentation Endya disponible <LinkDocumentationHere />.
          </li>
        </ul>
      </ul>
    </div>
  );
}
