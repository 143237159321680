import { useNavigate } from "react-router-dom";
import { useEntrepriseAvancement } from "@src/societes/useEntrepriseAvancement";
import { GatewayInfoSocieteOrias } from "@conformite/gateway";
import { ConformiteSocieteList } from "./ConformiteSocieteList";

export function ConformiteSocieteListCapaciteProfessionnelleIas() {
  const navigate = useNavigate();
  const { getEntrepriseAvancementForAssujettiAxe } = useEntrepriseAvancement();

  return (
    <ConformiteSocieteList
      getFormAvancement={(entreprise) =>
        getEntrepriseAvancementForAssujettiAxe(
          entreprise,
          "capaciteProfessionnelleIas"
        )
      }
      title="Capacité Professionnelle IAS"
      onSocieteRedirect={(entreprise) => {
        navigate(`${entreprise.siren}?fromAxe`);
      }}
      defaultFilters={{
        categories: GatewayInfoSocieteOrias.CategorieIASList,
      }}
    />
  );
}
