import { Button, Card } from "@lya-protect/lya-protect-form-library/dist/UI";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ReactComponent as Loader } from "@src/assets/loader.svg";
import { ReactComponent as XCircle } from "@src/assets/icons/x-circle.svg";
import { ReactComponent as CheckCircle } from "@src/assets/icons/check-circle-outline.svg";
import classNames from "classnames";
import { GatewayGarantieFinanciereApi } from "@src/api/conformite/garantieFinanciere.api";
import styles from "./Document.module.scss";

function getValidParams(
  siren?: string,
  millesime?: string,
  categorie?: string,
  pathname?: string | null
) {
  if (
    !siren ||
    !millesime ||
    !/^\d+$/.test(millesime) ||
    !categorie ||
    !["ias", "iobsp"].includes(categorie) ||
    !pathname
  ) {
    return null;
  }
  return {
    siren,
    millesime: Number(millesime),
    categorie: categorie as "ias" | "iobsp",
    pathname,
  };
}

export function GarantieFinanciereDocumentValidator() {
  const [query] = useSearchParams();
  const pathname = query.get("pathname");
  const { siren, millesime, categorie } = useParams<{
    siren: string;
    millesime: string;
    categorie: string;
  }>();
  const [status, setStatus] = useState<"idle" | "error" | "success">("idle");

  const validParams = getValidParams(siren, millesime, categorie, pathname);

  useEffect(() => {
    if (!validParams) return;

    GatewayGarantieFinanciereApi.validateDocument(
      validParams.siren,
      validParams.millesime,
      validParams.categorie,
      validParams.pathname
    )
      .then(() => {
        setStatus("success");
      })
      .catch(
        handleGatewayError({
          onUnhandled: (error) => {
            console.error(error);
            setStatus("error");
          },
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!validParams) {
    return (
      <Card className={styles.card}>
        <>
          <div className={styles.header}>
            <XCircle className={styles.icon} />
            <h1>OUPS</h1>
          </div>
          <p>
            Les paramètres de validations sont invalides, vérifiez l&apos;url
          </p>
        </>
      </Card>
    );
  }

  if (status === "idle") {
    return <Loader />;
  }
  return (
    <Card className={styles.card}>
      {status === "error" && (
        <>
          <div className={styles.header}>
            <XCircle className={styles.icon} />
            <h1>OUPS</h1>
          </div>
          <p>une erreur est survenue lors de la validation du document.</p>
        </>
      )}
      {status === "success" && (
        <>
          <div className={styles.header}>
            <CheckCircle
              className={classNames(styles.icon, styles.iconSuccess)}
            />
            <h1>Le document a été validé avec succès</h1>
          </div>
          <p>
            La validation du document a été prise en compte. Vous pouvez fermer
            cette page
          </p>
        </>
      )}
      <Button
        onClick={() => {
          window.close();
        }}
      >
        Fermer la page
      </Button>
    </Card>
  );
}
