import {
  GatewayAuthentication,
  GatewayControleQuinquennal,
} from "@conformite/gateway";
import { Button, Card } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as ChevronDown } from "@src/assets/icons/chevron-down.svg";
import { ReactComponent as ChevronUp } from "@src/assets/icons/chevron-up.svg";
import { ReactComponent as Pencil } from "@src/assets/icons/pencil.svg";
import { DownloadFile } from "@src/components/DownloadFile/DownloadFile";
import { useIsAllowed } from "@src/session/useIsAllowed";
import { format } from "date-fns";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ControleQuinquennalAxeRemediation.module.scss";

type ControleQuinquennalAxeRemediationProps = {
  remediation: GatewayControleQuinquennal.ControleQuinquennalRemediationWithFileResponseType;
  defaultOpen: boolean;
  siren: string;
  millesime: number;
  onDeleteRemediation: (idRemediation: string) => void;
};
export function ControleQuinquennalAxeRemediation({
  defaultOpen,
  remediation,
  siren,
  millesime,
  onDeleteRemediation,
}: ControleQuinquennalAxeRemediationProps) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const { hasAllowedCognitoRole } = useIsAllowed();
  return (
    <div className={styles.remediation}>
      <div
        className={styles.header}
        onClick={() => setIsOpen((old) => !old)}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter") setIsOpen((old) => !old);
        }}
      >
        <p className={styles.titleContainer}>
          <span className={styles.title}>Commentaire du contrôleur</span>
          <span>-</span>
          <span>
            {format(new Date(remediation.creationDate), "dd/MM/yyyy")}
          </span>
        </p>
        <Button
          variant="text"
          icon={isOpen ? <ChevronUp /> : <ChevronDown />}
        />
      </div>
      {isOpen && (
        <div>
          <p className={styles.description}>{remediation.description}</p>
          <div className={styles.filesWithActions}>
            <div className={styles.files}>
              {remediation.files.map((file) => (
                <div className={styles.file} key={file.idFile}>
                  {file.filename}
                  <DownloadFile
                    filename={`document_remediation_${new Date().toISOString()}.pdf`}
                    millesime={millesime}
                    siren={siren}
                    pathname={file.pathname}
                  />
                </div>
              ))}
            </div>
            {hasAllowedCognitoRole(GatewayAuthentication.Role.ENDYA) && (
              <div className={styles.actions}>
                <Button
                  icon={<Pencil />}
                  variant="text"
                  onClick={() => {
                    navigate(`remediation/${remediation.id}`);
                  }}
                >
                  Modifier le commentaire
                </Button>
                <Button
                  variant="text"
                  className={styles.deleteButton}
                  onClick={() => {
                    onDeleteRemediation(remediation.id);
                  }}
                >
                  Supprimer
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
type ControleQuinquennalAxeRemediationsProps = {
  remediations: GatewayControleQuinquennal.ControleQuinquennalRemediationWithFileResponseType[];
  siren: string;
  millesime: number;
  onDeleteRemediation?: (idRemediation: string) => Promise<void>;
};
export function ControleQuinquennalAxeRemediations({
  remediations,
  siren,
  millesime,
  onDeleteRemediation,
}: ControleQuinquennalAxeRemediationsProps) {
  return (
    <Card className={styles.remediations}>
      {remediations.map((remediation, index) => (
        <ControleQuinquennalAxeRemediation
          remediation={remediation}
          key={remediation.id}
          defaultOpen={index === 0}
          siren={siren}
          millesime={millesime}
          onDeleteRemediation={(idRemediation) => {
            onDeleteRemediation?.(idRemediation).finally(() => null);
          }}
        />
      ))}
    </Card>
  );
}
