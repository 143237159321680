import { useMemo, useState } from "react";
import { chain } from "lodash";
import { GatewayInfoSocieteOrias } from "@conformite/gateway";
import { cleanProperty } from "@src/helper/clean.helper";

export const SOCIETES_PAGE_SIZE = 25;

export type UseRenouvellementSocieteFilteredReturn<
  T extends {
    siren?: string;
    raisonSociale?: string;
  }
> = {
  societes: T[];
  numberOfSocietes: number;
  societesPaginated: T[];
  search?: string;
  page: number;
  setSearch: (search?: string) => void;
  setPage: (page: number) => void;
};
export const useRenouvellementSocieteFiltered = <
  T extends {
    siren?: string;
    raisonSociale?: string;
    categoriesActivites?: GatewayInfoSocieteOrias.Categorie[];
    categoriesActivitesAccessoire?: GatewayInfoSocieteOrias.Categorie[];
  }
>(
  societes: T[]
): UseRenouvellementSocieteFilteredReturn<T> => {
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState<string>();
  const societesFiltered = useMemo(
    () =>
      chain(societes)
        .sort(
          (societe1, societe2) =>
            societe1.raisonSociale?.localeCompare(
              societe2.raisonSociale || ""
            ) || 0
        )
        .value(),
    [societes]
  );
  const societeSearchFiltered = useMemo(
    () =>
      societesFiltered.filter((societe) => {
        const searchCleaned = cleanProperty(search);
        if (!searchCleaned) return true;
        const raisonSocialCleaned = cleanProperty(societe.raisonSociale);
        const sirenCleaned = cleanProperty(societe.siren);
        return (
          !!raisonSocialCleaned?.includes(searchCleaned) ||
          !!sirenCleaned?.includes(searchCleaned)
        );
      }),
    [societesFiltered, search]
  );

  const societesPaginated = useMemo(() => {
    return societeSearchFiltered.slice(
      page * SOCIETES_PAGE_SIZE,
      (page + 1) * SOCIETES_PAGE_SIZE
    );
  }, [societeSearchFiltered, page]);
  return {
    societes: societeSearchFiltered,
    numberOfSocietes: societesFiltered.length,
    societesPaginated,
    search,
    page,
    setSearch,
    setPage,
  };
};
