import { PrismicImage } from "@prismicio/react";
import { Link } from "@src/components/Link/Link";
import {
  Card,
  FormField,
  FormGrid,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { SliceFactoryProps } from "../../SliceManager";
import styles from "./SliceListPartenaires.module.scss";

export function SliceListPartenaires({
  slice,
}: SliceFactoryProps<"liste_partenaires">) {
  return (
    <FormGrid disableHorizontalGutter>
      <FormRow>
        <FormField>
          <h2>{slice.primary.titre[0].text}</h2>
        </FormField>
      </FormRow>
      <FormRow size="full" className={styles.list}>
        {slice.items.map((item) => (
          <FormField key={item.nom} size="half" className={styles.item}>
            <Link to={`/services/${item.lien.uid}`} className={styles.link}>
              <Card className={styles.card}>
                <PrismicImage field={item.logo} width={50} height={50} />
                <div className={styles.col}>
                  <span className={styles.name}> {item.nom}</span>
                  <span className={styles.desc}>{item.description}</span>
                </div>
              </Card>
            </Link>
          </FormField>
        ))}
      </FormRow>
    </FormGrid>
  );
}
