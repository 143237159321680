import { GatewayContact } from "@conformite/gateway";
import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as AddIcon } from "@src/assets/icons/plus-circle.svg";
import { AssujettiList } from "@src/components/Assujetti/AssujettiList";
import { ComplianceProgressBar } from "@src/components/ComplianceProgressBar/ComplianceProgressBar";
import { useDialog } from "@src/components/Dialog/useDialog";
import { Dropdown } from "@src/components/DropDown/Dropdown";
import { SidePane } from "@src/components/SidePane/SidePane";
import { useIsAllowed } from "@src/session/useIsAllowed";
import { Assujetti } from "@src/store/store.definition";
import { useMe } from "@src/store/store.me";
import { useCallback } from "react";
import { AssujettiPaneContent } from "../AssujettiPaneContent/AssujettiPaneContent";
import { useAssujettiPane } from "../AssujettiPaneContent/useAssujettiPane";
import { ExportAssujettiButton } from "../ImportExport/Export/ExportAssujettiButton";
import { ImportAssujettiDialog } from "../ImportExport/Import/ImportAssujettiDialog";
import { useAssujetisAvancement } from "../useAssujettiAvancement";
import styles from "./AssujettiMainList.module.scss";
import { useAssujettiFilter } from "./useAssujettiFilter";

type AssujettiMainListProps = {
  millesime: number;
  assujettis?: Assujetti[];
  setEntry: (entry: Assujetti) => void;
  removeEntries: (ids: string[], millesime: number) => void;
  setEntries: (assujettis: Assujetti[], clean: boolean) => void;
};

export function AssujettiMainList({
  assujettis,
  setEntry,
  removeEntries,
  setEntries,
  millesime,
}: AssujettiMainListProps) {
  const { me } = useMe();
  const props = useAssujettiFilter(assujettis);
  const [assujettiPaneState, setAssujettiPaneState] = useAssujettiPane();
  const { openDialog } = useDialog();

  const { hasAllowedRole } = useIsAllowed();
  const { getAssujetisAvancement } = useAssujetisAvancement();
  const AssujettiProgress = useCallback(
    ({ assujetti }: { assujetti: Assujetti }) => (
      <ComplianceProgressBar progress={getAssujetisAvancement(assujetti)} />
    ),
    [getAssujetisAvancement]
  );

  if (!me) return <div />;

  return (
    <>
      <AssujettiList
        {...props}
        removeEntries={removeEntries}
        AssujetiConformite={AssujettiProgress}
        HeaderActions={
          hasAllowedRole(
            GatewayContact.ContactRoleUtilisateur.ADMINISTRATEUR,
            GatewayContact.ContactRoleUtilisateur.RESPONSABLE_CONFORMITE
          ) && (
            <div className={styles.actionButtons}>
              {me.entreprisePrincipale.millesimeActuel === millesime && (
                <Button
                  onClick={() =>
                    setAssujettiPaneState((prevState) => ({
                      assujetti: null,
                      initialPage: "information-personne",
                      isOpen: true,
                      paneSession:
                        prevState.assujetti === null &&
                        prevState.initialPage === "information-personne"
                          ? prevState.paneSession
                          : assujettiPaneState.paneSession + 1,
                    }))
                  }
                  variant="outline"
                  icon={<AddIcon />}
                >
                  Ajouter une personne
                </Button>
              )}
              <Dropdown content="Actions" placement="bottom-end">
                {({ close }) => (
                  <div>
                    {millesime === me.entreprisePrincipale.millesimeActuel && (
                      <Button
                        variant="text"
                        onClick={() => {
                          close();
                          openDialog(({ onClose, dialogRef }) =>
                            ImportAssujettiDialog({
                              onClose,
                              dialogRef,
                              setAssujettis: setEntries,
                            })
                          )
                            .catch(console.error)
                            .finally(close);
                        }}
                      >
                        Importer les personnes assujetties
                      </Button>
                    )}
                    <ExportAssujettiButton />
                  </div>
                )}
              </Dropdown>
            </div>
          )
        }
        onRowClick={(assujetti) => {
          setAssujettiPaneState({
            assujetti,
            initialPage: "detail-conformite",
            isOpen: true,
            paneSession: assujettiPaneState.paneSession + 1,
          });
        }}
      />
      <SidePane
        isOpen={assujettiPaneState.isOpen}
        setIsOpen={(nextIsOpen) =>
          setAssujettiPaneState((prevSate) => ({
            ...prevSate,
            isOpen: nextIsOpen,
          }))
        }
      >
        {(closePane) => (
          <AssujettiPaneContent
            readonly={me.entreprisePrincipale.millesimeActuel !== millesime}
            key={assujettiPaneState.paneSession}
            millesime={millesime}
            setEntry={(assujetti) => {
              setEntry(assujetti);
              setAssujettiPaneState((prevState) => ({
                ...prevState,
                assujetti,
              }));
            }}
            closePane={closePane}
            assujetti={assujettiPaneState.assujetti}
            initialPage={assujettiPaneState.initialPage}
          />
        )}
      </SidePane>
    </>
  );
}
