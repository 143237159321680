import { Card } from "@lya-protect/lya-protect-form-library/dist/UI";
import {
  GatewayAvancement,
  GatewayDeclarationActivite,
} from "@conformite/gateway";
import { TagConformite } from "@src/components/Tag/TagConformite";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { ConformiteSocieteActions } from "@src/conformite/societeActions/ConformiteSocieteActions";
import { useOnce } from "@src/hooks/useOnce";
import styles from "../ConformiteDeclarationActivite.module.scss";
import {
  ConformiteDeclarationActiviteIasForm,
  useDeclarationActiviteIas,
} from "./ConformiteDeclarationActiviteIasForm";

type ConformiteDeclarationActiviteIasEditProps = {
  siren: string;
  declarationActivite: GatewayDeclarationActivite.DeclarationActiviteIas | null;
  isConforme: boolean;
  millesime: number;
  stopEditing: (
    value?: GatewayDeclarationActivite.DeclarationActiviteIas,
    avancement?: GatewayAvancement.AvancementSociete
  ) => void;
};

export function ConformiteDeclarationActiviteIasEdit({
  siren,
  isConforme,
  declarationActivite,
  millesime,
  stopEditing,
}: ConformiteDeclarationActiviteIasEditProps) {
  const { form, onSubmit } = useDeclarationActiviteIas(
    siren,
    millesime,
    declarationActivite,
    stopEditing
  );

  useOnce(() => {
    if (declarationActivite !== null) {
      form.trigger().catch(console.error);
    }
  });

  return (
    <MaxWidthContent size="large" className={styles.contentEditable}>
      <Card className={styles.content}>
        <div className={styles.cardHeaderLeft}>
          <h3>Formulaire</h3>
          <TagConformite isConforme={isConforme} year={millesime} isEditing />
        </div>
        <ConformiteDeclarationActiviteIasForm form={form} />
        <ConformiteSocieteActions
          onClick={() => {
            onSubmit(form.getValues()).catch(console.error);
          }}
          isLoading={false}
          stopEditing={() => stopEditing()}
        />
      </Card>
    </MaxWidthContent>
  );
}
