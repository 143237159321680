export const rcProUrl = 'rcPro';
export interface ConformiteRcProFile {
  pathname?: string;
  filename?: string;
  startDate?: Date;
  endDate?: Date;
  orias?: string;
  isValid: boolean;
}
export interface ConformiteRcProPropertyCOA {
  niveauMinimumGarantie: ConformiteRcProPropertyType;
  franchiseInferieur: ConformiteRcProPropertyType;
  declarationActivitesIntraComnmunautaire: ConformiteRcProPropertyType;
  declarationApporteurCommercialisation: ConformiteRcProPropertyType;
  declarationNombreSalarie: ConformiteRcProPropertyType;
  files?: ConformiteRcProFile[];
}
export type ConformiteRcProPropertyCOAKey = keyof ConformiteRcProPropertyCOA;
export interface ConformiteRcProPropertyMIA {
  rcpIndividuelleSouscrit: ConformiteRcProPropertyType;
  courvertRcMandant: ConformiteRcProPropertyType;
  niveauMinimumGarantie: ConformiteRcProPropertyType;
  franchiseInferieur: ConformiteRcProPropertyType;
  declarationActivitesIntraComnmunautaire: ConformiteRcProPropertyType;
  declarationApporteurCommercialisation: ConformiteRcProPropertyType;
  declarationNombreSalarie: ConformiteRcProPropertyType;
  files?: ConformiteRcProFile[];
}
export type ConformiteRcProPropertyMIAKey = keyof ConformiteRcProPropertyMIA;
export interface ConformiteRcProPropertyCOBSP {
  niveauMinimumGarantieBSP: ConformiteRcProPropertyType;
  franchiseInferieur: ConformiteRcProPropertyType;
  activiteTerritoieFrancais: ConformiteRcProPropertyType;
  declarationApporteurCommercialisation: ConformiteRcProPropertyType;
  declarationNombreSalarie: ConformiteRcProPropertyType;
  files?: ConformiteRcProFile[];
}
export type ConformiteRcProPropertyCOBSPKey =
  keyof ConformiteRcProPropertyCOBSP;
export interface ConformiteRcProPropertyMIOBSP {
  rcpIndividuelleSouscrit: ConformiteRcProPropertyType;
  courvertRcMandant: ConformiteRcProPropertyType;
  niveauMinimumGarantieBSP: ConformiteRcProPropertyType;
  franchiseInferieur: ConformiteRcProPropertyType;
  activiteTerritoieFrancais: ConformiteRcProPropertyType;
  declarationApporteurCommercialisation: ConformiteRcProPropertyType;
  declarationNombreSalarie: ConformiteRcProPropertyType;
  files?: ConformiteRcProFile[];
}
export type ConformiteRcProPropertyMIOBSPKey =
  keyof ConformiteRcProPropertyMIOBSP;

export type ConformiteRcProProperty =
  | ConformiteRcProPropertyCOA
  | ConformiteRcProPropertyMIA
  | ConformiteRcProPropertyCOBSP
  | ConformiteRcProPropertyMIOBSP;
export type ConformiteRcProPropertyKey =
  | ConformiteRcProPropertyMIAKey
  | ConformiteRcProPropertyCOAKey
  | ConformiteRcProPropertyCOBSPKey
  | ConformiteRcProPropertyMIOBSPKey;
export const ConformiteRcProPropertyType = {
  A_VERIFIER: 'A_VERIFIER',
  OUI: 'OUI',
  NON: 'NON',
} as const;
export type ConformiteRcProPropertyType =
  keyof typeof ConformiteRcProPropertyType;

export function getRcProInvalidateDocumentUrl(
  type: CategorieOcrRcPro,
  siren: string,
  millesime: number,
  pathname: string,
) {
  return `${rcProUrl}/${siren}/${millesime}/${type}/documents/refuse?pathname=${encodeURIComponent(
    pathname,
  )}`;
}

export enum CategorieOcrRcPro {
  'coa' = 'coa',
  'mia' = 'mia',
  'cobsp' = 'cobsp',
  'miobsp' = 'miobsp',
}
