export function loadRecapcha() {
  const loadScriptByURL = (id: string, url: string) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.id = id;
      document.body.appendChild(script);
    }
  };

  // load the script by passing the URL
  if (import.meta.env.VITE_RECAPTCHA_KEY)
    loadScriptByURL(
      import.meta.env.VITE_RECAPTCHA_KEY,
      `https://www.google.com/recaptcha/api.js?render=${
        import.meta.env.VITE_RECAPTCHA_KEY
      }`
    );
}
