import { AvancementAssujetti } from '../../conformite/avancement/service/avancement.definition';

export type Assujetti = {
  id: string;
  millesime: number;
  name: string;
  surname: string;
  sirenCompany: string;
  role: RoleAssujetti;
  email: string | null;
  createdAt: Date;
  updatedAt: Date;
};
export type AssujettiWithAvancementType = Assujetti & {
  avancement: AvancementAssujetti;
};

export class AssujettiWithAvancement {
  constructor(
    readonly name: string,
    readonly surname: string,
    readonly sirenCompany: string,
    readonly role: RoleAssujetti,
    readonly email: string | null,
    readonly id: string,
    readonly millesime: number,
    readonly avancement: AvancementAssujetti,
  ) {}
  static fromAssujettiAndAvancement(
    assujetti: Assujetti,
    avancement: AvancementAssujetti | undefined | null,
  ): AssujettiWithAvancement {
    return new AssujettiWithAvancement(
      assujetti.name,
      assujetti.surname,
      assujetti.sirenCompany,
      assujetti.role,
      assujetti.email,
      assujetti.id,
      assujetti.millesime,
      avancement ?? {
        millesime: assujetti.millesime,
        capaciteProfessionnelleIas: null,
        capaciteProfessionnelleIobsp: null,
        formationContinueIas: null,
        formationContinueIobsp: null,
        honorabilite: null,
        idAssujetti: assujetti.id,
      },
    );
  }
}

export const RoleAssujetti = {
  REPRESENTANT_LEGAL: 'REPRESENTANT_LEGAL',
  ASSOCIE: 'ASSOCIE',
  DIRECTEUR_GENERAL: 'DIRECTEUR_GENERAL',
  DIRECTEUR_ADMINISTRATIF: 'DIRECTEUR_ADMINISTRATIF',
  DIRECTEUR_CONFORMITE: 'DIRECTEUR_CONFORMITE',
  DIRECTEUR_MARKETING: 'DIRECTEUR_MARKETING',
  DIRECTEUR_COMMERCIAL: 'DIRECTEUR_COMMERCIAL',
  CHARGE_CLIENTELE: 'CHARGE_CLIENTELE',
  JURISTE: 'JURISTE',
  GESTIONNAIRE: 'GESTIONNAIRE',
  CHEF_DE_PROJET: 'CHEF_DE_PROJET',
  RESPONSABLE_BUREAU: 'RESPONSABLE_BUREAU',
  COMMERCE: 'COMMERCE',
  GESTION: 'GESTION',
  BACK_OFFICE: 'BACK_OFFICE',
} as const;

export type RoleAssujetti = keyof typeof RoleAssujetti;
