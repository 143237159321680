import { GatewayInfoSocieteOrias } from "@conformite/gateway";
import { cleanProperty } from "@src/helper/clean.helper";
import { useFilterPagination } from "@src/components/Filter/useFilterPagination";
import { AdhesionDataEntreprisesItem } from "@src/adhesion/AdhesionData/adhesionDataEntreprises";
import { filterSearchByKeys } from "@src/components/Filter/filter.helper";

export const SOCIETES_PAGE_SIZE = 25;
export type SocieteFilters = {
  categories?: GatewayInfoSocieteOrias.Categorie[];
};

export const useSocieteAdhesionFiltered = (
  societes: AdhesionDataEntreprisesItem[]
) => {
  const {
    content,
    page,
    setPage,
    numberOfContentUnpaginated,
    filtersSelected,
    setFiltersSelected,
  } = useFilterPagination({
    content: societes,
    pagination: {
      pageSize: SOCIETES_PAGE_SIZE,
    },
    filters: [
      {
        name: "raisonSociale",
        filter: filterSearchByKeys(["raisonSociale", "siren"]),
      },
    ],
    sorts: [
      {
        name: "raisonSocial",
        sorting: (societe1, societe2) =>
          societe1.raisonSociale?.localeCompare(societe2.raisonSociale || "") ||
          0,
      },
    ],
    defaultSort: {
      name: "raisonSocial",
      order: "asc",
    },
  });

  return {
    numberOfSocietes: numberOfContentUnpaginated,
    societesPaginated: content,
    search: filtersSelected?.raisonSociale?.[0],
    page,
    setSearch: (search?: string) =>
      setFiltersSelected({ raisonSociale: [search ?? ""] }),
    setPage,
  };
};
