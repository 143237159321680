import { GatewayImportExportAssujetti } from "@conformite/gateway";
import { AssujettiExcelMappedTemplate } from "../AssujettiExcelTemplate";
import {
  excelLabelByAssujettiRole,
  excelLabelByCategorieIAS,
  excelLabelByCategorieIOBSP,
  excelLabelByGradeDiplome,
  excelLabelByNiveauIas,
  excelLabelByNiveauIobsp,
  excelLabelByStatutPro,
  excelLabelByTypeJustificatifIAS,
  excelLabelByTypeJustificatifIOBSP,
} from "../ImportExport.definition";

function transformAssujetti(
  assujetti: GatewayImportExportAssujetti.AssujettiRowImportAssujetti
): AssujettiExcelMappedTemplate["assujetti"] {
  return {
    mail: assujetti.email ?? null,
    name: assujetti.name,
    role: excelLabelByAssujettiRole[assujetti.role],
    sirenCompany: assujetti.sirenCompany,
    surname: assujetti.surname,
  };
}

function transformHonorabilite(
  honorabilite: GatewayImportExportAssujetti.AssujettiRowImportHonorabilite
): AssujettiExcelMappedTemplate["honorabilite"] {
  return {
    dateVerif: honorabilite.lastVerifiedAt ?? null,
    honorable: honorabilite.isHonourable ?? null,
  };
}

function transformFormationContinueIAS(
  formationContinue: GatewayImportExportAssujetti.AssujettiRowImportFormationContinueIAS
): AssujettiExcelMappedTemplate["formationContinueIAS"] {
  return {
    dateSaisie: formationContinue.dateDernierVerification,
    formation0Heures: formationContinue.formations.at(0)?.nombreHeures ?? null,
    formation0Theme: formationContinue.formations.at(0)?.theme ?? null,
    formation1Heures: formationContinue.formations.at(1)?.nombreHeures ?? null,
    formation1Theme: formationContinue.formations.at(1)?.theme ?? null,
  };
}

function transformFormationContinueIOBSP(
  formationContinue: GatewayImportExportAssujetti.AssujettiRowImportFormationContinueIOBSP
): AssujettiExcelMappedTemplate["formationContinueIOBSP"] {
  return {
    formation0Heures: formationContinue.formations.at(0)?.nombreHeures ?? null,
    formation0Theme: formationContinue.formations.at(0)?.theme ?? null,
    formation1Heures: formationContinue.formations.at(1)?.nombreHeures ?? null,
    formation1Theme: formationContinue.formations.at(1)?.theme ?? null,
    dateSaisie: formationContinue.dateDernierVerification,
    heuresFormationCreditConsommation:
      formationContinue.heuresFormationCreditConsommation ?? null,
    heuresFormationCreditImmobilier:
      formationContinue.heuresFormationCreditImmobilier ?? null,
    heuresFormationCreditPretViagerHypotecaire:
      formationContinue.heuresFormationCreditPretViagerHypotecaire ?? null,
    heuresFormationCreditRegroupementCredits:
      formationContinue.heuresFormationCreditRegroupementCredits ?? null,
    heuresFormationCreditServicePaiement:
      formationContinue.heuresFormationCreditServicePaiement ?? null,
  };
}

function transformCapaciteProIAS(
  capacitePro: GatewayImportExportAssujetti.AssujettiRowImportCapaciteProIAS
): AssujettiExcelMappedTemplate["capaciteProIAS"] {
  return {
    categorie: capacitePro.categorie
      ? excelLabelByCategorieIAS[capacitePro.categorie]
      : null,
    datePriseFonction: capacitePro.datePriseFonction ?? null,
    gradeDiplome: capacitePro.gradeDiplome
      ? excelLabelByGradeDiplome[capacitePro.gradeDiplome]
      : null,
    justificatif: capacitePro.typeJustificatif
      ? excelLabelByTypeJustificatifIAS[capacitePro.typeJustificatif]
      : null,
    niveau: capacitePro.niveau
      ? excelLabelByNiveauIas[capacitePro.niveau]
      : null,
    nomDiplome: capacitePro.nomDiplome ?? null,
    soumisALaCondition: capacitePro.conditionCapaciteProfessionnelle ?? null,
    statutPro: capacitePro.statusProfessionel
      ? excelLabelByStatutPro[capacitePro.statusProfessionel]
      : null,
  };
}

function transformCapaciteProIOBSP(
  capacitePro: GatewayImportExportAssujetti.AssujettiRowImportCapaciteProIOBSP
): AssujettiExcelMappedTemplate["capaciteProIOBSP"] {
  return {
    categorie: capacitePro.categorie
      ? excelLabelByCategorieIOBSP[capacitePro.categorie]
      : null,
    datePriseFonction: capacitePro.datePriseFonction ?? null,
    gradeDiplome: capacitePro.gradeDiplome
      ? excelLabelByGradeDiplome[capacitePro.gradeDiplome]
      : null,
    justificatif: capacitePro.typeJustificatif
      ? excelLabelByTypeJustificatifIOBSP[capacitePro.typeJustificatif]
      : null,
    niveau: capacitePro.niveau
      ? excelLabelByNiveauIobsp[capacitePro.niveau]
      : null,
    nomDiplome: capacitePro.nomDiplome ?? null,
    soumisALaCondition: capacitePro.conditionCapaciteProfessionnelle ?? null,
    statutPro: capacitePro.statusProfessionel
      ? excelLabelByStatutPro[capacitePro.statusProfessionel]
      : null,
  };
}

export function transformAPIRowsForToRows(
  mapping: GatewayImportExportAssujetti.AssujettiRowImport
): AssujettiExcelMappedTemplate {
  return {
    assujetti: transformAssujetti(mapping.assujetti),
    honorabilite: transformHonorabilite(mapping.honorabilite),
    formationContinueIAS: transformFormationContinueIAS(
      mapping.formationContinueIAS
    ),
    formationContinueIOBSP: transformFormationContinueIOBSP(
      mapping.formationContinueIOBSP
    ),
    capaciteProIAS: transformCapaciteProIAS(mapping.capaciteProIAS),
    capaciteProIOBSP: transformCapaciteProIOBSP(mapping.capaciteProIOBSP),
  };
}
