import { Card } from "@lya-protect/lya-protect-form-library/dist/UI";
import {
  GatewayAvancement,
  GatewayGarantieFinanciere,
} from "@conformite/gateway";
import { TagConformite } from "@src/components/Tag/TagConformite";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { ConformiteSocieteActions } from "@src/conformite/societeActions/ConformiteSocieteActions";
import styles from "../ConformiteGarantieFinanciere.module.scss";
import {
  ConformiteGarantieFinanciereEditFormIas,
  useConformiteGarantieFinanciereIasEdit,
} from "./ConformiteGarantieFinanciereEditFormIas";

type ConformiteGarantieFinanciereIasEditProps = {
  siren: string;
  isConforme: boolean;
  millesime: number;
  garantieFinanciere: GatewayGarantieFinanciere.ConformiteGarantieFinanciereIas | null;
  stopEditing: (
    value?: GatewayGarantieFinanciere.ConformiteGarantieFinanciereIas,
    avancement?: GatewayAvancement.AvancementSociete
  ) => void;
  disabled?: boolean;
};

export function ConformiteGarantieFinanciereIasEdit({
  siren,
  isConforme,
  millesime,
  stopEditing,
  garantieFinanciere,
  disabled,
}: ConformiteGarantieFinanciereIasEditProps) {
  const { formConfig, onSubmit } = useConformiteGarantieFinanciereIasEdit(
    siren,
    millesime,
    garantieFinanciere,
    stopEditing
  );
  return (
    <MaxWidthContent size="large" className={styles.contentEditable}>
      <Card className={styles.content}>
        <div className={styles.cardHeaderLeft}>
          <h3>Formulaire</h3>
          <TagConformite isConforme={isConforme} year={millesime} isEditing />
        </div>
        <ConformiteGarantieFinanciereEditFormIas
          garantieFinanciere={garantieFinanciere}
          millesime={millesime}
          formConfig={formConfig}
          disabled={disabled}
          siren={siren}
        />
        {!disabled && (
          <ConformiteSocieteActions
            onClick={() => {
              onSubmit(formConfig.getValues()).catch(console.error);
            }}
            isLoading={false}
            stopEditing={() => stopEditing()}
          />
        )}
      </Card>
    </MaxWidthContent>
  );
}
