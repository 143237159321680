import { useCallback, useEffect, useRef, useState } from "react";
import { GatewayInfoSocieteOrias } from "@conformite/gateway";
import {
  AdhesionStepEnum,
  ID_FORM_ADHESION,
  ParcoursAdhesionStepComponentProps,
} from "@src/adhesion/ParcoursAdhesion.definition";
import {
  EntrepriseInfoActiviteForm,
  EntrepriseInfoActiviteFormHandle,
  EntrepriseInfoActiviteFormValues,
} from "@src/societes/form/EntrepriseInfoActiviteForm";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import { GatewayInfoSocieteOriasApi } from "@src/api/info-societe-orias.api";
import { useMe } from "@src/store/store.me";
import { ParcoursAdhesionNextAction } from "@src/adhesion/AdhesionActions/ParcoursAdhesionNextAction";
import { ParcoursAdhesionSaveAndQuitAction } from "@src/adhesion/AdhesionActions/ParcoursAdhesionSaveAndQuitAction";

function useValuesInfoActivites(
  needFetch: boolean,
  siren: string | undefined,
  updateDataAfterFetch: (
    siren: string,
    infoOrias: GatewayInfoSocieteOrias.InfoSocieteOriasType
  ) => void
) {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!siren) return;
    if (!needFetch) {
      setIsLoading(false);
      return;
    }
    GatewayInfoSocieteOriasApi.getBySiren({
      sirens: [siren],
      oriasIds: [],
    })
      .then((response) => {
        updateDataAfterFetch(siren, response.bySiren[siren]);
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siren]);

  return isLoading;
}

export function AdhesionInfoActivitePage({
  addData,
  changeStep,
  handleQuit,
  data,
}: ParcoursAdhesionStepComponentProps) {
  const { me } = useMe();
  const isLoading = useValuesInfoActivites(
    data.informationsActivite.statutOrias === undefined,
    me?.entreprisePrincipale.siren,
    (siren, infoOrias) => {
      addData({
        informationsActivite: {
          ...data.informationsActivite,
          statutOrias: infoOrias.statut,
          numeroOrias: infoOrias.numeroOrias,
          coaAccessoire: false,
          cobspAccessoire: false,
          miaAccessoire: false,
          miobspAccessoire: false,
          sousCategoriesCOBSP: [],
          sousCategoriesMIOBSP: [],
          categories: infoOrias.categories || [],
        },
      });
    }
  );

  const handleSubmit = useCallback(
    (infoActivite: EntrepriseInfoActiviteFormValues) => {
      addData({
        informationsActivite: { ...data.informationsActivite, ...infoActivite },
      });
      if (
        infoActivite.categories.find((categorie) =>
          [
            GatewayInfoSocieteOrias.Categorie.COBSP,
            GatewayInfoSocieteOrias.Categorie.MIOBSP,
          ].includes(categorie)
        )
      )
        changeStep(AdhesionStepEnum.SOUS_CATEGORIES);
      else changeStep(AdhesionStepEnum.ORGANISATION_PROFESSIONNELLE);
    },
    [addData, changeStep, data.informationsActivite]
  );

  const formRef = useRef<EntrepriseInfoActiviteFormHandle>(null);

  if (isLoading) return <div />;

  return (
    <ParcoursFullPageTemplate
      title="Informations sur votre activité"
      actions={
        <>
          <ParcoursAdhesionSaveAndQuitAction
            addData={addData}
            formConfig={() => formRef?.current?.getFormConfig()}
            handleQuit={handleQuit}
            transformValues={(formValues) => ({
              informationsActivite: {
                ...data.informationsActivite,
                ...formValues,
              },
            })}
          />
          <ParcoursAdhesionNextAction type="submit">
            Suivant
          </ParcoursAdhesionNextAction>
        </>
      }
    >
      <EntrepriseInfoActiviteForm
        ref={formRef}
        defaultValues={{
          categories: data.informationsActivite.categories || [],
          coaAccessoire: data.informationsActivite.coaAccessoire,
          cobspAccessoire: data.informationsActivite.cobspAccessoire,
          miaAccessoire: data.informationsActivite.miaAccessoire,
          miobspAccessoire: data.informationsActivite.miobspAccessoire,
          statutOrias:
            data.informationsActivite.numeroOrias === undefined
              ? GatewayInfoSocieteOrias.StatutSociete.JAMAIS_INSCRIT
              : GatewayInfoSocieteOrias.StatutSociete.INSCRIT,
          numeroOrias: data.informationsActivite.numeroOrias,
        }}
        onSubmit={handleSubmit}
        formId={ID_FORM_ADHESION}
      />
    </ParcoursFullPageTemplate>
  );
}
