import {
  Button,
  Card,
  FormField,
  FormGrid,
  FormRow,
  UIInputContainer,
  UIInputNumber,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayDeclarationActivite } from "@conformite/gateway";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { TagConformite } from "@src/components/Tag/TagConformite";
import { TagList } from "@src/components/AutocompleteWithList/TagList";
import { UIInputSelect } from "@lya-protect/lya-protect-form-library/dist/UI/Input/UIInputSelect";
import styles from "../ConformiteDeclarationActivite.module.scss";
import { organisationToLabel } from "../edit/Organistions/Orgnanisations";
import { activiteIasToLabel } from "../edit/Activites/ActivitesIas";

type ConformiteDeclarationActiviteIasReadProps = {
  declarationActivite: GatewayDeclarationActivite.DeclarationActiviteIas | null;
  isConforme: boolean;
  setToEdit: () => void;
  millesime: number;
};
export function ConformiteDeclarationActiviteIasRead({
  declarationActivite,
  isConforme,
  setToEdit,
  millesime,
}: ConformiteDeclarationActiviteIasReadProps) {
  return (
    <MaxWidthContent size="large">
      <Card className={styles.content}>
        <div className={styles.cardHeader}>
          <div className={styles.cardHeaderLeft}>
            <h3>Formulaire</h3>
            <TagConformite isConforme={isConforme} year={millesime} />
          </div>
          <Button onClick={setToEdit}>Modifier</Button>
        </div>
        <FormGrid disableHorizontalGutter>
          <h4 className={styles.subtitle}>Organisation de la distribution</h4>
          <FormRow>
            {declarationActivite &&
              declarationActivite.organisations.length > 0 && (
                <FormField>
                  <TagList
                    fields={
                      declarationActivite?.organisations.map(
                        (organisation) => ({
                          id: organisation.type,
                          value: `${organisationToLabel[organisation.type]} : ${
                            organisation.repartitionClientele
                          } %`,
                        })
                      ) ?? []
                    }
                  />
                  {!declarationActivite?.organisations.length && (
                    <p>Aucune organisation renseignée</p>
                  )}
                </FormField>
              )}
          </FormRow>
          <h4 className={styles.subtitle}>
            Répartition du chiffre d&apos;affaire
          </h4>
          <FormRow>
            <FormField size="one-third">
              <UIInputContainer
                name="pourcentageCaClientsParticuliers"
                label="Pourcentage du CA clients particuliers"
              >
                <UIInputNumber
                  value={declarationActivite?.pourcentageCaClientsParticuliers}
                  hasError={false}
                  disabled
                />
              </UIInputContainer>
            </FormField>
            <FormField size="one-third">
              <UIInputContainer
                name="pourcentageCaClientsProfessionnels"
                label="Pourcentage du CA clients professionnels"
              >
                <UIInputNumber
                  value={
                    declarationActivite?.pourcentageCaClientsProfessionnels
                  }
                  hasError={false}
                  disabled
                />
              </UIInputContainer>
            </FormField>
            <FormField size="one-third">
              <UIInputContainer
                name="pourcentageTnsTpe"
                label="Pourcentage TNS/TPE"
              >
                <UIInputNumber
                  value={declarationActivite?.pourcentageTnsTpe}
                  hasError={false}
                  disabled
                />
              </UIInputContainer>
            </FormField>
          </FormRow>
          <FormRow>
            <FormField size="one-third">
              <UIInputContainer
                name="effectifGlobalEntreprise"
                label="Effectif global de l'entreprise"
              >
                <UIInputNumber
                  value={declarationActivite?.effectifGlobalEntreprise}
                  hasError={false}
                  disabled
                />
              </UIInputContainer>
            </FormField>
          </FormRow>
          <h4 className={styles.subtitle}>Diversification de l’activité</h4>
          <FormRow>
            <FormField>
              <UIInputContainer
                name="activitePrincipale"
                label="Activité principale"
              >
                <UIInputSelect
                  name="activitePrincipale"
                  options={Object.entries(activiteIasToLabel).map(
                    ([key, value]) => ({
                      label: value,
                      value: key,
                    })
                  )}
                  value={declarationActivite?.activitePrincipale ?? undefined}
                  onChange={() => null}
                  disabled
                />
              </UIInputContainer>
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <UIInputContainer
                name="activites10Pourcent"
                label="Activités représentants au minimum 10% du CA"
              >
                <>
                  <TagList
                    fields={
                      declarationActivite?.activites10Pourcent.map(
                        (activite) => ({
                          id: activite,
                          value: activiteIasToLabel[activite],
                        })
                      ) ?? []
                    }
                  />
                  {!declarationActivite?.activites10Pourcent.length && (
                    <p>Aucune activité renseignée</p>
                  )}
                </>
              </UIInputContainer>
            </FormField>
          </FormRow>
          <h4 className={styles.subtitle}>Fournisseurs de produits</h4>
          <TagList
            fields={
              declarationActivite?.fournisseurs.map((fournisseur) => ({
                id: fournisseur,
                value: fournisseur,
              })) ?? []
            }
          />
          {!declarationActivite?.fournisseurs.length && (
            <p>Aucun fournisseur renseigné</p>
          )}
        </FormGrid>
      </Card>
    </MaxWidthContent>
  );
}
