import { PrismicImage, PrismicRichText } from "@prismicio/react";
import { SliceFactoryProps } from "../../SliceManager";
import styles from "./SliceTextImage.module.scss";
import stylesTextRich from "../TextRich.module.scss";

export function SliceTextImage({ slice }: SliceFactoryProps<"text_image">) {
  return (
    <div className={styles.textImage}>
      <PrismicImage field={slice.primary.image} className={styles.image} />
      <div className={stylesTextRich.textRich}>
        <PrismicRichText field={slice.primary.contenu} />
      </div>
    </div>
  );
}
