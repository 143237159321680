import { useContext, useEffect } from "react";
import styles from "./Dialog.module.scss";
import { DialogContext } from "./DialogContext";
import { MaxWidthContent } from "../MaxWidthContent/MaxWidthContent";

export type DialogComponentProps = Pick<DialogProps, "dialogRef">;

type DialogProps = {
  children: React.ReactNode;
  dialogRef: React.MutableRefObject<HTMLDialogElement | null>;
};
export function Dialog({ children, dialogRef }: DialogProps) {
  useEffect(() => {
    const dialogElement = dialogRef.current;
    dialogElement?.addEventListener("click", (e) => {
      if (e.target === dialogRef.current) {
        dialogRef.current?.close();
      }
    });
    return () => dialogElement?.removeEventListener("click", () => null);
  }, [dialogRef]);
  return (
    <dialog ref={dialogRef} className={styles.dialog}>
      <MaxWidthContent className={styles.content}>{children}</MaxWidthContent>
    </dialog>
  );
}

export function DialogContainer() {
  const { dialogState } = useContext(DialogContext);
  return dialogState ? <dialogState.component {...dialogState.props} /> : null;
}
