import {
  ParcoursAction,
  ParcoursActionProps,
} from "@src/parcours/NavigationButtons/ParcoursAction";
import { ID_FORM_ADHESION } from "../ParcoursAdhesion.definition";

export function ParcoursAdhesionNextAction(
  props: Omit<ParcoursActionProps, "formId">
) {
  return <ParcoursAction {...props} formId={ID_FORM_ADHESION} />;
}
