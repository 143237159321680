import styles from "./ComplianceProgressBar.module.scss";

type ComplianceProgressBarProps = {
  progress: number;
};

export function ComplianceProgressBar({
  progress,
}: ComplianceProgressBarProps) {
  const clampedProgress = Math.min(Math.max(progress, 0), 100);
  return (
    <div className={styles.root} data-progress={clampedProgress}>
      <div className={styles.barOutline}>
        <div
          className={styles.bar}
          style={{ width: `${clampedProgress + 4}%` }}
        />
      </div>
      <p className={styles.label}>{clampedProgress}%</p>
    </div>
  );
}
