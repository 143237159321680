import { Form } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { FormGrid } from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayConformiteRcPro } from "@conformite/gateway";
import { ConformiteRcProEditProperties } from "./ConformiteRcProEditProperties";
import { ConformiteRcProEditFiles } from "./ConformiteRcProEditFiles";
import styles from "../ConformiteRcPro.module.scss";
import { useConformiteRcProEdit } from "./useConformiteRcProEdit";

type ConformiteRcProFormProps = {
  formConfig: ReturnType<typeof useConformiteRcProEdit>["formConfig"];
  onSubmit?: ReturnType<typeof useConformiteRcProEdit>["onSubmit"];
  properties: GatewayConformiteRcPro.ConformiteRcProProperty | null;
  hasRcpIndividuelleSouscritSetToNo: boolean;
};

export function ConformiteRcProForm({
  formConfig,
  onSubmit = () => Promise.resolve(),
  hasRcpIndividuelleSouscritSetToNo,
  properties,
}: ConformiteRcProFormProps) {
  return (
    <Form formConfig={formConfig} onSubmit={onSubmit}>
      <ConformiteRcProEditFiles
        form={formConfig}
        hasRcpIndividuelleSouscritSetToNo={hasRcpIndividuelleSouscritSetToNo}
      />
      <h4 className={styles.subtitle}>Questionnaire</h4>
      <FormGrid disableHorizontalGutter>
        <ConformiteRcProEditProperties
          properties={properties}
          hasRcpIndividuelleSouscritSetToNo={hasRcpIndividuelleSouscritSetToNo}
        />
      </FormGrid>
    </Form>
  );
}
