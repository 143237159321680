import { PageWithSidebar } from "@src/components/PageWithSidebar/PageWithSidebar";
import { PropsWithChildren, ReactNode } from "react";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { UserMenu } from "@src/components/UserMenu/UserMenu";
import { PageWithActions } from "@src/components/PageWithActions/PageWithActions";
import { ParametreSidebar } from "./ParametreSidebar";
import style from "./ParametrePageTemplate.module.scss";

type ParametrePageTemplateProps = {
  title: string;
  actions?: ReactNode;
  isFullPage?: boolean;
} & PropsWithChildren;

export function ParametrePageTemplate({
  children,
  title,
  actions,
  isFullPage = false,
}: ParametrePageTemplateProps) {
  return (
    <PageWithSidebar sidebar={<ParametreSidebar />}>
      <PageWithActions actions={actions}>
        <div className={style.header}>
          <div className={style.userMenuWrapper}>
            <UserMenu />
          </div>
          <h1>{title}</h1>
        </div>

        <div className={style.content}>
          {isFullPage ? (
            children
          ) : (
            <MaxWidthContent>{children}</MaxWidthContent>
          )}
        </div>
      </PageWithActions>
    </PageWithSidebar>
  );
}
