import { GatewayAdhesion2Api } from "@src/api/adhesion2.api";
import { RenouvellementStepEnum } from "./ParcoursRenouvellement.definition";
import { RenouvellementData } from "./steps/RenouvellementData";

export async function persistRenouvellementCacheOnAPI(
  data: RenouvellementData,
  history: RenouvellementStepEnum[]
): Promise<void> {
  return GatewayAdhesion2Api.saveCache(
    {
      data: { ...data, coupon: undefined },
      history,
    },
    "RENOUVELLEMENT"
  );
}

export async function getRenouvellementCacheFromAPI() {
  return GatewayAdhesion2Api.getCache("RENOUVELLEMENT");
}
