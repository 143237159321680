import { GatewayAssujetti } from "@conformite/gateway";
import { cloneDeep, keyBy, omit } from "lodash";
import { useMemo } from "react";
import { StoreEntryFetchStatus } from "./store";
import { Assujetti, AssujettiById } from "./store.definition";
import { useStore } from "./store.hooks";

export type AssujettisBySocietes = Record<
  string,
  GatewayAssujetti.AssujettiWithAvancement[]
>;

type UseAssujettiReturn = {
  assujettis: AssujettiById | undefined;
  assujettisBySocietes: AssujettisBySocietes;
  setEntry: (entry: Assujetti) => void;
  updateAvancement: (
    millesime: number,
    idAssujetti: string,
    avancement: GatewayAssujetti.Avancement | null
  ) => void;
  removeEntries: (ids: string[], millesime: number) => void;
  setEntriesOfMillesime: (
    assujettis: Assujetti[],
    millesime: number,
    clean?: boolean
  ) => void;
  status: StoreEntryFetchStatus;
  setEntries: (entries: Assujetti[]) => void;
};

export function useAssujetti(): UseAssujettiReturn {
  const storeValue = useStore();
  const { setData, data, status } = storeValue.assujetti;

  const selectedMillesime = storeValue.millesime.selected;
  const assujettiOfSelectedMillesime = selectedMillesime
    ? data?.[selectedMillesime]
    : undefined;

  function setEntry(assujetti: Assujetti) {
    setData({
      ...data,
      [assujetti.millesime]: {
        ...(data?.[assujetti.millesime] || {}),
        [assujetti.id]: assujetti,
      },
    });
  }

  function setEntries(assujettis: Assujetti[]) {
    if (!data) return;
    const nextData = cloneDeep(data);
    assujettis.forEach((assujetti) => {
      nextData[assujetti.millesime] = {
        ...(data[assujetti.millesime] ?? {}),
        [assujetti.id]: assujetti,
      };
    });
    setData(nextData);
  }

  function updateAvancement(
    millesime: number,
    idAssujetti: string,
    avancement: GatewayAssujetti.Avancement | null
  ) {
    const assujetis = data?.[millesime][idAssujetti];
    if (!assujetis || !avancement) return;
    setEntry({
      ...assujetis,
      avancement,
    });
  }

  function removeEntries(assujettiIds: string[], millesime: number) {
    setData({
      ...data,
      [millesime]: omit(data?.[millesime] || {}, assujettiIds),
    });
  }

  function setEntriesOfMillesime(
    assujettis: Assujetti[],
    millesime: number,
    clean = false
  ) {
    const previousOfMillesime = data?.[millesime] ?? {};
    const newAssujettisByIds = keyBy(assujettis, "id");
    setData({
      ...data,
      [millesime]: clean
        ? newAssujettisByIds
        : { ...previousOfMillesime, ...newAssujettisByIds },
    });
  }

  const assujettisBySocietes: AssujettisBySocietes = useMemo(() => {
    if (!assujettiOfSelectedMillesime) return {};
    return Object.values(assujettiOfSelectedMillesime).reduce(
      (acc, assujetti) => {
        const assujetiFromSociete = acc[assujetti.sirenCompany] || [];
        return {
          ...acc,
          [assujetti.sirenCompany]: [...assujetiFromSociete, assujetti],
        };
      },
      {} as AssujettisBySocietes
    );
  }, [assujettiOfSelectedMillesime]);

  return {
    assujettis: assujettiOfSelectedMillesime,
    assujettisBySocietes,
    setEntry,
    removeEntries,
    setEntriesOfMillesime,
    status,
    updateAvancement,
    setEntries,
  };
}
