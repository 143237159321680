import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import {
  AdhesionStepEnum,
  ID_FORM_ADHESION,
  ParcoursAdhesionStepComponentProps,
} from "@src/adhesion/ParcoursAdhesion.definition";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import { ParcoursAdhesionNextAction } from "@src/adhesion/AdhesionActions/ParcoursAdhesionNextAction";
import { GatewayAuthApi } from "@src/api/auth.api";
import {
  SignupConfirmForm,
  SignupConfirmFormValues,
} from "@src/user/Signup/SignupConfirmForm";
import { ReactComponent as Loader } from "@src/assets/loader.svg";
import { useState } from "react";

export function AdhesionConfirm({
  changeStep,
  data,
}: ParcoursAdhesionStepComponentProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (submittedData: SignupConfirmFormValues) => {
    setIsLoading(true);
    try {
      await GatewayAuthApi.confirm(
        data.identifiants.email,
        submittedData.confirmCode
      );
      changeStep(AdhesionStepEnum.INIT);
    } catch (err) {
      handleGatewayError({
        onResponse: (error) => {
          displayErrorToast(error.response.data.message, {
            canal: "adhesion",
          });
        },
        onUnhandled: (error) => {
          console.error(error);
          displayErrorToast("Une erreur inconnue est survenue", {
            canal: "adhesion",
          });
        },
      })(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ParcoursFullPageTemplate
      title="Vérification de l'adresse email"
      actions={
        <ParcoursAdhesionNextAction
          type="submit"
          disabled={isLoading}
          icon={isLoading ? <Loader /> : undefined}
        >
          Suivant
        </ParcoursAdhesionNextAction>
      }
    >
      <SignupConfirmForm
        email={data.identifiants.email}
        formId={ID_FORM_ADHESION}
        onSubmit={handleSubmit}
      />
    </ParcoursFullPageTemplate>
  );
}
