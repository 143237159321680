import { GatewayAttestationConformite } from "@conformite/gateway";
import { userSession } from "@src/session/UserSession";

export class GatewayAttestationConformiteApi {
  static async getDocumentAdhesion(
    request: GatewayAttestationConformite.GetAttestationConformiteType
  ) {
    return userSession.axiosInstance.get<ArrayBuffer>(
      `attestation-conformite/${request.millesime}/${request.idEntreprise}`,
      {
        responseType: "arraybuffer",
      }
    );
  }
}
