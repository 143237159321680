import {
  Button,
  Card,
  FormField,
  FormGrid,
  FormRow,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { useCallback, useState } from "react";
import { useMe } from "@src/store/store.me";
import { SkeletonCard } from "@src/components/Skeletons/card/SkeletonCard";
import { useDialog } from "@src/components/Dialog/useDialog";
import { UpdateMailForm, UpdateMailFormValues } from "./UpdateMailForm";
import style from "./UpdateMailCard.module.scss";
import { ConfirmUpdateMailDialog } from "./ConfirmUpdateMailDialog";

const FORM_UPDATE_MAIL = "form-update-mail";

export function UpdateMailCard() {
  const [isDirty, setIsDirty] = useState<boolean>();
  const { openDialog } = useDialog();
  const { me, status: meStatus } = useMe();

  const handleConfirmChangeMail = useCallback(
    async (values: UpdateMailFormValues): Promise<void> => {
      try {
        await openDialog(({ onClose, dialogRef }) => (
          <ConfirmUpdateMailDialog
            dialogRef={dialogRef}
            onClose={onClose}
            email={values.email}
          />
        ));
      } catch (err) {
        console.error(err);
        displayErrorToast("Une erreur inconnue est survenue");
      }
    },
    [openDialog]
  );

  if (meStatus !== "loaded" || !me) return <SkeletonCard />;
  return (
    <Card>
      <FormGrid>
        <FormRow>
          <FormField>
            <h3>Modifier mon adresse mail</h3>
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <UpdateMailForm
              key={me.contact.email}
              setIsDirty={setIsDirty}
              defaultValues={{ email: me.contact.email }}
              onSubmit={handleConfirmChangeMail}
              formId={FORM_UPDATE_MAIL}
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField className={style.updateMailCardActions}>
            <Button type="submit" form={FORM_UPDATE_MAIL} disabled={!isDirty}>
              Enregistrer
            </Button>
          </FormField>
        </FormRow>
      </FormGrid>
    </Card>
  );
}
