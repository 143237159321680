import classNames from "classnames";
import {
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import style from "./PasswordCriterias.module.scss";

type PasswordCriteriasProps = {
  password: string;
};

export function PasswordCriterias({ password = "" }: PasswordCriteriasProps) {
  const criterias = [
    {
      label: "8 caractères",
      met: password.length >= 8,
    },
    {
      label: "1 majuscule",
      met: /[A-Z]/.test(password),
    },
    {
      label: "1 minuscule",
      met: /[a-z]/.test(password),
    },
    {
      label: "1 chiffre",
      met: /[0-9]/.test(password),
    },
    {
      label: "1 caractère spécial (@$!%*?&)",
      met: /[#?!@$%^&*-]/.test(password),
    },
  ];
  return (
    <div className={style.passwordCriterias}>
      <FormRow>
        <FormField>
          <p className={style.description}>
            Votre mot de passe doit contenir au moins :
          </p>
        </FormField>
      </FormRow>
      <ul className={style.criteriaList}>
        {criterias.map((criteria) => (
          <li
            key={criteria.label}
            className={classNames(
              style.criteria,
              criteria.met && style.criteriaMet
            )}
          >
            <p>{criteria.label}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}
