import {
  Card,
  Emphasis,
  FormField,
  FormGrid,
  FormNotice,
  FormRow,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import {
  EntrepriseIdentificationEtAdresseForm,
  EntreprisePrincipaleIdentificationEtAdresseFormValues,
} from "@src/societes/form/EntrepriseIdentificationEtAdresseForm";
import { useCallback, useState } from "react";
import { SkeletonCard } from "@src/components/Skeletons/card/SkeletonCard";
import { GatewayEntrepriseApi } from "@src/api/entreprise2.api";
import { useMe } from "@src/store/store.me";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { ParametrePageTemplate } from "../ParametrePageTemplate";
import { ParametrePageActions } from "../ParametrePageActions";

const FORM_ID = "params-infos-societe";

export function InformationsSociete() {
  const { me, updateEntreprisePrincipale, status } = useMe();
  const [isLoading, setIsLoading] = useState(false);
  const [formKey, setFormKey] = useState(0);
  const [showActions, setShowActions] = useState(false);

  const handleResetForm = useCallback(() => {
    setFormKey((prev) => prev + 1);
  }, []);

  const handleSubmit = useCallback(
    (payload: EntreprisePrincipaleIdentificationEtAdresseFormValues) => {
      setIsLoading(true);
      GatewayEntrepriseApi.updateEntreprisePrincipale({
        adresse: {
          ...payload,
          numeroVoie: payload.numeroVoie as string,
        },
        ...payload,
      })
        .then((response) => {
          updateEntreprisePrincipale({
            adresse: response.adresse,
            raisonSociale: response.raisonSociale,
            trancheEffectif: response.trancheEffectif,
            formeJuridique: response.formeJuridique,
            typeSouscripteur: response.typeSouscripteur,
          });
          handleResetForm();
        })
        .catch(
          handleGatewayError({
            onUnhandled: (err) => {
              console.error(err);
              displayErrorToast(
                "Une erreur est survenue lors de la modification des informations de votre société"
              );
            },
          })
        )
        .finally(() => setIsLoading(false));
    },
    [updateEntreprisePrincipale, handleResetForm]
  );

  return (
    <ParametrePageTemplate
      title="Informations de ma société"
      actions={
        showActions && (
          <ParametrePageActions
            formId={FORM_ID}
            onCancel={handleResetForm}
            isLoading={isLoading}
          />
        )
      }
    >
      {status === "loading" && <SkeletonCard height={500} />}

      {status === "loaded" && me && (
        <Card>
          <FormGrid>
            <FormNotice>
              <Emphasis>*</Emphasis> Champs obligatoires
            </FormNotice>
            <FormRow>
              <FormField>
                <EntrepriseIdentificationEtAdresseForm
                  key={formKey}
                  formId={FORM_ID}
                  defaultValues={{
                    ...me.entreprisePrincipale,
                    ...me.entreprisePrincipale.adresse,
                  }}
                  onSubmit={handleSubmit}
                  setIsDirty={setShowActions}
                />
              </FormField>
            </FormRow>
          </FormGrid>
        </Card>
      )}
    </ParametrePageTemplate>
  );
}
