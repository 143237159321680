import { PaymentMethod as PaymentMethodService } from '../service/paiement.definition';

export const paymentControllerURL = 'payment';

export const PaymentMethod = PaymentMethodService;
export type PaymentMethod = PaymentMethodService;

export interface PaiementIntentionResponseType {
  montant: number;
  clientSecret?: string;
  invoiceId?: string;
}

export interface InvoiceInfosResponseType {
  pdfUrl?: string;
  date: Date;
  method?: PaymentMethod;
  id: string;
  amountDue: number;
  numero?: string;
}

export interface UpdatePaymentMethodRequestType {
  paymentMethod: string;
}
