import Fuse from "fuse.js";
import { UseFormReturn } from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import REGAFI from "@src/assets/data/REGAFI.json";
import { AutoCompleteWithList } from "@src/components/AutocompleteWithList/AutocompleteWithList";

export type FournisseurRegafiData = {
  fournisseur: string;
  fournisseurs: { value: string }[];
};

type FournisseurRegafiProps = {
  form: UseFormReturn<FournisseurRegafiData>;
};
const fuse = new Fuse(REGAFI, { threshold: 0.4 });
export function FournisseurRegafi({ form }: FournisseurRegafiProps) {
  const value = form.watch("fournisseur");
  return (
    <AutoCompleteWithList<"fournisseur", "fournisseurs">
      form={form}
      label="Sélectionnez les fournisseurs à ajouter"
      placeholder="Saisir le(s) fournisseur(s)"
      options={(value
        ? fuse.search(value, { limit: 20 }).map((r) => r.item)
        : REGAFI.slice(0, 20)
      ).map((item) => ({
        label: item,
        value: item,
      }))}
      nameSelected="fournisseurs"
      nameSearch="fournisseur"
    />
  );
}
