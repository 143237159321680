import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { DownloadFile } from "@src/components/DownloadFile/DownloadFile";
import { Fragment } from "react";
import { GatewayConformiteRcPro } from "@conformite/gateway";
import { format } from "date-fns";
import styles from "./ConformiteReadFiles.module.scss";

type ConformiteReadFilesProps = {
  files: GatewayConformiteRcPro.ConformiteRcProFile[] | undefined;
  siren: string;
  millesime: number;
  isMandataire: boolean;
};
const formatDate = (date: Date) => {
  const d = new Date(date);
  return format(d, "dd/MM/yyyy");
};

export function ConformiteReadFiles({
  files,
  siren,
  millesime,
  isMandataire,
}: ConformiteReadFilesProps) {
  const shouldShowNoFiles = !files || files.length === 0;
  if (shouldShowNoFiles) {
    return (
      <div className={styles.files}>
        <p>Aucune pièce justificative</p>
      </div>
    );
  }
  return (
    <div className={styles.filesContainer}>
      {files
        ?.filter((file) => file?.filename)
        .map((file, index) => (
          <Fragment key={file.pathname ?? index}>
            <div className={styles.files}>
              {file?.filename}
              {file.pathname && file.filename && (
                <DownloadFile
                  millesime={millesime}
                  pathname={file.pathname}
                  filename={file.filename}
                  siren={siren}
                />
              )}
            </div>
            {isMandataire && (
              <p>
                <b>Numéro Orias du mandant :</b> {file.orias || "Non renseigné"}
              </p>
            )}
            {!isMandataire && (
              <>
                {file.startDate && file.endDate && (
                  <p>
                    <b>Date de validité :</b> du {formatDate(file.startDate)} au{" "}
                    {formatDate(file.endDate)}
                  </p>
                )}
                {(!file.startDate || !file.endDate) && (
                  <p>
                    <b>Date de validité :</b> Non renseignée
                  </p>
                )}
              </>
            )}
            {!file.isValid && (
              <InfoCard type="error" key={`${file.filename ?? ""}/error`}>
                Votre document n&apos;est pas valide. Veuillez le remplacer par
                un document valide.
              </InfoCard>
            )}
          </Fragment>
        ))}
    </div>
  );
}
