import { GatewayContact, GatewayCreationContact } from "@conformite/gateway";
import { ParcoursAjoutUtilisateur } from "./parcoursUtilisateur/ParcoursAjoutUtilisateur";

type AjoutUtilisateurPaneContentProps = {
  isEdit?: boolean;
  onSubmit: (
    values: GatewayCreationContact.CreationContactRequestType
  ) => Promise<void>;
  defaultValues?: GatewayContact.ContactWithAssociationsResponseType;
  buttonLabel: string;
};

export function AjoutUtilisateurPaneContent({
  onSubmit,
  defaultValues,
  buttonLabel,
  isEdit,
}: AjoutUtilisateurPaneContentProps) {
  return (
    <ParcoursAjoutUtilisateur
      isEdit={isEdit}
      submitButtonLabel={buttonLabel}
      defaultValues={{
        ...defaultValues,
        entreprises: defaultValues?.entreprisesAssociesIds || [],
      }}
      onSubmit={async (values) =>
        onSubmit(values as GatewayCreationContact.CreationContactRequestType)
      }
    />
  );
}
