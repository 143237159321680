import { ReactComponent as ChevronLeft } from "@src/assets/icons/chevron-left.svg";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { UserMenu } from "@src/components/UserMenu/UserMenu";
import { isString } from "lodash";
import { PropsWithChildren, ReactNode } from "react";
import { PageWithActions } from "@src/components/PageWithActions/PageWithActions";
import { Link } from "@src/components/Link/Link";
import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import styles from "./ControleQuinquenalTemplate.module.scss";
import { useControleQuinquennalAdmin } from "./admin/useControleQuinquennalAdmin";

type ControleQuinquennalTemplateProps = {
  backUrl?: string;
  title: string;
  actions?: ReactNode;
} & PropsWithChildren;
export function ControleQuinquennalTemplate({
  backUrl,
  title,
  actions,
  children,
}: ControleQuinquennalTemplateProps) {
  const { entreprise, controleQuinquennal } = useControleQuinquennalAdmin();

  if (!entreprise || !controleQuinquennal) return <div />;
  return (
    <PageWithActions actions={actions}>
      <div className={styles.header}>
        <div>
          {backUrl && (
            <div className={styles.back}>
              <Link to={backUrl}>
                <Button variant="text" icon={<ChevronLeft />}>
                  Retour
                </Button>
              </Link>
            </div>
          )}
          <div className={styles.headerContent}>
            {isString(title) && <h1>{title}</h1>}
            {!isString(title) && title}
            <h2>
              {entreprise.raisonSociale} - {controleQuinquennal.millesime}
            </h2>
          </div>
        </div>
        <div className={styles.headerUserMenu}>
          <UserMenu />
        </div>
      </div>
      <MaxWidthContent size="large">{children}</MaxWidthContent>
    </PageWithActions>
  );
}
