import {
  Sidebar,
  SidebarDelimiter,
  SidebarLink,
  SidebarSubtitle,
} from "@src/components/SideBar/SideBar";
import { ReactComponent as ChevronLeftIcon } from "@src/assets/icons/chevron-left.svg";
import { GatewayContact } from "@conformite/gateway";
import { useIsAllowed } from "@src/session/useIsAllowed";
import style from "./ParametreSidebar.module.scss";

export function ParametreSidebar() {
  const { hasAllowedRole } = useIsAllowed();
  return (
    <Sidebar>
      <SidebarLink to="/" precise>
        <div className={style.backHomeLink}>
          <ChevronLeftIcon className={style.backHomeLinkIcon} />
          Retour à l&apos;accueil
        </div>
      </SidebarLink>

      <SidebarDelimiter />
      <SidebarSubtitle>Paramètres</SidebarSubtitle>

      <SidebarLink to="/parametres/informations-personnelles">
        Mes informations
      </SidebarLink>

      {hasAllowedRole(GatewayContact.ContactRoleUtilisateur.ADMINISTRATEUR) && (
        <SidebarLink to="/parametres/informations-societe">
          Ma société
        </SidebarLink>
      )}

      {hasAllowedRole(
        GatewayContact.ContactRoleUtilisateur.ADMINISTRATEUR,
        GatewayContact.ContactRoleUtilisateur.RESPONSABLE_CONFORMITE
      ) && (
        <SidebarLink to="/parametres/gestion-utilisateurs">
          Gestion des utilisateurs
        </SidebarLink>
      )}

      {hasAllowedRole(GatewayContact.ContactRoleUtilisateur.ADMINISTRATEUR) && (
        <SidebarLink to="/parametres/factures">Mes factures</SidebarLink>
      )}

      <SidebarLink to="/parametres/identifiants">
        Identifiants de connexion
      </SidebarLink>
    </Sidebar>
  );
}
