import { GatewayImportExportAssujetti } from "@conformite/gateway";
import { nullableDateStringToDate } from "@src/helper/date.helper";
import { userSession } from "@src/session/UserSession";

type ExportAssujettiResponseWithDateString =
  NestedSwapDatesWithStrings<GatewayImportExportAssujetti.ExportAssujettiResponseType>;

const formatResponseExportAssujettis = (
  res: ExportAssujettiResponseWithDateString
): GatewayImportExportAssujetti.ExportAssujettiResponseType =>
  res.map((row) => ({
    ...row,
    honorabilite: {
      ...row.honorabilite,
      lastVerifiedAt: nullableDateStringToDate(row.honorabilite.lastVerifiedAt),
    },
    formationContinueIAS: {
      ...row.formationContinueIAS,
      dateDernierVerification: nullableDateStringToDate(
        row.formationContinueIAS.dateDernierVerification
      ),
    },
    formationContinueIOBSP: {
      ...row.formationContinueIOBSP,
      dateDernierVerification: nullableDateStringToDate(
        row.formationContinueIOBSP.dateDernierVerification
      ),
    },
    capaciteProIAS: {
      ...row.capaciteProIAS,
      datePriseFonction: nullableDateStringToDate(
        row.capaciteProIAS.datePriseFonction
      ),
    },
    capaciteProIOBSP: {
      ...row.capaciteProIOBSP,
      datePriseFonction: nullableDateStringToDate(
        row.capaciteProIOBSP.datePriseFonction
      ),
    },
  }));

export class GatewayImportExportAssujettiAPI {
  public static async importAssujetti(
    rows: {
      data: GatewayImportExportAssujetti.AssujettiRowImport;
      index: number;
    }[],
    clean = false
  ): Promise<GatewayImportExportAssujetti.ImportAssujetiResponseType> {
    const response =
      await userSession.axiosInstance.post<GatewayImportExportAssujetti.ImportAssujetiResponseType>(
        `${
          GatewayImportExportAssujetti.importExportAssujettiControllerUrl
        }/import?clean=${clean.toString()}`,
        {
          rows,
        }
      );
    return response.data;
  }

  public static async exportAssujetti(
    millesime: number
  ): Promise<GatewayImportExportAssujetti.ExportAssujettiResponseType> {
    const response =
      await userSession.axiosInstance.get<ExportAssujettiResponseWithDateString>(
        `${GatewayImportExportAssujetti.importExportAssujettiControllerUrl}/export/${millesime}`
      );
    return formatResponseExportAssujettis(response.data);
  }

  public static async adminExportAssujetti(
    entrepriseId: string,
    millesime: number
  ): Promise<GatewayImportExportAssujetti.ExportAssujettiResponseType> {
    const response =
      await userSession.axiosInstance.get<ExportAssujettiResponseWithDateString>(
        `${GatewayImportExportAssujetti.importExportAssujettiControllerUrl}/export/${entrepriseId}/${millesime}`
      );
    return formatResponseExportAssujettis(response.data);
  }
}
