import {
  GatewayControleQuinquennal,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { Button, Card } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as Download } from "@src/assets/icons/download.svg";
import { ReactComponent as AddIcon } from "@src/assets/icons/plus-circle.svg";
import { FileInputButton } from "@src/components/FileInputButton/FileInputButton";
import { Link } from "@src/components/Link/Link";
import { ControleQuinquennalTemplate } from "@src/controleQuinquennal/ControleQuinquenalTemplate";
import { ControleQuinquennalAxeRemediations } from "@src/controleQuinquennal/adherent/axe/ControleQuinquennalAxeRemediation";
import { axeTypeToLabel } from "@src/controleQuinquennal/controleQuinquennal.helper";
import { useControleQuinquennalAdminAxe } from "../useControleQuinquennalAdminAxe";
import styles from "./ControleQuinquennalAdminAxe.module.scss";
import { ControleQuinquennalAxeProps } from "./axes/ControleQuinquennalAxe.type";
import { ControleQuinquennalAxeGarantieFinanciereIas } from "./axes/ControleQuinquennalAxeGarantieFinanciere";
import { ControleQuinquennalAxeRcPro } from "./axes/ControleQuinquennalAxeRcPro";
import { ControleQuinquennalAxeAssujettis } from "./axes/assujetti/ControleQuinquennalAxeAssujettis";

const axeTypeToReactElement: Record<
  GatewayControleQuinquennal.ControleQuinquennalAxeType,
  (props: ControleQuinquennalAxeProps) => JSX.Element | JSX.Element[] | null
> = {
  ASSUJETTI: ControleQuinquennalAxeAssujettis,
  RCPRO_COA: ControleQuinquennalAxeRcPro(GatewayInfoSocieteOrias.Categorie.COA),
  RCPRO_MIA: ControleQuinquennalAxeRcPro(GatewayInfoSocieteOrias.Categorie.MIA),
  RCPRO_COBSP: ControleQuinquennalAxeRcPro(
    GatewayInfoSocieteOrias.Categorie.COBSP
  ),
  RCPRO_MIOBSP: ControleQuinquennalAxeRcPro(
    GatewayInfoSocieteOrias.Categorie.MIOBSP
  ),
  GARANTIE_FINANCIERE_IAS: ControleQuinquennalAxeGarantieFinanciereIas,
  GARANTIE_FINANCIERE_IOBSP: ControleQuinquennalAxeGarantieFinanciereIas,
};

export function ControleQuinquennalAdminAxePage() {
  const {
    axe,
    entreprise,
    controleQuinquennal,
    remediations,
    numberFiles,
    handleZip,
    handleAddFile,
    handleValidateAxe,
    handleDeleteRemediation,
  } = useControleQuinquennalAdminAxe();
  const ComponentAxe = axeTypeToReactElement[axe?.type ?? "ASSUJETTI"];

  if (!axe || !entreprise || !controleQuinquennal || !remediations)
    return <div />;

  return (
    <ControleQuinquennalTemplate
      title={
        axeTypeToLabel[
          axe?.type ??
            GatewayControleQuinquennal.ControleQuinquennalAxeType.ASSUJETTI
        ]
      }
      backUrl={`/admin/controle-quinquennal/${entreprise.hubspotId}`}
      actions={
        [
          GatewayControleQuinquennal.ControleQuinquennalAxeStatus.EN_COURS,
          GatewayControleQuinquennal.ControleQuinquennalAxeStatus.VALIDE,
        ].includes(axe.status) && (
          <div className={styles.actions}>
            <Link
              to={`/admin/controle-quinquennal/${entreprise.hubspotId}/${axe.idAxe}/remediation`}
            >
              <Button variant="outline-accent" className={styles.remediation}>
                Remédiation
              </Button>
            </Link>

            {axe.status ===
              GatewayControleQuinquennal.ControleQuinquennalAxeStatus
                .EN_COURS && (
              <Button onClick={handleValidateAxe}>Valider la conformité</Button>
            )}
          </div>
        )
      }
    >
      <div className={styles.container}>
        {remediations.length > 0 && (
          <ControleQuinquennalAxeRemediations
            millesime={controleQuinquennal.millesime}
            remediations={remediations}
            siren={entreprise?.siren ?? ""}
            onDeleteRemediation={(idRemediation) =>
              handleDeleteRemediation(idRemediation).finally(() => null)
            }
          />
        )}

        <Card className={styles.downloadCard}>
          <h3>Pièces complémentaire ({numberFiles})</h3>
          <FileInputButton
            variant="text"
            icon={<AddIcon />}
            className={styles.download}
            onChange={handleAddFile}
          >
            Ajouter une pièce
          </FileInputButton>
          <Button
            variant="text"
            icon={<Download />}
            className={styles.download}
            onClick={handleZip}
          >
            Télécharger toutes les pièces
          </Button>
        </Card>
        <Card className={styles.axeComponent}>
          <ComponentAxe
            controleQuinquennal={controleQuinquennal}
            axe={axe}
            entreprise={entreprise}
          />
        </Card>
      </div>
    </ControleQuinquennalTemplate>
  );
}
