import {
  GatewayEntreprise,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { activitesFromEntreprise } from "@src/helper/entreprise.helper";
import {
  excelLabelByCategorieRepresentation,
  excelLabelByFormeJuridique,
  excelLabelByOrganisationProfessionnelle,
  excelLabelByPays,
  excelLabelBySousCategorie,
  excelLabelByTrancheEffectif,
} from "./EntrepriseExport.definition";

export const transformEntrepriseApiRowsToRows = (
  entreprise: GatewayEntreprise.EntrepriseAControllerResponseType
) => ({
  siren: entreprise.siren,
  numeroOrias: entreprise.numeroOrias,
  formeJuridique: entreprise.formeJuridique
    ? excelLabelByFormeJuridique[entreprise.formeJuridique]
    : undefined,
  raisonSociale: entreprise.raisonSociale,
  typeVoie: entreprise.adresse?.typeVoie,
  numeroVoie: entreprise.adresse?.numeroVoie,
  adresse: entreprise.adresse?.libelleVoie,
  complementAdresse: entreprise.adresse?.complementAdresse,
  codePostal: entreprise.adresse?.codePostal,
  ville: entreprise.adresse?.ville,
  pays: entreprise.adresse?.pays
    ? excelLabelByPays[entreprise.adresse.pays]
    : undefined,
  trancheEffectif: entreprise.trancheEffectif
    ? excelLabelByTrancheEffectif[entreprise.trancheEffectif]
    : undefined,
  chiffreAffaire: entreprise.chiffreAffaire,
  categorieOriasCOA: activitesFromEntreprise(entreprise).includes(
    GatewayInfoSocieteOrias.Categorie.COA
  ),
  coaAccessoire: entreprise.categoriesActivitesAccessoires?.includes(
    GatewayInfoSocieteOrias.Categorie.COA
  ),
  categorieOriasMIA: activitesFromEntreprise(entreprise).includes(
    GatewayInfoSocieteOrias.Categorie.MIA
  ),
  miaAccessoire: entreprise.categoriesActivitesAccessoires?.includes(
    GatewayInfoSocieteOrias.Categorie.MIA
  ),
  categorieOriasCOBSP: activitesFromEntreprise(entreprise).includes(
    GatewayInfoSocieteOrias.Categorie.COBSP
  ),
  cobspAccessoire: entreprise.categoriesActivitesAccessoires?.includes(
    GatewayInfoSocieteOrias.Categorie.COBSP
  ),
  categorieOriasMIOBSP: activitesFromEntreprise(entreprise).includes(
    GatewayInfoSocieteOrias.Categorie.MIOBSP
  ),
  miobspAccessoire: entreprise.categoriesActivitesAccessoires?.includes(
    GatewayInfoSocieteOrias.Categorie.MIOBSP
  ),
  sousCategorieCOBSP1: entreprise.sousCategorieCOBSP?.[0]
    ? excelLabelBySousCategorie[entreprise.sousCategorieCOBSP[0]]
    : undefined,
  sousCategorieCOBSP2: entreprise.sousCategorieCOBSP?.[1]
    ? excelLabelBySousCategorie[entreprise.sousCategorieCOBSP[1]]
    : undefined,
  sousCategorieCOBSP3: entreprise.sousCategorieCOBSP?.[2]
    ? excelLabelBySousCategorie[entreprise.sousCategorieCOBSP[2]]
    : undefined,
  sousCategorieCOBSP4: entreprise.sousCategorieCOBSP?.[3]
    ? excelLabelBySousCategorie[entreprise.sousCategorieCOBSP[3]]
    : undefined,
  sousCategorieCOBSP5: entreprise.sousCategorieCOBSP?.[4]
    ? excelLabelBySousCategorie[entreprise.sousCategorieCOBSP[4]]
    : undefined,
  sousCategorieCOBSP6: entreprise.sousCategorieCOBSP?.[5]
    ? excelLabelBySousCategorie[entreprise.sousCategorieCOBSP[5]]
    : undefined,
  sousCategorieMIOBSP1: entreprise.sousCategoriesIOBSP?.[0]
    ? excelLabelBySousCategorie[entreprise.sousCategoriesIOBSP[0]]
    : undefined,
  sousCategorieMIOBSP2: entreprise.sousCategoriesIOBSP?.[1]
    ? excelLabelBySousCategorie[entreprise.sousCategoriesIOBSP[1]]
    : undefined,
  sousCategorieMIOBSP3: entreprise.sousCategoriesIOBSP?.[2]
    ? excelLabelBySousCategorie[entreprise.sousCategoriesIOBSP[2]]
    : undefined,
  sousCategorieMIOBSP4: entreprise.sousCategoriesIOBSP?.[3]
    ? excelLabelBySousCategorie[entreprise.sousCategoriesIOBSP[3]]
    : undefined,
  sousCategorieMIOBSP5: entreprise.sousCategoriesIOBSP?.[4]
    ? excelLabelBySousCategorie[entreprise.sousCategoriesIOBSP[4]]
    : undefined,
  sousCategorieMIOBSP6: entreprise.sousCategoriesIOBSP?.[5]
    ? excelLabelBySousCategorie[entreprise.sousCategoriesIOBSP[5]]
    : undefined,
  organisationPro1: entreprise.organisationProfessionnelle?.[0]
    ? excelLabelByOrganisationProfessionnelle[
        entreprise.organisationProfessionnelle[0]
      ]
    : undefined,
  organisationPro2: entreprise.organisationProfessionnelle?.[1]
    ? excelLabelByOrganisationProfessionnelle[
        entreprise.organisationProfessionnelle[1]
      ]
    : undefined,
  organisationPro3: entreprise.organisationProfessionnelle?.[2]
    ? excelLabelByOrganisationProfessionnelle[
        entreprise.organisationProfessionnelle[2]
      ]
    : undefined,
  categorieRepresentation: entreprise.categorieRepresentation
    ? excelLabelByCategorieRepresentation[entreprise.categorieRepresentation]
    : undefined,
});
