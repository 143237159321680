import {
  GatewayAssujetti,
  GatewayFormationContinue,
} from "@conformite/gateway";
import { AxiosResponse } from "axios";
import { nullableDateStringToDate } from "@src/helper/date.helper";
import { transformKeysInObject } from "@src/helper/object.helper";
import { userSession } from "@src/session/UserSession";

function apiResponseToIAS(
  response: AxiosResponse<NestedSwapDatesWithStrings<GatewayFormationContinue.FormationContinueIas> | null>
) {
  if (!response.data) return null;
  return transformKeysInObject(
    response.data,
    ["dateDernierVerification"],
    nullableDateStringToDate
  ) as GatewayFormationContinue.FormationContinueIas;
}

function apiResponseToIOBSP(
  response: AxiosResponse<NestedSwapDatesWithStrings<GatewayFormationContinue.FormationContinueIobsp> | null>
) {
  if (!response.data) return null;
  return transformKeysInObject(
    response.data,
    ["dateDernierVerification"],
    nullableDateStringToDate
  ) as GatewayFormationContinue.FormationContinueIobsp;
}

export class GatewayFormationContinueApi {
  public static async getIAS(
    idAssujetti: string,
    millesime: number
  ): Promise<GatewayFormationContinue.FormationContinueIas | null> {
    return userSession.axiosInstance
      .get<NestedSwapDatesWithStrings<GatewayFormationContinue.FormationContinueIas> | null>(
        `${GatewayFormationContinue.formationContinueUrl}/${idAssujetti}/${millesime}/ias`
      )
      .then(apiResponseToIAS);
  }

  public static async getIasAdmin(
    idAssujetti: string,
    millesime: number
  ): Promise<GatewayFormationContinue.FormationContinueIas | null> {
    return userSession.axiosInstance
      .get<NestedSwapDatesWithStrings<GatewayFormationContinue.FormationContinueIas> | null>(
        `${GatewayFormationContinue.formationContinueUrl}/${idAssujetti}/${millesime}/ias/admin`
      )
      .then(apiResponseToIAS);
  }

  public static async saveIAS(
    idAssujetti: string,
    millesime: number,
    form: GatewayFormationContinue.FormationContinueIas
  ): Promise<GatewayAssujetti.Avancement | null> {
    const response =
      await userSession.axiosInstance.post<GatewayAssujetti.Avancement | null>(
        `${GatewayFormationContinue.formationContinueUrl}/${idAssujetti}/${millesime}/ias`,
        form
      );
    return response.data;
  }

  public static async getIOBSP(
    idAssujetti: string,
    millesime: number
  ): Promise<GatewayFormationContinue.FormationContinueIobsp | null> {
    return userSession.axiosInstance
      .get<NestedSwapDatesWithStrings<GatewayFormationContinue.FormationContinueIobsp> | null>(
        `${GatewayFormationContinue.formationContinueUrl}/${idAssujetti}/${millesime}/iobsp`
      )
      .then(apiResponseToIOBSP);
  }

  public static async getIobspAdmin(
    idAssujetti: string,
    millesime: number
  ): Promise<GatewayFormationContinue.FormationContinueIobsp | null> {
    return userSession.axiosInstance
      .get<NestedSwapDatesWithStrings<GatewayFormationContinue.FormationContinueIobsp> | null>(
        `${GatewayFormationContinue.formationContinueUrl}/${idAssujetti}/${millesime}/iobsp/admin`
      )
      .then(apiResponseToIOBSP);
  }

  public static async saveIOBSP(
    idAssujetti: string,
    millesime: number,
    form: GatewayFormationContinue.FormationContinueIobsp
  ): Promise<GatewayAssujetti.Avancement | null> {
    const response =
      await userSession.axiosInstance.post<GatewayAssujetti.Avancement | null>(
        `${GatewayFormationContinue.formationContinueUrl}/${idAssujetti}/${millesime}/iobsp`,
        form
      );
    return response.data;
  }
}
