import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import {
  GatewayAssujetti,
  GatewayConformite,
  GatewayContact,
} from "@conformite/gateway";
import { ClickableCard } from "@src/components/ClickableCard/ClickableCard";
import { Assujetti } from "@src/store/store.definition";
import { ReactComponent as ArrowRightIcon } from "@src/assets/icons/arrow-right.svg";
import { ReactComponent as ChevronRightIcon } from "@src/assets/icons/chevron-right.svg";
import { IconStatus } from "@src/components/IconStatus/IconStatus";
import { useEntreprises } from "@src/store/store.entreprise";
import { useIsAllowed } from "@src/session/useIsAllowed";
import styles from "./AssujettiDetailConformite.module.scss";
import { AssujettiPaneContentPage } from "../../AssujettiPaneContent";
import {
  AssujettiParcoursConformiteEnum,
  getAvailableStepsConformite,
} from "../AssujettiParcoursConformite/AssujettiParcoursConformite";

type CardAxeProps = {
  onClick: () => void;
  label: string;
  isValid: boolean;
};
function CardAxe({ isValid, label, onClick }: CardAxeProps) {
  return (
    <ClickableCard onClick={onClick}>
      <div className={styles.cardContent}>
        <div className={styles.cardLabelWrapper}>
          <p className={styles.cardLabel}>{label}</p>
          <IconStatus isValid={isValid} />
        </div>
        <ArrowRightIcon className={styles.arrowRight} />
      </div>
    </ClickableCard>
  );
}

type AssujettiDetailConformiteProps = {
  assujetti: Assujetti;
  navigate: (
    nextPage: AssujettiPaneContentPage,
    resetHistoryWith?: AssujettiPaneContentPage[]
  ) => void;
};

export function AssujettiDetailConformite({
  assujetti,
  navigate,
}: AssujettiDetailConformiteProps) {
  const { entreprisesBySiren } = useEntreprises();
  const { hasAllowedRole, hasAllowedAxeConformite } = useIsAllowed();

  const isValid = (key: keyof GatewayAssujetti.Avancement) => {
    return assujetti.avancement[key] === 100;
  };
  const availableStepsConformite = getAvailableStepsConformite(
    entreprisesBySiren ? entreprisesBySiren[assujetti.sirenCompany] : undefined
  );

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h2>
          {assujetti.surname} {assujetti.name}
        </h2>
        {hasAllowedRole(
          GatewayContact.ContactRoleUtilisateur.ADMINISTRATEUR
        ) && (
          <Button
            iconVariant="left"
            icon={<ChevronRightIcon />}
            variant="text"
            onClick={() => navigate("information-personne")}
          >
            Informations
          </Button>
        )}
      </div>
      <div className={styles.cardList}>
        {hasAllowedAxeConformite(
          GatewayConformite.AxeConformite.HONORABILITE
        ) && (
          <CardAxe
            onClick={() => navigate("form-honorabilite")}
            label="Honorabilité"
            isValid={isValid("honorabilite")}
          />
        )}
        {availableStepsConformite.includes(
          AssujettiParcoursConformiteEnum.CAPACITE_PRO_IAS
        ) &&
          hasAllowedAxeConformite(
            GatewayConformite.AxeConformite.CAPACITE_PRO_IAS
          ) && (
            <CardAxe
              onClick={() => navigate("form-capacite-pro-ias")}
              label="Capacité professionnelle IAS"
              isValid={isValid("capaciteProfessionnelleIas")}
            />
          )}
        {availableStepsConformite.includes(
          AssujettiParcoursConformiteEnum.CAPACITE_PRO_IOBSP
        ) &&
          hasAllowedAxeConformite(
            GatewayConformite.AxeConformite.CAPACITE_PRO_IOBSP
          ) && (
            <CardAxe
              onClick={() => navigate("form-capacite-pro-iobsp")}
              label="Capacité professionnelle IOBSP"
              isValid={isValid("capaciteProfessionnelleIobsp")}
            />
          )}
        {availableStepsConformite.includes(
          AssujettiParcoursConformiteEnum.FORMATION_CONTINUE_IAS
        ) &&
          hasAllowedAxeConformite(
            GatewayConformite.AxeConformite.FORMATION_CONTINUE_IAS
          ) && (
            <CardAxe
              onClick={() => navigate("form-formation-ias")}
              label="Formation continue IAS"
              isValid={isValid("formationContinueIas")}
            />
          )}
        {availableStepsConformite.includes(
          AssujettiParcoursConformiteEnum.FORMATION_CONTINUE_IOBSP
        ) &&
          hasAllowedAxeConformite(
            GatewayConformite.AxeConformite.FORMATION_CONTINUE_IOBSP
          ) && (
            <CardAxe
              onClick={() => navigate("form-formation-iobsp")}
              label="Formation continue IOBSP"
              isValid={isValid("formationContinueIobsp")}
            />
          )}
      </div>
    </div>
  );
}
