import { ParcoursAction } from "@src/parcours/NavigationButtons/ParcoursAction";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import {
  EntrepriseInformationsPersonnellesForm,
  EntrepriseInformationsPersonnellesFormValues,
} from "@src/societes/form/EntrepriseInformationsPersonnellesForm";
import { useCallback } from "react";
import {
  ID_FORM_INVITATION,
  ParcoursInvitationComponentProps,
  ParcoursInvitationStepEnum,
} from "../ParcoursInvitation";

export function ParcoursInvitationInformationsPersonnelles({
  addData,
  data,
  changeStep,
}: ParcoursInvitationComponentProps) {
  const handleSubmit = useCallback(
    (values: EntrepriseInformationsPersonnellesFormValues) => {
      addData(values);
      changeStep(ParcoursInvitationStepEnum.INFORMATIONS_CONNEXION);
    },
    [addData, changeStep]
  );

  return (
    <ParcoursFullPageTemplate
      title="Informations personnelles"
      actions={
        <ParcoursAction formId={ID_FORM_INVITATION} type="submit">
          Suivant
        </ParcoursAction>
      }
    >
      <EntrepriseInformationsPersonnellesForm
        defaultValues={data}
        onSubmit={handleSubmit}
        formId={ID_FORM_INVITATION}
      />
    </ParcoursFullPageTemplate>
  );
}
