import { userSession } from "@src/session/UserSession";

export type OcrType = "garantieFinanciere" | "rcPro";

export class GatewayOcrApi {
  public static async refuseDocument(
    siren: string,
    millesime: number,
    categorie: string,
    type: OcrType,
    pathname: string
  ): Promise<void> {
    return userSession.axiosInstance.put(
      `/conformite/ocr/${type}/${categorie}/${siren}/${millesime}/refuse?pathname=${encodeURIComponent(
        pathname
      )}`
    );
  }
}
