import { ReactNode } from "react";
import {
  FormAction,
  FormGrid,
  FormTitle,
} from "@lya-protect/lya-protect-form-library/dist/UI";

type ParcoursEntrepriseTemplateProps = {
  children: ReactNode;
  title: string;
  actions?: ReactNode;
};

export function ParcoursEntrepriseTemplate({
  title,
  children,
  actions,
}: ParcoursEntrepriseTemplateProps) {
  return (
    <div>
      <FormGrid disableHorizontalGutter>
        <FormTitle>
          <h2>{title}</h2>
        </FormTitle>
        {children}
        <FormAction>{actions}</FormAction>
      </FormGrid>
    </div>
  );
}
