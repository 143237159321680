import { Assujetti } from "@src/store/store.definition";
import { useState } from "react";
import { AssujettiPaneContentPage } from "./AssujettiPaneContent";

type AssujettiPaneState = {
  assujetti: Assujetti | null;
  isOpen: boolean;
  initialPage: AssujettiPaneContentPage;
  paneSession: number;
  defaultSiren?: string;
};

export const useAssujettiPane = () => {
  return useState<AssujettiPaneState>({
    assujetti: null,
    isOpen: false,
    initialPage: "detail-conformite",
    paneSession: 0,
  });
};
