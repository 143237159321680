import { Button, Card } from "@lya-protect/lya-protect-form-library/dist/UI";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { userSession } from "@src/session/UserSession";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as Loader } from "@src/assets/loader.svg";
import { ReactComponent as XCircle } from "@src/assets/icons/x-circle.svg";
import { useStore } from "@src/store/store.hooks";
import { GatewayAdminApi } from "@src/api/admin.api";
import styles from "./AdminSwitchSession.module.scss";

export function AdminSwitchSession() {
  const { contactId, entreprisePrincipalId } = useParams<{
    contactId: string;
    entreprisePrincipalId: string;
  }>();
  const [status, setStatus] = useState<"idle" | "error">("idle");
  const navigate = useNavigate();
  const {
    refresh: { increaseRefreshCount },
  } = useStore();
  useEffect(() => {
    if (!contactId || !entreprisePrincipalId) {
      setStatus("error");
      return;
    }
    GatewayAdminApi.isAdmin()
      .then(() => {
        localStorage.setItem("adminContactId", contactId);
        localStorage.setItem(
          "adminEntreprisePrincipalId",
          entreprisePrincipalId
        );
        increaseRefreshCount();
        navigate("/");
      })
      .catch((error) => {
        console.error(error);
        handleGatewayError({
          onUnhandled: () => {
            setStatus("error");
          },
        })(error);
      });
  }, [contactId, entreprisePrincipalId, navigate, increaseRefreshCount]);
  if (status === "idle") {
    return <Loader />;
  }
  return (
    <Card className={styles.card}>
      {status === "error" && (
        <>
          <div className={styles.header}>
            <XCircle className={styles.icon} />
            <h1>Attention</h1>
          </div>
          <p>
            Vous n&apos;avez pas les droits d&apos;accéder à cette
            fonctionnalité.
          </p>
        </>
      )}
      <Button
        onClick={() => {
          window.close();
        }}
      >
        Fermer la page
      </Button>
    </Card>
  );
}
