import {
  GatewayEntreprise,
  GatewayInfoSocieteOrias,
  GatewayAdresse,
} from "@conformite/gateway";
import { invertMap } from "@src/helper/object.helper";

export const formeJuridiqueByExcelLabel = {
  "Organisme de placement collectif en valeurs mobilières sans personnalité morale":
    GatewayEntreprise.FormeJuridique
      .ORGANISME_DE_PLACEMENT_COLLECTIF_EN_VALEURS_MOBILIERES_SANS_PERSONNALITE_MORALE,
  "Entrepreneur individuel":
    GatewayEntreprise.FormeJuridique.ENTREPRENEUR_INDIVIDUEL,
  Indivision: GatewayEntreprise.FormeJuridique.INDIVISION,
  "Société créée de fait":
    GatewayEntreprise.FormeJuridique.SOCIETE_CREEE_DE_FAIT,
  "Société en participation":
    GatewayEntreprise.FormeJuridique.SOCIETE_EN_PARTICIPATION,
  "Fiducie ": GatewayEntreprise.FormeJuridique.FIDUCIE,
  "Paroisse hors zone concordataire":
    GatewayEntreprise.FormeJuridique.PAROISSE_HORS_ZONE_CONCORDATAIRE,
  "Assujetti unique à la TVA":
    GatewayEntreprise.FormeJuridique.ASSUJETTI_UNIQUE_A_LA_TVA,
  "Autre groupement de droit privé non doté de la personnalité morale":
    GatewayEntreprise.FormeJuridique
      .AUTRE_GROUPEMENT_DE_DROIT_PRIVE_NON_DOTE_DE_LA_PERSONNALITE_MORALE,
  "Personne morale de droit étranger, immatriculée au RCS (registre du commerce et des sociétés)":
    GatewayEntreprise.FormeJuridique
      .PERSONNE_MORALE_DE_DROIT_ETRANGER_IMMATRICULEE_AU_RCS,
  "Personne morale de droit étranger, non immatriculée au RCS":
    GatewayEntreprise.FormeJuridique
      .PERSONNE_MORALE_DE_DROIT_ETRANGERE_NON_IMMATRICULEE_AU_RCS,
  "Etablissement public ou régie à caractère industriel ou commercial":
    GatewayEntreprise.FormeJuridique
      .ETABLISSEMENT_PUBLIC_OU_REGIE_A_CARACTERE_INDUSTRIEL_OU_COMMERCIAL,
  "Société coopérative commerciale particulière":
    GatewayEntreprise.FormeJuridique
      .SOCIETE_COOPERATIVE_COMMERCIALE_PARTICULIERE,
  "Société en nom collectif":
    GatewayEntreprise.FormeJuridique.SOCIETE_EN_NOM_COLLECTIF,
  "Société en commandite":
    GatewayEntreprise.FormeJuridique.SOCIETE_EN_COMMANDITE,
  "Société à responsabilité limitée (SARL)":
    GatewayEntreprise.FormeJuridique.SOCIETE_A_RESPONSABILITE_LIMITEE,
  "Société anonyme à conseil d'administration":
    GatewayEntreprise.FormeJuridique.SOCIETE_ANONYME_A_CONSEIL_D_ADMINISTRATION,
  "Société anonyme à directoire":
    GatewayEntreprise.FormeJuridique.SOCIETE_ANONYME_A_DIRECTOIRE,
  "Société par actions simplifiée":
    GatewayEntreprise.FormeJuridique.SOCIETE_PAR_ACTIONS_SIMPLIFIEE,
  "Société européenne ": GatewayEntreprise.FormeJuridique.SOCIETE_EUROPEENNE,
  "Caisse d'épargne et de prévoyance":
    GatewayEntreprise.FormeJuridique.CAISSE_D_EPARGNE_ET_DE_PREVOYANCE,
  "Groupement d'intérêt économique":
    GatewayEntreprise.FormeJuridique.GROUPEMENT_D_INTERET_ECONOMIQUE,
  "Société coopérative agricole":
    GatewayEntreprise.FormeJuridique.SOCIETE_COOPERATIVE_AGRICOLE,
  "Société d'assurance mutuelle":
    GatewayEntreprise.FormeJuridique.SOCIETE_D_ASSURANCE_MUTUELLE,
  "Société civile": GatewayEntreprise.FormeJuridique.SOCIETE_CIVILE,
  "Autre personne morale de droit privé inscrite au registre du commerce et des sociétés":
    GatewayEntreprise.FormeJuridique
      .AUTRE_PERSONNE_MORALE_DE_DROIT_PRIVE_INSCRITE_AU_REGISTRE_DU_COMMERCE_ET_DES_SOCIETES,
  "Administration de l'état":
    GatewayEntreprise.FormeJuridique.ADMINISTRATION_DE_L_ETAT,
  "Collectivité territoriale":
    GatewayEntreprise.FormeJuridique.COLLECTIVITE_TERRITORIALE,
  "Etablissement public administratif":
    GatewayEntreprise.FormeJuridique.ETABLISSEMENT_PUBLIC_ADMINISTRATIF,
  "Autre personne morale de droit public administratif":
    GatewayEntreprise.FormeJuridique
      .AUTRE_PERSONNE_MORALE_DE_DROIT_PUBLIC_ADMINISTRATIF,
  "Organisme gérant un régime de protection sociale à adhésion obligatoire":
    GatewayEntreprise.FormeJuridique
      .ORGANISME_GERANT_UN_REGIME_DE_PROTECTION_SOCIALE_A_ADHESION_OBLIGATOIRE,
  "Organisme mutualiste": GatewayEntreprise.FormeJuridique.ORGANISME_MUTUALISTE,
  "Comité d'entreprise": GatewayEntreprise.FormeJuridique.COMITE_D_ENTREPRISE,
  "Organisme professionnel":
    GatewayEntreprise.FormeJuridique.ORGANISME_PROFESSIONNEL,
  "Organisme de retraite à adhésion non obligatoire":
    GatewayEntreprise.FormeJuridique
      .ORGANISME_DE_RETRAITE_A_ADHESION_NON_OBLIGATOIRE,
  "Syndicat de propriétaires":
    GatewayEntreprise.FormeJuridique.SYNDICAT_DE_PROPRIETAIRES,
  "Association loi 1901 ou assimilé":
    GatewayEntreprise.FormeJuridique.ASSOCIATION_LOI_1901_OU_ASSIMILE,
  Fondation: GatewayEntreprise.FormeJuridique.FONDATION,
  "Autre personne morale de droit privé":
    GatewayEntreprise.FormeJuridique.AUTRE_PERSONNE_MORALE_DE_DROIT_PRIVE,
};
export const excelLabelByFormeJuridique = invertMap(formeJuridiqueByExcelLabel);

export const paysByExcelLabel = {
  France: GatewayAdresse.Pays.FRANCE,
  Allemagne: GatewayAdresse.Pays.ALLEMAGNE,
  Belgique: GatewayAdresse.Pays.BELGIQUE,
  Croatie: GatewayAdresse.Pays.CROATIE,
  Danemark: GatewayAdresse.Pays.DANEMARK,
  Estonie: GatewayAdresse.Pays.ESTONIE,
  Finlande: GatewayAdresse.Pays.FINLANDE,
  Grèce: GatewayAdresse.Pays.GRECE,
  Hongrie: GatewayAdresse.Pays.HONGRIE,
  Islande: GatewayAdresse.Pays.ISLANDE,
  Italie: GatewayAdresse.Pays.ITALIE,
  Lettonie: GatewayAdresse.Pays.LETTONIE,
  Liechtenstein: GatewayAdresse.Pays.LIECHTENSTEIN,
  Lituanie: GatewayAdresse.Pays.LITUANIE,
  Luxembourg: GatewayAdresse.Pays.LUXEMBOURG,
  Malte: GatewayAdresse.Pays.MALTE,
  Norvège: GatewayAdresse.Pays.NORVEGE,
  "Pays-Bas": GatewayAdresse.Pays.PAYS_BAS,
  Pologne: GatewayAdresse.Pays.POLOGNE,
  Portugal: GatewayAdresse.Pays.PORTUGAL,
  "République Tchèque": GatewayAdresse.Pays.REPUBLIQUE_TCHEQUE,
  Slovaquie: GatewayAdresse.Pays.SLOVAQUIE,
  Slovénie: GatewayAdresse.Pays.SLOVENIE,
  Suède: GatewayAdresse.Pays.SUEDE,
  Suisse: GatewayAdresse.Pays.SUISSE,
};
export const excelLabelByPays = invertMap(paysByExcelLabel);

export const trancheEffectifByExcelLabel = {
  "Pas de salarié": GatewayEntreprise.TrancheEffectif.AUCUN,
  "1 ou 2 salariés": GatewayEntreprise.TrancheEffectif.DE_1_A_2,
  "3 à 5 salariés": GatewayEntreprise.TrancheEffectif.DE_3_A_5,
  "6 à 9 salariés": GatewayEntreprise.TrancheEffectif.DE_6_A_9,
  "10 à 19 salariés": GatewayEntreprise.TrancheEffectif.DE_10_A_19,
  "20 à 49 salariés": GatewayEntreprise.TrancheEffectif.DE_20_A_49,
  "50 à 99 salariés": GatewayEntreprise.TrancheEffectif.DE_50_A_99,
  "100 à 199 salariés": GatewayEntreprise.TrancheEffectif.DE_100_A_199,
  "200 à 249 salariés": GatewayEntreprise.TrancheEffectif.DE_200_A_249,
  "250 à 499 salariés": GatewayEntreprise.TrancheEffectif.DE_250_A_499,
  "500 à 999 salariés": GatewayEntreprise.TrancheEffectif.DE_500_A_999,
  "1000 à 1999 salariés": GatewayEntreprise.TrancheEffectif.DE_1000_A_1999,
  "2000 à 4999 salariés": GatewayEntreprise.TrancheEffectif.DE_2000_A_4999,
  "5000 à 9999 salariés": GatewayEntreprise.TrancheEffectif.DE_5000_A_9999,
  "10000 salariés et plus": GatewayEntreprise.TrancheEffectif.PLUS_DE_10000,
};
export const excelLabelByTrancheEffectif = invertMap(
  trancheEffectifByExcelLabel
);

export enum ExcelOrganisationPro {
  PLANETE_CSCA = "PLANETE CSCA",
  APIC = "APIC",
  GCAB = "GCAB",
  AUTRE = "Autre",
  AUCUNE = "Aucune",
}

export const organisationProfessionnelleByExcelLabel = {
  [ExcelOrganisationPro.PLANETE_CSCA]:
    GatewayInfoSocieteOrias.OrganisationProfessionnelle.PLANETE_CSCA,
  [ExcelOrganisationPro.APIC]:
    GatewayInfoSocieteOrias.OrganisationProfessionnelle.APIC,
  [ExcelOrganisationPro.GCAB]:
    GatewayInfoSocieteOrias.OrganisationProfessionnelle.GCAB,
  [ExcelOrganisationPro.AUTRE]:
    GatewayInfoSocieteOrias.OrganisationProfessionnelle.AUTRE,
  [ExcelOrganisationPro.AUCUNE]:
    GatewayInfoSocieteOrias.OrganisationProfessionnelle.AUCUNE,
};

export const excelLabelByOrganisationProfessionnelle = invertMap(
  organisationProfessionnelleByExcelLabel
);

export const sousCategorieByExcelLabel = {
  "Services de paiement":
    GatewayInfoSocieteOrias.ActiviteBancaire.SERVICE_PAIEMENT,
  "Regroupement de crédits":
    GatewayInfoSocieteOrias.ActiviteBancaire.REGROUPEMENT_CREDITS,
  "Crédits à la consommation":
    GatewayInfoSocieteOrias.ActiviteBancaire.CREDIT_CONSOMMATION,
  "Prêts viagers hypothécaires":
    GatewayInfoSocieteOrias.ActiviteBancaire.PRET_VIAGER_HYPOTECAIRE,
  "Crédits immobiliers":
    GatewayInfoSocieteOrias.ActiviteBancaire.CREDIT_IMMOBILIER,
  "Autres activités": GatewayInfoSocieteOrias.ActiviteBancaire.AUTRES_ACTIVITES,
};
export const excelLabelBySousCategorie = invertMap(sousCategorieByExcelLabel);

export const categorieRepresentationByExcelLabel = {
  "Courtier de moins de 20 salariés":
    GatewayInfoSocieteOrias.CategorieRepresentation.COURTIERS_MOINS_20_SALARIES,
  "Courtier de plus de 20 salariés":
    GatewayInfoSocieteOrias.CategorieRepresentation.COURTIERS_PLUS_20_SALARIES,
  "Courtier grossiste":
    GatewayInfoSocieteOrias.CategorieRepresentation.COURTIER_GROSSISTE,
  "Courtier affinitaire":
    GatewayInfoSocieteOrias.CategorieRepresentation.COURTIER_AFFINITAIRE,
  Comparateur: GatewayInfoSocieteOrias.CategorieRepresentation.COMPARATEUR,
  "Mandataire d'intermédiaire en assurance ":
    GatewayInfoSocieteOrias.CategorieRepresentation
      .MANDATAIRE_INTERMEDIAIRE_ASSURANCE,
  "Courtier en opérations de banque et en services de paiement":
    GatewayInfoSocieteOrias.CategorieRepresentation
      .COURTIER_OPERATION_DE_BANQUE_ET_SERVICES_DE_PAIEMENT,
  "Mandataire d'intermédiaire en opérations de banque et en services de paiement":
    GatewayInfoSocieteOrias.CategorieRepresentation
      .MANDATAIRE_INTERMEDIAIRE_OPERATION_DE_BANQUE_ET_SERVICE_DE_PAIEMENT,
};
export const excelLabelByCategorieRepresentation = invertMap(
  categorieRepresentationByExcelLabel
);
