import { ReactElement, createRef, useContext } from "react";
import { DialogContext } from "./DialogContext";
import { ConfirmDialog, ConfirmDialogProps } from "./ConfirmDialog";

type DialogProps<TResult> = {
  onClose: (value: TResult) => void;
  dialogRef: React.MutableRefObject<HTMLDialogElement | null>;
};
export const useDialog = () => {
  const { setDialog } = useContext(DialogContext);
  const openDialog = <
    TResult extends boolean = boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    TAdditionalProps extends Record<string, any> | undefined = undefined
  >(
    component: (props: DialogProps<TResult> & TAdditionalProps) => ReactElement,
    additionalProps?: TAdditionalProps
  ) =>
    new Promise<TResult | undefined>((resolve) => {
      const dialogRef = createRef<HTMLDialogElement>();
      setTimeout(() => {
        dialogRef.current?.addEventListener(
          "close",
          (e) => {
            resolve(!e.defaultPrevented as TResult | undefined);
          },
          { once: true }
        );
        dialogRef.current?.showModal();
      }, 0);
      setDialog({
        component,
        props: {
          ...additionalProps,
          onClose: (value: TResult) => {
            dialogRef.current?.close(value.toString());
            setTimeout(() => setDialog(null), 0);
          },
          dialogRef,
        },
      });
    });
  return {
    openDialog,
    openConfirmDialog: async (
      props: Omit<ConfirmDialogProps, "onClose" | "dialogRef">
    ): Promise<boolean> => {
      const result = await openDialog(({ onClose, dialogRef }) =>
        ConfirmDialog({
          ...props,
          onClose,
          dialogRef,
        })
      );
      return result === true;
    },
  };
};
