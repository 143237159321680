import { Button, Card } from "@lya-protect/lya-protect-form-library/dist/UI";
import classNames from "classnames";
import { ReactNode } from "react";
import { ReactComponent as IconX } from "@src/assets/icons/x-lg.svg";
import style from "./infoCard.module.scss";

type InfoCardProps = {
  children: ReactNode;
  className?: string;
  onCancel?: () => void;
  type?: "info" | "warning" | "error" | "success" | "neutral";
};

export function InfoCard({
  children,
  className,
  type = "info",
  onCancel,
}: InfoCardProps) {
  return (
    <Card
      variant="outline"
      className={classNames(style.infoCard, style[type], className)}
    >
      <div className={style.content}>{children}</div>
      {onCancel && (
        <Button
          onClick={onCancel}
          variant="text"
          icon={<IconX />}
          className={style.cancelButton}
        />
      )}
    </Card>
  );
}
