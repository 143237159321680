import { ConformiteAssujettiList } from "./ConformiteAssujettiList";

export function ConformiteAssujettiListFormationContinueIas() {
  return (
    <ConformiteAssujettiList
      title="Formation Continue IAS"
      avancementType="formationContinueIas"
      backLink="/conformite/formation-continue/ias"
    />
  );
}
