import {
  GatewayControleQuinquennal,
  GatewayEntreprise,
} from "@conformite/gateway";
import {
  Button,
  Card,
  displaySucessToast,
  displayWarningToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import {
  axeTypeToLabel,
  controleTypeOrder,
} from "@src/controleQuinquennal/controleQuinquennal.helper";
import { Link } from "@src/components/Link/Link";
import { ReactComponent as ArrowRight } from "@src/assets/icons/arrow-right.svg";
import { ReactComponent as Download } from "@src/assets/icons/download.svg";
import { ReactComponent as ThreeDot } from "@src/assets/icons/three-dots-outline.svg";
import {
  DownloadedFile,
  getDownloadedFileAdmin,
} from "@src/helper/file.helper";
import { groupBy } from "lodash";
import { ZipEntry, zipFilesAndDownload } from "@src/helper/zip.helper";
import { TagWithIcon } from "@src/components/Tag/TagWithIcon";
import { TagVariant } from "@src/components/Tag/Tag";
import { useMemo } from "react";
import { useDialog } from "@src/components/Dialog/useDialog";
import { useNavigate } from "react-router-dom";
import { ControleQuinquennalApi } from "@src/api/controleQuinquennal.api";
import { ControleQuinquennalTemplate } from "../../ControleQuinquenalTemplate";
import style from "./ControleQuinquennalAdminPage.module.scss";
import { useControleQuinquennalAdmin } from "../useControleQuinquennalAdmin";

const axeStatusToTagVariant: Record<
  GatewayControleQuinquennal.ControleQuinquennalAxeStatus,
  { variant: TagVariant; text: string }
> = {
  [GatewayControleQuinquennal.ControleQuinquennalAxeStatus.EN_COURS]: {
    variant: "info",
    text: "À contrôler",
  },
  [GatewayControleQuinquennal.ControleQuinquennalAxeStatus.REMEDIE]: {
    variant: "error",
    text: "Remédiation",
  },
  [GatewayControleQuinquennal.ControleQuinquennalAxeStatus.VALIDE]: {
    variant: "success",
    text: "Contrôle validé",
  },
};

type ControleQuinquennalItemProps = {
  axe: GatewayControleQuinquennal.ControleQuinquennalAxeWithNumberOfFiles;
};
function ControleQuinquennalAxeItem({ axe }: ControleQuinquennalItemProps) {
  const info = useMemo(() => axeStatusToTagVariant[axe.status], [axe.status]);
  return (
    <Card className={style.itemWrapper}>
      <h4>{axeTypeToLabel[axe.type]}</h4>
      <p>{axe.numberOfFiles} pièces déposées</p>
      <TagWithIcon
        variant={info.variant}
        overrideIcon={
          axe.status ===
          GatewayControleQuinquennal.ControleQuinquennalAxeStatus.EN_COURS
            ? ThreeDot
            : undefined
        }
      >
        {info.text}
      </TagWithIcon>
      <Link to={`${axe.idAxe}`}>
        <Button variant="text" icon={<ArrowRight />} className={style.access}>
          Accéder
        </Button>
      </Link>
    </Card>
  );
}

const useControleQuinquennalAxeZip = (
  axes: GatewayControleQuinquennal.ControleQuinquennalAxeWithFiles[],
  entreprise: GatewayEntreprise.EntreprisePrincipaleResponseType | undefined
) => {
  return {
    handleZip: () => {
      if (!entreprise) return;
      const filesToDownload = axes.flatMap((axe) => {
        return axe.controleQuinquennalAxeFiles.map((file) =>
          getDownloadedFileAdmin(
            entreprise.siren,
            file.pathname,
            file.filename
          ).then((fileDownloaded) => ({
            file: fileDownloaded,
            axe: axe.type,
          }))
        );
      });
      if (filesToDownload.length === 0) {
        displayWarningToast("Aucune pièce à télécharger");
        return;
      }
      Promise.all(filesToDownload)
        .then(async (responses) => {
          const filesByAxe = groupBy(responses, "axe");
          const zipEntries: ZipEntry[] = Object.entries(filesByAxe).map(
            ([axe, files]) => ({
              foldername:
                axeTypeToLabel[
                  axe as GatewayControleQuinquennal.ControleQuinquennalAxeType
                ],
              folder: (
                files.filter((file) => file.file) as {
                  axe: string;
                  file: DownloadedFile;
                }[]
              ).map((file) => ({
                filename: file.file.filename,
                file: file.file.file,
              })),
            })
          );
          await zipFilesAndDownload(zipEntries);
        })
        .catch(console.error);
    },
  };
};
export function ControleQuinquennalAdminPage() {
  const { controleQuinquennal, status, axes, entreprise } =
    useControleQuinquennalAdmin();
  const { handleZip } = useControleQuinquennalAxeZip(axes, entreprise);
  const { openConfirmDialog } = useDialog();
  const navigate = useNavigate();
  const numberOfFiles = axes.flatMap(
    (axe) => axe.controleQuinquennalAxeFiles
  ).length;
  const canSubmit = axes.every(
    (axe) =>
      axe.status !==
      GatewayControleQuinquennal.ControleQuinquennalAxeStatus.EN_COURS
  );
  const onValidate = () => {
    if (!controleQuinquennal || !entreprise) return;
    if (
      axes.some(
        (axe) =>
          axe.status ===
          GatewayControleQuinquennal.ControleQuinquennalAxeStatus.REMEDIE
      )
    ) {
      openConfirmDialog({
        title: "Vous allez envoyer une demande de remédiation",
        message:
          "Un axe n’étant pas conforme, l'utilisateur contrôlé sera invité à compléter ou corriger sa conformité avant la fin de l'exercice.",
        cancelText: "Retour",
        confirmText: "Confirmer",
      })
        .then((shouldValidate) => {
          if (!shouldValidate) return;
          ControleQuinquennalApi.validateAdmin(
            controleQuinquennal,
            entreprise.siren
          )
            .then(() => {
              displaySucessToast(
                "Le contrôle quinquennal a bien été validé, vous pouvez fermer cette page."
              );
            })
            .catch(console.error);
        })
        .catch(console.error);
    } else {
      navigate(
        `/admin/controle-quinquennal/${entreprise?.hubspotId ?? ""}/rapport`
      );
    }
  };
  const axesSorted = useMemo(
    () =>
      controleQuinquennal?.controleQuinquennalAxes
        ? [...controleQuinquennal.controleQuinquennalAxes].sort(
            (axe1, axe2) =>
              controleTypeOrder[axe1.type] - controleTypeOrder[axe2.type]
          )
        : [],
    [controleQuinquennal?.controleQuinquennalAxes]
  );

  if (status !== "loaded" || !controleQuinquennal || !entreprise)
    return <div />;
  return (
    <ControleQuinquennalTemplate
      title="Contrôle"
      actions={
        <div className={style.actions}>
          <Button disabled={!canSubmit} onClick={onValidate}>
            Soumettre
          </Button>
        </div>
      }
    >
      <div className={style.container}>
        <Card className={style.downloadCard}>
          <h3>Pièces déposées ({numberOfFiles})</h3>
          <Button
            variant="text"
            icon={<Download />}
            className={style.download}
            onClick={handleZip}
          >
            Télécharger toutes les pièces
          </Button>
        </Card>
        <div className={style.axes}>
          {axesSorted.map((axe) => (
            <ControleQuinquennalAxeItem key={axe.idAxe} axe={axe} />
          ))}
        </div>
      </div>
    </ControleQuinquennalTemplate>
  );
}
