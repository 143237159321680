import axios, { AxiosError, AxiosHeaders, AxiosRequestConfig } from "axios";
import { Navigate } from "react-router-dom";
import {
  LocalStorageKeys,
  localStorageHelper,
} from "@src/helper/localStorage.helper";
import { isStringifiedJSON } from "@src/helper/object.helper";
import { UserSession } from "./UserSession";

export class ConnectedAxios {
  axiosApiInstance = axios.create({
    baseURL: `${import.meta.env.VITE_SCHEME_GATEWAY}://${
      import.meta.env.VITE_URL_GATEWAY
    }`,
  });

  session: UserSession;

  constructor(session: UserSession) {
    this.session = session;

    this.axiosApiInstance.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        if (this.session.sessionData.isConnected === false)
          throw Error("not connected");
        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${this.session.sessionData.idToken}`,
            "x-admin-contact-id":
              localStorageHelper.getItem(LocalStorageKeys.ADMIN_CONTACT_ID) ??
              undefined,
            "x-admin-entreprise-principal-id":
              localStorageHelper.getItem(
                LocalStorageKeys.ADMIN_ENTREPRISE_PRINCIPAL_ID
              ) ?? undefined,
          } as unknown as AxiosHeaders,
        };
      }
    );
    this.axiosApiInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error: AxiosError) => {
        const originalRequest = error.config as AxiosRequestConfig & {
          retriedAfterRefresh?: boolean;
        };
        if (
          error.response?.status !== 401 ||
          originalRequest.retriedAfterRefresh ||
          (originalRequest.params as { noRetry?: boolean })?.noRetry
        )
          throw error;
        originalRequest.retriedAfterRefresh = true;
        await this.session.refresh();
        if (this.session.sessionData.isConnected === false) {
          Navigate({
            to: "/login",
          });
          throw Error("Refresh request failed");
        }
        originalRequest.headers = {
          ...(originalRequest.headers || {}),
          Authorization: `Bearer ${this.session.sessionData.idToken}`,
          "x-admin-contact-id":
            localStorageHelper.getItem(LocalStorageKeys.ADMIN_CONTACT_ID) ??
            undefined,
          "x-admin-entreprise-principal-id":
            localStorageHelper.getItem(
              LocalStorageKeys.ADMIN_ENTREPRISE_PRINCIPAL_ID
            ) ?? undefined,
        };
        if (isStringifiedJSON(originalRequest.data as string)) {
          const parsed = JSON.parse(originalRequest.data as string) as Record<
            string,
            unknown
          >;
          if (parsed.accessToken) {
            originalRequest.data = JSON.stringify({
              ...parsed,
              accessToken: localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN),
            });
          }
        }
        return this.axiosApiInstance(originalRequest);
      }
    );
  }
}
