import { StepComponentProps } from "@src/parcours/Parcours.types";
import { RenouvellementData } from "./steps/RenouvellementData";

export const ID_FORM_RENOUVELLEMENT = "ID_FORM_RENOUVELLEMENT";

export enum RenouvellementStepEnum {
  INTRODUCTION = "introduction",
  RESUME_ENTREPRISE = "resume-entreprise",
  IDENTIFICATION = "identification",
  ADRESSE = "adresse",
  INFORMATIONS_ACTIVITE = "informations-activite",
  INFORMATIONS_PERSONNELLES = "informations-personnelles",
  MISE_A_JOUR_SOCIETES = "mise-a-jour-societes",
  LISTE_SOCIETES = "liste-societes",
  SOUS_CATEGORIES = "sous-categories",
  ORGANISATIONS_PROFESSIONNELLES = "organisations-professionnelles",
  INFORMATIONS_COMPLEMENTAIRES = "informations-complementaires",
  CHIFFRE_AFFAIRE = "chiffre-affaire",
  PAIEMENT = "paiement",
  CODE_REDUCTION = "code-reduction",
}

export type ParcoursRenouvellementStepComponentProps = StepComponentProps<
  RenouvellementStepEnum,
  RenouvellementData,
  Record<string, unknown>
>;
