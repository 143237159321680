import { useMemo, useState } from "react";

export type Order = "asc" | "desc";
export type FilterProps<T, FilterKeys extends string> = {
  name: FilterKeys;
  filter: (content: T, values: string[]) => boolean;
};
export type SortProps<T, SortKeys extends string> = {
  name: SortKeys;
  sorting: (content1: T, content2: T) => number;
};
export type DefaultSort<SortKeys extends string> = {
  name: SortKeys;
  order: Order;
};
export type FilterSelection<FilterKeys extends string> = Record<
  FilterKeys,
  string[]
>;
type FilterPaginationProps<
  T,
  FilterKeys extends string,
  SortKeys extends string
> = {
  content: T[];
  filters?: FilterProps<T, FilterKeys>[];
  pagination?: { pageSize: number; startingPage?: number };
  sorts?: SortProps<T, SortKeys>[];
  defaultSort?: DefaultSort<SortKeys>;
};
export type FilterPaginationReturn<
  T,
  FilterKeys extends string,
  SortKeys extends string
> = {
  config: FilterPaginationProps<T, FilterKeys, SortKeys>;
  page: number;
  setPage: (page: number) => void;
  pageSize: number;
  filtersSelected: FilterSelection<FilterKeys> | null;
  setFiltersSelected: (filters: FilterSelection<FilterKeys> | null) => void;
  sortSelected: DefaultSort<SortKeys> | null;
  setSortSelected: (sort: DefaultSort<SortKeys> | null) => void;
  content: T[];
  numberOfContentUnpaginated: number;
  setFilterSelected: (key: FilterKeys, value: string[]) => void;
};
export const useFilterPagination = <
  T,
  FilterKeys extends string,
  SortKeys extends string
>(
  config: FilterPaginationProps<T, FilterKeys, SortKeys>
): FilterPaginationReturn<T, FilterKeys, SortKeys> => {
  const {
    content: contentRaw,
    filters,
    pagination,
    sorts,
    defaultSort,
  } = config;
  const [page, setPage] = useState(pagination?.startingPage || 0);
  const [filtersSelected, setFiltersSelected] =
    useState<FilterSelection<FilterKeys> | null>(
      filters
        ? filters.reduce(
            (acc, filter) => ({ ...acc, [filter.name]: [] }),
            {} as FilterSelection<FilterKeys>
          )
        : null
    );
  const [sortSelected, setSortSelected] =
    useState<DefaultSort<SortKeys> | null>(
      defaultSort
        ? {
            name: defaultSort.name,
            order: defaultSort.order,
          }
        : null
    );
  const contentFiltered = useMemo(() => {
    if (!filters || !filtersSelected) return contentRaw;
    const filtersToApply = Object.entries(filtersSelected) as [
      FilterKeys,
      string[]
    ][];
    const filtersMethod = filtersToApply.map(([key, value]) => {
      const filterMethod = filters.find((f) => f.name === key);
      if (!filterMethod) return null;
      return (element: T) => {
        return filterMethod.filter(element, value);
      };
    });
    return contentRaw.filter((content) =>
      filtersMethod.every((filter) => {
        if (!filter) return true;
        return filter(content);
      })
    );
  }, [contentRaw, filters, filtersSelected]);

  const contentSorted = useMemo(() => {
    if (!sorts || !sortSelected) return contentFiltered;
    const sortedMethod = sorts.find((sort) => sort.name === sortSelected.name);
    if (!sortedMethod) return contentFiltered;
    return contentFiltered.sort((a, b) => {
      const sortedValue = sortedMethod.sorting(a, b);
      return sortSelected.order === "asc" ? sortedValue : sortedValue * -1;
    });
  }, [contentFiltered, sortSelected, sorts]);

  const contentPaginated = useMemo(() => {
    if (!pagination) return contentSorted;
    return contentSorted.slice(
      page * pagination.pageSize,
      (page + 1) * pagination.pageSize
    );
  }, [pagination, contentSorted, page]);

  return {
    config,
    page,
    pageSize: pagination?.pageSize ?? 0,
    setPage,
    filtersSelected,
    setFiltersSelected,
    setFilterSelected: (key: FilterKeys, value: string[]) =>
      setFiltersSelected((old) => {
        if (!old) return null;
        return { ...old, [key]: value };
      }),
    sortSelected,
    setSortSelected,
    content: contentPaginated,
    numberOfContentUnpaginated: contentFiltered.length,
  };
};
