import { GatewayControleQuinquennal } from "@conformite/gateway";
import { Button, Card } from "@lya-protect/lya-protect-form-library/dist/UI";
import { Link } from "@src/components/Link/Link";
import { ReactComponent as ArrowRight } from "@src/assets/icons/arrow-right.svg";
import { axeTypeToLabel } from "@src/controleQuinquennal/controleQuinquennal.helper";
import classNames from "classnames";
import { TagWithIcon } from "@src/components/Tag/TagWithIcon";
import { useMemo } from "react";
import styles from "./ControleQuinquennalItemCard.module.scss";

type ControlQuinquennalItemCardProps = {
  axe: GatewayControleQuinquennal.ControleQuinquennalAxeWithNumberOfFiles;
  controleQuinquennal?: GatewayControleQuinquennal.ControleQuinquennal;
};
export function ControleQuinquennalItemCard({
  axe,
  controleQuinquennal,
}: ControlQuinquennalItemCardProps) {
  const pieceLabel = `${
    axe.numberOfFiles > 0 ? axe.numberOfFiles : "Aucune"
  } pièce${axe.numberOfFiles > 1 ? "s" : ""}`;

  const isValide =
    axe.status ===
    GatewayControleQuinquennal.ControleQuinquennalAxeStatus.VALIDE;
  const status = useMemo(() => {
    if (!axe.isComplet) {
      return (
        <TagWithIcon variant="error" className={styles.tag}>
          Incomplet
        </TagWithIcon>
      );
    }
    if (isValide) {
      return (
        <TagWithIcon variant="success" className={styles.tag}>
          Validé
        </TagWithIcon>
      );
    }
    return (
      <TagWithIcon variant="success" className={styles.tag}>
        Complet
      </TagWithIcon>
    );
  }, [axe.isComplet, isValide]);

  return (
    <Card key={axe.idAxe} className={styles.axe}>
      <p className={styles.categorie}>{axeTypeToLabel[axe.type]}</p>
      <p>{pieceLabel}</p>
      {status}
      <Link
        to={`/controle-quinquennal/${axe.idControleQuinquennal}/${axe.idAxe}`}
      >
        <Button variant="text" className={styles.action} icon={<ArrowRight />}>
          Accéder
        </Button>
      </Link>
    </Card>
  );
}
