import { ConformiteRcProReadContent } from "@src/conformite/rcPro/read/ConformiteRcProRead";
import { useRcPro } from "@src/conformite/rcPro/ConformiteRcPro";
import { GatewayInfoSocieteOrias } from "@conformite/gateway";
import { ControleQuinquennalAxeProps } from "./ControleQuinquennalAxe.type";

export const ControleQuinquennalAxeRcPro = (
  categorie: GatewayInfoSocieteOrias.Categorie
) =>
  function ControleQuinquennalAxeRcProWithType({
    controleQuinquennal,
    entreprise,
  }: ControleQuinquennalAxeProps) {
    const { properties } = useRcPro({
      id: entreprise.siren,
      millesime: controleQuinquennal.millesime,
      categorie,
      isAdmin: true,
    });
    return (
      <ConformiteRcProReadContent
        properties={properties}
        millesime={controleQuinquennal.millesime}
        siren={entreprise.siren}
      />
    );
  };
