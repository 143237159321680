import { GatewayControleQuinquennal } from "@conformite/gateway";

export const axeTypeToLabel: Record<
  GatewayControleQuinquennal.ControleQuinquennalAxeType,
  string
> = {
  ASSUJETTI: "Personnes assujetties",
  RCPRO_COA: "RC Pro COA",
  RCPRO_MIA: "RC Pro MIA",
  RCPRO_COBSP: "RC Pro COBSP",
  RCPRO_MIOBSP: "RC Pro MIOBSP",
  GARANTIE_FINANCIERE_IAS: "Garantie financière IAS",
  GARANTIE_FINANCIERE_IOBSP: "Garantie financière IOBSP",
};
export const controleTypeOrder: Record<
  GatewayControleQuinquennal.ControleQuinquennalAxeType,
  number
> = {
  ASSUJETTI: 1,
  RCPRO_COA: 2,
  RCPRO_MIA: 3,
  RCPRO_COBSP: 4,
  RCPRO_MIOBSP: 5,
  GARANTIE_FINANCIERE_IAS: 6,
  GARANTIE_FINANCIERE_IOBSP: 7,
};
