export enum TarifType {
  MULTI = 'MULTI',
  MONO = 'MONO',
}

export type Tarif = {
  id: string;
  max: number;
  montant: number;
};

type TarifMax = {
  id: string;
  valeur: number;
};

export type MappedTarifs = Record<TarifType, Tarif[]> & {
  maxGroupe: Record<TarifType, TarifMax>;
};

export enum PaymentMethod {
  CARD = 'CARD',
  SEPA = 'SEPA',
  TRANSFER = 'TRANSFER',
}

export type PaiementType = {
  invoiceId: string;
  montant: number;
  subtotal: number;
  intentId?: string;
  clientSecret?: string;
};
export type PaymentWithHash = {
  paiement: PaiementType;
  hash: string;
  idsEntreprisesNonAdherentes: EntrepriseIdAndSiren[];
};

export type InvoiceMetadata = {
  accounted?: 'true';
  millesime?: string;
};

export type EntrepriseIdAndSiren = {
  id: string;
  siren: string;
};
