import { ConformiteAssujettiList } from "./ConformiteAssujettiList";

export function ConformiteAssujettiListCapaciteProfessionnelleIobsp() {
  return (
    <ConformiteAssujettiList
      title="Capacité Professionnelle IOBSP"
      avancementType="capaciteProfessionnelleIobsp"
      backLink="/conformite/capacite-professionnelle/iobsp"
    />
  );
}
