import { Parcours } from "@src/parcours/Parcours";
import { useNavigate, useParams } from "react-router-dom";
import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { useEntreprises } from "@src/store/store.entreprise";
import { LoadingPage } from "@src/components/LoadingPage/LoadingPage";
import { StepComponentProps } from "@src/parcours/Parcours.types";
import { getAxesConformiteEntrepriseByActivites } from "@src/societes/societe.helper";
import { useMemo } from "react";
import {
  GatewayConformite,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { invertMap } from "@src/helper/object.helper";
import { ConformiteIntroductionStep } from "./steps/introduction/ConformiteIntroductionStep";
import style from "./ParcoursConformite.module.scss";
import { ConformiteAssujettisStep } from "./steps/assujettis/ConformiteAssujettisStep";
import { ConformiteRcProStep } from "./steps/rcpro/ConformiteRcProStep";
import { ConformiteGarantieFinanciereIasStep } from "./steps/garantie-financiere/ConformiteGarantieFinanciereIasStep";
import { ConformiteGarantieFinanciereIobspStep } from "./steps/garantie-financiere/ConformiteGarantieFinanciereIobspStep";
import { ConformiteDeclarationActiviteIasStep } from "./steps/declaration-activite/ConformiteDeclarationActiviteIasStep";
import { ConformiteDeclarationActiviteIobspStep } from "./steps/declaration-activite/ConformiteDeclarationActiviteIobspStep";
import { ConformiteRecapitulatif } from "./steps/recapitulatif/ConformiteRecapitulatif";
import { UIParcoursConformite } from "./UIParcoursConformite";

enum ParcoursConformiteGenericStepEnum {
  INTRODUCTION = "introduction",
  IMPORT_ASSUJETTIS = "import-assujettis",
  RECAPITULATIF = "recapitulatif",
}

enum ParcoursConformiteFormulairesEntrepriseEnum {
  RC_PRO_COA = "rc-pro-coa",
  RC_PRO_COBSP = "rc-pro-cobsp",
  RC_PRO_MIA = "rc-pro-mia",
  RC_PRO_MIOBSP = "rc-pro-miobsp",
  GARANTIE_FINANCIERE_IAS = "garantie-financiere-ias",
  GARANTIE_FINANCIERE_IOBSP = "garantie-financiere-iobsp",
  DECLARATION_ACTIVITE_IAS = "declaration-activite-ias",
  DECLARATION_ACTIVITE_IOBSP = "declaration-activite-iobsp",
}

export type ParcoursConformiteStepEnum =
  | ParcoursConformiteGenericStepEnum
  | ParcoursConformiteFormulairesEntrepriseEnum;
export const ParcoursConformiteStepEnum = {
  ...ParcoursConformiteGenericStepEnum,
  ...ParcoursConformiteFormulairesEntrepriseEnum,
};

const axeConformiteByParcoursStep: Record<
  ParcoursConformiteFormulairesEntrepriseEnum,
  GatewayConformite.AxeConformite
> = {
  [ParcoursConformiteFormulairesEntrepriseEnum.RC_PRO_COA]:
    GatewayConformite.AxeConformite.RC_PRO_COA,
  [ParcoursConformiteFormulairesEntrepriseEnum.RC_PRO_COBSP]:
    GatewayConformite.AxeConformite.RC_PRO_COBSP,
  [ParcoursConformiteFormulairesEntrepriseEnum.RC_PRO_MIA]:
    GatewayConformite.AxeConformite.RC_PRO_MIA,
  [ParcoursConformiteFormulairesEntrepriseEnum.RC_PRO_MIOBSP]:
    GatewayConformite.AxeConformite.RC_PRO_MIOBSP,
  [ParcoursConformiteFormulairesEntrepriseEnum.GARANTIE_FINANCIERE_IAS]:
    GatewayConformite.AxeConformite.GARANTIE_FINANCIERE_IAS,
  [ParcoursConformiteFormulairesEntrepriseEnum.GARANTIE_FINANCIERE_IOBSP]:
    GatewayConformite.AxeConformite.GARANTIE_FINANCIERE_IOBSP,
  [ParcoursConformiteFormulairesEntrepriseEnum.DECLARATION_ACTIVITE_IAS]:
    GatewayConformite.AxeConformite.DECLARATION_ACTIVITE_IAS,
  [ParcoursConformiteFormulairesEntrepriseEnum.DECLARATION_ACTIVITE_IOBSP]:
    GatewayConformite.AxeConformite.DECLARATION_ACTIVITE_IOBSP,
};

const parcoursStepByAxeConformite = invertMap(axeConformiteByParcoursStep);

export const useParcoursConformite = () => {
  const { siren } = useParams<{ siren: string }>();
  const { entreprisesBySiren } = useEntreprises();

  const entreprise = useMemo(
    () => (siren ? entreprisesBySiren?.[siren] : undefined),
    [siren, entreprisesBySiren]
  );

  const axesEntreprise = entreprise
    ? getAxesConformiteEntrepriseByActivites(entreprise.categoriesActivites)
    : [];

  const getNextStep = (currentStep: ParcoursConformiteStepEnum) => {
    const isStepGeneric = (
      Object.values(ParcoursConformiteGenericStepEnum) as string[]
    ).includes(currentStep);
    if (isStepGeneric && axesEntreprise[0])
      return parcoursStepByAxeConformite[axesEntreprise[0]];
    if (isStepGeneric) return ParcoursConformiteStepEnum.RECAPITULATIF;

    const currAxe =
      axeConformiteByParcoursStep[
        currentStep as ParcoursConformiteFormulairesEntrepriseEnum
      ];
    const indexCurrentAxeEntreprise = axesEntreprise.indexOf(currAxe);

    const nextAxe = axesEntreprise[indexCurrentAxeEntreprise + 1];
    if (nextAxe) return parcoursStepByAxeConformite[nextAxe];
    return ParcoursConformiteStepEnum.RECAPITULATIF;
  };

  return { siren, entreprise, getNextStep, parcoursStepByAxeConformite };
};

type ParcoursConformiteData = undefined;

type ParcoursConformiteStepProperties = {
  siren: string;
};

export type ParcoursConformiteStepComponentProps = StepComponentProps<
  ParcoursConformiteStepEnum,
  ParcoursConformiteData,
  ParcoursConformiteStepProperties
>;

const ConformiteRcProStepByCategorie = (
  categorie: GatewayInfoSocieteOrias.Categorie
) =>
  // eslint-disable-next-line func-names
  function (props: ParcoursConformiteStepComponentProps) {
    return <ConformiteRcProStep categorie={categorie} {...props} />;
  };

export const ID_FORM_CONFORMITE = "form-conformite";

export function ParcoursConformite() {
  useParcoursConformite();
  const navigate = useNavigate();
  const { siren } = useParams<{ siren: string }>();
  const { status, entreprisesBySiren } = useEntreprises();

  if (["loading", "init"].includes(status)) {
    return <LoadingPage className={style.loadingPageWrapper} />;
  }
  if (!siren || !entreprisesBySiren?.[siren]) {
    displayErrorToast("Une erreur inconnue est survenue");
    return <div />;
  }

  return (
    <Parcours<
      ParcoursConformiteStepEnum,
      ParcoursConformiteData,
      ParcoursConformiteStepProperties
    >
      withRouter
      handleQuit={async () => {
        await Promise.resolve(navigate("/"));
      }}
      rootPath={`/societes/${siren}/conformite`}
      UIParcoursComponent={UIParcoursConformite}
      config={{
        [ParcoursConformiteStepEnum.INTRODUCTION]: {
          component: ConformiteIntroductionStep,
        },
        [ParcoursConformiteStepEnum.IMPORT_ASSUJETTIS]: {
          component: ConformiteAssujettisStep,
        },
        [ParcoursConformiteStepEnum.RC_PRO_COA]: {
          component: ConformiteRcProStepByCategorie(
            GatewayInfoSocieteOrias.Categorie.COA
          ),
        },
        [ParcoursConformiteStepEnum.RC_PRO_COBSP]: {
          component: ConformiteRcProStepByCategorie(
            GatewayInfoSocieteOrias.Categorie.COBSP
          ),
        },
        [ParcoursConformiteStepEnum.RC_PRO_MIA]: {
          component: ConformiteRcProStepByCategorie(
            GatewayInfoSocieteOrias.Categorie.MIA
          ),
        },
        [ParcoursConformiteStepEnum.RC_PRO_MIOBSP]: {
          component: ConformiteRcProStepByCategorie(
            GatewayInfoSocieteOrias.Categorie.MIOBSP
          ),
        },
        [ParcoursConformiteStepEnum.GARANTIE_FINANCIERE_IAS]: {
          component: ConformiteGarantieFinanciereIasStep,
        },
        [ParcoursConformiteStepEnum.GARANTIE_FINANCIERE_IOBSP]: {
          component: ConformiteGarantieFinanciereIobspStep,
        },
        [ParcoursConformiteStepEnum.DECLARATION_ACTIVITE_IAS]: {
          component: ConformiteDeclarationActiviteIasStep,
        },
        [ParcoursConformiteStepEnum.DECLARATION_ACTIVITE_IOBSP]: {
          component: ConformiteDeclarationActiviteIobspStep,
        },
        [ParcoursConformiteStepEnum.RECAPITULATIF]: {
          component: ConformiteRecapitulatif,
        },
      }}
      errorHandlers={{
        notFound: () => navigate("/404", { replace: true }),
        notAuthorized: () => navigate("/login", { replace: true }),
      }}
      firstStep={ParcoursConformiteGenericStepEnum.INTRODUCTION}
      initialData={undefined}
      stepProperties={{ siren }}
    />
  );
}
