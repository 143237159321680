import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Form,
  InputText,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  Button,
  FormField,
  FormRow,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import { useCallback } from "react";
import { GatewayPaymentApi } from "@src/api/payment.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import {
  AdhesionStepEnum,
  ID_FORM_ADHESION,
  ParcoursAdhesionStepComponentProps,
} from "@src/adhesion/ParcoursAdhesion.definition";

const schema = zod.object({
  coupon: zod.string().trim(),
});

type CouponType = zod.infer<typeof schema>;

export function AdhesionCouponPage({
  changeStep,
  addData,
  data,
}: ParcoursAdhesionStepComponentProps) {
  const formConfig = useFormConfig<typeof schema, CouponType>({
    schema,
    defaultValues: { coupon: data.coupon },
  });

  const handleSubmit = useCallback(
    async ({ coupon }: CouponType) => {
      try {
        await GatewayPaymentApi.assertThatCouponExist(coupon);
        addData({ coupon });
        changeStep(AdhesionStepEnum.PAIEMENT_ADHESION);
      } catch (e) {
        handleGatewayError({
          onResponse: (error) => {
            displayErrorToast(error.response.data.message);
          },
          onUnhandled: (error) => {
            console.error(error);
            displayErrorToast("Une erreur inconnue est survenue");
          },
        })(e);
      }
    },
    [changeStep, addData]
  );

  return (
    <ParcoursFullPageTemplate
      title="Code de remise"
      actions={
        <Button
          disabled={!formConfig.formState.isValid}
          type="submit"
          form={ID_FORM_ADHESION}
        >
          Valider
        </Button>
      }
    >
      <Form
        id={ID_FORM_ADHESION}
        formConfig={formConfig}
        onSubmit={handleSubmit}
      >
        <FormRow>
          <FormField>
            <InputText<CouponType> name="coupon" label="Code" required />
          </FormField>
        </FormRow>
      </Form>
    </ParcoursFullPageTemplate>
  );
}
