import { GatewayMe } from "@conformite/gateway";
import { useStore } from "./store.hooks";
import { Me } from "./store.definition";

export const useMe = () => {
  const storeValue = useStore();

  const { data, status, setData } = storeValue.me;

  const updateMe = (me: Me) => setData(me);

  const updateEntreprisePrincipale = (
    entreprisePrincipale: Partial<GatewayMe.MeEntreprisePrincipale>
  ) => {
    if (!data) throw new Error("Data is not loaded");

    setData({
      contact: data.contact,
      entreprisePrincipale: {
        ...data.entreprisePrincipale,
        ...entreprisePrincipale,
      },
    });
  };

  const updateContact = (
    contact: Partial<GatewayMe.GetMeResponse["contact"]>
  ) => {
    if (!data) throw new Error("Data is not loaded");

    setData({
      contact: { ...data.contact, ...contact },
      entreprisePrincipale: data?.entreprisePrincipale,
    });
  };

  return {
    me: data,
    status,
    updateMe,
    updateEntreprisePrincipale,
    updateContact,
  };
};
