import {
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import {
  useFormConfig,
  Form,
  InputNumber,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";

const schema = zod.object({
  chiffreAffaire: zod
    .number({
      errorMap: () => ({
        message: "Veuillez renseigner votre chiffre d'affaires",
      }),
    })
    .min(0, { message: "Veuillez renseigner votre chiffre d'affaires" }),
});
export type RenouvellementChiffreAffaireFormValues = zod.infer<typeof schema>;

type RenouvellementChiffreAffaireProps = {
  formId?: string;
  onSubmit: (response: RenouvellementChiffreAffaireFormValues) => void;
  defaultValues: RenouvellementChiffreAffaireFormValues;
};

export function RenouvellementChiffreAffaireForm({
  formId,
  onSubmit,
  defaultValues,
}: RenouvellementChiffreAffaireProps) {
  const formConfig = useFormConfig<
    typeof schema,
    RenouvellementChiffreAffaireFormValues
  >({ schema, defaultValues });

  return (
    <Form formConfig={formConfig} onSubmit={onSubmit} id={formId}>
      <FormRow>
        <FormField>
          <InputNumber<RenouvellementChiffreAffaireFormValues>
            name="chiffreAffaire"
            label="Chiffre d'affaires n-1 net de rétrocession pour les activités concernées en €"
            min={0}
            required
          />
        </FormField>
      </FormRow>
    </Form>
  );
}
