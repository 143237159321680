import { GatewayAssujetti, GatewayCapacitePro } from "@conformite/gateway";
import { Form } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { FieldSet } from "@src/components/FieldSet/FieldSet";
import { useCallback } from "react";
import { GatewayCapaciteProApi } from "@src/api/conformite/capacitePro.api";
import {
  ConformiteFormCapaciteProIASContent,
  useCapaciteProIASForm,
} from "@src/assujettis/AssujettiPaneContent/pages/AssujettiParcoursConformite/Steps/FormsCapacitePro/ConformiteFormCapaciteProIAS/ConformiteFormCapaciteProIASContent";
import { useControleQuinquennalForm } from "./useControleQuinquennalForm";
import { ControleQuinquennalFormTitle } from "./ControleQuinquennalFormTitle";

export const useCapaciteProIas = (
  assujetti: GatewayAssujetti.AssujettiWithAvancement | undefined,
  needed: boolean
) => {
  const call = useCallback(() => {
    if (!assujetti) return Promise.resolve(null);
    return GatewayCapaciteProApi.getIASAdmin(assujetti.id, assujetti.millesime);
  }, [assujetti]);
  const { content, hasLoaded } = useControleQuinquennalForm(
    assujetti,
    needed,
    call
  );
  return {
    capaciteProIas: content,
    hasLoadedCapaciteProIas: hasLoaded,
  };
};
type ControleQuinquennalAssujettiSidePaneContentCapaciteProProps = {
  assujetti: GatewayAssujetti.AssujettiWithAvancement;
  capacitePro: GatewayCapacitePro.CapaciteProfessionnelleIAS | null;
};

export function ControleQuinquennalAssujettiSidePaneContentCapaciteProIas({
  assujetti,
  capacitePro,
}: ControleQuinquennalAssujettiSidePaneContentCapaciteProProps) {
  const props = useCapaciteProIASForm(capacitePro);
  return (
    <>
      <ControleQuinquennalFormTitle
        assujetti={assujetti}
        title="Capacité professionnelle IAS"
        avancement="capaciteProfessionnelleIas"
      />
      <Form formConfig={props.formConfig} onSubmit={() => null}>
        <FieldSet disabled>
          <ConformiteFormCapaciteProIASContent
            {...props}
            data={{ assujetti }}
            hideTitle
          />
        </FieldSet>
      </Form>
    </>
  );
}
