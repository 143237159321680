import { GatewayControleQuinquennal } from "@conformite/gateway";
import {
  Button,
  Card,
  FormField,
  FormGrid,
  FormRow,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ControleQuinquennalApi } from "@src/api/controleQuinquennal.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { ReactComponent as CrossIcon } from "@src/assets/icons/x-lg.svg";
import { Link } from "@src/components/Link/Link";
import { SkeletonContent } from "@src/components/Skeletons/SkeletonContent";
import { useControleQuinquennalAdmin } from "@src/controleQuinquennal/admin/useControleQuinquennalAdmin";
import { axeTypeToLabel } from "@src/controleQuinquennal/controleQuinquennal.helper";
import { keyBy, partition } from "lodash";
import { PropsWithChildren, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ControleQuinquennalTemplate } from "../../ControleQuinquenalTemplate";
import { useControleQuinquennalAdminAxe } from "../useControleQuinquennalAdminAxe";
import {
  ControleQuinquennalRemediationForm,
  RemediationFormValues,
} from "./ControleQuinquennalRemediationForm";
import styles from "./ControleQuinquennalRemediationForm.module.scss";

const FORM_REMEDIATION_ID = "form-remediation-id";

function PageContentWrapper({
  children,
  isLoading,
}: PropsWithChildren & { isLoading: boolean }) {
  return (
    <>
      {isLoading && <SkeletonContent />}
      {!isLoading && children}
    </>
  );
}
export function ControleQuinquennalRemediationPage() {
  const { idAxe, idRemediation } = useParams<{
    idAxe: string;
    idRemediation: string;
  }>();

  const navigate = useNavigate();
  const { controleQuinquennal, entreprise, axes } =
    useControleQuinquennalAdmin();
  const { remediations } = useControleQuinquennalAdminAxe();

  const remediationToUpdate = useMemo(() => {
    if (!idRemediation) return undefined;
    const remediationsById = keyBy(remediations, "id");
    return remediationsById[idRemediation];
  }, [remediations, idRemediation]);

  const axe = axes?.find((axeToFind) => axeToFind.idAxe === idAxe);

  const handleSubmit = useCallback(
    async (values: RemediationFormValues) => {
      if (!controleQuinquennal || !idAxe) {
        displayErrorToast(
          "Une erreur inconnue est survenue lors de la création de la remediation"
        );
        return;
      }

      const [filesToAdd, filesExisting] = partition(
        values.files,
        (file) => file.file
      );
      try {
        if (idRemediation)
          await ControleQuinquennalApi.updateRemediation(
            controleQuinquennal.id,
            idAxe,
            idRemediation,
            {
              description: values.description,
              idFiles: filesExisting.map((file) => file.idFile) as string[],
            },
            filesToAdd.map((file) => file.file) as File[]
          );
        else
          await ControleQuinquennalApi.createRemediation(
            controleQuinquennal.id,
            idAxe,
            {
              description: values.description,
              idFiles: filesExisting.map((file) => file.idFile) as string[],
            },
            filesToAdd.map((file) => file.file) as File[]
          );
        navigate(`/admin/controle-quinquennal/${entreprise?.hubspotId ?? ""}`);
      } catch (err) {
        handleGatewayError({
          onUnhandled: () =>
            displayErrorToast(
              "Une erreur inconnue est survenue lors de la création de la remediation"
            ),
        })(err);
      }
    },
    [controleQuinquennal, entreprise?.hubspotId, idAxe, navigate, idRemediation]
  );

  const backUrl = `/admin/controle-quinquennal/${entreprise?.hubspotId ?? ""}/${
    idAxe ?? ""
  }`;

  return (
    <ControleQuinquennalTemplate
      title={
        axeTypeToLabel[
          axe?.type ??
            GatewayControleQuinquennal.ControleQuinquennalAxeType.ASSUJETTI
        ]
      }
      backUrl={backUrl}
      actions={
        <div className={styles.actions}>
          <Link to={backUrl}>
            <Button type="button" variant="text" icon={<CrossIcon />}>
              Annuler
            </Button>
          </Link>
          <Button type="submit" form={FORM_REMEDIATION_ID}>
            Enregistrer
          </Button>
        </div>
      }
    >
      <Card>
        <FormGrid>
          <FormRow>
            <FormField>
              <h3>Commentaire de remédiation</h3>
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <PageContentWrapper
                isLoading={!!idRemediation && !remediationToUpdate}
              >
                <ControleQuinquennalRemediationForm
                  formId={FORM_REMEDIATION_ID}
                  onSubmit={handleSubmit}
                  defaultValue={remediationToUpdate}
                />
              </PageContentWrapper>
            </FormField>
          </FormRow>
        </FormGrid>
      </Card>
    </ControleQuinquennalTemplate>
  );
}
