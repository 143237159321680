import { PageWithSidebar } from "../PageWithSidebar/PageWithSidebar";
import { MainPageSidebar } from "./MainPageSidebar";

type MainPageTemplateProps = {
  children: React.ReactNode;
};
export function MainPageTemplate({ children }: MainPageTemplateProps) {
  return (
    <PageWithSidebar sidebar={<MainPageSidebar />}>{children}</PageWithSidebar>
  );
}
