import {
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { formatDate, wasLessThanOneYearAgo } from "@src/helper/date.helper";
import styles from "./NextVerificationCard.module.scss";

type NextVerificationCardProps = { lastVerifiedAt?: Date | null };
export function NextVerificatioCard({
  lastVerifiedAt,
}: NextVerificationCardProps) {
  const needVerifiedAt = lastVerifiedAt ? new Date(lastVerifiedAt) : undefined;
  if (needVerifiedAt)
    needVerifiedAt.setFullYear(needVerifiedAt.getFullYear() + 1);

  return (
    <>
      <FormRow>
        <FormField>
          <div className={styles.nextVerificationCard}>
            <span className={styles.nextVerificationCard__label}>
              Prochaine vérification :
            </span>
            <span className={styles.nextVerificationCard__value}>
              {needVerifiedAt !== undefined ? formatDate(needVerifiedAt) : "-"}
            </span>
          </div>
        </FormField>
      </FormRow>
      {lastVerifiedAt && !wasLessThanOneYearAgo(lastVerifiedAt) && (
        <FormRow>
          <FormField>
            <InfoCard type="warning">
              {
                "La dernière vérification date d'il y a plus d'un an. Elle n'est\
                donc pas valable. Merci de vérifier à nouveau"
              }
            </InfoCard>
          </FormField>
        </FormRow>
      )}
    </>
  );
}
