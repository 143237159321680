import { GatewayContact } from "@conformite/gateway";
import { userSession } from "@src/session/UserSession";
import { ContactsWithAssociations } from "@src/store/store.definition";

export class GatewayContactApi {
  public static async retrieveContactsWithAssociations() {
    const { data } = await userSession.axiosInstance.get<
      GatewayContact.ContactWithAssociationsResponseType[]
    >(GatewayContact.contactControllerURL);

    return data;
  }

  public static async delete(contactId: string) {
    await userSession.axiosInstance.delete(
      `${GatewayContact.contactControllerURL}/${contactId}`
    );
  }

  public static async updateContactWithAssociations(
    contactId: string,
    updateContact: GatewayContact.UpdateContactRequestType
  ) {
    const response =
      await userSession.axiosInstance.patch<ContactsWithAssociations>(
        `${GatewayContact.contactControllerURL}/${contactId}`,
        updateContact
      );

    return response.data;
  }
}
