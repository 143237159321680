import { Assujetti } from "@src/store/store.definition";
import {
  FilterPaginationReturn,
  useFilterPagination,
} from "@src/components/Filter/useFilterPagination";
import { cleanProperty } from "@src/helper/clean.helper";
import { useEntreprises } from "@src/store/store.entreprise";
import { useAssujetisAvancement } from "../useAssujettiAvancement";

export const ASSUJETTIS_PAGE_SIZE = 25;

export type AssujettiFilterHookReturn = FilterPaginationReturn<
  Assujetti,
  "search" | "conformite" | "fonction",
  "name" | "conformite"
>;

export const useAssujettiFilter = (
  assujettis: Assujetti[] = []
): AssujettiFilterHookReturn => {
  const { getAssujetisAvancement } = useAssujetisAvancement();
  const { entreprisesBySiren } = useEntreprises();
  const filter = useFilterPagination({
    content: assujettis,
    pagination: {
      pageSize: ASSUJETTIS_PAGE_SIZE,
    },
    filters: [
      {
        name: "search",
        filter: (assujetti, values) => {
          const entrepriseName =
            entreprisesBySiren?.[assujetti.sirenCompany]?.raisonSociale;
          if (values.length === 0) return true;
          const valuesToCheck = [
            entrepriseName,
            assujetti.name,
            assujetti.surname,
          ].map((value) => cleanProperty(value));
          return values.some((search) => {
            const searchCleaned = cleanProperty(search);
            if (!searchCleaned) return true;
            return valuesToCheck.some((property) =>
              property?.includes(searchCleaned)
            );
          });
        },
      },
      {
        name: "conformite",
        filter: (assujetti, values) => {
          if (values.length === 0) return true;
          const conformite = getAssujetisAvancement(assujetti);
          return values.reduce((acc, value) => {
            const isConforme = conformite === 100;
            const conformiteValue = value === "true" ? isConforme : !isConforme;
            return acc || conformiteValue;
          }, false);
        },
      },
      {
        name: "fonction",
        filter: (assujetti, values) => {
          if (values.length === 0) return true;
          return values.includes(assujetti.role);
        },
      },
    ],
    sorts: [
      {
        name: "name",
        sorting: (assujetti1, assujetti2) =>
          `${assujetti1.name}${assujetti1.surname}`.localeCompare(
            `${assujetti2.name}${assujetti2.surname}`
          ),
      },
      {
        name: "conformite",
        sorting: (assujetti1, assujetti2) =>
          getAssujetisAvancement(assujetti1) -
          getAssujetisAvancement(assujetti2),
      },
    ],
    defaultSort: {
      name: "name",
      order: "asc",
    },
  });

  return filter;
};
