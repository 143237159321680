import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { Parcours } from "@src/parcours/Parcours";
import {
  AdhesionDataEntreprisesItem,
  AdhesionDataEntreprisesItemInitialData,
} from "@src/adhesion/AdhesionData/adhesionDataEntreprises";
import { StepComponentProps } from "@src/parcours/Parcours.types";
import { UIParcoursEntreprise } from "./UIParcoursEntreprise";
import { EntrepriseIdentificationEtAdresseStep } from "./steps/EntrepriseIdentificationEtAdresseStep";
import { EntrepriseInfoActiviteStep } from "./steps/EntrepriseInfoActiviteStep";
import { EntrepriseSirenStep } from "./steps/EntrepriseSirenStep";
import { EntrepriseSousCategoriesActiviteStep } from "./steps/EntrepriseSousCategoriesActiviteStep";
import { EntrepriseOrganisationProfessionnelleStep } from "./steps/EntrepriseOrganisationProfessionnelleStep";
import { EntrepriseInformationsComplementairesStep } from "./steps/EntrepriseInformationsComplementairesStep";
import { EntreprisePaiementStep } from "./steps/EntreprisePaiementStep";
import { EntrepriseCouponPage } from "./steps/EntrepriseCouponStep";

export enum ParcoursEntrepriseStepEnum {
  SIREN = "siren",
  IDENTITE_ET_ADRESSE = "identite-et-adresse",
  INFO_ACTIVITE = "info-activite",
  SOUS_CATEGORIES = "sous-categories",
  ORGANISTATION_PROFESSIONNELLE = "organisation-professionnelle",
  INFORMATIONS_COMPLEMENTAIRES = "informations-complementaires",
  PAIEMENT = "paiement",
  CODE_REDUCTION = "code-reduction",
}

type ParcoursEntrepriseProps = Omit<
  ParcoursEntrepriseStepProperties,
  "isEdit"
> & {
  entrepriseToEdit?: AdhesionDataEntreprisesItem;
};

export type ParcoursEntrepriseStepProperties = {
  onSubmit: (data: AdhesionDataEntreprisesItem) => void;
  withPayment?: boolean;
  isEdit: boolean;
  fromParcoursAdhesion?: boolean;
};

export type ParcoursEntrepriseStepComponentProps = StepComponentProps<
  ParcoursEntrepriseStepEnum,
  AdhesionDataEntreprisesItem,
  ParcoursEntrepriseStepProperties
>;

function getInitialData(entrepriseToEdit?: AdhesionDataEntreprisesItem) {
  if (!entrepriseToEdit) return AdhesionDataEntreprisesItemInitialData;
  return entrepriseToEdit;
}

export function ParcoursEntreprise({
  onSubmit,
  withPayment,
  entrepriseToEdit,
  fromParcoursAdhesion,
}: ParcoursEntrepriseProps) {
  return (
    <Parcours<
      ParcoursEntrepriseStepEnum,
      AdhesionDataEntreprisesItem,
      ParcoursEntrepriseStepProperties
    >
      UIParcoursComponent={UIParcoursEntreprise}
      config={{
        [ParcoursEntrepriseStepEnum.SIREN]: {
          component: EntrepriseSirenStep,
        },
        [ParcoursEntrepriseStepEnum.IDENTITE_ET_ADRESSE]: {
          component: EntrepriseIdentificationEtAdresseStep,
        },
        [ParcoursEntrepriseStepEnum.INFO_ACTIVITE]: {
          component: EntrepriseInfoActiviteStep,
        },
        [ParcoursEntrepriseStepEnum.SOUS_CATEGORIES]: {
          component: EntrepriseSousCategoriesActiviteStep,
        },
        [ParcoursEntrepriseStepEnum.ORGANISTATION_PROFESSIONNELLE]: {
          component: EntrepriseOrganisationProfessionnelleStep,
        },
        [ParcoursEntrepriseStepEnum.INFORMATIONS_COMPLEMENTAIRES]: {
          component: EntrepriseInformationsComplementairesStep,
        },
        [ParcoursEntrepriseStepEnum.PAIEMENT]: {
          component: EntreprisePaiementStep,
        },
        [ParcoursEntrepriseStepEnum.CODE_REDUCTION]: {
          component: EntrepriseCouponPage,
        },
      }}
      errorHandlers={{
        notFound: () => displayErrorToast("Impossible d'accéder à cette page"),
        notAuthorized: () =>
          displayErrorToast("Impossible d'accéder à cette page"),
      }}
      firstStep={ParcoursEntrepriseStepEnum.SIREN}
      initialData={getInitialData(entrepriseToEdit)}
      withRouter={false}
      stepProperties={{
        onSubmit,
        withPayment,
        isEdit: entrepriseToEdit !== undefined,
        fromParcoursAdhesion,
      }}
    />
  );
}
