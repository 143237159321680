import { PrismicText } from "@prismicio/react";
import { SliceFactoryProps } from "../../SliceManager";
import styles from "./SliceEnTete.module.scss";

export function SliceEntete({ slice }: SliceFactoryProps<"en_tete">) {
  return (
    <div className={styles.enTete}>
      <h1 className={styles.title}>
        <PrismicText field={slice.primary.titre} />
      </h1>
      <p className={styles.description}>
        <PrismicText field={slice.primary.description} />
      </p>
    </div>
  );
}
