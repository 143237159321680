export enum TypeVoie {
  ABBAYE = 'Abbaye',
  ANCIEN_CHEMIN = 'Ancien chemin',
  AERODROME_AEROPORT = 'Aérodrome, Aéroport',
  AIRE = 'Aire',
  ALLEE = 'Allée',
  ANSE = 'Anse',
  ANCIENNE_RUE = 'Ancienne Rue',
  ARCADE = 'Arcade',
  ANCIENNE_ROUTE = 'Ancienne route',
  AUTOROUTE = 'Autoroute',
  AVENUE = 'Avenue',
  BALCON = 'Balcon',
  BASTION = 'Bastion',
  BASE = 'Base',
  BATIMENT = 'Bâtiment',
  BAS_CHEMIN = 'Bas Chemin',
  BOUCLE = 'Boucle',
  BOULEVARD = 'Boulevard',
  BERGE = 'Berge',
  BOIS = 'Bois',
  BRECHE = 'Brèche',
  BARRIERE = 'Barrière',
  BOURG = 'Bourg',
  BRETTELLE = 'Bretelle',
  BASTIDE = 'Bastide',
  BUTTE = 'Butte',
  CALE = 'Cale',
  CAMP = 'Camp',
  CANAL = 'Canal',
  CARREFOUR = 'Carrefour',
  CARRIERE = 'Carrière',
  CARREAU = 'Carreau',
  CHEMIN_COMMUNAL = 'Chemin communal',
  CENTRE_COMMERCIAL = 'Centre Commercial',
  CHEMIN_DEPARTEMENTAL = 'Chemin départemental',
  CEINTURE = 'Ceinture',
  CAMPAGNE = 'Campagne',
  CHEMIN = 'Chemin',
  CHEMINEMENT = 'Cheminement',
  CHEZ = 'Chez',
  CHARMILLE = 'Charmille',
  CHALET = 'Chalet',
  CHAPELLE = 'Chapelle',
  CHAUSSEE = 'Chaussée',
  CHATEAU = 'Château',
  CITE = 'Cité',
  CLOS = 'Clos',
  COL = 'Col',
  COLLINE = 'Colline',
  CORNICHE = 'Corniche',
  CORON = 'Coron',
  COTE = 'Côte',
  COTEAU = 'Coteau',
  COTTAGE = 'Cottage',
  COUR = 'Cour',
  CAMPING = 'Camping',
  CHEMIN_RURAL = 'Chemin rural',
  COURS = 'Cours',
  CASTEL = 'Castel',
  CONTOUR = 'Contour',
  CENTRE = 'Centre',
  COURSIVE = 'Coursive',
  CHEMIN_VICINAL = 'Chemin Vicinal',
  DEPARTEMENTALE = 'Départementale',
  DIGUE = 'Digue',
  DOMAINE = 'Domaine',
  DESCENTE = 'Descente',
  ECART = 'Écart',
  ECLUSE = 'Écluse',
  EGLISE = 'Église',
  ENCEINTE = 'Enceinte',
  ENCLOS = 'Enclos',
  ENCLAVE = 'Enclave',
  ESCALIER = 'Escalier',
  ESPLANADE = 'Esplanade',
  ESPACE = 'Espace',
  FAUBOURG = 'Faubourg',
  FONTAINE = 'Fontaine',
  FORUM = 'Forum',
  FORT = 'Fort',
  FOSSE = 'Fosse',
  FERME = 'Ferme',
  GALERIE = 'Galerie',
  GARE = 'Gare',
  GRAND_BOULEVARD = 'Grand boulevard',
  GROUPE = 'Groupe',
  GRANDE_PLACE = 'Grande Place',
  GROUPEMENT = 'Groupement',
  GRANDE_RUE = 'Grande rue',
  GRILLE = 'Grille',
  HAMEAU = 'Hameau',
  HAUT_CHEMIN = 'Haut Chemin',
  HIPPODROME = 'Hippodrome',
  HALLE = 'Halle',
  HALAGE = 'Halage',
  HLM = 'HLM',
  ILE = 'Île',
  ILOT = 'Îlot',
  IMMEUBLE = 'Immeuble',
  IMPASSE = 'Impasse',
  JARDIN = 'Jardin',
  JETEE = 'Jetée',
  LIEU_DIT = 'Lieu-dit',
  LEVEE = 'Levée',
  LOTISSEMENT = 'Lotissement',
  MAIL = 'Mail',
  MAISON = 'Maison',
  MARCHE = 'Marché',
  MAS = 'Mas',
  MOULIN = 'Moulin',
  MONTEE = 'Montée',
  MUSEE = 'Musée',
  NOUVELLE_ROUTE = 'Nouvelle Route',
  PETITE_AVENUE = 'Petite Avenue',
  PALAIS = 'Palais',
  PARC = 'Parc',
  PASSAGE = 'Passage',
  PASSE = 'Passe',
  PATIO = 'Patio',
  PAVILLON = 'Pavillon',
  PETIT_CHEMIN = 'Petit Chemin',
  PERIPHERIQUE = 'Périphérique',
  PETITE_IMPASSE = 'Petite Impasse',
  PISTE = 'Piste',
  PARKING = 'Parking',
  PLACE = 'Place',
  PLAN = 'Plan',
  PLACIS = 'Placis',
  PASSERELLE = 'Passerelle',
  PLAINE = 'Plaine',
  PLATEAU = 'Plateau',
  PASSAGE_A_NIVEAU = 'Passage à Niveau',
  POINTE = 'Pointe',
  PONT = 'Pont',
  PORT = 'Port',
  POTERNE = 'Poterne',
  PETITE_RUE = 'Petite Rue',
  PRE = 'Pré',
  PROMENADE = 'Promenade',
  PRESQU_ILE = 'Presqu_île',
  PETITE_ROUTE = 'Petite Route',
  PARVIS = 'Parvis',
  PETITE_ALLEE = 'Petite Allée',
  PORTE = 'Porte',
  PLACETTE = 'Placette',
  QUAI = 'Quai',
  QUARTIER = 'Quartier',
  RUE = 'Rue',
  RACCOURCI = 'Raccourci',
  RAIDILLON = 'Raidillon',
  RONDE = 'Ronde',
  REMPART = 'Rempart',
  RESIDENCE = 'Résidence',
  RUELLE = 'Ruelle',
  ROUTE_NATIONALE = 'Route Nationale',
  RANGE = 'Rangée',
  ROCADE = 'Rocade',
  RAMPE = 'Rampe',
  ROND_POINT = 'Rond Point',
  ROTONDE = 'Rotonde',
  ROUTE = 'Route',
  RUELLETTE = 'Ruellette',
  RUETTE = 'Ruette',
  SENTIER = 'Sentier',
  SENTE = 'Sente',
  SQUARE = 'Square',
  STATION = 'Station',
  STADE = 'Stade',
  TOUR = 'Tour',
  TERRE_PLEIN = 'Terre-Plein',
  TRAVERSE = 'Traverse',
  TERRAIN = 'Terrain',
  TERTRE = 'Tertre',
  TERRASSE = 'Terrasse',
  VALLEE = 'Vallée',
  VOIE_COMMUNALE = 'Voie Communale',
  VIEUX_CHEMIN = 'Vieux Chemin',
  VENELLE = 'Venelle',
  VIA = 'Via',
  VILLA = 'Villa',
  VILLAGE = 'Village',
  VOIE = 'Voie',
  VIEILLE_RUE = 'Vieille Rue',
  VIEILLE_ROUTE = 'Vieille Route',
  ZONE_ARTISANALE = 'Zone Artisanale',
  ZONE_AMENAGEMENT_CONCERTE = 'Zone Aménagement Concerté',
  ZONE_AMENAGEMENT_DIFFERE = 'Zone Aménagement Différé',
  ZONE_INDUSTRIELLE = 'Zone Industrielle',
  ZONE = 'Zone',
  ZONE_A_URBANISER_EN_PRIORITE = 'Zone à Urbaniser en Priorité',
}

export enum Pays {
  ALLEMAGNE = 'ALLEMAGNE',
  AUTRICHE = 'AUTRICHE',
  BELGIQUE = 'BELGIQUE',
  CROATIE = 'CROATIE',
  DANEMARK = 'DANEMARK',
  ESPAGNE = 'ESPAGNE',
  ESTONIE = 'ESTONIE',
  FINLANDE = 'FINLANDE',
  FRANCE = 'FRANCE',
  GRECE = 'GRECE',
  HONGRIE = 'HONGRIE',
  ISLANDE = 'ISLANDE',
  ITALIE = 'ITALIE',
  LETTONIE = 'LETTONIE',
  LIECHTENSTEIN = 'LIECHTENSTEIN',
  LITUANIE = 'LITUANIE',
  LUXEMBOURG = 'LUXEMBOURG',
  MALTE = 'MALTE',
  NORVEGE = 'NORVEGE',
  PAYS_BAS = 'PAYS_BAS',
  POLOGNE = 'POLOGNE',
  PORTUGAL = 'PORTUGAL',
  REPUBLIQUE_TCHEQUE = 'REPUBLIQUE_TCHEQUE',
  SLOVAQUIE = 'SLOVAQUIE',
  SLOVENIE = 'SLOVENIE',
  SUEDE = 'SUEDE',
  SUISSE = 'SUISSE',
}

export type AdresseType = {
  typeVoie: TypeVoie;
  libelleVoie: string;
  numeroVoie?: string;
  complementAdresse?: string;
  codePostal: string;
  ville: string;
  pays: Pays;
};
