function LinkDocumentationHere() {
  return <a href="https://aide.endya.fr/faq/outils-conformite">ici</a>;
}

export function ControleQuinquennalExplainAxeGarantieFinanciereIobsp() {
  return (
    <div>
      <p>
        Si vous percevez, même à titre occasionnel, des fonds de vos clients
        dans le cadre de l&apos;activité d&apos;IOBSP, les documents sollicités
        ci-dessous permettront de vérifier la cohérence de la garantie
        financière souscrite avec les fonds réellement encaissés de clients.
      </p>
      <br />
      <p>Pièces à fournir :</p>
      <ul>
        <li>
          <b>Si vous encaissez des fonds :</b>
        </li>
        <ul>
          <li>
            Attestation de votre expert-comptable ou commissaire aux comptes
            précisant le détail des fonds encaissés au sens des articles L.519-4
            du code monétaire et financier et art.2 Arrêté du 26/06/2012 (cf.
            modèle accessible dans la documentation Endya disponible{" "}
            <LinkDocumentationHere />)
          </li>
          <li>
            Bilan comptable ou tout autre document comptable complémentaire N-1
          </li>
        </ul>
        <li>
          <b>Si vous ne percevez aucun fonds pour le compte de tiers : </b>
        </li>
        <ul>
          <li>
            Attestation de votre expert-comptable ou commissaire aux comptes
            précisant l&apos;absence d&apos;encaissement de fonds au sens des
            articles L.519-4 du code monétaire et financier et art.2 Arrêté du
            26/06/2012 (cf. modèle accessible dans la documentation Endya
            disponible <LinkDocumentationHere />)
          </li>
          <li>
            Ou, si vous n&apos;avez pas d&apos;expert-comptable ou commissaire
            aux comptes, déclaration sur l&apos;honneur attestant de
            l&apos;absence totale d&apos;encaissement de fonds accessible dans
            la documentation Endya disponible <LinkDocumentationHere />.
          </li>
        </ul>
      </ul>
    </div>
  );
}
