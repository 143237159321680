import { ReactComponent as CheckIcon } from "@src/assets/icons/check.svg";
import { ReactComponent as CrossIcon } from "@src/assets/icons/x-lg.svg";
import { ReactComponent as CheckFill } from "@src/assets/icons/check-circle-fill.svg";
import { ReactComponent as XFill } from "@src/assets/icons/x-circle-fill.svg";
import { ReactComponent as PersonIcon } from "@src/assets/icons/person.svg";
import { Table } from "@src/components/Table/Table";
import { GatewayAssujetti } from "@conformite/gateway";
import { ColumnDefinition } from "@src/components/Table/Table.definition";
import classNames from "classnames";
import styles from "./ControleQuinquennalAxeAssujettis.module.scss";
import {
  AssujettiFormType,
  assujettisFormTypeToLabel,
} from "./ControleQuinquennalAxeAssujettis";

type ControleQuinquennalAssujettiTableProps = {
  assujettiWithAvancement: {
    assujetti: GatewayAssujetti.AssujettiWithAvancement;
    avancement: number;
  }[];
  setSelectedAssujetti: (
    assujetti: GatewayAssujetti.AssujettiWithAvancement
  ) => void;
  axes: AssujettiFormType[];
};
export function ControleQuinquennalAssujettiTable({
  assujettiWithAvancement,
  setSelectedAssujetti,
  axes,
}: ControleQuinquennalAssujettiTableProps) {
  type AssujettiWithAvancement = (typeof assujettiWithAvancement)[number];
  const tableHeaders: ColumnDefinition<AssujettiWithAvancement>[] = [
    {
      key: "conforme",
      size: "max-content",
      render: ({ avancement }: AssujettiWithAvancement) => {
        const isConforme = avancement === 100;
        return isConforme ? (
          <CheckFill className={styles.conforme} />
        ) : (
          <XFill className={styles.notConforme} />
        );
      },
    },
    {
      key: "logo",
      size: "max-content",
      render: () => <PersonIcon />,
    },
    {
      key: "nom",
      size: "max-content",
      render: ({ assujetti }: AssujettiWithAvancement) => (
        <p className={styles.name}>
          {assujetti.surname} {assujetti.name}
        </p>
      ),
    },
    {
      key: "space",
      size: "1fr",
      render: () => <div />,
    },
    ...axes.map((assujettiAxe) => ({
      key: assujettiAxe,
      size: "max-content",
      render: ({ assujetti }: AssujettiWithAvancement) => {
        const avancement = assujetti.avancement[assujettiAxe] ?? 0;
        const isConforme = avancement === 100;
        return (
          <div className={styles.conformeContainer}>
            {isConforme && <CheckIcon className={styles.conforme} />}
            {!isConforme && (
              <CrossIcon
                className={classNames(
                  styles.notConforme,
                  styles.notConformeSmall
                )}
              />
            )}
            <p>{assujettisFormTypeToLabel[assujettiAxe]}</p>
          </div>
        );
      },
    })),
  ];
  return (
    <div className={styles.table}>
      <Table
        data={assujettiWithAvancement}
        columns={tableHeaders}
        hideHeader
        flatDesign
        noDarkenColor
        onRowClick={(row) => {
          setSelectedAssujetti(row.assujetti);
        }}
      />
    </div>
  );
}
