import { GatewayAssujetti, GatewayCapacitePro } from "@conformite/gateway";
import { invertMap, recordKeysToUppercase } from "@src/helper/object.helper";

export const assujettiRoleByExcelLabel = {
  "Représentant légal de la structure":
    GatewayAssujetti.RoleAssujetti.REPRESENTANT_LEGAL,
  Associé: GatewayAssujetti.RoleAssujetti.ASSOCIE,
  "Directeur général": GatewayAssujetti.RoleAssujetti.DIRECTEUR_GENERAL,
  "Directeur/responsable Administratif":
    GatewayAssujetti.RoleAssujetti.DIRECTEUR_ADMINISTRATIF,
  "Directeur conformité/juridique":
    GatewayAssujetti.RoleAssujetti.DIRECTEUR_CONFORMITE,
  "Directeur Marketing / Communication":
    GatewayAssujetti.RoleAssujetti.DIRECTEUR_MARKETING,
  "Chargé de clientèle": GatewayAssujetti.RoleAssujetti.CHARGE_CLIENTELE,
  "Souscripteur/juriste": GatewayAssujetti.RoleAssujetti.JURISTE,
  Gestionnaire: GatewayAssujetti.RoleAssujetti.GESTIONNAIRE,
  "Chef de projet": GatewayAssujetti.RoleAssujetti.CHEF_DE_PROJET,
  "Directeur/Responsable commercial":
    GatewayAssujetti.RoleAssujetti.DIRECTEUR_COMMERCIAL,
  "Responsable de bureau": GatewayAssujetti.RoleAssujetti.RESPONSABLE_BUREAU,
  Commerce: GatewayAssujetti.RoleAssujetti.COMMERCE,
  Gestion: GatewayAssujetti.RoleAssujetti.GESTION,
  "Back office": GatewayAssujetti.RoleAssujetti.BACK_OFFICE,
};
export const excelLabelByAssujettiRole = invertMap(assujettiRoleByExcelLabel);

export const niveauIasByExcelLabel = {
  "Niveau I": GatewayCapacitePro.CapaciteProNiveauIAS.NIVEAU_1,
  "Niveau II": GatewayCapacitePro.CapaciteProNiveauIAS.NIVEAU_2,
  "Niveau III": GatewayCapacitePro.CapaciteProNiveauIAS.NIVEAU_3,
};
export const excelLabelByNiveauIas = invertMap(niveauIasByExcelLabel);

export const niveauIobspByExcelLabel = {
  "Niveau I": GatewayCapacitePro.CapaciteProNiveauIOBSP.NIVEAU_1,
  "Niveau II": GatewayCapacitePro.CapaciteProNiveauIOBSP.NIVEAU_2,
};
export const excelLabelByNiveauIobsp = invertMap(niveauIobspByExcelLabel);

export const statutProByExcelLabel = {
  Cadre: GatewayCapacitePro.StatusProfessionel.CADRE,
  "Non Cadre": GatewayCapacitePro.StatusProfessionel.NON_CADRE,
  TNS: GatewayCapacitePro.StatusProfessionel.TNS,
};
export const excelLabelByStatutPro = invertMap(statutProByExcelLabel);

export const categorieIASByExcelLabel = {
  Dirigeant: GatewayCapacitePro.CategorieIAS.DIRIGEANT,
  "Salarié de bureau avec responsable niveau 1":
    GatewayCapacitePro.CategorieIAS.SALARIE_AVEC_RESPONSABLE_NIVEAU_1,
  "Salarié de bureau sans responsable niveau 1":
    GatewayCapacitePro.CategorieIAS.SALARIE_SANS_RESPONSABLE_NIVEAU_1,
  "Salarié debout": GatewayCapacitePro.CategorieIAS.SALARIE_DEBOUT,
  "Salarié en charge d'animer un réseau de production":
    GatewayCapacitePro.CategorieIAS.SALARIE_RESPONSABLE_RESEAU_PRODUCTION,
  "Salarié responsable d'un bureau de production":
    GatewayCapacitePro.CategorieIAS.SALARIE_RESPONSABLE_RESEAU_PRODUCTION,
};
export const excelLabelByCategorieIAS = invertMap(categorieIASByExcelLabel);

export const categorieIOBSPByExcelLabel = {
  "Dirigeant d'un COBSP": GatewayCapacitePro.CategorieIOBSP.DIRIGEANT_COBSP,
  "Salarié d'un COBSP": GatewayCapacitePro.CategorieIOBSP.SALARIE_COBSP,
  "Dirigeant d'un MIOBSP à titre principal (I-IOBSP)":
    GatewayCapacitePro.CategorieIOBSP.DIRIGEANT_MIOBSP_PRINCIPAL,
  "Salarié d'un MIOBSP à titre principal (I-IOBSP)":
    GatewayCapacitePro.CategorieIOBSP.SALARIE_MIOBSP_PRINCIPAL,
  "Dirigeant d'un MIOBSP à titre accessoire (II-IOBSP)":
    GatewayCapacitePro.CategorieIOBSP.DIRIGEANT_MIOBSP_ACCESSOIRE,
  "Salarié d'un MIOBSP à titre accessoire (II-IOBSP)":
    GatewayCapacitePro.CategorieIOBSP.SALARIE_MIOBSP_ACCESSOIRE,
};
export const excelLabelByCategorieIOBSP = invertMap(categorieIOBSPByExcelLabel);

export const gradeDiplomeByExcelLabel = {
  "Niveau 1 / Maîtrise des savoirs de base":
    GatewayCapacitePro.GradeDiplome.NIVEAU_1,
  "Niveau 2 / Aptitude à effecter des activités simples et à résoudre des problèmes courants":
    GatewayCapacitePro.GradeDiplome.NIVEAU_2,
  "Niveau 3 / CAP": GatewayCapacitePro.GradeDiplome.NIVEAU_3,
  "Niveau 4 / BAC": GatewayCapacitePro.GradeDiplome.NIVEAU_4,
  "Niveau 5 / BAC + 2": GatewayCapacitePro.GradeDiplome.NIVEAU_5,
  "Niveau 6 / LICENCE": GatewayCapacitePro.GradeDiplome.NIVEAU_6,
  "Niveau 7 / MASTER | MBA | Executive MBA":
    GatewayCapacitePro.GradeDiplome.NIVEAU_7,
  "Niveau 8 / DOCTORAT | DBA | PhD": GatewayCapacitePro.GradeDiplome.NIVEAU_8,
};
export const excelLabelByGradeDiplome = invertMap(gradeDiplomeByExcelLabel);

export const typeJustificatifIOBSPByExcelLabel = {
  "Licence 313/314/122/128": GatewayCapacitePro.TypeJustificatifIOBSP.LICENCE,
  "BTS 313/314/122/128": GatewayCapacitePro.TypeJustificatifIOBSP.BTS,
  "Ecole de commerce de niveau I":
    GatewayCapacitePro.TypeJustificatifIOBSP.ECOLE_COMMERCE_NIVEAU_1,
  "Diplôme étranger de niveau I":
    GatewayCapacitePro.TypeJustificatifIOBSP.DIPLOME_ETRANGER_NIVEAU_1,
  "1 an au cours des trois dernières années auprès d'un IOBSP de niveau I + Formation complémentaire de 40h":
    GatewayCapacitePro.TypeJustificatifIOBSP.EXP_1_AN_NIVEAU_1__FORMATION_COMP,
  "1 an au cours des trois dernières années  auprès d'un IOBSP de niveau I + Formation complémentaire inférieur à 40h (dérogation accordée aux salariés uniquement)":
    GatewayCapacitePro.TypeJustificatifIOBSP
      .EXP_1_AN_NIVEAU_1__FORMATION_COMP_SALARIE,
  "Niveau II/IOBSP acquis pendant au moins 1 an + 40h de formation complémentaire":
    GatewayCapacitePro.TypeJustificatifIOBSP
      .NIVEAU_2__EXP_1_AN_NIVEAU_2__FORMATION_COMP,
  "Niveau II/IOBSP acquis pendant au moins 1 an + Formation complémentaire inférieur à 40h (dérogation accordée aux salariés uniquement)":
    GatewayCapacitePro.TypeJustificatifIOBSP
      .NIVEAU_2__EXP_1_AN_NIVEAU_2__FORMATION_COMP_SALARIE,
  "1 an au cours des trois dernières années auprès d'un IOBSP de niveau II + Formation complémentaire de 40h":
    GatewayCapacitePro.TypeJustificatifIOBSP.EXP_1_AN_NIVEAU_2__FORMATION_COMP,
  "1 an au cours des trois dernières années auprès d'un IOBSP de niveau II + Formation complémentaire inférieur à 40h (dérogation accordée aux salariés uniquement)":
    GatewayCapacitePro.TypeJustificatifIOBSP
      .EXP_1_AN_NIVEAU_2__FORMATION_COMP_SALARIE,
  "Niveau III/IOBSP acquis pendant au moins 1 an + 40h de formation complémentaire":
    GatewayCapacitePro.TypeJustificatifIOBSP
      .NIVEAU_3__EXP_1_AN_NIVEAU_3__FORMATION_COMP,
  "Niveau III/IOBSP acquis pendant au moins 1 an + Formation complémentaire inférieur à 40h (dérogation accordée aux salariés uniquement)":
    GatewayCapacitePro.TypeJustificatifIOBSP
      .NIVEAU_3__EXP_1_AN_NIVEAU_3__FORMATION_COMP_SALARIE,
  "Expérience aquise à l'étranger + Stage de 3 mois":
    GatewayCapacitePro.TypeJustificatifIOBSP.EXP_ETRANGER__STAGE_3_MOIS,
  "Stage professionnel I-IOBSP de 150h":
    GatewayCapacitePro.TypeJustificatifIOBSP.STAGE_PRO_NIVEAU_1_150H,
  "Stage professionnel II-IOBSP de 80h":
    GatewayCapacitePro.TypeJustificatifIOBSP.STAGE_PRO_NIVEAU_2_80H,
  "Stage professionnel adapté pour les salariés en CI de 100h":
    GatewayCapacitePro.TypeJustificatifIOBSP.STAGE_PRO_100H_CREDIT_IMMOBILIER,
  "Stage professionnel adapté pour les salariés en regroupement de crédit de 74h":
    GatewayCapacitePro.TypeJustificatifIOBSP.STAGE_PRO_74H_REGROUPEMENT_CREDIT,
  "Expérience professionnelle acquise sur le poste pendant 6 mois sous la responsabilité d'un membre du personnel répondant au Niveau I-IOBSP":
    GatewayCapacitePro.TypeJustificatifIOBSP.EXP_PRO_6_MOIS_SALARIE_NIVEAU_1,
  "Expérience professionnelle acquise sur le poste pendant 6 mois sous la responsabilité d'un membre du personnel répondant au Niveau II-IOBSP":
    GatewayCapacitePro.TypeJustificatifIOBSP.EXP_PRO_6_MOIS_SALARIE_NIVEAU_2,
};
export const excelLabelByTypeJustificatifIOBSP = invertMap(
  typeJustificatifIOBSPByExcelLabel
);

export const typeJustificatifIASByExcelLabel = {
  "Grade Master": GatewayCapacitePro.TypeJustificatifIAS.GRADE_MASTER,
  "Ecole de commerce": GatewayCapacitePro.TypeJustificatifIAS.ECOLE_COMMERCE,
  "Diplôme étranger de niveau Master":
    GatewayCapacitePro.TypeJustificatifIAS.DIPLOME_ETRANGER_MASTER,
  "BTS 313": GatewayCapacitePro.TypeJustificatifIAS.BTS_313,
  "Licence 313": GatewayCapacitePro.TypeJustificatifIAS.LICENCE_313,
  "CQP 313": GatewayCapacitePro.TypeJustificatifIAS.CQP_313,
  "Exp pro 1 an cadre":
    GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_1_AN_CADRE,
  "Exp pro 2 ans cadre":
    GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_2_ANS_CADRE,
  "Exp pro 2 ans non cadre":
    GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_2_ANS_NON_CADRE,
  "Exp pro 4 ans non cadre":
    GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_4_ANS_NON_CADRE,
  "Stage pro niv.1": GatewayCapacitePro.TypeJustificatifIAS.STAGE_PRO_NIVEAU_1,
  "Stage pro niv.2": GatewayCapacitePro.TypeJustificatifIAS.STAGE_PRO_NIVEAU_2,
  "Formation niv.3": GatewayCapacitePro.TypeJustificatifIAS.FORMATION_NIVEAU_3,
  "Exp pro 6 mois": GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_6_MOIS,
};
export const excelLabelByTypeJustificatifIAS = invertMap(
  typeJustificatifIASByExcelLabel
);

export const assujettiRoleByExcelLabelUppercase = recordKeysToUppercase(
  assujettiRoleByExcelLabel
);
export const categorieIASByExcelLabelUppercase = recordKeysToUppercase(
  categorieIASByExcelLabel
);
export const niveauIasByExcelLabelUppercase = recordKeysToUppercase(
  niveauIasByExcelLabel
);
export const statutProByExcelLabelUppercase = recordKeysToUppercase(
  statutProByExcelLabel
);
export const categorieIOBSPByExcelLabelUppercase = recordKeysToUppercase(
  categorieIOBSPByExcelLabel
);
export const niveauIobspByExcelLabelUppercase = recordKeysToUppercase(
  niveauIobspByExcelLabel
);
export const gradeDiplomeByExcelLabelUppercase = recordKeysToUppercase(
  gradeDiplomeByExcelLabel
);
export const typeJustificatifIOBSPByExcelLabelUppercase = recordKeysToUppercase(
  typeJustificatifIOBSPByExcelLabel
);
export const typeJustificatifIASByExcelLabelUppercase = recordKeysToUppercase(
  typeJustificatifIASByExcelLabel
);
