import { useCallback, useState } from "react";
import { useStore } from "./store.hooks";

export type StoreEntryRefresh = {
  increaseRefreshCount: () => void;
  refreshCount: number;
};

export function useStoreEntryRefresh(): StoreEntryRefresh {
  const [refreshCount, setRefreshCount] = useState<number>(0);
  const increaseRefreshCount = useCallback(() => {
    setRefreshCount((count) => count + 1);
  }, []);
  return {
    refreshCount,
    increaseRefreshCount,
  };
}

export function useStoreRefresh() {
  const { refresh } = useStore();
  return refresh.increaseRefreshCount;
}
