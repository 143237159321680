import { isNil } from "lodash";

export function toEnum<TRecord extends Record<string, string>>(
  value: string,
  enumType: TRecord
): TRecord[keyof TRecord];
export function toEnum<TRecord extends Record<string, string>>(
  value: string | null | undefined,
  enumType: TRecord
): TRecord[keyof TRecord] | null;
export function toEnum<TRecord extends Record<string, string>>(
  value: string | null | undefined,
  enumType: TRecord
): TRecord[keyof TRecord] | null {
  if (isNil(value)) return null;
  const enumValue = enumType[value as keyof TRecord];
  if (enumValue === undefined) {
    throw new Error(`Invalid enum value ${value}`);
  }
  return enumValue;
}
