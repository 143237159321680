import { GatewayAssujetti, GatewayHonorabilite } from "@conformite/gateway";
import { Form } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { GatewayHonorabiliteApi } from "@src/api/conformite/honorabilite.api";
import {
  ConformiteFormHonorabiliteContent,
  useConformiteFormHonorabiliteForm,
} from "@src/assujettis/AssujettiPaneContent/pages/AssujettiParcoursConformite/Steps/ConformiteFormHonorabilite/ConformiteFormHonorabiliteContent";
import { FieldSet } from "@src/components/FieldSet/FieldSet";
import { useCallback } from "react";
import { useControleQuinquennalForm } from "./useControleQuinquennalForm";
import { ControleQuinquennalFormTitle } from "./ControleQuinquennalFormTitle";

export const useHonorablite = (
  assujetti: GatewayAssujetti.AssujettiWithAvancement | undefined,
  needed: boolean
) => {
  const call = useCallback(() => {
    if (!assujetti) return Promise.resolve(null);
    return GatewayHonorabiliteApi.getAdmin(assujetti.id, assujetti?.millesime);
  }, [assujetti]);
  const { content, hasLoaded } = useControleQuinquennalForm(
    assujetti,
    needed,
    call
  );
  return { honorabilite: content, hasLoadedHonorabilite: hasLoaded };
};
type ControleQuinquennalAssujettiSidePaneContentHonorabiliteProps = {
  assujetti: GatewayAssujetti.AssujettiWithAvancement;
  honorabilite: GatewayHonorabilite.Honorabilite | null;
};

export function ControleQuinquennalAssujettiSidePaneContentHonorabilite({
  assujetti,
  honorabilite,
}: ControleQuinquennalAssujettiSidePaneContentHonorabiliteProps) {
  const { lastVerifiedAt, formConfig } = useConformiteFormHonorabiliteForm(
    honorabilite ?? null
  );
  return (
    <>
      <ControleQuinquennalFormTitle
        assujetti={assujetti}
        title="Honorabilité"
        avancement="honorabilite"
      />
      <Form formConfig={formConfig} onSubmit={() => null}>
        <FieldSet disabled>
          <ConformiteFormHonorabiliteContent
            hideTitle
            data={{ assujetti }}
            lastVerifiedAt={lastVerifiedAt}
          />
        </FieldSet>
      </Form>
    </>
  );
}
