import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { Navigate, useOutletContext } from "react-router-dom";
import { Link } from "@src/components/Link/Link";
import style from "./ForgotPasswordSuccess.module.scss";
import { ForgotPasswordOutletContext } from "./ForgotPassword";

export function ForgotPasswordSuccess() {
  const { data } = useOutletContext<ForgotPasswordOutletContext>();

  if (!data.email) return <Navigate to="/login" />;

  return (
    <div className={style.root}>
      <div className={style.title}>
        <h1>
          Votre mot de passe a été <span>réinitialisé avec succés</span>
        </h1>
      </div>
      <p className={style.description}>
        Vous pouvez désormais vous connecter avec{" "}
        <span>votre nouveau mot de passe.</span>
      </p>
      <div className={style.actions}>
        <Link to="/login">
          <Button variant="filled">Se connecter</Button>
        </Link>
      </div>
    </div>
  );
}
