import { Button, Card } from "@lya-protect/lya-protect-form-library/dist/UI";
import { useNavigate } from "react-router-dom";
import style from "./CardConformite.module.scss";

export function CardConformite() {
  const navigate = useNavigate();
  return (
    <Card className={style.container}>
      <h4>Renseigner ma conformité</h4>
      <Button variant="outline" onClick={() => navigate("/societes")}>
        Accéder
      </Button>
    </Card>
  );
}
