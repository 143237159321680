import { isString } from "lodash";
import { ReactNode } from "react";
import { MainPageTemplate } from "./MainPageTemplate";
import styles from "./MainContentPageTemplate.module.scss";
import { UserMenu } from "../UserMenu/UserMenu";

type MainContentPageTemplateProps = {
  children: ReactNode;
  title: ReactNode;
  contentSelection?: ReactNode;
  actionNavigation?: ReactNode;
};

export function MainContentPageTemplate({
  children,
  title,
  actionNavigation,
  contentSelection,
}: MainContentPageTemplateProps) {
  return (
    <MainPageTemplate>
      <div>
        <div className={styles.header}>
          <div className={styles.headerUserMenu}>
            <UserMenu />
          </div>
          <div className={styles.actionNavigation}>{actionNavigation}</div>
          <div className={styles.headerContent}>
            {isString(title) && <h1>{title}</h1>}
            {!isString(title) && title}
            <div className={styles.contentSelection}>{contentSelection}</div>
          </div>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </MainPageTemplate>
  );
}
