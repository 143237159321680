import {
  GatewayControleQuinquennal,
  GatewayEntreprise,
} from "@conformite/gateway";
import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ControleQuinquennalApi } from "@src/api/controleQuinquennal.api";
import { GatewayEntrepriseApi } from "@src/api/entreprise2.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { useOnce } from "@src/hooks/useOnce";
import { StoreEntryFetchStatus } from "@src/store/store";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const useControleQuinquennalAdmin = () => {
  const { idEntreprise } = useParams<{ idEntreprise: string }>();
  const [status, setStatus] = useState<StoreEntryFetchStatus>("init");
  const [controleQuinquennal, setControleQuinquennal] =
    useState<GatewayControleQuinquennal.ControleQuinquennalWithAxes>();
  const [entreprise, setEntreprise] =
    useState<GatewayEntreprise.EntreprisePrincipaleResponseType>();
  const [axes, setAxes] = useState<
    GatewayControleQuinquennal.ControleQuinquennalAxeWithFiles[]
  >([]);

  const retrieveData = useCallback(async () => {
    if (!idEntreprise) return;
    setStatus("loading");

    try {
      const entrepriseResponse = await GatewayEntrepriseApi.getByIdForAdmin(
        idEntreprise
      );
      const controleQuinquennalResponse =
        await ControleQuinquennalApi.getControleQuinquennalAxeByEntrepriseId(
          idEntreprise,
          entrepriseResponse.millesimeActuel
        );
      setControleQuinquennal(controleQuinquennalResponse);
      setEntreprise(entrepriseResponse);
      setStatus("loaded");
    } catch (err) {
      handleGatewayError({
        onUnhandled: () => {
          displayErrorToast("Une erreur inconnue est survenue");
          throw err;
        },
      });
    }
  }, [idEntreprise]);

  useEffect(() => {
    if (controleQuinquennal) {
      Promise.all(
        controleQuinquennal.controleQuinquennalAxes.map(async (axe) =>
          ControleQuinquennalApi.getControleQuinquennalAxe(
            axe.idControleQuinquennal,
            axe.idAxe
          )
        )
      )
        .then((axesResponse) => {
          setAxes(axesResponse);
        })
        .catch(console.error);
    }
  }, [controleQuinquennal]);

  useOnce(() => {
    retrieveData().catch(console.error);
  });

  return {
    status,
    controleQuinquennal,
    idEntreprise,
    entreprise,
    axes,
    resetControleQuinquennalData: retrieveData,
    addFileToAxe: (
      idAxe: string,
      file: GatewayControleQuinquennal.ControleQuinquennalAxeFile
    ) => {
      setAxes((old) =>
        old.map((axe) => {
          if (axe.idAxe !== idAxe) return axe;
          return {
            ...axe,
            controleQuinquennalAxeFiles: [
              ...axe.controleQuinquennalAxeFiles,
              file,
            ],
          };
        })
      );
    },
  };
};
