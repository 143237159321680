import { useState } from "react";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { GatewayAuthApi } from "@src/api/auth.api";
import style from "./ReSendConfirmationCodeNotice.module.scss";

type ReSendConfirmationCodeNoticeProps = {
  email: string;
};
type ResendCodeStatus = "IDLE" | "PENDING" | "SUCCESS" | "ERROR";

export function ReSendConfirmationCodeNotice({
  email,
}: ReSendConfirmationCodeNoticeProps) {
  const [resendCodeStatus, setResendCodeStatus] =
    useState<ResendCodeStatus>("IDLE");

  const resendConfirmationCode = () => {
    setResendCodeStatus("PENDING");

    GatewayAuthApi.resendCode(email)
      .then(() => {
        setResendCodeStatus("SUCCESS");
      })
      .catch(() => {
        setResendCodeStatus("ERROR");
      });
  };
  return (
    <div className={style.root}>
      <p>
        <b>Nous vous avons envoyé un code par mail. </b>
      </p>
      <div>
        {resendCodeStatus === "SUCCESS" && (
          <>
            <p>
              Si vous ne lavez pas reçu, vérifiez dans vos courriers
              indésirables.
            </p>
            <InfoCard className={style.card} type="success">
              Le code a bien été renvoyé sur l&apos;adresse {email}
            </InfoCard>
          </>
        )}
        {resendCodeStatus !== "SUCCESS" && (
          <>
            <p>
              Si vous ne l&apos;avez pas reçu, vérifiez dans vos courriers
              indésirables, et{" "}
              <button
                className={style.link}
                type="button"
                disabled={resendCodeStatus === "PENDING"}
                onClick={resendConfirmationCode}
              >
                cliquez ici
              </button>{" "}
              pour relancer l&apos;envoi.
            </p>
            {resendCodeStatus === "ERROR" && (
              <InfoCard className={style.card} type="error">
                Une erreur est survenue lors du renvoi du code
              </InfoCard>
            )}
          </>
        )}
      </div>
    </div>
  );
}
