import {
  Button,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { useEffect, useState } from "react";
import { GatewayPaiement } from "@conformite/gateway";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { Header } from "@src/components/Header/Header";
import { GatewayPaymentApi } from "@src/api/payment.api";
import { Link } from "@src/components/Link/Link";
import { PaymentRemerciements } from "@src/societes/form/EntreprisePayment/EntreprisePaymentRemerciements";
import { useStoreRefresh } from "@src/store/store.refresh";
import { retrieveEntrepriseCreationRequest } from "@src/payment/utils/createEntreprises";

type AdhesionRemerciementsProps = {
  isEntreprise?: boolean;
};
export function AdhesionRemerciements({
  isEntreprise,
}: AdhesionRemerciementsProps) {
  const {
    creationEntrepriseParams: [invoiceId],
  } = retrieveEntrepriseCreationRequest();
  const [paymentInfos, setPaymentInfos] =
    useState<GatewayPaiement.InvoiceInfosResponseType>();
  const refresh = useStoreRefresh();

  useEffect(() => {
    if (invoiceId) {
      GatewayPaymentApi.getInvoiceInformations(invoiceId)
        .then(setPaymentInfos)
        .catch((e) => {
          displayErrorToast(
            "Impossible de récupérer les informations de paiement"
          );
          console.error(e);
        })
        .finally(refresh);
    } else {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId]);

  return (
    <div>
      <Header />
      <MaxWidthContent size="medium">
        <PaymentRemerciements
          paymentInfos={paymentInfos}
          informations="Vous pouvez dès maintenant retrouver cette nouvelle société dans votre liste des sociétés à contrôler"
          title="Ajout de société finalisé"
          action={
            <Link to={isEntreprise ? "/societes/" : "/"}>
              <Button>
                {isEntreprise
                  ? "Revenir sur l'application"
                  : "Renseigner ma conformité"}
              </Button>
            </Link>
          }
        />
      </MaxWidthContent>
    </div>
  );
}
