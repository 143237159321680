import { AxiosError, AxiosResponse, isAxiosError } from "axios";
import { GatewayCaptcha } from "@conformite/gateway";
import { CaptchaError, isCaptchaError } from "./captchaAxiosInstance";

export type GatewayError = {
  statusCode: number;
  message: string;
  error?: string;
  cause?: Record<string, unknown>;
};

export type AxiosErrorWithResponse<T> = AxiosError<T> & {
  response: AxiosResponse<T>;
};

type GatewayErrorHandler = {
  onNoResponse?: (error: AxiosError<GatewayError>) => void;
  onResponse?: (error: AxiosErrorWithResponse<GatewayError>) => void;
  onUnknown?: (error: Error) => void;
  onCaptchaError?: (error: CaptchaError) => void;
};

type HandleGatewayErrorConfigWithUnhandled = GatewayErrorHandler & {
  onUnhandled: (error: unknown) => void;
};

type HandleGatewayErrorConfigWithoutUnhandled =
  Required<GatewayErrorHandler> & {
    onUnhandled?: undefined;
  };

type HandleGatewayErrorConfig =
  | HandleGatewayErrorConfigWithUnhandled
  | HandleGatewayErrorConfigWithoutUnhandled;

export function handleGatewayError({
  onResponse,
  onNoResponse,
  onUnknown,
  onCaptchaError,
  onUnhandled,
}: HandleGatewayErrorConfig) {
  return (unknownError: unknown): void => {
    function handleError<
      THandler extends Required<GatewayErrorHandler>[keyof GatewayErrorHandler]
    >(handler: THandler | undefined, error: Parameters<THandler>[0]) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
      if (handler) handler(error as any);
      else if (onUnhandled) onUnhandled(error);
    }

    const error = unknownError as Error | AxiosError<GatewayError>;
    if (isAxiosError(error)) {
      if (error.response) {
        const errorWithResponse = error as AxiosErrorWithResponse<GatewayError>;
        if (
          errorWithResponse.response.data.message ===
          GatewayCaptcha.CaptchaErrorCode.INVALID.message
        ) {
          return handleError(
            onCaptchaError,
            new CaptchaError("CaptchaRefused")
          );
        }
        if (
          errorWithResponse.response.data.message ===
          GatewayCaptcha.CaptchaErrorCode.MISSING.message
        ) {
          return handleError(
            onCaptchaError,
            new CaptchaError("CaptchaMissing")
          );
        }
        return handleError(onResponse, errorWithResponse);
      }
      return handleError(onNoResponse, error);
    }
    if (isCaptchaError(error)) return handleError(onCaptchaError, error);
    return handleError(onUnknown, error);
  };
}
