import {
  Button,
  Card,
  displayWarningToast,
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { useMemo } from "react";
import { FileInputButton } from "@src/components/FileInputButton/FileInputButton";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import { ReactComponent as Download } from "@src/assets/icons/download.svg";
import { ExportEntrepriseButton } from "@src/societes/export/ExportEntrepriseButton";
import { useEntreprises } from "@src/store/store.entreprise";
import {
  ParcoursRenouvellementStepComponentProps,
  RenouvellementStepEnum,
} from "@src/renouvellement/ParcoursRenouvellement.definition";
import { useImportSocietes } from "@src/adhesion/Steps/AdhesionModeImport/useImportSocietes";
import style from "./RenouvellementMajSocietesIntro.module.scss";
import { transformImportSocietesDataToRenouvellementData } from "../list/RenouvellementMajSocietesList";

export function RenouvellementMajSocietesIntro({
  changeStep,
  addData,
  data,
}: ParcoursRenouvellementStepComponentProps) {
  const { entreprisesBySiren } = useEntreprises();
  const { handleImportSocietes } = useImportSocietes(
    ({ societesToAdd, societesInError }) => {
      if (societesInError.length > 0)
        displayWarningToast(
          `${societesInError.length} lignes sont erronées et n'ont pas été
          importées : ligne ${societesInError.map((s) => s.index).join(", ")}`,
          {
            hideAfter: 20000,
          }
        );
      addData({
        entreprisesUpdateCount: data.entreprisesUpdateCount + 1,
        entreprisesAControler:
          transformImportSocietesDataToRenouvellementData(societesToAdd),
      });
      changeStep(RenouvellementStepEnum.LISTE_SOCIETES);
    }
  );

  const entreprisesToDownload = useMemo(
    () => (entreprisesBySiren ? Object.values(entreprisesBySiren) : []),
    [entreprisesBySiren]
  );

  return (
    <ParcoursFullPageTemplate
      size="large"
      title="Mise à jour des sociétés à contrôler"
    >
      <FormRow>
        <FormField>
          <InfoCard type="info">
            N&apos;oubliez pas d&apos;inclure la société qui administre le
            groupe si elle est également soumise au contrôle
          </InfoCard>
        </FormField>
      </FormRow>
      <FormRow>
        <FormField className={style.cards}>
          <Card className={style.card}>
            <h3>Importer en masse la liste à jour des sociétés</h3>
            <p>
              Renseignez toutes les informations des sociétés dans un fichier
              excel à partir de la liste actuelle de vos sociétés.
            </p>
            <ExportEntrepriseButton
              variant="text"
              icon={<Download />}
              className={style.download}
              entreprises={entreprisesToDownload}
            >
              Télécharger la liste des sociétés connues
            </ExportEntrepriseButton>
            <FileInputButton
              onChange={handleImportSocietes}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
              Importer le fichier mis à jour
            </FileInputButton>
          </Card>
          <Card className={style.card}>
            <h3>Ou modifier les sociétés individuellement</h3>
            <p>
              Saisissez directement les modifications dans l&apos;outil, ligne
              par ligne.
            </p>
            <Button
              variant="outline"
              onClick={() => changeStep(RenouvellementStepEnum.LISTE_SOCIETES)}
            >
              Accéder à la liste
            </Button>
          </Card>
        </FormField>
      </FormRow>
    </ParcoursFullPageTemplate>
  );
}
