import { FormApiControleQuinquennal } from 'form-api';

export type ControleQuinquennal =
  FormApiControleQuinquennal.ControleQuinquennal;
export type ControleQuinquennalWithCompletion =
  FormApiControleQuinquennal.ControleQuinquennalWithCompletion;
export type ControleQuinquennalWithAxes =
  FormApiControleQuinquennal.ControleQuinquennalWithAxes;
export type ControleQuinquennalAxeType =
  FormApiControleQuinquennal.ControleQuinquennalAxeType;
export const ControleQuinquennalAxeType =
  FormApiControleQuinquennal.ControleQuinquennalAxeType;
export type ControleQuinquennalStatus =
  FormApiControleQuinquennal.ControleQuinquennalStatus;
export const ControleQuinquennalStatus =
  FormApiControleQuinquennal.ControleQuinquennalStatus;
export type ControleQuinquennalAxeStatus =
  FormApiControleQuinquennal.ControleQuinquennalAxeStatus;
export const ControleQuinquennalAxeStatus =
  FormApiControleQuinquennal.ControleQuinquennalAxeStatus;
export type ControleQuinquennalAxeUpdate =
  FormApiControleQuinquennal.ControleQuinquennalAxeUpdate;
export type ControleQuinquennalAxeWithNumberOfFiles =
  FormApiControleQuinquennal.ControleQuinquennalAxeWithNumberOfFiles;

export type ControleQuinquennalAxe =
  FormApiControleQuinquennal.ControleQuinquennalAxe;
export type ControleQuinquennalAxeWithFiles =
  FormApiControleQuinquennal.ControleQuinquennalAxeWithFiles;
export type ControleQuinquennalAxeFile =
  FormApiControleQuinquennal.ControleQuinquennalAxeFile;

export type ControleQuinquenalRemediationRequestType =
  FormApiControleQuinquennal.ControleQuinquennalRemediationRequestType;
export type ControleQuinquenalRemediationResponseType =
  FormApiControleQuinquennal.ControleQuinquennalRemediationResponseType;
export type ControleQuinquennalRemediationWithFileResponseType =
  FormApiControleQuinquennal.ControleQuinquennalRemediationWithFileResponseType;
export type ControleQuinquennalRapport =
  FormApiControleQuinquennal.ControleQuinquennalRapport;

export interface ForcerDepotAdminSystemParamType {
  entrepriseId: string;
  millesime: number;
}
