import { ImageField, RichTextField } from "@prismicio/client";
import {
  UseCmsDocumentListProps,
  useCmsDocumentList,
} from "../useCmsDocumentList";

export type PrismicActualite = {
  description_preview: RichTextField;
  image_preview: ImageField;
};
export const useActualite = ({
  page,
  pageSize,
}: Omit<UseCmsDocumentListProps, "documentType">) => {
  return useCmsDocumentList<PrismicActualite>({
    documentType: "actualite",
    pageSize,
    page,
  });
};
