import {
  FormField,
  FormGrid,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ParametrePageTemplate } from "../ParametrePageTemplate";
import { UpdatePasswordCard } from "./UpdatePasswordCard";
import { UpdateMailCard } from "./UpdateMailCard";

export function IdentifiantsConnexion() {
  return (
    <ParametrePageTemplate title="Identifiants de connexion">
      <FormGrid>
        <FormRow>
          <FormField>
            <UpdateMailCard />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <UpdatePasswordCard />
          </FormField>
        </FormRow>
      </FormGrid>
    </ParametrePageTemplate>
  );
}
