import { EtatEntiteHubspot } from '../../common/hubspot/hubspot.definition';
import {
  AdresseType,
  TypeVoie,
  Pays,
} from '../../common/entreprise/adresse.definition';
import { AvancementSociete } from '../../conformite/avancement/controller/avancement.definition';
import {
  Categorie,
  CategorieRepresentation,
  OrganisationProfessionnelle,
  ActiviteBancaire,
} from '../../info-societe-orias/service/info-societe-orias.definition';
import {
  EntrepriseStatus,
  TrancheEffectif as TrancheEffectifService,
  TypeAdhesion as TypeAdhesionService,
  TypeAdhesionActive as TypeAdhesionActiveService,
  FormeJuridique as FormeJuridiqueService,
  TypeSouscripteur as TypeSouscripteurService,
} from '../service/entreprise.definition';
export const entrepriseControllerUrl = 'entreprise';

export type EntrepriseStatusResponse = EntrepriseStatus;
export const EntrepriseStatusResponse = EntrepriseStatus;

export const TypeAdhesion = TypeAdhesionService;
export type TypeAdhesion = TypeAdhesionService;

export const TypeAdhesionActive = TypeAdhesionActiveService;
export type TypeAdhesionActive = TypeAdhesionActiveService;

export const TrancheEffectif = TrancheEffectifService;
export type TrancheEffectif = TrancheEffectifService;
export const FormeJuridique = FormeJuridiqueService;
export type FormeJuridique = FormeJuridiqueService;
export const TypeSouscripteur = TypeSouscripteurService;
export type TypeSouscripteur = TypeSouscripteurService;

export type EntrepriseAControllerResponseType = {
  hubspotId: string;
  siren: string;
  raisonSociale: string;
  numeroOrias?: string;
  adresse: AdresseType;
  formeJuridique: FormeJuridique;
  trancheEffectif: TrancheEffectif;
  chiffreAffaire: number;
  categoriesActivites: Categorie[];
  categoriesActivitesAccessoires: Categorie[];
  categorieRepresentation: CategorieRepresentation;
  organisationProfessionnelle: OrganisationProfessionnelle[];
  sousCategorieCOBSP: ActiviteBancaire[];
  sousCategoriesIOBSP: ActiviteBancaire[];
  millesime: number;
  avancement: AvancementSociete;
};

export type EntreprisePrincipaleResponseType = {
  hubspotId: string;
  siren: string;
  raisonSociale: string;
  adresse: AdresseType;
  formeJuridique: FormeJuridique;
  typeAdhesion: TypeAdhesion;
  typeSouscripteur: TypeSouscripteur;
  trancheEffectif: TrancheEffectif;
  millesimeActuel: number;
};

export type AdresseRequestType = {
  typeVoie: TypeVoie;
  libelleVoie: string;
  numeroVoie: string;
  complementAdresse?: string;
  codePostal: string;
  ville: string;
  pays: Pays;
};

export type UpdateEntrepriseAControllerRequestType = {
  hubspotId: string;
  raisonSociale: string;
  numeroOrias?: string;
  adresse: AdresseType;
  formeJuridique: FormeJuridique;
  trancheEffectif: TrancheEffectif;
  chiffreAffaire: number;
  categoriesActivites: Categorie[];
  categoriesActivitesAccessoires: Categorie[];
  categorieRepresentation: CategorieRepresentation;
  organisationProfessionnelle: OrganisationProfessionnelle[];
  sousCategorieCOBSP: ActiviteBancaire[];
  sousCategoriesIOBSP: ActiviteBancaire[];
};

export type UpdateEntreprisePrincipaleRequestType = {
  raisonSociale: string;
  formeJuridique: FormeJuridique;
  trancheEffectif: TrancheEffectif;
  typeSouscripteur: TypeSouscripteur;
  adresse: AdresseType;
};

export type DeleteEntrepriseRequestType = {
  ids: string[];
};

export type EtatEntreprise = EtatEntiteHubspot;
export const EtatEntreprise = EtatEntiteHubspot;
