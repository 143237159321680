import { userSession } from "@src/session/UserSession";

export class GatewayDocumentApi {
  public static async getDocument(siren: string, pathname: string) {
    const { data } = await userSession.axiosInstance.get<ArrayBuffer>(
      `/documents/${siren}?pathname=${encodeURIComponent(pathname)}`,
      {
        responseType: "arraybuffer",
      }
    );

    return data;
  }
}
