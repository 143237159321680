import {
  GatewayConformite,
  GatewayEntreprise,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import {
  categorieToAvancementKey,
  getAxesConformiteByActivites,
} from "@src/societes/societe.helper";
import { useEntrepriseAvancement } from "@src/societes/useEntrepriseAvancement";
import { useMemo } from "react";
import { activitesFromEntreprise } from "@src/helper/entreprise.helper";
import { InvalidesData } from "./InvalidesData";

const useAxesNonValides = (
  entreprise: GatewayEntreprise.EntrepriseAControllerResponseType
) => {
  const { getEntrepriseAvancementForAssujettiAxe } = useEntrepriseAvancement();

  return useMemo<Record<GatewayConformite.AxeConformite, number>>(() => {
    const avancementByAxeConformite: Record<
      GatewayConformite.AxeConformite,
      number
    > = {
      [GatewayConformite.AxeConformite.HONORABILITE]:
        getEntrepriseAvancementForAssujettiAxe(entreprise, "honorabilite"),
      [GatewayConformite.AxeConformite.FORMATION_CONTINUE_IAS]:
        getEntrepriseAvancementForAssujettiAxe(
          entreprise,
          "formationContinueIas"
        ),
      [GatewayConformite.AxeConformite.FORMATION_CONTINUE_IOBSP]:
        getEntrepriseAvancementForAssujettiAxe(
          entreprise,
          "formationContinueIobsp"
        ),
      [GatewayConformite.AxeConformite.CAPACITE_PRO_IAS]:
        getEntrepriseAvancementForAssujettiAxe(
          entreprise,
          "capaciteProfessionnelleIas"
        ),
      [GatewayConformite.AxeConformite.CAPACITE_PRO_IOBSP]:
        getEntrepriseAvancementForAssujettiAxe(
          entreprise,
          "capaciteProfessionnelleIobsp"
        ),
      [GatewayConformite.AxeConformite.RC_PRO_COA]:
        (entreprise.avancement[
          categorieToAvancementKey[GatewayInfoSocieteOrias.Categorie.COA]
        ] as number) ?? 0,
      [GatewayConformite.AxeConformite.RC_PRO_MIA]:
        (entreprise.avancement[
          categorieToAvancementKey[GatewayInfoSocieteOrias.Categorie.MIA]
        ] as number) ?? 0,
      [GatewayConformite.AxeConformite.RC_PRO_COBSP]:
        (entreprise.avancement[
          categorieToAvancementKey[GatewayInfoSocieteOrias.Categorie.COBSP]
        ] as number) ?? 0,
      [GatewayConformite.AxeConformite.RC_PRO_MIOBSP]:
        (entreprise.avancement[
          categorieToAvancementKey[GatewayInfoSocieteOrias.Categorie.MIOBSP]
        ] as number) ?? 0,
      [GatewayConformite.AxeConformite.GARANTIE_FINANCIERE_IAS]:
        entreprise.avancement.garantieFinanciereIas ?? 0,
      [GatewayConformite.AxeConformite.GARANTIE_FINANCIERE_IOBSP]:
        entreprise.avancement.garantieFinanciereIobsp ?? 0,
      [GatewayConformite.AxeConformite.DECLARATION_ACTIVITE_IAS]:
        entreprise.avancement.declarationActiviteIas ?? 0,
      [GatewayConformite.AxeConformite.DECLARATION_ACTIVITE_IOBSP]:
        entreprise.avancement.declarationActiviteIobsp ?? 0,
    };

    const axes = getAxesConformiteByActivites(
      activitesFromEntreprise(entreprise)
    );
    const avancementByAxeEntries = Object.entries(
      avancementByAxeConformite
    ) as [GatewayConformite.AxeConformite, number][];
    const filteredAvancementByAxe = avancementByAxeEntries.filter(([axeName]) =>
      axes.includes(axeName)
    );

    return Object.fromEntries(filteredAvancementByAxe) as Record<
      GatewayConformite.AxeConformite,
      number
    >;
  }, [entreprise, getEntrepriseAvancementForAssujettiAxe]);
};

type AxesNonValidesProps = {
  entreprise: GatewayEntreprise.EntrepriseAControllerResponseType;
};
export function AxesNonValides({ entreprise }: AxesNonValidesProps) {
  const axesNonValides = useAxesNonValides(entreprise);
  return (
    <InvalidesData
      title="Axes non conformes"
      values={Object.values(axesNonValides)}
      getAvancement={(avancement) => avancement}
    />
  );
}
