import { Card } from "@lya-protect/lya-protect-form-library/dist/UI";
import { PrismicText } from "@prismicio/react";
import { ReactComponent as LightBulb } from "@src/assets/icons/lightbulb.svg";
import { PrismicDocument } from "@prismicio/client";
import classNames from "classnames";
import styles from "./LeSaviezVous.module.scss";
import { PrismicLeSaviezVous } from "./useLeSaviezVous";

type LeSaviezVousItemProps = {
  title: JSX.Element | null;
  description: JSX.Element | null;
  small?: boolean;
};
export function LeSaviezVousItem({
  title,
  description,
  small = false,
}: LeSaviezVousItemProps) {
  return (
    <Card
      className={classNames(styles.leSaviezVous, {
        [styles.small]: small,
      })}
    >
      <LightBulb className={styles.icon} />
      <p className={styles.title}>{title}</p>
      <p className={styles.description}>{description}</p>
    </Card>
  );
}
type LeSaviezVousListProps = {
  leSaviezVous: PrismicDocument<PrismicLeSaviezVous, string, string>[];
};
export function LeSaviezVousList({ leSaviezVous }: LeSaviezVousListProps) {
  return (
    <div className={styles.leSaviezVousList}>
      {leSaviezVous?.map((doc) => (
        <LeSaviezVousItem
          key={doc.id}
          title={<PrismicText field={doc.data.title} />}
          description={<PrismicText field={doc.data.description} />}
        />
      ))}
    </div>
  );
}
