import { useMe } from "@src/store/store.me";
import { useMillesime } from "@src/store/store.millesime";
import styles from "./MillesimeSelect.module.scss";

const N_MILLESIMES = 3;

export function MillesimeSelect() {
  const { me } = useMe();
  const { selected, setSelected } = useMillesime();

  if (!me || !selected) return null;

  const thisYear = me?.entreprisePrincipale.millesimeActuel;
  const millesimes = Array.from(
    { length: N_MILLESIMES },
    (_, i) => thisYear + 1 - N_MILLESIMES + i
  );

  return (
    <div className={styles.millesimesSelect}>
      {millesimes.map((opt) => (
        <label key={opt} className={styles.millesimeLabel}>
          <input
            type="radio"
            name="millesime"
            value={opt}
            checked={selected === opt}
            onChange={() => setSelected(opt)}
          />
          <div className={styles.option}>{opt}</div>
        </label>
      ))}
    </div>
  );
}
