import { Button, Card } from "@lya-protect/lya-protect-form-library/dist/UI";
import { PrismicImage, PrismicText } from "@prismicio/react";
import { Link } from "@src/components/Link/Link";
import { ReactComponent as ArrowRight } from "@src/assets/icons/arrow-right.svg";
import classNames from "classnames";
import { PrismicDocument } from "@prismicio/client";
import styles from "./Actualite.module.scss";
import { PrismicActualite } from "./useActualite";

type ActualiteListProps = {
  actualites: PrismicDocument<PrismicActualite, string, string>[];
};
export function ActualiteList({ actualites }: ActualiteListProps) {
  return (
    <div className={styles.actualiteList}>
      {actualites.map((doc, index) => (
        <Card
          key={doc.id}
          className={classNames(styles.actualite, {
            [styles.big]: index === 0 && actualites.length % 2 === 1,
          })}
        >
          <PrismicImage
            field={doc.data.image_preview}
            className={styles.image}
          />
          <div className={styles.content}>
            <p className={styles.title}>
              <PrismicText field={doc.data.description_preview} />
            </p>
            <Link to={`/actualites/${doc.uid ?? ""}`}>
              <Button variant="text" icon={<ArrowRight />}>
                Lire l&apos;article
              </Button>
            </Link>
          </div>
        </Card>
      ))}
    </div>
  );
}
