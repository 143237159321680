import { PropsWithChildren, ReactNode } from "react";
import style from "./PageWithActions.module.scss";

type PageWithActionsProps = {
  actions: ReactNode;
} & PropsWithChildren;
export function PageWithActions({ children, actions }: PageWithActionsProps) {
  return (
    <div className={style.template}>
      <div className={style.scrollablePart}>{children}</div>

      {actions && <div className={style.actions}>{actions}</div>}
    </div>
  );
}
