import {
  Card,
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { categorieActiviteLabel } from "@src/societes/form/EntrepriseInfoActiviteForm";
import { categorieRepresentationRecord } from "@src/societes/form/EntrepriseInformationsComplementairesForm";
import { sousCategorieLabel } from "@src/societes/form/EntrepriseSousCategoriesActiviteForm";
import { organisationProfessionnelleLabel } from "@src/adhesion/Steps/AdhesionOrganisationProfessionnelle/AdhesionOrganisationProfessionnellePage";
import styles from "./ResumeInfoActivite.module.scss";
import { ResumeBlockComponentProps } from "./RenouvellementResumeEntreprisePage";

export function ResumeInfoActivite({ data }: ResumeBlockComponentProps) {
  const entreprise =
    data.entreprisesAControler[data.resume.entreprisePrincipale.siren];

  return (
    <Card variant="accent">
      <FormRow>
        <FormField>
          Numéro ORIAS : <b>{entreprise.numeroOrias ?? "-"}</b>
        </FormField>
        <FormField>
          Statut ORIAS : <b>TBD</b>
        </FormField>
      </FormRow>

      <FormRow>
        <FormField>
          Activités :{" "}
          <b>
            <ul className={styles.list}>
              {entreprise.categoriesActivites.map((c) => (
                <li key={c}>{categorieActiviteLabel[c]}</li>
              ))}
            </ul>
          </b>
        </FormField>
      </FormRow>
      {entreprise.categoriesActivitesAccessoires.length > 0 && (
        <FormRow>
          <FormField>
            Activités accessoires :{" "}
            <b>
              <ul className={styles.list}>
                {entreprise.categoriesActivitesAccessoires.map((c) => (
                  <li key={c}>{categorieActiviteLabel[c]}</li>
                ))}
              </ul>
            </b>
          </FormField>
        </FormRow>
      )}
      {entreprise.sousCategorieCOBSP.length > 0 && (
        <FormRow>
          <FormField>
            Sous-catégorie COBSP:{" "}
            <b>
              <ul className={styles.list}>
                {entreprise.sousCategorieCOBSP.map((c) => (
                  <li key={c}>{sousCategorieLabel[c]}</li>
                ))}
              </ul>
            </b>
          </FormField>
        </FormRow>
      )}

      {entreprise.sousCategoriesIOBSP.length > 0 && (
        <FormRow>
          <FormField>
            Sous-catégorie MIOBSP:{" "}
            <b>
              <ul className={styles.list}>
                {entreprise.sousCategoriesIOBSP.map((c) => (
                  <li key={c}>{sousCategorieLabel[c]}</li>
                ))}
              </ul>
            </b>
          </FormField>
        </FormRow>
      )}

      <FormRow>
        <FormField>
          Organisation professionnelle :{" "}
          <b>
            <ul className={styles.list}>
              {entreprise.organisationProfessionnelle.map((o) => (
                <li key={o}>{organisationProfessionnelleLabel[o]}</li>
              ))}
            </ul>
          </b>
        </FormField>
      </FormRow>

      <FormRow>
        <FormField>
          Catégorie de représentation :{" "}
          <b>
            {categorieRepresentationRecord[entreprise.categorieRepresentation]}
          </b>
        </FormField>
      </FormRow>
    </Card>
  );
}
