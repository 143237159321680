import {
  Form,
  InputSelect,
  InputText,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  FormField,
  FormGrid,
  FormRow,
  FormTitle,
  Toaster,
  displayErrorToast,
  displaySucessToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";

import { GatewayAdresse, GatewayEntreprise } from "@conformite/gateway";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { PageWithSidebar } from "@src/components/PageWithSidebar/PageWithSidebar";
import { useEntreprises } from "@src/store/store.entreprise";
import { GatewayEntrepriseApi } from "@src/api/entreprise2.api";
import {
  entrepriseIdentificationSchema,
  formeJuridiqueOptions,
  trancheEffectifOptions,
} from "@src/societes/form/EntrepriseIdentificationForm";
import {
  entrepriseAdresseSchema,
  paysOptions,
  typeVoieOptions,
} from "@src/societes/form/EntrepriseAdresseForm";
import { SocieteInformationSidebar } from "./SocieteInformationSidebar";
import styles from "./SocieteInformation.module.scss";
import { SocieteInformationActions } from "./SocieteInformationActions";

const schema = entrepriseIdentificationSchema
  .omit({ typeSouscripteur: true })
  .merge(entrepriseAdresseSchema)
  .merge(
    zod.object({
      id: zod.string(),
      // TODO: A remettre quand on aura les organisations professionnelles dans hubspot
      //     organisationsProfessionnelles: zod
      //       .array(zod.nativeEnum(GatewayInfoSocieteOrias.OrganisationProfessionnelle))
      //       .min(1, {
      //         message:
      //           "Veuillez faire un choix dans les organisations professionnelles",
      //       }),
    })
  );

type SocieteInformationFormValues = zod.infer<typeof schema>;
const formValueToEntreprise = (
  values: SocieteInformationFormValues,
  societe: GatewayEntreprise.EntrepriseAControllerResponseType
): GatewayEntreprise.UpdateEntrepriseAControllerRequestType => ({
  hubspotId: values.id,
  raisonSociale: values.raisonSociale,
  trancheEffectif: values.trancheEffectif,
  formeJuridique: values.formeJuridique,
  adresse: {
    typeVoie: values.typeVoie,
    libelleVoie: values.libelleVoie,
    numeroVoie: values.numeroVoie as string,
    complementAdresse: values.complementAdresse,
    codePostal: values.codePostal,
    ville: values.ville,
    pays: values.pays,
  },
  categorieRepresentation: societe.categorieRepresentation,
  categoriesActivites: societe.categoriesActivites,
  categoriesActivitesAccessoires: societe.categoriesActivitesAccessoires,
  chiffreAffaire: societe.chiffreAffaire,
  organisationProfessionnelle: societe.organisationProfessionnelle,
  sousCategorieCOBSP: societe.sousCategorieCOBSP,
  sousCategoriesIOBSP: societe.sousCategoriesIOBSP,
  numeroOrias: societe.numeroOrias,
});

type SocieteInformationProps = {
  societe: GatewayEntreprise.EntrepriseAControllerResponseType;
  setEntreprise: (
    entreprise: GatewayEntreprise.EntrepriseAControllerResponseType
  ) => void;
};
export function SocieteInformation({
  societe,
  setEntreprise,
}: SocieteInformationProps) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const formConfig = useFormConfig<typeof schema, SocieteInformationFormValues>(
    {
      schema,
      defaultValues: {
        id: societe.hubspotId,
        raisonSociale: societe.raisonSociale,
        pays: societe.adresse?.pays,
        ville: societe.adresse?.ville,
        siren: societe.siren,
        typeVoie: societe.adresse?.typeVoie,
        codePostal: societe.adresse?.codePostal,
        numeroVoie: societe.adresse?.numeroVoie,
        libelleVoie: societe.adresse?.libelleVoie,
        formeJuridique: societe.formeJuridique,
        trancheEffectif: societe.trancheEffectif,
        complementAdresse: societe.adresse?.complementAdresse || "",
        // TODO: ajouter organisations professionnelles
        // organisationsProfessionnelles: [],
      },
    }
  );
  const handleSubmit = (values: SocieteInformationFormValues) => {
    setIsLoading(true);
    GatewayEntrepriseApi.updateEntreprise(
      formValueToEntreprise(values, societe)
    )
      .then((updatedEntreprise) => {
        navigate(`/societes/${values.siren}${search}`);
        displaySucessToast("La société a bien été mise à jour");
        setEntreprise(updatedEntreprise);
      })
      .catch(() => {
        displayErrorToast("Une erreur est survenue lors de la mise à jour", {
          canal: "societe-information",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  // TODO: A remettre quand on aura les organisations professionnelles dans hubspot
  // const handleChange = useCallback(
  //   (nextValue: GatewayInfoSocieteOrias.OrganisationProfessionnelle[]) => {
  //     const doesPreviousContainAucun = formConfig
  //       .getValues("organisationsProfessionnelles")
  //       .includes(GatewayInfoSocieteOrias.OrganisationProfessionnelle.AUCUN);
  //     const doesNextContainAucun = nextValue.includes(
  //       GatewayInfoSocieteOrias.OrganisationProfessionnelle.AUCUN
  //     );
  //
  //     const options = { shouldValidate: true };
  //     if (doesNextContainAucun && !doesPreviousContainAucun) {
  //       formConfig.setValue(
  //         "organisationsProfessionnelles",
  //         [GatewayInfoSocieteOrias.OrganisationProfessionnelle.AUCUN],
  //         options
  //       );
  //     } else if (doesPreviousContainAucun) {
  //       formConfig.setValue(
  //         "organisationsProfessionnelles",
  //         nextValue.filter(
  //           (organisation) =>
  //             organisation !==
  //             GatewayInfoSocieteOrias.OrganisationProfessionnelle.AUCUN
  //         ),
  //         options
  //       );
  //     } else {
  //       formConfig.setValue(
  //         "organisationsProfessionnelles",
  //         nextValue,
  //         options
  //       );
  //     }
  //   },
  //   [formConfig]
  // );
  return (
    <FormGrid>
      <Form
        formConfig={formConfig}
        onSubmit={handleSubmit}
        id="societe-information"
      >
        <FormTitle>
          <h1>Informations générales</h1>
        </FormTitle>
        <FormRow>
          <FormField>
            <InputText<SocieteInformationFormValues>
              label="Siren"
              name="siren"
              required
              disabled
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <InputText<SocieteInformationFormValues>
              label="Raison sociale"
              name="raisonSociale"
              required
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <InputSelect<
              SocieteInformationFormValues,
              GatewayEntreprise.TrancheEffectif
            >
              name="trancheEffectif"
              label="Tranche effectif"
              placeholder="Sélectionner"
              options={trancheEffectifOptions}
              required
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <InputSelect<
              SocieteInformationFormValues,
              GatewayEntreprise.FormeJuridique
            >
              name="formeJuridique"
              label="Forme juridique"
              placeholder="Sélectionner"
              options={formeJuridiqueOptions}
              required
            />
          </FormField>
        </FormRow>

        <h2 className={styles.subtitle}>Adresse</h2>
        <FormRow>
          <FormField>
            <InputSelect<SocieteInformationFormValues, GatewayAdresse.TypeVoie>
              isSearchable
              name="typeVoie"
              label="Type de voie"
              placeholder="Sélectionner"
              options={typeVoieOptions}
              required
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <InputText<SocieteInformationFormValues>
              label="Libellé de la voie"
              name="libelleVoie"
              required
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <InputText<SocieteInformationFormValues>
              label="Numéro de rue"
              name="numeroVoie"
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <InputText<SocieteInformationFormValues>
              label="Complément d'adresse"
              name="complementAdresse"
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <InputSelect<SocieteInformationFormValues, GatewayAdresse.Pays>
              name="pays"
              label="Pays"
              placeholder="Sélectionner"
              options={paysOptions}
              required
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField size="quarter">
            <InputText<SocieteInformationFormValues>
              label="Code postal"
              name="codePostal"
              required
            />
          </FormField>
          <FormField size="three-quarter">
            <InputText<SocieteInformationFormValues>
              label="Ville"
              name="ville"
              required
            />
          </FormField>
        </FormRow>
        {/*
        // TODO: A remettre quand on aura les organisations professionnelles dans hubspot
        <h2 className={styles.subtitle}>Organisation professionnelle</h2>
        <h4 className={styles.subtitle}>
          Êtes-vous adhérent d’une ou plusieurs organisations professionnelles ?
        </h4>
        <FormRow>
          <FormField>
            <InputButtonGroup<
              SocieteInformationFormValues,
              true,
              GatewayInfoSocieteOrias.OrganisationProfessionnelle
            >
              label="Êtes-vous adhérent d'une ou plusieurs organisations professionnelles ?"
              name="organisationsProfessionnelles"
              size="large"
              variant="outline-pop"
              options={[
                {
                  value: GatewayInfoSocieteOrias.OrganisationProfessionnelle.APIC,
                  label: "APIC",
                },
                {
                  value: GatewayInfoSocieteOrias.OrganisationProfessionnelle.GCAB,
                  label: "GCAB",
                },
                {
                  value: GatewayInfoSocieteOrias.OrganisationProfessionnelle.PLANETE,
                  label: "Planete CSCA",
                },
                {
                  value: GatewayInfoSocieteOrias.OrganisationProfessionnelle.AUTRE,
                  label: "Autre",
                },
                {
                  value: GatewayInfoSocieteOrias.OrganisationProfessionnelle.AUCUN,
                  label: "Aucune",
                },
              ]}
              required
              isMultiple
              onChange={handleChange}
            />
          </FormField>
        </FormRow>
        */}
        <SocieteInformationActions
          idSociete={societe.siren || ""}
          isLoading={isLoading}
        />
        <Toaster
          canal="societe-information"
          position={{
            vertical: "bottom",
            horizontal: "center",
          }}
          className={styles.toaster}
        />
      </Form>
    </FormGrid>
  );
}

export function SocieteInformationContainer() {
  const { entreprisesBySiren, setEntreprise } = useEntreprises();
  const { id } = useParams<{ id: string }>();
  const societe = id && entreprisesBySiren ? entreprisesBySiren[id] : undefined;

  return (
    <PageWithSidebar sidebar={<SocieteInformationSidebar />}>
      <MaxWidthContent className={styles.page}>
        {societe && (
          <SocieteInformation societe={societe} setEntreprise={setEntreprise} />
        )}
      </MaxWidthContent>
    </PageWithSidebar>
  );
}
