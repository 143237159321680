import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as Trash } from "@src/assets/icons/trash.svg";
import { ReactComponent as Pencil } from "@src/assets/icons/pencil.svg";
import { Table } from "@src/components/Table/Table";
import {
  AdhesionStepEnum,
  ParcoursAdhesionStepComponentProps,
} from "@src/adhesion/ParcoursAdhesion.definition";
import { Pagination } from "@src/components/Pagination/Pagination";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { InputSearch } from "@src/components/InputSearch/InputSearch";
import { Link } from "@src/components/Link/Link";
import { FileInputButton } from "@src/components/FileInputButton/FileInputButton";
import { useDialog } from "@src/components/Dialog/useDialog";
import { TitleWithNumber } from "@src/components/TitleWithNumber/TitleWithNumber";
import { IconStatus } from "@src/components/IconStatus/IconStatus";
import { FilterSearch } from "@src/components/FilterSearch/FilterSearch";
import { Dropdown } from "@src/components/DropDown/Dropdown";
import { toEuro } from "@src/helper/currency.helper";
import { ListTemplate } from "@src/components/ListTemplate/ListTemplate";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import { ParcoursAdhesionNextAction } from "@src/adhesion/AdhesionActions/ParcoursAdhesionNextAction";
import { SidePane } from "@src/components/SidePane/SidePane";
import { ParcoursEntreprise } from "@src/societes/parcours/ParcoursEntreprise";
import { AdhesionDataEntreprisesItem } from "@src/adhesion/AdhesionData/adhesionDataEntreprises";
import { useState } from "react";
import { SaveAndQuitButton } from "@src/parcours/NavigationButtons/SaveAndQuitButton";
import styles from "./AdhesionSocieteList.module.scss";
import {
  isValidSociete,
  useImportSocietes,
} from "../AdhesionModeImport/useImportSocietes";
import {
  useSocieteAdhesionFiltered,
  SOCIETES_PAGE_SIZE,
} from "./useSocieteAdhesionFiltered";

function formatChiffreAffaires(chiffreAffaires?: number): number {
  return chiffreAffaires ? Math.ceil(Number(chiffreAffaires)) : 0;
}
type PaneState = {
  open: boolean;
  entrepriseToEdit?: AdhesionDataEntreprisesItem;
};

export function AdhesionSocieteList({
  data,
  addData,
  changeStep,
  handleQuit,
}: ParcoursAdhesionStepComponentProps) {
  const {
    numberOfSocietes,
    societesPaginated,
    page,
    search,
    setPage,
    setSearch,
  } = useSocieteAdhesionFiltered(data.societesRattachees);
  const { handleImportSocietes } = useImportSocietes(
    ({ societesToAdd, societesInError }) => {
      addData({
        societesRattachees: societesToAdd.map((societe) => societe.societe),
        importInError: societesInError.map((societe) => societe.index),
      });
    }
  );
  const { openConfirmDialog } = useDialog();
  const [paneState, setPaneState] = useState<PaneState>({
    open: false,
  });
  const [unmountPaneAfterDeleteCount, setUnmountPaneAfterDeleteCount] =
    useState(0);

  return (
    <>
      <ParcoursFullPageTemplate
        title={
          <TitleWithNumber
            title="Sociétés à contrôler"
            number={data.societesRattachees.length}
          />
        }
        size="large"
        actions={
          <>
            <SaveAndQuitButton onClick={handleQuit} />
            <ParcoursAdhesionNextAction
              onClick={() => changeStep(AdhesionStepEnum.PAIEMENT_ADHESION)}
            >
              Suivant
            </ParcoursAdhesionNextAction>
          </>
        }
      >
        {data.importInError.length > 0 && (
          <InfoCard type="warning" className={styles.importError}>
            {data.importInError.length} lignes sont erronées et n’ont pas été
            importées : ligne {data.importInError.join(", ")}
          </InfoCard>
        )}
        <ListTemplate
          header={
            <FilterSearch
              search={
                <InputSearch
                  placeholder="Raison sociale"
                  name="search"
                  value={search}
                  onChange={(nextValue) => {
                    setSearch(nextValue.target.value);
                    setPage(0);
                  }}
                />
              }
              action={
                <div className={styles.actions}>
                  <Button
                    variant="outline"
                    onClick={() =>
                      setPaneState({
                        open: true,
                      })
                    }
                  >
                    Ajouter un entreprise
                  </Button>
                  <Dropdown content="Actions" placement="bottom-end">
                    {({ close: closeDropDown }) => (
                      <>
                        <Link to="/modele_societe.xlsx" target="_blank">
                          <Button
                            variant="text"
                            className={styles.download}
                            onClick={() => closeDropDown()}
                          >
                            Télécharger le fichier modèle
                          </Button>
                        </Link>
                        <FileInputButton
                          variant="text"
                          onChange={(e) => {
                            closeDropDown();
                            handleImportSocietes(e);
                          }}
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          onClick={async () => {
                            const shouldContinue = await openConfirmDialog({
                              title:
                                "L’import en masse va écraser les sociétés déjà saisies",
                              message:
                                "La liste des sociétés se résumera aux sociétés listées dans votre fichier. Les données et sociétés déjà saisies mais non présentes dans le fichier seront supprimées.",
                              confirmText: "Confirmer",
                              cancelText: "Annuler",
                            });
                            if (!shouldContinue) closeDropDown();
                            return shouldContinue;
                          }}
                        >
                          Importer la liste des sociétés à jour
                        </FileInputButton>
                      </>
                    )}
                  </Dropdown>
                </div>
              }
            />
          }
          table={
            <Table
              data={societesPaginated}
              columns={[
                {
                  key: "validSociete",
                  size: "auto",
                  render: (entreprise) => (
                    <IconStatus isValid={isValidSociete(entreprise)} />
                  ),
                },
                {
                  key: "raisonSociale",
                  title: "Raison sociale",
                  size: 2,
                  render: (entreprise) => <p>{entreprise.raisonSociale}</p>,
                },
                {
                  key: "siren",
                  title: "SIREN",
                  size: 1,
                  render: (entreprise) => <p>{entreprise.siren}</p>,
                },
                {
                  key: "numeroOrias",
                  title: "N° Orias",
                  size: 1,
                  render: (entreprise) => <p>{entreprise.numeroOrias}</p>,
                },
                {
                  key: "chiffreAffaire",
                  title: "Chiffre d'affaires",
                  size: 1,
                  render: (entreprise) => (
                    <p>
                      {toEuro(
                        formatChiffreAffaires(entreprise.chiffreAffaire),
                        {
                          notation: "compact",
                        }
                      )}
                    </p>
                  ),
                },
                {
                  key: "actions",
                  size: "auto",
                  render: (entreprise) => (
                    <div className={styles.actions}>
                      <Button
                        className={styles.editButton}
                        icon={<Pencil />}
                        onClick={() => {
                          setPaneState({
                            open: true,
                            entrepriseToEdit: entreprise,
                          });
                        }}
                        variant="text"
                      />
                      <Button
                        className={styles.deleteButton}
                        icon={<Trash />}
                        variant="text"
                        onClick={() => {
                          openConfirmDialog({
                            title: "Supprimer l'entreprise",
                            message:
                              "Êtes-vous sur(e) de vouloir supprimer cette société ?",
                            confirmText: "Confirmer",
                            cancelText: "Annuler",
                          })
                            .then((shouldContinue) => {
                              if (shouldContinue) {
                                addData({
                                  societesRattachees:
                                    data.societesRattachees.filter(
                                      (societe) =>
                                        societe.siren !== entreprise.siren
                                    ),
                                });
                                if (
                                  paneState.entrepriseToEdit?.siren ===
                                  entreprise.siren
                                )
                                  setUnmountPaneAfterDeleteCount(
                                    unmountPaneAfterDeleteCount + 1
                                  );
                                setPaneState({
                                  open: false,
                                });
                              }
                            })
                            .catch(console.error);
                        }}
                      />
                    </div>
                  ),
                },
              ]}
            />
          }
          footer={
            <Pagination
              page={page}
              pageSize={SOCIETES_PAGE_SIZE}
              total={numberOfSocietes}
              onChange={setPage}
            />
          }
        />
      </ParcoursFullPageTemplate>
      <SidePane
        key={unmountPaneAfterDeleteCount}
        isOpen={paneState.open}
        setIsOpen={(nextIsOpen) => {
          setPaneState((prevState) => ({ ...prevState, open: nextIsOpen }));
        }}
      >
        {(closePane) => (
          <ParcoursEntreprise
            fromParcoursAdhesion
            key={`${JSON.stringify(paneState.entrepriseToEdit)}${
              data.societesRattachees.length
            }`}
            entrepriseToEdit={paneState.entrepriseToEdit}
            withPayment={false}
            onSubmit={(adhesion: AdhesionDataEntreprisesItem) => {
              const existingSocieteIndex = data.societesRattachees.findIndex(
                (societe) => societe.siren === adhesion.siren
              );
              const societesWithoutExistingSociete =
                data.societesRattachees.filter(
                  (_, index) => index !== existingSocieteIndex
                );
              if (existingSocieteIndex !== -1) {
                societesWithoutExistingSociete.splice(
                  existingSocieteIndex,
                  0,
                  adhesion
                );
              } else {
                societesWithoutExistingSociete.push(adhesion);
              }
              addData({
                societesRattachees: societesWithoutExistingSociete,
              });
              setPaneState({ open: false });
              closePane(true);
            }}
          />
        )}
      </SidePane>
    </>
  );
}
