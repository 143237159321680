import { useState } from "react";

export function useInnerNavigation<TPage extends string>(initialPage: TPage) {
  const [history, setHistory] = useState<TPage[]>([initialPage]);
  const [activePage, setActivePage] = useState<TPage>(initialPage);

  const goBack = () => {
    const nextHistory = history.slice(0, history.length - 1);
    setActivePage(nextHistory[nextHistory.length - 1]);
    setHistory(nextHistory);
  };

  const navigate = (nextPage: TPage, resetHistoryWith?: TPage[]) => {
    if (resetHistoryWith) setHistory([...resetHistoryWith]);
    else setHistory([...history, nextPage]);
    setActivePage(nextPage);
  };

  return {
    activePage,
    goBack: history.length > 1 ? goBack : null,
    navigate,
  };
}
