import { GatewayInfoSocietePappers } from "@conformite/gateway";
import { captchaAxiosInstance } from "./utils/captchaAxiosInstance";

export class GatewayInfoSocietePappersApi {
  public static async getBySiren(
    siren: string
  ): Promise<GatewayInfoSocietePappers.GetInfoSocietePappersResponse> {
    const res =
      await captchaAxiosInstance.get<GatewayInfoSocietePappers.GetInfoSocietePappersResponse>(
        `${GatewayInfoSocietePappers.infoSocietePappersUrl}/${siren}`
      );

    return res.data;
  }
}
