import { Button, Card } from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayGarantieFinanciere } from "@conformite/gateway";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { TagConformite } from "@src/components/Tag/TagConformite";
import styles from "../ConformiteGarantieFinanciere.module.scss";
import {
  ConformiteGarantieFinanciereEditFormIobsp,
  useConformiteGarantieFinanciereIobspEdit,
} from "../edit/ConformiteGarantieFinanciereIobspEditForm";

type ConformiteGarantieFinanciereReadIobspProps = {
  garantieFinanciere: GatewayGarantieFinanciere.ConformiteGarantieFinanciereIobsp | null;
  isConforme: boolean;
  setToEdit: () => void;
  millesime: number;
  siren: string;
};

export function ConformiteGarantieFinanciereReadIobsp({
  garantieFinanciere,
  isConforme,
  setToEdit,
  millesime,
  siren,
}: ConformiteGarantieFinanciereReadIobspProps) {
  const { form, onSubmit } = useConformiteGarantieFinanciereIobspEdit(
    siren,
    millesime,
    garantieFinanciere,
    () => null
  );
  return (
    <MaxWidthContent size="large">
      <Card className={styles.content}>
        <div className={styles.cardHeader}>
          <div className={styles.cardHeaderLeft}>
            <h3>Formulaire</h3>
            <TagConformite isConforme={isConforme} year={millesime} />
          </div>
          <Button onClick={setToEdit}>Modifier</Button>
        </div>
        <ConformiteGarantieFinanciereEditFormIobsp
          siren={siren}
          form={form}
          onSubmit={onSubmit}
          garantieFinanciere={garantieFinanciere}
          millesime={millesime}
          disabled
        />
      </Card>
    </MaxWidthContent>
  );
}
