import { PrismicRichText } from "@prismicio/react";
import { SliceFactoryProps } from "../../SliceManager";
import styles from "../TextRich.module.scss";

export function SliceContenu({ slice }: SliceFactoryProps<"contenu">) {
  return (
    <div className={styles.textRich}>
      <PrismicRichText field={slice.primary.contenu} />
    </div>
  );
}
