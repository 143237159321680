import { UseFormReturn } from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import { FileRead } from "@src/components/FileRead/FileRead";
import { FileInputButton } from "@src/components/FileInputButton/FileInputButton";
import styles from "../ConformiteGarantieFinanciere.module.scss";

export type FormWithFileNonDiffusable = UseFormReturn<{
  nonDiffusable: {
    fileNonDiffusable: {
      isValid: boolean;
      pathname?: string;
      filename?: string;
      file?: File;
    } | null;
  };
}>;
type ConformiteGarantieFinanciereEditFilesNonDiffusableProps = {
  form: FormWithFileNonDiffusable;
  disabled?: boolean;
  siren: string;
  millesime: number;
};
export function ConformiteGarantieFinanciereEditFilesNonDiffusable({
  form,
  disabled,
  siren,
  millesime,
}: ConformiteGarantieFinanciereEditFilesNonDiffusableProps) {
  const {
    nonDiffusable: { fileNonDiffusable: fileWatched },
  } = form.watch();
  const fileLabel = "Ajouter l’attestation de l'assureur";
  const shouldShowAddFile = !disabled && !fileWatched?.filename;

  return (
    <div className={styles.filesContainer}>
      {!shouldShowAddFile && (
        <div className={styles.fileContainer}>
          <FileRead
            name={fileWatched?.filename}
            deletable={!disabled}
            onDelete={() => {
              form.setValue("nonDiffusable.fileNonDiffusable", null);
            }}
            siren={siren}
            millesime={millesime}
            filename={fileWatched?.filename}
            pathname={fileWatched?.pathname}
          />
        </div>
      )}
      {shouldShowAddFile && (
        <FileInputButton
          accept="application/pdf"
          variant="input"
          onChange={(e) => {
            const file = e.target.files?.item(0);
            if (!file) return;
            form.setValue("nonDiffusable.fileNonDiffusable", {
              filename: file.name,
              file,
              isValid: false,
            });
          }}
        >
          {fileLabel}
        </FileInputButton>
      )}
    </div>
  );
}
