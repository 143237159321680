import {
  createContext,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from "react";
import { useSession } from "@src/session/UserSessionContext";
import {
  AssujettiByMillessime,
  ContactsWithAssociations,
  ControleQuinquennalStore,
  EntreprisesByMillesime,
  Me,
} from "./store.definition";
import { StoreEntryMillesime, useStoreEntryMillesime } from "./store.millesime";
import { StoreEntryRefresh, useStoreEntryRefresh } from "./store.refresh";

export type StoreEntryFetchStatus = "init" | "loading" | "loaded";

export type StoreEntry<TData> = {
  data?: TData;
  setData: Dispatch<SetStateAction<TData | undefined>>;
  status: StoreEntryFetchStatus;
  setStatus: (nextStatus: StoreEntryFetchStatus) => void;
  reset: () => void;
};

function useStoreEntryState<TData>(initialData?: TData): StoreEntry<TData> {
  const [data, setData] = useState<TData | undefined>();
  const [status, setStatus] = useState<StoreEntryFetchStatus>("init");

  function reset() {
    setStatus("init");
    setData(initialData);
  }

  return {
    data,
    setData,
    status,
    setStatus,
    reset,
  };
}

export type StoreContextValue = {
  refresh: StoreEntryRefresh;
  millesime: StoreEntryMillesime;
  entreprises: StoreEntry<EntreprisesByMillesime>;
  assujetti: StoreEntry<AssujettiByMillessime>;
  me: StoreEntry<Me>;
  contactsWithAssociations: StoreEntry<ContactsWithAssociations[]>;
  controleQuinquennal: StoreEntry<ControleQuinquennalStore>;
};
export const StoreContext = createContext<StoreContextValue>(
  {} as StoreContextValue
);

type StoreProviderProps = {
  children: React.ReactNode;
};

function StoreProviderEmptiedOnLogout({ children }: StoreProviderProps) {
  const entreprises = useStoreEntryState<EntreprisesByMillesime>();
  const assujetti = useStoreEntryState<AssujettiByMillessime>();
  const millesime = useStoreEntryMillesime();
  const me = useStoreEntryState<Me>();
  const contactsWithAssociations =
    useStoreEntryState<ContactsWithAssociations[]>();
  const controleQuinquennal = useStoreEntryState<ControleQuinquennalStore>();
  const refresh = useStoreEntryRefresh();

  const value = useMemo(() => {
    return {
      entreprises,
      assujetti,
      millesime,
      me,
      contactsWithAssociations,
      refresh,
      controleQuinquennal,
    };
  }, [
    entreprises,
    assujetti,
    millesime,
    refresh,
    me,
    contactsWithAssociations,
    controleQuinquennal,
  ]);

  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
}

export function StoreProvider({ children }: StoreProviderProps) {
  const session = useSession();

  return (
    <StoreProviderEmptiedOnLogout key={session.isConnected ? 0 : 1}>
      {children}
    </StoreProviderEmptiedOnLogout>
  );
}
