import { useAssujettiFilter } from "@src/assujettis/AssujettiMainList/useAssujettiFilter";
import { AssujettiList } from "@src/components/Assujetti/AssujettiList";
import { MainContentPageTemplate } from "@src/components/MainPageTemplate/MainContentPageTemplate";
import { useAssujetti } from "@src/store/store.assujetti";
import { useEntreprises } from "@src/store/store.entreprise";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Assujetti } from "@src/store/store.definition";
import { Link } from "@src/components/Link/Link";
import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as ChevronLeft } from "@src/assets/icons/chevron-left.svg";
import { TagConformite } from "@src/components/Tag/TagConformite";
import { useMillesime } from "@src/store/store.millesime";
import { useCallback } from "react";
import { GatewayAssujetti, GatewayEntreprise } from "@conformite/gateway";
import { useHasForbiddenTypeAdhesion } from "@src/session/useIsAllowed";
import styles from "./ConformiteAssujettiList.module.scss";

type ConformiteAssujettiListProps = {
  title: string;
  backLink: string;
  avancementType: keyof GatewayAssujetti.Avancement;
};
export function ConformiteAssujettiList({
  title,
  backLink,
  avancementType,
}: ConformiteAssujettiListProps) {
  const hasForbiddenTypeAdhesion = useHasForbiddenTypeAdhesion();
  const { siren } = useParams();
  const { entreprisesBySiren } = useEntreprises();
  const { assujettis, removeEntries } = useAssujetti();
  const [query] = useSearchParams();
  const fromAxe = query.get("fromAxe");
  const assujettiArray = assujettis ? Object.values(assujettis) : [];
  const entreprise = entreprisesBySiren?.[siren || ""];
  const props = useAssujettiFilter(
    assujettiArray.filter((assujetti) => assujetti.sirenCompany === siren)
  );
  const { selected: millesime } = useMillesime();
  const navigate = useNavigate();
  const AssujettiConformite = useCallback(
    ({ assujetti }: { assujetti: Assujetti }) => {
      if (!millesime) return <div />;
      return (
        <TagConformite
          year={millesime}
          isConforme={assujetti.avancement[avancementType] === 100}
        />
      );
    },
    [avancementType, millesime]
  );

  if (!entreprise) {
    return null;
  }
  return (
    <MainContentPageTemplate
      title={
        <div className={styles.title}>
          <h1>{title}</h1> <p>{entreprise.raisonSociale}</p>
        </div>
      }
      actionNavigation={
        hasForbiddenTypeAdhesion([
          GatewayEntreprise.TypeAdhesion.SOCIETE_INDIVIDUELLE,
        ]) ? null : (
          <Link to={fromAxe === null ? `/societes/${siren || ""}` : backLink}>
            <Button variant="text" icon={<ChevronLeft />}>
              Retour
            </Button>
          </Link>
        )
      }
    >
      <AssujettiList
        {...props}
        AssujetiConformite={AssujettiConformite}
        removeEntries={removeEntries}
        onRowClick={(assujetti) => {
          navigate(assujetti.id + (fromAxe === null ? "" : "?fromAxe"));
        }}
      />
    </MainContentPageTemplate>
  );
}
