import {
  Button,
  Card,
  FormField,
  FormRow,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { useEffect, useMemo } from "react";
import {
  GatewayAdhesion,
  GatewayEntreprise,
  GatewayPaiement,
} from "@conformite/gateway";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  InputButtonGroup,
  InputCheckbox,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { toEuro } from "@src/helper/currency.helper";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { CreationEntrepriseParams } from "@src/api/adhesion2.api";
import { useMe } from "@src/store/store.me";
import { getPaiementMethodOptions } from "@src/adhesion/Steps/AdhesionPaiement/AdhesionPaiementPage";
import { SkeletonContent } from "@src/components/Skeletons/SkeletonContent";
import { ReactComponent as ArrowRight } from "@src/assets/icons/arrow-right.svg";
import style from "./EntreprisePaymentForm.module.scss";
import { EntreprisePaymentStripe } from "./EntreprisePayment/EntreprisePaymentStripe";
import { ParcoursEntrepriseStepEnum } from "../parcours/ParcoursEntreprise";

type EntreprisePaymentFormProps = {
  createEntrepriseParams?: CreationEntrepriseParams;
  isGroup: boolean;
  intention?: GatewayAdhesion.CreateIntentionPaimentResponseType;
  onModePrelevementChange: (
    modePrelevement?: GatewayPaiement.PaymentMethod
  ) => void;
  formId: string;
  setPaiementIsSubmitting?: (isSubmitting: boolean) => void;
  changeStep: (step: ParcoursEntrepriseStepEnum) => void;
};

export const usePaymentButtonLabel = (
  modePrelevement?: GatewayPaiement.PaymentMethod
) => {
  return useMemo<string>(() => {
    switch (modePrelevement) {
      case GatewayPaiement.PaymentMethod.CARD:
        return "Valider le paiement CB";
      case GatewayPaiement.PaymentMethod.SEPA:
        return "Valider le débit direct SEPA";
      default:
        return "Suivant";
    }
  }, [modePrelevement]);
};

export function EntreprisePaymentForm({
  isGroup,
  intention,
  onModePrelevementChange,
  formId,
  createEntrepriseParams,
  setPaiementIsSubmitting,
  changeStep,
}: EntreprisePaymentFormProps) {
  const { me } = useMe();
  const schema = useMemo(
    () =>
      zod.object({
        sengageEnRepresentant: zod
          .boolean()
          .optional()
          .refine((val) => {
            return !isGroup || val === true;
          }, "Vous devez cocher cette case pour continuer"),
        modePrelevement: zod.nativeEnum(GatewayPaiement.PaymentMethod),
      }),
    [isGroup]
  );

  const formConfig = useFormConfig<typeof schema, AdhesionPaiementFormValues>({
    schema,
    defaultValues: { modePrelevement: GatewayPaiement.PaymentMethod.CARD },
  });

  type AdhesionPaiementFormValues = zod.infer<typeof schema>;

  const modePrelevementSelectionne = formConfig.watch("modePrelevement");

  useEffect(() => {
    onModePrelevementChange(modePrelevementSelectionne);
  }, [onModePrelevementChange, modePrelevementSelectionne]);

  if (!me || !intention?.paiement.clientSecret || !createEntrepriseParams)
    return <SkeletonContent />;

  return (
    <>
      <FormRow>
        <FormField>
          <Card
            variant="accent"
            className={style.adhesionPaiementPage__cardPricing}
          >
            <h4>
              <b>
                {`${
                  isGroup
                    ? "Le montant total des adhésions"
                    : "Le montant de votre adhésion"
                } pour l'année ${
                  me?.entreprisePrincipale.millesimeActuel || ""
                } s'élève à `}
                <span className={style.adhesionPaiementPage__labelPricing}>
                  {intention.paiement.montant
                    ? toEuro(intention.paiement.montant, {
                        hideUselessFractionDigits: true,
                      })
                    : "-"}
                </span>
                {intention.paiement.subtotal !== intention.paiement.montant && (
                  <span
                    className={
                      style.adhesionPaiementPage__labelPricingWithoutCoupon
                    }
                  >
                    {intention.paiement.subtotal
                      ? toEuro(intention.paiement.subtotal, {
                          hideUselessFractionDigits: true,
                        })
                      : "-"}
                  </span>
                )}
              </b>
            </h4>
            <Button
              icon={<ArrowRight />}
              variant="text"
              onClick={() =>
                changeStep(ParcoursEntrepriseStepEnum.CODE_REDUCTION)
              }
            >
              Renseigner un code
            </Button>
          </Card>
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <EntreprisePaymentStripe<AdhesionPaiementFormValues>
            setPaiementIsSubmitting={setPaiementIsSubmitting}
            typeAdhesion={
              me.entreprisePrincipale
                .typeAdhesion as GatewayEntreprise.TypeAdhesionActive
            }
            createEntrepriseParams={createEntrepriseParams}
            formId={formId}
            formConfig={formConfig}
            clientSecret={intention?.paiement.clientSecret}
            modePrelevement={modePrelevementSelectionne}
          >
            {({ updateModePrelevement }) => (
              <>
                {isGroup && (
                  <FormRow>
                    <FormField>
                      <InputCheckbox<AdhesionPaiementFormValues>
                        name="sengageEnRepresentant"
                        label="Je m'engage en tant que représentant des sociétés de ce groupe."
                      />
                    </FormField>
                  </FormRow>
                )}

                <FormRow>
                  <FormField>
                    <InputButtonGroup<
                      AdhesionPaiementFormValues,
                      false,
                      GatewayPaiement.PaymentMethod
                    >
                      name="modePrelevement"
                      variant="outline-pop"
                      options={getPaiementMethodOptions(
                        intention.paiement.montant
                      )}
                      onChange={(val) => {
                        if (val === GatewayPaiement.PaymentMethod.TRANSFER) {
                          formConfig.setValue("modePrelevement", val);
                          return;
                        }
                        updateModePrelevement(
                          val,
                          intention?.paiement.invoiceId
                        )
                          .then(() => {
                            formConfig.setValue("modePrelevement", val);
                          })
                          .catch((error) =>
                            handleGatewayError({
                              onUnhandled: () =>
                                displayErrorToast("Une erreur est survenue"),
                            })(error)
                          );
                      }}
                    />
                  </FormField>
                </FormRow>

                {modePrelevementSelectionne ===
                  GatewayPaiement.PaymentMethod.TRANSFER && (
                  <FormRow>
                    <FormField>
                      <InfoCard type="warning">
                        Le paiement par virement nécessite une action manuelle
                        de notre part, ce qui rallonge le délai de traitement de
                        votre dossier.
                      </InfoCard>
                    </FormField>
                  </FormRow>
                )}
                <FormRow>
                  <FormField>
                    <hr className={style.adhesionPaiementForm__separator} />
                  </FormField>
                </FormRow>
              </>
            )}
          </EntreprisePaymentStripe>
        </FormField>
      </FormRow>
    </>
  );
}
