import { UIParcoursComponentProps } from "@src/parcours/Parcours.types";
import { ParcoursEntrepriseStepEnum } from "./ParcoursEntreprise";

export type UIParcoursEntrepriseProps<TPath> = UIParcoursComponentProps<TPath>;

export function UIParcoursEntreprise({
  children,
}: UIParcoursEntrepriseProps<ParcoursEntrepriseStepEnum>) {
  return <div>{children}</div>;
}
