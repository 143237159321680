import { FormEvent, ReactElement, useEffect, useRef } from "react";
import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";

export type ParcoursActionProps = {
  onClick?: (e: FormEvent) => Promise<void> | void;
  children: string | ReactElement;
  type?: "button" | "submit";
  icon?: ReactElement;
  disabled?: boolean;
  formId?: string;
  variant?: "filled" | "text";
  id?: string;
  className?: string;
};
export function ParcoursAction({
  onClick,
  children,
  type,
  className,
  icon,
  disabled,
  formId,
  id,
  variant = "filled",
}: ParcoursActionProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (buttonRef.current && id) buttonRef.current.id = id;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Button
      ref={buttonRef}
      className={className}
      variant={variant}
      onClick={onClick}
      type={type}
      form={formId}
      icon={icon}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}
