import { GatewayConformiteRcPro } from "@conformite/gateway";
import classNames from "classnames";
import { ReactComponent as Check } from "@src/assets/icons/check.svg";
import { ReactComponent as XLg } from "@src/assets/icons/x-lg.svg";
import styles from "../ConformiteRcPro.module.scss";

const getPropertyStatusText = (
  value: GatewayConformiteRcPro.ConformiteRcProPropertyType
) => {
  if (value === GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER)
    return "À vérifier";
  if (value === GatewayConformiteRcPro.ConformiteRcProPropertyType.OUI)
    return "Oui";
  return "Non";
};
const getPropertyStatusIcon = (
  value: GatewayConformiteRcPro.ConformiteRcProPropertyType
): JSX.Element | null => {
  if (value === GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER)
    return null;
  if (value === GatewayConformiteRcPro.ConformiteRcProPropertyType.OUI)
    return <Check />;
  return <XLg />;
};
type PropertyStatusProps = {
  value: GatewayConformiteRcPro.ConformiteRcProPropertyType;
};
export function PropertyStatus({ value }: PropertyStatusProps) {
  return (
    <div
      className={classNames(styles.propertyVisu, {
        [styles.success]:
          value === GatewayConformiteRcPro.ConformiteRcProPropertyType.OUI,
        [styles.error]:
          value === GatewayConformiteRcPro.ConformiteRcProPropertyType.NON,
      })}
    >
      {getPropertyStatusText(value)}
      {getPropertyStatusIcon(value)}
    </div>
  );
}
