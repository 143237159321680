import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as QuestionCircle } from "@src/assets/icons/question-circle.svg";
import Logo from "@src/assets/logo.png";
import style from "./Header.module.scss";
import { Link } from "../Link/Link";

export type HeaderProps = { title?: string };
export function Header({ title }: HeaderProps) {
  return (
    <div className={style.header}>
      <div className={style.headerLeft}>
        <img src={Logo} alt="endya logo" />
        {title && <h2>{title}</h2>}
      </div>
      <Link to="https://aide.endya.fr/faq" target="_blank">
        <Button variant="text" icon={<QuestionCircle />}>
          Besoin d&apos;aide
        </Button>
      </Link>
    </div>
  );
}
