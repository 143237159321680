import {
  ContactCivilite,
  ContactFonction,
} from '../../contact/controller/contact.definition';

export const invitationControllerURL = '/invitation';

export interface InvitationContactResponseType {
  civilite: ContactCivilite;
  nom: string;
  prenom: string;
  telephone?: string;
  fonction: ContactFonction;
  email: string;
}

export interface InvitationUpdateContactRequestType {
  civilite: ContactCivilite;
  nom: string;
  prenom: string;
  telephone: string;
  email: string;
  fonction: ContactFonction;
  consentement: boolean;
  veutRecevoirNewsletter: boolean;
}
