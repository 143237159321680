const pushHsConversationOnReady = (method: () => void) => {
  window.hsConversationsOnReady = [
    ...(window.hsConversationsOnReady ?? []),
    method,
  ];
};

export const loadHubspotConversation = () => {
  if (window.HubSpotConversations) {
    window.HubSpotConversations.widget?.load();
  } else {
    pushHsConversationOnReady(() =>
      window.HubSpotConversations?.widget?.load()
    );
  }
};

export const removeHubspotConversation = () => {
  if (window.HubSpotConversations) {
    window.HubSpotConversations.widget?.remove();
  } else {
    pushHsConversationOnReady(() =>
      window.HubSpotConversations?.widget?.remove()
    );
  }
};

export const clearHubspotConversation = () => {
  if (window.HubSpotConversations) {
    window.HubSpotConversations?.clear({ resetWidget: true });
  } else {
    pushHsConversationOnReady(() =>
      window.HubSpotConversations?.clear({ resetWidget: true })
    );
  }
};
