import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Form,
  InputButtonGroup,
  InputSelect,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  FormField,
  FormGrid,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { SelectOption } from "@lya-protect/lya-protect-form-library/dist/UI/Input/UIInputSelect";
import { useEntreprises } from "@src/store/store.entreprise";
import { chain } from "lodash";
import { useMemo } from "react";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { ParcoursAction } from "@src/parcours/NavigationButtons/ParcoursAction";
import { PreviousButton } from "@src/parcours/NavigationButtons/PreviousButton";
import {
  GatewayConformite,
  GatewayContact,
  GatewayEntreprise,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { recordToSelectOptions } from "@src/adhesion/AdhesionData/adhesionData";
import { ReactComponent as Loader } from "@src/assets/loader.svg";
import { activitesFromEntreprise } from "@src/helper/entreprise.helper";
import { TagList } from "@src/components/AutocompleteWithList/TagList";
import { getAxesConformiteByActivites } from "@src/societes/societe.helper";
import style from "./AjoutUtilisateurAutorisationsStep.module.scss";
import {
  ParcoursAjoutUtilisateurStep,
  ParcoursAjoutUtilisateurStepComponentProps,
} from "./ParcoursAjoutUtilisateur";
import { labelsByAxesConformite } from "../GestionUtilisateurs";

const FORM_ID = "utilisateur-autorisation-form";

const getSchema = (roleUtilisateur?: GatewayContact.ContactRoleUtilisateur) =>
  zod.object({
    entreprises: zod
      .array(zod.string())
      .min(
        1,
        "Vous devez choisir au moins une société affiliée à l'utilisateur"
      ),
    axesConformite: zod
      .array(zod.nativeEnum(GatewayConformite.AxeConformite))
      .min(
        roleUtilisateur ===
          GatewayContact.ContactRoleUtilisateur.RESPONSABLE_CONFORMITE
          ? 0
          : 1,
        "Vous devez choisir au moins un axe de conformité auquel l'utilisateur aura accès"
      ),
  });

type UtilisateurAutorisationsFormValues = zod.infer<
  ReturnType<typeof getSchema>
>;

const useAxesConformiteOptions = () => {
  const { entreprisesBySiren } = useEntreprises();

  const activites = useMemo(
    () =>
      chain(entreprisesBySiren)
        .flatMap<GatewayInfoSocieteOrias.Categorie | undefined>((entreprise) =>
          activitesFromEntreprise(entreprise)
        )
        .filter((activite) => activite !== undefined)
        .uniq()
        .value() as GatewayInfoSocieteOrias.Categorie[],
    [entreprisesBySiren]
  );

  return useMemo<Record<GatewayConformite.AxeConformite, string>>(() => {
    const axesConformite = getAxesConformiteByActivites(activites);

    const entries = Object.entries(labelsByAxesConformite) as [
      GatewayConformite.AxeConformite,
      string
    ][];
    const filteredEntries = entries.filter(([axeName]) =>
      axesConformite.includes(axeName)
    );
    return Object.fromEntries(filteredEntries) as Record<
      GatewayConformite.AxeConformite,
      string
    >;
  }, [activites]);
};

export function AjoutUtilisateurAutorisationStep({
  changeStep,
  data,
  addData,
  onSubmit,
  submitButtonLabel,
}: ParcoursAjoutUtilisateurStepComponentProps) {
  const axesConformiteOptions = useAxesConformiteOptions();
  const { entreprisesBySiren: entreprises } = useEntreprises();
  const formConfig = useFormConfig<
    ReturnType<typeof getSchema>,
    UtilisateurAutorisationsFormValues
  >({
    schema: getSchema(data.role),
    defaultValues: {
      entreprises: data.entreprises,
      axesConformite: data.axesConformite,
    },
  });

  const selectedEntreprises = formConfig.watch("entreprises");

  const entreprisesByHubspotId = useMemo(() => {
    if (!entreprises) return {};
    const entreprisesValues = Object.values(entreprises);
    return entreprisesValues.reduce<
      Record<string, GatewayEntreprise.EntrepriseAControllerResponseType>
    >((acc, curr) => ({ ...acc, [curr.hubspotId]: curr }), {});
  }, [entreprises]);

  const entreprisesOptions = useMemo<SelectOption<string>[]>(() => {
    const entreprisesEntries = Object.entries(entreprisesByHubspotId);

    return chain(entreprisesEntries)
      .filter(([_, { raisonSociale }]) => !!raisonSociale)
      .map(([hubspotId, { raisonSociale }]) => ({
        label: raisonSociale,
        value: hubspotId,
      }))
      .differenceWith(
        selectedEntreprises,
        ({ value: hubspotIdA }, hubspotIdB) => hubspotIdA === hubspotIdB
      )
      .value();
  }, [entreprisesByHubspotId, selectedEntreprises]);

  return (
    <FormGrid>
      <Form
        id={FORM_ID}
        formConfig={formConfig}
        onSubmit={async (values: UtilisateurAutorisationsFormValues) => {
          try {
            await onSubmit({ ...data, ...values });
            addData(values);
          } catch (err) {
            console.error(err);
          }
        }}
      >
        <FormRow>
          <FormField>
            {data.role ===
              GatewayContact.ContactRoleUtilisateur.UTILISATEUR_SIMPLE && (
              <h2>Autorisations de l&apos;utilisateur simple</h2>
            )}

            {data.role ===
              GatewayContact.ContactRoleUtilisateur.RESPONSABLE_CONFORMITE && (
              <h2>Sociétés du responsable conformité</h2>
            )}
          </FormField>
        </FormRow>
        {data.role !==
          GatewayContact.ContactRoleUtilisateur.RESPONSABLE_CONFORMITE && (
          <FormRow>
            <FormField>
              <h4>Sociétés</h4>
            </FormField>
          </FormRow>
        )}
        <FormRow>
          <FormField>
            <InputSelect<UtilisateurAutorisationsFormValues, string>
              required
              name="entreprises"
              placeholder="Sélectionner"
              isSearchable
              label="Sélectionnez les sociétés affiliées à l'utilisateur"
              options={entreprisesOptions}
              onChange={(value) => {
                if (!value) return;
                formConfig.setValue("entreprises", [
                  ...formConfig.getValues("entreprises"),
                  value,
                ]);
                formConfig.trigger("entreprises").catch(console.error);
              }}
            />
          </FormField>
        </FormRow>

        {selectedEntreprises.length > 0 && (
          <FormRow>
            <FormField>
              <TagList
                fields={selectedEntreprises.map((entrepriseHubspotId) => ({
                  id: entrepriseHubspotId,
                  value:
                    entreprisesByHubspotId[entrepriseHubspotId].raisonSociale,
                }))}
                remove={(index) => {
                  formConfig.setValue(
                    "entreprises",
                    selectedEntreprises.filter(
                      (_, currIndex) => currIndex !== index
                    )
                  );
                  formConfig.trigger("entreprises").catch(console.error);
                }}
              />
            </FormField>
          </FormRow>
        )}

        {data.role !==
          GatewayContact.ContactRoleUtilisateur.RESPONSABLE_CONFORMITE && (
          <>
            <FormRow>
              <FormField>
                <h4>Axes de conformité</h4>
              </FormField>
            </FormRow>
            <FormRow>
              <FormField>
                <InputButtonGroup<
                  UtilisateurAutorisationsFormValues,
                  true,
                  GatewayConformite.AxeConformite
                >
                  label="Sélectionnez les axes de conformité auxquels l'utilisateur aura accès"
                  size="large"
                  variant="outline-pop"
                  isMultiple
                  name="axesConformite"
                  options={recordToSelectOptions(axesConformiteOptions)}
                  required
                />
              </FormField>
            </FormRow>
          </>
        )}

        <FormRow>
          <FormField>
            <InfoCard type="warning">
              {data.role ===
                GatewayContact.ContactRoleUtilisateur.UTILISATEUR_SIMPLE && (
                <p>
                  Cette personne pourra visualiser et modifier les informations
                  de conformité choisies de ces entreprises.
                </p>
              )}
              {data.role ===
                GatewayContact.ContactRoleUtilisateur
                  .RESPONSABLE_CONFORMITE && (
                <p>
                  Cette personne pourra visualiser et modifier les informations
                  de conformité de ces entreprises.
                </p>
              )}
            </InfoCard>
          </FormField>
        </FormRow>

        <FormRow>
          <FormField className={style.actionsWrapper}>
            <PreviousButton
              changeToPrevStep={() =>
                changeStep(ParcoursAjoutUtilisateurStep.UTILISATEUR)
              }
            />
            <ParcoursAction
              formId={FORM_ID}
              type="submit"
              disabled={formConfig.formState.isSubmitting}
              icon={formConfig.formState.isSubmitting ? <Loader /> : undefined}
            >
              {submitButtonLabel}
            </ParcoursAction>
          </FormField>
        </FormRow>
      </Form>
    </FormGrid>
  );
}
