import { ColumnDefinition } from "../Table.definition";
import styles from "../Table.module.scss";

type TableSeparatorProps<T> = {
  columns: ColumnDefinition<T>[];
  selectable?: boolean;
};
export function TableSeparator<T>({
  columns,
  selectable,
}: TableSeparatorProps<T>) {
  return (
    <div
      className={styles.separator}
      style={{
        gridColumn: `span ${columns.length + (selectable ? 1 : 0)}`,
      }}
    />
  );
}
