import { useCallback } from "react";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import {
  EntrepriseAdresseForm,
  EntrepriseAdresseFormValues,
} from "@src/societes/form/EntrepriseAdresseForm";
import { ParcoursAction } from "@src/parcours/NavigationButtons/ParcoursAction";
import {
  ID_FORM_RENOUVELLEMENT,
  ParcoursRenouvellementStepComponentProps,
  RenouvellementStepEnum,
} from "@src/renouvellement/ParcoursRenouvellement.definition";

export function RenouvellementAdressePage({
  data,
  setData,
  changeStep,
}: ParcoursRenouvellementStepComponentProps) {
  const handleSubmit = useCallback(
    (values: EntrepriseAdresseFormValues) => {
      setData({
        ...data,
        resume: {
          ...data.resume,
          entreprisePrincipale: {
            ...data.resume.entreprisePrincipale,
            adresse: {
              codePostal: values.codePostal,
              ville: values.ville,
              libelleVoie: values.libelleVoie,
              numeroVoie: values.numeroVoie as string,
              pays: values.pays,
              typeVoie: values.typeVoie,
              complementAdresse: values.complementAdresse,
            },
          },
        },
      });
      changeStep(RenouvellementStepEnum.RESUME_ENTREPRISE);
    },
    [setData, data, changeStep]
  );

  return (
    <ParcoursFullPageTemplate
      title="Adresse"
      actions={
        <ParcoursAction type="submit" formId={ID_FORM_RENOUVELLEMENT}>
          Enregistrer les modifications
        </ParcoursAction>
      }
    >
      <EntrepriseAdresseForm
        formId={ID_FORM_RENOUVELLEMENT}
        defaultValues={data.resume.entreprisePrincipale.adresse}
        onSubmit={handleSubmit}
      />
    </ParcoursFullPageTemplate>
  );
}
