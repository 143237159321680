import { GatewayAssujetti } from "@conformite/gateway";
import { FormTitle } from "@lya-protect/lya-protect-form-library/dist/UI";
import { IconStatus } from "@src/components/IconStatus/IconStatus";
import styles from "./ControleQuinquennalFormTitle.module.scss";

type ControleQuiquennalFormTitleProps = {
  title: string;
  assujetti: GatewayAssujetti.AssujettiWithAvancement;
  avancement: keyof GatewayAssujetti.AssujettiWithAvancement["avancement"];
};
export function ControleQuinquennalFormTitle({
  title,
  assujetti,
  avancement,
}: ControleQuiquennalFormTitleProps) {
  return (
    <FormTitle className={styles.header}>
      <h2 className={styles.title}>
        <b>{title}</b>
      </h2>
      <IconStatus isValid={assujetti.avancement[avancement] === 100} />
    </FormTitle>
  );
}
