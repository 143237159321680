import {
  FormField,
  FormGrid,
  FormNotice,
  FormRow,
  FormTitle,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { IconStatus } from "@src/components/IconStatus/IconStatus";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import {
  InputButtonGroup,
  InputDate,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import { isBeforeToday, wasLessThanOneYearAgo } from "@src/helper/date.helper";
import { useAssujetti } from "@src/store/store.assujetti";
import { GatewayHonorabiliteApi } from "@src/api/conformite/honorabilite.api";
import { GatewayHonorabilite } from "@conformite/gateway";
import { isToday } from "date-fns";
import { AssujettiConformiteData } from "../../AssujettiParcoursConformite";
import { NextVerificatioCard } from "../../Components/NextVerificationCard/NextVerificationCard";
import styles from "./ConformiteFormHonorabilite.module.scss";

export const honorabiliteSchema = zod.object({
  lastVerifiedAt: zod
    .date()
    .refine((value) => {
      if (!value) return false;
      return wasLessThanOneYearAgo(value);
    }, "La dernière vérification date d'il y a plus d'un an. Elle n'est donc pas valable. Merci de vérifier à nouveau")
    .refine((value) => {
      if (!value) return false;
      return isBeforeToday(value) || isToday(value);
    }, "La date doit être inférieure ou égale à la date du jour."),
  isHonourable: zod.literal(true, {
    errorMap: () => ({ message: "La personne assujettie n'est pas conforme" }),
  }),
});

export type HonorabiliteFormValues = zod.infer<typeof honorabiliteSchema>;

export const useConformiteFormHonorabiliteForm = (
  formData: GatewayHonorabilite.Honorabilite | null
) => {
  const formConfig = useFormConfig<
    typeof honorabiliteSchema,
    HonorabiliteFormValues
  >({
    schema: honorabiliteSchema,
    defaultValues: {
      isHonourable: formData?.isHonourable,
      lastVerifiedAt: formData?.lastVerifiedAt ?? new Date(),
    } as HonorabiliteFormValues,
  });
  const lastVerifiedAt = formConfig.watch("lastVerifiedAt");
  return { formConfig, lastVerifiedAt };
};
export const useConformiteFormHonorabilite = (
  data: AssujettiConformiteData,
  formData: GatewayHonorabilite.Honorabilite | null,
  setFormData: (data: GatewayHonorabilite.Honorabilite) => void
) => {
  const { updateAvancement } = useAssujetti();
  const { formConfig, lastVerifiedAt } =
    useConformiteFormHonorabiliteForm(formData);
  const onSubmit = async (values: HonorabiliteFormValues) => {
    const valuesToSubmit = {
      isHonourable: values.isHonourable ?? null,
      lastVerifiedAt: values.lastVerifiedAt ?? null,
    };
    const avancement = await GatewayHonorabiliteApi.save(
      data.assujetti.id,
      data.assujetti.millesime,
      valuesToSubmit
    );
    updateAvancement(data.assujetti.millesime, data.assujetti.id, avancement);
    setFormData(valuesToSubmit);
  };
  return { formConfig, onSubmit, lastVerifiedAt };
};

type ConformiteFormHonorabiliteContentProps = {
  hideTitle?: boolean;
  data: AssujettiConformiteData;
  lastVerifiedAt: Date | null;
};
export function ConformiteFormHonorabiliteContent({
  hideTitle,
  data,
  lastVerifiedAt,
}: ConformiteFormHonorabiliteContentProps) {
  return (
    <FormGrid disableHorizontalGutter>
      {!hideTitle && (
        <FormTitle className={styles.header}>
          <h2 className={styles.title}>
            <b>Honorabilité</b> - {data.assujetti.surname} {data.assujetti.name}
          </h2>
          <IconStatus
            isValid={data.assujetti.avancement.honorabilite === 100}
          />
        </FormTitle>
      )}
      <FormNotice>
        <FormRow>
          <InfoCard type="info" className={hideTitle ? styles.notice : ""}>
            La personne ne doit pas avoir de casier judiciaire de moins de 10
            ans.
          </InfoCard>
        </FormRow>
      </FormNotice>
      <FormRow>
        <FormField>
          <InputDate<HonorabiliteFormValues>
            label="Date de dernière vérification"
            name="lastVerifiedAt"
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <InputButtonGroup<HonorabiliteFormValues, false, boolean>
            size="medium"
            label="La personne est-elle honorable ?"
            name="isHonourable"
            variant="outline-pop"
            options={[
              { label: "Oui", value: true },
              { label: "Non", value: false },
            ]}
          />
        </FormField>
      </FormRow>
      <NextVerificatioCard lastVerifiedAt={lastVerifiedAt} />
    </FormGrid>
  );
}
