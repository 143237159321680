import { useCallback } from "react";
import {
  EntrepriseIdentificationForm,
  EntrepriseIdentificationFormValues,
} from "@src/societes/form/EntrepriseIdentificationForm";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import { ParcoursAction } from "@src/parcours/NavigationButtons/ParcoursAction";
import {
  ID_FORM_RENOUVELLEMENT,
  ParcoursRenouvellementStepComponentProps,
  RenouvellementStepEnum,
} from "@src/renouvellement/ParcoursRenouvellement.definition";

export function RenouvellementIdentificationPage({
  data,
  changeStep,
  setData,
}: ParcoursRenouvellementStepComponentProps) {
  const handleSubmit = useCallback(
    (values: EntrepriseIdentificationFormValues) => {
      setData({
        ...data,
        resume: {
          ...data.resume,
          entreprisePrincipale: {
            ...data.resume.entreprisePrincipale,
            ...values,
          },
        },
      });
      changeStep(RenouvellementStepEnum.RESUME_ENTREPRISE);
    },
    [setData, data, changeStep]
  );

  return (
    <ParcoursFullPageTemplate
      title="Identification"
      actions={
        <ParcoursAction type="submit" formId={ID_FORM_RENOUVELLEMENT}>
          Enregistrer les modifications
        </ParcoursAction>
      }
    >
      <EntrepriseIdentificationForm
        defaultValues={{
          formeJuridique: data.resume.entreprisePrincipale.formeJuridique,
          raisonSociale: data.resume.entreprisePrincipale.raisonSociale,
          siren: data.resume.entreprisePrincipale.siren,
          trancheEffectif: data.resume.entreprisePrincipale.trancheEffectif,
          typeSouscripteur: data.resume.entreprisePrincipale.typeSouscripteur,
        }}
        onSubmit={handleSubmit}
        formId={ID_FORM_RENOUVELLEMENT}
      />
    </ParcoursFullPageTemplate>
  );
}
