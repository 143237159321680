import { useNavigate } from "react-router-dom";
import { GatewayInfoSocieteOrias } from "@conformite/gateway";
import { ConformiteSocieteList } from "./ConformiteSocieteList";

export function ConformiteSocieteListDeclarationActiviteIas() {
  const navigate = useNavigate();

  return (
    <ConformiteSocieteList
      getFormAvancement={(entreprise) =>
        entreprise.avancement.declarationActiviteIas ?? 0
      }
      defaultFilters={{
        categories: GatewayInfoSocieteOrias.CategorieIASList,
      }}
      title="Déclaration activité IAS"
      onSocieteRedirect={(entreprise) => {
        navigate(`${entreprise.siren}?fromAxe`);
      }}
    />
  );
}
