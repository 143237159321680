import {
  AnyRegularField,
  GroupField,
  PrismicDocument,
  SliceZone,
} from "@prismicio/client";
import { usePrismicDocumentsByType } from "@prismicio/react";

export type UseCmsDocumentListProps = {
  pageSize: number;
  page: number;
  documentType: "actualite" | "le_saviez_vous";
};
export const useCmsDocumentList = <
  DataInterface extends Record<string, AnyRegularField | GroupField | SliceZone>
>({
  pageSize,
  page,
  documentType,
}: UseCmsDocumentListProps) => {
  const [data] = usePrismicDocumentsByType<
    PrismicDocument<DataInterface, string, string>
  >(documentType, {
    pageSize,
    page,
    orderings: "document.first_publication_date desc",
  });
  return {
    data: data?.results ?? [],
    total: data?.total_results_size ?? 0,
  };
};
