import { GatewayAdhesion2Api } from "@src/api/adhesion2.api";
import { AdhesionData } from "@src/adhesion/AdhesionData/adhesionData";
import { AdhesionStepEnum } from "./ParcoursAdhesion.definition";

export async function persistCacheOnAPI(
  data: AdhesionData,
  history: AdhesionStepEnum[]
): Promise<void> {
  const desensitizedData: AdhesionData = {
    ...data,
    identifiants: { email: "", password: "" },
    coupon: undefined,
  };

  return GatewayAdhesion2Api.saveCache(
    {
      data: desensitizedData,
      history,
    },
    "ADHESION"
  );
}

export async function getCacheFromAPI() {
  return GatewayAdhesion2Api.getCache("ADHESION");
}
