import { useMemo } from "react";
import { intersection } from "lodash";
import {
  GatewayEntreprise,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { activitesFromEntreprise } from "@src/helper/entreprise.helper";
import {
  FilterPaginationReturn,
  useFilterPagination,
} from "@src/components/Filter/useFilterPagination";
import { useEntrepriseAvancement } from "@src/societes/useEntrepriseAvancement";
import { filterSearchByKeys } from "@src/components/Filter/filter.helper";

export const SOCIETES_PAGE_SIZE = 25;
export type SocieteFilters = {
  categories?: GatewayInfoSocieteOrias.Categorie[];
};

export type UseSocieteFilteredReturn = FilterPaginationReturn<
  GatewayEntreprise.EntrepriseAControllerResponseType,
  "raisonSociale" | "conformite" | "categorie",
  "raisonSociale" | "conformite"
>;

export const useSocieteFiltered = (
  societes: GatewayEntreprise.EntrepriseAControllerResponseType[],
  filter?: SocieteFilters
): UseSocieteFilteredReturn => {
  const societesFilteredByCategories = useMemo(
    () =>
      societes.filter((societe) => {
        if (!filter?.categories) return true;
        return (
          intersection(activitesFromEntreprise(societe), filter.categories)
            .length > 0
        );
      }),
    [societes, filter]
  );
  const { getEntrepriseAvancement } = useEntrepriseAvancement();
  const filterData = useFilterPagination({
    content: societesFilteredByCategories,
    pagination: {
      pageSize: SOCIETES_PAGE_SIZE,
    },
    filters: [
      {
        name: "raisonSociale",
        filter: filterSearchByKeys(["raisonSociale", "siren"]),
      },
      {
        name: "conformite",
        filter: (societe, values) => {
          if (values.length === 0) return true;
          const conformite = getEntrepriseAvancement(societe);
          return values.reduce((acc, value) => {
            const isConforme = conformite === 100;
            const conformiteValue = value === "true" ? isConforme : !isConforme;
            return acc || conformiteValue;
          }, false);
        },
      },
      {
        name: "categorie",
        filter: (societe, values) => {
          if (values.length === 0) return true;
          const categories = activitesFromEntreprise(societe);
          return values.reduce((acc, value) => {
            const hasCategorie = (categories as string[]).includes(value);
            return acc || hasCategorie;
          }, false);
        },
      },
    ],
    sorts: [
      {
        name: "raisonSociale",
        sorting: (societe1, societe2) =>
          societe1.raisonSociale?.localeCompare(societe2.raisonSociale || "") ||
          0,
      },
      {
        name: "conformite",
        sorting: (societe1, societe2) =>
          getEntrepriseAvancement(societe1) - getEntrepriseAvancement(societe2),
      },
    ],
    defaultSort: {
      name: "raisonSociale",
      order: "asc",
    },
  });

  return filterData;
};
