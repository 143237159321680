import { ReactComponent as Download } from "@src/assets/icons/download.svg";
import { downloadFile, getDownloadedFile } from "@src/helper/file.helper";
import { useIsAllowed } from "@src/session/useIsAllowed";
import { GatewayAuthentication } from "@conformite/gateway";
import styles from "./DownloadFile.module.scss";

type DownloadFileProps = {
  siren: string;
  millesime: number;
  pathname: string;
  filename: string;
};
export function DownloadFile({
  siren,
  millesime,
  pathname,
  filename,
}: DownloadFileProps) {
  const { hasAllowedCognitoRole } = useIsAllowed();
  const isAdmin = hasAllowedCognitoRole(GatewayAuthentication.Role.ENDYA);
  const download = () => {
    getDownloadedFile(siren, millesime, pathname, filename, isAdmin)
      .then((file) => {
        if (!file) return;
        downloadFile(file.file, file.filename);
      })
      .catch(console.error);
  };

  return <Download onClick={download} className={styles.download} />;
}
