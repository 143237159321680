import { Link } from "@src/components/Link/Link";
import { UserMenu } from "@src/components/UserMenu/UserMenu";
import { PrismicText } from "@prismicio/react";
import { useSidebarAccueil } from "@src/cms/useSidebarAccueil";
import styles from "./home.module.scss";

export function HomeRightSidebar() {
  const { accueilSidebar } = useSidebarAccueil();

  return (
    <div className={styles.pageAside}>
      <div className={styles.pageAsideHeader}>
        <UserMenu />
      </div>

      <div className={styles.pageAsideContent}>
        <h2 className={styles.pageAsideTitle}>Nos services</h2>
        <div className={styles.service}>
          {accueilSidebar?.group.map((doc, index) => (
            <Link
              key={doc.pages?.id ?? index}
              to={`/services/${doc.pages?.uid ?? ""}`}
              className={styles.serviceLink}
            >
              <PrismicText field={doc.pages?.data?.titre_lien} />
            </Link>
          ))}
        </div>
        <h2 className={styles.pageAsideTitle}>Contact</h2>
        <Link to="mailto:support@endya.fr" className={styles.pageAsideMail}>
          support@endya.fr
        </Link>
      </div>
    </div>
  );
}
