import {
  GatewayControleQuinquennal,
  GatewayEntreprise,
} from "@conformite/gateway";
import {
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ControleQuinquennalApi } from "@src/api/controleQuinquennal.api";
import { useControlesQuinquennaux } from "@src/controleQuinquennal/adherent/list/useControlesQuinquennaux";
import { useControleQuinquennal } from "@src/store/store.controleQuinquennal";
import { useMe } from "@src/store/store.me";
import { differenceInDays } from "date-fns";
import { useEffect, useState } from "react";
import { CardControleQuinquennal } from "./CardControleQuinquennal";

const useControleQuinquennalOfIndividuel = () => {
  const { controlesQuinquennaux } = useControlesQuinquennaux();
  const [controleQuinquennal, setControleQuinquennal] =
    useState<GatewayControleQuinquennal.ControleQuinquennalWithAxes>();

  useEffect(() => {
    const idControleQuinquennal = controlesQuinquennaux[0]?.id;
    let isMounted = true;
    if (idControleQuinquennal) {
      ControleQuinquennalApi.getControleQuinquennal(idControleQuinquennal)
        .then((data) => {
          if (isMounted) setControleQuinquennal(data);
        })
        .catch(console.error);
    }

    return () => {
      isMounted = false;
    };
  }, [controlesQuinquennaux]);

  return { controleQuinquennal };
};

export function ControleQuinquennalIndividuel() {
  const { me } = useMe();
  const { hasControlesQuinquennaux } = useControleQuinquennal();
  const { controleQuinquennal } = useControleQuinquennalOfIndividuel();
  if (
    !controleQuinquennal ||
    me?.entreprisePrincipale.typeAdhesion !==
      GatewayEntreprise.TypeAdhesion.SOCIETE_INDIVIDUELLE ||
    !hasControlesQuinquennaux ||
    controleQuinquennal.status ===
      GatewayControleQuinquennal.ControleQuinquennalStatus.DEPOSE ||
    (controleQuinquennal.status ===
      GatewayControleQuinquennal.ControleQuinquennalStatus.TERMINE &&
      controleQuinquennal.dateRapportFinal &&
      differenceInDays(
        new Date(),
        new Date(controleQuinquennal.dateRapportFinal)
      ) > 30)
  )
    return null;

  return (
    <FormRow>
      <FormField>
        <CardControleQuinquennal controleQuinquennal={controleQuinquennal} />
      </FormField>
    </FormRow>
  );
}
