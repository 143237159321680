import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactElement, useState } from "react";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { useOnce } from "@src/hooks/useOnce";

export type WithFormData<T extends Record<string, unknown>, TFormData> = T & {
  formData: TFormData | null;
  setFormData: (data: TFormData) => void;
};

type ComponentWithFormDataProps<
  TProps extends Record<string, unknown>,
  TFormData
> = {
  FormComponent: (props: WithFormData<TProps, TFormData>) => ReactElement;
  formProps: TProps;
  fetchData: () => Promise<TFormData | null>;
};

export function ComponentWithFormData<
  TProps extends Record<string, unknown>,
  TFormData
>({
  FormComponent,
  formProps,
  fetchData,
}: ComponentWithFormDataProps<TProps, TFormData>) {
  const [formData, setFormData] = useState<TFormData | null>(null);
  const [isFetched, setIsFetched] = useState(false);
  useOnce(() => {
    fetchData()
      .then((v) => {
        setFormData(v);
        setIsFetched(true);
      })
      .catch(
        handleGatewayError({
          onUnhandled: () =>
            displayErrorToast(
              "Une erreur est survenue lors du chargement des données"
            ),
        })
      );
  });
  if (isFetched === false) return null;
  return (
    <FormComponent
      {...formProps}
      formData={formData}
      setFormData={setFormData}
    />
  );
}
