import { GatewayAvancement, GatewayEntreprise } from "@conformite/gateway";
import { EntreprisesBySiren } from "./store.definition";
import { useStore } from "./store.hooks";

export const useEntreprises = (overrideMillesime?: number) => {
  const storeValue = useStore();
  const { setData, data, status } = storeValue.entreprises;

  const selectedMillesime = overrideMillesime || storeValue.millesime.selected;
  const entreprisesOfMillesime =
    selectedMillesime !== undefined ? data?.[selectedMillesime] : undefined;

  const setEntreprise = (
    entreprise: GatewayEntreprise.EntrepriseAControllerResponseType
  ) => {
    if (!entreprisesOfMillesime) throw new Error("Data is not loaded");
    if (!selectedMillesime) throw new Error("No Millesime selected");
    setData({
      ...data,
      [selectedMillesime]: {
        ...entreprisesOfMillesime,
        [entreprise.siren]: entreprise,
      },
    });
  };
  const updateAvancement = (
    avancement: GatewayAvancement.AvancementSociete
  ) => {
    if (!selectedMillesime) throw new Error("No Millesime selected");
    const entreprise = entreprisesOfMillesime?.[avancement.siren];
    if (!entreprise) throw new Error("No entreprise to update");
    setEntreprise({
      ...entreprise,
      avancement,
    });
  };

  const setEntreprises = (
    entreprises: GatewayEntreprise.EntrepriseAControllerResponseType[]
  ) => {
    if (!selectedMillesime) throw new Error("No Millesime selected");
    const entrepriseBySiren = entreprises.reduce<EntreprisesBySiren>(
      (acc, curr) => ({ ...acc, [curr.siren]: curr }),
      {}
    );

    setData({
      ...(data || {}),
      [selectedMillesime]: entrepriseBySiren,
    });
  };

  return {
    entreprisesBySiren: entreprisesOfMillesime,
    status,
    setEntreprise,
    updateAvancement,
    setEntreprises,
  };
};
