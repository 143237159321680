import { isEqual } from "lodash";
import { useMemo, useState } from "react";

function initSelectFromData<T>(data: T[]): Record<number, boolean> {
  return data.reduce((selected, _, index) => {
    selected[index] = false;
    return selected;
  }, {} as Record<number, boolean>);
}

export type TableSelectionState<T> = {
  selectedIndexes: Record<number, boolean>;
  selectedRows: T[];
  atLeastOneSelected: boolean;
  allSelected: boolean;
  selectIndex: (index: number, nextSelected: boolean) => void;
  toggleAll: (toggle: boolean) => void;
};

export function useTableSelection<T>(
  enabled: boolean,
  data: T[]
): TableSelectionState<T> | null {
  const [prevData, setPrevData] = useState<T[]>(data);
  const [selectedIndexes, setSelectedIndexes] = useState(
    initSelectFromData(data)
  );
  // Reset selection when data changes
  const resetting = useMemo(() => {
    const hasChanged = !isEqual(prevData, data);
    setPrevData(data);
    setSelectedIndexes(initSelectFromData(data));
    return hasChanged;
  }, [data, prevData]);

  // disabled selection when reseting
  if (!enabled || resetting || data.length === 0) return null;

  return {
    selectedIndexes,
    selectedRows: Object.entries(selectedIndexes)
      .filter(([_, selected]) => selected)
      .map(([index]) => data[Number(index)]),
    atLeastOneSelected: Object.values(selectedIndexes).some(
      (selected) => selected
    ),
    allSelected: Object.values(selectedIndexes).every((selected) => selected),
    selectIndex: (index: number, nextSelected: boolean) =>
      setSelectedIndexes({ ...selectedIndexes, [index]: nextSelected }),
    toggleAll: (toggle: boolean) =>
      setSelectedIndexes(
        Object.keys(selectedIndexes).reduce((selected, _, index) => {
          selected[index] = toggle;
          return selected;
        }, {} as Record<number, boolean>)
      ),
  };
}
