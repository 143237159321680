import {
  Button,
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { ReactComponent as Download } from "@src/assets/icons/download.svg";
import { useMe } from "@src/store/store.me";

export function VirementForm() {
  const { me } = useMe();

  if (!me) return null;

  return (
    <>
      <FormRow>
        <FormField>
          <h2>Paiement par virement</h2>
        </FormField>
      </FormRow>

      <FormRow>
        <FormField>
          <InfoCard>
            Indiquez le numéro SIREN dans la référence du virement :{" "}
            <b>{me.entreprisePrincipale.siren}</b>
          </InfoCard>
        </FormField>
      </FormRow>

      <FormRow>
        <FormField>
          <Button icon={<Download />} variant="text">
            <a
              href="https://endya-public.s3.eu-west-3.amazonaws.com/rib_endya.pdf"
              target="_blank"
              rel="noreferrer"
              download
              style={{ textDecoration: "none" }}
            >
              Télécharger le RIB
            </a>
          </Button>
        </FormField>
      </FormRow>
    </>
  );
}
