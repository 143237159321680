import { Pagination } from "@src/components/Pagination/Pagination";
import { Dispatch, PropsWithChildren, SetStateAction } from "react";
import { CmsPageTemplate } from "../CmsPageTemplate";
import styles from "./CmsListPage.module.scss";

type CmsListPageProps = PropsWithChildren<{
  title: string;
  page: number;
  pageSize: number;
  total: number;
  setPage: Dispatch<SetStateAction<number>>;
}>;
export function CmsListPage({
  title,
  children,
  page,
  pageSize,
  total,
  setPage,
}: CmsListPageProps) {
  return (
    <CmsPageTemplate>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.page}>
        {children}
        <div className={styles.pagination}>
          <Pagination
            page={page - 1}
            pageSize={pageSize}
            total={total}
            onChange={(selectedPage) => {
              setPage(selectedPage + 1);
            }}
          />
        </div>
      </div>
    </CmsPageTemplate>
  );
}
