import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Form,
  InputSelect,
  InputText,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  Button,
  displayErrorToast,
  Emphasis,
  FormAction,
  FormField,
  FormGrid,
  FormNotice,
  FormRow,
  FormTitle,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayAssujetti } from "@conformite/gateway";
import { SelectOption } from "@lya-protect/lya-protect-form-library/dist/UI/Input/UIInputSelect";
import { useMemo, useRef } from "react";
import { recordToSelectOptions } from "@src/adhesion/AdhesionData/adhesionData";
import { useEntreprises } from "@src/store/store.entreprise";
import { Assujetti } from "@src/store/store.definition";
import { ReactComponent as ArrowRightIcon } from "@src/assets/icons/arrow-right.svg";
import { RoleAssujettiLabels } from "@src/assujettis/AssujettiConsts";
import { GatewayAssujettiApi } from "@src/api/assujetti.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import styles from "./AssujettiInfoForm.module.scss";
import { ReadonlyFieldSet } from "../AssujettiParcoursConformite/Components/ReadonlyFieldSet";

function useMyCompaniesOptions() {
  const { entreprisesBySiren } = useEntreprises();

  const myCompaniesOption: SelectOption<string>[] = useMemo(
    () =>
      entreprisesBySiren
        ? Object.values(entreprisesBySiren).map((e) => ({
            label: e.raisonSociale || "",
            value: e.siren,
          }))
        : [],
    [entreprisesBySiren]
  );

  return myCompaniesOption;
}

export const assujettiInfoSchema = zod.object({
  id: zod.string().optional(),
  name: zod.string().nonempty(),
  surname: zod.string().nonempty(),
  role: zod.nativeEnum(GatewayAssujetti.RoleAssujetti),
  email: zod
    .union([zod.string().email().optional(), zod.literal("")])
    .transform((val) => (val === "" ? undefined : val)),
  sirenCompany: zod.string().nonempty(),
});

export type AssujettiInfoFormValues = zod.infer<typeof assujettiInfoSchema>;
type AssujettiInfoFormProps = {
  afterSubmit: (
    submittedAssujetti: Assujetti,
    shouldCompleteConformite: boolean
  ) => void;
  assujetti: Assujetti | null;
  millesime: number;
  readonly?: boolean;
  defaultSiren?: string;
};
export function AssujettiInfoForm({
  afterSubmit,
  assujetti,
  millesime,
  readonly,
  defaultSiren,
}: AssujettiInfoFormProps) {
  const companiesOption = useMyCompaniesOptions();
  const completeConformiteButtonRef = useRef<HTMLButtonElement>(null);
  const formConfig = useFormConfig<
    typeof assujettiInfoSchema,
    AssujettiInfoFormValues
  >({
    schema: assujettiInfoSchema,
    defaultValues: {
      id: assujetti?.id,
      email: assujetti?.email || undefined,
      name: assujetti?.name,
      role: assujetti?.role,
      sirenCompany: defaultSiren ?? assujetti?.sirenCompany,
      surname: assujetti?.surname,
    },
  });

  return (
    <Form
      formConfig={formConfig}
      onSubmit={async (values, e) => {
        try {
          const submittedAssujetti = await GatewayAssujettiApi.set({
            millesime,
            ...values,
          });
          afterSubmit(
            {
              ...submittedAssujetti,
              avancement: assujetti
                ? assujetti.avancement
                : {
                    idAssujetti: submittedAssujetti.id,
                    millesime: submittedAssujetti.millesime,
                    honorabilite: null,
                    formationContinueIas: null,
                    formationContinueIobsp: null,
                    capaciteProfessionnelleIas: null,
                    capaciteProfessionnelleIobsp: null,
                  },
            },
            (e?.nativeEvent as SubmitEvent).submitter ===
              completeConformiteButtonRef.current
          );
        } catch (err) {
          handleGatewayError({
            onUnhandled: () =>
              displayErrorToast(
                "Une erreur est survenue lors de la création de la personne"
              ),
          })(err);
          throw err;
        }
      }}
    >
      <ReadonlyFieldSet readonly={readonly}>
        <FormGrid disableHorizontalGutter>
          <FormTitle>
            <h2>Informations de la personne</h2>
          </FormTitle>
          <FormNotice>
            <Emphasis>*</Emphasis> Champs obligatoires
          </FormNotice>
          {!defaultSiren && (
            <FormRow>
              <FormField>
                <InputSelect<AssujettiInfoFormValues, string>
                  label="Société"
                  name="sirenCompany"
                  options={companiesOption}
                  required
                />
              </FormField>
            </FormRow>
          )}
          <FormRow>
            <FormField>
              <InputText<AssujettiInfoFormValues>
                label="Nom"
                name="name"
                required
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <InputText<AssujettiInfoFormValues>
                label="Prénom"
                name="surname"
                required
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <InputSelect<
                AssujettiInfoFormValues,
                GatewayAssujetti.RoleAssujetti
              >
                label="Fonction"
                name="role"
                options={recordToSelectOptions(RoleAssujettiLabels)}
                required
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <InputText<AssujettiInfoFormValues>
                label="Adresse email"
                name="email"
              />
            </FormField>
          </FormRow>
          <FormAction className={styles.actions}>
            <Button type="submit" disabled={formConfig.formState.isSubmitting}>
              Enregistrer
            </Button>
            <Button
              ref={completeConformiteButtonRef}
              variant="text"
              type="submit"
              iconVariant="left"
              icon={<ArrowRightIcon />}
              disabled={formConfig.formState.isSubmitting}
            >
              Compléter la conformite
            </Button>
          </FormAction>
        </FormGrid>
      </ReadonlyFieldSet>
    </Form>
  );
}
