import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayAuthApi } from "@src/api/auth.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import {
  AdhesionStepEnum,
  ID_FORM_ADHESION,
  ParcoursAdhesionStepComponentProps,
} from "@src/adhesion/ParcoursAdhesion.definition";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import { ParcoursAdhesionNextAction } from "@src/adhesion/AdhesionActions/ParcoursAdhesionNextAction";
import { SignupForm, SignupFormValues } from "@src/user/Signup/SignupForm";
import { useState } from "react";
import { ReactComponent as Loader } from "@src/assets/loader.svg";

export function AdhesionIdentifiants({
  addData,
  data,
  changeStep,
}: ParcoursAdhesionStepComponentProps) {
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (submittedData: SignupFormValues) => {
    setIsLoading(true);
    try {
      await GatewayAuthApi.signup(
        submittedData.email.email,
        submittedData.password.password
      );
      addData({
        identifiants: {
          password: submittedData.password.password,
          email: submittedData.email.email,
        },
        consents: {
          consentCGV: submittedData.consentCGV,
          consentNewsletter: submittedData.consentNewsletter,
        },
      });
      changeStep(AdhesionStepEnum.CONFIRM);
    } catch (err) {
      handleGatewayError({
        onResponse: (error) => {
          if (error.response.status === 409) {
            displayErrorToast(
              "Désolé, nous ne pouvons pas créer votre compte, l'adresse\
            email que vous avez renseigné est déjà utilisée",
              {
                canal: "adhesion",
              }
            );
          } else {
            displayErrorToast(error.response.data.message, {
              canal: "adhesion",
            });
          }
        },
        onUnhandled: () => {
          displayErrorToast("Une erreur inconnue est survenue", {
            canal: "adhesion",
          });
        },
      })(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ParcoursFullPageTemplate
      title="Identifiants de connexion"
      actions={
        <ParcoursAdhesionNextAction
          type="submit"
          disabled={isLoading}
          icon={isLoading ? <Loader /> : undefined}
        >
          Suivant
        </ParcoursAdhesionNextAction>
      }
    >
      <SignupForm
        formId={ID_FORM_ADHESION}
        onSubmit={handleSubmit}
        defaultValues={{
          email: {
            email: data.identifiants.email,
          },
          consentNewsletter: data.consents.consentNewsletter,
        }}
      />
    </ParcoursFullPageTemplate>
  );
}
