import {
  EntrepriseInformationsPersonnellesForm,
  EntrepriseInformationsPersonnellesFormValues,
} from "@src/societes/form/EntrepriseInformationsPersonnellesForm";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";

import {
  AdhesionStepEnum,
  ID_FORM_ADHESION,
  ParcoursAdhesionStepComponentProps,
} from "@src/adhesion/ParcoursAdhesion.definition";
import { ParcoursAdhesionNextAction } from "../../AdhesionActions/ParcoursAdhesionNextAction";

export function AdhesionInfoPerso({
  addData,
  changeStep,
  data,
}: ParcoursAdhesionStepComponentProps) {
  const handleSubmit = (
    submittedData: EntrepriseInformationsPersonnellesFormValues
  ) => {
    addData({ informationsPersonnelles: submittedData });
    changeStep(AdhesionStepEnum.IDENTIFIANTS);
  };
  return (
    <ParcoursFullPageTemplate
      title="Informations personnelles"
      actions={
        <ParcoursAdhesionNextAction type="submit">
          Suivant
        </ParcoursAdhesionNextAction>
      }
    >
      <EntrepriseInformationsPersonnellesForm
        onSubmit={handleSubmit}
        defaultValues={data.informationsPersonnelles}
        formId={ID_FORM_ADHESION}
      />
    </ParcoursFullPageTemplate>
  );
}
