import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as XLg } from "@src/assets/icons/x-lg.svg";
import styles from "./AutocompleteWithList.module.scss";

type TagListProps = {
  remove?: (index: number) => void;
  fields: { id: string; value: string }[];
};
export function TagList({ remove, fields }: TagListProps) {
  return (
    <div className={styles.list}>
      {fields.map((field, index) => (
        <div key={field.id} className={styles.item} data-removable={!!remove}>
          <p>{field.value}</p>
          {remove && (
            <Button
              variant="text"
              onClick={() => remove(index)}
              icon={<XLg />}
            />
          )}
        </div>
      ))}
    </div>
  );
}
