import { UIParcoursComponentProps } from "@src/parcours/Parcours.types";
import { UIParcoursFullPage } from "@src/parcours/UIParcoursFullPage";
import { useParcoursConformite } from "./ParcoursConformite";

export function UIParcoursConformite<TStepEnum extends string>(
  props: UIParcoursComponentProps<TStepEnum>
) {
  const { entreprise } = useParcoursConformite();
  return <UIParcoursFullPage {...props} title={entreprise?.raisonSociale} />;
}
