import { createContext, useContext, useMemo, useState } from "react";
import { UserSessionData, userSession } from "./UserSession";

type UserSessionContextValue = UserSessionData & {
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  refresh: () => Promise<void>;
};
export const UserSessionContext = createContext<UserSessionContextValue>(
  {} as UserSessionContextValue
);

export function UserSessionProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [session, _setSession] = useState<UserSessionData>(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    userSession.sessionData
  );

  userSession.onSetSessionData = _setSession;

  const value = useMemo(() => {
    return {
      ...session,
      login: userSession.login.bind(userSession),
      logout: userSession.logout.bind(userSession),
      refresh: userSession.refresh.bind(userSession),
    };
  }, [session]);

  return (
    <UserSessionContext.Provider value={value}>
      {children}
    </UserSessionContext.Provider>
  );
}

export const useSession = () => {
  return useContext(UserSessionContext);
};
