export enum ContactFonction {
  REPRESENTANT_LEGAL = 'REPRESENTANT_LEGAL',
  GERANT = 'GERANT',
  DIRECTEUR_GENERAL = 'DIRECTEUR_GENERAL',
  DIRECTEUR_ADMINISTRATIF = 'DIRECTEUR_ADMINISTRATIF',
  DIRECTEUR_RH = 'DIRECTEUR_RH',
  RESPONSABLE_CONFORMITE = 'RESPONSABLE_CONFORMITE',
  AUTRES = 'AUTRES',
}

export enum ContactCivilite {
  MONSIEUR = 'Monsieur',
  MADAME = 'Madame',
}

export enum ContactRole {
  ADMINISTRATEUR = 'ADMINISTRATEUR',
  RESPONSABLE_CONFORMITE = 'RESPONSABLE_CONFORMITE',
  UTILISATEUR_SIMPLE = 'UTILISATEUR_SIMPLE',
}
