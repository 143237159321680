import { GatewayCreationContact } from "@conformite/gateway";
import {
  Button,
  FormField,
  FormGrid,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import {
  EntrepriseInformationsPersonnellesForm,
  EntrepriseInformationsPersonnellesFormValues,
} from "@src/societes/form/EntrepriseInformationsPersonnellesForm";
import { useState } from "react";
import { ReactComponent as Loader } from "@src/assets/loader.svg";

const FORM_ID = "ajout-admin-pane-form";

type AjoutAdministrateurPaneContentProps = {
  onSubmit: (
    values: GatewayCreationContact.CreationContactRequestType
  ) => Promise<void>;
  defaultValues?: Partial<EntrepriseInformationsPersonnellesFormValues>;
  buttonLabel: string;
  isEdit?: boolean;
};

export function AjoutAdministrateurPaneContent({
  onSubmit,
  defaultValues = {},
  buttonLabel,
  isEdit,
}: AjoutAdministrateurPaneContentProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (
    values: EntrepriseInformationsPersonnellesFormValues
  ): Promise<void> => {
    setIsLoading(true);
    try {
      await onSubmit(
        values as GatewayCreationContact.CreationContactRequestType
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <FormGrid>
      <FormRow>
        <FormField>
          <h2>Ajouter un administrateur</h2>
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <EntrepriseInformationsPersonnellesForm
            defaultValues={defaultValues}
            formId={FORM_ID}
            onSubmit={(values) => {
              handleSubmit(values).catch((err) => {
                console.error(err);
              });
            }}
            withEmail
            disabledEmail={isEdit}
          />
        </FormField>
      </FormRow>

      <FormRow>
        <FormField>
          <InfoCard type="warning">
            Cette personne pourra visualiser et modifier toutes les informations
            de toutes les entreprises.
          </InfoCard>
        </FormField>
      </FormRow>

      <FormRow>
        <FormField>
          <Button
            form={FORM_ID}
            type="submit"
            disabled={isLoading}
            icon={isLoading ? <Loader /> : undefined}
          >
            {buttonLabel}
          </Button>
        </FormField>
      </FormRow>
    </FormGrid>
  );
}
