import { Card } from "@lya-protect/lya-protect-form-library/dist/UI";
import { SkeletonContent } from "../SkeletonContent";

type SkeletonCardProps = {
  className?: string;
  height?: number;
};
export function SkeletonCard({ className, height }: SkeletonCardProps) {
  return (
    <Card className={className}>
      <div style={{ height }}>
        <SkeletonContent />
      </div>
    </Card>
  );
}
