import { PrismicProvider, usePrismicDocumentsByUIDs } from "@prismicio/react";
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import { CmsPageTemplate } from "../CmsPageTemplate";
import { prismicClient } from "../prismic";
import { SliceBody } from "../slice/Slice.definition";
import { SliceManager } from "../slice/SliceManager";

function ServiceItemContent() {
  const { slug } = useParams<{ slug: string }>();
  const data = usePrismicDocumentsByUIDs("generique", slug ? [slug] : []);

  const body = useMemo(() => {
    if (data[0]?.results.length === 0) {
      return undefined;
    }
    return data[0]?.results[0].data as unknown as SliceBody;
  }, [data]);

  return (
    <CmsPageTemplate size="big">
      {body && <SliceManager body={body} />}
    </CmsPageTemplate>
  );
}
export function ServicePage() {
  return (
    <PrismicProvider client={prismicClient}>
      <ServiceItemContent />
    </PrismicProvider>
  );
}
