import { Form } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  FormField,
  FormRow,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { PropsWithChildren, ReactElement, useCallback, useMemo } from "react";
import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import {
  FieldValues,
  UseFormReturn,
} from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import { GatewayEntreprise, GatewayPaiement } from "@conformite/gateway";
import { useNavigate } from "react-router-dom";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { GatewayPaymentApi } from "@src/api/payment.api";
import { useStripePaiement } from "@src/adhesion/Steps/AdhesionPaiement/useStripePaiement";
import { CreationEntrepriseParams } from "@src/api/adhesion2.api";
import { storeEntrepriseCreationRequest } from "@src/payment/utils/createEntreprises";
import { VirementForm } from "./EntrepriseVirementForm";

type PaymentStripeFormProps<TValue extends FieldValues> = PropsWithChildren & {
  formConfig: UseFormReturn<TValue, unknown, undefined>;
  modePrelevement: GatewayPaiement.PaymentMethod;
  formId: string;
  entrepriseCreationParams: CreationEntrepriseParams;
  typeAdhesion: GatewayEntreprise.TypeAdhesionActive;
  setPaiementIsSubmitting?: (isSubmitting: boolean) => void;
};

function EntreprisePaymentStripeForm<TValue extends FieldValues>({
  formConfig,
  modePrelevement,
  formId,
  children,
  entrepriseCreationParams,
  typeAdhesion,
  setPaiementIsSubmitting,
}: PaymentStripeFormProps<TValue>) {
  // TODO retrieve typeAdhesion from /me
  const { onSubmit: submitStripe } = useStripePaiement(
    GatewayEntreprise.TypeAdhesionActive.GROUPE,
    entrepriseCreationParams
  );
  const navigate = useNavigate();
  const handleSubmit = useCallback(
    (values: TValue) => {
      setPaiementIsSubmitting?.(true);
      if (modePrelevement === GatewayPaiement.PaymentMethod.TRANSFER) {
        storeEntrepriseCreationRequest(typeAdhesion, entrepriseCreationParams);
        navigate(`/adhesion/creation-entreprises`);
      } else
        submitStripe().catch((error: Error) => {
          handleGatewayError({
            onUnhandled: () =>
              displayErrorToast(
                "Une erreur est survenue lors du paiement de la cotisation"
              ),
          })(error);
          console.error(error);
        });
      setPaiementIsSubmitting?.(false);
      return values;
    },
    [
      modePrelevement,
      navigate,
      submitStripe,
      typeAdhesion,
      entrepriseCreationParams,
      setPaiementIsSubmitting,
    ]
  );

  return (
    <Form formConfig={formConfig} onSubmit={handleSubmit} id={formId}>
      {children}
      {modePrelevement === GatewayPaiement.PaymentMethod.TRANSFER && (
        <VirementForm />
      )}
      {modePrelevement !== GatewayPaiement.PaymentMethod.TRANSFER && (
        <FormRow>
          <FormField>
            <PaymentElement />
          </FormField>
        </FormRow>
      )}
    </Form>
  );
}

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC);
type EntreprisePaymentStripeProps<TValue extends FieldValues> = {
  createEntrepriseParams: CreationEntrepriseParams;
  clientSecret: string;
  modePrelevement: GatewayPaiement.PaymentMethod;
  formConfig: UseFormReturn<TValue, unknown, undefined>;
  formId: string;
  typeAdhesion: GatewayEntreprise.TypeAdhesionActive;
  setPaiementIsSubmitting?: (isSubmitting: boolean) => void;
  children: ({
    updateModePrelevement,
  }: {
    updateModePrelevement: (
      paymentMethod: GatewayPaiement.PaymentMethod,
      invoiceId?: string
    ) => Promise<string | null>;
  }) => ReactElement;
};

export function EntreprisePaymentStripe<TValue extends FieldValues>({
  createEntrepriseParams,
  clientSecret,
  modePrelevement,
  formConfig,
  children,
  typeAdhesion,
  formId,
  setPaiementIsSubmitting,
}: EntreprisePaymentStripeProps<TValue>) {
  const options = useMemo<StripeElementsOptions>(
    () => ({ clientSecret }),
    [clientSecret]
  );

  const updateModePrelevementCallback = useCallback(
    (paymentMethod: GatewayPaiement.PaymentMethod, invoiceId?: string) => {
      if (!invoiceId) return Promise.resolve(null);
      return GatewayPaymentApi.updateInvoice(invoiceId, {
        paymentMethod,
      });
    },
    []
  );

  return (
    <Elements key={modePrelevement} stripe={stripePromise} options={options}>
      <EntreprisePaymentStripeForm
        setPaiementIsSubmitting={setPaiementIsSubmitting}
        entrepriseCreationParams={createEntrepriseParams}
        modePrelevement={modePrelevement}
        formConfig={formConfig}
        typeAdhesion={typeAdhesion}
        formId={formId}
      >
        {children({
          updateModePrelevement: updateModePrelevementCallback,
        })}
      </EntreprisePaymentStripeForm>
    </Elements>
  );
}
