import { GatewayPaiement } from "@conformite/gateway";
import { userSession } from "@src/session/UserSession";

export class GatewayPaymentApi {
  public static async getInvoiceInformations(
    paymentIntentId: string
  ): Promise<GatewayPaiement.InvoiceInfosResponseType> {
    const response =
      await userSession.axiosInstance.get<GatewayPaiement.InvoiceInfosResponseType>(
        `${GatewayPaiement.paymentControllerURL}/invoice-infos/${paymentIntentId}`
      );

    return response.data;
  }

  public static async updateInvoice(
    invoiceId: string,
    payload: GatewayPaiement.UpdatePaymentMethodRequestType
  ) {
    const response = await userSession.axiosInstance.patch<string>(
      `${GatewayPaiement.paymentControllerURL}/invoice/${invoiceId}`,
      payload
    );

    return response.data;
  }

  public static async getBillingPortalSession(): Promise<string> {
    const response = await userSession.axiosInstance.get<string>(
      `${GatewayPaiement.paymentControllerURL}/billing-portal-session`
    );

    return response.data;
  }

  public static async assertThatCouponExist(coupon: string) {
    await userSession.axiosInstance.get(
      `${GatewayPaiement.paymentControllerURL}/assert-coupon/${coupon}`
    );
  }
}
