import {
  Dispatch,
  ReactElement,
  SetStateAction,
  createContext,
  useState,
} from "react";

export const useDialogContextState = (): DialogContext => {
  const [dialogState, setDialog] = useState<DialogState | null>(null);
  return { dialogState, setDialog };
};

type DialogState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: (props: any) => ReactElement;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: any;
};
type DialogContext = {
  dialogState: DialogState | null;
  setDialog: Dispatch<SetStateAction<DialogState | null>>;
};
export const DialogContext = createContext<DialogContext>({
  dialogState: null,
  setDialog: () => null,
});
