import { GatewayAssujetti, GatewayContact } from "@conformite/gateway";
import {
  AssujettiParcoursConformiteEnum,
  getAvailableStepsConformite,
} from "@src/assujettis/AssujettiPaneContent/pages/AssujettiParcoursConformite/AssujettiParcoursConformite";
import { Table } from "@src/components/Table/Table";
import { ColumnDefinition } from "@src/components/Table/Table.definition";
import { useEntreprises } from "@src/store/store.entreprise";
import { useMemo } from "react";
import { ReactComponent as XLg } from "@src/assets/icons/x-lg.svg";
import {
  Button,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as Check } from "@src/assets/icons/check.svg";
import { useIsAllowed } from "@src/session/useIsAllowed";
import { ReactComponent as TrashIcon } from "@src/assets/icons/trash.svg";
import { useMillesime } from "@src/store/store.millesime";
import { useAssujetti } from "@src/store/store.assujetti";
import { ReactComponent as Pencil } from "@src/assets/icons/pencil.svg";
import { ListTemplate } from "@src/components/ListTemplate/ListTemplate";
import { FilterSearch } from "@src/components/FilterSearch/FilterSearch";
import { InputSearch } from "@src/components/InputSearch/InputSearch";
import { useAssujettiFilter } from "@src/assujettis/AssujettiMainList/useAssujettiFilter";
import { Pagination } from "@src/components/Pagination/Pagination";
import { ReactComponent as AddIcon } from "@src/assets/icons/plus-circle.svg";
import { Assujetti } from "@src/store/store.definition";
import { AssujettiPaneContentPage } from "@src/assujettis/AssujettiPaneContent/AssujettiPaneContent";
import style from "./ConformiteAssujettisList.module.scss";

type LinkUpdateAvancementProps = {
  avancement: number | null;
  onClick: () => void;
};

export function LinkUpdateAvancement({
  avancement = 0,
  onClick,
}: LinkUpdateAvancementProps) {
  return (
    <Button
      variant="text"
      onClick={onClick}
      icon={
        avancement === 100 ? (
          <Check className={style.iconSuccess} />
        ) : (
          <XLg className={style.iconError} />
        )
      }
    >
      Modifier
    </Button>
  );
}

const insertIf = (
  condition: boolean,
  value: ColumnDefinition<GatewayAssujetti.AssujettiWithAvancement>
) => (condition ? [value] : []);

type ConformiteAssujettisListProps = {
  assujettisWithAvancement: GatewayAssujetti.AssujettiWithAvancement[];
  siren: string;
  onOpenAddAssujettiPane: () => void;
  onDeleteMultipleAssujettis: (assujettis: Assujetti[]) => Promise<void>;
  onUpdatePane: (id: string, pane: AssujettiPaneContentPage) => void;
};

export function ConformiteAssujettisList({
  assujettisWithAvancement,
  siren,
  onOpenAddAssujettiPane,
  onDeleteMultipleAssujettis,
  onUpdatePane,
}: ConformiteAssujettisListProps) {
  const { entreprisesBySiren } = useEntreprises();
  const { hasAllowedRole } = useIsAllowed();
  const { selected: millesime } = useMillesime();
  const { assujettis } = useAssujetti();

  const availableStepsConformite = useMemo(() => {
    if (!entreprisesBySiren) return [];
    return getAvailableStepsConformite(entreprisesBySiren[siren]);
  }, [entreprisesBySiren, siren]);

  const {
    filtersSelected,
    setFilterSelected,
    page,
    pageSize,
    setPage,
    content,
  } = useAssujettiFilter(
    assujettisWithAvancement.filter(
      (assujetti) => assujetti.sirenCompany === siren
    )
  );

  return (
    <ListTemplate
      footer={
        <Pagination
          page={page}
          pageSize={pageSize}
          total={assujettisWithAvancement?.length || 0}
          onChange={setPage}
        />
      }
      header={
        <FilterSearch
          search={
            <InputSearch
              placeholder="Prénom Nom"
              name="search"
              value={filtersSelected?.search[0]}
              onChange={(e) => {
                setFilterSelected("search", [e.target.value]);
              }}
            />
          }
          action={
            <Button
              onClick={onOpenAddAssujettiPane}
              variant="outline"
              icon={<AddIcon />}
            >
              Ajouter une personne
            </Button>
          }
        />
      }
      isLoading={!millesime || !assujettis}
      table={
        assujettis && (
          <Table<GatewayAssujetti.AssujettiWithAvancement>
            columns={[
              {
                key: "name",
                title: "Prénom Nom",
                render: ({ name, surname }) => (
                  <span>{`${surname} ${name}`}</span>
                ),
                size: "auto",
              },
              {
                key: "honorabilite",
                title: "Honorabilité",
                render: ({ avancement, id }) => (
                  <LinkUpdateAvancement
                    onClick={() => onUpdatePane(id, "form-honorabilite")}
                    avancement={avancement.honorabilite}
                  />
                ),
                size: "auto",
              },
              ...insertIf(
                availableStepsConformite.includes(
                  AssujettiParcoursConformiteEnum.CAPACITE_PRO_IAS
                ),
                {
                  key: "capacite-pro-ias",
                  title: "Capacité pro IAS",
                  render: ({ avancement, id }) => (
                    <LinkUpdateAvancement
                      onClick={() => onUpdatePane(id, "form-capacite-pro-ias")}
                      avancement={avancement.capaciteProfessionnelleIas}
                    />
                  ),
                  size: "auto",
                }
              ),
              ...insertIf(
                availableStepsConformite.includes(
                  AssujettiParcoursConformiteEnum.CAPACITE_PRO_IOBSP
                ),
                {
                  key: "capacite-pro-iobsp",
                  title: "Capacité pro IOBSP",
                  render: ({ avancement, id }) => (
                    <LinkUpdateAvancement
                      onClick={() =>
                        onUpdatePane(id, "form-capacite-pro-iobsp")
                      }
                      avancement={avancement.capaciteProfessionnelleIobsp}
                    />
                  ),
                  size: "auto",
                }
              ),
              ...insertIf(
                availableStepsConformite.includes(
                  AssujettiParcoursConformiteEnum.FORMATION_CONTINUE_IAS
                ),
                {
                  key: "formation-continue-ias",
                  title: "Formation continue IAS",
                  render: ({ avancement, id }) => (
                    <LinkUpdateAvancement
                      onClick={() => onUpdatePane(id, "form-formation-ias")}
                      avancement={avancement.formationContinueIas}
                    />
                  ),
                  size: "auto",
                }
              ),
              ...insertIf(
                availableStepsConformite.includes(
                  AssujettiParcoursConformiteEnum.FORMATION_CONTINUE_IOBSP
                ),
                {
                  key: "formation-continue-iobsp",
                  title: "Formation continue IOBSP",
                  render: ({ avancement, id }) => (
                    <LinkUpdateAvancement
                      onClick={() => onUpdatePane(id, "form-formation-iobsp")}
                      avancement={avancement.formationContinueIobsp}
                    />
                  ),
                  size: "auto",
                }
              ),
              {
                key: "informations-assujetti",
                size: "auto",
                render: ({ id }) => (
                  <div className={style.actionsContainer}>
                    <Button
                      variant="text"
                      icon={<Pencil />}
                      onClick={() => onUpdatePane(id, "information-personne")}
                    />
                    <Button
                      variant="text"
                      icon={<TrashIcon />}
                      onClick={() => {
                        if (!assujettis[id]) return;
                        onDeleteMultipleAssujettis([assujettis[id]]).catch(
                          () => {
                            displayErrorToast(
                              "une erreur est survenue lors de la suppression"
                            );
                          }
                        );
                      }}
                    />
                  </div>
                ),
              },
            ]}
            data={content}
            selectionOptions={
              hasAllowedRole(
                GatewayContact.ContactRoleUtilisateur.ADMINISTRATEUR,
                GatewayContact.ContactRoleUtilisateur.RESPONSABLE_CONFORMITE
              )
                ? {
                    renderActions: (selectedRows) => (
                      <Button
                        iconVariant="left"
                        className={style.deleteMultipleButton}
                        icon={<TrashIcon />}
                        onClick={() => {
                          onDeleteMultipleAssujettis(selectedRows).catch(() => {
                            displayErrorToast(
                              "une erreur est survenue lors de la suppression"
                            );
                          });
                        }}
                        variant="text"
                      >
                        Supprimer les personnes
                      </Button>
                    ),
                  }
                : undefined
            }
          />
        )
      }
    />
  );
}
