import { ReactNode } from "react";
import { SkeletonTable } from "../Skeletons/table/SkeletonTable";
import styles from "./ListTemplate.module.scss";

type ListTemplateProps = {
  isLoading?: boolean;
  header: ReactNode;
  table: ReactNode;
  footer: ReactNode;
};
export function ListTemplate({
  header,
  footer,
  table,
  isLoading,
}: ListTemplateProps) {
  return (
    <div className={styles.listTemplate}>
      <div className={styles.header}>{header}</div>
      <div className={styles.table}>
        {isLoading ? <SkeletonTable size={[1, 1]} /> : table}
      </div>
      <div className={styles.footer}>{footer}</div>
    </div>
  );
}
