import { Button, Card } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as QuestionCircle } from "@src/assets/icons/question-circle.svg";
import { ReactComponent as Person } from "@src/assets/icons/person.svg";
import { ReactComponent as ChevronDown } from "@src/assets/icons/chevron-down.svg";
import { useCallback } from "react";
import { useSession } from "@src/session/UserSessionContext";
import { useNavigate } from "react-router-dom";
import { useIsAllowed } from "@src/session/useIsAllowed";
import { GatewayAuthentication } from "@conformite/gateway";
import style from "./UserMenu.module.scss";
import { Dropdown } from "../DropDown/Dropdown";
import { Link } from "../Link/Link";

export function UserMenu() {
  const session = useSession();
  const navigate = useNavigate();
  const { hasAllowedCognitoRole } = useIsAllowed();

  const handleLogout = useCallback(async () => {
    try {
      await session.logout();
    } catch (e) {
      console.error("Error while logging out", e);
    }
    navigate("/login");
  }, [session, navigate]);

  return (
    <Card className={style.menuWrapper}>
      <Button variant="outline" className={style.questionButton}>
        <Link
          to="https://aide.endya.fr/faq"
          target="_blank"
          className={style.questionButtonIcon}
        >
          <QuestionCircle />
        </Link>
      </Button>

      <Dropdown
        withIcon={false}
        variant="outline-pop"
        placement="bottom-start"
        content={
          <div className={style.actionButtonInnerWrapper}>
            <span className={style.actionPersonIcon}>
              <Person />
            </span>
            <ChevronDown className={style.chevronDownAction} />
          </div>
        }
        className={style.actionButton}
      >
        {({ close }) => (
          <div>
            <Link to="/parametres/informations-personnelles">
              <Button variant="text" onClick={close}>
                Paramètres
              </Button>
            </Link>
            {hasAllowedCognitoRole(GatewayAuthentication.Role.ENDYA) && (
              <Button
                variant="text"
                onClick={() => {
                  localStorage.removeItem("adminContactId");
                  localStorage.removeItem("adminEntreprisePrincipalId");
                  handleLogout().finally(close);
                }}
                className={style.disconnectButton}
              >
                Quitter la session
              </Button>
            )}
            <Button
              variant="text"
              onClick={() => {
                handleLogout().finally(close);
              }}
              className={style.disconnectButton}
            >
              Me déconnecter
            </Button>
          </div>
        )}
      </Dropdown>
    </Card>
  );
}
