import { Parcours } from "@src/parcours/Parcours";
import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { StepComponentProps } from "@src/parcours/Parcours.types";
import { GatewayConformite, GatewayContact } from "@conformite/gateway";
import { UIParcoursAjoutUtilisateur } from "./UIParcoursAjoutUtilisateur";
import { AjoutUtilisateurInformationsStep } from "./AjoutUtilisateurInformationsStep";
import { AjoutUtilisateurAutorisationStep } from "./AjoutUtilisateurAutorisationsStep";

export type ParcoursAjoutUtilisateurData = {
  role?: GatewayContact.ContactRoleUtilisateur;
  civilite?: GatewayContact.ContactCivilite;
  nom?: string;
  prenom?: string;
  telephone?: string;
  fonction?: GatewayContact.ContactFonction;
  email?: string;
  entreprises: string[];
  axesConformite: GatewayConformite.AxeConformite[];
};

export enum ParcoursAjoutUtilisateurStep {
  UTILISATEUR = "UTILISATEUR",
  AUTORISATIONS = "AUTORISATIONS",
}

type ParcoursAjoutUtilisateurStepProperties = {
  isEdit?: boolean;
  onSubmit: (data: ParcoursAjoutUtilisateurData) => Promise<void>;
  defaultValues?: Partial<ParcoursAjoutUtilisateurData>;
  submitButtonLabel: string;
};

export type ParcoursAjoutUtilisateurStepComponentProps = StepComponentProps<
  ParcoursAjoutUtilisateurStep,
  ParcoursAjoutUtilisateurData,
  ParcoursAjoutUtilisateurStepProperties
>;

export function ParcoursAjoutUtilisateur({
  onSubmit,
  defaultValues,
  submitButtonLabel,
  isEdit,
}: ParcoursAjoutUtilisateurStepProperties) {
  return (
    <Parcours<
      ParcoursAjoutUtilisateurStep,
      ParcoursAjoutUtilisateurData,
      ParcoursAjoutUtilisateurStepProperties
    >
      UIParcoursComponent={UIParcoursAjoutUtilisateur}
      config={{
        [ParcoursAjoutUtilisateurStep.UTILISATEUR]: {
          component: AjoutUtilisateurInformationsStep,
        },
        [ParcoursAjoutUtilisateurStep.AUTORISATIONS]: {
          component: AjoutUtilisateurAutorisationStep,
        },
      }}
      firstStep={ParcoursAjoutUtilisateurStep.UTILISATEUR}
      errorHandlers={{
        notFound: () => displayErrorToast("Impossible d'accéder à cette page"),
        notAuthorized: () =>
          displayErrorToast("Impossible d'accéder à cette page"),
      }}
      withRouter={false}
      initialData={{ entreprises: [], axesConformite: [], ...defaultValues }}
      stepProperties={{ onSubmit, submitButtonLabel, isEdit }}
    />
  );
}
