import {
  GatewayControleQuinquennal,
  GatewayEntreprise,
} from "@conformite/gateway";
import {
  displayErrorToast,
  displaySucessToast,
  displayWarningToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ControleQuinquennalApi } from "@src/api/controleQuinquennal.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { useDialog } from "@src/components/Dialog/useDialog";
import {
  DownloadedFile,
  getDownloadedFileAdmin,
} from "@src/helper/file.helper";
import { ZipEntry, zipFilesAndDownload } from "@src/helper/zip.helper";
import { userSession } from "@src/session/UserSession";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useControleQuinquennalAdmin } from "./useControleQuinquennalAdmin";

const useControleQuinquennalAxeZip = (
  axe: GatewayControleQuinquennal.ControleQuinquennalAxeWithFiles | undefined,
  entreprise: GatewayEntreprise.EntreprisePrincipaleResponseType | undefined
) => {
  return {
    handleZip: () => {
      if (!entreprise || !axe) return;
      const filesToDownload = axe.controleQuinquennalAxeFiles.map((file) =>
        getDownloadedFileAdmin(
          entreprise.siren,
          file.pathname,
          file.filename
        ).then((fileDownloaded) => ({
          file: fileDownloaded,
          axe: axe.type,
        }))
      );
      if (filesToDownload.length === 0) {
        displayWarningToast("Aucune pièce à télécharger");
        return;
      }
      Promise.all(filesToDownload)
        .then(async (responses) => {
          const filteredResponses = responses.filter(
            (file) => file.file !== null
          ) as { file: DownloadedFile; axe: string }[];
          const zipEntries: ZipEntry[] = filteredResponses.map((file) => ({
            filename: file.file.filename,
            file: file.file.file,
          }));
          await zipFilesAndDownload(zipEntries);
        })
        .catch(console.error);
    },
  };
};

export const useControleQuinquennalAdminAxe = () => {
  const { openConfirmDialog } = useDialog();
  const navigate = useNavigate();
  const [remediations, setRemediations] =
    useState<
      GatewayControleQuinquennal.ControleQuinquennalRemediationWithFileResponseType[]
    >();
  const {
    controleQuinquennal,
    axes,
    addFileToAxe,
    resetControleQuinquennalData: reset,
  } = useControleQuinquennalAdmin();

  const { idAxe } = useParams<{
    idEntreprise: string;
    idAxe: string;
  }>();
  const axe = axes.find((axeToSearch) => axeToSearch.idAxe === idAxe);

  useEffect(() => {
    if (!idAxe || !controleQuinquennal) return;
    ControleQuinquennalApi.getRemediationByAxeId(controleQuinquennal.id, idAxe)
      .then(setRemediations)
      .catch(
        handleGatewayError({
          onUnhandled: (err) => {
            console.error(err);
            displayErrorToast(
              "Une erreur inconnue est survenue lors de la récupération des remédiations"
            );
          },
        })
      );
  }, [idAxe, controleQuinquennal]);
  const { entreprise } = useControleQuinquennalAdmin();
  const { handleZip } = useControleQuinquennalAxeZip(axe, entreprise);

  const handleAddFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (!idAxe || !controleQuinquennal) return;
    const file = event.target.files?.item(0);
    if (!file) return;
    const formData = new FormData();
    formData.append("document", file);
    userSession.axiosInstance
      .post<GatewayControleQuinquennal.ControleQuinquennalAxeFile>(
        `/admin/controle-quinquennal/${controleQuinquennal.id}/axes/${idAxe}/files`,
        formData
      )
      .then((response) => {
        addFileToAxe(idAxe, response.data);
        displaySucessToast("La pièce a bien été ajoutée");
      })
      .catch(
        handleGatewayError({
          onUnhandled: (err) => {
            console.error(err);
            displayErrorToast(
              "Une erreur inconnue est survenue lors de l'ajout de la pièce"
            );
          },
        })
      );
  };
  const handleValidateAxe = () => {
    if (!axe || !controleQuinquennal || !entreprise) return;
    userSession.axiosInstance
      .put<GatewayControleQuinquennal.ControleQuinquennalAxeFile>(
        `/admin/controle-quinquennal/${controleQuinquennal.id}/axes/${axe.idAxe}/validate`
      )
      .then(() => {
        navigate(`/admin/controle-quinquennal/${entreprise.hubspotId}`);
      })
      .catch(
        handleGatewayError({
          onUnhandled: (err) => {
            console.error(err);
            displayErrorToast(
              "Une erreur inconnue est survenue lors de la validation de la conformité"
            );
          },
        })
      );
  };
  const handleDeleteRemediation = useCallback(
    async (remediationId: string) => {
      const confirm = await openConfirmDialog({
        title: "Êtes-vous sûr de vouloir supprimer cette remédiation ?",
        message: "Cette action est irréversible",
        confirmText: "Supprimer",
        cancelText: "Annuler",
      });
      if (!confirm) return;

      try {
        await ControleQuinquennalApi.deleteRemediation(remediationId);
        await reset();
      } catch (e) {
        displayErrorToast(
          "Une erreur est survenue lors de la suppression de la remédiation"
        );
        console.error(e);
      }
    },
    [openConfirmDialog, reset]
  );

  return {
    axe,
    entreprise,
    controleQuinquennal,
    remediations,
    numberFiles: axe?.controleQuinquennalAxeFiles.length ?? 0,
    handleZip,
    handleAddFile,
    handleValidateAxe,
    handleDeleteRemediation,
  };
};
