import { GatewayContact, GatewayEntreprise } from "@conformite/gateway";
import {
  displayErrorToast,
  displayInfoToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayAttestationConformiteApi } from "@src/api/attestation-conformite.api";
import {
  GatewayError,
  handleGatewayError,
} from "@src/api/utils/handleGatewayError";
import { downloadFile } from "@src/helper/file.helper";
import { useMillesime } from "@src/store/store.millesime";
import { MouseEventHandler, useCallback } from "react";
import { useIsAllowed } from "@src/session/useIsAllowed";
import { useEntrepriseAvancement } from "../useEntrepriseAvancement";

export const useDownloadAttestation = () => {
  const { selected: selectedMillesime } = useMillesime();
  const { getEntrepriseAvancement } = useEntrepriseAvancement();
  const { hasAllowedRole } = useIsAllowed();

  const getIsAllowedToDownloadAttestation = useCallback(
    (entreprise: GatewayEntreprise.EntrepriseAControllerResponseType) => {
      if (
        !hasAllowedRole(
          GatewayContact.ContactRoleUtilisateur.ADMINISTRATEUR,
          GatewayContact.ContactRoleUtilisateur.RESPONSABLE_CONFORMITE
        )
      )
        return false;
      return (
        selectedMillesime === 2023 ||
        getEntrepriseAvancement(entreprise) === 100
      );
    },
    [selectedMillesime, hasAllowedRole, getEntrepriseAvancement]
  );

  const downloadAttestation = useCallback(
    (
        entreprise: GatewayEntreprise.EntrepriseAControllerResponseType
      ): MouseEventHandler =>
      (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (!entreprise.hubspotId || !selectedMillesime) {
          displayErrorToast("Une erreur est survenue");
          return;
        }
        displayInfoToast("Génération de l'attestation d'adhésion en cours");
        GatewayAttestationConformiteApi.getDocumentAdhesion({
          idEntreprise: entreprise.hubspotId,
          millesime: selectedMillesime,
        })
          .then(({ data }) => {
            const filename = `endya_${entreprise.siren}.pdf`;
            downloadFile(data, filename);
          })
          .catch(
            handleGatewayError({
              onResponse: (error) => {
                if (error.response.status === 403) {
                  // usage of decoder caused by {responseType: "arraybuffer"} in GatewayAttestationConformiteApi.getDocumentAdhesion typeof error.response.data === ArrayBuffer
                  const textDecoder = new TextDecoder();
                  const decodedBuffer = textDecoder.decode(
                    error.response.data as unknown as ArrayBuffer
                  );
                  const gatewayError = JSON.parse(
                    decodedBuffer
                  ) as GatewayError;

                  displayErrorToast(gatewayError.message);
                } else displayErrorToast("Une erreur est survenue");
              },
              onUnhandled: (error) => {
                console.error(error);
                displayErrorToast("Une erreur est survenue");
              },
            })
          );
      },
    [selectedMillesime]
  );

  return {
    downloadAttestation,
    getIsAllowedToDownloadAttestation,
  };
};
