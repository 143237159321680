import { FormApiConformiteGarantieFinanciere } from 'form-api';

export const garantieFinanciereUrl = 'garantie-financiere';

export type ConformiteGarantieFinanciereFile =
  FormApiConformiteGarantieFinanciere.ConformiteGarantieFinanciereFile;
export type ConformiteGarantieFinanciereFileNonDiffusable =
  FormApiConformiteGarantieFinanciere.ConformiteGarantieFinanciereFileNonDiffusable;
export type ConformiteGarantieFinanciereIas =
  FormApiConformiteGarantieFinanciere.ConformiteGarantieFinanciereIas;
export type ConformiteGarantieFinanciereIobsp =
  FormApiConformiteGarantieFinanciere.ConformiteGarantieFinanciereIobsp;

export type CategorieOcrGarantieFinanciere =
  FormApiConformiteGarantieFinanciere.CategorieOcrGarantieFinanciere;
export const CategorieOcrGarantieFinanciere =
  FormApiConformiteGarantieFinanciere.CategorieOcrGarantieFinanciere;
