import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import {
  AdhesionStepEnum,
  ParcoursAdhesionStepComponentProps,
} from "@src/adhesion/ParcoursAdhesion.definition";
import { ParcoursAdhesionNextAction } from "@src/adhesion/AdhesionActions/ParcoursAdhesionNextAction";
import style from "./AdhesionCreationSuccess.module.scss";

export function AdhesionCreationSuccess({
  changeStep,
}: ParcoursAdhesionStepComponentProps) {
  return (
    <ParcoursFullPageTemplate
      actions={
        <ParcoursAdhesionNextAction
          onClick={() => changeStep(AdhesionStepEnum.TYPE_ENTREPRISE)}
        >
          Suivant
        </ParcoursAdhesionNextAction>
      }
    >
      <div className={style.root}>
        <div className={style.title}>
          <h1>
            Votre compte a bien été créé, mais{" "}
            <span>votre adhésion n&apos;est pas encore finalisée</span>
          </h1>
        </div>
        <div className={style.description}>
          <p>
            <b>
              Nous avons besoin d&apos;informations supplémentaires pour
              calculer votre cotisation et valider votre adhésion.
            </b>
          </p>
          <p>
            Vous pouvez à tout moment sauvegarder votre saisie et y revenir plus
            tard.
          </p>
        </div>
      </div>
    </ParcoursFullPageTemplate>
  );
}
