import JSZip from "jszip";
import { downloadFile } from "./file.helper";

export type ZipEntry =
  | { foldername: string; folder: ZipEntry[] }
  | { filename: string; file: ArrayBuffer };

const manageEntries = (zip: JSZip, entries: ZipEntry[]) => {
  entries.forEach((entry) => {
    if ("foldername" in entry) {
      const folder = zip.folder(entry.foldername);
      if (folder) {
        manageEntries(folder, entry.folder);
      }
    } else {
      zip.file(entry.filename, entry.file);
    }
  });
};
export const zipFilesAndDownload = async (
  entries: ZipEntry[]
): Promise<Blob> => {
  const zip = new JSZip();
  manageEntries(zip, entries);
  const content = await zip.generateAsync({ type: "blob" });
  downloadFile(content, "pieces.zip", { type: "application/zip" });
  return content;
};
