import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Form,
  InputButtonGroup,
  InputSelect,
  InputText,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayAdresse, GatewayEntreprise } from "@conformite/gateway";
import { useEffect } from "react";
import {
  formeJuridiqueOptions,
  trancheEffectifOptions,
  typeSouscripteurOptions,
} from "./EntrepriseIdentificationForm";
import {
  entrepriseAdresseSchema,
  paysOptions,
  typeVoieOptions,
} from "./EntrepriseAdresseForm";

const entrepriseAControllerIdentificationSchema = zod.object({
  siren: zod.string().nonempty(),
  raisonSociale: zod
    .string()
    .nonempty({ message: "Veuillez renseigner votre raison sociale" }),
  trancheEffectif: zod.nativeEnum(GatewayEntreprise.TrancheEffectif),
  formeJuridique: zod.nativeEnum(GatewayEntreprise.FormeJuridique),
});

const entreprisePrincipaleIdentificationSchema = zod.object({
  siren: zod.string().nonempty(),
  raisonSociale: zod
    .string()
    .nonempty({ message: "Veuillez renseigner votre raison sociale" }),
  trancheEffectif: zod.nativeEnum(GatewayEntreprise.TrancheEffectif),
  formeJuridique: zod.nativeEnum(GatewayEntreprise.FormeJuridique),
  typeSouscripteur: zod.nativeEnum(GatewayEntreprise.TypeSouscripteur),
});

const schemaAController = zod.intersection(
  entrepriseAControllerIdentificationSchema,
  entrepriseAdresseSchema
);

const schemaPrincipale = zod.intersection(
  entreprisePrincipaleIdentificationSchema,
  entrepriseAdresseSchema
);

export type EntreprisePrincipaleIdentificationEtAdresseFormValues = zod.infer<
  typeof schemaPrincipale
>;
export type EntrepriseAControllerIdentificationEtAdresseFormValues = zod.infer<
  typeof schemaAController
>;

type EntrepriseCommonIdentificationEtAdresseFormProps = {
  defaultValues: EntreprisePrincipaleIdentificationEtAdresseFormValues;
  onSubmit: (
    data: EntreprisePrincipaleIdentificationEtAdresseFormValues
  ) => void;
  formId: string;
  setIsDirty?: (isDirty: boolean) => void;
};

type EntreprisePrincipaleIdentificationEtAdresseFormProps =
  EntrepriseCommonIdentificationEtAdresseFormProps & {
    isEntrepriseAController?: boolean;
  };
type EntrepriseAControllerIdentificationEtAdresseFormProps =
  EntrepriseCommonIdentificationEtAdresseFormProps & {
    isEntrepriseAController: true;
  };

type EntrepriseIdentificationEtAdresseFormProps =
  | EntreprisePrincipaleIdentificationEtAdresseFormProps
  | EntrepriseAControllerIdentificationEtAdresseFormProps;

export function EntrepriseIdentificationEtAdresseForm({
  defaultValues,
  onSubmit,
  formId,
  setIsDirty,
  isEntrepriseAController,
}: EntrepriseIdentificationEtAdresseFormProps) {
  const formConfig = useFormConfig<
    typeof schemaPrincipale | typeof schemaAController,
    EntreprisePrincipaleIdentificationEtAdresseFormValues
  >({
    schema: isEntrepriseAController ? schemaAController : schemaPrincipale,
    defaultValues: {
      ...defaultValues,
      complementAdresse: defaultValues.complementAdresse ?? undefined,
    },
  });

  useEffect(() => {
    setIsDirty?.(formConfig.formState.isDirty);
  }, [formConfig.formState.isDirty, setIsDirty]);

  return (
    <Form formConfig={formConfig} onSubmit={onSubmit} id={formId}>
      <FormRow>
        <FormField>
          <InputText<EntreprisePrincipaleIdentificationEtAdresseFormValues>
            label="Siren"
            name="siren"
            required
            disabled
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <InputText<EntreprisePrincipaleIdentificationEtAdresseFormValues>
            label="Raison sociale"
            name="raisonSociale"
            required
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <InputSelect<
            EntreprisePrincipaleIdentificationEtAdresseFormValues,
            GatewayEntreprise.TrancheEffectif
          >
            name="trancheEffectif"
            label="Tranche effectif"
            placeholder="Sélectionner"
            options={trancheEffectifOptions}
            required
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <InputSelect<
            EntreprisePrincipaleIdentificationEtAdresseFormValues,
            GatewayEntreprise.FormeJuridique
          >
            name="formeJuridique"
            label="Forme juridique"
            placeholder="Sélectionner"
            options={formeJuridiqueOptions}
            required
          />
        </FormField>
      </FormRow>
      {!isEntrepriseAController && (
        <FormRow>
          <FormField>
            <InputButtonGroup<
              EntreprisePrincipaleIdentificationEtAdresseFormValues,
              false,
              GatewayEntreprise.TypeSouscripteur
            >
              label="Vous souscrivez en tant que"
              name="typeSouscripteur"
              variant="outline-pop"
              size="large"
              options={typeSouscripteurOptions}
              required
            />
          </FormField>
        </FormRow>
      )}
      <FormRow>
        <FormField>
          <h2>Adresse</h2>
        </FormField>
      </FormRow>

      <FormRow>
        <FormField>
          <InputSelect<
            EntreprisePrincipaleIdentificationEtAdresseFormValues,
            GatewayAdresse.TypeVoie
          >
            isSearchable
            name="typeVoie"
            label="Type de voie"
            placeholder="Sélectionner"
            options={typeVoieOptions}
            required
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <InputText<EntreprisePrincipaleIdentificationEtAdresseFormValues>
            label="Libellé de la voie"
            name="libelleVoie"
            required
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <InputText<EntreprisePrincipaleIdentificationEtAdresseFormValues>
            label="Numéro de rue"
            name="numeroVoie"
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <InputText<EntreprisePrincipaleIdentificationEtAdresseFormValues>
            label="Complément d'adresse"
            name="complementAdresse"
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <InputSelect<
            EntreprisePrincipaleIdentificationEtAdresseFormValues,
            GatewayAdresse.Pays
          >
            name="pays"
            label="Pays"
            placeholder="Sélectionner"
            options={paysOptions}
            required
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField size="quarter">
          <InputText<EntreprisePrincipaleIdentificationEtAdresseFormValues>
            label="Code postal"
            name="codePostal"
            required
          />
        </FormField>
        <FormField size="three-quarter">
          <InputText<EntreprisePrincipaleIdentificationEtAdresseFormValues>
            label="Ville"
            name="ville"
            required
          />
        </FormField>
      </FormRow>
    </Form>
  );
}
