import { GatewayContact, GatewayEntreprise } from "@conformite/gateway";
import {
  FormField,
  FormGrid,
  FormRow,
  FormTitle,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { PrismicProvider } from "@prismicio/react";
import { useAssujetisAvancement } from "@src/assujettis/useAssujettiAvancement";
import { ActualiteList } from "@src/cms/Actualite/ActualiteList";
import { useActualite } from "@src/cms/Actualite/useActualite";
import { CmsHeader } from "@src/cms/CmsHeader";
import { LeSaviezVous } from "@src/cms/LeSaviezVous/LeSaviezVous";
import { prismicClient } from "@src/cms/prismic";
import { MainPageTemplate } from "@src/components/MainPageTemplate/MainPageTemplate";
import { SkeletonCard } from "@src/components/Skeletons/card/SkeletonCard";
import { useIsAllowed } from "@src/session/useIsAllowed";
import { DownloadAttestationCard } from "@src/societes/attestation/DownloadAttestationCard";
import { useDownloadAttestation } from "@src/societes/attestation/useDownloadAttestation";
import { useEntrepriseAvancement } from "@src/societes/useEntrepriseAvancement";
import { useAssujetti } from "@src/store/store.assujetti";
import { useEntreprises } from "@src/store/store.entreprise";
import { useMe } from "@src/store/store.me";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { AxesNonValides } from "./AxesNonValides";
import { CardConformite } from "./CardConformite";
import { CardRenouvellement } from "./CardRenouvellement";
import { ControleQuinquennalIndividuel } from "./ControleQuinquennalIndividuel";
import { HomeRightSidebar } from "./HomeRightSidebar";
import { InvalidesData } from "./InvalidesData";
import styles from "./home.module.scss";

export function HomeContent() {
  const { me } = useMe();
  const { getAssujetisAvancement } = useAssujetisAvancement();
  const { assujettis } = useAssujetti();
  const { getEntrepriseAvancement } = useEntrepriseAvancement();
  const { entreprisesBySiren, status: entreprisesStatus } = useEntreprises();
  const { hasAllowedRole } = useIsAllowed();
  const { downloadAttestation, getIsAllowedToDownloadAttestation } =
    useDownloadAttestation();
  const navigate = useNavigate();

  const entreprisesList = useMemo<
    GatewayEntreprise.EntrepriseAControllerResponseType[]
  >(() => Object.values(entreprisesBySiren ?? {}), [entreprisesBySiren]);

  const conformiteOfAllEntreprisesAre100 = useMemo(
    () => entreprisesList.every(getIsAllowedToDownloadAttestation),
    [entreprisesList, getIsAllowedToDownloadAttestation]
  );
  const entreprisesLength = useMemo(
    () => Object.keys(entreprisesBySiren ?? {}).length,
    [entreprisesBySiren]
  );
  const { data: actualites } = useActualite({
    pageSize: 3,
    page: 1,
  });

  return (
    <MainPageTemplate>
      <div className={styles.page}>
        <div className={styles.pageMain}>
          <FormGrid>
            <FormTitle className={styles.title}>
              <h1>Votre conformité</h1>
            </FormTitle>
            <FormRow>
              {(!entreprisesBySiren || !assujettis) && (
                <>
                  <FormField>
                    <SkeletonCard />
                  </FormField>
                  <FormField>
                    <SkeletonCard />
                  </FormField>
                </>
              )}
              {entreprisesBySiren && assujettis && (
                <>
                  {entreprisesLength > 1 && (
                    <FormField>
                      <InvalidesData
                        getAvancement={getEntrepriseAvancement}
                        values={Object.values(entreprisesBySiren)}
                        title="Entreprises non valides"
                      />
                    </FormField>
                  )}

                  {entreprisesLength === 1 && (
                    <FormField>
                      <AxesNonValides
                        entreprise={Object.values(entreprisesBySiren ?? {})[0]}
                      />
                    </FormField>
                  )}

                  <FormField>
                    <InvalidesData
                      getAvancement={getAssujetisAvancement}
                      values={Object.values(assujettis)}
                      title="Personnes non validés"
                    />
                  </FormField>
                </>
              )}
            </FormRow>
            <ControleQuinquennalIndividuel />
            {entreprisesStatus === "loaded" &&
              !conformiteOfAllEntreprisesAre100 && (
                <FormRow>
                  <FormField>
                    <CardConformite />
                  </FormField>
                </FormRow>
              )}

            {entreprisesStatus === "loaded" &&
              conformiteOfAllEntreprisesAre100 && (
                <FormRow>
                  <FormField>
                    <DownloadAttestationCard
                      onDownload={
                        entreprisesList.length === 1
                          ? downloadAttestation(entreprisesList[0])
                          : () => navigate("/societes")
                      }
                    />
                  </FormField>
                </FormRow>
              )}

            {hasAllowedRole(
              GatewayContact.ContactRoleUtilisateur.ADMINISTRATEUR
            ) &&
              me &&
              me.entreprisePrincipale.etat ===
                GatewayEntreprise.EtatEntreprise.A_RENOUVELER && (
                <FormRow>
                  <FormField>
                    <CardRenouvellement
                      millesime={me.entreprisePrincipale.millesimeActuel}
                    />
                  </FormField>
                </FormRow>
              )}
            <CmsHeader title="S'informer" to="/actualites" />
            <ActualiteList actualites={actualites} />
            <LeSaviezVous />
          </FormGrid>
        </div>

        <HomeRightSidebar />
      </div>
    </MainPageTemplate>
  );
}
export function Home() {
  return (
    <PrismicProvider client={prismicClient}>
      <HomeContent />
    </PrismicProvider>
  );
}
