import { GatewayAssujetti } from "@conformite/gateway";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Form,
  InputRadioGroup,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  Button,
  FormField,
  FormGrid,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as DownloadIcon } from "@src/assets/icons/download.svg";
import { ReactComponent as Loader } from "@src/assets/loader.svg";
import { Dialog } from "@src/components/Dialog/Dialog";
import { FileInputButton } from "@src/components/FileInputButton/FileInputButton";
import { useMe } from "@src/store/store.me";
import { useState } from "react";
import styles from "./ImportAssujettiDialog.module.scss";
import { useImportAssujetti } from "./useImportAssujetti";

const schema = zod.object({
  clean: zod.enum(["true", "false"]),
});

type CleanFormValues = zod.infer<typeof schema>;

type WarningContentProps = {
  onCancel: () => void;
  onConfirm: (cleanOnImport: boolean) => void;
};

const WARNING_CONTENT_FORM_ID = "warning-content-form-id";
function WarningContent({ onCancel, onConfirm }: WarningContentProps) {
  const formConfig = useFormConfig<typeof schema, CleanFormValues>({
    schema,
  });

  return (
    <FormGrid>
      <FormRow>
        <FormField>
          <h3 className={styles.title}>
            Import en masse de personnes assujetties
          </h3>
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <p className={styles.message}>
            Sélectionnez l&apos;option souhaitée :
          </p>
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <Form
            id={WARNING_CONTENT_FORM_ID}
            formConfig={formConfig}
            onSubmit={({ clean }: CleanFormValues) => {
              onConfirm(clean === "true");
            }}
          >
            <InputRadioGroup<CleanFormValues>
              className={styles.radio}
              name="clean"
              size="medium"
              options={[
                {
                  label:
                    "Ecraser l’ensemble des données existantes y compris celles des autre sociétés",
                  value: "true",
                },
                {
                  label: "Ajouter à liste existante (attention aux doublons)",
                  value: "false",
                },
              ]}
            />
          </Form>
        </FormField>
      </FormRow>
      <FormRow>
        <FormField className={styles.actions}>
          <Button variant="text" onClick={onCancel}>
            Annuler
          </Button>
          <Button form={WARNING_CONTENT_FORM_ID} type="submit" variant="filled">
            Confirmer
          </Button>
        </FormField>
      </FormRow>
    </FormGrid>
  );
}

type ImportContentProps = {
  onCancel: () => void;
  onImportSuccess: (
    importedAssujettis: GatewayAssujetti.AssujettiWithAvancement[],
    clean: boolean
  ) => void;
  cleanOnImport?: boolean;
};

function ImportContent({
  onCancel,
  onImportSuccess,
  cleanOnImport = false,
}: ImportContentProps) {
  const { me } = useMe();
  const { importAssujettis, isImporting } = useImportAssujetti();

  if (!me) return <div />;
  return (
    <FormGrid>
      <FormRow>
        <FormField>
          <h3>Importer les assujettis en masse</h3>
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <p>
            Renseignez toutes les informations des personnes dans un fichier
            excel à partir d&apos;un modèle téléchargeable
          </p>
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <a
            href="/modele_personnes_assujetties.xlsx"
            target="_blank"
            className={styles.downloadLink}
          >
            <Button
              variant="text"
              icon={<DownloadIcon />}
              className={styles.download}
            >
              Télécharger le modèle
            </Button>
          </a>
        </FormField>
      </FormRow>
      <FormRow>
        <FormField className={styles.actions}>
          <Button variant="text" onClick={onCancel}>
            Précédent
          </Button>
          <FileInputButton
            disabled={isImporting}
            icon={isImporting ? <Loader /> : undefined}
            onChange={importAssujettis({
              me,
              successCallback: (assujettis) =>
                onImportSuccess(assujettis, cleanOnImport),
              clean: cleanOnImport,
            })}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          >
            Importer le fichier complété
          </FileInputButton>
        </FormField>
      </FormRow>
    </FormGrid>
  );
}

export type ImportAssujettiDialogProps = {
  onClose?: (value: string) => void;
  dialogRef: React.MutableRefObject<HTMLDialogElement | null>;
  setAssujettis: (
    assujettis: GatewayAssujetti.AssujettiWithAvancement[],
    clean: boolean
  ) => void;
};
export function ImportAssujettiDialog({
  onClose,
  dialogRef,
  setAssujettis,
}: ImportAssujettiDialogProps) {
  const [state, setState] = useState<{
    step: "warning" | "import";
    cleanOnImport: boolean;
  }>({ step: "warning", cleanOnImport: false });

  return (
    <Dialog dialogRef={dialogRef}>
      {state.step === "warning" && (
        <WarningContent
          onCancel={() => onClose?.("false")}
          onConfirm={(shouldClean) =>
            setState({
              step: "import",
              cleanOnImport: shouldClean,
            })
          }
        />
      )}
      {state.step === "import" && (
        <ImportContent
          onCancel={() => {
            setState((prev) => ({ ...prev, step: "warning" }));
          }}
          cleanOnImport={state.cleanOnImport}
          onImportSuccess={(
            importedAssujettis: GatewayAssujetti.AssujettiWithAvancement[]
          ) => {
            setAssujettis(importedAssujettis, state.cleanOnImport);
            onClose?.("true");
          }}
        />
      )}
    </Dialog>
  );
}
