import { forwardRef, useCallback, useImperativeHandle } from "react";
import {
  Form,
  InputButtonGroup,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import { GatewayInfoSocieteOrias } from "@conformite/gateway";
import { recordToSelectOptions } from "@src/adhesion/AdhesionData/adhesionData";
import { organisationProfessionnelleLabel } from "@src/adhesion/Steps/AdhesionOrganisationProfessionnelle/AdhesionOrganisationProfessionnellePage";
import { UseFormReturn } from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";

const schema = zod.object({
  organisationsProfessionnelles: zod
    .array(zod.nativeEnum(GatewayInfoSocieteOrias.OrganisationProfessionnelle))
    .min(1, {
      message:
        "Veuillez faire un choix dans les organisations professionnelles",
    }),
});

export type EntrepriseOrganisationProfessionnelleFormValues = zod.infer<
  typeof schema
>;

type EntrepriseOrganisationProfessionnelleFormProps = {
  onSubmit: (response: EntrepriseOrganisationProfessionnelleFormValues) => void;
  formId?: string;
  defaultValues: EntrepriseOrganisationProfessionnelleFormValues;
};

const organisationOptions = recordToSelectOptions(
  organisationProfessionnelleLabel
);

export type EntrepriseOrganisationProfessionnelleFormHandle = {
  getFormConfig: () => UseFormReturn<EntrepriseOrganisationProfessionnelleFormValues>;
};

export const EntrepriseOrganisationProfessionnelleForm = forwardRef<
  EntrepriseOrganisationProfessionnelleFormHandle,
  EntrepriseOrganisationProfessionnelleFormProps
>(({ defaultValues, onSubmit, formId }, ref) => {
  const formConfig = useFormConfig<
    typeof schema,
    EntrepriseOrganisationProfessionnelleFormValues
  >({
    schema,
    defaultValues,
  });

  useImperativeHandle(ref, () => ({
    getFormConfig() {
      return formConfig;
    },
  }));

  const handleChange = useCallback(
    (nextValue: GatewayInfoSocieteOrias.OrganisationProfessionnelle[]) => {
      const isPreviousContainAucun = formConfig
        .getValues("organisationsProfessionnelles")
        ?.includes(GatewayInfoSocieteOrias.OrganisationProfessionnelle.AUCUNE);
      const isNextContainAucun = nextValue.includes(
        GatewayInfoSocieteOrias.OrganisationProfessionnelle.AUCUNE
      );

      const options = { shouldValidate: true };
      if (isNextContainAucun && !isPreviousContainAucun) {
        formConfig.setValue(
          "organisationsProfessionnelles",
          [GatewayInfoSocieteOrias.OrganisationProfessionnelle.AUCUNE],
          options
        );
      } else if (isPreviousContainAucun) {
        formConfig.setValue(
          "organisationsProfessionnelles",
          nextValue.filter(
            (organisation) =>
              organisation !==
              GatewayInfoSocieteOrias.OrganisationProfessionnelle.AUCUNE
          ),
          options
        );
      } else {
        formConfig.setValue(
          "organisationsProfessionnelles",
          nextValue,
          options
        );
      }
    },
    [formConfig]
  );

  return (
    <Form formConfig={formConfig} onSubmit={onSubmit} id={formId}>
      <FormRow>
        <FormField>
          <InputButtonGroup<
            EntrepriseOrganisationProfessionnelleFormValues,
            true,
            GatewayInfoSocieteOrias.OrganisationProfessionnelle
          >
            label="Êtes-vous adhérent d'une ou plusieurs organisations professionnelles ?"
            name="organisationsProfessionnelles"
            size="large"
            variant="outline-pop"
            options={organisationOptions}
            required
            isMultiple
            onChange={handleChange}
          />
        </FormField>
      </FormRow>
    </Form>
  );
});
