import { FileInputButton, FileInputButtonProps } from "./FileInputButton";

type FileInputButtonWithDragProps = Omit<FileInputButtonProps, "onChange"> & {
  onChange: (file: File[]) => void;
};
export function FileInputButtonWithDrag({
  onChange,
  ...props
}: FileInputButtonWithDragProps) {
  return (
    <div
      onDrop={(ev) => {
        ev.preventDefault();
        const { files } = ev.dataTransfer;
        if (!files) return;
        onChange([...files]);
      }}
      onDragOver={(e) => {
        e.preventDefault();
      }}
    >
      <FileInputButton
        {...props}
        onChange={(e) => {
          const { files } = e.target;
          if (!files) return;
          onChange([...files]);
        }}
      />
    </div>
  );
}
