import { Parcours } from "@src/parcours/Parcours";
import { UIParcoursFullPage } from "@src/parcours/UIParcoursFullPage";
import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayContact } from "@conformite/gateway";
import { StepComponentProps } from "@src/parcours/Parcours.types";
import { ParcoursInvitationInformationsPersonnelles } from "./step/ParcoursInvitationInformationsPersonnelles";
import { ParcoursInvitationInformationsConnexion } from "./step/ParcoursInvitationInformationsConnexion";
import { ParcoursInvitationConfirm } from "./step/ParcoursInvitationConfirm";

export const ID_FORM_INVITATION = "id-form-invitation";

export enum ParcoursInvitationStepEnum {
  INFORMATIONS_PERSONNELLES = "informations-personnelles",
  INFORMATIONS_CONNEXION = "informations-connexion",
  CONFIRMATION_EMAIL = "confirmation",
}

export type ParcoursInvitationData = {
  civilite: GatewayContact.ContactCivilite;
  nom: string;
  prenom: string;
  telephone: string;
  fonction: GatewayContact.ContactFonction;
  email: string;
  password: string;
  consentement: boolean;
  veutRecevoirNewsletter: boolean;
};

export const ParcoursInvitationDefaultData: ParcoursInvitationData = {
  civilite: "" as GatewayContact.ContactCivilite,
  nom: "",
  prenom: "",
  telephone: "",
  fonction: "" as GatewayContact.ContactFonction,
  email: "",
  password: "",
  consentement: false,
  veutRecevoirNewsletter: false,
};

export type ParcoursInvitationStepProperties = {
  contactId: string;
  secret: string;
};

export type ParcoursInvitationComponentProps = StepComponentProps<
  ParcoursInvitationStepEnum,
  ParcoursInvitationData,
  ParcoursInvitationStepProperties
>;

type ParcoursInvitationProps = {
  initialData?: ParcoursInvitationData;
  rootPath: string;
};

export function ParcoursInvitation({
  initialData = ParcoursInvitationDefaultData,
  rootPath,
  contactId,
  secret,
}: ParcoursInvitationProps & ParcoursInvitationStepProperties) {
  return (
    <Parcours<
      ParcoursInvitationStepEnum,
      ParcoursInvitationData,
      ParcoursInvitationStepProperties
    >
      rootPath={rootPath}
      UIParcoursComponent={UIParcoursFullPage}
      config={{
        [ParcoursInvitationStepEnum.INFORMATIONS_PERSONNELLES]: {
          component: ParcoursInvitationInformationsPersonnelles,
          progress: 30,
        },
        [ParcoursInvitationStepEnum.INFORMATIONS_CONNEXION]: {
          component: ParcoursInvitationInformationsConnexion,
          progress: 60,
        },
        [ParcoursInvitationStepEnum.CONFIRMATION_EMAIL]: {
          component: ParcoursInvitationConfirm,
          progress: 90,
        },
      }}
      errorHandlers={{
        notFound: () => displayErrorToast("Impossible d'accéder à cette page"),
        notAuthorized: () =>
          displayErrorToast("Impossible d'accéder à cette page"),
      }}
      firstStep={ParcoursInvitationStepEnum.INFORMATIONS_PERSONNELLES}
      initialData={initialData}
      stepProperties={{ contactId, secret }}
    />
  );
}
