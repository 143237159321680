import {
  ConformiteGarantieFinanciereEditFormIobsp,
  useConformiteGarantieFinanciereIobspEdit,
} from "@src/conformite/garantieFinanciere/edit/ConformiteGarantieFinanciereIobspEditForm";
import { useMillesime } from "@src/store/store.millesime";
import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { useCallback, useEffect, useState } from "react";
import { GatewayGarantieFinanciere } from "@conformite/gateway";
import { GatewayGarantieFinanciereApi } from "@src/api/conformite/garantieFinanciere.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { useEntreprises } from "@src/store/store.entreprise";
import {
  ParcoursConformiteStepComponentProps,
  useParcoursConformite,
} from "../../ParcoursConformite";
import { ParcoursConformiteTemplate } from "../../ParcoursConformiteTemplate";
import { ParcoursConformiteLoaderStep } from "../../ParcoursConformiteLoaderStep";

function FormContainer({
  siren,
  changeStep,
  currentStep,
  millesime,
  garantieFinanciere,
}: ParcoursConformiteStepComponentProps & {
  millesime: number;
  garantieFinanciere: GatewayGarantieFinanciere.ConformiteGarantieFinanciereIobsp | null;
}) {
  const { entreprise } = useParcoursConformite();
  const { updateAvancement } = useEntreprises();

  const { form, onSubmit } = useConformiteGarantieFinanciereIobspEdit(
    siren,
    millesime,
    garantieFinanciere,
    (_, avancement) => updateAvancement(avancement)
  );

  const handleSubmit = useCallback(async () => {
    if (form.formState.isDirty) await onSubmit(form.getValues());
  }, [form, onSubmit]);

  return (
    <ParcoursConformiteTemplate
      currentStep={currentStep}
      changeStep={changeStep}
      isConforme={entreprise?.avancement.garantieFinanciereIobsp === 100}
      isDirty={form.formState.isDirty}
      title="Garantie financière IOBSP"
      size="large"
      onSubmit={handleSubmit}
    >
      <ConformiteGarantieFinanciereEditFormIobsp
        siren={siren}
        form={form}
        millesime={millesime}
        garantieFinanciere={garantieFinanciere}
      />
    </ParcoursConformiteTemplate>
  );
}

export function ConformiteGarantieFinanciereIobspStep({
  siren,
  changeStep,
  currentStep,
  ...props
}: ParcoursConformiteStepComponentProps) {
  const { selected: millesime } = useMillesime();
  const [garantieFinanciere, setGarantieFinanciere] =
    useState<GatewayGarantieFinanciere.ConformiteGarantieFinanciereIobsp | null>();
  useEffect(() => {
    if (!siren || !millesime) return;
    GatewayGarantieFinanciereApi.getGarantieFinanciereIobsp(siren, millesime)
      .then((data) => {
        setGarantieFinanciere(data);
      })
      .catch((error) => {
        handleGatewayError({
          onUnhandled: () => {
            displayErrorToast(
              "Une erreur est survenue lors de la récupération du formulaire"
            );
          },
        })(error);
      });
  }, [siren, millesime]);

  if (!millesime || garantieFinanciere === undefined)
    return (
      <ParcoursConformiteLoaderStep
        currentStep={currentStep}
        changeStep={changeStep}
        title="Garantie financière IOBSP"
        size="large"
      />
    );
  return (
    <FormContainer
      {...props}
      changeStep={changeStep}
      currentStep={currentStep}
      garantieFinanciere={garantieFinanciere}
      millesime={millesime}
      siren={siren}
    />
  );
}
