import {
  Button,
  Card,
  FormField,
  FormGrid,
  FormRow,
  displayErrorToast,
  displaySucessToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { useCallback, useState } from "react";
import { GatewayAuthApi } from "@src/api/auth.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import {
  UpdatePasswordForm,
  UpdatePasswordFormValues,
} from "./UpdatePasswordForm";
import style from "./UpdatePasswordCard.module.scss";

const FORM_UPDATE_PASSWORD = "form-update-password";

export function UpdatePasswordCard() {
  const [formKey, setFormKey] = useState(0);

  const handleChangePassword = useCallback(
    async (values: UpdatePasswordFormValues): Promise<void> => {
      try {
        await GatewayAuthApi.changePassword({
          previousPassword: values.previousPassword,
          proposedPassword: values.password.password,
        });
        setFormKey((prev) => prev + 1);
        displaySucessToast("Votre mot de passe à bien été modifié");
      } catch (e) {
        handleGatewayError({
          onResponse: (err) => {
            console.error(err);
            displayErrorToast(err.response.data.message);
          },
          onUnhandled: (err) => {
            console.error(err);
            displayErrorToast(
              "Une erreur est survenue lors du changement de mot de passe"
            );
          },
        })(e);
      }
    },
    []
  );

  return (
    <Card>
      <FormGrid>
        <FormRow>
          <FormField>
            <h3>Modifier mon mot de passe</h3>
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <UpdatePasswordForm
              key={formKey}
              onSubmit={handleChangePassword}
              formId={FORM_UPDATE_PASSWORD}
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField className={style.updatePasswordCardActions}>
            <Button type="submit" form={FORM_UPDATE_PASSWORD}>
              Enregistrer
            </Button>
          </FormField>
        </FormRow>
      </FormGrid>
    </Card>
  );
}
