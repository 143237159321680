import { GatewayAssujetti } from "@conformite/gateway";
import { userSession } from "@src/session/UserSession";

export class GatewayAssujettiApi {
  public static async list(
    millesime: number
  ): Promise<GatewayAssujetti.ListAssujettiWithAvancementResponseType> {
    const res =
      await userSession.axiosInstance.get<GatewayAssujetti.ListAssujettiWithAvancementResponseType>(
        `${GatewayAssujetti.listAssujettiUrl}/${millesime}`
      );

    return res.data;
  }

  public static async listAdmin(
    millesime: number,
    siren: string
  ): Promise<GatewayAssujetti.ListAssujettiWithAvancementResponseType> {
    const res =
      await userSession.axiosInstance.get<GatewayAssujetti.ListAssujettiWithAvancementResponseType>(
        `${GatewayAssujetti.listAssujettiUrl}/${millesime}/${siren}/admin`
      );

    return res.data;
  }

  public static async set(
    assujetti: GatewayAssujetti.SetAssujettiRequestType
  ): Promise<GatewayAssujetti.SetAssujettiResponseType> {
    const res =
      await userSession.axiosInstance.post<GatewayAssujetti.SetAssujettiResponseType>(
        `${GatewayAssujetti.setAssujettiUrl}`,
        { ...assujetti }
      );

    return res.data;
  }

  public static async deleteMultiple(
    assujettiIds: string[],
    millesime: number
  ): Promise<GatewayAssujetti.DeleteMultipleAssujettiResponseType> {
    const res =
      await userSession.axiosInstance.delete<GatewayAssujetti.DeleteMultipleAssujettiResponseType>(
        `${GatewayAssujetti.assujettiControllerUrl}/${millesime}`,
        { data: { ids: assujettiIds } }
      );

    return res.data;
  }
}
