import {
  UIInputCheckboxGroup,
  UIInputContainer,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { CheckboxOption } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { FilterSelection } from "./useFilterPagination";

type FilterMultiSelectProps<FilterKeys extends string> = {
  label?: string;
  name: string;
  filtersSelected: FilterSelection<FilterKeys> | null;
  setFilterSelected: (key: FilterKeys, value: string[]) => void;
  options: CheckboxOption<string>[];
  keyFitler: FilterKeys;
};
export function FilterMultiSelect<FilterKeys extends string>({
  name,
  label,
  filtersSelected,
  setFilterSelected,
  options,
  keyFitler,
}: FilterMultiSelectProps<FilterKeys>) {
  return (
    <UIInputContainer name={name} label={label} hideError>
      <UIInputCheckboxGroup
        name={name}
        hasError={false}
        value={filtersSelected?.[keyFitler] || []}
        options={options}
        onChange={(e: { target: { value: string[] } }) => {
          setFilterSelected(keyFitler, e.target.value);
        }}
      />
    </UIInputContainer>
  );
}
