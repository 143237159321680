import { Card } from "@lya-protect/lya-protect-form-library/dist/UI";
import { useMemo } from "react";
import { SkeletonCard } from "@src/components/Skeletons/card/SkeletonCard";
import { Gauge } from "@src/components/Gauge/Gauge";
import style from "./InvalidesData.module.scss";

const getInvalidesData = <T,>(values: T[], getAvancement: (v: T) => number) => {
  const avancements = values.map(getAvancement);
  return {
    total: values.length,
    nonValides: avancements.reduce(
      (acc, curr) => (curr !== 100 ? acc + 1 : acc),
      0
    ),
  };
};

type InvalideDataProps<T> = {
  title: string;
  values?: T[];
  getAvancement: (v: T) => number;
};

export function InvalidesData<T>({
  title,
  values,
  getAvancement,
}: InvalideDataProps<T>) {
  const invalidesData = useMemo(
    () => (values ? getInvalidesData(values, getAvancement) : undefined),
    [values, getAvancement]
  );

  const progression = useMemo(
    () =>
      invalidesData
        ? ((invalidesData.total - invalidesData.nonValides) /
            invalidesData.total) *
          100
        : 0,
    [invalidesData]
  );

  return (
    <>
      {!values && <SkeletonCard />}
      {values && invalidesData && (
        <Card className={style.container}>
          <h4>{title}</h4>
          <Gauge isValid={invalidesData.nonValides === 0} percent={progression}>
            <div className={style.dataWrapper}>
              <div
                className={style.dataWrapperContent}
                data-len={invalidesData.nonValides.toString().length}
              >
                <span
                  className={style.dataScore}
                  data-valid={invalidesData.nonValides === 0}
                  data-len={invalidesData.nonValides.toString().length}
                >
                  {invalidesData.nonValides}
                </span>
                <span className={style.dataTotal}>/ {invalidesData.total}</span>
              </div>
            </div>
          </Gauge>
        </Card>
      )}
    </>
  );
}
