import {
  ActiviteBancaire as ActiviteBancaireService,
  CategorieIASList as CategorieIASListService,
  CategorieIOBSPList as CategorieIOBSPListService,
  InfoSocieteOriasType as InfoSocieteOriasTypeService,
  StatutSociete as StatutSocieteService,
} from '../service/info-societe-orias.definition';

export const infoSocieteOriasUrl = 'info-societe-orias';

export type ActiviteBancaire = ActiviteBancaireService;
export const ActiviteBancaire = ActiviteBancaireService;
export type StatutSociete = StatutSocieteService;
export const StatutSociete = StatutSocieteService;

export type InfoSocieteOriasType = InfoSocieteOriasTypeService;

export type RetrieveInfoSocietesOriasResponseType = {
  bySiren: Record<string, InfoSocieteOriasType>;
  byOriasId: Record<string, InfoSocieteOriasType>;
};

export type RetrieveInfoSocietesOriasRequestType = {
  sirens: string[] | undefined;
  oriasIds: string[] | undefined;
};

export enum Categorie {
  COA = 'COA',
  MIA = 'MIA',
  COBSP = 'COBSP',
  MIOBSP = 'MIOBSP',
}

export const CategorieIASList = CategorieIASListService;
export const CategorieIOBSPList = CategorieIOBSPListService;

export enum OrganisationProfessionnelle {
  PLANETE_CSCA = 'PLANETE_CSCA',
  APIC = 'APIC',
  GCAB = 'GCAB',
  AUTRE = 'AUTRE',
  AUCUNE = 'AUCUNE',
}

export enum CategorieRepresentation {
  COURTIERS_MOINS_20_SALARIES = 'COURTIERS_MOINS_20_SALARIES',
  COURTIERS_PLUS_20_SALARIES = 'COURTIERS_PLUS_20_SALARIES',
  COURTIER_GROSSISTE = 'COURTIER_GROSSISTE',
  COURTIER_AFFINITAIRE = 'COURTIER_AFFINITAIRE',
  COMPARATEUR = 'COMPARATEUR',
  MANDATAIRE_INTERMEDIAIRE_ASSURANCE = 'MANDATAIRE_INTERMEDIAIRE_ASSURANCE',
  COURTIER_OPERATION_DE_BANQUE_ET_SERVICES_DE_PAIEMENT = 'COURTIER_OPERATION_DE_BANQUE_ET_SERVICES_DE_PAIEMENT',
  MANDATAIRE_INTERMEDIAIRE_OPERATION_DE_BANQUE_ET_SERVICE_DE_PAIEMENT = 'MANDATAIRE_INTERMEDIAIRE_OPERATION_DE_BANQUE_ET_SERVICE_DE_PAIEMENT',
}
