import {
  ConformiteDeclarationActiviteIasForm,
  useDeclarationActiviteIas,
} from "@src/conformite/declarationActivite/edit/ConformiteDeclarationActiviteIasForm";
import { useCallback, useEffect, useState } from "react";
import { useMillesime } from "@src/store/store.millesime";
import { GatewayDeclarationActivite } from "@conformite/gateway";
import { GatewayDeclarationActiviteApi } from "@src/api/conformite/declarationActivite.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { useEntreprises } from "@src/store/store.entreprise";
import {
  ParcoursConformiteStepComponentProps,
  useParcoursConformite,
} from "../../ParcoursConformite";
import { ParcoursConformiteTemplate } from "../../ParcoursConformiteTemplate";
import { ParcoursConformiteLoaderStep } from "../../ParcoursConformiteLoaderStep";

type FormContainerProps = {
  siren: string;
  millesime: number;
  declarationActivite: GatewayDeclarationActivite.DeclarationActiviteIas | null;
} & ParcoursConformiteStepComponentProps;

function FormContainer({
  declarationActivite,
  millesime,
  siren,
  changeStep,
  currentStep,
}: FormContainerProps) {
  const { entreprise } = useParcoursConformite();
  const { updateAvancement } = useEntreprises();
  const { form, onSubmit } = useDeclarationActiviteIas(
    siren,
    millesime,
    declarationActivite,
    (_, avancement) => {
      if (avancement) updateAvancement(avancement);
    }
  );

  const handleSubmit = useCallback(async () => {
    if (form.formState.isDirty) await onSubmit(form.getValues());
  }, [form, onSubmit]);

  return (
    <ParcoursConformiteTemplate
      currentStep={currentStep}
      changeStep={changeStep}
      title="Déclaration d'activités IAS"
      isConforme={entreprise?.avancement.declarationActiviteIas === 100}
      isDirty={form.formState.isDirty}
      size="large"
      onSubmit={handleSubmit}
    >
      <ConformiteDeclarationActiviteIasForm form={form} />
    </ParcoursConformiteTemplate>
  );
}

export function ConformiteDeclarationActiviteIasStep({
  siren,
  changeStep,
  currentStep,
  ...props
}: ParcoursConformiteStepComponentProps) {
  const { selected: millesime } = useMillesime();

  const [declarationActivite, setDeclarationActivite] =
    useState<GatewayDeclarationActivite.DeclarationActiviteIas | null>();
  useEffect(() => {
    if (!siren || !millesime) return;
    GatewayDeclarationActiviteApi.getIAS(siren, millesime)
      .then((data) => {
        setDeclarationActivite(data);
      })
      .catch((error) => {
        handleGatewayError({
          onUnhandled: () => {
            displayErrorToast(
              "Une erreur est survenue lors de la récupération du formulaire"
            );
          },
        })(error);
      });
  }, [siren, millesime]);

  if (!millesime || declarationActivite === undefined)
    return (
      <ParcoursConformiteLoaderStep
        currentStep={currentStep}
        changeStep={changeStep}
        title={"Déclaration d'activités IAS"}
        size="large"
      />
    );

  return (
    <FormContainer
      {...props}
      currentStep={currentStep}
      changeStep={changeStep}
      declarationActivite={declarationActivite}
      millesime={millesime}
      siren={siren}
    />
  );
}
