import { GatewayAuthentication } from "@conformite/gateway";
import { axiosInstance } from "@src/api/utils/axiosInstance";
import { captchaAxiosInstance } from "@src/api/utils/captchaAxiosInstance";
import { userSession } from "./UserSession";

export class GatewaySessionApi {
  public static async signin(
    body: GatewayAuthentication.SigninRequestType
  ): Promise<GatewayAuthentication.SigninResponseType> {
    const response =
      await captchaAxiosInstance.post<GatewayAuthentication.SigninResponseType>(
        `${GatewayAuthentication.signinRequestUrl}`,
        body
      );
    return response.data;
  }

  public static async logout(
    body: GatewayAuthentication.RevokeRequestType
  ): Promise<GatewayAuthentication.RevokeResponseType> {
    const response =
      await userSession.axiosInstance.post<GatewayAuthentication.RevokeResponseType>(
        GatewayAuthentication.revokeRequestUrl,
        body,
        {
          params: {
            noRetry: true, // prevent infinite loop on 401
          },
        }
      );
    return response.data;
  }

  public static async refresh(
    accessToken: string,
    refreshToken: string
  ): Promise<GatewayAuthentication.RefreshResponseType> {
    const response =
      await axiosInstance.post<GatewayAuthentication.RefreshResponseType>(
        GatewayAuthentication.refreshRequestUrl,
        {
          accessToken,
          refreshToken,
        }
      );
    return response.data;
  }
}
