import { GatewayCapacitePro } from "@conformite/gateway";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import { isNil } from "lodash";
import { recordToSelectOptions } from "@src/adhesion/AdhesionData/adhesionData";
import { isBeforeToday } from "@src/helper/date.helper";

export const labelsByFormCapaciteProNiveauIOBSP: Record<
  GatewayCapacitePro.CapaciteProNiveauIOBSP,
  string
> = {
  NIVEAU_1: "Niveau 1",
  NIVEAU_2: "Niveau 2",
};

export const labelsByCategorieIOBSP: Record<
  GatewayCapacitePro.CategorieIOBSP,
  string
> = {
  DIRIGEANT_COBSP: "Dirigeant d'un COBSP",
  SALARIE_COBSP: "Salarié d'un COBSP",
  DIRIGEANT_MIOBSP_PRINCIPAL:
    "Dirigeant d'un MIOBSP à titre principal (I-IOBSP)",
  SALARIE_MIOBSP_PRINCIPAL: "Salarié d'un MIOBSP à titre principal (I-IOBSP)",
  DIRIGEANT_MIOBSP_ACCESSOIRE:
    "Dirigeant d'un MIOBSP à titre accessoire (II-IOBSP)",
  SALARIE_MIOBSP_ACCESSOIRE:
    "Salarié d'un MIOBSP à titre accessoire (II-IOBSP)",
};

export const typeJustificatifAvailableByNiveauIOBSP: Record<
  GatewayCapacitePro.CapaciteProNiveauIOBSP,
  GatewayCapacitePro.TypeJustificatifIOBSP[]
> = {
  NIVEAU_1: [
    GatewayCapacitePro.TypeJustificatifIOBSP.LICENCE,
    GatewayCapacitePro.TypeJustificatifIOBSP.ECOLE_COMMERCE_NIVEAU_1,
    GatewayCapacitePro.TypeJustificatifIOBSP.DIPLOME_ETRANGER_NIVEAU_1,
    GatewayCapacitePro.TypeJustificatifIOBSP.EXP_1_AN_NIVEAU_1__FORMATION_COMP,
    GatewayCapacitePro.TypeJustificatifIOBSP
      .EXP_1_AN_NIVEAU_1__FORMATION_COMP_SALARIE,
    GatewayCapacitePro.TypeJustificatifIOBSP
      .NIVEAU_2__EXP_1_AN_NIVEAU_2__FORMATION_COMP,
    GatewayCapacitePro.TypeJustificatifIOBSP
      .NIVEAU_2__EXP_1_AN_NIVEAU_2__FORMATION_COMP_SALARIE,
    GatewayCapacitePro.TypeJustificatifIOBSP.EXP_ETRANGER__STAGE_3_MOIS,
    GatewayCapacitePro.TypeJustificatifIOBSP.STAGE_PRO_NIVEAU_1_150H,
    GatewayCapacitePro.TypeJustificatifIOBSP.STAGE_PRO_100H_CREDIT_IMMOBILIER,
    GatewayCapacitePro.TypeJustificatifIOBSP.STAGE_PRO_74H_REGROUPEMENT_CREDIT,
    GatewayCapacitePro.TypeJustificatifIOBSP.EXP_PRO_6_MOIS_SALARIE_NIVEAU_1,
  ],
  NIVEAU_2: [
    GatewayCapacitePro.TypeJustificatifIOBSP.LICENCE,
    GatewayCapacitePro.TypeJustificatifIOBSP.BTS,
    GatewayCapacitePro.TypeJustificatifIOBSP.ECOLE_COMMERCE_NIVEAU_1,
    GatewayCapacitePro.TypeJustificatifIOBSP.DIPLOME_ETRANGER_NIVEAU_1,
    GatewayCapacitePro.TypeJustificatifIOBSP.EXP_1_AN_NIVEAU_1__FORMATION_COMP,
    GatewayCapacitePro.TypeJustificatifIOBSP
      .EXP_1_AN_NIVEAU_1__FORMATION_COMP_SALARIE,
    GatewayCapacitePro.TypeJustificatifIOBSP
      .NIVEAU_2__EXP_1_AN_NIVEAU_2__FORMATION_COMP,
    GatewayCapacitePro.TypeJustificatifIOBSP
      .NIVEAU_2__EXP_1_AN_NIVEAU_2__FORMATION_COMP_SALARIE,
    GatewayCapacitePro.TypeJustificatifIOBSP.EXP_1_AN_NIVEAU_2__FORMATION_COMP,
    GatewayCapacitePro.TypeJustificatifIOBSP
      .EXP_1_AN_NIVEAU_2__FORMATION_COMP_SALARIE,
    GatewayCapacitePro.TypeJustificatifIOBSP
      .NIVEAU_3__EXP_1_AN_NIVEAU_3__FORMATION_COMP,
    GatewayCapacitePro.TypeJustificatifIOBSP
      .NIVEAU_3__EXP_1_AN_NIVEAU_3__FORMATION_COMP_SALARIE,
    GatewayCapacitePro.TypeJustificatifIOBSP.EXP_ETRANGER__STAGE_3_MOIS,
    GatewayCapacitePro.TypeJustificatifIOBSP.STAGE_PRO_NIVEAU_1_150H,
    GatewayCapacitePro.TypeJustificatifIOBSP.STAGE_PRO_NIVEAU_2_80H,
    GatewayCapacitePro.TypeJustificatifIOBSP.EXP_PRO_6_MOIS_SALARIE_NIVEAU_1,
    GatewayCapacitePro.TypeJustificatifIOBSP.EXP_PRO_6_MOIS_SALARIE_NIVEAU_2,
    GatewayCapacitePro.TypeJustificatifIOBSP.STAGE_PRO_100H_CREDIT_IMMOBILIER,
    GatewayCapacitePro.TypeJustificatifIOBSP.STAGE_PRO_74H_REGROUPEMENT_CREDIT,
  ],
};

export const labelsByTypeJustificatifIOBSP: Record<
  GatewayCapacitePro.TypeJustificatifIOBSP,
  string
> = {
  [GatewayCapacitePro.TypeJustificatifIOBSP.LICENCE]: "Licence 313/314/122/128",
  [GatewayCapacitePro.TypeJustificatifIOBSP.BTS]: "BTS 313/314/122/128",
  [GatewayCapacitePro.TypeJustificatifIOBSP.ECOLE_COMMERCE_NIVEAU_1]:
    "Ecole de commerce de niveau 1",
  [GatewayCapacitePro.TypeJustificatifIOBSP.DIPLOME_ETRANGER_NIVEAU_1]:
    "Diplôme étranger de niveau I",
  [GatewayCapacitePro.TypeJustificatifIOBSP.STAGE_PRO_NIVEAU_1_150H]:
    "Stage pro I-IOBSP de 150h",
  [GatewayCapacitePro.TypeJustificatifIOBSP.STAGE_PRO_NIVEAU_2_80H]:
    "Stage pro II-IOBSP de 80h",
  [GatewayCapacitePro.TypeJustificatifIOBSP.STAGE_PRO_100H_CREDIT_IMMOBILIER]:
    "Stage pro adapté de 100h (uniquement pour les salariés en CI)",
  [GatewayCapacitePro.TypeJustificatifIOBSP.STAGE_PRO_74H_REGROUPEMENT_CREDIT]:
    "Stage pro adapté de 74H (uniquement pour les salariés en Regroupement de crédits)",
  [GatewayCapacitePro.TypeJustificatifIOBSP.EXP_1_AN_NIVEAU_1__FORMATION_COMP]:
    "Exp pro d'1 an au cours des 3 dernières années auprès d'un IOBSP de niveau 1 + Formation complémentaire de 40h",
  [GatewayCapacitePro.TypeJustificatifIOBSP
    .NIVEAU_2__EXP_1_AN_NIVEAU_2__FORMATION_COMP]:
    "Niveau II-IOBSP + Exp pro d'1 an au cours des 3 dernières années auprès d'un IOBSP de niveau II + Formation complémentaire de 40h ",
  [GatewayCapacitePro.TypeJustificatifIOBSP
    .EXP_1_AN_NIVEAU_1__FORMATION_COMP_SALARIE]:
    "Exp pro d'1 an au cours des 3 dernières années auprès d'un IOBSP de niveau 1 + Formation adapté aux activités exercées (uniquement pour les salariés) ",
  [GatewayCapacitePro.TypeJustificatifIOBSP
    .NIVEAU_2__EXP_1_AN_NIVEAU_2__FORMATION_COMP_SALARIE]:
    "Niveau II-IOBSP + Exp pro d'1 an au cours des 3 dernières années auprès d'un IOBSP de niveau II + Formation adapté aux activités exercées (uniquement pour les salariés) ",
  [GatewayCapacitePro.TypeJustificatifIOBSP.EXP_1_AN_NIVEAU_2__FORMATION_COMP]:
    "Exp pro d'1 an au cours des 3 dernières années auprès d'un IOBSP de niveau II + Formation complémentaire de 40h",
  [GatewayCapacitePro.TypeJustificatifIOBSP
    .EXP_1_AN_NIVEAU_2__FORMATION_COMP_SALARIE]:
    "Exp pro d'1 an au cours des 3 dernières années auprès d'un IOBSP de niveau II +  Formation adapté aux activités exercées (uniquement pour les salariés) ",
  [GatewayCapacitePro.TypeJustificatifIOBSP
    .NIVEAU_3__EXP_1_AN_NIVEAU_3__FORMATION_COMP]:
    "Niveau III-IOBSP + Exp pro d'1 an au cours des 3 dernières années auprès d'un IOBSP de niveau III + Formation complémentaire de 40H",
  [GatewayCapacitePro.TypeJustificatifIOBSP
    .NIVEAU_3__EXP_1_AN_NIVEAU_3__FORMATION_COMP_SALARIE]:
    "Niveau III-IOBSP + Exp pro d'1 an au cours des 3 dernières années auprès d'un IOBSP de niveau III + Formation adapté aux activités exercées (uniquement pour les salariés) ",
  [GatewayCapacitePro.TypeJustificatifIOBSP.EXP_ETRANGER__STAGE_3_MOIS]:
    "Exp aquise à l'étranger + Stage de 3 mois ",
  [GatewayCapacitePro.TypeJustificatifIOBSP.EXP_PRO_6_MOIS_SALARIE_NIVEAU_1]:
    "Exp pro acquise sur le poste pendant 6 mois sous la responsabilité d'un membre du personnel répondant au Niveau I-IOBSP",
  [GatewayCapacitePro.TypeJustificatifIOBSP.EXP_PRO_6_MOIS_SALARIE_NIVEAU_2]:
    "Exp pro acquise sur le poste pendant 6 mois sous la responsabilité d'un membre du personnel répondant au Niveau II-IOBSP",
};

export function getTypeJustificatifOptionsIOBSP(
  selectedNiveau: GatewayCapacitePro.CapaciteProNiveauIOBSP | null | undefined
) {
  if (!selectedNiveau) return [];
  return recordToSelectOptions(
    typeJustificatifAvailableByNiveauIOBSP[selectedNiveau].reduce(
      (record, typeJustificatif) => {
        record[typeJustificatif] =
          labelsByTypeJustificatifIOBSP[typeJustificatif];
        return record;
      },
      {} as Record<GatewayCapacitePro.TypeJustificatifIOBSP, string>
    )
  );
}

export const capaciteProIOBSPSchema = zod.object({
  datePriseFonction: zod
    .date()
    .refine(
      (v) => isBeforeToday(v),
      " La date doit être inférieure à aujourd'hui"
    ),
  capacite: zod
    .object({
      conditionCapaciteProfessionnelle: zod.boolean(),
      niveau: zod
        .nativeEnum(GatewayCapacitePro.CapaciteProNiveauIOBSP)
        .nullish(),
      statutProfessionel: zod
        .nativeEnum(GatewayCapacitePro.StatusProfessionel)
        .nullish(),
      categorie: zod.nativeEnum(GatewayCapacitePro.CategorieIOBSP).nullish(),
      gradeDiplome: zod.nativeEnum(GatewayCapacitePro.GradeDiplome).nullish(),
      nomDiplome: zod.string().nullish(),
      typeJustificatif: zod
        .nativeEnum(GatewayCapacitePro.TypeJustificatifIOBSP)
        .nullish(),
    })
    .superRefine((data, ctx) => {
      if (data.conditionCapaciteProfessionnelle) {
        if (isNil(data.niveau))
          ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            path: ["niveau"],
            message: "Champ requis",
          });

        if (isNil(data.statutProfessionel))
          ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            path: ["statutProfessionel"],
            message: "Champ requis",
          });

        if (isNil(data.categorie))
          ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            path: ["categorie"],
            message: "Champ requis",
          });

        if (isNil(data.gradeDiplome))
          ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            path: ["gradeDiplome"],
            message: "Champ requis",
          });

        if (isNil(data.nomDiplome))
          ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            path: ["nomDiplome"],
            message: "Champ requis",
          });

        if (isNil(data.typeJustificatif))
          ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            path: ["typeJustificatif"],
            message: "Champ requis",
          });
      }
    }),
});
