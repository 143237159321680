import { Card } from "@lya-protect/lya-protect-form-library/dist/UI";
import {
  GatewayAvancement,
  GatewayConformiteRcPro,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { ConformiteSocieteActions } from "@src/conformite/societeActions/ConformiteSocieteActions";
import { TagConformite } from "@src/components/Tag/TagConformite";
import styles from "../ConformiteRcPro.module.scss";
import { ConformiteRcProForm } from "./ConformiteRcProForm";
import { useConformiteRcProEdit } from "./useConformiteRcProEdit";

type ConformiteRcProEditProps = {
  siren: string;
  isConforme: boolean;
  millesime: number;
  categorie: GatewayInfoSocieteOrias.Categorie;
  rcPro: GatewayConformiteRcPro.ConformiteRcProProperty | null;
  stopEditing: (
    value?: GatewayConformiteRcPro.ConformiteRcProProperty,
    avancement?: GatewayAvancement.AvancementSociete
  ) => void;
};

export function ConformiteRcProEdit({
  siren,
  isConforme,
  millesime,
  stopEditing,
  rcPro,
  categorie,
}: ConformiteRcProEditProps) {
  const {
    properties,
    formConfig,
    onSubmit,
    hasRcpIndividuelleSouscritSetToNo,
  } = useConformiteRcProEdit(siren, millesime, categorie, rcPro, stopEditing);
  return (
    <MaxWidthContent size="large" className={styles.contentEditable}>
      <Card className={styles.content}>
        <div className={styles.cardHeaderLeft}>
          <h3>Formulaire</h3>
          <TagConformite isConforme={isConforme} year={millesime} isEditing />
        </div>
        <ConformiteRcProForm
          formConfig={formConfig}
          properties={properties}
          hasRcpIndividuelleSouscritSetToNo={hasRcpIndividuelleSouscritSetToNo}
        />
        <ConformiteSocieteActions
          onClick={() => {
            onSubmit(formConfig.getValues()).catch(console.error);
          }}
          isLoading={false}
          stopEditing={() => stopEditing()}
        />
      </Card>
    </MaxWidthContent>
  );
}
