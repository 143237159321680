import { Button, Card } from "@lya-protect/lya-protect-form-library/dist/UI";
import classNames from "classnames";
import { MouseEventHandler } from "react";
import style from "./DownloadAttestationCard.module.scss";

type DownloadAttestationCardProps = {
  onDownload: MouseEventHandler;
  className?: string;
};

export function DownloadAttestationCard({
  onDownload,
  className,
}: DownloadAttestationCardProps) {
  return (
    <Card className={classNames(style.wrapper, className)}>
      <div className={style.leftPart}>
        <h4>Attestation d&apos;adhésion</h4>
        <p className={style.description}>
          Téléchargez votre attestation d&apos;adhésion pour l&apos;année en
          cours
        </p>
      </div>
      <Button variant="outline" onClick={onDownload}>
        Télécharger l&apos;attestation d&apos;adhésion
      </Button>
    </Card>
  );
}
