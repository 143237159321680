import { PropsWithChildren } from "react";
import { ReactComponent as ArrowRight } from "@src/assets/icons/arrow-right.svg";
import styles from "./FilterDropdown.module.scss";
import { Dropdown } from "../DropDown/Dropdown";

export function SortDropdown({ children }: PropsWithChildren) {
  return (
    <Dropdown
      content="Trier par"
      variant="text"
      icon={<ArrowRight />}
      className={styles.sortButton}
      dismissOnClick
    >
      {() => <div className={styles.filters}>{children}</div>}
    </Dropdown>
  );
}
