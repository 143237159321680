import {
  ParcoursConformiteTemplate,
  ParcoursConformiteTemplateProps,
} from "./ParcoursConformiteTemplate";

type ParcoursConformiteLoaderStepProps = Pick<
  ParcoursConformiteTemplateProps,
  "changeStep" | "currentStep" | "title" | "size"
>;

export function ParcoursConformiteLoaderStep({
  title,
  changeStep,
  currentStep,
  size,
}: ParcoursConformiteLoaderStepProps) {
  return (
    <ParcoursConformiteTemplate
      title={title}
      changeStep={changeStep}
      currentStep={currentStep}
      size={size}
    />
  );
}
