import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import {
  AdhesionStepEnum,
  ID_FORM_ADHESION,
  ParcoursAdhesionStepComponentProps,
} from "@src/adhesion/ParcoursAdhesion.definition";
import {
  EntrepriseIdentificationForm,
  EntrepriseIdentificationFormValues,
} from "@src/societes/form/EntrepriseIdentificationForm";
import { ParcoursAdhesionNextAction } from "@src/adhesion/AdhesionActions/ParcoursAdhesionNextAction";

export function AdhesionEntreprisePage({
  addData,
  changeStep,
  data,
}: ParcoursAdhesionStepComponentProps) {
  const handleSubmit = (submittedData: EntrepriseIdentificationFormValues) => {
    addData({ entreprise: submittedData });
    changeStep(AdhesionStepEnum.ENTREPRISE_ADRESSE);
  };
  return (
    <ParcoursFullPageTemplate
      title="Identification"
      actions={
        <ParcoursAdhesionNextAction type="submit">
          Suivant
        </ParcoursAdhesionNextAction>
      }
    >
      <EntrepriseIdentificationForm
        nonDiffusable={data.entreprise.nonDiffusable}
        onSubmit={handleSubmit}
        defaultValues={data.entreprise}
        formId={ID_FORM_ADHESION}
      />
    </ParcoursFullPageTemplate>
  );
}
