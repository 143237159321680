export function ControleQuinquennalExplainAxeRcProMia() {
  return (
    <div>
      <p>
        Les documents sollicités ci-dessous permettront de vérifier la
        couverture de l&apos;activité de MIA par une garantie RC Professionnelle
        conforme aux exigences légales (activité couverte, territoire et période
        de couverture, minimum de garantie et de franchise) souscrite
        personnellement ou par votre mandant.
      </p>
      <br />
      <p>
        Pièces à fournir mentionnant la franchise et la territorialité de la
        garantie RC Professionnelle souscrite :
      </p>
      <ul>
        <li>
          <b>
            Si vous disposez de votre propre garantie RC professionnelle MIA :
          </b>
        </li>
        <ul>
          <li>Conditions particulières</li>
          <li>ou Conditions générales</li>
          <li>
            ou tout autre document tel que le tableau de garantie ou le
            certificat d&apos;adhésion
          </li>
        </ul>
        <li>
          <b>
            Si vous bénéficiez de la couverture RC professionnelle du ou des
            mandants :
          </b>
        </li>
        <ul>
          <li>
            L&apos;ensemble de vos mandats de mandataire d&apos;intermédiaire en
            assurance (MIA) ;
          </li>
          <li>
            Pour chaque mandat : tout document mentionnant la franchise et la
            territorialité de la garantie RC Professionnelle de votre mandant
            (Conditions particulières ou conditions générales, …)
          </li>
        </ul>
      </ul>
    </div>
  );
}
