import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { RenouvellementEntrepriseParams } from "@src/api/adhesion2.api";
import { LoadingPageAfterPaiement } from "@src/components/LoadingPage/LoadingPage";
import {
  renouvellerEntreprises,
  retrieveEntrepriseCreationRequest,
} from "../../../payment/utils/createEntreprises";

export function RenouvellementAfterPaiement() {
  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const { typeAdhesion, creationEntrepriseParams } =
        retrieveEntrepriseCreationRequest();
      renouvellerEntreprises(
        typeAdhesion,
        creationEntrepriseParams as RenouvellementEntrepriseParams
      )
        .then(() => {
          navigate(`/renouvellement/remerciements${search}`);
        })
        .catch(
          handleGatewayError({
            onResponse: (err) => {
              if (err.response.data.statusCode === 409) {
                navigate(`/renouvellement/remerciements${search}`);
                return;
              }
              displayErrorToast(
                "Une erreur est survenue lors du renouvellement des entreprises"
              );
            },
            onUnhandled: () => {
              displayErrorToast(
                "Une erreur est survenue lors du renouvellement des entreprises",
                {
                  hideAfter: false,
                }
              );
            },
          })
        );
    } catch (err) {
      displayErrorToast(
        `Une erreur est survenue lors de l'envoi des entreprises : ${
          (err as Error).message
        }`,
        {
          hideAfter: false,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ minHeight: "100vh" }}>
      <LoadingPageAfterPaiement />
    </div>
  );
}
