import { GatewayImportExportAssujetti } from "@conformite/gateway";
import { typeJustificatifAvailableByCategorieIAS } from "@src/assujettis/AssujettiPaneContent/pages/AssujettiParcoursConformite/Steps/FormsCapacitePro/ConformiteFormCapaciteProIAS/formCapaciteProIAS.definition";
import { typeJustificatifAvailableByNiveauIOBSP } from "@src/assujettis/AssujettiPaneContent/pages/AssujettiParcoursConformite/Steps/FormsCapacitePro/ConformiteFormCapaciteProIOBSP/formCapaciteProIOBSP.definition";
import { toEnum } from "@src/helper/enum.helper";
import { AssujettiExcelMappedTemplate } from "../AssujettiExcelTemplate";
import {
  assujettiRoleByExcelLabelUppercase,
  categorieIASByExcelLabelUppercase,
  gradeDiplomeByExcelLabelUppercase,
  niveauIasByExcelLabelUppercase,
  statutProByExcelLabelUppercase,
  typeJustificatifIASByExcelLabelUppercase,
  categorieIOBSPByExcelLabelUppercase,
  niveauIobspByExcelLabelUppercase,
  typeJustificatifIOBSPByExcelLabelUppercase,
} from "../ImportExport.definition";

function transformAssujetti(
  mapping: AssujettiExcelMappedTemplate["assujetti"],
  millesimeActuel: number
): GatewayImportExportAssujetti.AssujettiRowImportAssujetti {
  if (
    !mapping.name ||
    !mapping.surname ||
    !mapping.sirenCompany ||
    !mapping.role
  )
    throw new Error("Missing required fields");

  return {
    email: mapping.mail,
    name: mapping.name,
    surname: mapping.surname,
    sirenCompany: mapping.sirenCompany.replaceAll(" ", "").padStart(9, "0"),
    millesime: millesimeActuel,
    role: toEnum(
      mapping.role.toUpperCase(),
      assujettiRoleByExcelLabelUppercase
    ),
  };
}

function transformHonorabilite(
  mapping: AssujettiExcelMappedTemplate["honorabilite"]
): GatewayImportExportAssujetti.AssujettiRowImportHonorabilite {
  return {
    lastVerifiedAt: mapping.dateVerif,
    isHonourable: mapping.honorable,
  };
}

function transformFormationContinueIAS(
  mapping: AssujettiExcelMappedTemplate["formationContinueIAS"]
): GatewayImportExportAssujetti.AssujettiRowImportFormationContinueIAS {
  return {
    dateDernierVerification: mapping.dateSaisie,
    formations: [
      {
        nombreHeures: mapping.formation0Heures,
        theme: mapping.formation0Theme,
      },
      {
        nombreHeures: mapping.formation1Heures,
        theme: mapping.formation1Theme,
      },
    ].filter(
      (formation) =>
        !(formation.nombreHeures === null && formation.theme === null)
    ),
  };
}

function transformFormationContinueIOBSP(
  mapping: AssujettiExcelMappedTemplate["formationContinueIOBSP"]
): GatewayImportExportAssujetti.AssujettiRowImportFormationContinueIOBSP {
  return {
    dateDernierVerification: mapping.dateSaisie,
    formations: [
      {
        nombreHeures: mapping.formation0Heures,
        theme: mapping.formation0Theme,
      },
      {
        nombreHeures: mapping.formation1Heures,
        theme: mapping.formation1Theme,
      },
    ].filter(
      (formation) =>
        !(formation.nombreHeures === null && formation.theme === null)
    ),
    heuresFormationCreditConsommation:
      mapping.heuresFormationCreditConsommation,
    heuresFormationCreditImmobilier: mapping.heuresFormationCreditImmobilier,
    heuresFormationCreditPretViagerHypotecaire:
      mapping.heuresFormationCreditPretViagerHypotecaire,
    heuresFormationCreditRegroupementCredits:
      mapping.heuresFormationCreditRegroupementCredits,
    heuresFormationCreditServicePaiement:
      mapping.heuresFormationCreditServicePaiement,
  };
}

function transformCapaciteProIAS(
  mapping: AssujettiExcelMappedTemplate["capaciteProIAS"]
): GatewayImportExportAssujetti.AssujettiRowImportCapaciteProIAS {
  if (mapping.soumisALaCondition !== true)
    return {
      datePriseFonction: mapping.datePriseFonction,
      conditionCapaciteProfessionnelle: mapping.soumisALaCondition,
      categorie: null,
      gradeDiplome: null,
      niveau: null,
      nomDiplome: null,
      statusProfessionel: null,
      typeJustificatif: null,
    };
  const mapped = {
    datePriseFonction: mapping.datePriseFonction,
    conditionCapaciteProfessionnelle: mapping.soumisALaCondition,
    categorie: toEnum(
      mapping.categorie?.toUpperCase(),
      categorieIASByExcelLabelUppercase
    ),
    gradeDiplome: toEnum(
      mapping.gradeDiplome?.toUpperCase(),
      gradeDiplomeByExcelLabelUppercase
    ),
    niveau: toEnum(
      mapping.niveau?.toUpperCase(),
      niveauIasByExcelLabelUppercase
    ),
    nomDiplome: mapping.nomDiplome,
    statusProfessionel: toEnum(
      mapping.statutPro?.toUpperCase(),
      statutProByExcelLabelUppercase
    ),
    typeJustificatif: toEnum(
      mapping.justificatif?.toUpperCase(),
      typeJustificatifIASByExcelLabelUppercase
    ),
  };
  if (
    mapped.categorie &&
    mapped.typeJustificatif &&
    !typeJustificatifAvailableByCategorieIAS[mapped.categorie].includes(
      mapped.typeJustificatif
    )
  ) {
    return {
      ...mapped,
      typeJustificatif: null,
    };
  }
  return mapped;
}

function transformCapaciteProIOBSP(
  mapping: AssujettiExcelMappedTemplate["capaciteProIOBSP"]
): GatewayImportExportAssujetti.AssujettiRowImportCapaciteProIOBSP {
  if (mapping.soumisALaCondition !== true)
    return {
      datePriseFonction: mapping.datePriseFonction,
      conditionCapaciteProfessionnelle: mapping.soumisALaCondition,
      categorie: null,
      gradeDiplome: null,
      niveau: null,
      nomDiplome: null,
      statusProfessionel: null,
      typeJustificatif: null,
    };
  const mapped = {
    datePriseFonction: mapping.datePriseFonction,
    conditionCapaciteProfessionnelle: mapping.soumisALaCondition,
    categorie: toEnum(
      mapping.categorie?.toUpperCase(),
      categorieIOBSPByExcelLabelUppercase
    ),
    gradeDiplome: toEnum(
      mapping.gradeDiplome?.toUpperCase(),
      gradeDiplomeByExcelLabelUppercase
    ),
    niveau: toEnum(
      mapping.niveau?.toUpperCase(),
      niveauIobspByExcelLabelUppercase
    ),
    nomDiplome: mapping.nomDiplome,
    statusProfessionel: toEnum(
      mapping.statutPro?.toUpperCase(),
      statutProByExcelLabelUppercase
    ),
    typeJustificatif: toEnum(
      mapping.justificatif?.toUpperCase(),
      typeJustificatifIOBSPByExcelLabelUppercase
    ),
  };
  if (
    mapped.niveau &&
    mapped.typeJustificatif &&
    !typeJustificatifAvailableByNiveauIOBSP[mapped.niveau].includes(
      mapped.typeJustificatif
    )
  ) {
    return {
      ...mapped,
      typeJustificatif: null,
    };
  }
  return mapped;
}

export function transformRowsForToAPIRows(millesimeActuel: number) {
  return (
    mapping: AssujettiExcelMappedTemplate
  ): GatewayImportExportAssujetti.AssujettiRowImport => {
    const r = {
      assujetti: transformAssujetti(mapping.assujetti, millesimeActuel),
      honorabilite: transformHonorabilite(mapping.honorabilite),
      formationContinueIAS: transformFormationContinueIAS(
        mapping.formationContinueIAS
      ),
      formationContinueIOBSP: transformFormationContinueIOBSP(
        mapping.formationContinueIOBSP
      ),
      capaciteProIAS: transformCapaciteProIAS(mapping.capaciteProIAS),
      capaciteProIOBSP: transformCapaciteProIOBSP(mapping.capaciteProIOBSP),
    };
    return r;
  };
}
