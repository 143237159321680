import { FormApiAssujetti } from 'form-api';
import { AssujettiToSet } from '../infrastructure/assujetti.definition';

export const assujettiControllerUrl = 'assujetti';
export const setAssujettiUrl = assujettiControllerUrl;

export const RoleAssujetti = FormApiAssujetti.RoleAssujetti;
export type RoleAssujetti = FormApiAssujetti.RoleAssujetti;

export type SetAssujettiResponseType = FormApiAssujetti.AssujettiDto;
export type SetAssujettiRequestType = AssujettiToSet;

export const listAssujettiUrl = assujettiControllerUrl;
export type ListAssujettiResponseType = FormApiAssujetti.AssujettiDto[];
export type ListAssujettiWithAvancementResponseType =
  FormApiAssujetti.AssujettiWithAvancement[];

export type Avancement = FormApiAssujetti.AvancementDto;
export type DeleteMultipleAssujettiRequestType = {
  ids: string[];
};
export type DeleteMultipleAssujettiResponseType = number;

export type AssujettiWithAvancement = FormApiAssujetti.AssujettiWithAvancement;
