import { PrismicImage } from "@prismicio/react";
import { SliceFactoryProps } from "../../SliceManager";
import styles from "./SliceGrosseImage.module.scss";

export function SliceGrosseImage({ slice }: SliceFactoryProps<"gross_image">) {
  return (
    <div className={styles.container}>
      <PrismicImage field={slice.primary.image} className={styles.image} />
    </div>
  );
}
