import {
  GatewayConformite,
  GatewayContact,
  GatewayEntreprise,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { Button, Card } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as ChevronLeft } from "@src/assets/icons/chevron-left.svg";
import { ReactComponent as InfoCircle } from "@src/assets/icons/info-circle.svg";
import { isIas, isIobsp } from "@src/assujettis/useAssujettiAvancement";
import { Link } from "@src/components/Link/Link";
import { MainContentPageTemplate } from "@src/components/MainPageTemplate/MainContentPageTemplate";
import { MillesimeSelect } from "@src/components/MillesimeSelect/MillesimeSelect";
import { SkeletonCard } from "@src/components/Skeletons/card/SkeletonCard";
import { TagConformite } from "@src/components/Tag/TagConformite";
import { activitesFromEntreprise } from "@src/helper/entreprise.helper";
import {
  useHasForbiddenTypeAdhesion,
  useIsAllowed,
} from "@src/session/useIsAllowed";
import { useEntreprises } from "@src/store/store.entreprise";
import { useMillesime } from "@src/store/store.millesime";
import { useParams } from "react-router-dom";
import { DownloadAttestationCard } from "../attestation/DownloadAttestationCard";
import { useDownloadAttestation } from "../attestation/useDownloadAttestation";
import { categorieToAvancementKey } from "../societe.helper";
import { useEntrepriseAvancement } from "../useEntrepriseAvancement";
import styles from "./Societe.module.scss";

const activitesOrder: Record<GatewayInfoSocieteOrias.Categorie, number> = {
  [GatewayInfoSocieteOrias.Categorie.COA]: 1,
  [GatewayInfoSocieteOrias.Categorie.MIA]: 2,
  [GatewayInfoSocieteOrias.Categorie.COBSP]: 3,
  [GatewayInfoSocieteOrias.Categorie.MIOBSP]: 4,
};
type CardConformiteProps = {
  conformite: string;
  isConforme: boolean;
  to: string;
  millesime: number;
};
function CardConformite({
  conformite,
  isConforme,
  to,
  millesime,
}: CardConformiteProps) {
  return (
    <Card className={styles.cardConformite}>
      <h4>{conformite}</h4>
      <div className={styles.cardConformiteRight}>
        <TagConformite isConforme={isConforme} year={millesime} />
        <Link to={to}>Accéder</Link>
      </div>
    </Card>
  );
}

function CardConformiteTunnel() {
  return (
    <Card className={styles.cardConformiteTunnel}>
      <div className={styles.cardConformiteTunnelLeftPart}>
        <h3>Formulaire de conformité</h3>
        <p className={styles.cardConformiteTunnelDescription}>
          Renseignez votre conformité dans un tunnel unique
        </p>
      </div>
      <Link to="conformite">
        <Button variant="outline">Accéder au formulaire</Button>
      </Link>
    </Card>
  );
}

const axeRCProByCategorie: Record<
  GatewayInfoSocieteOrias.Categorie,
  GatewayConformite.AxeConformite
> = {
  [GatewayInfoSocieteOrias.Categorie.COA]:
    GatewayConformite.AxeConformite.RC_PRO_COA,
  [GatewayInfoSocieteOrias.Categorie.COBSP]:
    GatewayConformite.AxeConformite.RC_PRO_COBSP,
  [GatewayInfoSocieteOrias.Categorie.MIA]:
    GatewayConformite.AxeConformite.RC_PRO_MIA,
  [GatewayInfoSocieteOrias.Categorie.MIOBSP]:
    GatewayConformite.AxeConformite.RC_PRO_MIOBSP,
};

type SocieteProps = {
  societe?: GatewayEntreprise.EntrepriseAControllerResponseType;
  isLoading: boolean;
  millesime: number;
};
export function Societe({ societe, isLoading, millesime }: SocieteProps) {
  const { hasAllowedAxeConformite } = useIsAllowed();
  const { getEntrepriseAvancementForAssujettiAxe } = useEntrepriseAvancement();
  const { downloadAttestation, getIsAllowedToDownloadAttestation } =
    useDownloadAttestation();
  const { selected } = useMillesime();

  if (isLoading)
    return (
      <div>
        <SkeletonCard className={styles.conformiteForm} />
        <div className={styles.conformites}>
          <SkeletonCard />
          <SkeletonCard />
        </div>
      </div>
    );

  if (!societe) {
    return (
      <div className={styles.infoNoSociete}>
        <InfoCircle className={styles.infoNoSocieteIcon} />
        <p>
          Cette société n&apos;a pas été enregistrée sur l&apos;année {selected}
        </p>
      </div>
    );
  }

  const societeCategories = activitesFromEntreprise(societe).sort(
    (activite1, activite2) =>
      activitesOrder[activite1] - activitesOrder[activite2]
  );

  return (
    <div>
      <CardConformiteTunnel />
      {getIsAllowedToDownloadAttestation(societe) && (
        <DownloadAttestationCard
          onDownload={downloadAttestation(societe)}
          className={styles.downloadAttestation}
        />
      )}
      <div className={styles.conformites}>
        {hasAllowedAxeConformite(
          GatewayConformite.AxeConformite.HONORABILITE
        ) && (
          <CardConformite
            conformite="Honorabilité"
            isConforme={
              getEntrepriseAvancementForAssujettiAxe(
                societe,
                "honorabilite"
              ) === 100
            }
            millesime={millesime}
            to={`/conformite/honorabilite/${societe.siren}`}
          />
        )}
        {isIas(societeCategories) &&
          hasAllowedAxeConformite(
            GatewayConformite.AxeConformite.CAPACITE_PRO_IAS
          ) && (
            <CardConformite
              conformite="Capacité professionnelle IAS"
              isConforme={
                getEntrepriseAvancementForAssujettiAxe(
                  societe,
                  "capaciteProfessionnelleIas"
                ) === 100
              }
              millesime={millesime}
              to={`/conformite/capacite-professionnelle/ias/${societe.siren}`}
            />
          )}
        {isIobsp(societeCategories) &&
          hasAllowedAxeConformite(
            GatewayConformite.AxeConformite.CAPACITE_PRO_IOBSP
          ) && (
            <CardConformite
              conformite="Capacité professionnelle IOBSP"
              isConforme={
                getEntrepriseAvancementForAssujettiAxe(
                  societe,
                  "capaciteProfessionnelleIobsp"
                ) === 100
              }
              millesime={millesime}
              to={`/conformite/capacite-professionnelle/iobsp/${societe.siren}`}
            />
          )}
        {isIas(societeCategories) &&
          hasAllowedAxeConformite(
            GatewayConformite.AxeConformite.FORMATION_CONTINUE_IAS
          ) && (
            <CardConformite
              conformite="Formation continue IAS"
              isConforme={
                getEntrepriseAvancementForAssujettiAxe(
                  societe,
                  "formationContinueIas"
                ) === 100
              }
              millesime={millesime}
              to={`/conformite/formation-continue/ias/${societe.siren}`}
            />
          )}
        {isIobsp(societeCategories) &&
          hasAllowedAxeConformite(
            GatewayConformite.AxeConformite.FORMATION_CONTINUE_IOBSP
          ) && (
            <CardConformite
              conformite="Formation continue IOBSP"
              isConforme={
                getEntrepriseAvancementForAssujettiAxe(
                  societe,
                  "formationContinueIobsp"
                ) === 100
              }
              millesime={millesime}
              to={`/conformite/formation-continue/iobsp/${societe.siren}`}
            />
          )}
        {societeCategories?.map(
          (categorie: GatewayInfoSocieteOrias.Categorie) =>
            hasAllowedAxeConformite(axeRCProByCategorie[categorie]) && (
              <CardConformite
                key={categorie}
                conformite={`RC pro ${categorie}`}
                isConforme={
                  societe.avancement[categorieToAvancementKey[categorie]] ===
                  100
                }
                millesime={millesime}
                to={`/conformite/rc-pro/${categorie.toLowerCase()}/${
                  societe.siren
                }`}
              />
            )
        )}
        {isIas(societeCategories) &&
          hasAllowedAxeConformite(
            GatewayConformite.AxeConformite.GARANTIE_FINANCIERE_IAS
          ) && (
            <CardConformite
              conformite="Garantie financière IAS"
              isConforme={societe.avancement.garantieFinanciereIas === 100}
              millesime={millesime}
              to={`/conformite/garantie-financiere/ias/${societe.siren}`}
            />
          )}
        {isIobsp(societeCategories) &&
          hasAllowedAxeConformite(
            GatewayConformite.AxeConformite.GARANTIE_FINANCIERE_IOBSP
          ) && (
            <CardConformite
              conformite="Garantie financière IOBSP"
              isConforme={societe.avancement.garantieFinanciereIobsp === 100}
              millesime={millesime}
              to={`/conformite/garantie-financiere/iobsp/${societe.siren}`}
            />
          )}
        {isIas(societeCategories) &&
          hasAllowedAxeConformite(
            GatewayConformite.AxeConformite.DECLARATION_ACTIVITE_IAS
          ) && (
            <CardConformite
              conformite="Déclaration activité IAS"
              isConforme={societe.avancement.declarationActiviteIas === 100}
              millesime={millesime}
              to={`/conformite/declaration-activite/ias/${societe.siren}`}
            />
          )}
        {isIobsp(societeCategories) &&
          hasAllowedAxeConformite(
            GatewayConformite.AxeConformite.DECLARATION_ACTIVITE_IOBSP
          ) && (
            <CardConformite
              conformite="Déclaration activité IOBSP"
              isConforme={societe.avancement.declarationActiviteIobsp === 100}
              millesime={millesime}
              to={`/conformite/declaration-activite/iobsp/${societe.siren}`}
            />
          )}
      </div>
    </div>
  );
}

export function SocieteContainer() {
  const hasForbiddenTypeAdhesion = useHasForbiddenTypeAdhesion();
  const { selected: millesime } = useMillesime();
  const { entreprisesBySiren, status } = useEntreprises();
  const { id } = useParams<{ id: string }>();
  const { hasAllowedRole } = useIsAllowed();
  const societe = id && entreprisesBySiren ? entreprisesBySiren[id] : undefined;

  return (
    <MainContentPageTemplate
      title={
        <div className={styles.header}>
          <div className={styles.title}>
            <h1>{societe?.raisonSociale}</h1>
            {societe &&
              hasAllowedRole(
                GatewayContact.ContactRoleUtilisateur.ADMINISTRATEUR
              ) && (
                <Link
                  to={`/societes/${societe.siren}/informations`}
                  className={styles.titleLink}
                >
                  Informations
                </Link>
              )}
          </div>
          <MillesimeSelect />
        </div>
      }
      actionNavigation={
        hasForbiddenTypeAdhesion([
          GatewayEntreprise.TypeAdhesion.SOCIETE_INDIVIDUELLE,
        ]) ? null : (
          <Link to="/societes">
            <Button variant="text" icon={<ChevronLeft />}>
              Retour
            </Button>
          </Link>
        )
      }
    >
      {millesime && (
        <Societe
          isLoading={status !== "loaded"}
          societe={societe}
          millesime={millesime}
        />
      )}
    </MainContentPageTemplate>
  );
}
