import { GatewayAssujetti, GatewayHonorabilite } from "@conformite/gateway";
import { AxiosResponse } from "axios";
import { nullableDateStringToDate } from "@src/helper/date.helper";
import { transformKeysInObject } from "@src/helper/object.helper";
import { userSession } from "@src/session/UserSession";

function apiResponseToHonorabilite(
  response: AxiosResponse<NestedSwapDatesWithStrings<GatewayHonorabilite.Honorabilite> | null>
): GatewayHonorabilite.Honorabilite | null {
  if (!response.data) {
    return null;
  }
  return transformKeysInObject(
    response.data,
    ["lastVerifiedAt"],
    nullableDateStringToDate
  ) as GatewayHonorabilite.Honorabilite;
}

export class GatewayHonorabiliteApi {
  public static async get(
    idAssujetti: string,
    millesime: number
  ): Promise<GatewayHonorabilite.Honorabilite | null> {
    return userSession.axiosInstance
      .get<NestedSwapDatesWithStrings<GatewayHonorabilite.Honorabilite> | null>(
        `${GatewayHonorabilite.honorabiliteUrl}/${idAssujetti}/${millesime}`
      )
      .then(apiResponseToHonorabilite);
  }

  public static async getAdmin(
    idAssujetti: string,
    millesime: number
  ): Promise<GatewayHonorabilite.Honorabilite | null> {
    return userSession.axiosInstance
      .get<NestedSwapDatesWithStrings<GatewayHonorabilite.Honorabilite> | null>(
        `${GatewayHonorabilite.honorabiliteUrl}/${idAssujetti}/${millesime}/admin`
      )
      .then(apiResponseToHonorabilite);
  }

  public static async save(
    idAssujetti: string,
    millesime: number,
    form: GatewayHonorabilite.Honorabilite
  ): Promise<GatewayAssujetti.Avancement | null> {
    const res =
      await userSession.axiosInstance.post<GatewayAssujetti.Avancement | null>(
        `${GatewayHonorabilite.honorabiliteUrl}/${idAssujetti}/${millesime}`,
        form
      );
    return res.data;
  }
}
