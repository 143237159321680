import { GatewayAssujetti } from "@conformite/gateway";

export const RoleAssujettiLabels: Record<
  GatewayAssujetti.RoleAssujetti,
  string
> = {
  REPRESENTANT_LEGAL: "Représentant légal de la structure",
  ASSOCIE: "Associé",
  DIRECTEUR_GENERAL: "Directeur général",
  DIRECTEUR_ADMINISTRATIF: "Directeur/responsable Administratif",
  DIRECTEUR_CONFORMITE: "Directeur conformité/juridique",
  DIRECTEUR_MARKETING: "Directeur Marketing / Communication",
  DIRECTEUR_COMMERCIAL: "Directeur/Responsable commercial",
  CHARGE_CLIENTELE: "Chargé de clientèle",
  JURISTE: "Souscripteur/juriste",
  GESTIONNAIRE: "Gestionnaire",
  CHEF_DE_PROJET: "Chef de projet",
  RESPONSABLE_BUREAU: "Responsable de bureau",
  COMMERCE: "Commerce",
  GESTION: "Gestion",
  BACK_OFFICE: "Back office",
} as const;
