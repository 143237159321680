import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import classNames from "classnames";
import { ReactComponent as ChevronLeft } from "@src/assets/icons/chevron-left.svg";
import { ReactComponent as ChevronRight } from "@src/assets/icons/chevron-right.svg";
import styles from "./Pagination.module.scss";

type PaginationProps = {
  total: number;
  page: number;
  pageSize: number;
  onChange: (page: number) => void;
  className?: string;
};

export function Pagination({
  total,
  page,
  pageSize,
  onChange,
  className,
}: PaginationProps) {
  return (
    <div className={classNames(styles.pagination, className)}>
      <Button
        onClick={() => onChange(page - 1)}
        disabled={page === 0}
        icon={<ChevronLeft />}
        variant="outline-pop"
        className={styles.action}
      />
      <span>{`${page * pageSize + 1}-${Math.min(
        (page + 1) * pageSize,
        total
      )} sur ${total}`}</span>
      <Button
        onClick={() => onChange(page + 1)}
        disabled={total === 0 || page === Math.ceil(total / pageSize) - 1}
        icon={<ChevronRight />}
        variant="outline-pop"
        className={styles.action}
      />
    </div>
  );
}
