import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSession } from "@src/session/UserSessionContext";
import { ParcoursErrorHandlers, StepConfigMap } from "./Parcours.types";

class Value<T> {
  _value: T;

  constructor(value: T) {
    this._value = value;
  }

  public get value(): T {
    return this._value;
  }

  public set value(v: T) {
    this._value = v;
  }
}

function useValueInstance<T>(initialValue: T) {
  const [valueInstance] = useState(new Value(initialValue));
  return {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    get: () => valueInstance.value,
    set: (v: T) => {
      valueInstance.value = v;
    },
  };
}

export function useParcoursData<TData>(initialData: TData) {
  const [data, _setData] = useState<TData>(initialData);
  const { get, set } = useValueInstance(initialData);

  function setData(nextData: TData) {
    set(nextData);
    _setData(nextData);
  }

  function addData(partialData: Partial<TData>) {
    setData({ ...data, ...partialData });
  }

  return { data, setData, addData, getData: get };
}

function updateHistory<TPath extends string>(
  entry: TPath,
  history: TPath[],
  setHistory: (history: TPath[]) => void
) {
  if (history.includes(entry)) {
    setHistory(history.slice(0, history.indexOf(entry) + 1));
  } else {
    setHistory([...history, entry]);
  }
}
type UseParcoursNavigationReturn = {
  pathName: string | undefined;
  navigate: (nextPathName: string) => void;
};

function useParcoursNavigation<TPath extends string>(
  withRouter: boolean,
  firstStep: TPath,
  rootPath = ""
): UseParcoursNavigationReturn {
  const [fakePath, setFakePath] = useState<string>(firstStep);
  const navigate = useNavigate();
  const { "*": pathName } = useParams();

  return {
    navigate: withRouter
      ? (nextPathName: string) => {
          navigate(`${rootPath}/${nextPathName}`);
        }
      : setFakePath,
    pathName: withRouter ? pathName : fakePath,
  };
}

export function useNavigateOnLocationChange<
  TPath extends string,
  TData,
  TStepProperties extends Record<string, unknown>
>(
  pathName: string | undefined,
  navigate: (pathName: string) => void,
  activeStepPath: TPath,
  setActiveStepPath: (step: TPath) => void,
  config: StepConfigMap<TPath, TData, TStepProperties>,
  errorHandlers: ParcoursErrorHandlers,
  defaultHistory?: TPath[] | null
) {
  const firstMountRef = useRef(true);
  const session = useSession();
  const [history, setHistory] = useState<TPath[]>(
    defaultHistory || [activeStepPath]
  );
  useEffect(() => {
    if (firstMountRef.current) {
      if (config[activeStepPath].protected && !session.isConnected) {
        errorHandlers.notAuthorized();
      } else {
        navigate(activeStepPath);
      }
      firstMountRef.current = false;
    } else if (!pathName || !Object.keys(config).includes(pathName)) {
      errorHandlers.notFound();
    } else if (config[pathName as TPath].protected && !session.isConnected) {
      errorHandlers.notAuthorized();
    } else if (
      history.includes(pathName as TPath) &&
      history.indexOf(pathName as TPath) !== history.length - 1 &&
      config[activeStepPath].allowPrevious === false
    ) {
      errorHandlers.notFound();
    } else {
      setActiveStepPath(pathName as TPath);
      updateHistory(pathName as TPath, history, setHistory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName]);

  return {
    navigating: pathName !== activeStepPath,
    history,
  };
}

export function useActiveStepConfig<
  TPath extends string,
  TData,
  TStepProperties extends Record<string, unknown>
>(
  firstStep: TPath,
  defaultHistory: TPath[] | undefined | null,
  errorHandlers: ParcoursErrorHandlers,
  config: StepConfigMap<TPath, TData, TStepProperties>,
  withRouter: boolean,
  rootPath?: string
) {
  const { navigate, pathName } = useParcoursNavigation(
    withRouter,
    firstStep,
    rootPath
  );

  const [activeStepPath, setActiveStepPath] = useState<TPath>(firstStep);

  const { history, navigating } = useNavigateOnLocationChange(
    pathName,
    navigate,
    activeStepPath,
    setActiveStepPath,
    config,
    errorHandlers,
    defaultHistory
  );

  function changeStep(step: TPath) {
    navigate(step);
  }
  function changeToPrevStep() {
    if (history.length < 2) return;
    navigate(history[history.length - 2]);
  }

  return {
    ...config[activeStepPath],
    activeStepPath,
    history,
    navigating,
    changeStep,
    changeToPrevStep,
  };
}
