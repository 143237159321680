export class RowError extends Error {
  row: number;

  col?: string;

  constructor(message: string, row: number | string, col?: string) {
    super(message);
    this.row = Number(row);
    this.col = col;
    Object.setPrototypeOf(this, RowError.prototype);
  }
}
