import { FormApiDeclarationActivite } from 'form-api';
import { IasIobsp } from '../../common/controller/conformite.definition';

export const declarationActiviteUrl = (
  siren = ':siren',
  millesime?: number,
  categorie?: IasIobsp,
) =>
  `/declaration-activite/${siren}/${millesime ?? ':millesime'}/${
    categorie ?? ':categorie'
  }`;

export type DeclarationActiviteIas =
  FormApiDeclarationActivite.DeclarationActiviteIas;
export type DeclarationActiviteIobsp =
  FormApiDeclarationActivite.DeclarationActiviteIobsp;
export const ActiviteIas = FormApiDeclarationActivite.ActiviteIas;
export type ActiviteIas = FormApiDeclarationActivite.ActiviteIas;
export const ActiviteIobsp = FormApiDeclarationActivite.ActiviteIobsp;
export type ActiviteIobsp = FormApiDeclarationActivite.ActiviteIobsp;
export type DeclarationActiviteOrganisation =
  FormApiDeclarationActivite.DeclarationActiviteOrganisation;
export const OrganisationType = FormApiDeclarationActivite.OrganisationType;
export type OrganisationType = FormApiDeclarationActivite.OrganisationType;
