export enum ExcelPropertyType {
  date = "DATE",
  string = "STRING",
  boolean = "BOOLEAN",
  number = "NUMBER",
}

export type ExcelCellValueByPropertyType = {
  [ExcelPropertyType.date]: Date;
  [ExcelPropertyType.string]: string;
  [ExcelPropertyType.boolean]: string;
  [ExcelPropertyType.number]: number;
};

export type MappedTypeByExcelPropertyType = {
  [ExcelPropertyType.date]: Date;
  [ExcelPropertyType.string]: string;
  [ExcelPropertyType.boolean]: boolean;
  [ExcelPropertyType.number]: number;
};

export type MappedType = MappedTypeByExcelPropertyType[ExcelPropertyType];

export type ExcelTemplate = {
  firstRowIndex: number;
  categories: {
    [key: string]: TemplateCategory;
  };
};
export type TemplateCategory = {
  columns: {
    [key: string]: TemplateColumn;
  };
};
export type TemplateColumn = {
  col: string;
  type: ExcelPropertyType;
};

export type ExportCellTemplate = {
  path: string;
  type: ExcelPropertyType;
} | null;

export type MappedExcelTemplateCategory<T extends TemplateCategory> = {
  [properties in keyof T["columns"]]:
    | MappedTypeByExcelPropertyType[T["columns"][properties]["type"]]
    | null;
};
export type MappedExcelTemplate<T extends ExcelTemplate> = {
  [category in keyof T["categories"]]: MappedExcelTemplateCategory<
    T["categories"][category]
  >;
};
