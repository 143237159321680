import {
  Button,
  Card,
  displaySucessToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { Link } from "@src/components/Link/Link";
import { ControleQuinquennalTemplate } from "@src/controleQuinquennal/ControleQuinquenalTemplate";
import { ReactComponent as ChevronLeft } from "@src/assets/icons/chevron-left.svg";
import { ReactComponent as PlusCircle } from "@src/assets/icons/plus-circle.svg";
import { useParams } from "react-router-dom";
import { useDialog } from "@src/components/Dialog/useDialog";
import { FileInputButtonWithDrag } from "@src/components/FileInputButton/FileInputButtonWithDrag";
import { useState } from "react";
import { FileRead } from "@src/components/FileRead/FileRead";
import { ControleQuinquennalApi } from "@src/api/controleQuinquennal.api";
import styles from "./ControleQuinquennalRapport.module.scss";
import { useControleQuinquennalAdmin } from "../useControleQuinquennalAdmin";

export function ControleQuinquennalRapportPage() {
  const { openConfirmDialog } = useDialog();
  const { idEntreprise } = useParams<{ idEntreprise: string }>();
  const [file, setFile] = useState<File>();
  const { controleQuinquennal, entreprise } = useControleQuinquennalAdmin();
  const handleSend = () => {
    if (!controleQuinquennal || !file || !entreprise) return;
    openConfirmDialog({
      title: "Vous allez envoyer le rapport",
      message:
        "Le contrôle ne sera plus modifiable. Le rapport sera accessible par l'adhérent.",
      cancelText: "Annuler",
      confirmText: "Valider",
    })
      .then((shouldSend) => {
        if (!shouldSend) return;
        ControleQuinquennalApi.validateAdmin(
          controleQuinquennal,
          entreprise.siren,
          file
        )
          .then(() => {
            displaySucessToast(
              "Le contrôle quinquennal a bien été validé, vous pouvez fermer cette page."
            );
          })
          .catch(console.error);
      })
      .catch(console.error);
  };

  return (
    <ControleQuinquennalTemplate
      title="Contrôle"
      actions={
        <div className={styles.actions}>
          <Link to={`/admin/controle-quinquennal/${idEntreprise ?? ""}`}>
            <Button variant="text" icon={<ChevronLeft />}>
              Précédent
            </Button>
          </Link>
          <Button onClick={handleSend} disabled={!file}>
            Envoyer
          </Button>
        </div>
      }
    >
      <Card className={styles.card}>
        <h3>Rapport</h3>
        {!file && (
          <FileInputButtonWithDrag
            variant="outline-accent"
            onChange={(files) => {
              const fileUploaded = files.shift();
              setFile(fileUploaded);
            }}
            multiple={false}
            icon={<PlusCircle />}
            className={styles.upload}
          >
            Déposer votre rapport en pdf
          </FileInputButtonWithDrag>
        )}
        {file && (
          <FileRead
            onDelete={() => setFile(undefined)}
            name={file.name}
            deletable
          />
        )}
      </Card>
    </ControleQuinquennalTemplate>
  );
}
