import { Form } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  FormField,
  FormRow,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactNode } from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import {
  FieldValues,
  UseFormReturn,
} from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import { GatewayEntreprise, GatewayPaiement } from "@conformite/gateway";
import { useNavigate } from "react-router-dom";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import {
  CreationEntrepriseParams,
  RenouvellementEntrepriseParams,
} from "@src/api/adhesion2.api";
import { storeEntrepriseCreationRequest } from "@src/payment/utils/createEntreprises";
import { ID_FORM_ADHESION } from "@src/adhesion/ParcoursAdhesion.definition";
import { useStripePaiement } from "./useStripePaiement";
import { AdhesionVirementPage } from "./AdhesionVirementPage";

type AdhesionPaiementStripeFormProps<TValue extends FieldValues> = {
  formContent: ReactNode;
  formConfig: UseFormReturn<TValue, unknown, undefined>;
  modePrelevementSelectionne: GatewayPaiement.PaymentMethod;
  typeAdhesion: GatewayEntreprise.TypeAdhesionActive;
  entrepriseCreationParams:
    | CreationEntrepriseParams
    | RenouvellementEntrepriseParams;
  isRenouvellement?: boolean;
  setIsPaiementSubmitting: (isSubmitting: boolean) => void;
};

export function AdhesionPaiementStripeForm<TValue extends FieldValues>({
  formContent,
  formConfig,
  modePrelevementSelectionne,
  typeAdhesion,
  entrepriseCreationParams,
  isRenouvellement,
  setIsPaiementSubmitting,
}: AdhesionPaiementStripeFormProps<TValue>) {
  const { onSubmit: submitStripe } = useStripePaiement(
    typeAdhesion,
    entrepriseCreationParams,
    isRenouvellement
  );
  const navigate = useNavigate();

  const handleSubmit = async (values: TValue) => {
    setIsPaiementSubmitting(true);
    if (modePrelevementSelectionne === GatewayPaiement.PaymentMethod.TRANSFER) {
      try {
        storeEntrepriseCreationRequest(typeAdhesion, entrepriseCreationParams);
        if (isRenouvellement) {
          navigate(`/renouvellement/maj-entreprises`);
        } else {
          navigate(`/adhesion/creation-entreprises`);
        }
      } catch (error) {
        handleGatewayError({
          onUnhandled: () => {
            console.error(error);
            displayErrorToast(
              "Une erreur est survenue lors de la création des entreprises"
            );
          },
        })(error);
      }
    } else {
      try {
        await submitStripe();
      } catch (error) {
        handleGatewayError({
          onUnhandled: () => {
            console.error(error);
            displayErrorToast(
              "Une erreur est survenue lors du paiement de la cotisation"
            );
          },
        })(error);
      }
    }
    setIsPaiementSubmitting(false);
    return values;
  };

  return (
    <Form formConfig={formConfig} onSubmit={handleSubmit} id={ID_FORM_ADHESION}>
      {formContent}
      {modePrelevementSelectionne ===
        GatewayPaiement.PaymentMethod.TRANSFER && <AdhesionVirementPage />}
      {modePrelevementSelectionne !==
        GatewayPaiement.PaymentMethod.TRANSFER && (
        <FormRow>
          <FormField>
            <PaymentElement id="payment-element" />
          </FormField>
        </FormRow>
      )}
    </Form>
  );
}
