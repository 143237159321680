import { PropsWithChildren } from "react";
import { ReactComponent as FilterIcon } from "@src/assets/icons/filter.svg";
import styles from "./FilterDropdown.module.scss";
import { Dropdown } from "../DropDown/Dropdown";
import { FilterSelection } from "./useFilterPagination";
import { BulletNumber } from "../BulletNumber/BulletNumber";

type FilterDropdownProps<FilterKeys extends string> = PropsWithChildren<{
  filtersSelected: FilterSelection<FilterKeys> | null;
  blacklist?: FilterKeys[];
}>;
export function FilterDropdown<FilterKeys extends string>({
  children,
  filtersSelected,
  blacklist = [],
}: FilterDropdownProps<FilterKeys>) {
  const filters: [FilterKeys, string[]][] = filtersSelected
    ? (Object.entries(filtersSelected) as [FilterKeys, string[]][])
    : [];
  const numberOfFilters = filters
    .filter(([key]) => !blacklist.includes(key))
    .reduce((acc: number, [, curr]) => acc + (curr.length > 0 ? 1 : 0), 0);
  const Icon = numberOfFilters > 0 ? <FilterIcon /> : <FilterIcon />;
  return (
    <Dropdown
      content={
        <div className={styles.buttonContent}>
          <p>Filtrer</p>
          {numberOfFilters > 0 && <BulletNumber value={numberOfFilters} />}
        </div>
      }
      variant="text"
      icon={Icon}
      dismissOnClick
    >
      {() => <div className={styles.filters}>{children}</div>}
    </Dropdown>
  );
}
