import { FormApiConformiteFormationContinue } from 'form-api';

export const formationContinueUrl = 'formation-continue';
export type FormationContinueIas =
  FormApiConformiteFormationContinue.FormationContinueIas;
export type FormationContinueIobsp = Omit<
  FormApiConformiteFormationContinue.FormationContinueIobsp,
  'activitesBancaires'
>;
export type Formation = FormApiConformiteFormationContinue.Formation;
