import {
  Button,
  ButtonVariant,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ChangeEventHandler, ReactElement, useRef } from "react";
import classNames from "classnames";
import { ReactComponent as PlusCircle } from "@src/assets/icons/plus-circle.svg";
import styles from "./FileInputButton.module.scss";

export type FileInputButtonProps = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  children: string | ReactElement;
  accept?: string;
  variant?: ButtonVariant | "input";
  onClick?: () => Promise<boolean>;
  icon?: JSX.Element;
  disabled?: boolean;
  className?: string;
  multiple?: boolean;
};
export function FileInputButton({
  onChange,
  multiple,
  accept,
  children,
  icon,
  variant,
  onClick = () => Promise.resolve(true),
  disabled = false,
  className,
}: FileInputButtonProps) {
  const refFile = useRef<HTMLInputElement>(null);
  return (
    <>
      <input
        type="file"
        multiple={multiple}
        ref={refFile}
        onChange={onChange}
        accept={accept}
        className={styles.fileinput}
        disabled={disabled}
      />
      <Button
        variant={variant === "input" ? "text" : variant}
        icon={variant === "input" ? <PlusCircle /> : icon}
        onClick={() => {
          onClick()
            .then((shouldContinue) => {
              if (!shouldContinue || !refFile.current) return;
              refFile.current.value = "";
              refFile.current.click();
            })
            .catch((e) => {
              console.error(e);
            });
        }}
        className={classNames(
          {
            [styles.fileInputButton]: variant === "input",
          },
          className
        )}
        disabled={disabled}
      >
        {children}
      </Button>
    </>
  );
}
