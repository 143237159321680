import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as Loader } from "@src/assets/loader.svg";
import { PreviousButton } from "@src/parcours/NavigationButtons/PreviousButton";
import style from "./ParcoursEntrepriseActions.module.scss";

type ParcoursEntrepriseActionsProps = {
  onPrevious?: () => void;
  isLoading?: boolean;
  children?: string;
};

export const ID_FORM_ENTREPRISE = "id-form-entreprise";

export function ParcoursEntrepriseActions({
  onPrevious,
  isLoading = false,
  children = "Suivant",
}: ParcoursEntrepriseActionsProps) {
  return (
    <div className={style.adhesionEntrepriseActions}>
      <div>
        {onPrevious && <PreviousButton changeToPrevStep={onPrevious} />}
      </div>
      <div>
        <Button
          type="submit"
          disabled={isLoading}
          icon={isLoading ? <Loader /> : undefined}
          form={ID_FORM_ENTREPRISE}
        >
          {children}
        </Button>
      </div>
    </div>
  );
}
